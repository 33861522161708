import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextareaAutosize,
  Typography,
  withStyles,
} from "@material-ui/core";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { generate } from "shortid";
import config from "../../../config/aws-exports";
import { API } from "aws-amplify";
import { Constants } from "../../../config/constants";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import moment from "moment";
import EditAssignment from "../EditAssignment/EditAssignment";
import assessStyle from "../AssessmentAndAssignmentView/Assessment.module.scss";
import AssessmentAndAssignmentView from "../AssessmentAndAssignmentView/AssessmentAndAssignmentView";
import { Image } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { authData, awsSignIn } from "../../../redux/auth/authSlice";
import { useDispatch } from "react-redux";
import { produce } from "immer";

const CustomButton = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "6px 15px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: "#01498e",
    borderColor: "#fff",
    color: "#fff",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      backgroundColor: "#01498e",
      border: "1px solid #fff",
      color: "#fff",
      boxShadow: "#fff",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#fff",
      borderColor: "#01498e",
    },
    "&:focus": {
      //   boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  },
})(Button);

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  // console.log(props);

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
});

export default function AssessmentAndAssignmentList({ list, type, courseId }) {
  const classes = useStyles2();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [assessId, setAssessId] = React.useState(0);
  const [instructions, setInstructions] = React.useState([]);
  const [instructionsView, setInstructionsView] = React.useState(true);
  const [questions, setQuestions] = React.useState([]);
  const [imgUrl, setImgUrl] = React.useState("");
  const [qLoading, setQLoading] = React.useState(false);
  const userDeatils = useSelector(authData);
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [quizQuestions, setQuizQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [fileName, setFileName] = useState("");
  const [checkboxes, setCheckboxes] = useState([]);
  const [correct, setCorrect] = useState(false);
  const [score, setScore] = useState(0);
  const [editRow, setEditRow] = useState({});
  let rows = [...list];
  //   JSON.parse(JSON.stringify(assessment));
  // console.log(rows);
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleClickOpen = (scrollType, assessId, instructions) => () => {
    setOpen(true);
    setScroll(scrollType);
    setAssessId(assessId);
    setInstructions(instructions);
    setInstructionsView(true);
    getQuiz(assessId);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleAnswerOptionClick = (correct, index) => {
    // questions[currentQuestion].response = index;
    let qdata = [...quizQuestions];

    qdata = produce(qdata, (draft) => {
      draft[currentQuestion].response = index;
    });
    setQuizQuestions(qdata);
    console.log(questions);

    // alert(JSON.stringify(correct));
    setCorrect(correct);
  };
  const handleClickEditOpen =
    (scrollType, assessId, instructions, row) => () => {
      setOpenEdit(true);
      setScroll(scrollType);
      setAssessId(assessId);
      setInstructions(instructions);
      setInstructionsView(true);
      getQuiz(assessId);
      setEditRow(row);

      console.log("Row", row);
    };

  function setCheckbox(index, checked) {
    console.log(checkboxes);
    let newCheckboxes = [...checkboxes];

    if (newCheckboxes[index].checked === undefined) {
      newCheckboxes = produce(newCheckboxes, (draft) => {
        draft[index].checked = false;
      });

      // newCheckboxes[index].checked = false;
    }
    // newCheckboxes[index].checked = checked;
    newCheckboxes = produce(newCheckboxes, (draft) => {
      draft[index].checked = checked;
    });
    setCheckboxes(newCheckboxes);
    let tmp = 0;
    for (let k = 0; k < checkboxes.length; k++) {
      if (
        checkboxes[k].checked !== undefined &&
        checkboxes[k].checked === true
      ) {
        tmp = 1;
        setCorrect(true);
      }
      if (tmp === 0 && k == checkboxes.length - 1) {
        setCorrect(undefined);
      }
    }
  }
  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const handleAnswerBtnClick = () => {
    let qdata = [...quizQuestions];
    if (correct === "true" || correct === true) {
      setScore(score + 1);
    }
    // ansaddedtmp();
    const nextQuestion = currentQuestion + 1;

    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
      if (
        questions[nextQuestion].atype == 3 ||
        questions[nextQuestion].atype == 5
      ) {
        setCheckboxes(questions[nextQuestion].iopts);
      }
    } else {
      handleClose();
      setCurrentQuestion(0);
      setInstructionsView(true);
      setQuizQuestions(userDeatils.questions);
    }
    setCorrect(undefined);
  };
  async function getQuiz(id) {
    setQLoading(true);
    const bodyParam = {
      body: {
        assessid: courseId,
        quizid: id,
        type: "assessmentdetails",
        quiztype: type == "Assessment" ? "assess" : "assign",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(JSON.stringify(bodyParam.body));

      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_ASSESMENT,
        bodyParam
      );
      // console.log(response);
      const { qitems } = response;

      setQuestions(qitems);
      setQLoading(false);
      let ques = { ...userDeatils };
      if (response.qitems === undefined || response === undefined) {
        ques.questions = [];
        dispatch(awsSignIn(ques));
      } else {
        ques.questions = qitems;
        dispatch(awsSignIn(ques));
      }
      const imgUrl = `https://${
        Constants.DOMAIN
      }/${config.aws_org_id.toLowerCase()}-resources/images/assessment-images/${courseId}/${id}/`;
      setImgUrl(imgUrl);
      setQLoading(false);
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <div>
      <EditAssignment
        open={openEdit}
        handleClose={handleEditClose}
        type={type}
        //   scroll={scroll}
        assessId={assessId}
        row={editRow}
        //   instructions={instructions}

        questions={questions}
        //   imgUrl={imgUrl}
        //   getQuiz={getQuiz}
        //   fullScreen={fullScreen}
        //  setInstructionsView1={setInstructionsView}
        //   instructionsView={instructionsView}
        courseId={courseId}
        qLoading={qLoading}
      />

      <TableContainer component={Paper}></TableContainer>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="custom pagination table">
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row, indx) => (
              <TableRow key={generate() + row.title}>
                {row.startdate === undefined && row.enddate === undefined ? (
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ fontSize: "1.5rem", cursor: "pointer" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        onClick={handleClickOpen(
                          "body",
                          row.assessid,
                          row.instructions
                        )}
                      >
                        {row.title}
                        <p>
                          Answer Reveal Date :{" "}
                          {moment(row.answerdate).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )}
                        </p>
                      </div>
                      <div>
                        {/* <CustomButton
                          onClick={handleClickEditOpen(
                            "body",
                            row.assessid,
                            row.instructions,
                            row
                          )}
                        >
                          Edit
                        </CustomButton> */}

                        {/* <IconButton
                          aria-label="delete"
                          className={classes.margin}
                          onClick={handleClickEditOpen(
                            "body",
                            row.assessid,
                            row.instructions,
                            row
                          )}
                        >
                          <EditIcon
                            style={{ color: " #01498e", fontSize: "small" }}
                          />
                        </IconButton> */}
                      </div>
                    </div>
                  </TableCell>
                ) : (
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ fontSize: "1.5rem", cursor: "pointer" }}
                    onClick={handleClickOpen(
                      "body",
                      row.assessid,
                      row.instructions
                    )}
                  >
                    {row.title}
                    <p>
                      Start Date :{" "}
                      {moment(row.startdate).format("MMMM Do YYYY, h:mm:ss a")}
                    </p>
                    <p>
                      End Date :{" "}
                      {moment(row.enddate).format("MMMM Do YYYY, h:mm:ss a")}
                    </p>
                    <p>
                      Answer Reveal Date :{" "}
                      {moment(row.answerdate).format("MMMM Do YYYY, h:mm:ss a")}
                    </p>
                  </TableCell>
                )}
              </TableRow>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: false,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
        <AssessmentAndAssignmentView
          open={open}
          handleClose={handleClose}
          scroll={scroll}
          assessId={assessId}
          instructions={instructions}
          type={type}
          questions={questions}
          imgUrl={imgUrl}
          getQuiz={getQuiz}
          fullScreen={fullScreen}
          setInstructionsView={setInstructionsView}
          instructionsView={instructionsView}
          courseId={courseId}
          qLoading={qLoading}
        />

        {/* <EditAssignment
        open={openEdit}
        handleClose={handleEditClose}
        scroll={scroll}
        assessId={assessId}
        instructions={instructions}
        type={type}
        questions={questions}
        imgUrl={imgUrl}
        getQuiz={getQuiz}
        fullScreen={fullScreen}
        setInstructionsView={setInstructionsView}
        instructionsView={instructionsView}
        courseId={courseId}
        qLoading={qLoading}
      /> */}
      </TableContainer>
    </div>
  );
}
