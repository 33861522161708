// Dependencies imports
import React, { useState, useEffect,useRef } from 'react';
import { API } from "aws-amplify";
import swal from 'sweetalert';
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import Skeleton from "@material-ui/lab/Skeleton";
import {useReactToPrint} from "react-to-print";
import { Link, useNavigate } from "react-router-dom";
// import ReactToPrint from "react-to-print";

// Local imports
import UserHeader from '../Header/UserHeader/UserHeader';
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import { authData, awsSignIn } from "../../redux/auth/authSlice";
import ApplicationView from '../Programs/ApplicationView';

// Styles imports
import App from './ApplicationDetails.module.scss';

const ApplicationDetails = () => {
    const [application, setApplication] = useState({});
    //const [general, setGeneral] = useState([]);
    const [loading, setLoading] = useState(false);
    const [load, setLoad] = useState(true);
    let navigate = useNavigate();
    const componentRef = useRef();
    const handlePrint = useReactToPrint({content : () => componentRef.current})

    let userDetails = useSelector(authData);
    const dispatch = useDispatch();

  
    // Useeffect for api call
    useEffect(() => {
        getDetails();
        breadcrumb();
        return () => { };
    }, []);

    // API call syntax to get applicationdetails
    async function getDetails() {
       
        const bodyParam = {
            body: {
                oid: config.aws_org_id,
                action: 1,
                appid: userDetails.curapplication.appid,
                eid: userDetails.curapplication.eid,
                emailid: userDetails.curapplication.emailid,
            },
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        
        try {
            //console.log(bodyParam.body);
            const application = await API.post(
                config.aws_cloud_logic_custom_name,
                Constants.UPDATE_USER_APPLICATION,
                bodyParam
            );
            //console.log("Application details1", userDetails.curapplication.eid);
            application.eid = userDetails.curapplication.eid;
           // console.log("Application details", application);
            setApplication(application);
            setLoad(false);
            //setGeneral(application.applicationform.generalinfo);
        } catch (error) {
            // swal({
            //     title: "Oooops!",
            //     text: "something went wrong!",
            //     icon: "warning",
            //     dangerMode: true,
            // })
            //     .then(willDelete => {
            //         if (willDelete) {
            //         }
            //     });
            console.error(error);
        }
    };

    async function submit(status) {
      
        const bodyParam = {
            body: {
                oid: config.aws_org_id,
                bpid: userDetails.curprgcou.bpid,
                type: 2,
                status: status,
                eid: userDetails.curapplication.eid,
            },
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
       
        console.log("1"+JSON.stringify(bodyParam.body))
        try {
            
            const application = await API.post(
                config.aws_cloud_logic_custom_name,
                Constants.GET_UPDATE_APPLICATION_STATUS,
                bodyParam
            );
           
            console.log("Application details", application);
            setLoading(false);
            if(status === 3){
                swal({
                    title: "Success!",
                    text: "Application Approved",
                    icon: "success",
                    dangerMode: false,
                })
                .then(willDelete => {
                    if (willDelete) {
                        navigate("/application");
                    }
                });
            }  else{
                swal({
                    title: "Success!",
                    text: "Application Rejected",
                    icon: "success",
                    dangerMode: false,
                })
            }

            // setApplication(application);
            // setGeneral(application.applicationform.generalinfo);
        } catch (error) {
            swal({
                title: "Oooops!",
                text: "something went wrong!",
                icon: "warning",
                dangerMode: true,
            })
                .then(willDelete => {
                    if (willDelete) {

                    }
                });
            console.error(error);
        }
    }
    
      
    function breadcrumb() {
        let sdata = { ...userDetails };
        let temp = [...sdata.breadcrumb];
        
        temp[3] = {
            name:'Application Detials',
            path:'/applicationdetails'
        };

        sdata.breadcrumb = temp;

        dispatch(awsSignIn(sdata));
    }

  
    return (
        <div className={App.maincontainer}>
            {loading === false ?
                <div>
            <UserHeader Bindex={3}/>
            <h3 className={App.header}>Application Details</h3>
            <div className={App.generalinfo}>
              {application != undefined && !load && <ApplicationView ref={componentRef}  data={application} />  }
            </div>
            <div className={App.buttonholder}>
                <button className={App.submit} onClick = {()=>{submit(3); setLoading(true)}} >Approve</button>
                <button className={App.cancel} onClick = {()=>{submit(4); setLoading(true)}} >Reject</button>
                
                        <button className={App.cancel} onClick={() => handlePrint()}>Print</button>
                        
            </div>
            </div>
            :
            <div className={App.spinnerdiv}>
                <CircularProgress />
                </div>}
                </div>
        
    );
};

export default ApplicationDetails;