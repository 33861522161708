// Dependencies imports
import React, { useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

// Local imports
import { ReactComponent as Close } from "../../assets/svg/close_black_24dp.svg";
// styles imports
import Edit from "./EditUser.module.scss";
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import { API } from "aws-amplify";
import DisbleUser from "./DisbleUser";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/styles";
import swal from "sweetalert";

const useStyles = makeStyles({
  root: {
    "& .super-app-theme--header": {
      backgroundColor: "rgb(38, 101, 170)",
    },
  },
  root1: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
});
const EditUser = (props) => {
  let { handleClose, editUserData, getUsersDetails } = props;

  const [userName, setUserName] = useState(editUserData.col1);
  const [email, setEmail] = useState(editUserData.col2);
  const [pno, setPno] = useState(editUserData.col7);
  const [gender, setGender] = useState(editUserData.col6);
  const [address, setAddress] = useState(editUserData.col8);
  const [urole, setUrole] = useState(editUserData.col3);
  const [eid, seteid] = useState(editUserData.col9);
  const [spin, setSpin] = useState(false);
  const classes = useStyles();
  const [userNameErr, setUserNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [pnoErr, setPnoErr] = useState("");
  const [genderErr, setGenderErr] = useState("");
  const [openUser, setopenUser] = useState(false);
  const [programDeatails, setProgramDeatails] = useState([]);

  useEffect(() => {
    getUser_Program_Batch_Details();
  }, []);

  const onChangeHandlerUser = (event) => {
    setUserName(event.target.value);
  };
  const onChangeHandlerEmail = (event) => {
    setEmail(event.target.value);
  };
  const onChangeHandlerPno = (event) => {
    setPno(event.target.value);
  };
  const onChangeHandlerGender = (event) => {
    setGender(event.target.value);
  };
  const onChangeHandlerAddress = (event) => {
    setAddress(event.target.value);
  };

  const handleCloseDisable = () => {
    setopenUser(false);
  };

  async function getUser_Program_Batch_Details() {
    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: eid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        `/getUserProgramDetails`,
        bodyParam
      );

      setProgramDeatails(response);

      setSpin(false);
    } catch (error) {
      console.log("getUserError", error);
    }
  }
  const onsubmit = () => {
    let formIsValid = true;
    setUserNameErr("");
    setEmailErr("");
    setPnoErr("");
    setGenderErr("");

    if (
      userName === "" ||
      userName === null ||
      (userName != undefined && userName.trim() === "")
    ) {
      formIsValid = false;
      setUserNameErr("Please enter user name");
      return;
    }
    if (
      email === "" ||
      email === null ||
      (email != undefined && email.trim() === "")
    ) {
      formIsValid = false;
      setEmailErr("*Please enter your email-ID.");
      return;
    }
    if (
      pno !== null &&
      pno != undefined &&
      pno.trim() !== "" &&
      pno.length < 10
    ) {
      formIsValid = false;
      setPnoErr(
        "Please enter a valid phone number (should be atleast 10 digits)"
      );
      return;
    }
    if (gender === "" || gender === null) {
      formIsValid = false;
      setGenderErr("Please select gender");
      return;
    }
    if (userName !== "") {
      if (!userName.match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        setUserNameErr("*Please enter alphabet characters only.");
        return;
      }
    }
    if (email !== "") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        formIsValid = false;
        setEmailErr("*Please enter valid email-ID.");
        return;
      }
    }

    if (formIsValid == true) {
      editUsersDetails();
    }

    async function editUsersDetails() {
      setSpin(true);
      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          uname: userName,
          eid: eid,
          urole: urole,
          gender: gender,
          address: address !== undefined ? address : " ",
          contactno: pno !== undefined ? pno : " ",
        
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      try {
        await API.post(
          config.aws_cloud_logic_custom_name,
          Constants.GET_UPDATEUSER,
          bodyParam
        );

        swal({
          title: "Success",
          text: "User Edited successfully",
          icon: "success",
          dangerMode: false,
        }).then((willDelete) => {
          if (willDelete) {
            setSpin(false);
          }
        });
        handleClose();
        getUsersDetails();
      } catch (error) {
        swal({
          title: "Oooops!",
          text: "something went wrong!",
          icon: "warning",
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            setSpin(false);
          }
        });
        console.log("getUserError", error);
      }
    }
  };
  return (
    <div className={Edit.maindiv}>
      {spin === false ? (
        <div className={Edit.containermain}>
          <div className={Edit.iconcontainer} onClick={handleClose}>
            <Close className={Edit.icon} />
          </div>
          <div className={Edit.namebutton}>
            <h3 className={Edit.username}> Edit User</h3>
          </div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openUser}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openUser}>
              <DisbleUser
                handleClose={handleCloseDisable}
                editUserData={editUserData}
                getUsersDetails={getUsersDetails}
              />
            </Fade>
          </Modal>
          <div className={Edit.maindivision}>
            <div className={Edit.form}>
              <div className={Edit.field}>
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "500px",
                    fontFamily: "nunito",
                  }}
                  for="name"
                  required
                >
                  Name
                </label>
                <input
                  style={{
                    border: "none",
                    width: "80%",
                    boxShadow: "0px 0px 5px 0px lightgrey ",
                    background: "white",
                    height: "30px",
                    marginBottom: "20px",
                    fontSize: "15px",
                    paddingLeft: "10px",
                    fontWeight: "200px",
                  }}
                  type="text"
                  id="name"
                  onChange={onChangeHandlerUser}
                  value={userName}
                  required="required"
                />
              </div>
              <div
                style={{
                  color: "#cc0000",
                  marginBottom: "12px",
                  marginTop: "-10px",
                  textAlign: "start",
                  marginLeft: "20%",
                }}
              >
                {userNameErr}
              </div>

              <div className={Edit.field}>
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "500px",
                    fontFamily: "nunito",
                  }}
                  for="email"
                  required
                >
                  Email
                </label>
                <input
                  style={{
                    border: "none",
                    width: "80%",
                    boxShadow: "0px 0px 5px 0px lightgrey ",
                    background: "white",
                    height: "30px",
                    marginBottom: "20px",
                    fontSize: "15px",
                    paddingLeft: "10px",
                    fontWeight: "200px",
                  }}
                  type="email"
                  id="email"
                  onChange={onChangeHandlerEmail}
                  value={email}
                  required="required"
                  disabled
                />
              </div>
              <div
                style={{
                  color: "#cc0000",
                  marginBottom: "12px",
                  marginTop: "-10px",
                  textAlign: "start",
                  marginLeft: "20%",
                }}
              >
                {emailErr}
              </div>

              <div className={Edit.field}>
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "500px",
                    fontFamily: "nunito",
                  }}
                  for="contact"
                >
                  Ph. No
                </label>
                <input
                  style={{
                    border: "none",
                    width: "80%",
                    boxShadow: "0px 0px 5px 0px lightgrey ",
                    background: "white",
                    height: "30px",
                    marginBottom: "20px",
                    fontSize: "15px",
                    paddingLeft: "10px",
                    fontWeight: "200px",
                  }}
                  type="number"
                  id="contact"
                  onChange={onChangeHandlerPno}
                  value={pno}
                />
              </div>
              <div
                style={{
                  color: "#cc0000",
                  marginBottom: "12px",
                  marginTop: "-10px",
                  textAlign: "start",
                  marginLeft: "20%",
                }}
              >
                {pnoErr}
              </div>

              <div className={Edit.field}>
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "500px",
                    fontFamily: "nunito",
                  }}
                  for="gender"
                >
                  Gender
                </label>
                <select
                  style={{
                    border: "none",
                    width: "80%",
                    boxShadow: "0px 0px 5px 0px lightgrey",
                    background: "white",
                    height: "30px",
                    marginBottom: "20px",
                    fontSize: "15px",
                    paddingLeft: "10px",
                    fontWeight: "200px",
                  }}
                  name="gender"
                  id="gender"
                  onChange={onChangeHandlerGender}
                  value={gender}
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="trans">Trans Gender</option>
                </select>
              </div>
              <div
                style={{
                  color: "#cc0000",
                  marginBottom: "12px",
                  marginTop: "-10px",
                  textAlign: "start",
                  marginLeft: "20%",
                }}
              >
                {genderErr}
              </div>

              <div className={Edit.field}>
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "500px",
                    fontFamily: "nunito",
                  }}
                  for="address"
                >
                  Address
                </label>
                <textarea
                  style={{
                    border: "none",
                    width: "80%",
                    boxShadow: "0px 0px 5px 0px lightgrey",
                    background: "white",
                    height: "80px",
                    marginBottom: "20px",
                    fontSize: "15px",
                    paddingLeft: "10px",
                    fontWeight: "200px",
                  }}
                  id="address"
                  rows="4"
                  onChange={onChangeHandlerAddress}
                  value={address}
                />
              </div>

              <div style={{ display: "flex" }}>
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "500px",
                    fontFamily: "nunito",
                  }}
                >
                  Program Assigned
                </p>

                <div
                  style={{
                    border: "none",
                    width: "85%",
                    boxShadow: "0px 0px 5px 0px lightgrey ",
                    background: "white",
                    height: "fit-content",
                    marginBottom: "20px",
                    paddingLeft: "10px",
                    fontWeight: "200px",
                  }}
                >
                  {programDeatails == undefined ||
                  programDeatails.length == 0 ? (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "500px",
                        fontFamily: "nunito",
                        textAlign: "left",
                      }}
                    >
                      No Program assigned
                    </p>
                  ) : (
                    <>
                      {programDeatails.map((item, index) => (
                        <p
                          style={{
                            fontSize: "16px",
                            textAlign: "left",
                          }}
                        >
                          {index + 1}. {item.pname} - {item.bname}
                        </p>
                      ))}
                    </>
                  )}
                </div>
              </div>

              <br></br>
              <br></br>
              <input
                type="submit"
                value="Save"
                className={Edit.submit}
                onClick={onsubmit}
              ></input>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.root}>
          <CircularProgress style={{ color: Constants.main_color_1 }} />
        </div>
      )}
    </div>
  );
};

export default EditUser;
