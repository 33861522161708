import React, { useEffect, useState } from "react";
import {
  Tab,
  Container,
  Form,
  Header,
  Image,
  Checkbox,
  Radio,
  TextArea,
} from "semantic-ui-react";
import { FaBars } from "react-icons/fa";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import UserHeader from "../Header/UserHeader/UserHeader";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import { API } from "aws-amplify";
import axios from "axios";
import ApplicationStyle from "./Application.module.scss";
import CircularProgress from "@material-ui/core/CircularProgress";

import "./applicationform.css";
import { ImportantDevices } from "@material-ui/icons";
import { setCookie } from "react-use-cookie";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    alignItems: "center",
  },
  paper: {
    padding: theme.spacing(2),

    color: theme.palette.text.secondary,
    marginLeft: "20%",
  },
}));

const  Application = React.forwardRef((props, ref) => {
  const [application, setApplication] = useState(props.data);
  
  let navigate = useNavigate();
  const dispatch = useDispatch();
  let userDetails = useSelector(authData);
   useEffect(() => {
    console.log("userDetails2==",userDetails)
    const expires = new Date().getTime() + 60 * 60 * 1000;
    setCookie("CloudFront-Expires", expires);
    setCookie("CloudFront-Policy", application.Policy);
    setCookie("CloudFront-Signature", application.pSignature);
    setCookie("CloudFront-Key-Pair-Id", application.KeyPairId);
   }, []); 
  // console.log("get", localStorage.getItem("eVBAB"))
  const classes = useStyles(); 
  return   (<div style={{ zIndex: "-2" }}>  
            <Container text id="containers" >
              <div ref={ref}>
        <div style={{ paddingBottom: "2rem", borderBottom: "2px solid",textAlign:'center' }}>
        <img src={`https://${
                  Constants.DOMAIN
                }/${config.aws_org_id.toLowerCase()}-resources/images/org-images/jssaher-cert.jpg`}
                alt=""  style={{width:'50%'}}/>
          
        </div>
        <div class="headerContainer" >
          <div>
            <Header as="h1" style={{ paddingTop: "2rem", marginBottom: 0 }}>
              APPLICATION FOR ADMISSION
            </Header>
            <h3 style={{ marginTop: 0 }}>ACADEMIC YEAR 2022 - 2023</h3>
          </div>
          <div class="profile"> 
            <img src={`https://${
                  Constants.DOMAIN
                }/${config.aws_org_id.toLowerCase()}-data/userdata/${application?.eid}/${application?.profilePhoto}`}
                alt="" /> 
          </div>
        </div>

        <Form style={{ textAlign: "left" }} className='mainform' >
          <h3 class="subHeading">A. DETAILS OF APPLICANT</h3>
          <ol>
            <li>
              <Form.Field>
                <div class="checkboxContent">
                  1.Title:
                  <input
                    type="radio"
                    name="title"
                    value="Mr"
                    defaultChecked={application?.appTitle === "Mr"}
                 
                  />
                  <label>Mr</label>
                  <input
                    type="radio"
                    value="Mrs"
                    name="title" 
                    defaultChecked={application?.appTitle === "Mrs"}
                   
                  />
                  <label >Mrs</label>
                  <input
                    type="radio"
                    value="Miss"
                    name="title" 
                    defaultChecked={application?.appTitle === "Miss"}
                  />
                  <label>Miss</label>
                </div>
              </Form.Field>
            </li>
            <li>
              <Form.Field>
                2.Surname:
                <div class="ui input inputContainer">
                  <p  class="inputField" >{application?.surName}
                    </p>
                </div>
              </Form.Field>
            </li>
            <li>
              <Form.Field>
                3.First Name:
                <div class="ui input inputContainer">
                  <p
                    type="text"
                    class="inputField"
                  > {application?.firstName}</p>
                </div>
              </Form.Field>
            </li>
            <li>
              <Form.Field>
                4.Maiden Name (If applicable):
                <div class="ui input inputContainer4">
                  <p
                    type="text"
                    class="inputField" 
                  >{application?.maidenName}</p>
                </div>
              </Form.Field>
            </li>
            <li>
              <Form.Field>
                5.Date of Birth:
                <div class="ui input inputContainer2">
                  <input
                    type="date"
                    id="birthday"
                    name="birthday"
                    class="inputField"
                    defaultValue={application?.dob}
                  />
                </div>
                Age:
                <div class="ui input inputContainer2">
                  <p  class="inputField"
                  >{application?.age}</p>
                </div>
              </Form.Field>
            </li>
            <li>
              <Form.Field>
                6.Nationality:
                <div class="ui input inputContainer">
                  <p class="inputField" > {application?.nationality}</p>
                </div>
              </Form.Field>
            </li>
            <li>
              7.Identification:
              <Form.Field>
                NIC No.
                <div class="ui input inputContainer2">
                  <p  class="inputField"  >{application?.nic}</p>
                </div>
                Passport No.
                <div class="ui input inputContainer2">
                  <p 
                    class="inputField"  >{application?.passport}</p>
                </div>
              </Form.Field>
            </li>
            <li>
              8.Residential Address:
                <div class="ui input inputContainer3">
                  <p > {application?.address}</p>
                </div>
            
            </li>
            <li>
              <Form.Field>
                9.Telephone (M):
                <div class="ui input inputContainer2">
                  <p  class="inputField"  > {application?.phoneNo}</p>
                </div>
                Telephone (H):
                <div class="ui input inputContainer2">
                  <p  class="inputField"  > {application?.phoneNo}</p>
                </div>
              </Form.Field>
            </li>
            <li>
             10.Email Address:
                <div class="ui input inputContainer">
                  <p class="inputField"  >{application?.email}</p>
                </div>
            </li>
          </ol>

          <h3 class="subHeading">
            B. PROGRAMME OF STUDY APPLIED FOR (In order of priority)
          </h3>
          <table class="ui celled table">
            <thead>
              <tr>
                <th>SN</th>
                <th>Programme Titles</th>
                <th>Full-Time</th>
                <th>Part-Time</th>
              </tr>
            </thead>
            <tbody>
              {application?.programmeTable?.map((item, i) => {
                return (
                  <tr>
                    <td data-label="sn">
                      <p class="tableInputField" 
                      >{i + 1}</p>
                    </td>
                    <td data-label="title">
                      <p 
                        class="tableInputField" 
                      >{item.title}</p>
                    </td>
                    <td data-label="ftime">
                      <p  class="tableInputField" 
                      >{item.ftime}</p>
                    </td>
                    <td data-label="ptime">
                      <p  class="tableInputField" 
                      >{item.ptime}</p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <h3 class="subHeading">C. ACADEMIC RECORDS</h3>
          <p>
            Please attach certified copies of all your qualifications and
            academic transcripts.
          </p>
          <ol>
            <li>
              1.Cambridge School Certificate / 10th Standard
              <Form.Field>
                Year:
                <div class="ui input inputContainer2">
                  <p 
                    class="inputField" 
                  >{application?.certificate?.tenthYear}</p>
                </div>
                Index/Registration No:
                <div class="ui input inputContainer2">
                  <p  class="inputField" 
                  >{application?.certificate?.tenthRegistration}</p>
                </div>
              </Form.Field>
              <Form.Field>
                <table class="ui celled table">
                  <thead>
                    <tr>
                      <th>SN</th>
                      <th>Subjects</th>
                      <th>Grades</th>
                    </tr>
                  </thead>
                  <tbody>
                    {application?.certificate?.tenth?.map((item, i) => {
                      return (
                        <tr>
                          <td data-label="sn1">
                            <p 
                              class="tableInputField" 
                            >{i + 1}</p>
                          </td>
                          <td data-label="subject1">
                            <p 
                              class="tableInputField" 
                            >{item.Subjects}</p>
                          </td>
                          <td data-label="grade1">
                            <p 
                              class="tableInputField" 
                            >{item.tenthGrades}</p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Form.Field>
            </li>
            <li>
              2.GCE ‘O’ Level (if applicable)
              <Form.Field>
                Year:
                <div class="ui input inputContainer2">
                  <p  class="inputField" 
                  >{application?.certificate?.GCEYear}</p>
                </div>
                Index/Registration No:
                <div class="ui input inputContainer2">
                  <p 
                    class="inputField" 
                  >{application?.certificate?.GCERegistration}</p>
                </div>
              </Form.Field>
              <Form.Field>
                <table class="ui celled table">
                  <thead>
                    <tr>
                      <th>SN</th>
                      <th>Main Subjects </th>
                      <th>Grades</th>
                    </tr>
                  </thead>
                  <tbody>
                    {application?.certificate?.GCE?.map((item, i) => {
                      return (
                        <tr>
                          <td data-label="sn1">
                            <p 
                              class="tableInputField" >{i + 1}</p>
                          </td>
                          <td data-label="subject1">
                            <p 
                              class="tableInputField" >{item.GCESubjects}</p>
                          </td>
                          <td data-label="grade1">
                            <p  class="tableInputField" >{item.GCEGrades}</p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Form.Field>
            </li>
            <li >
              3.Cambridge Higher School Certificate / 12th Standard
              <Form.Field>
                Year:
                <div class="ui input inputContainer2">
                  <p  class="inputField" >{application?.certificate?.tweleYear}</p>
                </div>
                Index/Registration No:
                <div class="ui input inputContainer2">
                  <p class="inputField" >{application?.certificate?.tweleRegistration}</p>
                </div>
              </Form.Field>
              <Form.Field style={{display:'block'}}>
                <table class="ui celled table" style={{width:'100%'}}>
                  <thead>
                    <tr>
                      <th>SN</th>
                      <th>Main Subjects </th>
                      <th>Grades</th>
                    </tr>
                  </thead>
                  <tbody>
                    {application?.certificate?.twele?.map((item, i) => {
                      return (
                        <tr>
                          <td data-label="sn1">
                            <p  >{i + 1}</p>
                          </td>
                          <td data-label="subject1">
                            <p class="tableInputField" >{item.tSubjects}</p>
                          </td>
                          <td data-label="grade1">
                            <p class="tableInputField" >{item.tGrades}</p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <table class="ui celled table">
                  <thead>
                    <tr>
                      <th>SN</th>
                      <th>Subsidiary Subjects (if applicable) </th>
                      <th>Grades</th>
                    </tr>
                  </thead>
                  <tbody>
                  {application.certificate?.subsidiary?.map((item, i) => {
                      return (
                        <tr key={'tw'+i}>
                          <td data-label="sn1">
                          <p class="tablepField" 
                      >{i + 1}</p>
                          </td>
                          <td data-label="subject1">
                            <p class="tableInputField" >{item.tSubjects}</p>
                          </td>
                          <td data-label="grade1">
                            <p class="tableInputField" >{item.tGrades}</p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  </table>
              </Form.Field>
            </li>

            <li>
              4.GCE ‘A’ Level (if applicable)
              <table class="ui celled table">
                <thead>
                  <tr>
                    <th>SN</th>
                    <th>Main Subjects</th>
                    <th>Grades</th>
                  </tr>
                </thead>
                <tbody>
                  {application?.certificate?.GCEA?.map((item, i) => {
                    return (
                      <tr>
                        <td data-label="sn1">
                        <p  >{i + 1}</p>
                        </td>
                        <td data-label="subject1">
                          <p class="tableInputField" >{item.ASubjects}</p>
                        </td>
                        <td data-label="grade1">
                          <p  class="tableInputField" >{item.AGrades}</p>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </li>
            <li>
              5.Other Secondary / High School Qualifications (if applicable)
              <Form.Field>
                Year:
                <div class="ui input inputContainer2">
                  <p class="inputField" >{application?.certificate?.othSecYear}</p>
                </div>
                Index/Registration No:
                <div class="ui input inputContainer2">
                  <p class="inputField" >{application?.certificate?.othRegistration}</p>
                </div>
              </Form.Field>
              <Form.Field>
                <table class="ui celled table">
                  <thead>
                    <tr>
                      <th>SN</th>
                      <th>Course / Programme</th>
                      <th>Awarding Body</th>
                      <th>Grades Awarded</th>
                      <th>Year</th>
                      <th>Duration</th>
                    </tr>
                  </thead>
                  <tbody>
                    {application?.certificate?.oth?.map((item, i) => {
                      return (
                        <tr>
                          <td data-label="sn1">
                          <p  >{i + 1}</p>
                          </td>
                          <td data-label="subject1">
                            <p class="tableInputField" >{item.Course}</p>
                          </td>
                          <td data-label="grade1">
                            <p class="tableInputField" >{item.othAwards}</p>
                          </td>
                          <td data-label="subject1">
                            <p  class="tableInputField" >{item.othGradesAwd}</p>
                          </td>
                          <td data-label="grade1">
                            <p   class="tableInputField"  >{item.othYear}</p>
                          </td>
                          <td data-label="grade1">
                            <p class="tableInputField" >{item.othDur}</p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Form.Field>
            </li>
            <li>
              6.Post-Secondary Education / Graduate Degree
              <table class="ui celled table">
                <thead>
                  <tr>
                    <th>
                      Duration <br />
                      From / To
                    </th>
                    <th>Post-Secondary Institution / University</th>
                    <th>Qualifications Obtained</th>
                    <th>Main Subject Area</th>
                    <th>Grades Awarded (Specify Class / Division / Honours)</th>
                  </tr>
                </thead>
                <tbody>
                  {application?.certificate?.Post?.map((item, i) => {
                    return (
                      <tr>
                        <td data-label="sn1">
                          From
                          <input
                            type="date"
                            id="today"
                            name="today"
                            class="inputField"
                            defaultValue={item.DurF} 
                          />
                          To
                          <input
                            type="date"
                            id="today"
                            name="today"
                            class="inputField"
                            defaultValue={item.DurT} 
                          />
                        </td>
                        <td data-label="subject1">
                          <p class="tableInputField" >{item.PostUni}</p>
                        </td>
                        <td data-label="grade1">
                          <p class="tableInputField" >{item.PostQua}</p>
                        </td>
                        <td data-label="subject1">
                          <p class="tableInputField" >{item.PostMain}</p>
                        </td>
                        <td data-label="grade1">
                          <p  class="tableInputField" >{item.PostGradA}</p>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <p>
                <b>NOTE: </b>Applicants whose studies were not in English medium
                should submit an attestation of proficiency in English which is
                internationally recognised, either an IELTS or a TOEFL score or
                similar.
              </p>
              {/* <div class="ui checkbox"> */}
              <input
                type="checkbox"
                name="check" 
                //value='check'
                 defaultChecked={application.attachAttest}
            
              />
              <label>
                Attached attestation of IELTS or TOEFL test or similar
              </label>
              {/* </div> */}
            </li>
          </ol> 
          <h3 class="subHeading">
            D. PAYMENT OF APPLICATION FEE – FOR MAURITIAN NATIONALS ONLY
          </h3>
          <ol >
            <li>
              A.For online application
              <br />
              {/* <div class="ui checkbox"> */}
              <input
                type="checkbox"
                name="check" 
                //value='check'
                defaultChecked={application.attachPaySlip}
              
              />
              <label>Attached proof of payment (transfer slip from bank)</label>
              {/* </div> */}
              <p>
                <b>NOTE: </b>&nbsp;Bank details of JSS Academy of Higher
                Education and Research (Mauritius) <br />
                <div class="spaceBetween">
                  <label>Name of Bank: &nbsp; Bank of Baroda</label>
                  <label>Branch: &nbsp; Head Office, Port Louis</label>
                </div>
                <div class="spaceBetween">
                  <label>Account No.:&nbsp;90310200004706</label>
                  <label>Swift Code:&nbsp; BARBMUMU</label>
                </div>
                <p>IBAN: &nbsp;MU33BARB02310002000047060 00MUR</p>
              </p>
            </li>
            <li>
              B.For direct application, submit bank draft together with the
              application form to JSS Academy of Higher Education and Research
              (Mauritius), Avenue Droopnath Ramphul, Bonne Terre, Vacoas.
              {/*  <div class="ui checkbox"> */}
              <input
                type="checkbox"
                name="check" 
                //value='check'
                defaultChecked={application.attachDrafte}
               
              />
              <label>
                Attached Bank Draft No.
                <div class="ui input inputContainer2">
                  <p class="inputField"  >{application?.bankDraftNo}</p>
                </div>{" "}
                for MUR 1,000 / USD 30 in favour of JSS Academy of Higher
                Education and Research (Mauritius)
              </label>
              {/*  </div> */}
            </li>
          </ol> 
          <h3 class="subHeading">E. DECLARATION OF APPLICANT</h3>
          <p>Please read and sign the following applicant certification. </p>
          <p>This application is not valid unless signed and dated.</p>
          <ol>
            <li>
             1. I,
              <div class="ui input inputContainer2">
                <p class="inputField" >{application?.agreementName}</p>
              </div>{" "}
              solemnly declare that if admitted to JSS Academy of Higher
              Education and Research (Mauritius), I will
              <ol style={{listStyleType:'none'}}>
                <li>
                 a. Diligently follow the Programme of Study for which I am
                  enrolled until its termination;
                </li>
                <li>
                 b. Inform the CEO, in writing and without delay, if I withdraw
                  from the Programme;
                </li>
                <li>
                 c. Conform to all the rules and regulations of JSS Academy of
                  Higher Education and Research (Mauritius);
                </li>
                <li>
                 d. Pay in advance all fees and dues required until the completion
                  of my studies;
                </li>
                <li>
                 e. Incur the cost of recovering any additional outstanding
                  balance due to JSS Academy of Higher Education and Research
                  (Mauritius);
                </li>
                <li>
                 f. Inform the Institution if I am suffering from any illness or
                  incapacity.
                </li>
              </ol>
            </li>
            <li>
             2. I acknowledge that JSS Academy of Higher Education and Research
              (Mauritius) reserves the right to seek information from any
              relevant bodies as to the standing of my claimed qualifications
              and experience and to reserve any decision regarding the admission
              made on the basis of incorrect or incomplete information.
            </li>
            <li>
             3. I do not have any physical disability / I have the following
              disabilities
              <div class="ui input inputContainer2">
                <p class="inputField" >{application?.disability}</p>
              </div>
            </li>
            <li>
             4. I acknowledge that the submission of incorrect or incomplete
              information may result in the withdrawal of any offer and / or
              cancellation of enrolment at any stage.
            </li>
            <li>
             5. I acknowledge that it is my responsibility to provide all relevant
              and required documentary evidence of my qualifications and
              experiences.
            </li>
            <li>
             6. I declare that the above information provided is true and correct.
            </li>
          </ol> 
          <h3 class="subHeading">F. APPLICATION CHECKLIST</h3>
          <ol>
            <li>
              {/* <div class="ui checkbox"> */}
              <input
                type="checkbox"
                label="Completed all relevant sections of this form"
                name="check" 
                //checked={application.compSection}
                //value={true}
                defaultChecked={application.compSection}
              />
              <label>1. Completed all relevant sections of this form</label>
              {/*  </div> */}
            </li>
            <li>
              {/* <div class="ui checkbox"> */}
              <input
                type="checkbox"
                label="Attached evidence of my name change (if applicable)"
                name="check" 
                //value='check'
               defaultChecked={application.attachedEvi}
              />
              <label>3. Attached evidence of my name change (if applicable)</label>
              {/*  </div> */}
            </li>
            <li>
              {/* <div class="ui checkbox"> */}
              <input
                type="checkbox"
                label="Attached certified copies of my qualifications"
                name="check" 
                //value='check'
               defaultChecked={application.attachedCert}
              />
              <label>2. Attached certified copies of my qualifications</label>
              {/*  </div> */}
            </li>
            <li>
              {/*  <div class="ui checkbox"> */}
              <input
                type="checkbox"
                label="Signed and dated the applicant declaration Section E above"
                name="check" 
                defaultChecked={application.appDeclaration}
              />
              <label>
                4. Signed and dated the applicant declaration Section E above
              </label>
              {/* </div> */}
            </li>
          </ol> 
          <Form.Field>
            Date:
            <div class="ui input inputContainer2">
              <input
                type="date"
                id="birthday"
                name="birthday"
                class="inputField"
                required 
                defaultValue={application?.todaydate}
             
              />
            </div>
            Applicants’ Signature:
            <div class="ui input inputContainer2">
            <img src={`https://${
                  Constants.DOMAIN
                }/${config.aws_org_id.toLowerCase()}-data/userdata/${application?.eid}/${application?.Signature}`}
                alt="" style={{height:'60px'}} />
            </div>
          </Form.Field> 
          <p>
            <b>Note:</b> JSS Academy of Higher Education and Research
            (Mauritius) reserves the right not to run the above programme.
          </p> 
        </Form>  
        <div style={{alignItems: 'center', paddingBottom: '5%' }}>
          <h4 className="dochead">10th marks card: </h4> 
          <img src={`https://${
                  Constants.DOMAIN
                }/${config.aws_org_id.toLowerCase()}-data/userdata/${application?.eid}/${application?.tenthdoc}`}
                alt=""  /> 
        </div>
        <div style={{alignItems: 'center', paddingBottom: '5%' }}>
          <h4 className="dochead">12th marks card: </h4> 
          <img src={`https://${
                  Constants.DOMAIN
                }/${config.aws_org_id.toLowerCase()}-data/userdata/${application?.eid}/${application?.tweledoc}`}
                alt=""  /> 
        </div>
        <div style={{alignItems: 'center', paddingBottom: '5%' }}>
          <h4 className="dochead">Degree marks card: </h4> 
          <img src={`https://${
                  Constants.DOMAIN
                }/${config.aws_org_id.toLowerCase()}-data/userdata/${application?.eid}/${application?.degreemarksdoc}`}
                alt=""  /> 
        </div>
        <div style={{alignItems: 'center', paddingBottom: '5%' }}>
          <h4 className="dochead">Master marks card: </h4> 
          <img src={`https://${
                  Constants.DOMAIN
                }/${config.aws_org_id.toLowerCase()}-data/userdata/${application?.eid}/${application?.mastersdoc}`}
                alt="" /> 
        </div>
        <div style={{alignItems: 'center', paddingBottom: '5%' }}>
          <h4 className="dochead">ID proof: </h4> 
          <img src={`https://${
                  Constants.DOMAIN
                }/${config.aws_org_id.toLowerCase()}-data/userdata/${application?.eid}/${application?.idproofdoc}`}
                alt="" /> 
        </div>
              </div>
      </Container>
      </div>);  
});
export default Application;