// Dependencies imports
import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import { API } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
// Style imports
// import Rec from "./Record.module.scss";
import { awsSignIn, authData, awsSignOut } from "../../redux/auth/authSlice";
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbar } from '@material-ui/data-grid';
// import ReferencePopUp from './ReferencePopUp';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Skeleton from "@material-ui/lab/Skeleton";

import Grad from './Grading.module.scss';
import Cont from './Content.module.scss';
import Ann from './Announcement.module.scss';

import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TablePagination from '@material-ui/core/TablePagination';

const header = {
    color: "white",
    fontFamily: "nunito",
    fontSize: "15px",
    fontWeight: "400px"
}

const programnames = {
    color: "black",
    fontFamily: "nunito",
    fontSize: "18px",
    fontWeight: "500px"
}

const programtype = {
    color: "black",
    fontSize: "16px",
    fontWeight: "400px",
    fontFamily: "nunito",
    paddingBotton: "10px"
}

// Styles for Tables
const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
        transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    root: {
        '& .super-app-theme--header': {
            backgroundColor: Constants.main_color_1,
            fontSize: "16px",
            color: 'rgb(255,255,255)',
            fontFamily: "nunito"
        },
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
        transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    ".MuiDataGrid-root": {
        fontSize: "14px",
        fontFamily: "nunito",
        fontWeight: 400,
        color: "black",
        cursor: "pointer"
    }
}));

// Styles for Pagination
const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}


// Start of RecordSession Component
const Feedback = (props) => {
    let {
        handleClose,
        setFeedbackQuestions,
        feedbackQuestions,
        indexValue,
        setIndexValue
    } = props;
    let userDetails = useSelector(authData);
    const [loading, setLoading] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [individualQuestion, setIndividualQuestion] = useState();
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = useState(0);
    const feedbackText = useRef();
    const [feedbackTextArray, setFeedbackTextArray] = useState();
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [openEdit, setOpenEdit] = useState(false);
    const [editUserData, setEditUserData] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        // alert(JSON.stringify(indexValue))
        getIndividualFeedback();
    }, []);

    async function getIndividualFeedback() {
        setLoading(true);
        const bodyParam = {
            body: {
                oid: config.aws_org_id,
                pid: userDetails.curprgcou.opid,
                bpid: userDetails.curprgcou.bpid,
            },
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        // alert(JSON.stringify(bodyParam.body));
        try {
            const response = await API.post(
                config.aws_cloud_logic_custom_name,
                Constants.INDIVIDUAL_FEEDBACK,
                bodyParam
            );
            // alert(JSON.stringify(response))
            setTableData(response.udata);
            setIndividualQuestion(response.questions);
            let trows = [];
            for (let i = 0; i < response.udata.length; i++) {
                // alert(JSON.stringify(response.udata))
                if (response.udata[i].response[indexValue] == 0 || response.udata[i].response[indexValue] == '0') {
                    feedbackText.current = "Strongly Agree";
                }
                if (response.udata[i].response[indexValue] == 1 || response.udata[i].response[indexValue] == '1') {
                    feedbackText.current = "Agree";
                }
                if (response.udata[i].response[indexValue] == 2 || response.udata[i].response[indexValue] == '2') {
                    feedbackText.current = "Disagree";
                }
                if (response.udata[i].response[indexValue] == 3 || response.udata[i].response[indexValue] == '3') {
                    feedbackText.current = "Strongly Disagree";
                }
                if (response.udata[i].response[indexValue] == 4 || response.udata[i].response[indexValue] == '4') {
                    feedbackText.current = "Not Applicable";
                }

                let obj = {};
                obj.id = i;
                obj.col1 = response.udata[i].UNAME;
                obj.col2 = feedbackText.current;
                trows.push(obj);
            }
            const tcolumns = [
                { field: 'col1', headerName: 'Student name', flex: 1, headerClassName: 'super-app-theme--header' },
                { field: 'col2', headerName: 'Feedback', flex: 1, headerClassName: 'super-app-theme--header' },

            ];
            setRows(trows);
            // alert(JSON.stringify(trows))
            setColumns(tcolumns);
            setLoading(false);
        }
        catch (error) {
            console.error(error);
        }
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer style={{ display: "flex", justifyContent: "flex-end" }}>
                <GridToolbar />
            </GridToolbarContainer>
        );
    }

    const handleCellClick = (param, event) => {
        console.log(param.row);
        setOpenEdit(true);
        setEditUserData(param.row);
        // console.log(event);
    }

    function tableview() {
        return (
            tableData.length >= 1 ?
                <TableBody>
                    {(rowsPerPage > 0
                        ? tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : tableData
                    ).map((item, index) => {
                        if (item.response[indexValue] == 0 || item.response[indexValue] == '0') {
                            feedbackText.current = "Strongly Agree";
                        }
                        if (item.response[indexValue] == 1 || item.response[indexValue] == '1') {
                            feedbackText.current = "Agree";
                        }
                        if (item.response[indexValue] == 2 || item.response[indexValue] == '2') {
                            feedbackText.current = "Disagree";
                        }
                        if (item.response[indexValue] == 3 || item.response[indexValue] == '3') {
                            feedbackText.current = "Strongly Disagree";
                        }
                        if (item.response[indexValue] == 4 || item.response[indexValue] == '4') {
                            feedbackText.current = "Not Applicable";
                        }
                        return (
                            <TableRow>
                                <TableCell style={programnames}>{item.UNAME}</TableCell>
                                <TableCell style={programtype}>
                                    <div>
                                        <p>{feedbackText.current}</p>
                                    </div>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
                :
                <TableBody>
                    <TableRow>
                        <TableCell style={programnames}></TableCell>
                        <TableCell style={programtype}><p style={{ paddingLeft: "25%" }}>No Data</p></TableCell>
                    </TableRow>
                </TableBody>
        );
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div>
            {loading === false ?
                <div
                    style={{
                        // display: "flex",
                        // height: "80%",
                        backgroundColor: "white",
                        width: "100%",
                    }}
                >

                    <div style={{ width: "100%" }}>
                        <span style={{
                            float: "right",
                            cursor: "pointer",
                            color: "black",
                            fontSize: "26px",
                            paddingRight: "20px",
                            paddingBottom: "50px",
                        }} onClick={() => { handleClose() }}>x</span>
                    </div>
                    <h2 style={{ marginLeft: "50px", paddingTop: "20px", paddingBottom: "20px" }}>Individual Feedback</h2>
                    <div style={{ marginLeft: "20px", marginRight: "20px" }}>
                        <div className={Grad.headingindividualQuestions}>
                            <p>{indexValue + 1}. {individualQuestion[indexValue]}</p>
                        </div>
                        {/* <div className={Ann.table}>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead style={{ background: Constants.main_color_1 }}>
                                        <TableRow>
                                            <TableCell style={header}>
                                                Student name
                                            </TableCell>
                                            <TableCell style={header}>Feedback</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {loading ? '' : tableview()}
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                                colSpan={2}
                                                count={tableData.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: { 'aria-label': 'rows per page' },
                                                    native: true,
                                                }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActions}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </div> */}
                        <div style={{ height: 400, width: '100%' }} className={classes.root}>
                            {loading === false ?
                                <DataGrid rows={rows} columns={columns} onCellClick={handleCellClick} className={classes['.MuiDataGrid-root']}
                                    components={{
                                        Toolbar: CustomToolbar,
                                    }}
                                    disableSelectionOnClick
                                /> : <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><Skeleton variant="rect" width="100%" height={600} /> </div>}
                        </div>
                    </div>
                </div> : <div>
                <CircularProgress
          style={{ position: "absolute", top: "48%", left: "48%" }}
        />
                </div>}
        </div>
    );
};

export default Feedback;