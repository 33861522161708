// Dependencies imports
import React, { useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";
import swal from 'sweetalert';
// Local imports
import moment from "moment";
import { Constants } from "../../../config/constants";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData, awsSignOut } from "../../../redux/auth/authSlice";
import config from "../../../config/aws-exports";
import { API } from "aws-amplify";
// Style imports
import Add from "./AddRecording.module.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent"
  },
}));

// Start of main Add Recording Component
const EditReference = (props) => {
  let { handleClose, reload, editData } = props;
  console.log("dt" + JSON.stringify(editData));
  const [spin, setSpin] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [timeStamp, setTimeStamp] = useState(editData.id);
  const [title, setTitle] = useState(editData.title);
  const [err, setErr] = useState("");
  // const [date, setDate] = useState(moment(editData.date).format("YYYY-MM-DD"));
  const [link, setLink] = useState(editData.link);
  // const [time, setTime] = useState(moment(editData.date).format("HH:mm"));
  const classes = useStyles();

  let userDetails = useSelector(authData);
  let lcourseDetails = userDetails.curprg;
  const onsubmit = (event) => {
    // setname(name)
    // alert(event.target.files[0]);


    setErr("");

    // if (date == undefined || date == "" || date === null) {
    //     setErr("Please Select date");
    //     return;
    // }
    // if (time == undefined || time == "" || time === null) {
    //     setErr("Please Select time");
    //     return;
    // }
    if (title == undefined || title.trim() === "") {

      setErr("Please Enter Title");
      return;
    }
    if (title != undefined && title.trim() != "" && title.trim().length > 100) {
      setErr("*Please Enter Event Title length less than 100 character");
      return;
    }
    if ((link == undefined || link.trim() == "") && isFilePicked == false && editData.otype == undefined) {
      setErr("Please Select a file or add a link");
      return;
    }
    let regex =
      "^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$";
    let result = new RegExp(regex, "i");
    if (link != undefined && link.trim() != "") {
      if (link === "" || !(result.test(link))) {
        setErr("Please Enter a valid link");
        return;
      }
    }

    if (isFilePicked === true) {
      let extend = selectedFile.name.substring(selectedFile.name.lastIndexOf('.') + 1, selectedFile.name.length) || selectedFile.name;
      console.log("hiiiiiiii" + extend)

      getPreSignedUrl(extend)
    }
    else {
      add();
    }





  };
  async function add(fname) {
    setSpin(true);
    let timestamp = new Date().getTime();

    // let d =date;
    // let st = time;
    // let dateTimeStart = new Date((d + " " + st).split(" ").join("T"));
    // dateTimeStart = dateTimeStart.getTime();

    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        cid: lcourseDetails.btid,
        action: "update",
        annjson: {
          id: editData.id,
          title: title,
          //   otype: "video",
          // date: dateTimeStart,


        },
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    if (editData.otype != undefined) {
      bodyParam.body.annjson.ourl = editData.ourl;
      bodyParam.body.annjson.fname = editData.fname;
      bodyParam.body.annjson.otype = editData.otype;
    }
    if (isFilePicked === true) {
      let type = "";
      let extend = selectedFile.name.substring(selectedFile.name.lastIndexOf('.') + 1, selectedFile.name.length) || selectedFile.name;
      if (extend == "JPG" || extend == "PNG" || extend == "JPEG" || extend == "GIF" || extend == "jpg" || extend == "png" || extend == "jpeg" || extend == "gif") {
        type = "image";
      } else if (extend == "mp4") {
        type = "video";
      } else if (extend == "mp3") {
        type = "audio";
      } else {
        type = "pdf";
      }
      bodyParam.body.annjson.otype = type;
      bodyParam.body.annjson.ourl = fname;
      bodyParam.body.annjson.fname = selectedFile.name;
      //   bodyParam.body.annjson.otype = "video";
    }
    if (link !== undefined && link.trim() != "") {
      bodyParam.body.annjson.link = link;
    }


    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.REFERENCES,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );
      // const users = response;
      reload();
      handleClose();
      swal({
        title: "Success",
        text: "Reference Edited successfully",
        icon: "success",
        dangerMode: false,
      })
        .then(willDelete => {
          if (willDelete) {
            handleClose();

          }
        });
      setSpin(false);

    } catch (error) {
      swal({
        title: "Oooops!",
        text: "something went wrong!",
        icon: "warning",
        dangerMode: true,
      })
        .then(willDelete => {
          if (willDelete) {

          }
        });
      console.log("getUserError", error);
    }
    console.log("Mount");
  }

  async function getPreSignedUrl(extend) {
    setSpin(true);
    let fname = timeStamp.toString() + "." + extend;
    const bodyParam = {
      body: {

        oid: config.aws_org_id,
        type: 'references',
        filetype: selectedFile.type,
        filename: fname


      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    console.log("presignedbody" + JSON.stringify(bodyParam.body));
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PRESIGNED_URL,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );


      fileUpload(selectedFile, response, fname);
      // setSpin(false);

    } catch (error) {
      swal({
        title: "Oooops!",
        text: "something went wrong!",
        icon: "warning",
        dangerMode: true,
      })
        .then(willDelete => {
          if (willDelete) {
            setSpin(false);
          }
        });
      console.log("getUserError", error);
    }
    console.log("Mount");
  }
  async function fileUpload(file, url, fname) {
    await axios
      .put(url, file, { headers: { "Content-Type": file.type } })
      .then((res) => {

        console.log(res);
        add(fname);

      })
      .catch((err) => {
        console.error(err);
      });
  }
  const onChangeHandlerTitle = event => {
    setTitle(event.target.value);
  };

  // const onChangeHandlerDate = event => {
  //     setDate(event.target.value);
  //     };
  //     const onChangeHandlerTime = event => {
  //         setTime(event.target.value);
  //     };
  const onChangeHandlerLink = event => {
    setLink(event.target.value);
  };

  const changeHandler = (event) => {
    console.log("1", event.target.files)
    console.log("2", event.target.files[0].name)
    setSelectedFile(event.target.files[0]);
    let tstamp = new Date().getTime();
    setTimeStamp(tstamp);
    // setAnnouncmentFile(event.target.files[0]);
    setIsFilePicked(true);
  };
  return (
    <div>
      {spin === false ?
        <div className={Add.mainpopup}>
          <h3 className={Add.header}>Edit</h3>
          <div className={Add.form}>
            <div className={Add.caution}>{err}</div>
            <form>
              {/* <div className={Add.fieldholder}>
                                <div clasName={Add.labelholder}>
                                    <h3 className={Add.label}>Date</h3>
                                </div>
                                <div className={Add.inputholder}>
                                    <div className={Add.caution}></div>
                                    <input type="date" className={Add.date} onChange={onChangeHandlerDate} value={date} /><br />
                                </div>
                            </div>
                            <div className={Add.fieldholder}>
                                <div clasName={Add.labelholder}>
                                    <h3 className={Add.label}>Time</h3>
                                </div>
                                <div className={Add.inputholder}>
                                    <div className={Add.caution}></div>
                                    <input type="time" className={Add.date} onChange={onChangeHandlerTime} value={time} /><br />
                                  
                                </div>
                            </div> */}
              <div className={Add.fieldholder}>
                <div className={Add.labelholder}>
                  <h3 className={Add.label} style={{ textAlign: "left" }}>Title</h3>
                </div>
                <div className={Add.inputholder}>
                  <div className={Add.caution}></div>
                  <textarea placeholder="Type your event here..." maxlength="160" rows={5} className={Add.area} onChange={onChangeHandlerTitle} value={title}  ></textarea>
                </div>
              </div>


              {/* <div className={Add.fieldholder}>
                                <div className={Add.labelholder}>

                                </div>
                                <div className={Add.inputholder}>
                                    <h4 className={Add.caution}>* Title should be less than 100 characters.</h4>
                                </div>
                            </div> */}
              <div className={Add.fieldholder}>
                <div clasName={Add.labelholder}>
                  <h3 className={Add.label}>Link</h3>
                </div>
                <div className={Add.inputholder}>
                  <input type="text" placeholder={"Add your link"} className={Add.date} onChange={onChangeHandlerLink} value={link}></input>
                  {/* <input type="file" id="file" name="file not selected" accept=".jpg,.png,.jpeg,.gif,.pdf,.mp4,.mp3" className={Add.browse} style={{  border: "1px solid #6a6a6a", borderRadius: "5px", height: "fit-content", width: "fit-content", padding: "0px 10px" }} onChange={changeHandler} /> */}
                </div>
              </div>
              <div className={Add.fieldholder}>
                <div clasName={Add.labelholder}>
                  <h3 className={Add.label}>Select Video</h3>
                </div>
                <div className={Add.inputholder}>
                  {/* <input type="text" placeholder={"Add your link"} className={Add.date}></input> */}
                  <input type="file" id="file" name="file not selected" accept=".jpg,.png,.jpeg,.gif,.pdf,.mp4,.mp3" className={Add.browse} onChange={changeHandler} />
                  Previous file : {editData.fname}
                </div>
              </div>
              <div className={Add.buttonholder}>
                <button className={Add.cancel} onClick={handleClose}>Cancel</button>
                <button className={Add.submit} onClick={onsubmit} >Submit</button>
              </div>
            </form>
          </div>
        </div> : <div className={classes.root}>
          <CircularProgress style={{ color: Constants.main_color_1 }} /></div>}
    </div>

  );

};

export default EditReference;

