import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { awsSignOut, authData, awsSignIn } from "../../../redux/auth/authSlice";
import { SignOut } from "../../../redux/auth/loginSlice";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import "./UserHeader.scss";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { Link, useNavigate } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const UserHeader = ({ Bindex }) => {
  const dispatch = useDispatch();
  let userDetails = useSelector(authData);
  const [anchorEl, setAnchorEl] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const classes = useStyles();
  let navigate = useNavigate();

  useEffect(() => {
    breadcrumb();
  }, []);

  function breadcrumb() {
    let breadcrumb = [];
    if (userDetails.breadcrumb !== undefined) {
      breadcrumb = { ...userDetails.breadcrumb };
    }
    if (Bindex != undefined) {
      let temp = [];
      for (let i = 0; i <= Bindex; i++) {
        temp.push(breadcrumb[i]);
      }
      if (temp !== undefined) {
        setBreadcrumbs(temp);
      }
      // alert(JSON.stringify(temp));
    }
    // alert(Bindex+JSON.stringify(breadcrumb))
  }

  function signingOut() {
    Auth.signOut();
    dispatch(awsSignOut());
    dispatch(SignOut());
    navigate("/");
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function onClickOfPage() {
    let sdata = { ...userDetails };
    sdata.bpidpstatus = false;

    // if (userDetails.breadcrumb[2].name === "complents") {
    //   //window.location.reload();
    //   console.log("True");
    // }

    // sdata.breadcrumb = temp;

    dispatch(awsSignIn(sdata));
  }

  return (
    <div className="super-container">
      <div className={classes.root}>
        {breadcrumbs !== undefined ? (
          <Breadcrumbs separator="›" aria-label="breadcrumb" style={{fontSize:"2.5rem"}}>
            {breadcrumbs.map((item) => {
              return (
                <Link className="bread" to={item.path} 
                 style={{fontSize:"17px"}}
                >
                  {item.name}
                </Link>
              );
            })}
            ;
          </Breadcrumbs>
        ) : null}
      </div>
      <div className="header">
        <div
          style={{ fontSize: "15px", color: "#333333", marginRight: "-12px" }}
        >
          {userDetails.name == undefined ? userDetails.email : userDetails.name}
        </div>
        <Avatar
          style={{ marginBottom: "-7px", cursor: "pointer" }}
          name="user"
          onClick={handleClick}
        />
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          style={{ marginTop: "45px", marginLeft: "-15px" }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            style={{ fontSize: "14px" }}
            // onClick={() => {
            //   onClickOfPage();
            //   navigate("/complaints");

            //   // window.location.reload();
            // }}
          >
            <Link
              onClick={() => {
                onClickOfPage();

                // window.location.href = '/PreloginComplaintes';
              }}
              style={{ color: "black", textDecoration: "none" }}
              to={`/complaints/complaints`}
            >
              Complaints
            </Link>
          </MenuItem>
          <MenuItem
            onClick={() => {
              signingOut();
            }}
            style={{ fontSize: "14px" }}
          >
            Signout
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default UserHeader;
