import React, { useEffect, useState, useRef } from "react";
import User from "./AddUsersTenant.module.scss";
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import { API } from "aws-amplify";
import swal from "sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import {  Checkbox } from "@material-ui/core";
import { authData } from "../../redux/auth/authSlice";
import {  useSelector } from "react-redux";
import { Close } from "@material-ui/icons";
import Select from '@material-ui/core/Select';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import Backdrop from "@material-ui/core/Backdrop";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent"
  },
  formControl: {
    border: "1px solid #6a6a6a",
    height: "fit-content",
    width: "100%",
    borderRadius: "5px",
    fontSize: "14px",
    fontFamily: "nunito",
    padding: "5px"
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  program: {
    fontSize: "16px",
    color: "black",
    fontWeight: "500",
    paddingLeft: "5px"
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const UserBulkUploadTenant = (props) => {
  let { handleClose, type, rows, rowdata } = props;
  const [spin, setSpin] = useState(false);
  const [emcheck, setEmcheck] = useState(false);
  const classes = useStyles();
  const [csvFile, setCsvFile] = useState("");
  const [csvArray, setCsvArray] = useState([]);
  const [fileErr, setFileErr] = useState();
  const [courseErr, setCourseErr] = useState();
  const [selectedCourseErr, setSelectedCourseErr] = useState();
  const [semErr, setSemErr] = useState();
  const [userData, setUserData] = useState([]);
  const [errorData, setErrorData] = useState([]);
  const [personName, setPersonName] = useState([]);
  const [topicIDs, setTopicsIds] = useState([]);
  const [totalelectives, setTotalElectives] = useState([]);
  const [header, setHeader] = useState("Assign Electives");
  const [seme, setSeme] = useState("");
  const [programDetail, setProgramDetail] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [oldselectedCourse, setOldSelectedCourse] = useState([]);
  const [saveButton, setSaveButton] = useState(false);
  let userDetails = useSelector(authData);
  const accRef = useRef();

  useEffect(() => {
    if (type == 'user') {
      let head = 'View Electives - ' + rowdata?.name;
      setHeader(head);
    }
    if (type == 'score') {
      setHeader('Upload Semester Score');
    }
    return () => { };
  }, []);

  const onsubmit = () => {
    setUserData([]);
    setErrorData([]);
    setFileErr("");
    setSemErr("");
    setCourseErr("");
    var temp = [];
    var temp2 = [];
    var etemp = [];
    var csvemail = [];
    if (seme === "" || seme.length === 0) {
      setSemErr("* Select Semester");
      return;
    }
    if (type != 'score' && (personName === "" || personName.length === 0)) {
      setCourseErr("* Select Course");
      return;
    }
    if (type != 'user' && (csvFile === undefined || csvFile === "")) {
      setFileErr("Please select csv file to upload");
      return;
    }
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (rows != undefined && rows.length > 0) {
      for (let index = 0; index < rows.length; index++) {
        etemp.push(rows[index].email);
      }
    }
    if (csvArray.length > 0 && type != 'score') {
      for (var i = 0; i < csvArray.length; i++) {
        if (

          csvArray[i].Email !== undefined &&
          csvArray[i].Email.replace(/\s/g, "") != "" &&
          pattern.test(csvArray[i].Email)
        ) {
          temp.push(csvArray[i]);
          csvemail.push(csvArray[i].Email);
        } else if (!etemp.includes(csvArray[i].Email)) {
          temp2.push(csvArray[i]);
          csvemail.push(csvArray[i].Email);
          setEmcheck(true);
        } else {
          csvemail.push(csvArray[i].Email);
          temp2.push(csvArray[i]);
        }
      }
    }
    if(type == 'score'){
      temp = csvArray;
    }
    setErrorData(temp2);
    if (type != 'user' && csvArray.length == 0) {
      setFileErr("File has no records");
      return;
    }

    if (temp2.length == 0) {
      if (type != "batch" && type != "score") {
        temp.push({ "Email": rowdata.email });
        Updatebatchuser(temp);
      } else if (type == 'score') {
        Updateuserscore(temp);
      } else {
       
        Updatebatchuser(temp);
      }
    }
  };

  async function Updatebatchuser(afile) {

    setSpin(true);
    var eles = [];
    if (selectedCourse.length > 0) {
      for (var i = 0; i < selectedCourse.length; i++) {
        eles.push(selectedCourse[i].btid);
      }
    }
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        jsonfile: afile,
        selectedelectives: eles,
        action: "add",
        bpid: userDetails.curprgcou.bpid,
        totalelectives: totalelectives,
        oldelectives: oldselectedCourse,
        bpid: userDetails.curprgcou.bpid,
        pid: userDetails.curprgcou.pid,
        sem: seme
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
  
    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        `/addBulkElectiveUsers`,
        bodyParam
      );

      swal({
        title: "Success",
        text: "Electives assigned successfully",
        icon: "success",
        dangerMode: false,
      }).then((willDelete) => {
        if (willDelete) {
          setSpin(false);
          window.location.reload("./users");
        }
      });
      setSpin(true);
      handleClose();

    } catch (error) {
      console.error(error);
      swal({
        title: "Oooops!",
        text: "Something went wrong!",
        icon: "warning",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          swal("Contact Developer");
          setSpin(false);
        }
      });
    }
  }
  async function Updateuserscore(afile) {

    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        jsonfile: afile,
        action: "add",
        bpid: userDetails.curprgcou.bpid,
        pid: userDetails.curprgcou.pid,
        sem: seme
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        `/addBulkUsersScore`,
        bodyParam
      );

      swal({
        title: "Success",
        text: "Scores updated successfully",
        icon: "success",
        dangerMode: false,
      }).then((willDelete) => {
        if (willDelete) {
          setSpin(false);
          window.location.reload("./users");
        }
      });
      setSpin(true);
      handleClose();

    } catch (error) {
      console.error(error);
      swal({
        title: "Oooops!",
        text: "Something went wrong!",
        icon: "warning",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          swal("Contact Developer");
          setSpin(false);
        }
      });
    }
  }
  function isHTML(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "csv":
        return true;
      default:
        return false;
    }
  }
  function getExtension(filename) {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  }
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (e.target.files[0] === undefined) {
      return;
    }
    if (!isHTML(e.target.files[0].name)) {
      swal("Oops!", "Select correct file format..!", "error");
      return;
    }
    setCsvFile(file);
    const reader = new FileReader();
    reader.onload = (e) => {
      const xd = e.target.result;

      var array = CSVToArray(xd);
      var objArray = [];
      for (var i = 1; i < array.length; i++) {
        objArray[i - 1] = {};
        for (var k = 0; k < array[0].length && k < array[i].length; k++) {
          var key = array[0][k];
          if (array[i][k] != undefined) {
            objArray[i - 1][key] =
              key == "Email" ? array[i][k].toLowerCase() : array[i][k];
          }
        }
      }
      var filteredarray;
      if (type != "batch") {
        filteredarray = objArray.filter((item) => item.Email !== undefined && item.Email.trim() !== "");
      } else {
        filteredarray = objArray.filter(
          (key) =>
            key.Email != undefined &&
            key.Email.replace(/\s/g, "") != ""
        );
      }
      setCsvArray(filteredarray);
    };
    reader.readAsText(file);
  };

  const CSVToArray = function (strData, strDelimiter) {
    strDelimiter = strDelimiter || ",";

    var objPattern = new RegExp(
      "(\\" +
      strDelimiter +
      "|\\r?\\n|\\r|^)" +
      '(?:"([^"]*(?:""[^"]*)*)"|' +
      '([^"\\' +
      strDelimiter +
      "\\r\\n]*))",
      "gi"
    );

    var arrData = [[]];

    var arrMatches = null;

    while ((arrMatches = objPattern.exec(strData))) {
      var strMatchedDelimiter = arrMatches[1];

      if (strMatchedDelimiter.length && strMatchedDelimiter != strDelimiter) {
        arrData.push([]);
      }

      if (arrMatches[2]) {
        var strMatchedValue = arrMatches[2].replace(new RegExp('""', "g"), '"');
      } else {
        // We found a non-quoted value.
        strMatchedValue = arrMatches[3].length == 0 ? "" : arrMatches[3];
      }

      arrData[arrData.length - 1].push(strMatchedValue);
    }

    return arrData;
  };
  const onChangeHandlerSem = event => {
    if (type != 'score') {
      accRef.current = true;
      setSeme(event.target.value);
      getProgDetails(event.target.value);
    } else {
      setSeme(event.target.value);
    }
    
  };
  async function getProgDetails(sem) {
    const bodyParam = {
      body: {
        bpid: userDetails.curprgcou.bpid,
        oid: config.aws_org_id,
        pid: userDetails.curprgcou.pid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    if (
      userDetails.role == "Instructors" ||
      userDetails.role == "Coordinators"
    ) {
      bodyParam.body.eid = userDetails.eid;
    }
    try {

      const PDetails = await API.post(
        config.aws_cloud_logic_custom_name,
        `/getProgramsDetails`,
        bodyParam
      );
      accRef.current = false;
      const topicIDs = [];
      const telectives = [];
      const selectedelectives = [];
      const oldselectedelectives = [];
      for (const iterator of PDetails.pcurriculum) {
        if (iterator.semester == sem && iterator.telective == true) {
          topicIDs.push({ "btid": iterator.btid, "ttitle": iterator.ttitle });
          telectives.push(iterator.btid);
          if (rowdata?.electives != null && rowdata?.electives?.includes(iterator.btid)) {
            selectedelectives.push(iterator.ttitle);
            oldselectedelectives.push(iterator.btid);
          }
        }
      }

      setOldSelectedCourse(oldselectedelectives);
      setTotalElectives(telectives);
      setTopicsIds(topicIDs);
      setPersonName(selectedelectives);
      setProgramDetail(topicIDs);
      
      if (topicIDs.length == 0) {
        setCourseErr("There are no elective courses for this semester!");
      } else {
        setCourseErr('');
      }
      if (telectives.length > 0 && selectedelectives.length == 0) {
        setSelectedCourseErr("There are no elective courses assigned to this user!");
      } else {
        setSelectedCourseErr('');
      }
    
    } catch (error) {
      console.error(error);
    }
  }
  const handleChange = (event) => {
    let selectArray = event.target.value;

    let temparr = [];
    for (let i = 0; i < programDetail.length; i++) {
      let obj = {};

      obj.btid = programDetail[i].btid;
      obj.ttitle = programDetail[i].ttitle;
      for (let k = 0; k < selectArray.length; k++) {
        if (obj.ttitle == selectArray[k]) {
         
          temparr.push(obj);
        }

      }

    }
   
    setSelectedCourse(temparr);
    setPersonName(event.target.value);
  };
  async function downloadcsv() {
    let response = '';
    if (type != 'score' && type != 'user') {
      response = await fetch(
        `https://${Constants.DOMAIN
        }/${config.aws_org_id.toLowerCase()}-resources/formats/electiveuserlist.csv`
      );
    } else {
      response = await fetch(
        `https://${Constants.DOMAIN
        }/${config.aws_org_id.toLowerCase()}-resources/formats/finaluserlist.csv`
      );
    }
    const data = await response.text();
    const blob = new Blob([data], { type: "data:text/csv;charset=utf-8," });
    const url = window.URL.createObjectURL(blob);
    let dwldLink = document.createElement("a");
    let isSafariBrowser =
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("Chrome") == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", "userlist.csv");
    if(type =='score' ){
      dwldLink.setAttribute("download", "finaluserlist.csv");
    }
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
  const onedit = () => {
    setSaveButton(true);
    setCourseErr('');
    setSelectedCourseErr('');
  }
  return (
    <div className={User.maindiv}>

      {spin === false ? (
        <div className={User.maincontainer}>
          <div className={User.iconcontainer}>
            <h3 className={User.create}>{header}</h3>

            <div>
              {type != 'user' ? <a
                style={{ fontSize: "13px", cursor: "pointer", color: "blue", marginRight: "20px" }}
                onClick={downloadcsv}
              >
                Download Format
              </a> : null}
              <Close className={User.closebutton} onClick={handleClose} />
            </div>
          </div>

          <div className={User.form}>
            <div
              style={{
                color: "#cc0000",
                marginBottom: "12px",
                marginTop: "-10px",
                textAlign: "start",
                fontSize: "13px",
              }}
            >
              <p>{semErr}</p>
            </div>
            <div className={User.field}>
              <h3 className={User.select}>
                Select Semester
              </h3>
              <select style={{ border: "none", width: "20%", boxShadow: "0px 0px 5px 0px lightgrey", background: "white", height: "30px", marginBottom: "20px", fontSize: "15px", paddingLeft: "10px", fontWeight: "200px" }}
                name="sem" id="sem" required onChange={onChangeHandlerSem}>
                <option value="0">Select Semester</option>
                <option value="1">Semester 1</option>
                <option value="2">Semester 2</option>
                <option value="3">Semester 3</option>
                <option value="4">Semester 4</option>
                <option value="5">Semester 5</option>
                <option value="6">Semester 6</option>
              </select>
            </div>
            <div
              style={{
                color: "#cc0000",
                marginBottom: "12px",
                marginTop: "-10px",
                textAlign: "start",
                fontSize: "13px",
              }}
            >
              <p>{courseErr}</p>
            </div>
            {saveButton ? <div className={User.field}>

              <h3 className={User.select}>
                Select Elective Courses
              </h3>
              <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                style={{ border: "none", width: "20%", boxShadow: "0px 0px 5px 0px lightgrey", background: "white", height: "30px", marginBottom: "20px", fontSize: "15px", paddingLeft: "10px", fontWeight: "200px" }}
                multiple
                value={personName}
                onChange={handleChange}
                input={<Input />}
                renderValue={(selected) => {
                  selected.join(', ');

                  let temp = [];
                  for (let i = 0; i < selected.length; i++) {
                    temp.push(selected[i]);
                  }
                  return temp;
                }}
                MenuProps={MenuProps}
              >
                {topicIDs.map((item) => (
                  <MenuItem key={item} value={item.ttitle}>
                    <Checkbox checked={personName.indexOf(item.ttitle) > -1} />
                    <ListItemText primary={item.ttitle} />
                  </MenuItem>
                ))}

              </Select>
            </div> : null}
            {type != 'user' && type !== 'score' ? <div className={User.field}>

              <h3 className={User.select}>
                Select Elective Courses
              </h3>
              <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                style={{ border: "none", width: "20%", boxShadow: "0px 0px 5px 0px lightgrey", background: "white", height: "30px", marginBottom: "20px", fontSize: "15px", paddingLeft: "10px", fontWeight: "200px" }}
                multiple
                value={personName}
                onChange={handleChange}
                input={<Input />}
                renderValue={(selected) => {
                  selected.join(', ');

                  let temp = [];
                  for (let i = 0; i < selected.length; i++) {
                    temp.push(selected[i]);
                  }
                  return temp;
                }}
                MenuProps={MenuProps}
              >
                {topicIDs.map((item) => (
                  <MenuItem key={item} value={item.ttitle}>
                    <Checkbox checked={personName.indexOf(item.ttitle) > -1} />
                    <ListItemText primary={item.ttitle} />
                  </MenuItem>
                ))}

              </Select>
            </div> : null}
            {type == 'user' && type !== 'score' ? <div>
              <h3> Selected Elective Courses </h3>
              <div style={{ textAlign: "start", padding: "12px", fontSize: "14px" }}>
              <div
              style={{
                color: "#cc0000",
                marginBottom: "12px",
                marginTop: "-10px",
                textAlign: "start",
                fontSize: "13px",
              }}
            >
              <p>{selectedCourseErr}</p>
            </div>
                {personName.length > 0 ? <ol>
                  {personName.map((item) => <li>{item}</li>)}
                </ol> : null}
              </div>
            </div> : null}
            <div
              style={{
                color: "#cc0000",
                marginBottom: "12px",
                marginTop: "-10px",
                textAlign: "start",
                fontSize: "13px",
              }}
            >
              <p>{fileErr}</p>
            </div>
            {type != 'user' ? <div
              className={User.field}
              style={{
                border: "1px solid gray",
                padding: "50px",
                display: "block",
              }}
            >
              <input
                type="file"
                id="files"
                accept=".csv"
                onChange={(e) => handleFileUpload(e)}
              />
            
            </div> : null}

            <br></br>

            {userData.length > 0 ? (
              <>
                {type != "batch" ? (
                  <p style={{ fontSize: "14px", textAlign: "left" }}>
                    These users are already exist in portal. Please remove from
                    file and upload again.
                  </p>
                ) : (
                  <p style={{ fontSize: "14px", textAlign: "left" }}>
                    These users are already exist in batch. Please remove from
                    file and upload again.
                  </p>
                )}
                <div
                  style={{
                    height: "200px",
                    overflow: "scroll",
                    marginTop: "10px",
                  }}
                >
                  {type != "batch" ? (
                    <table style={{ fontSize: "14px", width: "100%" }}>
                      <thead style={{ backgroundColor: "aliceblue" }}>
                        <th>Firstname</th>
                        <th>Lastname</th>
                        <th>Email</th>
                        <th>Address</th>
                        <th>Contanct No</th>
                        <th>Gender</th>
                      </thead>
                      <tbody>
                        {userData.map((item, i) => (
                          <tr key={i}>
                            <td>{item.Firstname}</td>
                            <td>{item.lastname}</td>
                            <td>{item.ADS}</td>
                            <td>{item.CNO}</td>
                            <td>{item.GEN}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <table style={{ fontSize: "14px", width: "100%" }}>
                      <thead style={{ backgroundColor: "aliceblue" }}>
                        <th>Firstname</th>
                        <th>Lastname</th>
                        <th>Email</th>
                      </thead>
                      <tbody>
                        {userData.map((item, i) => (
                          <tr key={i}>
                            <td>{item.first_name}</td>
                            <td>{item.last_name}</td>
                            <td>{item.EMAILID}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </>
            ) : null}
            {errorData.length > 0 ? (
              <>
                {emcheck ? (
                  <p style={{ fontSize: "14px", textAlign: "left" }}>
                    Below are the already existed users please remove from file
                    and upload again.
                  </p>
                ) : (
                  <p style={{ fontSize: "14px", textAlign: "left" }}>
                    Below are the invalid entries please remove from file and
                    upload again.
                  </p>
                )}

                <div
                  style={{
                    height: "200px",
                    overflow: "scroll",
                    marginTop: "10px",
                  }}
                >
                  {type != "batch" ? (
                    <table style={{ fontSize: "14px", width: "100%" }}>
                      <thead style={{ backgroundColor: "aliceblue" }}>
                        <th>FirstName</th>
                        <th>LastName</th>
                        <th>Email</th>
                        <th>Contact No</th>
                        <th>Gender</th>
                        <th>Address</th>
                        <th>Role</th>
                      </thead>
                      <tbody>
                        {errorData.map((item, i) => (
                          <tr key={i}>
                            <td>{item.Firstname || item.firstname}</td>
                            <td>{item.Lastname || item.lastname}</td>
                            <td>{item.Email || item.email}</td>
                            <td>{item.Contactno || item.contactno}</td>
                            <td>{item.gender}</td>
                            <td>{item.address}</td>
                            <td>{item.role || item.Role}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <table style={{ fontSize: "14px", width: "100%" }}>
                      <thead style={{ backgroundColor: "aliceblue" }}>
                        <th>Name</th>
                        <th>Email</th>
                      </thead>
                      <tbody>
                        {errorData.map((item, i) => (
                          <tr key={i}>
                            <td>{item.Firstname}</td>
                            <td>{item.LastName}</td>
                            <td>{item.Email}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </>
            ) : null}
            <br></br>
            {saveButton && type == 'user' ? <input
              type="submit"
              value="Save"
              onClick={onsubmit}
              className={
                window.navigator.onLine === true
                  ? User.btn_color
                  : User.btn_colordis
              }
              disabled={!window.navigator.onLine}
            ></input> : null
            }
            {type != 'user' ?
              <input
                type="submit"
                value="Save"
                onClick={onsubmit}
                className={
                  window.navigator.onLine === true
                    ? User.btn_color
                    : User.btn_colordis
                }
                disabled={!window.navigator.onLine}
              ></input> : null}
            {type == 'user' && !saveButton ? <input
              type="submit"
              value="Edit"
              onClick={onedit}
              className={
                window.navigator.onLine === true
                  ? User.btn_color
                  : User.btn_colordis
              }
              disabled={!window.navigator.onLine}
            ></input> : null}
          </div>
          <Backdrop sx={{ color: "#fff" }} open={accRef.current} className={classes.backdrop}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      ) : (
        <div className={classes.root}>
          <CircularProgress style={{ color: config.main_color_1 }} />
        </div>
      )}
    </div>
  );
};

export default UserBulkUploadTenant;
