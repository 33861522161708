// Dependencies imports
import React, { useState, useEffect } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
} from "@material-ui/data-grid";
import { authData } from "../../redux/auth/authSlice";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/styles";

// Local imports

// Styles imports
import Assesg from "./AttendanceGrid.module.scss";
import { useSelector } from "react-redux";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";

import { API } from "aws-amplify";

const useStyles = makeStyles({
  root: {
    "& .super-app-theme--header": {
      backgroundColor: Constants.main_color_1,
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
  },
});

// Data for Faculty Grid
const columns = [
  {
    field: "col1",
    headerName: "Student Name",
    width: 300,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "col2",
    headerName: "Candiate id ",
    width: 300,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "col3",
    headerName: "Email id",
    width: 300,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "col4",
    headerName: "Country name",
    width: 300,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "col5",
    headerName: "Total modules active (All Courses)",
    width: 300,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "col6",
    headerName: "Total modules completed in  numbers (All Courses)",
    width: 400,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "col7",
    headerName: "Total modules completed in percentage (All Courses)",
    width: 400,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "col8",
    headerName: "Active assignments (All Courses)",
    width: 300,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "col9",
    headerName: "Submitted assignments (All Courses)",
    width: 300,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "col10",
    headerName: "Average Assignment marks (All Courses)",
    width: 300,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "col11",
    headerName: "Assessment 1 (All Courses)",
    width: 300,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "col12",
    headerName: "Assessment 2 (All Courses)",
    width: 300,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "col13",
    headerName: "Assessment 3 (All Courses)",
    width: 300,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "col14",
    headerName: "Average of Assessment (best of two) (All Courses)",
    width: 400,
    headerClassName: "super-app-theme--header",
  },
];

// Start of Faculty Grid Component
const ReportGrid = () => {
  const [rows, setRows] = useState([]);
  const [topicsIds, setTopicsIds] = useState();
  const [loading, setLoading] = useState(true);
  const classes = useStyles();


  let userDetails = useSelector(authData);

  useEffect(() => {
    getProgDetails("1");
    return () => {};
  }, []);

  async function getProgDetails(sem) {
    const bodyParam = {
      body: {
        bpid: userDetails.curprgcou.bpid,
        oid: config.aws_org_id,
        pid: userDetails.curprgcou.pid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    if (
      userDetails.role == "Instructors" ||
      userDetails.role == "Coordinators"
    ) {
      bodyParam.body.eid = userDetails.eid;
    }
    try {
      const PDetails = await API.post(
        config.aws_cloud_logic_custom_name,
        `/getProgramsDetails`,
        bodyParam
      );

      const topicIDs = [];
      for (const iterator of PDetails.pcurriculum) {
        if (iterator.semester == sem) {
          topicIDs.push(iterator.btid);
        }
      }

      setTopicsIds(topicIDs);
      getReports(topicIDs);
    } catch (error) {
      console.error(error);
    }
  }

  async function getReports(topicIDs) {
    setLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        bpid: userDetails.curprgcou.bpid,
        topicids: topicIDs,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_MY_REPORTS,
        bodyParam
      );
     
      if (response === null || response === undefined) {
        setLoading(false);
      }
      let trows = [];
      for (let i = 0; i < response.length; i++) {
        
        let obj = {};
        obj.id = i;
        obj.col1 = response[i].UNAME;
        if (
          response[i].USN !== undefined &&
          response[i].USN[userDetails.curprgcou.bpid] !== undefined
        ) {
          obj.col2 = response[i].USN[userDetails.curprgcou.bpid].usn;
        }
        obj.col3 = response[i].EMAILID;
        obj.col4 = response[i].country;
        obj.col5 = response[i].totalActiveModule;
        obj.col6 = response[i].totalCompletedModule;
        obj.col7 = parseFloat(response[i].totalCompletedModulePercent).toFixed(
          2
        );
        obj.col8 = response[i].totalMiniassignments;
        obj.col9 = response[i].totalCompletedMiniassignment;
        obj.col10 =
          response[i].miniAverageScore != null
            ? parseFloat(response[i].miniAverageScore).toFixed(2)
            : "Not graded";
        obj.col11 =
          response[i].assesment1 != null
            ? parseFloat(response[i].assesment1).toFixed(2)
            : "Not Attended";
        obj.col12 =
          response[i].assesment2 != null
            ? parseFloat(response[i].assesment2).toFixed(2)
            : "Not Attended";
        obj.col13 =
          response[i].assesment3 != null
            ? parseFloat(response[i].assesment3).toFixed(2)
            : "Not Attended";
        let average = [];
        average.push(response[i].assesment1);
        average.push(response[i].assesment2);
        average.push(response[i].assesment3);
        average.sort();
        obj.col14 = parseFloat((average[0] + average[1]) / 2).toFixed(2);
        trows.push(obj);
      }
      setRows(trows);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={{
            fileName: `Report-${userDetails.curprgcou.bname}-${userDetails.curprgcou.pname}`,
          }}
        />
      </GridToolbarContainer>
    );
  }

  const onChangeHandlerSem = (event) => {
    getProgDetails(event.target.value);
  };
  
  return (
    <div className={Assesg.maindiv}>
      <div className={Assesg.leftside}>
        <h3 className={Assesg.select}>Select Semester</h3>
        <select
          style={{
            border: "none",
            width: "20%",
            boxShadow: "0px 0px 5px 0px lightgrey",
            background: "white",
            height: "30px",
            marginBottom: "20px",
            fontSize: "15px",
            paddingLeft: "10px",
            fontWeight: "200px",
          }}
          name="sem"
          id="sem"
          required
          onChange={onChangeHandlerSem}
        >
          <option value="1">Semester 1</option>
          <option value="2">Semester 2</option>
          <option value="3">Semester 3</option>
          <option value="4">Semester 4</option>
          <option value="5">Semester 5</option>
          <option value="6">Semester 6</option>
        </select>
      </div>
      {loading === false ? (
        <div style={{ height: 500, width: "100%" }} className={classes.root}>
          <DataGrid
            rows={rows}
            columns={columns}
            className={classes[".MuiDataGrid-root"]}
            components={{
              Toolbar: CustomToolbar,
            }}
            disableSelectionOnClick
          />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            paddingTop: "20px",
            height: "100%",
          }}
        >
          <Skeleton variant="rect" width="100%" height={400} />{" "}
        </div>
      )}
    </div>
  );
};

export default ReportGrid;
