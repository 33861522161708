// Dependencies imports
import React, { useState } from 'react';
import swal from 'sweetalert';
import Paper from '@material-ui/core/Paper';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

// Local imports

// Styles imports
import Add from './AddEvent.module.scss';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        heigth: "100%",
        alignItems: "center",
        justifyContent: "center",
        background: "transparent"
    },
}));

const AddEvents = (props) => {
    let { eventData, handleClose, getEvents } = props;
    const [state, setState] = useState(false);
    const [eventDate, setEventDate] = useState("");
    const [evnentName, setEventName] = useState("");
    const [eventLink, setEventLink] = useState("")
    const [eventId, setEventId] = useState("");
    const [eventDateErr, setEventDateErr] = useState("");
    const [eventNameErr, setEventNameErr] = useState("");
    const [spin, setSpin] = useState(false);
    const classes = useStyles();

    let userDetails = useSelector(authData);


    const handleChange = (event) => {
        setState(state === false ? true : false);
    };
    const onChangeHandlerEventName = event => {
        setEventName(event.target.value);
    };
    const onChangeHandlerEventDate = event => {
        setEventDate(event.target.value);
    };
    const onChangeHandlerEventLink = event => {
        setEventLink(event.target.value);
    };

    const onsubmit = () => {
        // setname(name)
        let formIsValid = true;
        let neweid;
        setEventDateErr("");
        setEventNameErr("");
        if (eventDate === "") {
            formIsValid = false;
            setEventDateErr("Please Enter Date");
            return;
        }
        if (evnentName.trim() === "") {
            formIsValid = false;
            setEventNameErr("*Please Enter Event description");
            return;

        }
       
        if (evnentName.trim() != "" && evnentName.trim().length > 160) {
            formIsValid = false;
            setEventNameErr("*Please Enter Event description length less than 160 character");
            return;
        }
        if (formIsValid === true) {
            if (eventData.length === 0 || eventData.length === undefined) {
                // setEventId(1);
                neweid = 1;
            }
            else {
                neweid = eventData[eventData.length - 1].eid + 1;

            }
            addEvent(neweid);
        }
        
    };

    async function addEvent(id) {
        setSpin(true);
        let timestamp = new Date().getTime();
        const bodyParam = {
            body: {
                annjson: {
                    eid: timestamp,
                    start: eventDate,
                    title: evnentName,
                    link: eventLink,
                },
                rtype: "put",
                oid: config.aws_org_id,
                bpid: userDetails.curprgcou.bpid,
                action: "events",

            },
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };

        try {
            const response = await API.post(
                config.aws_cloud_logic_custom_name,
                Constants.GET_EVENTS,
                bodyParam
                //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
            );
            handleClose();
            // const users = response;
            swal({
                title: "Success",
                text: "Event Added successfully",
                icon: "success",
                dangerMode: false,
            })
                .then(willDelete => {
                    if (willDelete) {
                        handleClose();
                        
                    }
                });
                setSpin(false);
                getEvents();
        } catch (error) {
            swal({
                title: "Oooops!",
                text: "something went wrong!",
                icon: "warning",
                dangerMode: true,
            })
                .then(willDelete => {
                    if (willDelete) {
                        setSpin(false);
                    }
                });
            console.log("getUserError", error);
        }
        console.log("Mount");
    }

    return (
        <div>
            {spin === false ?
                <div className={Add.mainpopup}>
                    <h3 className={Add.header}>Add Event</h3>
                    <div className={Add.form}>
                        <form>
                            <div className={Add.fieldholder}>
                                <div clasName={Add.labelholder}>
                                    <h3 className={Add.label}>Event Date</h3>
                                </div>
                                <div className={Add.inputholder}>
                                    <div style={{ color: "#cc0000", marginBottom: "12px", marginTop: "-10px", textAlign: "start", marginLeft: "20%" }}>{eventDateErr}</div>
                                    <input type="date" className={Add.date} onChange={onChangeHandlerEventDate} value={eventDate} /><br />
                                </div>
                            </div>
                            <div className={Add.fieldholder}>
                                <div className={Add.labelholder}>
                                    <h3 className={Add.label}>Event Description</h3>
                                </div>
                                <div className={Add.inputholder}>
                                    <div style={{ color: "#cc0000", marginBottom: "12px", marginTop: "-10px", textAlign: "start", marginLeft: "20%" }}>{eventNameErr}</div>
                                    <textarea placeholder="Type your event here..." maxlength="160" rows={5} className={Add.area} onChange={onChangeHandlerEventName} value={evnentName} ></textarea>
                                </div>
                            </div>
                            <div className={Add.fieldholder}>
                                <div className={Add.labelholder}>

                                </div>
                                <div className={Add.inputholder}>
                                    <h4 className={Add.caution}>* Event description should be less than 160 characters.</h4>
                                </div>
                            </div>
                            <div className={Add.fieldholder}>
                                <div clasName={Add.labelholder}>
                                    <h3 className={Add.label}>Event Link</h3>
                                </div>
                                <div className={Add.inputholder}>
                                    <input type="text" placeholder={"Add your link"} className={Add.date} onChange={onChangeHandlerEventLink} value={eventLink} ></input>
                                </div>
                            </div>
                            <div className={Add.buttonholder}>
                                <button className={Add.cancel} onClick={handleClose}>Cancel</button>
                                <button className={Add.submit} onClick={onsubmit} >Submit</button>
                            </div>
                        </form>
                    </div>
                </div> : <div className={classes.root}>
                    <CircularProgress style={{ color: Constants.main_color_1 }} /></div>}
        </div>

    );
};

export default AddEvents;