// Dependencies imports
import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbarContainer, GridToolbar } from '@material-ui/data-grid';
import { API } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/styles';
import { useNavigate } from "react-router-dom";

// Local imports

import { awsSignIn, authData } from "../../redux/auth/authSlice";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";

// Styles imports
import Assesg from './AssesmentGrid.module.scss';
import UserBulkUploadTenant from './UserBulkUploadTenant';
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import UserBulkRegistartion from './UserBulkRegistration';

// Data for User Stats Grid 

const useStyles = makeStyles({
    root: {
        '& .super-app-theme--header': {
            background: Constants.main_color_1,
            fontSize: "16px",
            color: 'rgb(255,255,255)',
            fontFamily: "nunito"
        },
    },
    ".MuiDataGrid-root": {
        fontSize: "14px",
        fontFamily: "nunito",
        fontWeight: 400,
        color: "black",
        cursor: "pointer"
    }
});

// Start of Instructor Grid Component
const UserStatsGrid = (props) => {
    let { userData, selectionModel, setSelectionModel, handleClose,allTests } = props;
    const classes = useStyles();
    const [openBulk, setOpenBulk] = useState(false);
    const [openBulkRegister, setOpenBulkRegister] = useState(false);
    const [rowdata, setRowdata] = useState(null);

    let userDetails = useSelector(authData);
    let navigate = useNavigate();
    const dispatch = useDispatch();

    // function for toolbar options above datagrid
    function CustomToolbar() {
        return (
            <GridToolbarContainer style={{ display: "flex", justifyContent: "flex-end" }}>
                <GridToolbar />
            </GridToolbarContainer>
        );
    }
    // Useeffect for faculty details api
    useEffect(() => {
        return () => { };
    }, []);

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            width: 300,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            editable: true,
            renderCell: (param) => {
                return (

                    <h4
                        onClick={() => {
                            handleCellClick(param);
                        }}
                        className={Assesg.head}
                    >
                        {param.value}
                    </h4>

                );
            },
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 300,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            editable: true,
            renderCell: (param) => {
                return (

                    <h4
                        onClick={() => {
                            handleCellClick(param);
                        }}
                        className={Assesg.head}
                    >
                        {param.value}
                    </h4>

                );
            },
        },

        {
            field: 'usn',
            headerName: 'Candidate Id',
            width: 300,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            editable: true
        },

        {
            field: 'eid',
            headerName: 'eid',
            width: 300,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            editable: true
        },
        {
            field: 'electives',
            headerName: 'ELECTIVES',
            width: 300,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            editable: true,
            renderCell: (params) => (
                <button
                    className={Assesg.notification}
                    onClick={() => {
                        handlePublishOpen(params);
                    }}
                >
                    View
                </button>
            ),
        },
        {
            field: 'LA',
            headerName: 'Last Active',
            width: 300,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            editable: true
        },
        {
            field: 'wbid',
            headerName: 'Assign Tests',
            width: 100,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            editable: true,
            renderCell: (params) => (
                params.value ? (
                    <button
                        className={Assesg.notification}
                        onClick={() => {
                            handleRegisterOpen(params);
                        }}
                    >
                        View 
                    </button>
                ) : null
            ),
        }
    ];
    function handlePublishOpen(item) {

        setRowdata(item.row);
        setOpenBulk(true);
    }
    function handleRegisterOpen(item) {

        setRowdata(item.row);
        setOpenBulkRegister(true);
    }
    function handleRegisterClose() {

        setOpenBulkRegister(false);
    }
    const handleCellClick = (param) => {
        
        let sdata = { ...userDetails };
        sdata.selbatchuser = { ...param.row };
        let temp = [...sdata.breadcrumb];
    
        temp[3] = {
            name: sdata.selbatchuser.name,
            path: "/userprogress"
        };

        sdata.breadcrumb = temp;
        dispatch(awsSignIn(sdata));

        navigate("/userprogress");

    }
    // Api call to get faculty details
    async function getFaculty() {

        const bodyParam = {
            body: {
                oid: config.aws_org_id,
                bpid: userDetails.curprgcou.bpid
            },
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        try {

            const users = await API.post(
                config.aws_cloud_logic_custom_name,
                Constants.GET_PROGRAM_BATCH_REPORT,
                bodyParam
            );

            convertData(users);

        }
        catch (error) {
            console.error(error);
        }
    }
    function convertData(data) {
        let temp = [];
        for (let i = 0; i < data.length; i++) {
            let obj = {};
            obj.id = i;
            obj.name = data[i].UNAME;
            obj.email = data[i].EMAILID;
            obj.usn = data[i].USN;
            obj.exam = data[i].EXAM;
            obj.electives = data[i].ELECTIVES;

            if (obj.userrole === "User") {

                temp.push(obj);
            }

        }

    }

    const handleBulkClose = () => {
        setOpenBulk(false);
    };



    return (
        <div style={{ height: 400, width: '100%' }}>
            <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                    rows={userData}
                    columns={columns}
                    checkboxSelection
                    disableSelectionOnClick
                    components={{ Toolbar: CustomToolbar }}
                    
                    onSelectionModelChange={(newSelection) => {

                        setSelectionModel(newSelection);
                    }}
                    selectionModel={selectionModel}
                    className={classes['.MuiDataGrid-root']}
                />
            </div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openBulk}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openBulk}>
                    <UserBulkUploadTenant
                        handleClose={handleBulkClose}
                        type="user"
                        getUsers={getFaculty}
                        rows={userData}
                        rowdata={rowdata}
                    />
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openBulkRegister}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openBulkRegister}>
                    <UserBulkRegistartion
                        handleClose={handleRegisterClose}
                        getUsers={getFaculty}
                        rows={userData}
                        rowdata={rowdata}
                        type={'edit'}
                        tests={allTests}
                    />
                </Fade>
            </Modal>
        </div >
    );
}

export default UserStatsGrid;