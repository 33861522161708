// Dependencies imports
import React, { useEffect, useState } from 'react';
import { API } from "aws-amplify";
import { useDispatch,useSelector } from "react-redux";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Paper from '@material-ui/core/Paper';
import Skeleton from "@material-ui/lab/Skeleton";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import swal from "sweetalert";

// Local imports
import UserHeader from '../Header/UserHeader/UserHeader';
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import { awsSignIn,authData } from "../../redux/auth/authSlice";
import { ReactComponent as Delete } from '../../assets/svg/delete_black_24dp.svg';
import { ReactComponent as Edit } from '../../assets/svg/edit_black_24dp.svg';
import AddEvents from './AddEvents';
import EditEvents from './EditEvents';
import DeleteEvents from './DeleteEvents';

//Styles imports
import Ann from './Announcement.module.scss';

const header = {
    color: "white",
    fontFamily: "nunito",
    fontSize: "15px",
    fontWeight: "400px"
}

const programnames = {
    color: Constants.main_color_1,
    fontFamily: "nunito",
    fontSize: "16px",
    fontWeight: "500px",
    width: "20%"
}

const programtype = {
    color: "#6a6a6a !important",
    fontSize: "14px",
    fontWeight: "400px",
    fontFamily: "nunito",
    paddingBotton: "10px",
    width: "60%"
}

// Styles for Tables
const useStyles = makeStyles((theme) => ({
    root: {
        '& .super-app-theme--header': {
            backgroundColor: Constants.main_color_1,
        },
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
        transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
}));

// Styles for Pagination
const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));

// Pagination function
function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

// Start of Announcement component
const Events = () => {
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [eventData, setEventData] = useState([]);
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [editEventData, setEditEventData] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteEventData, setDeleteEventData] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const dispatch = useDispatch();

    let userDetails = useSelector(authData);
    const classes = useStyles();

    // Useeffect for api call
    useEffect(() => {
        getEvents();
        breadcrumb();
        return () => { };
    }, []);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleclick = (item) => {
        // alert("1"+JSON.stringify(item));
        console.log(item);
        setOpenEdit(true);
        setEditEventData(item);
        // console.log(event);
    }
    const handleclickdelete = (item, index) => {
        // alert(item.eid)
        setOpenDelete(true);
        setDeleteEventData(item.eid);
    }

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };
    const handleCloseDelete = () => {
        setOpenDelete(false);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // API call syntax to get batches
    async function getEvents() {
        setLoading(true);
        let batchjson = [];
        batchjson.push(userDetails.curprgcou.bpid);
        const bodyParam = {
            body: {
                oid: config.aws_org_id,
                batchjson: batchjson,
                rtype: "get"
            },
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        try {
            console.log(bodyParam.body);
            const events = await API.post(
                config.aws_cloud_logic_custom_name,
                Constants.GET_EVENTS,
                bodyParam
            );
            console.log("events", events);

            if (events !== undefined && events.result !== undefined && events.result.events) {
                if (events.result[userDetails.curprgcou.bpid] !== undefined)
                    setEventData(events.result[userDetails.curprgcou.bpid]);

                setTableData(events.result.events);
                setLoading(false);
            }
        }
        catch (error) {
            console.error(error);
        }
    }

    // Maping rows and link to pagination
    function tableview() {
        return (
            tableData.length >= 1 ?
                <TableBody>
                    {(rowsPerPage > 0
            ? tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : tableData
          ).map((item, index) => {
                        return (
                            <TableRow>
                                <TableCell style={programnames}>{item.start}</TableCell>
                                <TableCell style={programtype}>{item.title} <br />
                                   <div> <a  href={item.link} target="blank">{item.link} </a></div> 
                                </TableCell>
                                <TableCell style={{width:"10%"}}>
                                    <div onClick={() => { handleclick(item) }}>
                                        <Edit className={Ann.icon} />
                                    </div>

                                </TableCell>
                                <TableCell style={{width:"10%"}}>
                                    <div onClick={() => { handleclickdelete(item, index) }}>
                                        <Delete className={Ann.icon} />

                                    </div>

                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody> :
                <TableBody>
                    <TableRow>
                        <TableCell style={programnames}></TableCell>
                        <TableCell style={programtype}><p style={{ paddingLeft: "25%" }}>No Data</p></TableCell>
                    </TableRow>
                </TableBody>
        );
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };

      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };

      function breadcrumb() {
        let sdata = { ...userDetails };
        let temp = [...sdata.breadcrumb];
        
        temp[2] = {
            name:'Events',
            path:'/events'
        };

        sdata.breadcrumb = temp;

        dispatch(awsSignIn(sdata));
    }

    return (
        <div className={Ann.maincontainer}>
            <UserHeader Bindex={2}/>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <AddEvents handleClose={handleClose} eventData={eventData} getEvents={getEvents} />
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openEdit}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openEdit}>
                    <EditEvents handleClose={handleCloseEdit} editEventData={editEventData} getEvents={getEvents} />
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openDelete}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,

                }}
            >
                <Fade in={openDelete}>
                    <DeleteEvents handleClose={handleCloseDelete} index={deleteEventData} getEvents={getEvents} />
                </Fade>
            </Modal>
            <div className={Ann.add}>
                <h3 className={Ann.header}>Events</h3>
                <button className={Ann.addbutton} onClick={handleClickOpen}>Add</button>
            </div>
            <div className={Ann.table}>

                {loading === false ? <TableContainer component={Paper}>
                    <Table aria-label="custom pagination table">
                        <TableHead style={{ background: Constants.main_color_1 }}>
                            <TableRow>
                                <TableCell style={header}>
                                    Event Date
                                </TableCell>
                                <TableCell style={header}>Event Name</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        {loading ? '' : tableview()}
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                    colSpan={3}
                                    count={tableData.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer> : <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><Skeleton variant="rect" width={1500} height={500} /> </div>}
            </div>
            {/* <AddEvents eventData = {eventData}  /> */}
        </div>
    );
};

export default Events;