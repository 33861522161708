import React, { useEffect, useState, useRef } from "react";
import User from "./AddUsersTenant.module.scss";
import config from "../../config/aws-exports";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { authData } from "../../redux/auth/authSlice";
import { useSelector } from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import { DataGrid } from '@material-ui/data-grid';
import TextField from '@mui/material/TextField';
import { Close } from "@material-ui/icons";
import { Constants } from "../../config/constants";
import { Select, ListItemText, MenuItem, Input, Checkbox } from '@material-ui/core';
import { API } from "aws-amplify";
import swal from "sweetalert";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .super-app-theme--header': {
      background: Constants.main_color_1,
      fontSize: "16px",
      color: 'rgb(255,255,255)',
      fontFamily: "nunito"
    },
  },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer"
  },
  formControl: {
    border: "1px solid #6a6a6a",
    height: "fit-content",
    width: "100%",
    borderRadius: "5px",
    fontSize: "14px",
    fontFamily: "nunito",
    padding: "5px"
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  program: {
    fontSize: "16px",
    color: "black",
    fontWeight: "500",
    paddingLeft: "5px"
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const UserBulkRegistartion = (props) => {
  let { handleClose, getUsers, rows, tests, type, rowdata } = props;
  const [spin, setSpin] = useState(false);
  const classes = useStyles();
  const [personName, setPersonName] = useState([]);
  let userDetails = useSelector(authData);
  const accRef = useRef();
  const [filterText, setFilterText] = useState('');
  const [selectedTests, setSelectedTests] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [assignedTests, setAssignedTests] = useState([]);
  const [saveButton, setSaveButton] = useState(false);
  const filteredRows = rows.filter(row =>
    Object.values(row).some(value =>
      value !== null && value !== undefined && value.toString().toLowerCase().includes(filterText.toLowerCase())
    )
  );

  useEffect(() => {
    if (type == 'edit') {
      
      getAssignedTests();
    }
    return () => { };
  }, []);

  async function onsubmit() {

    let userslist = await recvDataMail(selectionModel);
    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        bpid: userDetails.curprgcou.bpid,
        userarray: userslist,
        tests: selectedTests,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {

      await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.BULK_REGISTRATION,
        bodyParam
      );
      swal({
        title: "Success",
        text: "Registration Done",
        icon: "success",
        dangerMode: false,
      });
      getUsers();
      handleClose();
      setSpin(false);
    } catch (error) {
      console.error(error);
    }
  };

  async function getAssignedTests() {

    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        loginId: rowdata?.email == undefined ? rowdata?.Email : rowdata?.email,

      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {

      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        `${Constants.GET_ASSIGNED_TESTS}?loginId= ${rowdata?.email}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      
      let obj = response;
      setAssignedTests(obj.assignedTests);
      let selected = [];
      if (obj.assignedTests.length > 0) {
        for (let i = 0; i < obj.assignedTests.length; i++) {
          if (obj.assignedTests[i].test_name) {
            selected.push(obj.assignedTests[i].test_name);
          }
        }
      }
      setPersonName(selected);
      setSpin(false);
    } catch (error) {
      console.error(error);
    }
  };

  async function onEditSubmit() {

    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        login_id: rowdata?.Email == undefined ? rowdata?.email : rowdata?.Email,
        tests: selectedTests,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {

      await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.ASSIGN_TESTS_TO_USER,
        bodyParam
      );
      swal({
        title: "Success",
        text: "Tests Assined Successfully!",
        icon: "success",
        dangerMode: false,
      });
      
      handleClose();
      getUsers();
      setSpin(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFilterChange = e => {
    setFilterText(e.target.value);
  };

  const onedit = () => {
    setSaveButton(true);

  }

  const handleChange = (event) => {
    let selectArray = event.target.value;
    let temparr = tests?.filter(test => selectArray.includes(test.testname)).map(test => test.testid);
    setSelectedTests(temparr);
    setPersonName(selectArray);

  };

  async function recvDataMail(indexArr) {
    const filteredUsers = rows?.filter(row => indexArr?.includes(row.id));
    return filteredUsers;
  }

  const handleEditChange = (event) => {
    let selectArray = event.target.value;
    let tempObject = {};
    tests?.filter(test => selectArray.includes(test.testname)).forEach(test => {
      tempObject[test.testid] = {
        endDate: test.endDate,
        startDate: test.startDate
      };
    });

    setSelectedTests(tempObject);
    setPersonName(selectArray);

  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 300,
      flex: 1,
      headerClassName: 'super-app-theme--header',

    },
    {
      field: 'email',
      headerName: 'Email',
      width: 300,
      flex: 1,
      headerClassName: 'super-app-theme--header',
    }
  ];

  return (
    <div className={User.maindiv}>

      {spin === false ? (
        <div className={User.maincontainer}>
          <div className={User.iconcontainer}>
            <h3 className={User.create}>{type !== 'edit' ? 'Bulk Registration' : 'View Assigned Tests'}</h3>
            <Close className={User.closebutton} onClick={handleClose} />
          </div>

          <div className={User.form}>
            {type == 'add' ? <div>
              <div className={User.field}>
                <h3 className={User.select}>
                  Select Test
                </h3>
                <Select
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  style={{ border: "none", width: "50%", boxShadow: "0px 0px 5px 0px lightgrey", background: "white", height: "30px", fontSize: "15px", paddingLeft: "10px", fontWeight: "200px", textAlign: "left" }}
                  multiple
                  value={personName}
                  onChange={handleChange}
                  input={<Input />}
                  renderValue={(selected) => {
                    selected.join(', ');

                    let temp = [];
                    for (let i = 0; i < selected.length; i++) {
                      temp.push(selected[i]);
                    }
                    return temp;
                  }}
                  MenuProps={MenuProps}
                >
                  {tests?.map((item) => (
                    <MenuItem key={item} value={item.testname}>
                      <Checkbox checked={personName.indexOf(item.testname) > -1} />
                      <ListItemText primary={item.testname} style={{textWrap: "balance"}}/>
                    </MenuItem>
                  ))}

                </Select>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <TextField
                  label="Search"
                  value={filterText}
                  onChange={handleFilterChange}
                  variant="outlined"
                  size="small"
                  fullWidth
                  margin="normal"
                  style={{ fontSize: '14px', width: 'auto', }}
                />
              </div>
              <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                  rows={filteredRows}
                  columns={columns}
                  checkboxSelection
                  onSelectionModelChange={(newSelection) => {

                    setSelectionModel(newSelection);
                  }}
                  selectionModel={selectionModel}
                  isSelected={(params) => {
                    return false; 
                  }}
                  className={classes['.MuiDataGrid-root']}
                />
              </div>

            </div> : null}
            {type == 'edit' && !saveButton ? <div>
              <div className={User.field} style={{ display: 'block' }}>
                <h3> Selected Tests </h3>
                <div style={{ textAlign: "start", padding: "12px", fontSize: "14px" }}>

                  {personName.length > 0 ? <ol>
                    {personName.map((item) => <li>{item}</li>)}
                  </ol> : null}
                </div>
              </div>
            </div> : null}
            {type != 'edit' ? <div
              style={{
                marginTop: "15px",

              }}
            >
              <input
                type="submit"
                value="Save"
                onClick={onsubmit}
                className={
                  window.navigator.onLine === true
                    ? User.btn_color
                    : User.btn_colordis
                }
                disabled={!window.navigator.onLine}
              ></input>
            </div> : null}
            {type == 'edit' && saveButton ? <div

            >
              <div className={User.field}>
                <h3 className={User.select}>
                  Select Test
                </h3>
                <Select
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  style={{ border: "none", width: "50%", boxShadow: "0px 0px 5px 0px lightgrey", background: "white", height: "30px", fontSize: "15px", paddingLeft: "10px", fontWeight: "200px" }}
                  multiple
                  value={personName}
                  onChange={handleEditChange}
                  input={<Input />}
                  renderValue={(selected) => {
                    selected.join(', ');

                    let temp = [];
                    for (let i = 0; i < selected.length; i++) {
                      temp.push(selected[i]);
                    }
                    return temp;
                  }}
                  MenuProps={MenuProps}
                >
                  {tests?.map((item) => (
                    <MenuItem key={item} value={item.testname}>
                      <Checkbox checked={personName.indexOf(item.testname) > -1} />
                      <ListItemText primary={item.testname} />
                    </MenuItem>
                  ))}

                </Select>
              </div>
              <input
                type="submit"
                value="Save"
                onClick={onEditSubmit}
                className={
                  window.navigator.onLine === true
                    ? User.btn_color
                    : User.btn_colordis
                }
                disabled={!window.navigator.onLine}
                style={{
                  marginTop: "60px",

                }}
              ></input>
            </div> : null}
            {type == 'edit' && !saveButton ? <input
              type="submit"
              value="Edit"
              onClick={onedit}
              className={
                window.navigator.onLine === true
                  ? User.btn_color
                  : User.btn_colordis
              }
              disabled={!window.navigator.onLine}
            ></input> : null}
          </div>
          <Backdrop sx={{ color: "#fff" }} open={accRef.current} className={classes.backdrop}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      ) : (
        <div className={classes.root}>
          <CircularProgress style={{ color: config.main_color_1 }} />
        </div>
      )}
    </div>
  );
};

export default UserBulkRegistartion;
