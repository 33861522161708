// Dependencies imports
import React from 'react';
import ReactPlayer from "react-player";
// Local imports
import { ReactComponent as Close } from '../../assets/svg/close_black_24dp.svg';
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
// Styles imports
import Ann from "./AnnouncementFile.module.scss";

const AnnouncementFile = (props) => {
  let { handleFileClose, obj } = props;


  return (
    <div className={Ann.mainpopup}>
      <div className={Ann.iconholder}>
        <Close onClick={handleFileClose} className={Ann.icon} />
      </div>
      <div className={Ann.mediaholder} style={{ display: "flex", alignItems: "center", justifyItems: "center", height: "100% !important", width: "100% !important", overflowY: "auto" }}>
        <div>
          {obj.type === "pdf" ? (
            <>
              {obj.ftype == undefined ? <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100% !important",
                  height: "500px",
                  overflowX: "hidden",
                  paddingBottom: "20px",
                  objectFit: "cover"
                }}
              >
                <iframe
                  src={`https://${Constants.DOMAIN}/${config.aws_org_id.toLowerCase()}-resources/images/announcement-images/${obj.mfname}`}

                  width={600}
                  title="Iframe Example"
                ></iframe>
                
              </div> : <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100% !important",
                  height: "500px",
                  overflowX: "hidden",
                  paddingBottom: "20px",
                  objectFit: "cover"
                }}
              >
                {obj.ftype == 'academic' ? <iframe
                  src={`https://${Constants.DOMAIN}/${config.aws_org_id.toLowerCase()}-resources/pdf/academic/${obj.mfname}`}

                  width={800}
                  title="Iframe Example"
                ></iframe> : <iframe
                  src={`https://${Constants.DOMAIN}/${config.aws_org_id.toLowerCase()}-resources/pdf/${obj.mfname}`}

                  width={800}
                  title="Iframe Example"
                ></iframe>}
              </div>}
            </>
          ) : null || obj.type === "video" ? (
            <div
              className="player-wrapper"
              style={{ display: "flex", justifyContent: "center", width: "100%", alignItems: "center" }}
            >
              <ReactPlayer url={`https://${Constants.DOMAIN}/${config.aws_org_id.toLowerCase()}-resources/images/announcement-images/${obj.mfname}`} controls={true} width="100%" />
            </div>
          ) : null || obj.type === "audio" ? (
            <div
              className="player-wrapper"

            >
              <ReactPlayer url={`https://${Constants.DOMAIN}/${config.aws_org_id.toLowerCase()}-resources/images/announcement-images/${obj.mfname}`} controls={true} height="50px" />
            </div>
          ) : null || obj.type === "image" ? (
            <div style={{ width: "100%", height: "300px", margin: "auto", display: "flex", alignItems: "center", justifyContent: "center" }}
            >
              <img src={`https://${Constants.DOMAIN}/${config.aws_org_id.toLowerCase()}-resources/images/announcement-images/${obj.mfname}`} style={{ objectFit: "cover" }} />
            </div>) : null}
        </div>
      </div>
    </div>
  );

};

export default AnnouncementFile;