// Dependencies imports
import React, { useState } from 'react';
import swal from 'sweetalert';
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
// Local imports
import Add from './RejectApplication.module.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent"
  },
}));


const RejectApplication = (props) => {
  let { handleClose, status, title } = props;
  const [spin, setSpin] = useState(false);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  let userDetails = useSelector(authData);
  const [evnentName, setEventName] = useState("");
  const [eventNameErr, setEventNameErr] = useState("");
  console.log(userDetails);
  const onsubmit = () => {
    // setname(name)
    let formIsValid = true;
    setEventNameErr("");

    if (status != 4 && evnentName.trim() === "") {
      formIsValid = false;
      setEventNameErr("*Please enter feedback");
      return;

    }
    if (status == 4 && evnentName.trim() === "") {
      formIsValid = false;
      setEventNameErr("*Please select reason");
      return;

    }
    if (formIsValid === true) {

      updateApplication();
    }


  };

  async function updateApplication() {
    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        bpid: userDetails.curprgcou.bpid,
        pname: userDetails.curprgcou.ptitle,
        type: 2,
        status: status,
        message: evnentName,
        eid: [userDetails.curapplication.eid],
        emailid: [userDetails.curapplication.emailid],
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("1" + JSON.stringify(bodyParam.body))
    try {

      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_UPDATE_APPLICATION_STATUS,
        bodyParam
      );
      console.log("Application details", response);
      setSpin(false);
      handleClose();
      if (status == 4) {
        swal({
          title: "Success!",
          text: "Application Rejected",
          icon: "success",
          dangerMode: false,
        })
          .then(willDelete => {
            if (willDelete) {

            }
          });

      } else {
        swal({
          title: "Success!",
          text: "Application On Hold",
          icon: "success",
          dangerMode: false,
        })
          .then(willDelete => {
            if (willDelete) {

            }
          });

      }
    }
    catch (error) {
      swal({
        title: "Oooops!",
        text: "something went wrong!",
        icon: "warning",
        dangerMode: true,
      })
        .then(willDelete => {
          if (willDelete) {

          }
        });
      console.error(error);
    }
  }
  const onChangeHandlerEventName = event => {
    setEventName(event.target.value);
  };

  return (
    <div>
      {spin === false ?
        <div className={Add.mainpopup}>
          <h3 className={Add.header}>Reason</h3>
          <div className={Add.form}>
            <form>
              {status != undefined && status == 4 ? (
                <div className={Add.fieldholder}>
                  <div className={Add.inputholder}>
                    <div style={{ color: "#cc0000", marginBottom: "12px", marginTop: "-10px", textAlign: "start" }}>{eventNameErr}</div>
                    <select style={{ border: "none", width: "100%", boxShadow: "0px 0px 5px 0px lightgrey", background: "white", height: "30px", marginBottom: "20px", fontSize: "15px", paddingLeft: "10px", fontWeight: "200px" }} name="mes" id="mes" required onChange={onChangeHandlerEventName}>
                      <option value="">Select Reason</option>
                      <option value="Reason 1">Reason 1</option>
                      <option value="Reason 2">Reason 2</option>
                      <option value="Reason 3">Reason 3</option>
                      <option value="Reason 4">Reason 4</option>
                      <option value="Reason 5">Reason 5</option>
                      <option value="Reason 6">Reason 6</option>
                    </select>
                  </div>
                </div>) : (
                <div className={Add.fieldholder}>
                  <div className={Add.inputholder}>
                    <div style={{ color: "#cc0000", marginBottom: "12px", marginTop: "-10px", textAlign: "start" }}>{eventNameErr}</div>
                    <textarea placeholder="Please enter your reason here..." maxlength="160" rows={5} className={Add.area} onChange={onChangeHandlerEventName} value={evnentName} ></textarea>
                  </div>
                </div>)}
            </form>
            <div className={Add.buttonholder}>
              <button className={Add.cancel} onClick={handleClose}>Cancel</button>
              <button className={Add.submit} onClick={onsubmit} >Submit</button>
            </div>
          </div>
        </div> : <div className={classes.root}>
          <CircularProgress style={{ color: Constants.main_color_1 }} /></div>}
    </div>
  );
};

export default RejectApplication;