import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import { Box, Button, Paper } from "@material-ui/core";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbar,
} from "@material-ui/data-grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { API } from "aws-amplify";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import { Item } from "semantic-ui-react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
  selectEmpty: {
    // marginTop: theme.spacing(2),
  },
  fsty: {
    fontSize: "16px",
    fontFamily: "nunito",
    fontWeight: "bold",
    color: "black",
    cursor: "pointer",
  },
}));

export default function CRIRFeedback({ close, crir, bcid, question, qno }) {
  const [rows, setRows] = useState([]);
  const [instr, setInstr] = useState([]);
  const [cinstr, setCInstr] = useState("");
  const [cinstrn, setCInstrn] = useState("");
  const [spin, setSpin] = useState(false);
  const [rcount, setRcount] = useState({});
  const [spinR, setSpinR] = useState(true);
  const [rcountR, setRcountR] = useState([]);

  let userDetails = useSelector(authData);
  const classes = useStyles();
  const tcolumns = [
    {
      field: "name",
      headerName: "Student name",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "fb",
      headerName: "Feedback",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
  ];

  useEffect(() => {
    console.log("crir", crir);

    if (crir == "ir") {
      getIns();
    } else {
      feedbackF();
      feedbackR();
    }
  }, []);

  async function getIns() {
    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        bpid: userDetails.curprgcou.bpid,
        bcid: bcid.btid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    // alert(JSON.stringify(bodyParam.body));
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        // Constants.GET_COURSE_VIDEO,
        Constants.getCourseInstructor,
        bodyParam
      );

      // alert(JSON.stringify(response));
      console.log(response);
      setInstr(response);
      if (response?.length > 0) {
        setCInstr(response[0].emailid);
        setCInstrn(response[0].name);
        feedbackF(response[0]);
        feedbackR(response[0]);
      } else {
        setSpin(false);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function feedbackF(irdt) {
    setSpin(true);

    //console.log("bcid", bcid.btid);

    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        // eid: userDetails.eid,
        crir: crir,
        // resp: resp,
        bcid: bcid.btid,
        action: "get",
        qno: qno,
        irdt: irdt,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    // alert(JSON.stringify(bodyParam.body));
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        // Constants.GET_COURSE_VIDEO,
        Constants.CIFeedBack,
        bodyParam
      );

      // alert(JSON.stringify(response));
      console.log(response);

      setRows(response);

      let obj = {};
      for (let i = 0; i < response.length; i++) {
        const v = response[i];
        if (obj[v.fb] == undefined) {
          obj[v.fb] = 1;
        } else {
          obj[v.fb] = obj[v.fb] + 1;
        }
      }
      setRcount(obj);
      // alert(JSON.stringify(obj));
      setSpin(false);
    } catch (error) {
      console.error(error);
    }
  }

  async function feedbackR(irdt) {
    setSpinR(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        // eid: userDetails.eid,
        crir: crir,
        // resp: resp,
        bcid: bcid.btid,
        action: "get",
        qno: qno,
        irdt: irdt,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    // alert(JSON.stringify(bodyParam.body));
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        // Constants.GET_COURSE_VIDEO,
        Constants.CIFeedBackReport,
        bodyParam
      );

      // alert(JSON.stringify(response));
      console.log(response);

      // setRows(response);

      // let obj = {};
      // for (let i = 0; i < response.length; i++) {
      //     const v = response[i];
      //     if (obj[v.fb] == undefined) {
      //         obj[v.fb] = 1;
      //     } else {
      //         obj[v.fb] = obj[v.fb] + 1;
      //     }
      // }
      setRcountR(response);
      // alert(JSON.stringify(obj));
      setSpinR(false);
    } catch (error) {
      console.error(error);
    }
  }

  function exp() {
    // let obj = {...rcount};
    // obj['Question'] = question;
    const items = rcountR;
    const replacer = (key, value) =>
      value === null || value === undefined || value === "" ? 0 : value; // specify how you want to handle null values here
    const header = Object.keys({
      Question: "",
      "Not Applicable": "",
      "Strongly Disagree": "",
      Disagree: "",
      Agree: "",
      "Strongly Agree": "",
    });
    const csv = [
      [
        `JSSAHER - ${userDetails.curprgcou.pname} - ${userDetails.curprgcou.bname}`,
      ].join(","),
      [`Course - ${bcid.ttitle}`].join(","),
      [`Feedback for the faculty - ${cinstrn}`].join(","),
      ["", "", "", ""].join(","),
      header.join(","), // header row first
      ...items.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      ),
    ].join("\r\n");

    let blob = new Blob(["\ufeff" + csv], {
      type: "text/csv;charset=utf-8;",
    });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("Chrome") == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }

    dwldLink.setAttribute("href", url);

    dwldLink.setAttribute("download", "Feedback - " + cinstrn + ".csv");

    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
    console.log(csv);
  }

  const handleChange = (event) => {
    setCInstr(event.target.value);
    if (event.target.value != "") {
      let obj = { emailid: event.target.value };
      feedbackF(obj);
      feedbackR(obj);
      for (let i = 0; i < instr.length; i++) {
        const element = instr[i];
        if (element.emailid == obj.emailid) {
          setCInstrn(element.name);
        }
      }
    } else {
      setRows([]);
      setRcount({});
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <GridToolbar />
      </GridToolbarContainer>
    );
  }

  return (
    <div className={classes.root}>
      <Paper
        elevation={3}
        style={{ height: "80vh", width: "72vw", overflowY: "scroll" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            height: "28px",
            marginRight: "15px",
          }}
        >
          {" "}
          <span
            style={{ fontSize: "20px", fontWeight: "bold", cursor: "pointer" }}
            onClick={() => {
              close(false);
            }}
          >
            X
          </span>{" "}
        </div>
        <div
          style={{
            marginLeft: "50px",
            marginRight: "40px",
          }}
        >
          <h2 style={{ paddingBottom: "15px" }}>
            <span>{question}</span>
          </h2>
          {crir == "ir" ? (
            <div
              style={{
                paddingBottom: "15px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "60px",
              }}
            >
              <h2>
                <span>Please Select Instructor</span>
              </h2>

              <FormControl className={classes.formControl}>
                <InputLabel
                  id="demo-simple-select-helper-label"
                  className={classes.fsty}
                >
                  Instructor
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={cinstr}
                  onChange={handleChange}
                >
                  <MenuItem value="" className={classes.fsty}>
                    <em>None</em>
                  </MenuItem>
                  {instr.map((Item) => {
                    return (
                      <MenuItem value={Item.emailid} className={classes.fsty}>
                        {Item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>.</FormHelperText>
              </FormControl>
              <Button
                onClick={exp}
                disabled={spinR}
                variant="contained"
                color="Primary"
                style={{ width: "200px" }}
              >
                Export Consolidate
              </Button>
            </div>
          ) : (
            <div style={{ marginBottom:"20px", textAlign:"right"}}>
              <Button
                onClick={exp}
                disabled={spinR}
                variant="contained"
                color="Primary"
                style={{ width: "200px" }}
              >
                Export Consolidate
              </Button>
            </div>
          )}
          {Object.keys(rcount).length > 0 ? (
            <div
              style={{
                paddingBottom: "15px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h4>
                {Object.keys(rcount).map((k) => {
                  return (
                    <span style={{ margin: "6px" }}>
                      {k} - {rcount[k]}
                    </span>
                  );
                })}
              </h4>
            </div>
          ) : null}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "54vh",
            }}
          >
            <DataGrid
              rows={rows}
              columns={tcolumns}
              className={classes[".MuiDataGrid-root"]}
              components={{
                Toolbar: CustomToolbar,
              }}
              disableSelectionOnClick
            />
          </div>
        </div>
        <div>
          {spin ? (
            <CircularProgress
              style={{ position: "absolute", top: "48%", left: "48%" }}
            />
          ) : null}
        </div>
      </Paper>
    </div>
  );
}
