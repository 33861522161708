import React from 'react';

const UsersIcon = ({ className, cls1, cls2 }) => {
    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className={className}
            width={30}
            height={30}>
            <titel>Users</titel>
            <path class={cls1} d="M15.31,8.11c-.15,1.86-1.3,3.42-2.9,3.42a3.19,3.19,0,0,1-2.9-3.42,3.2,3.2,0,0,1,2.9-3.43A3.1,3.1,0,0,1,15.31,8.11Z" />
            <path class={cls2} d="M5.86,20.32V17.68a3.86,3.86,0,0,1,3.75-3.95h5.61A3.86,3.86,0,0,1,19,17.68v2.64" />
            <path class={cls1} d="M20.89,8.54a2,2,0,0,1-1.76,2.08,1.94,1.94,0,0,1-1.77-2.08,1.94,1.94,0,0,1,1.77-2.08A1.88,1.88,0,0,1,20.89,8.54Z" />
            <path class={cls2} d="M18.31,12.6h2.52A2.35,2.35,0,0,1,23.11,15v1.61" />
            <path class={cls1} d="M4.11,8.54a2,2,0,0,0,1.76,2.08A1.94,1.94,0,0,0,7.64,8.54,1.94,1.94,0,0,0,5.87,6.46,1.88,1.88,0,0,0,4.11,8.54Z" />
            <path class={cls2} d="M6.69,12.6H4.17A2.35,2.35,0,0,0,1.89,15v1.61" />
        </svg>
    );
};


export default UsersIcon;