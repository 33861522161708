import React, { useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import { IconButton, Slider } from '@material-ui/core';
import { PlayArrow as PlayArrowIcon, Pause as PauseIcon, VolumeUp as VolumeUpIcon } from '@material-ui/icons';
import CloseIcon from "@material-ui/icons/Close";
import VolumeOffIcon from '@mui/icons-material/VolumeOff';

const AudioPlayer = ({ curObject, handleOpenClose }) => {
    const playerRef = useRef(null);
    const [playing, setPlaying] = useState(false);
    const [played, setPlayed] = useState(0);
    const [duration, setDuration] = useState(0);
    const [volume, setVolume] = useState(0.8);
    const [mute, setMute] = useState(false);

    const togglePlayPause = () => setPlaying(!playing);

    const handleProgress = (state) => {
        setPlayed(state.played); // Update the played state with the current progress
    };

    const handleDuration = (duration) => {
        setDuration(duration);
    };

    const handleVolumeChange = (event, newValue) => {
        setMute(false)
        setVolume(newValue / 100);
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };

    return (
        <div
            className="custom-player-wrapper"
            style={{
                padding: "3rem",
                backgroundColor: "#f9f9f9",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                width: "60%"
            }}
        >
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "2rem" }}>
                <h3 style={{ fontWeight: "600", color: "var(--header-color)" }}>{curObject.otitle}</h3>
                <CloseIcon style={{ cursor: "pointer" }} onClick={() => handleOpenClose()} />
            </div>
            <div style={{ display: "flex", background: "rgb(0 0 0 / 67%)", alignItems: "center" }}>
                <ReactPlayer
                    ref={playerRef}
                    url={curObject.ourl}
                    playing={playing}
                    volume={volume}
                    onProgress={handleProgress}
                    onDuration={handleDuration}
                    width="100%"
                    height="50px"
                    style={{ display: "none" }}
                />
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", paddingRight: "2rem" }}>
                    <IconButton onClick={togglePlayPause} style={{ color: "white" }}>
                        {playing ? <PauseIcon /> : <PlayArrowIcon />}
                    </IconButton>
                    <div style={{ display: "flex", alignItems: "center", flex: 1, margin: "0 10px" }}>
                        <Slider
                            value={played * 100}
                            onChange={(e, newValue) => playerRef.current.seekTo(newValue / 100)}
                            aria-labelledby="continuous-slider"
                            style={{ margin: "0 10px", flex: 1, color: "white" }}
                        />
                        <div style={{ color: "white", fontSize: "14px" }}>
                            <span >{formatTime(played * duration)}</span> / <span>{formatTime(duration)}</span>
                        </div>

                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>


                        {
                            mute || volume == "0.0" ? (<VolumeOffIcon style={{ color: "white", cursor: "pointer", }} onClick={() => {
                                setMute(false);
                                setVolume(0.8);
                            }} />) : (<VolumeUpIcon style={{ color: "white", cursor: "pointer", }} onClick={() => {
                                setMute(true);
                                setVolume(0.0);

                            }} />)
                        }

                        <Slider
                            value={volume * 100}
                            onChange={handleVolumeChange}
                            aria-labelledby="continuous-slider"
                            style={{ width: 100, marginLeft: "10px", color: "white" }}
                        />
                    </div>
                </div>
               
            </div>
        </div>
    );
};

export default AudioPlayer;
