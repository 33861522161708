import React, { useState, useEffect, useRef } from "react";
import { API } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Modal,
  Fade,
  CircularProgress,
  IconButton,
  Box,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import UploadIcon from "@mui/icons-material/Upload";
import { Close } from "@mui/icons-material";
import swal from "sweetalert";
import axios from "axios";

import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import { authData } from "../../redux/auth/authSlice";

import Prog from "./Programs.module.scss";
import User from "./UserProgress.module.scss";
import { Skeleton } from "@material-ui/lab";
import { saveAs } from "file-saver";
import { setCookie } from "react-use-cookie";

const useStyles = makeStyles({
  root: {
    "& .super-app-theme--header": {
      backgroundColor: Constants.main_color_1,
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
  },
});
const header = {
  color: "white",
  fontFamily: "nunito",
  fontSize: "15px",
  fontWeight: "400px",
};
const Programs = () => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [courseData, setCourseData] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openFileUpload, setOpenFileUpload] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [pissueTextUpload, setPIssueTextUpload] = useState("");
  const [tempMfile, setTempMfile] = useState("");
  const [spin, setSpin] = useState(false);
  const [marksFile, setMarkFile] = useState();
  const [openCertificateFileUpload, setOpenCertificateFileUpload] = useState(false);
  const [fileSelected, setFileSelected] = useState(false);
  const [deleteMarksCert, setDeleteMarksCert] = useState(false);
  const [tempCfile, setTempCfile] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [markLists, setMarkLists] = useState([]);
  const [sem, setSem] = useState('');

  const classes = useStyles();
  const userDetails = useSelector(authData);
  const dispatch = useDispatch();
  const componentRef = useRef();

  useEffect(() => {
    getMarkscardDetails();

  }, []);

  async function getMarkscardDetails() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        bpid: userDetails?.curprgcou?.bpid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(config.aws_cloud_logic_custom_name, `/getSemMarkscardCertificate`, bodyParam);
      const users = response;

      if (response.lists !== undefined && response.lists.length > 0) {
        setMarkLists(response.lists);
        const expires = new Date().getTime() + 60 * 60 * 1000;
        setCookie("CloudFront-Expires", expires);
        setCookie("CloudFront-Policy", response.Policy);
        setCookie("CloudFront-Signature", response.Signature);
        setCookie("CloudFront-Key-Pair-Id", response.KeyPairId);
        setLoading(false);
      } else {
        const defaultSemesters = Array.from({ length: 9 }, (_, i) => ({ sem: i + 1, file: [] }));
        setMarkLists(defaultSemesters);
        setLoading(false);
      }
    } catch (error) {
      swal({
        title: "Oooops!",
        text: "something went wrong!",
        icon: "warning",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          swal("Oooops!", "Contact developer");
        }
      });
      console.log("getUserError", error);
    }
    console.log("Mount");
  }

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleFileUploadopen = (sem) => {
    setSem(sem);
    setOpenFileUpload(true);
  }
  const handleFileUploadClose = () => setOpenFileUpload(false);
  const handledeleteOpen = () => setOpenDelete(true);
  const handleDeleteClose = () => setOpenDelete(false);

  const changeHandler = (event) => {
    if (event.target.files[0] !== undefined) {
      if (!isInteractivity(event.target.files[0].name)) {
        swal("Oops!", "Select correct file format..!", "error");
        return;
      }
      let fileUploadType = "markscardlist";
      setSelectedFile(event.target.files[0]);
      uploadDocsToServer(event.target.files[0], fileUploadType);
    }
  };

  function isInteractivity(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "zip":
        return true;
      default:
        return false;
    }
  }

  function getExtension(filename) {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  }
  async function downloadcode(sData, index) {
    console.log(sData.curl[index]);
    try {
      const response = await fetch(sData.curl[index]);
      if (!response.ok) {
        throw new Error("Network response was not ok" + response.statusText);
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = sData.file[index];
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("There has been a problem with your fetch operation:", error);
    }
  }
  const tableview = () => {
    return (
      <TableBody>
        {markLists.map((semester) => (
          <TableRow key={semester.sem}>
            <TableCell style={{ width: "33%", fontSize: "15px" }}>{semester.sem !== 9 ? <p>Semester {semester.sem}</p> : <p>Certificate </p>} </TableCell>
            <TableCell style={{ width: "33%", fontSize: "15px" }}>
              <div
                style={{
                  height: "35px",
                  width: "150px",
                  borderRadius: "5px",
                  border: "1px solid black",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}

                onClick={() => handleFileUploadopen(semester.sem)}
              >
                <UploadIcon style={{ cursor: "pointer" }} />
                <h4>Upload File</h4>
              </div>
            </TableCell>
            <TableCell style={{ width: "33%", fontSize: "15px" }}>
              {semester?.file?.map((file, index) => (
                file ? (
                  <span key={index}>
                    <a
                      style={{ fontSize: "15px", cursor: "pointer", color: "blue" }}
                      onClick={() => {
                        downloadcode(semester, index);
                      }}
                    >
                      {file}
                    </a>
                    {index < semester?.file?.length - 1 ? ', ' : ''} {/* Add a comma if it's not the last item */}
                  </span>
                ) : (
                  <p key={index}>{file}</p>
                )
              ))}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
  };

  const loader = () => {
    return (
      <TableBody>
        {!loading &&
          [1, 2, 3, 4].map((item) => (
            <TableRow key={item}>
              <TableCell style={{ width: "25%" }}>
                <Skeleton variant="rect" width={210} height={118} />
              </TableCell>
              <TableCell style={{ width: "50%" }}>
                <Box pt={0.5}>
                  <Skeleton />
                  <Skeleton width="60%" />
                </Box>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    );
  };

  const uploadDocsToServer = async (file, fileUploadType) => {
    setLoading(true);

    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        type: fileUploadType,
        filetype: file.type,
        filename: file.name,
        folder: userDetails.curprgcou.bpid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      let response = await API.post(config.aws_cloud_logic_custom_name, Constants.GET_PRESIGNED_URL, bodyParam);
      fileUpload(file, response, fileUploadType);
    } catch (error) {
      setLoading(false);
      console.error("getCategoryError", error);
    }
  };

  const fileUpload = async (file, url, fileUploadType) => {
    setLoading(true);
    try {
      await axios.put(url, file, { headers: { "Content-Type": file.type } });
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const changeHandlerMarksCard = (e) => {
    setPIssueTextUpload("");
    setFileSelected(false);
    if (e.target.files !== undefined) {
      let selectedFile = e.target.files[0];
      let extend = selectedFile.name.substring(selectedFile.name.lastIndexOf(".") + 1, selectedFile.name.length) || selectedFile.name;
      let fileUploadType = "markscardlist";
      setFileSelected(true);
      if (Array.isArray(tempCfile) && tempCfile.length > 0) {
        setTempMfile(e.target.files[0].name);
      } else {
        setTempMfile(e.target.files[0].name);
      }
      uploadDocsToServer(e.target.files[0], fileUploadType);
    }
  };

  const onsubmit = async (type, fname) => {
    setSpin(true);

    if (markLists.length > 0) {
      for (var i = 0; i < markLists.length; i++) {
        if (markLists[i].sem == sem) {
          markLists[i].file.push(fname);
        }
      }
    }
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        bpid: userDetails.curprgcou.bpid,
        lists: markLists
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    bodyParam.body.markscard = fname;
    try {
      const response = await API.post(config.aws_cloud_logic_custom_name, "/uploadSemMarkscardCertificate", bodyParam);
      const users = response;
      swal({
        title: "Success",
        text: "Uploaded successfully",
        icon: "success",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setSpin(false);
          handleFileUploadClose();
          //window.location.reload("./Reports");
          getMarkscardDetails();
        }
      });
    } catch (error) {
      console.error("getUserError", error);
    }
  };

  return (
    <div className={Prog.maincontainer}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openFileUpload}
        closeAfterTransition
      >
        <Fade in={openFileUpload}>
          <div style={{ width: "50%", background: "transparent", alignItems: "center", justifyContent: "center", backgroundColor: "white" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", height: "max-content", padding: "10px" }}>
              <h3 style={{ padding: "8px" }}>Marks Card</h3>
              <Close className={User.closebutton} onClick={handleFileUploadClose} />
            </div>
            <div className={User.form} style={{ padding: "20px", textAlign: "center", margin: "10px auto" }}>
              <div style={{ color: "#cc0000", marginBottom: "12px", marginTop: "-10px", textAlign: "start", fontSize: "13px" }}></div>
              <div className={User.field} style={{ border: "1px solid gray", padding: "50px", display: "block" }}>
                <input type="file" id="files" accept=".zip" onChange={(e) => changeHandlerMarksCard(e)} />
                {pissueTextUpload !== undefined && pissueTextUpload !== "" && <p>{pissueTextUpload}</p>}
              </div>
              <br></br>
              <br></br>
              {spin ? (
                <CircularProgress />
              ) : (
                <Button variant="contained" color="primary" onClick={() => {
                  if (tempMfile && fileSelected) {
                    setMarkFile(tempMfile);
                    onsubmit("markscard", tempMfile);
                  } else {
                    swal("Oops!", "Please select file to upload", "error");
                    return;
                  }
                }}>
                  Save
                </Button>
              )}
            </div>
          </div>
        </Fade>
      </Modal>
      <div className={Prog.table}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ background: Constants.main_color_1 }}>
              <TableRow>
                <TableCell style={header}>Semester No.</TableCell>
                <TableCell style={header}>
                  <TableSortLabel>Upload</TableSortLabel>
                </TableCell>
                <TableCell style={header}>
                  <TableSortLabel>File Name</TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            {loading ? loader() : tableview()}
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default Programs;
