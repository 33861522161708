import React, { useState } from 'react';
import Discussion from '../components/CourseStructure/Discussion/Discussion';
import Aside from '../modules/SideNav/Aside';

function DiscussionLayout() {
    const rtl = false;

    const [toggled, setToggled] = useState(false);

    const handleToggleSidebar = value => {
        setToggled(value);
    };

    return (
        <div className={`app ${rtl ? 'rtl' : ''} ${toggled ? 'toggled' : ''}`}>
            <Aside
                rtl={rtl}
                toggled={toggled}
                handleToggleSidebar={handleToggleSidebar}
            />
            <Discussion handleToggleSidebar={handleToggleSidebar} />
        </div>
    );
}

export default DiscussionLayout;
