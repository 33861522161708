import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { API } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import Skeleton from "@material-ui/lab/Skeleton";

// Local imports
import UserHeader from "../../Header/UserHeader/UserHeader";

import { Constants } from "../../../config/constants";
import config from "../../../config/aws-exports"
import "./ProjectDetails.scss";
// Styles imports
import Grade from "../GradingDetails.module.scss";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbar,
} from "@material-ui/data-grid";

function CustomToolbar() {
  return (
    <GridToolbarContainer
      style={{ display: "flex", justifyContent: "flex-end" }}
    >
      <GridToolbar />
    </GridToolbarContainer>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .super-app-theme--header": {
      backgroundColor: Constants.main_color_1,
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
    },
  },
  heading: {
    fontSize: "18px",
    fontWeight: "600px",
    color: Constants.main_color_1,
    fontFamily: "nunito",
  },
  heading1: {
    fontSize: "18px",
    fontWeight: "600px",
    color: Constants.main_color_1,
    fontFamily: "nunito",
    paddingTop: "10px",
    paddingLeft: "10px",
    textAlign: "center",
  },
  dropoption: {
    fontSize: "16px",
    color: "#6a6a6a",
    fontWeight: "500",
    fontFamily: "nunito",
    padding: "10px 0px",
  },

  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    padding: "2rem",
    background: "white",
    "& .MuiDataGrid-cell ": {
      border: "none",
    },
    "& .Mui-odd": {
      backgroundColor: "#eee",
    },
    "& .Mui-even": {
      backgroundColor: "#fff",
    },
    "& .MuiDataGrid-footerContainer": {
      justifyContent: "center",
    },
    "& .MuiTablePagination-root": {
      fontSize: "14px",
      fontFamily: "nunito",
      fontWeight: 400,
      color: "black",
    },
    "& .MuiTypography-root": {
      fontSize: "14px",
      fontFamily: "nunito",
      fontWeight: 400,
      color: "black",
    },
    "& .MuiSelect-icon": {
      position: "relative",
      top: "-2px",
      left: "-26px",
    },
  },
}));

// Start of Assesment Details Screen
const ProjectDetails = () => {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  let userDetails = useSelector(authData);

  const dispatch = useDispatch();

  const classes = useStyles();
  let navigate = useNavigate();
  useEffect(() => {
    getBatchUsers();
  }, []);

  const tcolumns = [
    {
      field: "col1",
      headerName: "Username",
      flex: 1,
      headerClassName: "super-app-theme--header",

      renderCell: (param) => {
        return (
          <p
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleCellClick(param);
            }}
          >
            {param.value}
          </p>
        );
      },
    },
    {
      field: "col7",
      headerName: "Discussion Update",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (param) => {
        return (
          <div>
            {param.value == "New message" ? (
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <p>{param.value}</p>
                <span
                  style={{
                    fontSize: "12px",
                    color: "red",
                  }}
                >
                  &#9679;
                </span>
              </div>
            ) : (
              <p>{param.value}</p>
            )}
          </div>
        );
      },
    },
    {
      field: "col4",
      headerName: "Project Phase",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "col10",
      headerName: "Project Objective",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "col5",
      headerName: "Objective Status",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (param) => {
        return (
          <p
            className="textLabelField"
            style={{
              width: "100%",

              border:
                param.value === "Approved"
                  ? "1px solid"
                  : param.value === "In-Progress"
                    ? "1px solid"
                    : param.value === "Denied"
                      ? "1px solid"
                      : "",

              borderColor:
                param.value === "Approved"
                  ? "#005c0f"
                  : param.value === "In-Progress"
                    ? "#b89247"
                    : param.value === "Denied"
                      ? "#d61212"
                      : "",
              backgroundColor:
                param.value === "Approved"
                  ? "#f0ffec"
                  : param.value === "In-Progress"
                    ? "#fffaf0"
                    : param.value === "Denied"
                      ? "#fcf6f6"
                      : "",
              color:
                param.value === "Approved"
                  ? "#005c0f"
                  : param.value === "In-Progress"
                    ? "#b89247"
                    : param.value === "Denied"
                      ? "#d61212"
                      : "",
            }}
          >
            {param.value}
          </p>
        );
      },
    },
    {
      field: "col6",
      headerName: "Last Active Date",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
  ];



  async function getBatchUsers() {
    setLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        bpid: userDetails.curprgcou.bpid,
        tid: userDetails.project.tid,
        btid: userDetails.project.btid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const users = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getBatchUsersProjectProgress",
        bodyParam
      );

      let rows = [];
      for (let i = 0; i < users.length; i++) {
        let obj = {};
        if ((userDetails.role == "Admin") || (users[i].ProjectInstructor && users[i].ProjectInstructor[userDetails.curprgcou.bpid] && users[i].ProjectInstructor[userDetails.curprgcou.bpid][userDetails.project.btid] && users[i].ProjectInstructor[userDetails.curprgcou.bpid][userDetails.project.btid].EMAILID)) {
          if ((userDetails.role == "Admin") || users[i].ProjectInstructor[userDetails.curprgcou.bpid][userDetails.project.btid].EMAILID == userDetails.email) {

            obj.id = i;
            obj.col1 = users[i].UNAME;
            obj.col2 = users[i].EMAILID;
            obj.col3 = users[i].EID;
            obj.col4 = users[i].objects?.ntitle;
            if (users[i].projectDetails == null) {
              obj.col5 = "-";
              obj.col6 = "-";
            } else {
              if (users[i].projectDetails.length != undefined) {
                let len = users[i].projectDetails.length - 1;


                if (users[i].projectDetails[len].objStatus == 1) {
                  obj.col5 = "In-Progress";
                } else if (users[i].projectDetails[len].objStatus == 2) {
                  obj.col5 = "Approved";
                } else if (users[i].projectDetails[len].objStatus == 3) {
                  obj.col5 = "Denied";
                } else if (
                  users[i].projectDetails[len].objStatus == undefined ||
                  users[i].projectDetails[len].objStatus == undefined
                ) {
                  obj.col5 = "-";
                }

                obj.col6 = users[i].projectDetails[len].postedDate;
              }
            }
            if (users[i].progress != undefined) {
              if (
                users[i].progress.td != undefined ||
                users[i].progress.td.objStatus != undefined
              ) {
                if (users[i].progress.td.objStatus == true) {
                  obj.col7 = "New message";
                } else {
                  obj.col7 = "Updated";
                }
              }
            }

            obj.col8 = users[i].progress;

            if (users[i].objects?.objects != undefined) {
              if (users[i].progress.td != undefined) {
                for (let j = 0; j < users[i].objects?.objects.length; j++) {
                  if (
                    users[i].objects?.objects[j].oid ==
                    users[i].progress.td.currentObj
                  ) {
                    obj.col10 = users[i].objects?.objects[j].otitle;
                  }
                }
              }
            }
            obj.col11 = users[i].objects
            rows.push(obj);
          }
        }
      }

      setRows(rows); 
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  const handleCellClick = (param) => {
    let sdata = { ...userDetails };
    sdata.projectUser = param.row;
    sdata.userProgress = dispatch(awsSignIn(sdata));
    navigate("/projectgradingdetails");
  };

  return (
    <div className={Grade.maincontainer}>
      <UserHeader Bindex={3} />
      <h3 className={Grade.header}>Project Details</h3>


      {
        loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center", margin: "auto",
            }}
          >
            <Skeleton variant="rect" style={{ margin: "2rem", height: "50rem", width: "85%" }} />
          </div>) : (<div style={{ height: 600, width: "100%" }} className={classes.root}>
            <DataGrid
              rows={rows}
              columns={tcolumns}
              className={classes[".MuiDataGrid-root"]}
              components={{
                Toolbar: CustomToolbar,
              }}
              disableSelectionOnClick
            />
          </div>)
      }



    </div>
  );
};

export default ProjectDetails;
