// Dependencies imports
import React, { useState, useEffect } from "react";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import { API } from "aws-amplify";
import Ann from "./AnnouncementFile.module.scss";
import swal from "sweetalert";
import {
  CircularProgress,
  TextareaAutosize,
  Backdrop,
  Fade,
  Modal,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
// Local imports
import { ReactComponent as Close } from "../../assets/svg/close_black_24dp.svg";
import { makeStyles } from "@material-ui/core/styles";
import { Tab } from "semantic-ui-react";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
// Styles imports
import Miniassignmentfile from "./miniassignmentfile";
import Add from "./AddScoreNew.module.scss";
import Add1 from "./AddScore.module.scss";
const useStyles = makeStyles({
  root: {
    "& .super-app-theme--header": {
      backgroundColor: Constants.app_dark_color,
    },
  },
  root1: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
});
const AddScoreNew = (props) => {
  let { handleClose, currentUsers } = props;

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [currentUserIndex, setCurrentUserIndex] = useState(0);
  const [response, setResponseQuiz] = useState({});
  const [notSubmitted, setNotSubmitted] = useState(true);
  const [showQ, setShowQ] = useState("Show");
  const [score, setScore] = useState(0);
  const [obj, setObj] = useState();
  const [spin, setSpin] = useState(true);
  const [scoreErr, setScoreErr] = useState("");
  const [open, setOpen] = useState(false);
  const [feedbackEnabled, setFeedbackEnabled] = useState({
    enabled: false,
    comments: "",
  });
  const [scoreDisable, setScoreDisable] = useState(false);

  const imgUrl = `https://${
    Constants.DOMAIN
  }/${config.aws_org_id.toLowerCase()}-resources/images/quiz-images/${currentUsers.oid.substring(
    0,
    5
  )}/`;
  const classes = useStyles();
  let userDetails = useSelector(authData);

  useEffect(() => {
    getAssignmentData(currentUsers.notGradedUsers[currentUserIndex]);

    if (
      currentUsers.miniassignmenttype != undefined &&
      (currentUsers.miniassignmenttype == 1 ||
        currentUsers.miniassignmenttype == "1")
    ) {
      setScoreDisable(true);
    }
  }, []);

  async function getAssignmentData(data) {
    setSpin(true);
    setNotSubmitted(true);
    const bodyParam = {
      body: {
        eid: data.eid,
        oid: config.aws_org_id,
        quizid: currentUsers.oid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        `/getAssignmentData`,
        bodyParam
      );

      setResponseQuiz(response);
      if (response.errorType === "string") {
        setNotSubmitted(true);
      } else {
        setNotSubmitted(false);
        setScore(response.score);
      }
      setSpin(false);
    } catch (error) {
      console.error(error);
    }
  }

  function nextUser() {
    let l = currentUsers.notGradedUsers.length - 1;
    if (currentUserIndex < l) {
      let i = currentUserIndex + 1;
      setCurrentUserIndex(i);
      setCurrentQuestion(0);
      getAssignmentData(currentUsers.notGradedUsers[i]);
    }
  }

  function prevUser() {
    if (currentUserIndex > 0) {
      let i = currentUserIndex - 1;
      setCurrentUserIndex(i);
      setCurrentQuestion(0);
      getAssignmentData(currentUsers.notGradedUsers[i]);
    }
  }

  function showHideQ() {
    if (showQ == "Show") {
      setShowQ("Hide");
    } else {
      setShowQ("Show");
    }
  }

  function answerPrev() {
    const prevQuestion = currentQuestion - 1;

    setCurrentQuestion(prevQuestion);
  }

  function answerNext() {
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < response.response.length) {
      setCurrentQuestion(nextQuestion);
    }
  }

  async function gradeAssignments(score) {
    let userDet = currentUsers.notGradedUsers[currentUserIndex];
    if (score == undefined || score == null || score == "" || score == " ") {
      setScoreErr("Score can't be empty!");
      return;
    } else if (score > 10) {
      setScoreErr("Score Must be less then 10");
      return;
    } else if (score < 0) {
      setScoreErr("Score Must be greater then 0");
      return;
    } else {
      setSpin(true);
      setScoreErr("");
      const bodyParam = {
        body: {
          eid: userDet.eid,
          obid: currentUsers.oid,
          oid: config.aws_org_id,
          score: score,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      try {
        await API.post(
          config.aws_cloud_logic_custom_name,
          `/gradeAssignments`,
          bodyParam
        );

        getAssignmentData(currentUsers.notGradedUsers[currentUserIndex]);
      } catch (error) {
        swal({
          title: "Oooops!",
          text: "something went wrong!",
          icon: "warning",
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
        console.error(error);
      }
    }
  }
  function handleChange(e) {
    if (e.target.value > 10) return false;
    if (e.target.value < 0) return false;
    setScore(e.target.value);
  }
  function addLink(item) {
    setObj(item);
    handleFileOpen();
  }
  const handleFileOpen = () => {
    setOpen(true);
  };

  const handleFileClose = () => {
    setOpen(false);
  };

  const panes = [
    {
      menuItem: "Feedback",
      render: () => (
        <Tab.Pane>
          <TextareaAutosize
            style={{
              width: "100%",
              resize: "none",
              fontSize: "15px",
              lineHeight: "1.3",
              padding: "10px",
            }}
            minRows={8}
            placeholder="Type the feedback here..."
            defaultValue={feedbackEnabled.comments}
            onChange={(e) => (feedbackEnabled.comments = e.target.value)}
          />
        </Tab.Pane>
      ),
    },
  ];

  async function updatefeedback() {
    if (!feedbackEnabled.enabled || feedbackEnabled.comments == "") {
      setScoreErr("feedback can't be empty!");
      return;
    } else {
      setSpin(true);
      const bodyParam = {
        body: {
          eid: currentUsers.notGradedUsers[currentUserIndex].eid,
          objid: currentUsers.oid,
          oid: config.aws_org_id,
          fdcomments: feedbackEnabled.comments,
          bcourseid: userDetails.grade.btid,
          cname: userDetails.grade.ttitle,
          uname: currentUsers.unit,
          mname: currentUsers.ntitle,
          bpid: userDetails.curprgcou.bpid,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      try {
        await API.post(
          config.aws_cloud_logic_custom_name,
          `/updateAssignorMiniAssign`,
          bodyParam
        );
        handleClose();
        setSpin(false);
      } catch (error) {
        console.error(error);
      }
    }
  }

  const panes1 = [
    {
      menuItem: "Answer By student",
      render: () => (
        <Tab.Pane>
          <div>
            <textarea
              value={
                response.resubmittedData == undefined ||
                response?.resubmittedData.length == 0
                  ? response.response[currentQuestion].response
                  : response.resubmittedData[currentQuestion].response
              }
              style={{
                width: "100%",
                padding: "15px",
                borderRadius: "6px",
                height: "100px",
                whiteSpace: "normal",
                border: "2px solid black",
                fontSize: "1.4rem",
                lineHeight: "2rem",
              }}
            />

            <div>
              {response.resubmittedData != undefined &&
              response.resubmittedData[currentQuestion].filename !==
                undefined &&
              response.resubmittedData[currentQuestion].filename !== "" ? (
                <div>
                  <p
                    className={Ann.link}
                    style={{
                      color: "#007bff",
                      cursor: "pointer",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      addLink(response.resubmittedData[currentQuestion]);
                    }}
                  >
                    {response?.resubmittedData[currentQuestion]?.filename
                      ?.length > 18
                      ? response.resubmittedData[
                          currentQuestion
                        ].filename.substr(13)
                      : response.resubmittedData[currentQuestion].filename}
                  </p>
                </div>
              ) : (
                <>
                  {response.response[currentQuestion].filename !== undefined &&
                  response.response[currentQuestion].filename !== "" ? (
                    <div>
                      <p
                        className={Ann.link}
                        style={{
                          color: "#007bff",
                          cursor: "pointer",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                        onClick={() => {
                          addLink(response.response[currentQuestion]);
                        }}
                      >
                        {response?.response[currentQuestion]?.filename?.length >
                        18
                          ? response.response[currentQuestion].filename.substr(
                              13
                            )
                          : response.response[currentQuestion].filename}
                      </p>
                    </div>
                  ) : null}
                </>
              )}
            </div>
            <div>
              {response.resubmittedData != undefined &&
              response.resubmittedData[currentQuestion].ourl !== undefined ? (
                <a
                  href={response.resubmittedData[currentQuestion].ourl}
                  target="blank"
                  style={{ fontSize: "14" }}
                >
                  Click here to view in new tab
                </a>
              ) : (
                <>
                  {response.response[currentQuestion].ourl !== undefined ? (
                    <a
                      href={response.response[currentQuestion].ourl}
                      target="blank"
                      style={{ fontSize: "14" }}
                    >
                      Click here to view in new tab
                    </a>
                  ) : null}
                </>
              )}
            </div>
            <div>
              {currentQuestion > 0 ? (
                <button className={Add1.prev1} onClick={() => answerPrev()}>
                  Prev
                </button>
              ) : null}

              {currentQuestion + 1 === response.response.length ? null : (
                <button className={Add1.next1} onClick={() => answerNext()}>
                  Next
                </button>
              )}
            </div>
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Feedback",
      render: () => (
        <Tab.Pane>
          <div>
            {response.feedback != undefined ? (
              <div>
                {response.feedback.map((feedback) => (
                  <div>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <div>
                            <p
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                                marginBottom: "9px",
                                color: "#333333",
                                overflow: "auto",
                              }}
                            >
                              {feedback.givenby}
                            </p>
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                                marginBottom: "9px",
                                color: "#333333",
                                overflow: "auto",
                              }}
                            >
                              {feedback.message}
                            </p>
                          </div>
                        }
                        secondary={
                          <div>
                            <div style={{ fontSize: "12px" }}>
                              {feedback.timeStamp}
                            </div>
                          </div>
                        }
                      />
                    </ListItem>
                    <hr />
                  </div>
                ))}
              </div>
            ) : null}
          </div>

          <TextareaAutosize
            style={{
              width: "100%",
              resize: "none",
              fontSize: "15px",
              lineHeight: "1.3",
              padding: "10px",
            }}
            minRows={6}
            placeholder="Type the feedback here..."
            defaultValue={feedbackEnabled.comments}
            onChange={(e) => (feedbackEnabled.comments = e.target.value)}
          />

          <button
            disabled={scoreDisable}
            onClick={() => updatefeedback()}
            className={Add1.fdsubmit}
          >
            Submit Feedback
          </button>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Resubmitted Answer",
      render: () => (
        <Tab.Pane>
          {response.resubmittedData != undefined ? (
            <div>
              <textarea
                value={response.response[currentQuestion].response}
                style={{
                  width: "100%",
                  padding: "15px",
                  borderRadius: "6px",
                  height: "100px",
                  whiteSpace: "normal",
                  border: "2px solid black",
                  fontSize: "1.4rem",
                  lineHeight: "2rem",
                }}
              />

              <div>
                {response.response[currentQuestion].filename !== undefined &&
                response.response[currentQuestion].filename !== "" ? (
                  <div>
                    <p
                      className={Ann.link}
                      style={{
                        color: "#007bff",
                        cursor: "pointer",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                      onClick={() => {
                        addLink(response.response[currentQuestion]);
                      }}
                    >
                      {response?.response[currentQuestion]?.filename?.length >
                      18
                        ? response.response[currentQuestion].filename.substr(13)
                        : response.response[currentQuestion].filename}
                    </p>
                  </div>
                ) : null}
              </div>
              <div>
                {response.response[currentQuestion].ourl !== undefined ? (
                  <a
                    href={response.response[currentQuestion].ourl}
                    target="blank"
                    style={{ fontSize: "14" }}
                  >
                    Click here to view in new tab
                  </a>
                ) : null}
              </div>
              <div style={{ display: "flex", justifyContent: "" }}>
                {currentQuestion > 0 ? (
                  <button className={Add1.prev1} onClick={() => answerPrev()}>
                    Prev
                  </button>
                ) : null}

                {currentQuestion + 1 === response.response.length ? null : (
                  <button className={Add1.next1} onClick={() => answerNext()}>
                    Next
                  </button>
                )}
              </div>
            </div>
          ) : (
            <>No DATA</>
          )}
        </Tab.Pane>
      ),
    },
  ];
  return (
    <div>
      {spin === false ? (
        notSubmitted === false ? (
          <div className={Add.mainpopup}>
            <div className={Add.iconholder}>
              <Close onClick={handleClose} className={Add.icon} />
            </div>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={open}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <Miniassignmentfile
                  handleFileClose={handleFileClose}
                  obj={obj}
                />
              </Fade>
            </Modal>

            <div
              style={{
                color: "red",
                marginBottom: "12px",
                marginTop: "0px",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              {scoreErr}
            </div>

            <div className={Add.overholder}>
              <div style={{ textAlign: "left" }}>
                <h4 className={Add.over}>
                  {currentUsers.notGradedUsers[currentUserIndex].name}
                </h4>

                <h4 className={Add.over}>
                  {currentUsers.notGradedUsers[currentUserIndex].usn}
                </h4>

                <h4 className={Add.over}>
                  {currentUsers.notGradedUsers[currentUserIndex].email}
                </h4>
              </div>

              <h4 className={Add.over}>
                Question {currentQuestion + 1} / {response.response.length}
              </h4>
              <button className={Add.next} onClick={() => showHideQ()}>
                {showQ}
              </button>

              <h4 className={Add.over}>
                User {currentUserIndex + 1} /{" "}
                {currentUsers.notGradedUsers.length}
              </h4>
              <h4 className={Add.over}>Overall Score: </h4>
              <input
                value={score}
                type="number"
                min="0"
                max="10"
                onChange={(e) => handleChange(e)}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    gradeAssignments(score);
                  }
                }}
                className={Add.overinput}
              ></input>
              <button
                onClick={() => gradeAssignments(score)}
                className={Add.submit}
              >
                Submit
              </button>

              <div style={{ display: "flex", gap: "3rem" }}>
                {currentUserIndex > 0 ? (
                  <button className={Add.next} onClick={() => prevUser()}>
                    Previous User
                  </button>
                ) : null}

                {currentUserIndex + 1 ===
                currentUsers.notGradedUsers.length ? null : (
                  <button className={Add.next} onClick={() => nextUser()}>
                    Next User
                  </button>
                )}
              </div>
            </div>

            {showQ == "Hide" ? (
              <div className={Add.questionsection}>
                <div
                  className={Add.questiontext}
                  dangerouslySetInnerHTML={{
                    __html: response.response[currentQuestion].istem,
                  }}
                ></div>
                {response.response[currentQuestion].imageurl ? (
                  <div className={Add.imagecontainer}>
                    <img
                      src={`${imgUrl}${response.response[currentQuestion].imageurl}`}
                      alt="item"
                    />
                  </div>
                ) : null}
              </div>
            ) : null}

            <div className={Add.areaholder}>
              <Tab panes={panes1} />
            </div>

            {feedbackEnabled.enabled && (
              <div style={{ padding: "10px" }} className="tabclass">
                <Tab panes={panes} style={{ marginTop: "20px" }} />
                <button
                  disabled={scoreDisable}
                  onClick={() => updatefeedback()}
                  className={Add1.fdsubmit}
                >
                  Submit Feedback
                </button>
              </div>
            )}
          </div>
        ) : (
          <div>
            <h3 className={classes.header}>
              {currentUsers.notGradedUsers[currentUserIndex].name} Not Submitted
            </h3>
            <button className={Add.next} onClick={() => prevUser()}>
              Previous User
            </button>
            <button className={Add.next} onClick={() => nextUser()}>
              Next User
            </button>
          </div>
        )
      ) : (
        <div className={classes.root}>
          <CircularProgress style={{ color: Constants.main_color_1 }} />
        </div>
      )}
    </div>
  );
};

export default AddScoreNew;
