// WARNING: DO NOT EDIT. This file is Auto-Generated by AWS Mobile Hub. It will be overwritten.

// Copyright 2017-2018 Amazon.com, Inc. or its affiliates (Amazon). All Rights Reserved.
// Code generated by AWS Mobile Hub. Amazon gives unlimited permission to
// copy, distribute and modify it.

// AWS Mobile Hub Project Constants
const oepdev = {
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "d34uu2se50",
      name: "JS-AUTHORING",
      description: "",
      endpoint:
        "https://d34uu2se50.execute-api.us-east-1.amazonaws.com/JS369235",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "JS-AUTHORING",
  aws_cognito_identity_pool_id:
    "us-east-1:7ed6b066-f5b3-44d6-b929-020eeda966b5",
  aws_cognito_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "d1hfr1iyugj21x.cloudfront.net",
  aws_mandatory_sign_in: "enable",
  aws_project_region: "us-east-1",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_YaniOSdgK",
  aws_user_pools_web_client_id: "37q3v6e95jlhleufj06iav6ocr",
  aws_cloud_logic_custom_endpoint:
    "https://d34uu2se50.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "JSSAHER",
  // },
};

const oepProd = {
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "1ynia49yb3",
      name: "JSS-AUTHORING",
      description: "",
      endpoint:
        "https://1ynia49yb3.execute-api.us-east-1.amazonaws.com/Production",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "JSS-AUTHORING",
  aws_cognito_identity_pool_id:
    "us-east-1:053fb1d7-4265-4cc7-bf1b-6ab65fcf96cc",
  aws_cognito_region: "us-east-1",
  aws_content_delivery: "enable",
  aws_content_delivery_bucket: "jssaher-oep",
  aws_content_delivery_bucket_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "dxxl6kvuekma1.cloudfront.net",
  aws_mandatory_sign_in: "enable",
  aws_project_region: "us-east-1",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_iHxFujIKX",
  aws_user_pools_web_client_id: "6o94ebru8ibmbidv238uurjcqo",
  aws_cloud_logic_custom_endpoint:
    "https://1ynia49yb3.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "JSSAHER",
};

const qsd = {
  //aws_app_analytics: 'disable',
  aws_auth_facebook: "enable",
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "b3dpahnxc5",
      name: "QSD-PLATFORM",
      description: "",
      endpoint: "https://b3dpahnxc5.execute-api.us-east-1.amazonaws.com/235689",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_E: [
    {
      id: "b3dpahnxc5",
      name: "QSD-PLATFORM",
      description: "",
      endpoint: "https://b3dpahnxc5.execute-api.us-east-1.amazonaws.com/235689",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "QSD-PLATFORM",
  aws_cloud_logic_custom_name_E: "QSD-PLATFORM",
  aws_cognito_identity_pool_id:
    "us-east-1:9353bbc9-07fb-4f0c-98a8-8997413585bf",
  aws_cognito_region: "us-east-1",
  aws_content_delivery: "enable",
  aws_content_delivery_bucket: "qsd-resources",
  aws_content_delivery_bucket_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "d3cb79yue9i8q3.cloudfront.net",
  aws_facebook_app_id: "281569732265049",
  aws_facebook_app_permissions: "public_profile",
  aws_mandatory_sign_in: "enable",
  //aws_mobile_analytics_app_id: 'f085468f71664440ba6153ccb1036a9a',
  //aws_mobile_analytics_app_region: 'us-east-1',
  //aws_project_id: '5b55d3ef-e764-4657-b5c6-c0b023e8790e',
  //aws_project_name: 'EnhanzedRN',
  aws_project_region: "us-east-1",
  //aws_push_apns: 'enable',
  //aws_push_pinpoint: 'enable',
  aws_resource_name_prefix: "qsd-resources",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_2JYLYVl9h",
  aws_user_pools_web_client_id: "520ilpp3444pqmlstg9rc3b5nu",
  aws_cloud_logic_custom_endpoint:
    "https://d34uu2se50.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "QSD",
  aws_org_id_E: "QSD",
  aws_user_pools_id_E: "us-east-1_2JYLYVl9h",
  aws_user_pools_web_client_id_E: "520ilpp3444pqmlstg9rc3b5nu",
  //aws_mobile_analytics_app_id_E: 'f085468f71664440ba6153ccb1036a9a',
  aws_content_delivery_cloudfront_domain_E: "d3cb79yue9i8q3.cloudfront.net",
  aws_cognito_identity_pool_id_E:
    "us-east-1:9353bbc9-07fb-4f0c-98a8-8997413585bf",
  aws_cloud_logic_custom_endpoint_E:
    "https://b3dpahnxc5.execute-api.us-east-1.amazonaws.com/235689",
  aws_ctype: 0,
  aws_ctype_E: 0,
  aws_cloud_logic_custom_E1: [
    {
      id: "b3dpahnxc5",
      name: "QSD-PLATFORM",
      description: "",
      endpoint: "https://b3dpahnxc5.execute-api.us-east-1.amazonaws.com/235689",
      region: "us-east-1",
    },
  ],
  // oauth: {
  //   domain: 'enhanzedprod.auth.us-east-1.amazoncognito.com',
  //   scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  //   redirectSignIn: 'enhanzed://',
  //   redirectSignOut: 'enhanzed://',
  //   responseType: 'code',
  // },
};

// -------------------------------------------------------------------------------------------------------------------------------------

// New instances are created from here

// LearnED Prod instance
const learnedProd = {
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "cqkcbribl2",
      name: "LEARNED-AUTHORING",
      description: "",
      endpoint:
        "https://cqkcbribl2.execute-api.us-east-1.amazonaws.com/LEARNED_PROD",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "LEARNED-AUTHORING",
  aws_cognito_identity_pool_id:
    "us-east-1:e0aebaeb-5794-4946-bd55-71645255ad17",
  aws_cognito_region: "us-east-1",
  aws_content_delivery: "enable",
  aws_content_delivery_bucket: "ed-prod-learned",
  aws_content_delivery_bucket_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "d1erhpqukc9wx5.cloudfront.net",
  aws_mandatory_sign_in: "enable",
  aws_project_region: "us-east-1",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_Tg6DPRzxr",
  aws_user_pools_web_client_id: "6tk4bjqsrlvh58fvt7agoc2u97",
  aws_cloud_logic_custom_endpoint:
    "https://cqkcbribl2.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "LEARNED",
};

// T John prod instance
const tjohn = {
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "cqkcbribl2",
      name: "LEARNED-AUTHORING",
      description: "",
      endpoint:
        "https://cqkcbribl2.execute-api.us-east-1.amazonaws.com/LEARNED_PROD",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "LEARNED-AUTHORING",
  aws_cognito_identity_pool_id:
    "us-east-1:79bc7671-28d6-4cea-93e3-5f3716135dd7",
  aws_cognito_region: "us-east-1",
  aws_content_delivery: "enable",
  aws_content_delivery_bucket: "ed-prod-tjohn",
  aws_content_delivery_bucket_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "d39amklve0smgh.cloudfront.net",
  aws_mandatory_sign_in: "enable",
  aws_project_region: "us-east-1",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_n3b7dKtWe",
  aws_user_pools_web_client_id: "3i5rmhvbr0j20fg2cg9g4ka4cd",
  aws_cloud_logic_custom_endpoint:
    "https://cqkcbribl2.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "TJOHN",
};

// JSS Mysore Prod instance
const jssmys = {
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "cqkcbribl2",
      name: "LEARNED-AUTHORING",
      description: "",
      endpoint:
        "https://cqkcbribl2.execute-api.us-east-1.amazonaws.com/LEARNED_PROD",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "LEARNED-AUTHORING",
  aws_cognito_identity_pool_id:
    "us-east-1:e025a8e3-00cb-4df3-92d9-f4f89d8f0d83",
  aws_cognito_region: "us-east-1",
  aws_content_delivery: "enable",
  aws_content_delivery_bucket: "ed-prod-jssahermysore",
  aws_content_delivery_bucket_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "d329yd2lem62zp.cloudfront.net",
  aws_mandatory_sign_in: "enable",
  aws_project_region: "us-east-1",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_j2JhCiqOF",
  aws_user_pools_web_client_id: "1bnskq40u9b3cf5afmpatqfd9o",
  aws_cloud_logic_custom_endpoint:
    "https://cqkcbribl2.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "JSSAHERMYSORE",
};

// JSS Ooty Prod instance
const jssooty = {
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "cqkcbribl2",
      name: "LEARNED-AUTHORING",
      description: "",
      endpoint:
        "https://cqkcbribl2.execute-api.us-east-1.amazonaws.com/LEARNED_PROD",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "LEARNED-AUTHORING",
  aws_cognito_identity_pool_id:
    "us-east-1:c2c34952-f862-4c5c-9c10-138e9a731aae",
  aws_cognito_region: "us-east-1",
  aws_content_delivery: "enable",
  aws_content_delivery_bucket: "ed-prod-jssaherooty",
  aws_content_delivery_bucket_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "decrt8qnl2rk0.cloudfront.net",
  aws_mandatory_sign_in: "enable",
  aws_project_region: "us-east-1",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_3xjCjaelZ",
  aws_user_pools_web_client_id: "5d2bu9k7afjj02t57cfjdgc2vn",
  aws_cloud_logic_custom_endpoint:
    "https://cqkcbribl2.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "JSSAHEROOTY",
};

// JSS Noida Prod instance
const jssnoida = {
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "cqkcbribl2",
      name: "LEARNED-AUTHORING",
      description: "",
      endpoint:
        "https://cqkcbribl2.execute-api.us-east-1.amazonaws.com/LEARNED_PROD",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "LEARNED-AUTHORING",
  aws_cognito_identity_pool_id:
    "us-east-1:a1f9b82c-cfb1-41a4-bd4f-36d5e5df5fca",
  aws_cognito_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_bucket: "ed-prod-jssahernoida",
  aws_content_delivery_bucket_region: "us-east-1",
  aws_content_delivery_cloudfront_domain: "d2yx4k7ijlk17h.cloudfront.net",
  aws_mandatory_sign_in: "enable",
  aws_project_region: "us-east-1",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_rTjJJrt4H",
  aws_user_pools_web_client_id: "4fah8fo1qpggec2llp6ifni26g",
  aws_cloud_logic_custom_endpoint:
    "https://d34uu2se50.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "JSSAHERNOIDA",
};

// Sarada Vilas Prod instance
const saradavilas = {
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "cqkcbribl2",
      name: "LEARNED-AUTHORING",
      description: "",
      endpoint:
        "https://cqkcbribl2.execute-api.us-east-1.amazonaws.com/LEARNED_PROD",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "LEARNED-AUTHORING",
  aws_cognito_identity_pool_id:
    "us-east-1:c0c11eda-620c-476b-9eea-2650fd93ff1a",
  aws_cognito_region: "us-east-1",
  aws_content_delivery: "enable",
  aws_content_delivery_bucket: "ed-prod-saradavilas",
  aws_content_delivery_bucket_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "d2zrg5m4p3ti7g.cloudfront.net",
  aws_mandatory_sign_in: "enable",
  aws_project_region: "us-east-1",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_6LDoWFMjq",
  aws_user_pools_web_client_id: "74ekjvp8ccppnmvg9uf0jddkkm",
  aws_cloud_logic_custom_endpoint:
    "https://cqkcbribl2.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "SARADAVILAS",
};

// JSS Mauritius Prod instance
const oepmauritius = {
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "mcmy0dwsok",
      name: "OEPORG-AUTHORING",
      description: "",
      endpoint:
        "https://mcmy0dwsok.execute-api.us-east-1.amazonaws.com/OEPORG-AUTHORING",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "OEPORG-AUTHORING",
  aws_cognito_identity_pool_id:
    "us-east-1:52285fee-e238-450f-a1af-829712db4ebf",
  aws_cognito_region: "us-east-1",
  aws_content_delivery: "enable",
  aws_content_delivery_bucket: "jssaher-oep",
  aws_content_delivery_bucket_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "d1fgflxnncw0ta.cloudfront.net",
  aws_mandatory_sign_in: "enable",
  aws_project_region: "us-east-1",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_6P5Y77l80",
  aws_user_pools_web_client_id: "5o6061td35ove8to8q1uqajgih",
  aws_cloud_logic_custom_endpoint:
    "https://mcmy0dwsok.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "OEPMAURITIUS",
};

// NIEIT
const nieit = {
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "cqkcbribl2",
      name: "LEARNED-AUTHORING",
      description: "",
      endpoint:
        "https://cqkcbribl2.execute-api.us-east-1.amazonaws.com/LEARNED_PROD",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "LEARNED-AUTHORING",
  aws_cognito_identity_pool_id:
    "us-east-1:51745abf-9fcf-4b7e-a781-191aa111de9e",
  aws_cognito_region: "us-east-1",
  aws_content_delivery: "enable",
  aws_content_delivery_bucket: "ed-prod-nieit",
  aws_content_delivery_bucket_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "d33j0q3ff48itq.cloudfront.net",
  aws_mandatory_sign_in: "enable",
  aws_project_region: "us-east-1",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_AQTorejCG",
  aws_user_pools_web_client_id: "22okkf1hvempq108vlvtedc6ck",
  aws_cloud_logic_custom_endpoint:
    "https://cqkcbribl2.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "NIEIT",
};

// SDMIT
const sdmit = {
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "cqkcbribl2",
      name: "LEARNED-AUTHORING",
      description: "",
      endpoint:
        "https://cqkcbribl2.execute-api.us-east-1.amazonaws.com/LEARNED_PROD",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "LEARNED-AUTHORING",
  aws_cognito_identity_pool_id:
    "us-east-1:9aa214be-1886-4626-b951-1996d68dbd44",
  aws_cognito_region: "us-east-1",
  aws_content_delivery: "enable",
  aws_content_delivery_bucket: "ed-prod-sdmit",
  aws_content_delivery_bucket_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "d2ofkkgvyysxcg.cloudfront.net",
  aws_mandatory_sign_in: "enable",
  aws_project_region: "us-east-1",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_SimZ3i9M4",
  aws_user_pools_web_client_id: "6mdqh8pgff90ks5b5tlirv12kp",
  aws_cloud_logic_custom_endpoint:
    "https://cqkcbribl2.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "SDMIT",
};

export default oepProd;
