// Dependencies imports
import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import swal from 'sweetalert';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// Local imports

// Styles imports
import Send from './ExamSubmit.module.scss';
import { authData } from "../../redux/auth/authSlice";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import { API } from "aws-amplify";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        heigth: "100%",
        alignItems: "center",
        justifyContent: "center",
        background: "transparent"
    },
}));


const ExamSubmit = (props) => {
    let { handleExamClose ,getFaculty} = props;
    const [subject, setSubject] = useState("");
    const [examCode, setExamCode] = useState("");
    const [firstDate, setFirstDate] = useState("");
    const [lastDate, setLastDate] = useState("");
    const [url, setUrl] = useState("");
    const [error1, setError1] = useState(false);
    const [error2, setError2] = useState(false);
    const [error3, setError3] = useState(false);
    const [error4, setError4] = useState(false);
    const [error5, setError5] = useState(false);
    const [link, setLink] = useState(false);
    const [action, setAction] = useState("");
    let userDetails = useSelector(authData);
    const [spin, setSpin] = useState(false);
    const [errmsg, setErrmsg] = useState("");
    const classes = useStyles();
    const [checked, setChecked] = React.useState(false);



    async function onsubmit() {
        // if (action == "" || action.replace(/\s/g, '').length === 0 || action == undefined) {
          
        //     setSpin(false);
        //     setError1(true);
        //     return;
        // }
        setError1(false);
        setError2(false);
        setError3(false);
        setError4(false);
        setError5(false)
        if (subject == "" || subject.replace(/\s/g, '').length === 0 || subject == undefined) {
          
            setSpin(false);
            setError2(true);
            return;
        }
        else if (examCode == "" || examCode.replace(/\s/g, '').length === 0 || examCode == undefined) {
            setSpin(false);
            setError3(true);
            return;
        }
        else if (firstDate == "" || firstDate.replace(/\s/g, '').length === 0 || firstDate == undefined) {
            setSpin(false);
            setError4(true);
            return;
        }
        else if (firstDate == "" || firstDate.replace(/\s/g, '').length === 0 || firstDate == undefined) {
            setSpin(false);
            setError5(true);
            return;
        }
        else if (firstDate > lastDate) {
            swal("First date Must be less than Last Date");
            return;
        }
            
     
        
        setSpin(true);
        const bodyParam = {
            body: {
                oid: config.aws_org_id,
                bpid: userDetails.curprgcou.bpid,
                examcode: examCode,
                sname: subject,
                cdd:"yes",
                eligibilityApptDateFirst: firstDate,
                eligibilityApptDateLast:lastDate,
            },
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        // if (action == "CDD")
        //     bodyParam.body.cdd = "yes";
        // else if (action == "EAD")
        //     bodyParam.body.ead = "yes";
        // else {
        //     bodyParam.body.cdd = "yes";
        //     bodyParam.body.ead = "yes";
        // }
        if (checked == true) {
            bodyParam.body.ead = "yes";
        }
        try {
            // alert("response" + JSON.stringify(bodyParam.body));
            const response = await API.post(
                config.aws_cloud_logic_custom_name,
                `/updatExamdetail`,
                bodyParam
            );
            // let response = null;
            // alert("hii"+JSON.stringify(response));
            if (response == "Entered id is not a user" || response == "User not found")
            
            {
                swal({
                    title: "Error",
                    text: response,
                    icon: "error",
                    dangerMode: false,
                })
                    .then(willDelete => {
                        if (willDelete) {
                            setSpin(false);
                            // handleUserClose();
                            // getFaculty();
                        }
                    });
                   
            }

            else {
                swal({
                    title: "Success",
                    text: response,
                    icon: "success",
                    dangerMode: false,
                })
                    .then(willDelete => {
                        if (willDelete) {
                            setSpin(false);
                            handleExamClose();
                            
                        }
                    });
            }
       
          
            handleExamClose();
            setSpin(false);
            getFaculty();
        }
        catch (error) {
            console.error(error);
            swal({
                title: "Oooops!",
                text: "something went wrong!",
                icon: "warning",
                dangerMode: true,
            })
                .then(willDelete => {
                    if (willDelete) {
                        swal("Contact Developer");
                        setSpin(false);
                    }
                });
        }
    }
    const handleChangeforcheckbox = (event) => {
        setChecked(event.target.checked);
      };
    
    function handleChange(e) {

        setAction(e.target.value)
    }


    return (
        <div style={{width: "100%", hight: "100%"}}>
            {spin === false ?
        <div className={Send.popupcontainer}>
            <h3 className={Send.header}>Exam Submit</h3>
            
            <div className={Send.inputholder}>
                <lable className={Send.caution}>{error2 == true ? "Please enter Subject" : ""}</lable>
                <h4 className={Send.inputlable}>Subject Name</h4>
                <input type="text" className={Send.inputbox} value={subject} onChange={(e) => { setSubject(e.target.value) }}></input>
            </div>
            <div className={Send.inputholder}>
                <lable className={Send.caution}>{error3 == true ? "Please enter Exam Code" : ""}</lable>
                <h4 className={Send.inputlable}>Exam Code</h4>
                <input type="text" className={Send.inputbox} value={examCode} onChange={(e) => { setExamCode(e.target.value) }}></input>
                    </div>
                    <div className={Send.inputholder}>
                <lable className={Send.caution}>{error4 == true ? "Please enter First Date" : ""}</lable>
                        <h4 className={Send.inputlable}>First Date</h4>
                <input type="text" placeholder = "YYYY/MM/DD HH:MM:SS (24 HRS)" className={Send.inputbox} onChange={(e) => { setFirstDate(e.target.value) }}  value={firstDate} /><br />
                    </div>
                    <div className={Send.inputholder}>
                <lable className={Send.caution}>{error5 == true ? "Please enter Last Date" : ""}</lable>
                        <h4 className={Send.inputlable}>Last Date</h4>
                <input type="text" placeholder = "YYYY/MM/DD HH:MM:SS (24 HRS)" className={Send.inputbox} onChange={(e) => { setLastDate(e.target.value) }}  value={lastDate} /><br />
                    </div>
                    <lable className={Send.caution}>{error1 == true ? "Please select one" : ""}</lable>
            <div className={Send.radioholder}>
                {/* <div className={Send.inholder}>
                    <input type="radio" value="CDD" name="type-select" className={Send.input1} onChange={(e) => { handleChange(e) }}></input>
                    <span for="mail" className={Send.radiolable} >CDD</span>
                </div> */}
                <div className={Send.inholder}>
                            {/* <input type="radio" value="EAD" name="type-select" className={Send.input1} onChange={(e) => { handleChange(e) }}></input> */}
                            <FormControlLabel
      control={<Checkbox checked={checked} onChange={handleChangeforcheckbox} />}
      label="EAD"
    />
                    {/* <span className={Send.radiolable} for="notification" >EAD</span> */}
                </div>
                {/* <div className={Send.inholder}>
                    <input type="radio" value="BOTH" name="type-select" className={Send.input1} onChange={(e) => { handleChange(e) }}></input>
                    <span className={Send.radiolable} for="both"  >Both</span>
                </div> */}
            </div>
            {/* <input type="checkbox" value="true" onClick={(e) => clicking(e)} /><span className={Send.radiolable}>Include link</span>
            {link ? (
                <div className={Send.inputholder}>
                    <lable className={Send.caution}>{error4 == true ? "Please include valid url" : ""}</lable>
                    <h4 className={Send.inputlable}>Url</h4>
                    <input type="text" id="linkbox" className={Send.inputbox} value={url} onChange={(e) => { setUrl(e.target.value) }}></input>
                </div>)
                : null} */}
            <div className={Send.buttonholder}>
                <button className={Send.cancel} onClick={handleExamClose}>Cancel</button>
                <button className={Send.submit} onClick={() => onsubmit()}>Submit</button>
            </div>


        </div> : <div style={{width: "100%", height: "100%", display:"flex", alignItems: "center", justifyContent:"center"}}><CircularProgress style={{color: Constants.main_color_1}} /></div>}
        </div>
    );
};

export default ExamSubmit;