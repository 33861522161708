// Dependencies imports
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

// Local imports
import { Constants } from "../../config/constants";

// Styles imports
import Add from './AddAnnouncement.module.scss';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        heigth: "100%",
        alignItems: "center",
        justifyContent: "center",
        background: "transparent"
    },
}));

const AddUser = (props) => {
    // const [spin, setSpin] = useState(true);
    const classes = useStyles();

    let { handleClose, onSubmit, spin ,} = props;



    return (
        <div>
            {spin === false ?
                <div className={Add.mainpopup}>
                    <h3 className={Add.header}>Are you sure?</h3>
                    <div className={Add.buttonholder}>
                        <button className={Add.cancel} onClick={handleClose}>NO</button>
                        <button className={Add.submit} onClick={onSubmit} >Yes! I am sure</button>

                    </div>
                </div> : <div className={classes.root}>
                    <CircularProgress style={{ color: Constants.main_color_1 }} /></div>}
        </div>
    );
};

export default AddUser;