// Dependencies imports
import React, { useState } from 'react';
import swal from 'sweetalert';
import Paper from '@material-ui/core/Paper';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";

// Local imports

// Styles imports
import Add from './AddEvent.module.scss';

const DisbleUser = (props) => {
    let { handleClose ,editUserData,getUsersDetails} = props;
    // alert("1"+JSON.stringify(editEventData))
   

    let userDetails = useSelector(authData);


  

    const onsubmit = () => {
    
        updateuser();
        
       
           
          
    };
    async function updateuser()
    {
       
        const bodyParam = {
            body: {
                
                oid: config.aws_org_id,
                eid:editUserData.col9,
                action: editUserData.col5,
                
               
            },
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
          };
    
          try {
            const response = await API.post(
              config.aws_cloud_logic_custom_name,
              Constants.GET_UPDATEUSERSATUS,
              bodyParam
              //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
            );
            // const users = response;
             
            swal({
                title: "Success",
                text: "User Disabled successfully",
                icon: "success",
                dangerMode: false,
              })
              .then(willDelete => {
                if (willDelete) {
                    handleClose();
              getUsersDetails()
                }
              });
             ;
           
          } catch (error) {
            swal({
                title:  "Oooops!",
                text:  "something went wrong!",
                icon: "warning",
                dangerMode: true,
              })
              .then(willDelete => {
                if (willDelete) {
                   
                }
              });
            console.log("getUserError", error);
          }
          console.log("Mount");
    }
    return (
        <div className={Add.mainpopup}>
            {/* <h3 className={Add.header}>Delete Event</h3> */}
            <div className={Add.form}>
                <form>
                     <div style={{color: "#cc0000", marginBottom: "12px", marginTop: "-10px", textAlign:"start", marginLeft: "20%",fontSize:"25px"}}>you want to disable the user ?</div>
                    {/* <input type="date" className={Add.date} onChange={onChangeHandlerEventDate} value={eventDate} /><br />
                    <div style={{color: "#cc0000", marginBottom: "12px", marginTop: "-10px", textAlign:"start", marginLeft: "20%"}}>{eventNameErr}</div>
                    <textarea placeholder="Type your event here..." rows={5} className={Add.area} onChange={onChangeHandlerEventName} value={evnentName} ></textarea>
                    <div className={Add.cautionholder}>
                        <h4 className={Add.caution}>* Event description should be less than 160 characters.</h4>
                    </div>
                    <input type="text" placeholder={"Add your link"} className={Add.date} onChange={onChangeHandlerEventLink} value={eventLink} ></input> */}
                    <div className={Add.buttonholder}>
                        <button className={Add.submit} onClick={onsubmit} >Yes</button>
                        <button className={Add.cancel}  onClick={handleClose}>NO</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default DisbleUser;