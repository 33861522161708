// Dependencies imports
import React, { useState } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
} from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import PopAssignment from "./PopAssignment";
import { Constants } from "../../config/constants";
import useWindowDimensions from "../../modules/Window/Window";
import moment from "moment";

// Local imports

// Styles imports

// Data for Instructor Grid
const columns = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "score",
    headerName: "Score",
    flex: 1,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "usn",
    headerName: "Candiate id",
    flex: 1,
    headerClassName: "super-app-theme--header",
  },
];

const useStyles = makeStyles({
  root: {
    "& .super-app-theme--header": {
      backgroundColor: Constants.main_color_1,
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
  paper: {
    backgroundColor: "white",

    height: "100px",
    width: "500px",
    borderRadius: "4px",
    border: "none",
    padding: "20px",
  },
});
// Start of Instructor Grid Component
const AssignmentDetailsGrid = (props) => {
  let { rows, currentData, quizId } = props;
  const [open, setOpen] = useState(false);
  const [responseQuiz, setResponseQuiz] = useState({});
  const [userDet, setUserDetail] = useState({});
  const [notSubmitted, setNotSubmitted] = useState(false);
  const [usetData, setUserData] = useState("");
  const { height, width } = useWindowDimensions();
  let userDetails = useSelector(authData);
  const classes = useStyles();

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={{
            fileName: `Report-${userDetails.curprgcou.bname}-${
              userDetails.curprgcou.pname
            }-${
              userDetails.grade != undefined ? userDetails.grade.ttitle : ""
            }-${currentData.title}-${moment().format("DD/MM/YYYY")}`,
          }}
        />
      </GridToolbarContainer>
    );
  }
  const handleCellClick = (param, event) => {
    setOpen(true);
    setUserData(param.row);
  };
  function handleClose() {
    setOpen(false);
  }
  function handleCloseNotSubmitted() {
    setNotSubmitted(false);
  }

  return (
    <div
      className={classes.root}
      style={{ height: height - 200, width: "100%" }}
    >
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        onClose={handleClose}
      >
        <Fade in={open}>
          <PopAssignment
            handleClose={handleClose}
            response={responseQuiz}
            currentData={currentData}
            userDet={userDet}
            usetData={usetData}
            quizId={quizId}
          />
        </Fade>
      </Modal>
      <DataGrid
        rows={rows}
        columns={columns}
        components={{ Toolbar: CustomToolbar }}
        onCellClick={handleCellClick}
        disableSelectionOnClick
        className={classes[".MuiDataGrid-root"]}
      />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={notSubmitted}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={notSubmitted}>
          <div className={classes.paper}>
            <p onClick={handleCloseNotSubmitted}>X</p>
            <h3>Not Submitted</h3>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default AssignmentDetailsGrid;
