// Dependencies imports
import React, { useEffect, useState } from "react";
import CardMedia from "@material-ui/core/CardMedia";

import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import { makeStyles } from "@material-ui/core/styles";
import { Modal } from "@material-ui/core";
import FileViewer from "react-file-viewer";

// Local imports
import UserHeader from "../Header/UserHeader/UserHeader";
import { API } from "aws-amplify";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import { Backdrop, CircularProgress } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";

//Styles imports
import Grade from "./GradingDetails.module.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  "& > *": {
    margin: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
}));

const Complents = () => {
  const [complents, setComplents] = useState([]);
  const [istLoading, setIstLoading] = useState(false);
  const [openDelete, setopenDelete] = useState(false);
  const [openFile, setOpenFile] = useState(false);
  const [description, setDescription] = useState("");
  const [filename, setFilename] = useState("");
  const [fileType, setFileType] = useState("");
  const [email, setEmail] = useState("");

  const classes = useStyles();
  let userDetails = useSelector(authData);

  useEffect(() => {
    getComplents();
  }, []);

  const handledeleteOpen = () => {
    setopenDelete(true);
  };

  const handleDeleteClose = () => {
    setopenDelete(false);
  };

  const handleFileOpen = () => {
    let extend =
      filename.substring(filename.lastIndexOf(".") + 1, filename.length) ||
      filename;

    setFileType(extend);
    setOpenFile(true);
  };

  const handleFileClose = () => {
    setOpenFile(false);
  };

  async function getComplents() {
    
    let body1;

    setIstLoading(true);
    if (userDetails.bpidpstatus == true) {
      body1 = {
        oid: config.aws_org_id,
        bpid: userDetails.curprgcou.bpid,
      };
    } else {
      body1 = {
        oid: config.aws_org_id,
      };
    }
    const bodyParam = {
      body: body1,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      let response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getcomplents",
        bodyParam
      );

      if (response == null || response == undefined) {
        setComplents(undefined);
      } else {
        let complaints = response?.complents.reverse();
        setComplents(complaints);
      }

      setIstLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  const tableStyle = {
    borderCollapse: "collapse",
    width: "100%",
  };

  const tdStyle1 = {
    padding: "8px",
    textAlign: "left",
    border: "1px solid #ddd",
    fontSize: "14px",
  };

  const thStyle = {
    backgroundColor: "#f2f2f2",
    padding: "8px",
    textAlign: "left",
    border: "1px solid #ddd",
    fontSize: "16px",
  };

  const tdStyle = {
    padding: "8px",
    textAlign: "left",
    border: "1px solid #ddd",
    fontSize: "14px",
    cursor: "pointer",
    textDecoration: "underline",
  };
  return (
    <div className={Grade.maincontainer}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalConfirm}
        open={openDelete}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openDelete}>
          <div
            style={{
              backgroundColor: "white",
              width: "70rem",
              transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              transform: "translate(-50%, -50%)",
              position: "absolute",
              height: "max-content",
              maxHeight: "80vh",
              padding: "2rem",
              top: "50%",
              left: "50%",
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: "max-content",
                marginBottom: "2rem",
              }}
            >
              <h3>{email}</h3>
              <button onClick={handleDeleteClose}>X</button>
            </div>
            <div
              style={{
                border: "  1px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",

                  width: "100%",
                }}
              >
                <p
                  style={{
                    flexBasis: "30%",
                    fontSize: "1.6rem",
                    width: "30%",
                    textAlign: "left",
                    fontWeight: "700",
                    padding: "0rem 2rem",
                    margin: "0px",
                  }}
                >
                  Issue Description:
                </p>

                <label
                  style={{
                    fontSize: "1.6rem",
                    fontWeight: "500",
                    width: "70%",
                    borderLeft: "1px solid black",
                    padding: "1rem 2rem",
                    textAlign: "left",
                    margin: "0px",
                  }}
                >
                  {description}
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",

                  width: "100%",
                }}
              >
                <p
                  style={{
                    flexBasis: "30%",
                    fontSize: "1.6rem",
                    padding: "0rem 2rem",
                    textAlign: "left",
                    fontWeight: "700",
                    margin: "0px",
                    width: "30%",
                  }}
                >
                  File Uploaded:
                </p>

                {filename !== "" ? (
                  <label
                    style={{
                      
                      fontSize: "1.6rem",
                      fontWeight: "500",
                      width: "70%",
                      borderLeft: "1px solid black",
                      padding: "1rem 2rem",
                      margin: "0px",
                      textAlign: "left",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleFileOpen();
                    }}
                  >
                    {filename}
                  </label>
                ) : (
                  <label
                    style={{
                      
                      fontSize: "1.6rem",
                      fontWeight: "500",
                      width: "70%",
                      borderLeft: "1px solid black",
                      padding: "1rem 2rem",
                      margin: "0px",
                      textAlign: "left",
                     
                    }}
                    
                  >
                    No Files
                  </label>
                )}
              </div>
            </div>
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalConfirm}
        open={openFile}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openFile}>
          <div
            style={{
              backgroundColor: "white",
              width: "80%",
              transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              transform: "translate(-50%, -50%)",
              position: "absolute",
              height: "80vh",
              padding: "2rem",
              top: "50%",
              left: "50%",
              textAlign: "center",
              overflow: "scroll",
              overflowX: "hidden",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: "max-content",
                marginBottom: "2rem",
              }}
            >
              <h3></h3>
              <button onClick={handleFileClose}>X</button>
            </div>

            <div
              style={{
                textAlign: "center",
                marginTop: "20px",
                height: "50rem",
                overflow: "scroll",
                overflowX: "hidden",
              }}
            >
              {userDetails.bpidpstatus == true ? (
                <>
                  {fileType == "docx" ? (
                    <FileViewer
                      fileType="docx"
                      filePath={`https://${
                        Constants.DOMAIN
                      }/${config.aws_org_id.toLowerCase()}-resources/complents/${
                        userDetails.curprgcou.bpid
                      }/${email}/${filename}`}
                      onError={(e) => console.log("Error:", e)}
                    />
                  ) : (
                    <CardMedia
                      style={{ height: "100%", width: "100%" }}
                      className={classes.root}
                      alt="ProfilePage"
                      component="iframe"
                      src={`https://${
                        Constants.DOMAIN
                      }/${config.aws_org_id.toLowerCase()}-resources/complents/${
                        userDetails.curprgcou.bpid
                      }/${email}/${filename}`}
                    />
                  )}
                </>
              ) : (
                <>
                  {fileType == "docx" ? (
                    <FileViewer
                      fileType="docx"
                      filePath={`https://${
                        Constants.DOMAIN
                      }/${config.aws_org_id.toLowerCase()}-resources/complents/${email}/${filename}`}
                      onError={(e) => console.log("Error:", e)}
                    />
                  ) : (
                    <CardMedia
                      style={{ height: "100%", width: "100%" }}
                      className={classes.root}
                      alt="ProfilePage"
                      component="iframe"
                      src={`https://${
                        Constants.DOMAIN
                      }/${config.aws_org_id.toLowerCase()}-resources/complents/${email}/${filename}`}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </Fade>
      </Modal>
      <UserHeader />
      <h3 className={Grade.header}>Complaints / Grievances Details</h3>
      <div>
        {istLoading && (
          <Backdrop className={classes.backdrop} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {complents == undefined || complents.length == 0 ? (
          <>
            <div style={{ margin: "3rem" }}>
              <h3 className={classes.heading1}>No Complaints</h3>
            </div>
          </>
        ) : (
          <div style={{ marginTop: "30px" }}>
            <table style={tableStyle}>
              <thead>
                <tr>
                  <th style={thStyle}>Sl No</th>
                  <th style={thStyle}>Email Id</th>
                  <th style={thStyle}>Posted On</th>
                </tr>
              </thead>
              <tbody>
                {complents.map((item, index) => (
                  <tr key={index}>
                    <td style={tdStyle1}>{index + 1}</td>
                    <td
                      style={tdStyle}
                      onClick={() => {
                        setDescription(item.description);
                        setFilename(item.uploadedFile);
                        setEmail(item.email);

                        handledeleteOpen();
                      }}
                    >
                      {item.email}
                    </td>

                    <td style={tdStyle1}>{item.postedon}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Complents;
