import React, { useEffect, useState } from "react";

import moment from "moment";

import { Tab } from "semantic-ui-react";
import { Rating } from "semantic-ui-react";
import InstructorsCard from "../../modules/Cards/Instructors/InstructorsCard";
import { useSelector, useDispatch } from "react-redux";

import { awsSignIn, authData, awsSignOut } from "../../redux/auth/authSlice";
import { Link, useNavigate } from "react-router-dom";

import "../../modules/Tabs/Tabs.scss";
import { Document, Page, pdfjs } from "react-pdf";
import bio from "../../assets/images/P1 - PG Diploma in bioinformatics.jpg";
import { ReactComponent as Close } from "../../assets/svg/close_black_24dp.svg";

import Rupee from "../../assets/svgjs/Rupee";
import PdfIcon from "../../assets/svgjs/Pdf";
import PlaceholderParagraph from "../../modules/Placeholder/PlaceholderParagraph";

import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import ClockOrange from "../../assets/svgjs/ClockOrange";
import Announcements from "../../assets/svgjs/Announcements";

import CircularProgress from "@material-ui/core/CircularProgress";

import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Paper from "@material-ui/core/Paper";
import ReactPlayer from "react-player";

import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import Grid from "@material-ui/core/Grid";

import { FaBars } from "react-icons/fa";
import UserHeader from "../Header/UserHeader/UserHeader";
import config from "../../config/aws-exports";
import { a, API } from "aws-amplify";
import { Constants } from "../../config/constants";
import progIbfo from "./ProgramInfo.module.scss";
import swal from "sweetalert";
import CB1 from "./CreateBatchOne.module.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",

    "& .MuiAccordionDetails-root": {
      display: "list-item",
      padding: "8px 16px 16px",
    },
  },
  root1: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
  heading: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: theme.typography.fontWeightRegular,
    flexBasis: "33.33%",
    flexShrink: 0,
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
}));

export default function ProgramsInfo({ handleToggleSidebar }) {
  const classes = useStyles();
  const [activeIndex, setActiveIndex] = useState(0);
  const [programsJSON, setProgramsJSON] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [announcements, setAnnouncements] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false);
  const [obj, setObj] = useState({});
  const [openBatch, setOpenBatch] = useState(false);
  let navigate = useNavigate();
  const [bdata, setBdata] = useState({
    bname: "",
    pStart: null,
    eStart: null,
    pEnd: null,
    nodate: false,
  });
  const [errmsg, setErrmsg] = useState("");
  const [ptype, setPtype] = useState(1);
  const [etype, setEtype] = useState(1);
  const [startdate, setStartdate] = useState(new Date());
  const [enddate, setEnddate] = useState(new Date());
  const [enrollenddate, setEnrollenddate] = useState(new Date());
  const [disableDate, setDisableDate] = useState(false);
  const [spin, setSpin] = useState(false);

  let userDetails = useSelector(authData);
  const dispatch = useDispatch();
  //console.log("user11",userDetails)
  /* function handleTabChange(e){ 
     (e, { activeIndex }) => setActiveIndex({ activeIndex });} */

  useEffect(() => {
    set();
    getProgramsDetailsPost();
    getAnnouncementLoad();
  }, []);
  async function set() {
    //let a = await localStorage.getItem("active");

    setActiveIndex(a.a);
  }
  async function getProgramsDetailsPost() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        pid: userDetails.curprgDetails.pid,
        bpid: userDetails.curprgDetails.pid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        `/getProgramsDetails`,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );
      const programsJSON = response;
      console.log(programsJSON);
      setProgramsJSON(programsJSON);
      setIsLoading(false);
    } catch (error) {
      console.log("getCategoryError", error);
    }
    console.log("Mount");
  }

  async function getAnnouncementLoad() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        bpid: [a.bpid],
        rtype: "getall",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.POST_ANNOUNCEMENT,
        bodyParam
      );
      const AnnouncementsJSON = response.Response;
      setAnnouncements(AnnouncementsJSON);
      console.log("Announce", AnnouncementsJSON);
    } catch (error) {
      console.error(error);
    }
  }

  function continueLearning() {
    let pdata = userDetails.data.bpdata;
    let sdata = { ...userDetails };
    for (let i = 0; i < pdata.length; i++) {
      if (a.bpid == pdata[i].bpid) {
        sdata.curprg = pdata[i];
      }
    }

    dispatch(awsSignIn(sdata));

    navigate("/MyPrograms/programs");
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function modalO() {
    const handleClose = () => {
      setOpen(false);
    };
    console.log("obj", obj);

    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }

    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paperModal}>
              <span
                style={{ float: "right", cursor: "pointer" }}
                onClick={() => {
                  handleClose();
                }}
              >
                x
              </span>

              <div>
                {obj.type === "file" ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        height: "400px",
                        overflowX: "hidden",
                      }}
                    >
                      <iframe
                        src={`https://${
                          Constants.DOMAIN
                        }/${config.aws_org_id.toLowerCase()}-resources/images/announcement-images/${
                          obj.mfname
                        }`}
                        height="200"
                        width="100%"
                        title="Iframe Example"
                      ></iframe>
                      {/* <Document
                      file={`https://${Constants.DOMAIN}/${config.aws_org_id.toLowerCase()}-resources/images/announcement-images/0047786524-3.pdf`}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      <Page pageNumber={pageNumber} />
                    </Document> */}
                    </div>
                  </>
                ) : null || obj.type === "video" ? (
                  <div
                    className="player-wrapper"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <ReactPlayer url={obj.url} controls={true} width="100%" />
                  </div>
                ) : null || obj.type === "audio" ? (
                  <div
                    className="player-wrapper"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <ReactPlayer url={obj.url} controls={true} width="100%" />
                  </div>
                ) : null}
              </div>
            </div>
          </Fade>
        </Modal>
      </>
    );
  }
  function programNameAnc(prog) {
    for (let i = 0; i < userDetails.data.bpdata.length; i++) {
      console.log("iii", userDetails.data.bpdata[i].ptitle);
      if (announcements[prog].tid === userDetails.data.bpdata[i].bpid) {
        return <h2>{userDetails.data.bpdata[i].ptitle}</h2>;
      }
    }
  }
  const panes = [
    {
      menuItem: "Overview",
      render: () => (
        <Tab.Pane className={progIbfo.overview}>
          {isLoading ? (
            <PlaceholderParagraph />
          ) : (
            <p
              className={progIbfo.p_text}
              dangerouslySetInnerHTML={{ __html: programsJSON.poverview }}
            ></p>
          )}
          <div></div>
          <div>
            <h2 className={progIbfo.h2_margin}>Program features</h2>
            {isLoading ? (
              <div style={{ width: "100%", overflow: "hidden" }}>
                <PlaceholderParagraph />
              </div>
            ) : (
              <p
                className={progIbfo.p_text}
                dangerouslySetInnerHTML={{ __html: programsJSON.pfeatures }}
              ></p>
            )}
          </div>
          <div>
            <h2 className={progIbfo.h2_margin}>Program outcomes</h2>
            {isLoading ? (
              <PlaceholderParagraph />
            ) : (
              <p
                className={progIbfo.p_text}
                dangerouslySetInnerHTML={{ __html: programsJSON.poutcomes }}
              ></p>
            )}
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Instructors",
      render: () => (
        <Tab.Pane>
          {programsJSON.pinstructors.map((item, idx) => (
            <div>
              {item.title != undefined ? (
                <div className="instructors">
                  <div className="instructors__h1">
                    <h2
                      style={{
                        marginLeft: "20px",
                        marginTop: "20px",
                        fontSize: "18px",
                      }}
                    >
                      {item.title}
                    </h2>
                  </div>
                  <div>
                    <div
                      className="card-container"
                      style={{ float: "left", marginLeft: "50px" }}
                    >
                      {item.value.map(
                        ({ name, designation, org, pic }, idx) => (
                          <InstructorsCard
                            key={idx}
                            name={name}
                            designation={designation}
                            org={org}
                            pic={pic}
                            pid={programsJSON.pid}
                          />
                        )
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div style={{display:"flex", textAlign:"center"}}>
                  {/* <h2
                    style={{
                      marginLeft: "20px",
                      marginTop: "20px",
                      fontSize: "18px",
                    }}
                  >
                    Program Instructors
                  </h2> */}

                  <InstructorsCard
                    key={idx}
                    name={item.name}
                    designation={item.designation}
                    org={item.org}
                    pic={item.pic}
                    pid={programsJSON.pid}
                  />
                </div>
              )}
            </div>
          ))}
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Curriculum",
      render: () => (
        <Tab.Pane>
          {programsJSON
            ? programsJSON.pcurriculum.map(
                ({ tlabel, tduration, ttitle, tunits, tid }) => (
                  <div className={progIbfo.pcurriculum}>
                    <div className={progIbfo.courses_container}>
                      <div className={progIbfo.ID_container}>
                        <div
                          className={progIbfo.image_container}
                          style={{
                            backgroundImage: `url('https://d93u1ve2a5jxt.cloudfront.net/jssaher-resources/images/topic-images/${tid}.png')`,
                          }}
                        ></div>
                        <div className={progIbfo.details_container}>
                          <div>
                            <h3
                              style={{ fontSize: "15px", marginBottom: "10px" }}
                            >
                              {tlabel}
                            </h3>
                            <h2
                              style={{ fontSize: "15px", fontWeight: "bold" }}
                            >
                              {ttitle}
                            </h2>
                          </div>
                          <div>
                            <p style={{ marginTop: "-12px" }}>
                              <span>
                                <ClockOrange
                                  className={progIbfo.clock_size__s}
                                  cls1={progIbfo.cls1_s}
                                  cls2={progIbfo.cls2_s}
                                />
                              </span>
                              <span style={{ fontSize: "small" }}>
                                {tduration} Weeks
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      {tunits.map((units) => (
                        <div>
                          <p className={progIbfo.tunits}>{units}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                )
              )
            : null}
        </Tab.Pane>
      ),
    },
    {
      menuItem: "FAQs",
      render: () => (
        <Tab.Pane>
          {programsJSON
            ? programsJSON.pfaq.map(({ title, questions }, idx) => (
                <div className={classes.root} key={idx}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        <h2 style={{ fontSize: "16px", fontWeight: "bold" }}>
                          {title}
                        </h2>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {questions.map(({ ques, ans }, id) => (
                        <div className={classes.root} key={id}>
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <h3 style={{ fontSize: "15px" }}>{ques}</h3>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography style={{ fontSize: "14px" }}>
                                <p
                                  className={progIbfo.p_text}
                                  dangerouslySetInnerHTML={{ __html: ans }}
                                ></p>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))
            : null}
        </Tab.Pane>
      ),
    },
  ];

  let handleTabChange = (e, { activeIndex }) => setActiveIndex(activeIndex);
  const [ptButtonClass, setPtButtonClass] = useState("default"); //states for program type state changes
  const [etButtonClass, setEtButtonClass] = useState("default"); //states for enrollment type state changes

  // Program type toggle onclick function
  const pttoggleClass = (val, type) => {
    console.log("t1", type);
    // alert(type)
    setPtButtonClass(val);
    if (
      bdata.bname === undefined ||
      bdata.bname.replace(/\s/g, "").length === 0 ||
      bdata.bname.length < 3
    ) {
      if (bdata.bname === undefined) {
        setErrmsg("Please enter Batch name");
        return;
      } else {
        setErrmsg("Batch name should be atleast of 3 characters");
        return;
      }
    } else {
      // alert(type)
      setPtype(type);
      setErrmsg("");
    }
  };

  // Enrollment type toggle onclick function
  const ettoggleClass = (val, type, etypeRec) => {
    setEtButtonClass(val);

    setEtype(etypeRec);
    if (etypeRec === 3) {
      // createbatchon();
      setDisableDate(true);
    } else {
      setDisableDate(false);
    }
  };

  async function createbatchon(S, E, EnRD) {
    setSpin(true);
    var body1 = {
      oid: config.aws_org_id,
      pid: userDetails.curprgDetails.pid,
      pname: userDetails.curprgDetails.pname,
      pdescription: userDetails.curprgDetails.pdescription,
      ptype: ptype,
      etype: etype,
      bname: bdata.bname,
      nodate: bdata.nodate,
    };
    if (S !== undefined) {
      body1.startdate = S;
      body1.enddate = E;
      body1.enrollenddate = EnRD;
    }
    const bodyParam = {
      body: body1,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    // alert("body"+JSON.stringify(bodyParam.body));
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        `/createProgramInstance`,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );
      closeModalBatch();
      // alert("response" + JSON.stringify(response));
      swal({
        title: "Success!",
        text: "Batch Created Successfully",
        icon: "success",
        dangerMode: false,
      }).then((willDelete) => {
        if (willDelete) {
          closeModalBatch();
          setSpin(false);
        }
      });
      setSpin(false);
    } catch (error) {
      swal({
        title: "Oooops!",
        text: "Something went wrong!",
        icon: "warning",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setSpin(false);
          swal({
            title: "Contact Developer!",
          });
        }
      });
      console.log("getCategoryError", error);
    }

    //console.log("body",body)
  }
  function createbatch() {
    if (
      bdata.bname === undefined ||
      bdata.bname.replace(/\s/g, "").length === 0 ||
      bdata.bname.length < 3
    ) {
      if (bdata.bname === undefined) {
        setErrmsg("Please enter a Batch name");
        return;
      } else {
        setErrmsg("Batch name should be atleast of 3 characters");
        return;
      }
    } else {
      setErrmsg("");
    }

    if (ptype === 1 && etype === 1) {
      if (bdata.pStart === null || bdata.pStart === undefined) {
        setErrmsg("Please select a start date");
        return;
        //break;
      } else if (bdata.pEnd === null || bdata.pEnd === undefined) {
        setErrmsg("Please select an end date");
        return;
      } else if (bdata.eStart === null || bdata.eStart === undefined) {
        setErrmsg("Please select an enrollment date");
        return;
      } else {
        let SD = new Date(bdata.pStart);
        let ED = new Date(bdata.pEnd);
        let EnD = new Date(bdata.eStart);
        if (SD >= ED) {
          setErrmsg("Please select proper Start and End date");
          return;
        } else {
          setErrmsg("");
          let S = SD.getTime();
          console.log("s", S);
          setStartdate(S);
          let E = ED.getTime();
          setEnddate(E);
          let EnRD = EnD.getTime();
          setEnrollenddate(EnRD);
          createbatchon(S, E, EnRD);
        }

        //duedate = date.getTime();
      }
    } else if ((ptype === 1 || ptype === 2) && (etype === 1 || etype === 2)) {
      if (bdata.pStart === null || bdata.pStart === undefined) {
        setErrmsg("Please select a start date");
        return;
        //break;
      } else if (bdata.pEnd === null || bdata.pEnd === undefined) {
        setErrmsg("Please select a end date");
        return;
      } else if (bdata.eStart === null || bdata.eStart === undefined) {
        setErrmsg("Please select a enrollment date");
        return;
      } else if (bdata.pStart === undefined && bdata.nodate === false) {
        setErrmsg("Please select a start date");
        return;
      } else {
        setErrmsg("");
        if (bdata.pStart != undefined) {
          if (bdata.pEnd === undefined) {
            setErrmsg("Please select a end date");
            return;
          } else {
            setErrmsg("");
            let SD = new Date(bdata.pStart);
            let ED = new Date(bdata.pEnd);
            let EnD = new Date(bdata.eStart);
            if (SD >= ED) {
              setErrmsg("Please select proper Start and End date");
              return;
            } else {
              setErrmsg("");
              let S = SD.getTime();
              console.log("s", S);
              setStartdate(S);
              let E = ED.getTime();
              setEnddate(E);
              let EnRD = EnD.getTime();
              setEnrollenddate(EnRD);
              createbatchon(S, E, EnRD);
            }
          }
        } else {
          if (bdata.nodate === false) {
            setErrmsg("Please select a end date");
            return;
          } else {
            setErrmsg("");
            createbatchon();
          }
        }
      }
    } else if ((ptype === 1 || ptype === 2) && etype === 3) {
      createbatchon();
    }
  }

  function closeModalBatch() {
    setOpenBatch(false);
    setBdata({
      bname: "",
      pStart: null,
      eStart: null,
      pEnd: null,
      nodate: false,
    });
    setErrmsg("");
    setPtype(1);
    setEtype(1);
  }

  return (
    <div className={progIbfo.containermain}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openBatch}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openBatch}>
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {spin === false ? (
              <div className={CB1.containermain}>
                <div className={CB1.iconholder} style={{ cursor: "pointer" }}>
                  <Close
                    onClick={() => {
                      closeModalBatch();
                    }}
                  />
                </div>
                <div>
                  <h3 className={CB1.heading}>Create batch</h3>
                </div>
                <div className={CB1.form}>
                  <p
                    style={{
                      color: "red",
                      fontSize: "15px",
                      alignItems: "center",
                      marginBottom: "20px",
                      paddingLeft: "181px",
                    }}
                  >
                    {errmsg}
                  </p>
                  <div className={CB1.field}>
                    <label
                      style={{
                        fontSize: "16px",
                        fontWeight: "500px",
                        fontFamily: "nunito",
                      }}
                      for="batch-name"
                      required="required"
                    >
                      * Batch Name
                    </label>
                    <input
                      style={{
                        border: "none",
                        width: "75%",
                        boxShadow: "0px 0px 5px 0px lightgrey ",
                        background: "white",
                        height: "30px",
                        marginBottom: "20px",
                        fontSize: "15px",
                        paddingLeft: "10px",
                        fontWeight: "200px",
                      }}
                      type="text"
                      id="batch-name"
                      onChange={(e) =>
                        setBdata({ ...bdata, bname: e.target.value })
                      }
                    ></input>
                  </div>
                  <div className={CB1.field}>
                    <label
                      style={{
                        fontSize: "16px",
                        fontWeight: "500px",
                        fontFamily: "nunito",
                      }}
                      for="program-type"
                      required="required"
                    >
                      * Program type
                    </label>
                    <div className={CB1.buttonholder}>
                      <button
                        className={
                          ptButtonClass === "default"
                            ? CB1.buttonactive
                            : CB1.buttonstable
                        }
                        onClick={() => {
                          pttoggleClass("default", 1);
                        }}
                      >
                        Instructor led
                      </button>
                      <button
                        className={
                          ptButtonClass === "selfpaced"
                            ? CB1.buttonactive
                            : CB1.buttonstable
                        }
                        onClick={() => {
                          pttoggleClass("selfpaced", 2);
                        }}
                      >
                        Self paced
                      </button>
                    </div>
                  </div>
                  <div className={CB1.field}>
                    <label
                      style={{
                        fontSize: "16px",
                        fontWeight: "500px",
                        fontFamily: "nunito",
                      }}
                      for="Enrollment-type"
                      required="required"
                    >
                      * Enrollment type
                    </label>
                    <div className={CB1.buttonholder}>
                      <button
                        className={
                          etButtonClass === "default"
                            ? CB1.buttonactive
                            : CB1.buttonstable
                        }
                        onClick={() => {
                          ettoggleClass("default", 1, 1);
                        }}
                      >
                        Through application
                      </button>
                      <button
                        className={
                          etButtonClass === "openenroll"
                            ? CB1.buttonactive
                            : CB1.buttonstable
                        }
                        onClick={() => {
                          ettoggleClass("openenroll", 1, 2);
                        }}
                      >
                        Open enrollment
                      </button>
                      <button
                        className={
                          etButtonClass === "assignonly"
                            ? CB1.buttonactive
                            : CB1.buttonstable
                        }
                        onClick={() => {
                          ettoggleClass("assignonly", 1, 3);
                        }}
                      >
                        Assign only
                      </button>
                    </div>
                  </div>
                  <div className={CB1.field}>
                    <label
                      style={{
                        fontSize: "16px",
                        fontWeight: "500px",
                        fontFamily: "nunito",
                      }}
                      for="program-start"
                      required="required"
                    >
                      * Program start date
                    </label>
                    <div className={CB1.dateholder}>
                      <input
                        disabled={disableDate}
                        type="date"
                        id="program-start"
                        style={{
                          border: "none",
                          width: "25%",
                          boxShadow: "0px 0px 5px 0px lightgrey ",
                          background: "white",
                          height: "30px",
                          marginBottom: "20px",
                          fontSize: "15px",
                          paddingLeft: "10px",
                          fontWeight: "200px",
                        }}
                        onChange={(e) =>
                          setBdata({ ...bdata, pStart: e.target.value })
                        }
                      ></input>
                    </div>
                  </div>
                  <div className={CB1.field}>
                    <label
                      style={{
                        fontSize: "16px",
                        fontWeight: "500px",
                        fontFamily: "nunito",
                      }}
                      for="program-end"
                      required="required"
                    >
                      * Program end date
                    </label>
                    <div className={CB1.dateholder}>
                      <input
                        disabled={disableDate}
                        type="date"
                        id="program-end"
                        style={{
                          border: "none",
                          width: "25%",
                          boxShadow: "0px 0px 5px 0px lightgrey ",
                          background: "white",
                          height: "30px",
                          marginBottom: "20px",
                          fontSize: "15px",
                          paddingLeft: "10px",
                          fontWeight: "200px",
                        }}
                        onChange={(e) =>
                          setBdata({ ...bdata, pEnd: e.target.value })
                        }
                      ></input>
                    </div>
                  </div>
                  <div className={CB1.field}>
                    <label
                      style={{
                        fontSize: "16px",
                        fontWeight: "500px",
                        fontFamily: "nunito",
                      }}
                      for="enrollment-start"
                      required="required"
                    >
                      * Enrollment start date
                    </label>
                    <div className={CB1.dateholder}>
                      <input
                        disabled={disableDate}
                        type="date"
                        id="enrollment-start"
                        style={{
                          border: "none",
                          width: "25%",
                          boxShadow: "0px 0px 5px 0px lightgrey ",
                          background: "white",
                          height: "30px",
                          marginBottom: "20px",
                          fontSize: "15px",
                          paddingLeft: "10px",
                          fontWeight: "200px",
                        }}
                        onChange={(e) =>
                          setBdata({ ...bdata, eStart: e.target.value })
                        }
                      ></input>
                    </div>
                  </div>
                  <div className={CB1.submitbuttonholder}>
                    <button
                      onClick={() => createbatch()}
                      className={CB1.submitbutton}
                    >
                      Create
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className={classes.root1}>
                <CircularProgress style={{ color: Constants.main_color_1 }} />
              </div>
            )}
          </div>
        </Fade>
      </Modal>
      <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
        <FaBars />
      </div>
      <UserHeader />
      <div className={progIbfo.overview}>
        <div>
          <Typography component="div" key="h2" variant="h2">
            {isLoading ? (
              <Skeleton />
            ) : (
              <h3 className={progIbfo.header}>{programsJSON.pname}</h3>
            )}
          </Typography>
        </div>
        <div className={progIbfo.overview__card}>
          <div className={progIbfo.overview__cardContent}>
            <div className={progIbfo.content}>
              <div className={progIbfo.content__details}>
                <div className={progIbfo.align_self}>
                  {/* <Rating
                        icon="star"
                        defaultRating={4}
                        maxRating={5}
                        size="huge"
                      /> */}
                </div>
                <div className={progIbfo.items}>
                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <strong>
                      <span>
                        <ClockOrange
                          className={progIbfo.clock_size}
                          cls1={progIbfo.cls_1}
                          cls2={progIbfo.cls_2}
                        />
                      </span>
                      {isLoading ? <Skeleton /> : programsJSON.pinfo.duration}
                    </strong>
                  </div>
                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      marginLeft: "50%",
                      gap: "10px",
                    }}
                  >
                    Format:{" "}
                    <strong>
                      {isLoading ? <Skeleton /> : programsJSON.pinfo.format}
                    </strong>
                  </div>
                </div>
                <div className={progIbfo.items}>
                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                    s
                  >
                    <strong>
                      <span>
                        <Rupee className={progIbfo.rupee_size} />
                      </span>{" "}
                      {isLoading ? <Skeleton /> : programsJSON.pinfo.price}
                    </strong>
                  </div>
                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      marginLeft: "50%",
                      gap: "10px",
                    }}
                  >
                    Credits:{" "}
                    <strong>
                      {isLoading ? <Skeleton /> : programsJSON.pinfo.credits}
                    </strong>
                  </div>
                </div>
                <div className={progIbfo.align_self}>
                  {isLoading ? (
                    <Skeleton />
                  ) : userDetails.role == "Instructors" ||
                    userDetails.role == "Coordinators" ? null : (
                    <button
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        width: "150px",
                        borderRadius: "5px",
                        height: "30px",
                      }}
                      className={progIbfo.btn_color}
                      onClick={() => {
                        setOpenBatch(true);
                      }}
                    >
                      Create Batch
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          {isLoading ? (
            <Skeleton variant="rect" width="100%">
              <div style={{ paddingTop: "57%" }} />
            </Skeleton>
          ) : (
            <div className={progIbfo.overview__cardImage}>
              <img
                src={`https://${
                  Constants.DOMAIN
                }/${config.aws_org_id.toLowerCase()}-resources/images/program-images/${
                  programsJSON.pid
                }.png`}
                alt=""
              />
            </div>
          )}
        </div>
        <div className={progIbfo.tabMargin}>
          {isLoading === false ? (
            <Tab
              panes={panes}
              activeIndex={activeIndex}
              onTabChange={handleTabChange}
            />
          ) : (
            <Skeleton width="100%" height={500} />
          )}
        </div>
      </div>
    </div>
  );
}
