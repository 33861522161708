// Dependencies imports
import React, { useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import swal from "sweetalert";
// Local imports
import { Constants } from "../../../config/constants";
import { useSelector } from "react-redux";
import { authData } from "../../../redux/auth/authSlice";
import config from "../../../config/aws-exports";
import { API } from "aws-amplify";
// Style imports
import Add from "./AddRecording.module.scss";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
}));

const AddRecording = (props) => {
  const [spin, setSpin] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [timeStamp, setTimeStamp] = useState("");
  const [title, setTitle] = useState("");
  const [err, setErr] = useState("");
  const [date, setDate] = useState("");
  const [link, setLink] = useState("");
  const classes = useStyles();
  let timestamp;
  let { handleClose, reload } = props;
  let userDetails = useSelector(authData);
  let lcourseDetails = userDetails.curprg;

  const onsubmit = () => {
    setErr("");

    if (date == undefined || date == "" || date === null) {
      setErr("Please Select date");
      return;
    }

    if (title == undefined || title.trim() === "") {
      setErr("Please Enter Title");
      return;
    }
    if (title != undefined && title.trim() != "" && title.trim().length > 100) {
      setErr("*Please Enter Event Title length less than 100 character");
      return;
    }
    if ((link == undefined || link.trim() == "") && isFilePicked == false) {
      setErr("Please Select a file or add a link");
      return;
    }
    let regex =
      "^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$";
    let result = new RegExp(regex, "i");
    if (link != undefined && link.trim() != "") {
      if (link === "" || !result.test(link)) {
        setErr("Please Enter a valid link");
        return;
      }
    }

    if (isFilePicked === true) {
      let extend =
        selectedFile.name.substring(
          selectedFile.name.lastIndexOf(".") + 1,
          selectedFile.name.length
        ) || selectedFile.name;

      getPreSignedUrl(extend);
    } else {
      add();
    }
  };
  async function add(fname) {
    setSpin(true);
    timestamp = new Date().getTime();
    let d = date;
    let dateTimeStart = new Date(d.split(" "));
    dateTimeStart = dateTimeStart.getTime();
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        cid: lcourseDetails.btid,
        action: "put",
        annjson: {
          id: timestamp,
          title: title,
          date: dateTimeStart,
        },
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    if (isFilePicked === true) {
      bodyParam.body.annjson.ourl = fname;
      bodyParam.body.annjson.fname = selectedFile.name;
      bodyParam.body.annjson.otype = "video";
    }
    if (link !== undefined && link.trim() != "") {
      bodyParam.body.annjson.link = link;
    }

    sendMessage(fname, link);

    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.COURSE_VIDEO,
        bodyParam
      );

      handleClose();
      swal({
        title: "Success",
        text: "Session added successfully",
        icon: "success",
        dangerMode: false,
      }).then((willDelete) => {
        if (willDelete) {
          handleClose();
        }
      });
      setSpin(false);
      reload();
    } catch (error) {
      swal({
        title: "Oooops!",
        text: "something went wrong!",
        icon: "warning",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
        }
      });
    }
  }

  async function getPreSignedUrl(extend) {
    setSpin(true);
    let fname = timeStamp.toString() + "." + extend;
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        type: "session",
        filetype: selectedFile.type,
        filename: fname,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PRESIGNED_URL,
        bodyParam
      );

      fileUpload(selectedFile, response, fname);
    } catch (error) {
      swal({
        title: "Oooops!",
        text: "something went wrong!",
        icon: "warning",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setSpin(false);
        }
      });
    }
  }
  async function fileUpload(file, url, fname) {
    await axios
      .put(url, file, { headers: { "Content-Type": file.type } })
      .then(() => {
        add(fname);
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const onChangeHandlerTitle = (event) => {
    setTitle(event.target.value);
  };

  const onChangeHandlerDate = (event) => {
    setDate(event.target.value);
  };

  const onChangeHandlerLink = (event) => {
    setLink(event.target.value);
  };

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    let tstamp = new Date().getTime();
    setTimeStamp(tstamp);
    setIsFilePicked(true);
  };

  async function getUsers() {
    let filterStudents = [];
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        bpid: userDetails.curprgcou.bpid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const users = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PROGRAM_BATCH_REPORT,
        bodyParam
      );

      const list = users.map(({ EID, UNAME, UR }) => ({
        EID,
        UNAME,
        UR,
      }));

      filterStudents = list.filter((listnew) => listnew.UR === "User");
    } catch (error) {
      console.error(error);
    }

    return filterStudents;
  }

  async function sendMessage(fname, link) {
    let usersList = await getUsers();

    var body1 = {
      action: "NOTIFICATION",
      title: title,
      tenant: config.aws_org_id,
      oid: config.aws_org_id,
      userslist: usersList,
      id: timestamp,
    };

    const bodyParam = {
      body: body1,

      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    if (isFilePicked === true) {
      bodyParam.body.message = "File has been Attached";
    }

    if (link !== undefined && link.trim() != "") {
      bodyParam.body.link = true;
      bodyParam.body.url = link;
    }

    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_POST_NOTIFICATIONS,
        bodyParam
      );
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div>
      {spin === false ? (
        <div className={Add.mainpopup}>
          <h3 className={Add.header}>Add</h3>
          <div className={Add.form}>
            <div className={Add.caution}>{err}</div>
            <form>
              <div className={Add.fieldholder}>
                <div clasName={Add.labelholder}>
                  <h3 className={Add.label}>Date</h3>
                </div>
                <div className={Add.inputholder}>
                  <div className={Add.caution}></div>
                  <input
                    type="date"
                    className={Add.date}
                    onChange={onChangeHandlerDate}
                    value={date}
                  />
                  <br />
                </div>
              </div>
              <div className={Add.fieldholder}>
                <div className={Add.labelholder}>
                  <h3 className={Add.label} style={{ textAlign: "left" }}>
                    Title
                  </h3>
                </div>
                <div className={Add.inputholder}>
                  <div className={Add.caution}></div>
                  <textarea
                    placeholder="Type your event here..."
                    maxlength="160"
                    rows={5}
                    className={Add.area}
                    onChange={onChangeHandlerTitle}
                    value={title}
                  ></textarea>
                </div>
              </div>
              <div className={Add.fieldholder}>
                <div clasName={Add.labelholder}>
                  <h3 className={Add.label}>Link</h3>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="text"
                    placeholder={"Add your link"}
                    className={Add.date}
                    onChange={onChangeHandlerLink}
                    value={link}
                  ></input>
                </div>
              </div>
              <div className={Add.fieldholder}>
                <div clasName={Add.labelholder}>
                  <h3 className={Add.label}>Select Video</h3>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="file"
                    id="file"
                    name="file not selected"
                    accept=".mp4"
                    className={Add.browse}
                    onChange={changeHandler}
                  />
                </div>
              </div>
              <div className={Add.buttonholder}>
                <button className={Add.cancel} onClick={handleClose}>
                  Cancel
                </button>
                <button className={Add.submit} onClick={onsubmit}>
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className={classes.root}>
          <CircularProgress style={{ color: Constants.main_color_1 }} />
        </div>
      )}
    </div>
  );
};

export default AddRecording;
