// Dependencies imports
import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/styles';
import { API } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import Skeleton from "@material-ui/lab/Skeleton";


// Local imports
import UserHeader from '../Header/UserHeader/UserHeader';
import AssignmentDetailsGrid from './AssignmentDetailsGrid';
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";

// Styles imports
import Grade from './GradingDetails.module.scss';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading1: {
        fontSize: "18px",
        fontWeight: "600px",
        color: Constants.main_color_1,
        fontFamily: "nunito",
        paddingTop: "10px",
        paddingLeft: "10px",
        textAlign: "center"
    },
    heading: {
        fontSize: "18px",
        fontWeight: "600px",
        color: Constants.app_dark_color,
        fontFamily: "nunito"
    },
    dropoption: {
        fontSize: "16px",
        color: "#6a6a6a",
        fontWeight: "500",
        fontFamily: "nunito",
        padding: "10px 0px"
    }
}));


// Start of Assignment Details Screen
const AssignmentDetails = () => {
    const [course, setCourse] = useState([]);
    const [currentData, setCurrentData] = useState({});
    const [rows, setRows] = useState([]);
    const [index, setIndex] = useState();
    const [loading, setLoading] = useState(true);
    let userDetails = useSelector(authData);
    const [quizId, setQuizId] = useState("");
    // console.log("usdata" + JSON.stringify(userDetails));
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        getAssignments();
        breadcrumb();
    }, []);

    async function getAssignments() {
        let bpid = userDetails.assign.btid;
        bpid = bpid.substring(5, bpid.length);
        const bodyParam = {
            body: {
                oid: config.aws_org_id,

                bpid: bpid,// these thre are same
                assessid: userDetails.assign.btid,
                quiztype: 'assign',

            },
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };

        try {
            console.log(JSON.stringify(bodyParam.body))
            console.log(Constants.GET_ELGETASSESSMENTREPORT)

            const response = await API.post(
                config.aws_cloud_logic_custom_name,
                Constants.GET_ELGETASSESSMENTREPORT,
                bodyParam
                //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
            );
            const users = response;
            console.log("2222" + JSON.stringify(users))

            if (response.assessment !== undefined) {
                let courses = response.assessment;
                setCourse(courses);
                setLoading(false);
            } else {
                setCourse([]);
                setLoading(false);
            }

        } catch (error) {
            swal({
                title: "Oooops!",
                text: "something went wrong!",
                icon: "warning",
                dangerMode: true,
            })
                .then(willDelete => {
                    if (willDelete) {
                        swal("Oooops!", "Contact developer");
                    }
                });
            console.log("getUserError", error);
        }
        console.log("Mount");
    }
    const onClick = (data) => {

        console.log("ss" + JSON.stringify(data));
        setQuizId(data.assessid)
        setCurrentData(data);
        convertRowData(data);
    }
    function convertRowData(currentData) {
        let udata = currentData.userlist;
        let rows = [];
        if (udata != undefined) {
            for (let i = 0; i < udata.length; i++) {
                let obj = {};
                obj.id = i;
                obj.name = udata[i].ename;
                obj.eid = udata[i].eid;
                obj.email = udata[i].email;
                obj.usn = udata[i].USN;
                if (udata[i].score != undefined) {
                    if (udata[i].score == -1) {
                        obj.score = "Submited";
                    }
                    else {
                        obj.score = udata[i].score;
                    }
                }
                else {
                    obj.score = "Not Graded";

                }

                rows.push(obj);


            }
        }


        setRows(rows);
    }

    function breadcrumb() {
        let sdata = { ...userDetails };
        let temp = [...sdata.breadcrumb];

        temp[3] = {
            name: 'Assignment Details',
            path: '/assignmentdetails'
        };
        sdata.breadcrumb = temp;
        dispatch(awsSignIn(sdata));
    }


    return (
        <div className={Grade.maincontainer}>
            <UserHeader Bindex={3} />
            <h3 className={Grade.header}>Assignment Details</h3>
            {loading === false ?
                <div className={Grade.middlescreen}>
                    <div className={Grade.leftscreen}>
                        <Paper>
                            {course.length !== 0 ?
                                <div className={classes.root} >

                                    {course.map((obj, i) => (
                                        <h3 className={i === index ? Grade.headingactive : Grade.heading} onClick={() => { onClick(obj); setIndex(i) }}>{obj.title}</h3>
                                    ))}
                                </div> : <div className={classes.root}>
                                    <h3 className={classes.heading1}>No Data</h3>
                                </div>}
                        </Paper>
                    </div>
                    <div className={Grade.rightscreen}>
                        <Paper>
                            <AssignmentDetailsGrid rows={rows} currentData={currentData} quizId={quizId} />
                        </Paper>
                    </div>
                </div>
                : <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", paddingTop: "20px", width: "100%", gap: "10px" }}><Skeleton variant="rect" width="40%" height={600} style={{ borderRadius: "5px" }} /> <Skeleton variant="rect" width="58%" height={400} style={{ borderRadius: "5px" }} /></div>}
        </div>
    );
};

export default AssignmentDetails;