// Dependencies imports
import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { makeStyles } from '@material-ui/core/styles';
// Local imports
import UserHeader from "../Header/UserHeader/UserHeader";
import UsersIcon from "../../assets/svgjs/UsersIcon";
import FeedbackIcon from "../../assets/svgjs/FeedbackIcon";
import EmailIcon from "../../assets/svgjs/EmailIcon";
import FacultyIcon from "../../assets/svgjs/FacultyIcon";
import ContentIcon from "../../assets/svgjs/ContentIcon";
import DiscussionIcon from "../../assets/svgjs/DiscussionIcon";
import AnnouncementsIcon from "../../assets/svgjs/Announcements";
import EventIcon from "../../assets/svgjs/EventIcon";
import GradingIcon from "../../assets/svgjs/GradingIcon";
import ReportIcon from "../../assets/svgjs/ReportIcon";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
// Styles imports

import Manage from "./ManageBatch.module.scss";
import AnnouncementFile from './AnnouncementFile';
import { Constants } from "../../config/constants";

const useStyles = makeStyles({
  root: {
    '& .super-app-theme--header': {
      backgroundColor: Constants.app_dark_color,
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
});

// Start of Manage batch component
const ManageBatch = () => {
  const dispatch = useDispatch();
  let userDetails = useSelector(authData);
  const [open, setOpen] = useState(false);
  const [obj, setObj] = useState();
  const classes = useStyles();

  useEffect(() => {
    breadcrumb();
  }, []);

  function breadcrumb() {
    let sdata = { ...userDetails };
    if (sdata.breadcrumb == undefined) {
      sdata.breadcrumb = [];
      let temp = [...sdata.breadcrumb];
      temp[0] = {
        name: "Manage Programs",
        path: "/Programs",
      };
      temp[1] = {
        name: `${sdata.curprgcou.bname}-${sdata.curprgcou.ptitle}`,
        path: "/ManageBatch",
      };
      sdata.breadcrumb = temp;
    } else {
      let temp = [...sdata.breadcrumb];
      temp[0] = {
        name: "Manage Programs",
        path: "/Programs",
      };
      temp[1] = {
        name: `${sdata.curprgcou.bname}-${sdata.curprgcou.ptitle}`,
        path: "/ManageBatch",
      };
      sdata.breadcrumb = temp;
    }

    dispatch(awsSignIn(sdata));
  }

  function onClickOfPage(name, path) {
    let sdata = { ...userDetails };
    let temp = [...sdata.breadcrumb];

    temp[2] = {
      name: name,
      path: path,
    };

    sdata.bpidpstatus = true;

    sdata.breadcrumb = temp;

    dispatch(awsSignIn(sdata));
  }
  const handleFileOpen = () => {
    setOpen(true);
  };

  const handleFileClose = () => {
    setOpen(false);
  };
  function addLink(item) {
    var obj = {};
    obj.mfname = userDetails?.curprgcou?.bpid + '.pdf';
    obj.type = 'pdf';
    obj.ftype = item;
    setObj(obj);
    handleFileOpen();
  };
  return (
    <div className={Manage.containermain}>
      <UserHeader Bindex={1} />
     
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <AnnouncementFile handleFileClose={handleFileClose} obj={obj} />
        </Fade>
      </Modal>
      <Paper>
        <div className={Manage.topsection}>
          <div className={Manage.leftsection}>
            <div className={Manage.individual}>
              <div className={Manage.leftside}>
                <h4 className={Manage.typeleft}>Format:</h4>
              </div>
              <div className={Manage.rightside}>
                <h5 className={Manage.typeright}>
                  {userDetails.curprgcou.ptype == 1
                    ? "Instructor lead"
                    : "Self paced"}
                </h5>
              </div>
            </div>
            <div className={Manage.individual}>
              <div className={Manage.leftside}>
                <h4 className={Manage.typeleft}>Enrollment type:</h4>
              </div>
              <div className={Manage.rightside}>
                <h5 className={Manage.typeright}>
                  {userDetails.curprgcou.etype == 1
                    ? "Through application"
                    : userDetails.curprgcou.etype == 2
                      ? "Open enrollment"
                      : "Assign only"}
                </h5>
              </div>
            </div>
           
            <div className={Manage.individual} style={{marginLeft:'15%',marginTop:'5%'}}>
            {userDetails.curprgcou.etype == 1 ? (
            <div className={Manage.individual} style={{marginLeft:'15%'}}>
              <div className={Manage.leftside} >
                <h4 className={Manage.typeleft}>Applications:</h4>
              </div>
              <div className={Manage.rightside}>
                <Link
                  style={{fontSize:"16px",color:'#4183c4',marginLeft:'35%'}}
                  to={`/application`}
                  onClick={() => {
                    onClickOfPage("Application", "/application");
                  }}
                >
                  View
                </Link>
              </div>
            </div>
            ) : null}
              <div className={Manage.leftside} style={{marginLeft:'35%'}}>
                <h4 className={Manage.typeleft}>Syllabus:</h4>
              </div>
              <div className={Manage.rightside}>
                <p
                  style={{fontSize:"16px",color:'#4183c4',cursor:'pointer'}}
                  onClick={() => { addLink('syllabus') }}
                >
                  View
                </p>
              </div>
            </div>
          </div>
          {userDetails.curprgcou.etype !== 3 ? (
            <div className={Manage.rightsection}>
              <div className={Manage.individual}>
                <div className={Manage.leftside}>
                  <h4 className={Manage.typeleft}>Program start date:</h4>
                </div>
                <div className={Manage.rightside}>
                  <h5 className={Manage.typeright}>
                    {moment(userDetails.curprgcou.startdate).format(
                      "DD MMM YYYY"
                    )}
                  </h5>
                </div>
              </div>
              <div className={Manage.individual}>
                <div className={Manage.leftside}>
                  <h4 className={Manage.typeleft}>Program end date:</h4>
                </div>
                <div className={Manage.rightside}>
                  <h5 className={Manage.typeright}>
                    {moment(userDetails.curprgcou.enddate).format(
                      "DD MMM YYYY"
                    )}
                  </h5>
                </div>
              </div>

              <div className={Manage.individual}>
                <div className={Manage.leftside}>
                  <h4 className={Manage.typeleft}>Enrollment Start date:</h4>
                </div>
                <div className={Manage.rightside}>
                  <h5 className={Manage.typeright}>
                    {moment(userDetails.curprgcou.enrollenddate).format(
                      "DD MMM YYYY"
                    )}
                  </h5>
                </div>
              </div>
              <div className={Manage.individual}>
                <div className={Manage.leftside}>
                  <h4 className={Manage.typeleft}>Academic Schedule:</h4>
                </div>
                <div className={Manage.rightside}>
                  <p
                     style={{fontSize:"16px",color:'#4183c4',cursor:'pointer'}}
                    onClick={() => { addLink('academic') }}
                  >
                    View
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className={Manage.rightsection}></div>
          )}
        </div>
      </Paper>
      <div className={Manage.bottomsection}>
        <div className={Manage.firstrow}>
          <Paper>
            <div className={Manage.cardindividual}>
              <div className={Manage.icontittle}>
                <div className={Manage.iconholder}>
                  <UsersIcon
                    className={Manage.manageicons}
                    cls1={Manage.cls1}
                    cls2={Manage.cls2}
                  />
                </div>
                <Link to={`/UserStats`} className={Manage.links}>
                  <h2
                    className={Manage.cardheading}
                    onClick={() => {
                      onClickOfPage("Users", "/UserStats");
                    }}
                  >
                    Users
                  </h2>
                </Link>
              </div>
              <ul className={Manage.listblock}>
                <li className={Manage.listitems}>User Analytics</li>
                <li className={Manage.listitems}>List of users</li>
              </ul>
            </div>
          </Paper>
          <Paper>
            <div className={Manage.cardindividual}>
              <div className={Manage.icontittle}>
                <div className={Manage.iconholder}>
                  <FacultyIcon
                    className={Manage.manageicons}
                    cls1={Manage.cls1}
                    cls2={Manage.cls2}
                  />
                </div>
                <Link to={`/Faculty`} className={Manage.links}>
                  <h2
                    className={Manage.cardheading}
                    onClick={() => {
                      onClickOfPage("Faculty", "/Faculty");
                    }}
                  >
                    Faculty
                  </h2>
                </Link>
              </div>
              <ul className={Manage.listblock}>
                <li className={Manage.listitems}>Program coordinators</li>
                <li className={Manage.listitems}>Instructors</li>
              </ul>
            </div>
          </Paper>
          {userDetails.curprgcou.ptype == 1 ? (
            <Paper>
              <div className={Manage.cardindividual}>
                <div className={Manage.icontittle}>
                  <div className={Manage.iconholder}>
                    <ContentIcon
                      className={Manage.manageicons}
                      cls1={Manage.cls1}
                      cls2={Manage.cls2}
                    />
                  </div>
                  <Link to={`/Content`} className={Manage.links}>
                    <h2
                      className={Manage.cardheading}
                      onClick={() => {
                        onClickOfPage("Content", "/Content");
                      }}
                    >
                      Content
                    </h2>
                  </Link>
                </div>
                <ul className={Manage.listblock}>
                  <li className={Manage.listitems}>View all courses</li>
                  <li className={Manage.listitems}>Enable/Disable a course</li>
                </ul>
              </div>
            </Paper>
          ) : (
            <Paper>
              <div className={Manage.cardindividualdisabled}>
                <div className={Manage.icontittle}>
                  <div className={Manage.iconholder}>
                    <ContentIcon
                      className={Manage.manageiconsdisabled}
                      cls1={Manage.cls1dis}
                      cls2={Manage.cls2dis}
                    />
                  </div>
                  <h2 className={Manage.cardheadingdis}>Content</h2>
                </div>
                <ul className={Manage.listblock}>
                  <li className={Manage.listitems}>View all courses</li>
                  <li className={Manage.listitems}>Enable/Disable a course</li>
                </ul>
              </div>
            </Paper>
          )}
          <Paper>
            <div className={Manage.cardindividual}>
              <div className={Manage.icontittle}>
                <div className={Manage.iconholder}>
                  <DiscussionIcon
                    className={Manage.manageicons}
                    cls1={Manage.cls1}
                  />
                </div>
                <Link to={`/discussion`} className={Manage.links}>
                  <h2
                    className={Manage.cardheading}
                    onClick={() => {
                      onClickOfPage("Discussion", "/discussion");
                    }}
                  >
                    Discussions
                  </h2>
                </Link>
              </div>
              <ul className={Manage.listblock}>
                <li className={Manage.listitems}>Post questions</li>
                <li className={Manage.listitems}>View discussions</li>
              </ul>
            </div>
          </Paper>
        </div>
        <div className={Manage.firstrow}>
          <Paper>
            <div className={Manage.cardindividual}>
              <div className={Manage.icontittle}>
                <div className={Manage.iconholder}>
                  <AnnouncementsIcon
                    className={Manage.manageicons}
                    cls1={Manage.cls1}
                    cls2={Manage.cls2}
                  />
                </div>
                <Link to={`/announcement`} className={Manage.links}>
                  <h2
                    className={Manage.cardheading}
                    onClick={() => {
                      onClickOfPage("Announcements", "/announcement");
                    }}
                  >
                    Announcements
                  </h2>
                </Link>
              </div>
              <ul className={Manage.listblock}>
                <li className={Manage.listitems}>Add announcement / webinar</li>
                <li className={Manage.listitems}>View all announcements</li>
              </ul>
            </div>
          </Paper>
          <Paper>
            <div className={Manage.cardindividual}>
              <div className={Manage.icontittle}>
                <div className={Manage.iconholder}>
                  <EventIcon
                    className={Manage.manageicons}
                    cls1={Manage.cls1}
                    cls2={Manage.cls2}
                  />
                </div>
                <Link to={`/events`} className={Manage.links}>
                  <h2
                    className={Manage.cardheading}
                    onClick={() => {
                      onClickOfPage("Events", "/events");
                    }}
                  >
                    Events
                  </h2>
                </Link>
              </div>
              <ul className={Manage.listblock}>
                <li className={Manage.listitems}>Add / edit events</li>
                <li className={Manage.listitems}>View all events</li>
              </ul>
            </div>
          </Paper>
          <Paper>
            <div className={Manage.cardindividual}>
              <div className={Manage.icontittle}>
                <div className={Manage.iconholder}>
                  <EmailIcon
                    className={Manage.manageicons}
                    cls1={Manage.cls1}
                  />
                </div>
                <Link to={`/emailtemplate`} className={Manage.links}>
                  <h2
                    className={Manage.cardheading}
                    onClick={() => {
                      onClickOfPage("Email-Template", "/emailtemplate");
                    }}
                  >
                    E - mail template
                  </h2>
                </Link>
              </div>
              <ul className={Manage.listblock}>
                <li className={Manage.listitems}>Edit email template</li>
              </ul>
            </div>
          </Paper>
          <Paper>
            <div className={Manage.cardindividual}>
              <div className={Manage.icontittle}>
                <div className={Manage.iconholder}>
                  <GradingIcon
                    className={Manage.manageicons}
                    cls1={Manage.cls1}
                    cls2={Manage.cls4}
                    cls3={Manage.cls3}
                  />
                </div>
                <Link to={`/Grading`} className={Manage.links}>
                  <h2
                    className={Manage.cardheading}
                    onClick={() => {
                      onClickOfPage("Grading", "/Grading");
                    }}
                  >
                    Grading
                  </h2>
                </Link>
              </div>
              <ul className={Manage.listblock}>
                <li className={Manage.listitems}>Miniassignments</li>
                <li className={Manage.listitems}>Assignments</li>
                <li className={Manage.listitems}>Assesments</li>
              </ul>
            </div>
          </Paper>
        </div>
        <div
          className={Manage.lastrow}
          style={{ margin: "0px", justifyContent: "center", gap: "6rem" }}
        >
          <Paper>
            <div className={Manage.cardindividual}>
              <div className={Manage.icontittle}>
                <div className={Manage.iconholder}>
                  <ReportIcon
                    className={Manage.manageicons}
                    cls1={Manage.cls1}
                    cls2={Manage.cls2}
                  />
                </div>
                <Link to={`/Reports`} className={Manage.links}>
                  <h2
                    className={Manage.cardheading}
                    onClick={() => {
                      onClickOfPage("Reports", "/Reports");
                    }}
                  >
                    Reports
                  </h2>
                </Link>
              </div>
              <ul className={Manage.listblock}>
                <li className={Manage.listitems}>Attendance</li>
                <li className={Manage.listitems}>
                  User, Exam, Assignment and Content reports
                </li>
              </ul>
            </div>
          </Paper>
          <Paper>
            <div className={Manage.cardindividual}>
              <div className={Manage.icontittle}>
                <div className={Manage.iconholder}>
                  <FeedbackIcon
                    className={Manage.manageicons}
                    cls1={Manage.info_cls_1}
                    cls2={Manage.info_cls_2}
                  />
                </div>
                <Link to={`/Feedback`} className={Manage.links}>
                  <h2
                    className={Manage.cardheading}
                    onClick={() => {
                      onClickOfPage("Feedback", "/Feedback");
                    }}
                  >
                    Feedback
                  </h2>
                </Link>
              </div>
              <ul className={Manage.listblock}>
                <li className={Manage.listitems}>Average feedback</li>
                <li className={Manage.listitems}>Individual feedback</li>
              </ul>
            </div>
          </Paper>
          <Paper>
            <div className={Manage.cardindividual}>
              <div className={Manage.icontittle}>
                <div className={Manage.iconholder}>
                  <FeedbackIcon
                    className={Manage.manageicons}
                    cls1={Manage.info_cls_1}
                    cls2={Manage.info_cls_2}
                  />
                </div>
                <Link to={`/complaints`} className={Manage.links}>
                  <h2
                    className={Manage.cardheading}
                    onClick={() => {
                      onClickOfPage("complaints", "/complaints");
                    }}
                  >
                    Complaints / Grievances
                  </h2>
                </Link>
              </div>
              <ul className={Manage.listblock}>
                <li className={Manage.listitems}>Queries list</li>
                <li className={Manage.listitems}>Queries Response</li>
              </ul>
            </div>
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default ManageBatch;
