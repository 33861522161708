// Dependencies imports
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import swal from 'sweetalert';

// Local imports
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import { authData } from "../../redux/auth/authSlice";

// Styles imports
import Add from './AddAnnouncement.module.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent"
  },
}));

const AddCoordinator = (props) => {
  let { handleClose, getFaculty ,facultyData} = props;
  const [coordinatorEmail, setCoordinatorEmail] = useState("");
  const [coordinatorEmailErr, setCoordinatorEmailErr] = useState("");
  const [spin, setSpin] = useState(false);
  const classes = useStyles();
  let userDetails = useSelector(authData);

  const onChangeHandlerCoordinatorEmail = event => {
    setCoordinatorEmail(event.target.value);
  };

  const onsubmit = (event) => {
    // setname(name)
    // setSpin(false);
    console.log("usetail" + JSON.stringify(userDetails));
    let formIsValid = true;
    let neweid;
    setCoordinatorEmailErr("");


    if (coordinatorEmail === "") {
      formIsValid = false;
      setCoordinatorEmailErr("*Please enter your email-ID.")
      return;
    }
    if (coordinatorEmail !== "") {
      //regular expression for email validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(coordinatorEmail)) {
        formIsValid = false;
        setCoordinatorEmailErr("*Please enter valid email-ID.");
        return;
      }
    }
    if (facultyData != undefined && facultyData.length > 0) {
      for (let i = 0; i < facultyData.length; i++) {
        if (facultyData[i].email == coordinatorEmail) {
          setCoordinatorEmailErr("Coordinator already Exist");
          return;
        }
      }
    }
    if (formIsValid === true) {
     
      addCoordinator(neweid);
    }
  };


  async function addCoordinator() {
    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        cid: userDetails.curprgcou.bpid,
        email: coordinatorEmail,
        batch: userDetails.curprgcou.bname,
        action: "add",
        role: "Coordinator"
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    if (
      userDetails.role == "Instructors" ||
      userDetails.role == "Coordinators"
    ) {
      bodyParam.body.role = "Coordinator";
    }
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_UPDATEINSTRUCTORBATCH,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );
      // const users = response;
      handleClose();
      if (response.code === "2" || response.code === 2) {
        swal({
          title: "Success",
          text: "Program Coordinator added Successfully to the Batch",
          icon: "success",
          dangerMode: false,
        })
          .then(willDelete => {
            if (willDelete) {
              setSpin(false);
              // handleClose();
              getFaculty();
            }
          });

      } else if (response.code === "0" || response.code === 0) {

        swal({
          title: "Oooops!",
          text: "Program Coordinator already exist in the batch!",
          icon: "warning",
          dangerMode: true,
        })
          .then(willDelete => {
            if (willDelete) {
              setSpin(false);
            }
          });

      } else if (response.code === "3" || response.code === 3) {
        swal({
          title: "Success",
          text: "Program Coordinator successfully removed from the batch!",
          icon: "success",
          dangerMode: false,
        })
          .then(willDelete => {
            if (willDelete) {
              setSpin(false);
              handleClose();
              getFaculty();
            }
          });

      } else if (response.code === "5" || response.code === 5) {
        swal({
          title: "Oooops!",
          text: "This user is not an Program Coordinator !",
          icon: "warning",
          dangerMode: true,
        })
          .then(willDelete => {
            if (willDelete) {
              setSpin(false);
            }
          });

      } else {

        swal({
          title: "Oooops!",
          text: "Program Coordinator does not exist!",
          icon: "warning",
          dangerMode: true,
        })
          .then(willDelete => {
            if (willDelete) {
              setSpin(false);
            }
          });

      }

      setSpin(false);
      getFaculty();

    } catch (error) {
      
      swal({
        title: "Oooops!",
        text: "something went wrong!",
        icon: "warning",
        dangerMode: true,
      })
        .then(willDelete => {
          if (willDelete) {
            swal({
              title: "Contact Developer",
            
            })
          }
        });

    }
    setSpin(false);
    console.log("Mount");
  }
  return (
    <div>
      {spin === false ?
        <div className={Add.mainpopup}>
          <h3 className={Add.header}>Add Coordinator</h3>
          <div style={{ color: "#cc0000", marginBottom: "12px", marginTop: "-10px", textAlign: "start", marginLeft: "20%" }}>{coordinatorEmailErr}</div>
          <input type="email" placeholder="Email" className={Add.title} onChange={onChangeHandlerCoordinatorEmail} value={coordinatorEmail} />
          <div className={Add.buttonholder}>
            <button className={Add.cancel} onClick={handleClose}>Cancel</button>
            <button className={Add.submit} onClick={onsubmit}>Add</button>
          </div>
        </div> : <div className={classes.root}>
          <CircularProgress style={{ color: Constants.main_color_1 }} /></div>}
    </div>
  );
};

export default AddCoordinator;