// Dependencies imports
import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbarContainer, GridToolbar } from '@material-ui/data-grid';
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/styles';
import Skeleton from "@material-ui/lab/Skeleton";
import Button from '@material-ui/core/Button';
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import swal from 'sweetalert';
import AddCoordinator from './AddCoordinator';
// Local imports
import { authData } from "../../redux/auth/authSlice";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import RemovePopup from './RemovePopup';
import Fac from './Faculty.module.scss'
// Styles imports
import Assesg from './AssesmentGrid.module.scss';


const useStyles = makeStyles({
    root: {
        '& .super-app-theme--header': {
            backgroundColor: Constants.main_color_1,
            fontSize: "16px",
            color: "#fff",
            fontFamily: "nunito"
        },
    },
    root1: {
        display: 'flex',
        heigth: "100%",
        alignItems: "center",
        justifyContent: "center",
        background: "transparent"
    },
    ".MuiDataGrid-root": {
        fontSize: "14px",
        fontFamily: "nunito",
        fontWeight: 400,
        color: "black"
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
        transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    }
});

// Start of Coordinator Grid Component
const CoordinatorGrid = () => {

    const [loading, setLoading] = useState(true);
    const [facultyData, setFacultyData] = useState([]);
    const [open, setOpen] = useState(false);
    const [currentRemoveCoordinator, setCurrentRemoveCoordinator] = useState("");
    const classes = useStyles();
    const [spin, setSpin] = useState(false);
    const [openCoordinator, setopenCoordinator] = useState(false);

    let userDetails = useSelector(authData);

    // Useeffect for coordinator details api
    useEffect(() => {
        getFaculty();
        return () => { };
    }, []);

    // function for toolbar options above datagrid
    function CustomToolbar() {
        return (
            <GridToolbarContainer style={{ display: "flex", justifyContent: "flex-end" }}>
                <GridToolbar />
            </GridToolbarContainer>
        );
    }



    // Api call to get coordinator details
    async function getFaculty() {
        setLoading(true);
        const bodyParam = {
            body: {
                oid: config.aws_org_id,
                bpid: userDetails.curprgcou.bpid
            },
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        try {
            console.log(bodyParam.body);
            const faculty = await API.post(
                config.aws_cloud_logic_custom_name,
                Constants.GET_PROGRAM_BATCH_REPORT,
                bodyParam
            );
            console.log("faculty", faculty);
            convertData(faculty);
        }
        catch (error) {
            console.error(error);
        }
    }

    function convertData(data) {
        let temp = [];
        for (let i = 0; i < data.length; i++) {
            let obj = {};
            obj.id = i;
            obj.name = data[i].UNAME;
            obj.email = data[i].EMAILID;
            obj.userrole = data[i].UR;
            if (obj.userrole === "Coordinator") {
                temp.push(obj);
            }
        }
        setFacultyData(temp);
        setLoading(false);
    }



    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpenCoordinator = () => {
        setopenCoordinator(true);
      }
     
      const handleClickCloseCoordinator = () => {
        setopenCoordinator(false);
      }

    async function removeCoordinator() {
        setSpin(true);
        const bodyParam = {
            body: {

                oid: config.aws_org_id,
                cid: userDetails.curprgcou.bpid,
                email: currentRemoveCoordinator,
                batch: userDetails.curprgcou.bname,
                action: "remove",


            },
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        console.log("removebody" + JSON.stringify(bodyParam.body));

        try {
            const response = await API.post(
                config.aws_cloud_logic_custom_name,
                Constants.GET_UPDATEINSTRUCTORBATCH,
                bodyParam
                //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
            );
            // const users = response;
            
            handleClose();
            if (response.code === "2" || response.code === 2) {
                swal({
                    title: "Success",
                    text: "Program Coordinator added Successfully to the Batch",
                    icon: "success",
                    dangerMode: false,
                })
                    .then(willDelete => {
                        if (willDelete) {
                            handleClose();
                            getFaculty();
                            setSpin(false);

                        }
                    });

            } else if (response.code === "0" || response.code === 0) {

                swal({
                    title: "Oooops!",
                    text: "Program Coordinator already exist in the batch!",
                    icon: "warning",
                    dangerMode: true,
                })
                    .then(willDelete => {
                        if (willDelete) {
                            setSpin(false);

                        }
                    });

            } else if (response.code === "3" || response.code === 3) {
                swal({
                    title: "Success",
                    text: "Program Coordinator successfully removed from the batch!",
                    icon: "success",
                    dangerMode: false,
                })
                    .then(willDelete => {
                        if (willDelete) {
                            setSpin(false);
                            handleClose();
                            getFaculty();
                        }
                    });

            } else if (response.code === "5" || response.code === 5) {
                swal({
                    title: "Oooops!",
                    text: "This user is not an Program Coordinator !",
                    icon: "warning",
                    dangerMode: true,
                })
                    .then(willDelete => {
                        if (willDelete) {

                        }
                    });
            } else {
                swal({
                    title: "Oooops!",
                    text: "Program Coordinator does not exist!",
                    icon: "warning",
                    dangerMode: true,
                })
                    .then(willDelete => {
                        if (willDelete) {


                            swal({
                                title: "Contact Developer",
                               
                            })
                        }
                    });

            }
            setSpin(false);
            getFaculty();
            
        } catch (error) {
            setSpin(false);
            swal({
                title: "Oooops!",
                text: "something went wrong!",
                icon: "warning",
                dangerMode: true,
            })
                .then(willDelete => {
                    if (willDelete) {
                        swal("Oooops!", "Contact developer")
                    }
                });

        }
        console.log("Mount");
    }


    // Data for Coordinator Grid column
    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            width: 600,
            headerClassName: 'super-app-theme--header',
            flex: 1
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 600,
            headerClassName: 'super-app-theme--header',
            flex: 1
        },
        {
            field: "",
            headerClassName: 'super-app-theme--header',
            flex: 1,
            width: 400,
            renderCell: (params) => (
                <strong>
                    {/* {(params.value as Date).getFullYear()} */}
                    {(userDetails.role != "Admin") ? null : <Button
                        variant="contained"
                        size="small"
                        style={{ marginLeft: 16, background: Constants.main_color_2, color: "white", fontSize: "14px", fontFamily: "nunito" }}
                        onClick={() => { handleOpen(); setCurrentRemoveCoordinator(params.row.email) }}
                    >
                        Remove
                    </Button>}
                </strong>
            ),
        },
    ];


    return (
        <div style={{ height: 500, width: '100%' }}>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <RemovePopup handleClose={handleClose} onSubmit={removeCoordinator} spin={spin} setState={setSpin} />
                </Fade>
            </Modal>
            <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openCoordinator}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openCoordinator}>
          <AddCoordinator handleClose={handleClickCloseCoordinator} getFaculty={getFaculty} facultyData={facultyData}/>
        </Fade>
      </Modal>
            <div className={Fac.buttonholder}>
         {(userDetails.role == 'Admin' ) ? <button className={Fac.addbutton} onClick={handleClickOpenCoordinator}>Add</button> : null } 
          </div>
            <div style={{ height: 450, width: '100%' }} className={classes.root}>
                {loading === false ?
                    <DataGrid
                        rows={facultyData}
                        columns={columns}
                        components={{ Toolbar: CustomToolbar }}
                        disableSelectionOnClick
                        className={classes['.MuiDataGrid-root']}
                    /> : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "10px" }}><Skeleton variant="rect" width="100%" height={350} /> </div>}
            </div>
        </div >
    );
}

export default CoordinatorGrid;