import React from 'react';


const FacultyIcon = ({ className, cls1, cls2 }) => {
    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24">
            <path className={cls1} d="M10.84,16.25h-7A1.22,1.22,0,0,1,2.65,15V7.81A1.23,1.23,0,0,1,3.88,6.59H16.51a1.23,1.23,0,0,1,1.23,1.22V10" />
            <line className={cls2} x1="2.35" y1="18.19" x2="11.91" y2="18.13" />
            <line className={cls2} x1="10" y1="8.47" x2="15.38" y2="8.47" />
            <line className={cls2} x1="10.75" y1="10.17" x2="15.21" y2="10.17" />
            <path className={cls1} d="M20.42,12.21a2.28,2.28,0,0,1-2,2.35,2.19,2.19,0,0,1-2-2.35,2.19,2.19,0,0,1,2-2.35A2.13,2.13,0,0,1,20.42,12.21Z" />
            <path className={cls2} d="M14.15,18.41s-1.13,0-1.56-.4L8.39,13.8a1.38,1.38,0,0,1,1.95-2l2,2,1.43,1.42c.24.24.49.65.85.7s.7-.3,1-.52a4.47,4.47,0,0,1,2.75-.92,4.07,4.07,0,0,1,4.25,3.85Z" />
            <line className={cls2} x1="8.39" y1="11.84" x2="5.43" y2="8.89" />
            <line className={cls2} x1="12.35" y1="12.01" x2="14.88" y2="12.01" />
        </svg>
    );
};

export default FacultyIcon;