// Dependencies imports
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { API } from "aws-amplify";
// Local imports
import UserHeader from "../Header/UserHeader/UserHeader";
import ApplicationGrid from './ApplicationGrid';
import ApplicationApproveGrid from './ApplicationApproveGrid';
import { Link } from 'react-router-dom';

// Styles imports
import App from './Application.module.scss';
import { Tab } from 'semantic-ui-react';
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";

const Application = () => {
    const dispatch = useDispatch();
    let userDetails = useSelector(authData);
    const [loading, setLoading] = useState(true);
    const [applicationData, setApplicationData] = useState([]);
    const [approvedData, setApprovedData] = useState([]);
    const [meaData, setMeaData] = useState([]);

    useEffect(() => {
        breadcrumb();
        getApplication();
    }, []);



    function breadcrumb() {
        let sdata = { ...userDetails };
        let temp = [...sdata.breadcrumb];

        temp[2] = {
            name: 'Application',
            path: '/application'
        };

        sdata.breadcrumb = temp;

        dispatch(awsSignIn(sdata));
    }
    // Api call to get application details
    async function getApplication() {
        setLoading(true);

        const bodyParam = {
            body: {
                action: 1,
                oid: config.aws_org_id,
                bpid: userDetails.curprgcou.bpid
            },
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        if (userDetails.role == "Instructors" || userDetails.role == "Coordinators") {
            bodyParam.body.eid = userDetails.eid;
        }
        try {
            console.log(bodyParam.body);
            const application = await API.post(
                config.aws_cloud_logic_custom_name,
                Constants.GET_Programs_Details,
                bodyParam
            );
            console.log("applications", application);
            convertData(application.applications);

        }
        catch (error) {
            console.error(error);
        }
    }
    function convertData(data) {

        let temp = [];
        let approved = [];
        let approvedmea = [];

        for (let i = 0; i < data.length; i++) {
            let obj = {};
            obj.id = i;
            obj.user = data[i].uname;
            obj.application = data[i].fappid;
            obj.submitted = data[i].sdate;
            obj.eid = data[i].eid;
            obj.emailid = data[i].emailid;
            obj.appid = data[i].appid;

            if (data[i].status != 3 && data[i].status != 6 && data[i].status != 7) {

                obj.status = data[i].status == 2 ? 'Under Review' : data[i].status == 4 ? 'Rejected' : data[i].status == 5 ? 'On Hold' : '';
                temp.push(obj);
            } else if (data[i].status == 3) {

                obj.status = data[i].status == 3 ? 'Approved by JSSAHER' : '';
                approved.push(obj);
            } else {

                obj.status = data[i].status == 6 ? 'Approved by MEA' : data[i].status == 7 ? 'Rejected by MEA' : '';
                approvedmea.push(obj);
            }
        }

        setApplicationData(temp);
        setApprovedData(approved);
        setMeaData(approvedmea);
        setLoading(false);
    }
    const columns = [
        {
            field: 'user',
            headerName: 'Username',
            width: 350,
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'application',
            headerName: 'Application ID',
            width: 350,
            flex: 1,
            headerClassName: 'super-app-theme--header',
        }, {
            field: 'emailid',
            headerName: 'Email ID',
            width: 350,
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'submitted',
            headerName: 'Submitted On',
            width: 250,
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'status',
            headerName: 'Application Status',
            width: 250,
            flex: 1,
            headerClassName: 'super-app-theme--header',
        }
    ];
    const panes = [
        {
            menuItem: 'Applications',
            render: () => (
                <Tab.Pane active={true}>
                    <ApplicationGrid data={applicationData} columns={columns} />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Approved by JSSAHER',
            render: () => (
                <Tab.Pane >
                    <ApplicationApproveGrid data={approvedData} columns={columns} />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Approved by MEA',
            render: () => (
                <Tab.Pane >
                    <ApplicationGrid data={meaData} columns={columns} />
                </Tab.Pane>
            ),
        }
    ];

    return (
        <div className={App.maincontainer}>
            <UserHeader Bindex={2} />
            <div className={App.headerbutton}>
                <h3 className={App.header}>Applications</h3>
            </div>
            <div className={App.application}>
                <Tab panes={panes} />
            </div>
        </div>

    );
};

export default Application;