// Dependencies imports
import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";

import { Link, useNavigate } from "react-router-dom";

import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import Box from "@material-ui/core/Box";

// import Link from '@material-ui/core/Link';

// Local imports
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import UserHeader from "../Header/UserHeader/UserHeader";
import CoursewiseReportGrid from "./CoursewiseReportGrid";

// styles imports
import Prog from "./Programs.module.scss";
//
// Internal Styles for the material UI components
const header = {
  color: "white",
  fontFamily: "nunito",
  fontSize: "15px",
  fontWeight: "400px",
};

const programnames = {
  color: Constants.main_color_1,
  fontFamily: "nunito",
  fontSize: "16px",
  fontWeight: "500px",
};

const programtype = {
  color: "#6a6a6a",
  fontSize: "16px",
  fontWeight: "400px",
  fontFamily: "nunito",
};

const batchnames = {
  color: "#2a4696",
  fontFamily: "nunito",
  fontSize: "16px",
  fontWeight: "500px",
  paddingBotton: "10px",
};

const previousbatches = {
  color: "#2a4696",
  textDecoration: "underline",
  fontSize: "12px",
  fontWeight: "400px",
  border: "none",
  background: "transparent",
};

const useStyles = makeStyles({
  root: {
    "& .super-app-theme--header": {
      backgroundColor: Constants.main_color_1,
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
});

// Styles for Pagination
const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

// Pagination function
function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

// Start of Main Program components
const Programs = () => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [courseData, setCourseData] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const classes = useStyles();
  let userDetails = useSelector(authData);

  let navigate = useNavigate();
  const dispatch = useDispatch();
  // Useeffect for api call
  useEffect(() => {
    console.log("usserDetaissl:" + JSON.stringify(userDetails));
    getPrograms();
    return () => {};
  }, []);

  // API call syntax to get programs
  async function getPrograms() {
    setLoading(true);
    const bodyParam = {
      body: {
        bpid: userDetails.curprgcou.bpid,
        oid: config.aws_org_id,
        pid: userDetails.curprgcou.pid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const programs = await API.post(
        config.aws_cloud_logic_custom_name,
        `/getProgramsDetails`,
        bodyParam
      );
      console.log("shivaprograms" + JSON.stringify(programs));
      setTableData(programs.pcurriculum);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  // API call syntax to get batches
  async function getBatches(programs) {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        //eid: ,
        role: "admin",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    if (
      userDetails.role == "Instructors" ||
      userDetails.role == "Coordinators"
    ) {
      bodyParam.body.role = userDetails.role;
      bodyParam.body.eid = userDetails.eid;
    }
    try {
      console.log(bodyParam.body);
      const batches = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_BATCHES,
        bodyParam
      );
      console.log("Batches", batches);
      MergeData(programs.programs, batches);
    } catch (error) {
      console.error(error);
    }
  }

  // Function to Merge two data from two api
  function MergeData(programs, batches) {
    let tempPrograms = [];
    for (let i = 0; i < programs.length; i++) {
      let data = [];
      let flag = 0;
      for (const [key, value] of Object.entries(batches)) {
        if (value.opid == programs[i].pid) {
          data.push(value);
          flag = 1;
        }
      }
      programs[i].batches = data;
      if (flag == 1) {
        tempPrograms.push(programs[i]);
      } else if (userDetails.role == "Admin") {
        tempPrograms.push(programs[i]);
        // for the purposes of checking user role
      }
    }
    setTableData(tempPrograms);
    setLoading(false);
    console.log("MergeData", programs);
  }

  // API call syntax to get featured

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  function titleclick(data, PValue) {
    console.log("data", data);
    let sdata = { ...userDetails };
    // for(let i = 0; i < pdata.length; i++){
    //   if(data.bpid == pdata[i].bpid){
    //     sdata.curprgcou = pdata[i];
    //   }
    // }

    sdata.curprgDetails = { ...data };
    sdata.curprgDetails.pid = data.pid;

    console.log("dhfdf", sdata.curprgDetails);

    dispatch(awsSignIn(sdata));

    navigate("/ProgramsInfo");
  }

  function tableview() {
    console.log("tabledata" + JSON.stringify(tableData));
    return (
      <TableBody>
        {(rowsPerPage > 0
          ? tableData.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            )
          : tableData
        ).map((item) => {
          return (
            <TableRow>
              <TableCell style={{ width: "25%" }}>
                <img
                  className={Prog.image}
                  src={`https://${
                    Constants.DOMAIN
                  }/${config.aws_org_id.toLowerCase()}-resources/images/topic-images/${
                    item.tid
                  }.png`}
                  alt=""
                ></img>
              </TableCell>
              <TableCell style={{ width: "50%" }}>
                <h3
                  //   onClick={() => titleclick(item)}
                  style={programnames}
                  //   className={Prog.titleclick}
                >
                  {item.ttitle}
                </h3>
              </TableCell>
              {/* <TableCell style={{ width: "20%" }}>
                <h4 style={programtype}>Instructor Led</h4>
              </TableCell> */}
              <TableCell style={{ width: "25%" }}>
                {/* {/* <Link style={batchnames} to={`/ManageBatch`}>March Batch 2020</Link><br /> */}
                {/* <Link style={batchnames} to={`/ManageBatch`} >Feb Batch 2020</Link><br />
                <Link style={batchnames} to={`/ManageBatch`}>Jan Batch 2020</Link> */}

                <button
                  className={Prog.batchbutton}
                  onClick={() => {
                    handleClickOpen();
                    console.log("SssData" + JSON.stringify(item));
                    setCourseData(item);
                  }}
                >
                  <h3 className={Prog.batchtitle}>View Report </h3>
                </button>
              </TableCell>
              {/* <TableCell style={{ width: "10%" }}>
                <Checkbox checked={true} />
              </TableCell> */}
            </TableRow>
          );
        })}
      </TableBody>
    );
  }

  function loader() {
    let a = [1, 2, 3, 4];
    return (
      <TableBody>
        {a.map((item) => {
          return (
            <TableRow>
              <TableCell style={{ width: "25%" }}>
                <Skeleton variant="rect" width={210} height={118} />
              </TableCell>
              <TableCell style={{ width: "50%" }}>
                <Box pt={0.5}>
                  <Skeleton />
                  <Skeleton width="60%" />
                </Box>
              </TableCell>
              <TableCell style={{ width: "25%" }}>
                <Box pt={0.5}>
                  <Skeleton />
                  <Skeleton width="60%" />
                </Box>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Main Component Return
  return (
    <div className={Prog.maincontainer}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <CoursewiseReportGrid
            handleClose={handleClose}
            courseData={courseData}
          />
        </Fade>
      </Modal>
      <div className={Prog.table}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ background: Constants.main_color_1 }}>
              <TableRow>
                <TableCell style={header}>Course Image</TableCell>
                <TableCell style={header}>
                  <TableSortLabel>Course Name</TableSortLabel>
                </TableCell>
                {/* <TableCell style={header}>
                  <TableSortLabel>Program Type</TableSortLabel>
                </TableCell> */}
                <TableCell style={header}>Reports</TableCell>
                {/* <TableCell style={header}>Featured programs</TableCell> */}
              </TableRow>
            </TableHead>

            {loading ? loader() : tableview()}
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={3}
                  count={tableData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default Programs;
