// Dependencies imports
import React, { useState, useEffect } from 'react';
import { API } from "aws-amplify";
import Paper from '@material-ui/core/Paper';
import Skeleton from "@material-ui/lab/Skeleton";
import swal from 'sweetalert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch,useSelector } from "react-redux";
import { awsSignIn,authData } from "../../redux/auth/authSlice";

// Local imports
import UserHeader from "../Header/UserHeader/UserHeader";
import { ReactComponent as Edit } from "../../assets/svg/edit_black_24dp.svg";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";

// Styles imports
import Ema from './EmailTemp.module.scss';

// Start of Email Template Component
const EmailTemp = () => {
    const [loading, setLoading] = useState(true);
    const [template, setTemplate] = useState({ subject: "", body: "" });
    const [edit, setEdit] = useState(true);
    const [show, setShow] = useState(false);
    const [spin, setSpin] = useState(false);
    const dispatch = useDispatch();
    let userDetails = useSelector(authData);


    // Useeffect for template details api
    useEffect(() => {
        getTemplate();
        breadcrumb();
        return () => { };
    }, []);


    // Api call to get emait template
    async function getTemplate() {
        setLoading(true);
        const bodyParam = {
            body: {
                oid: config.aws_org_id,
            },
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        try {
            console.log(bodyParam.body);
            const template = await API.post(
                config.aws_cloud_logic_custom_name,
                Constants.GET_MAIL_TEMPLATE,
                bodyParam
            );
            console.log("template", template);
            setTemplate(template);
            setLoading(false);
        }
        catch (error) {
            console.error(error);
        }
    }

    // Api call to update email template
    async function updateTemplate() {

        setSpin(true);
        const bodyParam = {
            body: {
                oid: config.aws_org_id,
                subject: template.subject,
                body: template.body
            },
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        try {
           
            const updatetemplate = await API.post(
                config.aws_cloud_logic_custom_name,
                Constants.UPDATE_EMAIL_TEMPLATES,
                bodyParam
            );
            swal({
                title: "Success!",
                text: "Template updated successfully",
                icon: "success",
                dangerMode: false,
              })
                .then(willDelete => {
                  if (willDelete) {
                    setSpin(false);
                  }
                });
                setSpin(false);
            setShow(false);
            setEdit(true); 
            console.log("updatetemplate", updatetemplate);
            setTemplate(template);
        }
        catch (error) {
            swal({
                title: "Oooops!",
                text: "Something went wrong!",
                icon: "warning",
                dangerMode: true,
              })
                .then(willDelete => {
                  if (willDelete) {
                    setSpin(false);
                    swal({
                      title: "Contact Developer!",
        
                    })
                  }
                });
            console.error(error);
        }
    }

    const textareaone = {
        border: "1px solid lightgrey",
        width: "70%",
        resize: "none",
        background: "#f7f7f7",
        padding: "10px",
        fontFamily: "nunito",
        borderRadius: "5px",
        fontSize: "16px",
        color: "#808080"
    }

    const textareatwo = {
        border: "1px solid lightgrey",
        width: "70%",
        resize: "none",
        background: "#f7f7f7",
        padding: "10px",
        fontFamily: "nunito",
        borderRadius: "5px",
        fontSize: "16px",
        color: "#808080"
    }

    function handleChange(event) {
        let change = { ...template };
        change.subject = event.target.value;
        setTemplate(change);
        console.log(change);
    }

    function handleTemplateChange(event) {
        let change = { ...template };
        change.body = event.target.value;
        setTemplate(change);
        console.log(change);
    }

    function handleValidation() {
        if (template.subject === "") {
            alert("Subject cannot be empty")
            return;
        }

        if (template.body === "") {
            alert("Body cannot be empty");
            return;
        }

        if (template.body.indexOf("$name") === -1) {
            alert("$name cannot be removed");
            return;
        }

        if (template.body.indexOf("$user") === -1) {
            alert("$user cannot be removed");
            return;
        }

        if (template.body.indexOf("$pwd") === -1) {
            alert("$pwd cannot be removed");
            return;
        }

        updateTemplate();
        
    }
    //
    function breadcrumb() {
        let sdata = { ...userDetails };
        let temp = [...sdata.breadcrumb];
        
        temp[2] = {
            name:'Email-Template',
            path:'/emailtemplate'
        };

        sdata.breadcrumb = temp;

        dispatch(awsSignIn(sdata));
    }

    return (
        <div className={Ema.maincontainer}>
            <UserHeader Bindex={2}/>
            <div>
                <h3 className={Ema.header}>E-mail template</h3>
                {spin === true ? <div style={{position:"absolute",top:"48%",left:"48%"}}>
                    <CircularProgress style={{ color: Constants.main_color_1 }} /></div> : null}
            </div>
            <Paper>
                <div className={Ema.template}>
                    <div className={Ema.subjectsection}>
                        <h4 className={Ema.label}>Subject</h4>
                        {loading === false ?
                            <textarea style={textareaone} value={template.subject} onChange={(event) => { handleChange(event) }} disabled={edit} rows={3}>
                            </textarea> : <div style={{ width: "100%" }}><Skeleton type="rect" style={{ height: "200px", width: "70%", borderRadius: "5px", border: "1px solid lightgrey", padding: "0px 10px 10px 10px", marginTop: "-50px" }} /></div>}
                        <Edit className={Ema.icon} style={{ marginLeft: "-40px", marginTop: "10px" }} onClick={() => { setEdit(false); setShow(true) }} />

                    </div>
                    <div className={Ema.emailbody}>
                        <h4 className={Ema.label}>E-mail body</h4>
                        {loading === false ?
                            <textarea value={template.body} style={textareatwo} onChange={(event) => { handleTemplateChange(event) }} disabled={edit} rows={20} >
                            </textarea> : <div style={{ width: "100%" }}><Skeleton type="rect" style={{ height: "500px", marginTop: "-120px", width: "70%", borderRadius: "5px", border: "1px solid lightgrey", padding: "10px" }} /></div>}
                        <Edit className={Ema.icon} style={{ marginLeft: "-40px", marginTop: "10px" }} onClick={() => { setEdit(false); setShow(true) }} />
                    </div>
                    {show === true ?
                        <div className={Ema.buttonholder}>
                            <button className={Ema.cancel} onClick={() => { setShow(false); setEdit(true); getTemplate()}}>Cancel</button>
                            <button className={Ema.save} onClick={handleValidation}>Save</button>
                        </div> : null}
                </div>
            </Paper>
        </div >
    );
};

export default EmailTemp;

