import React, { useState, useEffect, useRef } from "react";
import { API } from "aws-amplify";

import Cviewstyle from "./courseCreation.module.scss";

import { Constants } from "../../../config/constants";
import config from "../../../config/aws-exports";
import { useSelector, } from "react-redux";
import { authData, } from "../../../redux/auth/authSlice";
import swal from "sweetalert";
import {

  Button,

  DialogContent,
  Dialog,
  Backdrop,
  makeStyles,

  TextField,
  InputAdornment,
  CircularProgress,

  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
} from "@material-ui/core";
import RegexTextField from "./RegexTextField";
import axios from "axios";
import CloseIcon from "@material-ui/icons/Close";
import EditorJs from "react-editor-js";
import { EDITOR_JS_TOOLS } from "./EditorConstants";
import edjsHTML from "editorjs-html";
import unzip from "unzip-js";
import CreateQuiz from "../../../components/CreateQuiz/CreateQuiz";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #fff",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "1100px",
    height: "660px",
    borderRadius: ".8rem",
    overflowX: "hidden",
    overflowY: "auto",
  },
  modal: {
    display: "flex",
   
    overflowY: "auto !important",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.73)",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "98%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
export default function ObjectPopUp({
  objOpen,
  setObjOpen,
  renderSwitch,
  folder,
  cNugget,
  setNuggets,
  nuggets,
  editquizincourse,
}) {
  let userDetails = useSelector(authData);
  const classes = useStyles();
  var iniotypes = [
    { type: "Narrative", id: "2", img: "html" },
    { type: "PDF", id: "3", img: "pdf" },
    { type: "Quiz", id: "4", img: "quiz" },
    { type: "Interactivity", id: "5", img: "Interactivity" },
    { type: "Video", id: "6", img: "video" },
    { type: "Audio", id: "7", img: "audio" },
    { type: "Scrom", id: "8", img: "scorm" },
    { type: "Project", id: "9", img: "Interactivity" },
  ];

  if (userDetails?.coursetype &&
    (userDetails.coursetype === "project" || userDetails.curprg?.coursetype === "Project")) {
    iniotypes = iniotypes.filter((pane) => pane.type === "Project");
  }

  const [loading, setLoading] = useState(false);
  const [selectType, setSelectType] = useState(true);
  const [otypes, setOtypes] = useState(iniotypes);
  const [selectedFile, setSelectedFile] = useState();
  const refobject = useRef({});
  const [isBookmarkedLoaded, setisBookmarkedLoaded] = useState(false);
  const [typesButtons, setTypeButtons] = useState(true);
  const [quizQuestion, setQuizQuestion] = useState([]);
  
  const [selectedObj, setSelectedObj] = useState({});
  const onlyAlphanumericRegex = /[^0-9]/gi;
  const instanceRef = useRef(null);
  useEffect(() => {

  }, [selectedObj]);
  const handleClose = () => {
    setSelectedObj({});
    setSelectType(true);
    setObjOpen(false);
    setTypeButtons(true);
    setQuizQuestion([]);
  };
  const sobjecttype = function (id) {
    let obj = {};
    obj.otherdata = { title: "", desc: "", dur: "", files: "" };
    if (id == "3" || id == 3) {
      obj.pdft = true;
      obj.sobject = "PDF";
      obj.afile = ".pdf";
      obj.otype = "pdf";
    } else if (id == "7" || id == 7) {
      obj.audiot = true;
      obj.sobject = "Audio";
      obj.otherdata.ytcode = "";
      obj.otype = "audio";
      obj.afile = ".mp3";
    } else if (id == "2" || id == 2) {
      obj.htmlt = true;
      obj.sobject = "Narrative";
      obj.selecthtmltype = true;
      obj.afile = ".html";
      obj.otype = "html";
      obj.otherdata.quiloutput = "";
    } else if (id == "5" || id == 5) {
      obj.htmlt = true;
      obj.sobject = "Interactivity";
      obj.selecthtmltype = true;
      obj.afile = ".html, application/zip, application/x-zip-compressed, .zip";
      obj.otype = "Interactivity";

      obj.otherdata.quiloutput = "";
    } else if (id == "8" || id == 8) {
      obj.htmlt = true;
      obj.sobject = "Scrom";
      obj.selecthtmltype = true;
      obj.afile = "application/zip, application/x-zip-compressed, .zip";
      obj.otype = "scorm";

      obj.otherdata.quiloutput = "";
    } else if (id == "4" || id == 4) {
      obj.quizt = true;
      obj.sobject = "Quiz";
      obj.qid = "0";
      obj.itemsPerPage = 1;
      obj.noques = false;
      obj.quizid = 1;
      obj.otype = "quiz";
      obj.quizquestions = [];
    } else if (id == "9" || id == 9) {
      obj.project = true;
      obj.sobject = "Project";
      obj.otype = "project";
      obj.fileEnable = false;
    } else {
      obj.videot = true;
      obj.sobject = "Video";
      obj.afile = "video/*";
      obj.otype = "video";
    }


    obj.seltype = true;
    obj.error1 = false;
    setSelectedObj(obj);
    if (id != "2" && id != 2) {
      setSelectType(false);
    }
  };

 
  function isVideo(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "m4v":
      case "avi":
      case "mpg":
      case "mp4":
     
        return true;
      default:
        return false;
    }
  }
  function isInteractivity(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "zip":
      case "html":
        return true;
      default:
        return false;
    }
  }
  function isAudio(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "mp3":
      case "wav":
      case "msv":
      case "m4p":
        return true;
      default:
        return false;
    }
  }
  function isPDF(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "pdf":
        return true;
      default:
        return false;
    }
  }
  function isHTML(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "html":
        return true;
      default:
        return false;
    }
  }
  function isScorm(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "zip":
        return true;
      default:
        return false;
    }
  }
  function getExtension(filename) {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  }

  const changeHandler = (event) => {
   
    if (event.target.files[0] !== undefined) {
      if (
        !isVideo(event.target.files[0].name) &&
        selectedObj.afile === "video/*"
      ) {
        swal("Oops!", "Select correct file format..!", "error");
        return;
      }
      if (
        !isInteractivity(event.target.files[0].name) &&
        selectedObj.afile ===
        ".html, application/zip, application/x-zip-compressed, .zip"
      ) {
        swal("Oops!", "Select correct file format..!", "error");
        return;
      }
      if (
        !isScorm(event.target.files[0].name) &&
        selectedObj.afile ===
        "application/zip, application/x-zip-compressed, .zip"
      ) {
        swal("Oops!", "Select correct file format..!", "error");
        return;
      }
      if (!isPDF(event.target.files[0].name) && selectedObj.afile === ".pdf") {
        swal("Oops!", "Select correct file format..!", "error");
        return;
      }
      if (
        !isHTML(event.target.files[0].name) &&
        selectedObj.afile === ".html"
      ) {
        swal("Oops!", "Select correct file format..!", "error");
        return;
      }
      if (
        !isAudio(event.target.files[0].name) &&
        selectedObj.afile === "audio/*"
      ) {
        swal("Oops!", "Select correct file format..!", "error");
        return;
      }

      var fileSize = event.target.files[0].size;
      var size = Math.round(fileSize / 1024);
      if (size <= 100 * 1024) {

        setSelectedFile(event.target.files[0]);
        selectedObj.otherdata.files = event.target.files[0].name;
        setSelectedObj(selectedObj);
      } else {
        swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Invalid file size, please select a file less than or equal to 100mb size",
        });

        return;
      }
    }
  };

  async function getObjectid(quizImagesPresent = false) {
    setLoading(true);
    let bodytype = {};
    bodytype.type = "object";
    bodytype.schema = config.SCHEMA_NAME;
    const bodyParam = {
      body: bodytype,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const objid = await API.post(
        config.aws_cloud_logic_custom_name,
      
        Constants.GET_TOPIC_ID,
        bodyParam
      );
     
      if (quizImagesPresent === false) {
        finalSave(objid.body[0].objid);
      } else {
        for (let i = 0; i < selectedObj.quizdata.qitems.length; i++) {
          uploadQuizImage(
            selectedObj.quizdata.qitems[i],
            objid.body[0].objid,
            i,
            selectedObj.quizdata.qitems.length
          );
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function uploadQuizImage(quest, objid, i, qlength) {
    if (quest.image === true) {
      const bodyParam = {
        body: {
          filetype: quest.file.type,
          filename: quest.imageurl,
          type: "quizobject",
          objid: objid,
          oid: config.aws_org_id,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      let preapi = "";
      if (
        config.aws_org_id === "ENABLED" ||
        config.aws_org_id === "EXCELSOFT"
      ) {
        preapi = "/getPreSignedURL";
      } else {
        preapi = "/getSouthPreSignedURL";
      }

      try {
        const preSignedData = await API.post(
          config.aws_cloud_logic_custom_name,
         
          preapi,
          bodyParam
        );

        axios
          .put(preSignedData, quest.file, {
            headers: {
              "Content-Type": quest.file.type,
              "Cache-Control": "no-cache no-store must-revalidate",
            },
          })
          .then(() => {
            delete selectedObj.quizdata?.qitems[i]?.file;

            if (i == qlength - 1) {
              finalSave(objid);
            }
          })
          .catch((error) => { });
      } catch (error) {

        console.error(error);
      }
    } else {
      if (i == qlength - 1) {
        finalSave(objid);
      }
    }
  }
  const finalSave = async () => {
    selectedObj.otherdata.dur =
      Number(selectedObj.otherdata.min) * 60 +
      Number(selectedObj.otherdata.sec);
    let file;
    let id = Math.floor(Math.random() * (999 - 100 + 1) + 100);
    let filename = "";
    if (selectedObj.stype !== undefined && selectedObj.stype == "chtml") {
      filename = Math.floor(Math.random() * (999 - 100 + 1) + 100) + ".html";
    } else if (selectedObj.otype == "quiz") {
      filename = Math.floor(Math.random() * (999 - 100 + 1) + 100) + ".json";
    } else if (selectedObj.otype == "project") {
      filename = "";
    } else {
      filename = id + selectedFile.name;
    }

    let object = {
      otitle: selectedObj.otherdata.title,
      odescription: selectedObj.otherdata.desc,
      otype: selectedObj.otype,
      oduration: selectedObj.otherdata.dur,
      ourl: filename,
      filechanges: true,
      newfile: filename,
      miniassignmenttype: selectedObj.otherdata.miniassignmenttype,
      fileEnable: selectedObj.otherdata.fileEnable,
    };
    if (selectedObj.otherdata.quesType !== undefined) {
      object.qtype = selectedObj.otherdata.quesType;
    }
    if (cNugget !== undefined && cNugget.nid !== undefined) {
      if (cNugget.objects.length > 0) {
        object.oid =
          cNugget.nid +
          "-" +
          (Number(
            cNugget.objects[cNugget.objects.length - 1].oid.substr(
              cNugget.objects[cNugget.objects.length - 1].oid.length - 1
            )
          ) +
            1);
      } else {
        object.oid = cNugget.nid + "-1";
      }
      if (cNugget.cedit !== undefined) {
        var filetype = filename.split(".").pop();
        object.ourl = object.oid + "." + filetype;
      }
    }
    refobject.current = object;

    var body = {
      filename: filename,
      folder: folder,
    };
    if (selectedObj.stype !== undefined && selectedObj.stype == "chtml") {
      body.filetype = "text/html";
      const savedData = await instanceRef.current.save();
     
      const edjsParser = edjsHTML();
  
      file = edjsParser.parse(savedData);
      file = JSON.parse(JSON.stringify(file));
      let html = "<html><heaed></heaed><body>";
      for (let i = 0; i < file.length; i++) {
        html = html + file[i];
      }
      html = html + "</html></body>";
      file = html;
    } else if (selectedObj.otype == "quiz") {
      file = selectedObj.quizdata;
      body.filetype = "application/json";
    } else if (selectedObj.otype == "project") {
      file = "";
      body.filetype = "";
    } else {
      file = selectedFile;
      body.filetype = selectedFile.type;
    }

    setisBookmarkedLoaded(true);

    uploadfile(body, file);
  };
  const checkData = async () => {
    if (
      selectedObj.otherdata.title === undefined ||
      selectedObj.otherdata.title == "" ||
      selectedObj.otherdata.title.replace(/\s/g, "").length === 0
    ) {
      selectedObj.otherdata.tval = true;

      setSelectedObj((prevselectedObj) => ({
        ...prevselectedObj,
        ...selectedObj,
      }));

      return;
    }
    if (selectedObj.otype != "project") {
      if (
        selectedObj.otherdata.desc === undefined ||
        selectedObj.otherdata.desc == "" ||
        selectedObj.otherdata.desc.replace(/\s/g, "").length === 0
      ) {
        selectedObj.otherdata.dsval = true;
        selectedObj.otherdata.tval = false;
        setSelectedObj((prevselectedObj) => ({
          ...prevselectedObj,
          ...selectedObj,
        }));
        return;
      }
      if (
        selectedObj.otherdata.min === undefined ||
        selectedObj.otherdata.min == "" ||
        selectedObj.otherdata.min.replace(/\s/g, "").length === 0
      ) {
        selectedObj.otherdata.dsval = false;
        selectedObj.otherdata.tval = false;
        selectedObj.otherdata.mval = true;
        setSelectedObj((prevselectedObj) => ({
          ...prevselectedObj,
          ...selectedObj,
        }));
        return;
      }
      if (
        selectedObj.otherdata.sec === undefined ||
        selectedObj.otherdata.sec == "" ||
        selectedObj.otherdata.sec.replace(/\s/g, "").length === 0
      ) {
        selectedObj.otherdata.dsval = false;
        selectedObj.otherdata.tval = false;
        selectedObj.otherdata.mval = false;
        selectedObj.otherdata.sval = true;
        setSelectedObj((prevselectedObj) => ({
          ...prevselectedObj,
          ...selectedObj,
        }));
        return;
      }
    }

    if (selectedObj.stype !== undefined && selectedObj.stype == "chtml") {
      const savedData = await instanceRef.current.save();
      if (savedData.blocks.length === 0) {
        selectedObj.otherdata.dsval = false;
        selectedObj.otherdata.tval = false;
        selectedObj.otherdata.mval = false;
        selectedObj.otherdata.sval = false;
        selectedObj.ferror = true;
        setSelectedObj((prevselectedObj) => ({
          ...prevselectedObj,
          ...selectedObj,
        }));
        return;
      }
    } else if (
      
      selectedObj.sobject == "Interactivity" ||
      selectedObj.sobject == "scorm" ||
      (selectedFile === undefined &&
        selectedObj.sobject !== "youtube" &&
        selectedObj.sobject !== "vimeo" &&
        selectedObj.sobject !== "Project")
    ) {
      if (selectedFile === undefined) {
        selectedObj.otherdata.dsval = false;
        selectedObj.otherdata.tval = false;
        selectedObj.otherdata.mval = false;
        selectedObj.otherdata.sval = false;
        selectedObj.otherdata.eval = false;
        selectedObj.ferror = true;
        setSelectedObj((prevselectedObj) => ({
          ...prevselectedObj,
          ...selectedObj,
        }));
        return;
      }

      unzip(selectedFile, function (err, zipFile) {
        if (err) {
          return console.error(err);
        }

        zipFile.readEntries(function (err, entries) {
          if (err) {
            return console.error(err);
          }
          let fileexist = true;
          let inapi = false;
          
          try {
            entries.forEach(function (entry) {
              if (entry.name.toString().includes("index_lms.html")) {
                fileexist = true;
                inapi = true;
                selectedObj.sname = entry.name.toString();
                setSelectedObj(selectedObj);
              }
              if (!inapi && entry.name.toString().includes("index.html")) {
                fileexist = true;
                selectedObj.sname = entry.name.toString();
                setSelectedObj(selectedObj);
              }
              if (!inapi && entry.name.toString().includes("indexAPI.html")) {
                fileexist = true;
                selectedObj.sname = entry.name.toString();
                setSelectedObj(selectedObj);
              }
            });
          } catch (e) {
            fileexist = false;
          }

          if (!fileexist) {
            selectedObj.otherdata.dsval = false;
            selectedObj.otherdata.tval = false;
            selectedObj.otherdata.mval = false;
            selectedObj.otherdata.sval = false;
            selectedObj.otherdata.eval = false;
            selectedObj.ferror = true;
            setSelectedObj((prevselectedObj) => ({
              ...prevselectedObj,
              ...selectedObj,
            }));
            swal({
              icon: "error",
              title: "Oops...",
              text: "Select correct file format..!",
            });
            setLoading(false);
            return;
          } else {
            getObjectid();
          }
        });
      });
    } else if (selectedObj.otype != "project" && selectedFile === undefined) {
      selectedObj.otherdata.dsval = false;
      selectedObj.otherdata.tval = false;
      selectedObj.otherdata.mval = false;
      selectedObj.otherdata.sval = false;
      selectedObj.ferror = true;
      setSelectedObj((prevselectedObj) => ({
        ...prevselectedObj,
        ...selectedObj,
      }));
      return;
    }



    finalSave();
  };
  async function uploadfile(body, file) {
    const bodyParam = {
      body,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {

      const preSignedData = await API.post(
        config.aws_cloud_logic_custom_name,
        
        "/getpreSignedURL",
        bodyParam
      );
      let ftype = "";
      if (selectedObj.stype !== undefined && selectedObj.stype == "chtml") {
        ftype = "text/html";
      } else if (selectedObj.otype == "quiz") {
        ftype = "application/json";
      } else if (selectedObj.otype == "project") {
        ftype = "";
      } else {
        ftype = selectedFile.type;
      }

      axios
        .put(preSignedData, file, {
          headers: {
            "Content-Type": ftype,
            "Cache-Control": "no-cache no-store must-revalidate",
          },
        })
        .then(() => {

          if (refobject.current.qtype == 2 || refobject.current.qtype == "2") {
            refobject.current.cutoff = selectedObj.quizdata.cutoff;
          }
          nuggets[cNugget.idx].nchanges = true;
          nuggets[cNugget.idx].objects.push(refobject.current);
          setNuggets(nuggets);
          setTypeButtons(true);
          setObjOpen(false);
          setQuizQuestion([]);
          setisBookmarkedLoaded(false);

          setSelectedObj({});
          setSelectType(true);

        })
        .catch((error) => {
          setisBookmarkedLoaded(false);
          console.error("There was an error!", error);
        });
    } catch (error) {

      console.error(error);
    }
  }
  return (
    <div>
      <Dialog open={objOpen} scroll="body" maxWidth="md" id="mymodal">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "1.5rem",
            backgroundColor: "#393939",
            color: "#fff",
          }}
        >
          <h3>
            Add Objects{" "}
            {selectedObj.sobject != "" && <span> - {selectedObj.sobject}</span>}
          </h3>
          <button
            onClick={handleClose}
            style={{
              border: "none",
              background: "transparent",
              cursor: "pointer",
              color: "#fff",
            }}
          >
            <CloseIcon fontSize="large" />
          </button>
        </div>

        <DialogContent>
          {selectType ? (
            <div style={{ margin: "0px 50px 0px 50px" }}>
              <p
                style={{
                  fontSize: "14px",
                  margin: "10px 0",
                  color: "#484848",
                  fontfamily: "MyWebFont1",
                }}
              >
                Please select the type of object
              </p>
              <div className={Cviewstyle.objbtnsmain}>
                <div className={Cviewstyle.objbtns}>
                  {selectedObj.sobject === undefined && (
                    <>
                      {otypes.map((type, oind) => (
                        <Button
                          variant="outlined"
                          className={Cviewstyle.objecttype}
                          onClick={() => sobjecttype(type.id)}
                        >
                          <span style={{ marginRight: "5px" }}>
                            {renderSwitch(type.img)}
                          </span>
                          <p style={{ fontSize: "14px" }}>{type.type}</p>
                        </Button>
                      ))}
                    </>
                  )}
                  {selectedObj.sobject !== undefined && (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginRight: "10px" }}
                        onClick={() => {
                          selectedObj.stype = "chtml";
                          setSelectedObj((prevselectedObj) => selectedObj);
                          setSelectType(false);
                        }}
                      >
                        Create HTML
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {

                          selectedObj.stype = "uhtml";
                          setSelectedObj((prevselectedObj) => selectedObj);
                          setSelectType(false);
                        }}
                      >
                        Upload HTML
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <>
              {selectedObj.seltype && selectedObj.sobject != "Quiz" && (
                <div style={{ minWidth: "450px" }}>
                  <div style={{ margin: "20px 60px", overflow: "hidden" }}>
                    {selectedObj.error1 && (
                      <p className={Cviewstyle.verror1}>
                        {selectedObj.sobject}
                        title cannot be empty
                      </p>
                    )}

                    <form autoComplete="off" style={{ width: "98%" }}>
                      <TextField
                        label="Title"
                        id="outlined-margin-normal"
                        className={classes.textField}
                        style={{ width: "98%" }}
                        error={selectedObj.otherdata.tval}
                        helperText={
                          selectedObj.otherdata.tval ? "Please Enter Title" : ""
                        }
                        margin="normal"
                        variant="outlined"
                        onChange={(e) => {
                          selectedObj.otherdata.title = e.target.value;
                          setSelectedObj(selectedObj);
                        }}
                      />
                      {selectedObj.otype != "project" && (
                        <TextField
                          label="Description"
                          id="outlined-margin-normal"
                          className={classes.textField}
                          error={selectedObj.otherdata.dsval}
                          helperText={
                            selectedObj.otherdata.dsval
                              ? "Please Enter Description"
                              : ""
                          }
                          margin="normal"
                          variant="outlined"
                          onChange={(e) => {
                            selectedObj.otherdata.desc = e.target.value;
                            setSelectedObj(selectedObj);
                          }}
                        />
                      )}
                      {selectedObj.otype != "project" && (
                        <RegexTextField
                          regex={onlyAlphanumericRegex}
                          label="Duration"
                          error={selectedObj.otherdata.mval}
                          helperText={
                            selectedObj.otherdata.mval ? "Please Enter Min" : ""
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                Min
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            selectedObj.otherdata.min = e.target.value;
                            setSelectedObj(selectedObj);
                          }}
                        />
                      )}
                      {selectedObj.otype != "project" && (
                        <RegexTextField
                          regex={onlyAlphanumericRegex}
                          label=""
                          error={selectedObj.otherdata.sval}
                          helperText={
                            selectedObj.otherdata.sval ? "Please Enter Sec" : ""
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                Sec
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {

                            selectedObj.otherdata.sec = e.target.value;
                            setSelectedObj(selectedObj);
                          }}
                        />
                      )}
                      {selectedObj.otype == "project" && (
                        <div style={{ width: "98%", margin: "0 8px 0 8px" }}>
                          <FormLabel
                            id="demo-row-radio-buttons-group-label"
                            style={{ fontSize: "12px", fontWeight: "600" }}
                          >
                            Enable Attachment
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                           
                            value={selectedObj.otherdata.fileEnable}
                           

                            onChange={(e) => {
                              selectedObj.otherdata.fileEnable = e.target.value;
                              setSelectedObj(selectedObj);
                            }}
                          >
                            <FormControlLabel
                              value="true"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value="false"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </div>
                      )}
                    </form>
                  </div>
                  {selectedObj.stype == "chtml" && (
                    <>
                      {selectedObj.ferror && (
                        <p
                          style={{
                            color: "red",
                            margin: "0 60px",
                            textAlign: "left",
                          }}
                        >
                          {" "}
                          Please enter some data.{" "}
                        </p>
                      )}
                      <div
                        style={{
                          height: "260px",
                          border: "1px solid #ddd",
                          margin: "0px 60px",
                          padding: "6px 28px",
                          overflow: "hidden auto",
                        }}
                      >
                        <EditorJs
                          tools={EDITOR_JS_TOOLS}
                          instanceRef={(instance) =>
                            (instanceRef.current = instance)
                          }
                        />
                      </div>
                    </>
                  )}
                  {(selectedObj.stype === undefined ||
                    selectedObj.stype != "chtml") &&
                    selectedObj.otype != "project" && (
                      <>
                        <p className={Cviewstyle.pvalid}>
                          Please select a{" "}
                          {selectedObj.sobject == "Narrative"
                            ? "HTML"
                            : selectedObj.sobject}{" "}
                          to upload
                        </p>
                        <div className={Cviewstyle.insidediv}>
                          <div className={Cviewstyle.centeritem}>
                            <input
                              type="file"
                              id="files"
                              style={{
                                visibility: "hidden",
                                position: "absolute",
                              }}
                              onChange={changeHandler}
                              accept={selectedObj.afile}
                            />
                            <label for="files">
                              <Button
                                variant="contained"
                                color="primary"
                                component="span"
                              >
                                Browse
                              </Button>
                            </label>
                          </div>

                          {selectedObj.otherdata.files != "" && (
                            <p className={Cviewstyle.pfile}>
                              {selectedObj.otherdata.files}
                            </p>
                          )}
                          {selectedObj.otherdata.files == "" && (
                            <p className={Cviewstyle.pfile}>
                              {selectedObj.sobject}
                              file not selected{" "}
                            </p>
                          )}
                          {selectedObj.ferror && (
                            <p
                              className={Cviewstyle.pfile}
                              style={{ color: "red" }}
                            >
                              {" "}
                              Please select file{" "}
                            </p>
                          )}
                        </div>
                      </>
                    )}
                </div>
              )}
              {selectedObj.sobject == "Quiz" && (
                <CreateQuiz
                  selectedObj={selectedObj}
                  setSelectedObj={setSelectedObj}
                  finalSave={finalSave}
                  folder={folder}
                  setTypeButtons={setTypeButtons}
                  typesButtons={typesButtons}
                  quizQuestion={quizQuestion}
                  setQuizQuestion={setQuizQuestion}
                  editquizincourse={editquizincourse}
                  handleClose={handleClose}
                />
              )}
            </>
          )}
          {isBookmarkedLoaded && (
            <Backdrop className={classes.backdrop} open={true}>
              <CircularProgress color="inherit" />
            </Backdrop>
          )}

          {!selectType && selectedObj.sobject != "Quiz" && (
            <div style={{ textAlign: "center", padding: "2rem" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => checkData()}
              >
                Save
              </Button>
            </div>
          )}
        </DialogContent>
      </Dialog>

    </div>
  );
}
