import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { CardMedia } from "@material-ui/core";
//import { ClassNames } from "@emotion/react";

const useStyles = makeStyles((theme) => ({
  root: {
  
    width: "80%",
    height: "80%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
}));

const ViewDoc = (props) => {
  let { handleAddClose, docFileName,eid,ftype } = props;
  const classes = useStyles();
  let userDetails = useSelector(authData);

  return (
    <div
      style={{
        width: "80%",
        //height: "80%",
        background: "transparent",
        alignitems: "center",
        justifycontent: "center",
        backgroundColor: "white",
        display: "grid",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "max-content",
        }}
      >
        <h3>{docFileName}</h3>
        <button onClick={handleAddClose}>X</button>
      </div>
      <div
        style={{
          textAlign: "center",
          marginTop: "20px",
          height: "50rem",
          overflow: "scroll",
          overflowX: "hidden",
          
        }}
      >
        

        <CardMedia style={{height:"100%", width:"100%"} } className={classes.root}
          alt="GeeksforGeeks"
          component="iframe"
          src={`https://${
            Constants.DOMAIN
          }/${config.aws_org_id.toLowerCase()}-resources/profile/${
            eid
          }/${ftype}/${docFileName}`}
        />
      </div>
    </div>
  );
};

export default ViewDoc;
