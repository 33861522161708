// Dependencies imports
import React, { useEffect, useState } from 'react';
import { Tab } from 'semantic-ui-react';
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from '@material-ui/styles';
import { useSelector, useDispatch } from "react-redux";
import { authData, awsSignIn, awsSignOut } from "../../redux/auth/authSlice";
// Local imports
import UserHeader from '../Header/UserHeader/UserHeader';
import InstructorGrid from './InstructorGrid';
import CoordinatorGrid from './CoordinatorGrid';


import EditEvents from './EditEvents';
import DeleteEvents from './DeleteEvents';
import { Constants } from '../../config/constants';

// Styles imports
import Fac from './Faculty.module.scss'

// Start of Faculty Component
const useStyles = makeStyles({
  root: {
    '& .super-app-theme--header': {
      backgroundColor: Constants.main_color_1,
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
});
const Faculty = () => {
  const dispatch = useDispatch();
  
  const [openCoordinator, setopenCoordinator] = useState(false);
  const classes = useStyles();
  // Data for tabs
  let userDetails = useSelector(authData);

  useEffect(() => {
    breadcrumb();
  }, []);

  function breadcrumb() {
    let sdata = { ...userDetails };
    let temp = [...sdata.breadcrumb];
    
    temp[2] = {
        name:'Faculty',
        path:'/Faculty'
    };

    sdata.breadcrumb = temp;

    dispatch(awsSignIn(sdata));
}
 
 
  const panes = [
    {
      menuItem: 'Program Coordinator',
      render: () => (
        <Tab.Pane >
          
         
          <CoordinatorGrid />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Instructors',
      render: () => (
        <Tab.Pane active={true}>
         
          <InstructorGrid />
        </Tab.Pane>
      ),
    }
  ];

  return (
    <div className={Fac.maindiv}>
      <UserHeader Bindex={2}/>
      <h3 className={Fac.header}>Faculty</h3>
     
    
      <Tab panes={panes} />
      {/* <AddCoordinator />
            <AddInstructor /> */}
    </div>
  );
};

export default Faculty;