// Dependencies imports
import React, { useEffect, useState, useRef } from "react";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import { Tab } from "semantic-ui-react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { useDispatch } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TablePagination from "@material-ui/core/TablePagination";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Local imports
import UserHeader from "../Header/UserHeader/UserHeader";
import { API } from "aws-amplify";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

//Styles imports
import Grad from "./Grading.module.scss";
import Cont from "./Content.module.scss";
import Ann from "./Announcement.module.scss";
import Grade from "./GradingDetails.module.scss";

import IndividualFeedback from "./IndividualFeedback";
import CRIRFeedback from "./CRIRFeedback";

const header = {
  color: "white",
  fontFamily: "nunito",
  fontSize: "15px",
  fontWeight: "400px",
};

const programnames = {
  color: Constants.main_color_1,
  fontFamily: "nunito",
  fontSize: "18px",
  fontWeight: "500px",
};

const programtype = {
  color: "black",
  fontSize: "16px",
  fontWeight: "400px",
  fontFamily: "nunito",
  paddingBotton: "10px",
};

// Start of Feedback component
const Feedback = () => {
  // Data for tabs
  const [programDetail, setProgramDetail] = useState([]);
  const [loading, setLoading] = useState(true);
  const [finalFeedback, setFinalFeedback] = useState();
  const [feedbackQuestions, setFeedbackQuestions] = useState();
  const [feedbackOptions, setFeedbackOptions] = useState();
  const feedbackText = useRef();
  const [feedbackTextArray, setFeedbackTextArray] = useState([]);
  const [responseCode, setResponseCode] = useState();
  const [open, setOpen] = useState(false);
  // const [exportCourse, setExportCourse] = useState([]);
  const [indexValue, setIndexValue] = useState();
  const [progDetails, setProgDetails] = useState({pcurriculum:[]});
  const [fopen, setFOpen] = useState(false);

  const [crir, setcrir] = useState("");
  const [bcid, setbcid] = useState("");
  const [question, setquestion] = useState("");
  const [qno, setqno] = useState("");

  let userDetails = useSelector(authData);
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      paddingTop: "20px",
      height: "60vh",
      overflowY: "auto",
    },
    heading: {
      fontSize: theme.typography.pxToRem(30),
      fontWeight: theme.typography.fontWeightRegular,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      border: "none",
      transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },

    tooltipFull: {
      fontSize: 30,
    },
    bottomIcons: {
      color: "#000",
      "&:hover": {
        color: "#777",
      },
    },
    volumeButton: {
      color: "#000",
      fontSize: 40,
      transform: "scale(0.9)",
      "&:hover": {
        color: "#777",
        transform: "scale(1.03)",
        transitionTimingFunction: "cubic-bezier(0.1, 0.1, 0.25, 1)",
      },
    },
    volumeButtonExit: {
      color: "lightgray",
      fontSize: 40,
      transform: "scale(0.9)",
      "&:hover": {
        color: "#777",
        transform: "scale(1.03)",
        transitionTimingFunction: "cubic-bezier(0.1, 0.1, 0.25, 1)",
      },
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    getAverageFeedback();
    getProgDetails();
    // EnableDisaleFeedback();
    breadcrumb();
  }, []);

  async function EnableDisaleFeedback(responseCode) {
    setLoading(true);
    const bodyParam = {
      body: {
        pid: userDetails.curprgcou.opid,
        bpid: userDetails.curprgcou.bpid,
        oid: config.aws_org_id,
        feedback: responseCode,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    // alert(JSON.stringify(bodyParam.body));
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.ENABLE_DISABLE_FEEDBACK,
        bodyParam
      );

      // alert(JSON.stringify(response));
      getAverageFeedback();
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  async function getAverageFeedback() {
    setLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        pid: userDetails.curprgcou.opid,
        bpid: userDetails.curprgcou.bpid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    // alert(JSON.stringify(bodyParam.body));
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.AVERAGE_FEEDBACK,
        bodyParam
      );
      // alert(JSON.stringify(response.questions.length));
      if (
        response.feedbackenable == true ||
        response.feedbackenable == "true"
      ) {
        setResponseCode("Disable");
      } else {
        setResponseCode("Enable");
      }

      if (
        response.questions === "null" ||
        response.questions === null ||
       // response.questions === [] ||
        response.questions === undefined
      ) {
        setFeedbackQuestions(null);
        setFeedbackOptions(null);
        setLoading(false);
      } else {
        setFeedbackQuestions(response.questions);
        setFeedbackOptions(response.options);

        let finalArray = [];
        // alert(response.options[0])
        for (let i = 0; i <= response.options.length; i++) {
          if (response.options[i] == 0 || response.options[i] == "0") {
            feedbackText.current = "Strongly Agree";
            finalArray.push(feedbackText.current);
          }
          if (response.options[i] == 1 || response.options[i] == "1") {
            feedbackText.current = "Agree";
            finalArray.push(feedbackText.current);
          }
          if (response.options[i] == 2 || response.options[i] == "2") {
            feedbackText.current = "Disagree";
            finalArray.push(feedbackText.current);
          }
          if (response.options[i] == 3 || response.options[i] == "3") {
            feedbackText.current = "Strongly Disagree";
            finalArray.push(feedbackText.current);
          }
          if (response.options[i] == 4 || response.options[i] == "4") {
            feedbackText.current = "Not Applicable";
            finalArray.push(feedbackText.current);
          }
        }
        // alert(JSON.stringify(feedbackText))
        // finalArray.push(feedbackText);
        setFeedbackTextArray(finalArray);
        setLoading(false);
        // alert(JSON.stringify(finalArray));
      }
      //    alert(JSON.stringify(feedbackQuestions))
    } catch (error) {
      console.error(error);
    }
  }

  async function getProgDetails() {

    const bodyParam = {
      body: {
        bpid: userDetails.curprgcou.bpid,
        oid: config.aws_org_id,
        pid: userDetails.curprgcou.pid
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    if (userDetails.role == "Instructors" || userDetails.role == "Coordinators") {
            bodyParam.body.eid = userDetails.eid;
          }
    try {
      console.log(bodyParam.body);
      const PDetails = await API.post(
        config.aws_cloud_logic_custom_name,
        (`/getProgramsDetails`),
        bodyParam
      );
      console.log("PDetails"+ JSON.stringify(PDetails));
      setProgDetails(PDetails);
      setLoading(false);
      console.log(PDetails);
    }
    catch (error) {
      // alert(JSON.stringify(error));
      console.error(error);
    }
  }

 

  const handleClose = () => {
    setOpen(false);
  };

  function modallfeed() {
    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <IndividualFeedback
              handleClose={handleClose}
              setFeedbackQuestions={setFeedbackQuestions}
              feedbackQuestions={feedbackQuestions}
              setIndexValue={setIndexValue}
              indexValue={indexValue}
            />
          </Fade>
        </Modal>
      </>
    );
  }
  function modallfeed1() {
    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={fopen}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={fopen}>
            <CRIRFeedback close={setFOpen} crir={crir} bcid={bcid} question={question} qno={qno}/>
          </Fade>
        </Modal>
      </>
    );
  }

  function openF(crir,bcid,question,qno) {
    setcrir(crir);
    setbcid(bcid);
    setquestion(question);
    setqno(qno);
    setFOpen(true);
  }

  function exportcsv() {
    // let an = feedbackQuestions;
    // let opt = feedbackTextArray;

    var months = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    var a = new Date();
    var aday = a.getDate() + "-" + months[a.getMonth()] + "-" + a.getFullYear();

    var json_pre = [],
      tnm = "Feedback";

    let arrHeaderid = ["Questions", "Options"];
    let arrHeadertitle = ["Questions", "Options"];
    // for (let row of an) {
    // arrHeaderid.push(feedbackQuestions);
    // arrHeadertitle.push(feedbackTextArray);
    // }
    var obj1 = {};
    obj1.Questions = feedbackQuestions[0];
    obj1.Options = feedbackTextArray[0];

    json_pre.push(obj1);

    var obj2 = {};
    obj2.Questions = feedbackQuestions[1];
    obj2.Options = feedbackTextArray[1];

    json_pre.push(obj2);

    var obj3 = {};
    obj3.Questions = feedbackQuestions[2];
    obj3.Options = feedbackTextArray[2];

    json_pre.push(obj3);

    var obj4 = {};
    obj4.Questions = feedbackQuestions[3];
    obj4.Options = feedbackTextArray[3];

    json_pre.push(obj4);

    var obj5 = {};
    obj5.Questions = feedbackQuestions[4];
    obj5.Options = feedbackTextArray[4];

    json_pre.push(obj5);

    var obj6 = {};
    obj6.Questions = feedbackQuestions[5];
    obj6.Options = feedbackTextArray[5];

    json_pre.push(obj6);

    var obj7 = {};
    obj7.Questions = feedbackQuestions[6];
    obj7.Options = feedbackTextArray[6];

    json_pre.push(obj7);

    var obj8 = {};
    obj8.Questions = feedbackQuestions[7];
    obj8.Options = feedbackTextArray[7];

    json_pre.push(obj8);

    var obj9 = {};
    obj9.Questions = feedbackQuestions[8];
    obj9.Options = feedbackTextArray[8];

    json_pre.push(obj9);

    var obj10 = {};
    obj10.Questions = feedbackQuestions[9];
    obj10.Options = feedbackTextArray[9];

    json_pre.push(obj10);
    // alert(JSON.stringify(json_pre))

    let csvData = ConvertToCSV(json_pre, arrHeaderid, arrHeadertitle);

    let blob = new Blob(["\ufeff" + csvData], {
      type: "text/csv;charset=utf-8;",
    });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("Chrome") == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }

    dwldLink.setAttribute("href", url);

    dwldLink.setAttribute("download", tnm + " " + aday + ".csv");

    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  function ConvertToCSV(objArray, headerList, newHeaders) {
    // alert("headerList "+JSON.stringify(headerList));
    // alert("newHeaders "+JSON.stringify(newHeaders));
    // console.log(headerList);
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    let row = "";
    // alert(JSON.stringify(array[0].Questions))

    //    let newHeaders = [];

    //        newHeaders = ["Name", "Score"];

    for (let index in newHeaders) {
      row += newHeaders[index] + ",";
    }
    row = row.slice(0, -1);
    str += row + "\r\n";
    // alert("array "+JSON.stringify(array));
    for (let i = 0; i < array.length; i++) {
      let line = "";
      // alert("headerList "+JSON.stringify(headerList));
      for (let index in headerList) {
        let head = headerList[index];
        // alert("head "+JSON.stringify(array[i]) + "head "+JSON.stringify(head));
        line +=
          headerList[headerList.length - 1] == headerList[index]
            ? strRep(array[i][head])
            : strRep(array[i][head]) + ",";
      }
      str += line + "\r\n";
    }
    return str;
  }

  function strRep(data) {
    // alert(JSON.stringify(data))
    if (typeof data == "string") {
      let newData = data.replace(/,/g, " ");
      return newData;
    } else if (typeof data == "undefined") {
      return "-";
    } else if (typeof data == "number") {
      return data.toString();
    } else {
      return data;
    }
  }

  function breadcrumb() {
    let sdata = { ...userDetails };
    let temp = [...sdata.breadcrumb];

    temp[2] = {
      name: "Feedback",
      path: "/Feedback",
    };

    sdata.breadcrumb = temp;

    dispatch(awsSignIn(sdata));
  }

  return (
    <div className={Grad.containermain}>
      {modallfeed()}
      {modallfeed1()}
      <UserHeader Bindex={2} />
      <div className={Cont.topbar}>
        <h2 className={Cont.header}>Program Feedback</h2>

        {feedbackQuestions != null || feedbackQuestions != undefined ? (
          <div>
            <button
              className={Cont.createbutton}
              style={{ cursor: "pointer" }}
              onClick={() => {
                EnableDisaleFeedback(responseCode);
              }}
            >
              {responseCode}
            </button>
          </div>
        ) : null}
      </div>
      <div>
        {loading === false ? (
          <div style={{ marginTop: "20px",width:"100%" }}>
            {/* <button className={Grade.exportsubmit} onClick={() => exportcsv()}>
              Export CSV
            </button> */}
            {feedbackQuestions != null || feedbackQuestions != undefined ? (
              <div style={{ marginTop: "20px" }}>
                {feedbackQuestions.map((questions, index) => (
                  <div className={Grad.headingQuestions} style={{ width:"100%",backgroundColor:"white",padding:"12px" }}>
                    <p>
                      {index + 1}. {questions}
                    </p>
                    <div style={{ marginTop: "10px" }}>
                      <p className={Grad.headingFeedback}>
                        Most given Feedback : {feedbackTextArray[index] != undefined? feedbackTextArray[index]:null}
                      </p>
                      <div
                        onClick={() => {
                          setOpen(true);
                          setIndexValue(index);
                        }}
                      >
                        <p className={Grad.headingIndividualFeedback}>
                          Individual Feedback{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div>
                <h3 className={Cont.subheader}>
                  There is no feedback for this batch yet
                </h3>
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Skeleton variant="rect" width={1500} height={500} />{" "}
          </div>
        )}

        
      </div>
      <div style={{width:"100%"}}>
      <h2 className={Cont.header} style={{marginBottom:"6px",marginTop:"10px"}}>Course Feedback</h2>

        <div>
        
          {progDetails.pcurriculum.map((item)=>{
            return (
              <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>{item.ttitle}</Typography>
              </AccordionSummary>
              <AccordionDetails style={{display:"flex",justifyContent:"center",flexDirection:"column"}}>
                {progDetails.cr.map((it,i)=>{
                  return (
                <Typography style={{padding:"6px",cursor:"pointer"}} onClick={()=>{openF("cr",item,it,`q${i+1}`);}}>
                  <h4 >
                    {i+1}. {it}
                  </h4>
                </Typography>
                  );
                })}
                
              </AccordionDetails>
            </Accordion>
            );
          })}
       
        </div>
        </div>

        <div style={{width:"100%"}}>
        <h2 className={Cont.header} style={{marginBottom:"6px",marginTop:"10px"}}>Instructor Feedback</h2>

        <div>
          {progDetails.pcurriculum.map((item)=>{
            return (
              <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>{item.ttitle}</Typography>
              </AccordionSummary>
              <AccordionDetails style={{display:"flex",justifyContent:"center",flexDirection:"column"}}>
                {progDetails.ir.map((it,i)=>{
                  return (
               
                    <Typography style={{padding:"6px",cursor:"pointer"}} onClick={()=>{openF("ir",item,it,`q${i+1}`);}}>
                    <h4 >
                      {i+1}. {it}
                    </h4>
                    </Typography>
                  );
                })}
                
              </AccordionDetails>
            </Accordion>
            );
          })}
       
        </div>
        </div>
{/* <Typography style={{padding:"6px",cursor:"pointer"}} onClick={()=>{openF("ir",item.btid,it,`q${i+1}`);}}>
<h4 >
  {i+1}. {it}
</h4>
</Typography> */}
    </div>
  );
};

export default Feedback;

