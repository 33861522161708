// Dependencies imports
import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Skeleton from "@material-ui/lab/Skeleton";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";

// Local imports
import { ReactComponent as Close } from "../../assets/svg/close_black_24dp.svg";
import { authData } from "../../redux/auth/authSlice";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";

// Styles imports
import Acc from "./AccordionPopup.module.scss";

const titlediv = {
  width: "50 %",
  height: "30px",
};

const title = {
  fontSize: "14px",
  fontFamily: "nunito",
  fontWeight: 500,
  color: Constants.main_color_1,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    width: "100%",
    paddingTop: "20px",
  },
  root1: {
    width: "100%",
  },
  heading: {
    fontSize: "16px",
    fontWeight: 400,
    color: Constants.main_color_1,
  },
  subheading: {
    color: "black",
    fontFamily: "nunito",
    fontSize: "14px",
    fontWeight: "600px",
  },
  "MuiAccordionDetails-root": {
    display: "inline-block",
    width: "100%",
    padding: "10px 20px",
  },
}));

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress
          variant="determinate"
          color="primary"
          style={{ backgroundColor: "whitesmoke" }}
          {...props}
        />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

// Start of main accordion component
const AccordionPopup = (props) => {
  const [program, setProgram] = useState({ pcurriculum: [] });
  const [loading, setLoading] = useState(true);
  const [topic, setTopic] = useState();

  const classes = useStyles();
  let { handleClose } = props;

  let userDetails = useSelector(authData);
  
  useEffect(() => {
    getuserprogress();
    return () => {};
  }, []);

  async function getuserprogress() {
    setLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.selbatchuser.eid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      
      const topic = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_USER_PROGRESS,
        bodyParam
      );

      if (topic === "No Topics") {
        // Handle the case when 'topic' is "No Topics"
      } else {
        setTopic(topic);
        getProgramsDetails(topic, "1");
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getProgramsDetails(topic, sem) {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        pid: userDetails.curprgcou.pid,
        bpid: userDetails.curprgcou.bpid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    if (
      userDetails.role === "Instructors" ||
      userDetails.role === "Coordinators"
    ) {
      bodyParam.body.eid = userDetails.eid;
    }

    try {
     
      const program = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_Programs_Details,
        bodyParam
      );

      let userCourse = topic[userDetails.curprgcou.bpid]?.courses;

      const topicsids = [];

      for (const iterator of program.pcurriculum) {
        if (iterator.semester === sem) {
          if (iterator.telective == false || iterator.telective == undefined) {
            topicsids.push(iterator.tid);
          } else {
            if (
              userCourse[iterator.btid] != undefined &&
              userCourse[iterator.btid]?.td?.telective &&
              userCourse[iterator.btid]?.td?.selected
            ) {
              topicsids.push(iterator.tid);
            }
          }
        }
      }

      getTopic(topic, program, topicsids);
    } catch (error) {
      console.error(error);
    }
  }

  async function getTopic(topic, program, topicsids) {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        topicids: topicsids,
        action: 1,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const topics = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_TOPIC,
        bodyParam
      );
      convertData(topic, program, topics.result);
    } catch (error) {
      console.error(error);
    }
  }

  function convertData(topic, program, topics) {
    const userdata = topic[userDetails.curprgcou.bpid].courses;
    const tempArray = [];

    for (const pcurr of program.pcurriculum) {
      for (const [key, value] of Object.entries(topics)) {
        if (key === pcurr.tid) {
          tempArray.push(pcurr);
        }
      }
    }

    program.pcurriculum = tempArray;

    for (const pcurr of program.pcurriculum) {
      const btid = pcurr.btid;
      let cnt = 0;

      for (const nugget of topics[pcurr.tid].nuggets) {
        for (const object of nugget.objects) {
          const obid = object.oid;

          if (
            userdata != undefined &&
            obid != undefined &&
            userdata[btid] != undefined &&
            userdata[btid].objects != undefined &&
            userdata[btid].objects[obid] != undefined &&
            userdata[btid].objects[obid].timespent != undefined
          ) {
            object.obtime = userdata[btid].objects[obid].timespent;
          } else {
            object.obtime = 0;
          }

          if (object.obtime / 60 > object.oduration) {
            object.obtime = object.oduration * 60;
          }

          if (object.obtime >= 60) {
            object.min = Math.floor(object.obtime / 60);
            object.sec = (object.obtime % 60).toFixed();
          } else {
            object.min = 0;
            object.sec = object.obtime.toFixed();
          }

          const per = (object.obtime / 60 / +object.oduration) * 100;
          object.per = per !== undefined ? per.toFixed(0) : 0;
          cnt++;
        }
      }

      pcurr.topic = topics[pcurr.tid];
    }

    setProgram(program);
    setLoading(false);
  }

  function listView() {
    return program.pcurriculum.map((pcurr) => (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{pcurr.ttitle}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ width: "100%" }}>
            <div className={classes.root}>
              {pcurr.topic.nuggets.map((nugget) => (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.subheading}>
                      {nugget.ntitle}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    className={classes["MuiAccordionDetails-root"]}
                  >
                    {nugget.objects.map((object) => (
                      <div className={Acc.toggleholder}>
                        <div className={Acc.toggleinternal}>
                          <div style={titlediv}>
                            <Typography style={title}>
                              {object.otitle}
                            </Typography>
                          </div>
                          <div className={classes.root1}>
                            <LinearProgressWithLabel value={object.per} />
                          </div>
                        </div>
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
    ));
  }
  const onChangeHandlerSem = (event) => {
    setLoading(true);
    getProgramsDetails(topic, event.target.value);
  };

  return (
    <div
      className={Acc.mainpopup}
     
    >
      <div
        className="fixed"
        style={{
          position: "fixed",
          width: "75%",
          zIndex: "999",
          background: "white",
          top:"3.7rem"
        }}
      >
        <div>
          <Close onClick={handleClose} style={{ float: "right" }} />
          <div
            style={{
              display: "flex",
              gap: "3rem",
              textAlign: "left",
              margin: "2rem",
            }}
          >
            <div>
              <h4>Name</h4>
              <h4>Email Id </h4>
              <h4>Candidate Id </h4>
            </div>

            <div>
              <h4>{userDetails.selbatchuser.name}</h4>
              <h4>{userDetails.selbatchuser.email} </h4>
              <h4>{userDetails.selbatchuser.usn}</h4>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.root} style={{ marginTop: "7rem" }}>
        <div className={Acc.leftside}>
          <h3 className={Acc.select}>Select Semester</h3>
          <select
            style={{
              border: "none",
              width: "20%",
              boxShadow: "0px 0px 5px 0px lightgrey",
              background: "white",
              height: "30px",
              marginBottom: "20px",
              fontSize: "15px",
              paddingLeft: "10px",
              fontWeight: "200px",
            }}
            name="sem"
            id="sem"
            required
            onChange={onChangeHandlerSem}
          >
            <option value="1">Semester 1</option>
            <option value="2">Semester 2</option>
            <option value="3">Semester 3</option>
            <option value="4">Semester 4</option>
            <option value="5">Semester 5</option>
            <option value="6">Semester 6</option>
          </select>
        </div>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Skeleton variant="rect" width={1500} height={600} />{" "}
          </div>
        ) : (
          listView()
        )}
      </div>
    </div>
  );
};

export default AccordionPopup;
