// Dependencies imports
import React, { useEffect } from "react";
import { Tab } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";

// Local imports
import UserHeader from "../Header/UserHeader/UserHeader";
import AttendanceGrid from "./AttendanceGrid";
import ReportGrid from "./ReportGrid";
import MiniGrid from "./MiniGrid";
import CourseReport from "./Coursewisereport";
import MarksCard from "./MarkscardUpload";
// import AssesmentGrid from './AssesmentGrid';

// Style imports
import Rep from "./Reports.module.scss";

// Start of Reports component
const Reports = () => {
  const dispatch = useDispatch();
  let userDetails = useSelector(authData);

  useEffect(() => {
    breadcrumb();
  }, []);

  function breadcrumb() {
    let sdata = { ...userDetails };
    let temp = [...sdata.breadcrumb];

    temp[2] = {
      name: "Reports",
      path: "/Reports",
    };

    sdata.breadcrumb = temp;

    dispatch(awsSignIn(sdata));
  }

  // Data for tabs
  const panes = [
    {
      menuItem: "Attendance",
      render: () => (
        <Tab.Pane active={true}>
          <div className={Rep.gridcontainer}>
            <AttendanceGrid />
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Over all Report",
      render: () => (
        <Tab.Pane active={true}>
          <div className={Rep.gridcontainer}>
            <ReportGrid />
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Course wise report",
      render: () => (
        <Tab.Pane active={true}>
          <div className={Rep.gridcontainer}>
            <CourseReport />
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Marks Cards & Certificates",
      render: () => (
        <Tab.Pane active={true}>
          <div className={Rep.gridcontainer}>
            <MarksCard />
          </div>
        </Tab.Pane>
      ),
    },
    // {
    //     menuItem: "Assessment report",
    //     render: () => (
    //         <Tab.Pane active={true}>
    //             <div className={Rep.gridcontainer}>
    //                 <AssesmentGrid />
    //             </div>
    //         </Tab.Pane>
    //     ),
    // }
  ];
  return (
    <div className={Rep.maincontainer}>
      <UserHeader Bindex={2} />
      <h3 className={Rep.heading}>Reports</h3>
      <div className={Rep.gridholder}>
        <Tab panes={panes} />
      </div>
    </div>
  );
};

export default Reports;
