import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import config from "../../../config/aws-exports";
import { API, JS } from "aws-amplify";
import { Constants } from "../../../config/constants";
import assessStyle from "../AssessmentAndAssignmentView/Assessment.module.scss";
import { produce } from "immer";
import { generate } from "shortid";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextareaAutosize,
  Typography,
  withStyles,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles, Radio, RadioGroup } from "@material-ui/core";
import swal from "sweetalert";
import moment from "moment";
import { Image } from "semantic-ui-react";
import DeleteIcon from "@material-ui/icons/Delete";
import { Skeleton } from "@material-ui/lab";
import { green } from "@material-ui/core/colors";
import CloseIcon from "@material-ui/icons/Close";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { authData, awsSignIn } from "../../../redux/auth/authSlice";
import axios from "axios";

const CustomButton = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "6px 15px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: "#01498e",
    borderColor: "#fff",
    color: "#fff",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      backgroundColor: "#01498e",
      border: "1px solid #fff",
      color: "#fff",
      boxShadow: "#fff",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#fff",
      borderColor: "#01498e",
    },
    "&:focus": {
      //   boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  },
})(Button);

const useStyles = makeStyles((theme) => ({
  paper: {
    "& .MuiDialog-paperWidthSm": {
      minWidth: "800px",
      padding: "30px 80px",
    },
  },
}));

const BlueRadio = withStyles({
  root: {
    color: "#01498e",
    "&$checked": {
      color: "#01498e",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
const initialValues = [
  { content: "" },
  { content: "" },
  { content: "" },
  { content: "" },
  { content: "" },
  { content: "" },
];

const initialValidation = {
  error1: false,
  error2: false,
  error3: false,
  error4: false,
  error5: false,
  error6: false,
  error7: false,
  error8: false,
  error9: false,
  error09: false,
};
const initialState = {
  opt1: false,
  opt2: false,
  opt3: false,
  opt4: false,
  opt5: false,
  opt6: false,
};
export default function EditAssignment({
  open,
  handleClose,
  scroll,
  assessId,
  type,
  setInstructionsView,
  instructionsView,
  fullScreen,
  courseId,
  qLoading,
  row,
}) {
  console.log({ row });
  const classes = useStyles();
  const initialMatchTheFollowing = [
    { value1: "", value2: "" },
    { value1: "", value2: "" },
    { value1: "", value2: "" },
    { id: generate(), value1: "", value2: "" },
  ];
  const [state, setState] = useState(initialState);
  const [singleSelectImageBase, setSingleSelectImageBase] =
    useState(initialValidation);
  const [multiSelectValidation, setMultiSelectValidation] =
    useState(initialValidation);
  const [questionValidation, setQuestionValidation] =
    useState(initialValidation);
  const [instructions, setInstructions] = useState([{ value: "" }]);
  const [value, setValue] = useState("");
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [correct, setCorrect] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [quizQuestions, setQuizQuestions] = useState([]);
  const [questionData, setQuestionData] = useState([]);
  const [titel, setTitel] = useState(row.title);
  const [fileName, setFileName] = useState("");
  const [resTime, setResTime] = useState(
    moment(row.answerdate).format("hh:mm")
  );
  const [resDate, setResDate] = useState(
    moment(row.answerdate).format("YYYY-MM-DD")
  );
  const [ins, setins] = useState(row.instructions);
  const [quizType, setQuizType] = useState("");
  const dispatch = useDispatch();
  const [matchTheFollowing, setMatchTheFollowing] = useState(
    initialMatchTheFollowing
  );
  const [multiSelectImageBase, setMultiSelectImageBase] =
    useState(initialValidation);
  const [defaultValidation, setDefaultValidation] = useState(initialValidation);
  const [detailsView, setDetailsView] = useState(true);
  const [addQuestionView, setAddQuestionView] = useState(false);
  const [editView, setEditView] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [imgUrl, setImgUrl] = React.useState("");
  const [progress, setProgress] = useState(false);
  const [success, setSuccess] = useState(false);
  const [aType, setAType] = useState("");
  const handleAdd = () => {
    const abc = [...ins, []];
    setins(abc);
  };
  const [questionObj, setQuestionObj] = useState({ currentQ: 0 });
  const [tFOptions, setTFOptions] = useState([
    { content: "True" },
    { content: "False" },
  ]);
  const handleChange = (onChangeValue, i) => {
    const inputdata = [...ins];
    inputdata[i] = onChangeValue.target.value;
    setins(inputdata);
  };

  const handleDelete = (i) => {
    const deletVal = [...ins];
    deletVal.splice(i, 1);
    setins(deletVal);
  };

  const handleChangeRadio = (event, oidx, qidx, qztype) => {
    setValue(event.target.value);

    setQuestionData((option) =>
      produce(option, (v) => {
        v[qidx].iopts.forEach((opts) => {
          opts.correct = false;
        });
      })
    );

    setQuestionData((option) =>
      produce(option, (v) => {
        v[qidx].iopts[oidx].correct = true;
      })
    );
    setQuestionData((option) =>
      produce(option, (v) => {
        v[qidx].atype = qztype;
      })
    );
  };

  const handleOptionChange = (event, qidx) => {
    setValue(event.target.value);

    setQuestionData((option) =>
      produce(option, (v) => {
        v[qidx].iopts.forEach((opts) => {
          opts.correct = false;
        });
      })
    );
    const correctoptionvalue = event.target.value;
    // console.log(correctoptionvalue);
    setQuestionData((option) =>
      produce(option, (v) => {
        v[qidx].iopts[correctoptionvalue].correct = true;
      })
    );
    setQuestionData((option) =>
      produce(option, (v) => {
        v[qidx].correctoption = correctoptionvalue;
      })
    );
  };

  const handleMultiSelectChange = (event, qidx) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const onFileChange = (event, qidx, oidx) => {
    setSelectedFile({ file: event.target.files[0] });
    var uniqid = new Date().getTime();
    var imtype = event.target.files[0].name.slice(
      (Math.max(0, event.target.files[0].name.lastIndexOf(".")) || Infinity) + 1
    );
    let imageName = `img${uniqid}.${imtype}`;
    setQuestionData((currentFile) =>
      produce(currentFile, (draft) => {
        draft[qidx].img = imageName;
      })
    );
    imageUpload(event.target.files[0], imageName);
  };
  const deleteInstruction = (ins, idx) => {
    if (quizType !== "6") {
      if (idx !== 0) {
        const filteredInstructions = instructions.filter(
          (instruction) => instruction.id !== ins.id
        );
        setInstructions(filteredInstructions);
      }
    } else {
      if (idx > 2) {
        const filteredMatchTheFollowing = matchTheFollowing.filter(
          (matchTheFollowing) => matchTheFollowing.id !== ins.id
        );
        setMatchTheFollowing(filteredMatchTheFollowing);
      }
    }
  };

  const onSImageFileChange = (event, qidx, oidx) => {
    var uniqid = new Date().getTime();
    var imtype = event.target.files[0].name.slice(
      (Math.max(0, event.target.files[0].name.lastIndexOf(".")) || Infinity) + 1
    );
    let imageName = `img${uniqid}.${imtype}`;
    setQuestionData((currentFile) =>
      produce(currentFile, (draft) => {
        draft[qidx].iopts[oidx].url = imageName;
        draft[qidx].iopts[oidx].img = event.target.files[0].name;
      })
    );
    imageUpload(event.target.files[0], imageName);
  };
  const handleInputChange = (e, oidx, qidx) => {
    const { name, value } = e.target;
    setQuestionData((currentoptions) =>
      produce(currentoptions, (v) => {
        v[qidx].iopts[oidx].content = value;
      })
    );
  };
  const imageUpload = async (file, name) => {
    const bodyParam = {
      body: {
        filetype: file.type,
        filename: name,
        oid: config.aws_org_id,
        type: "assessmentsimage",
        crid: courseId,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    // alert("hii"+JSON.stringify(bodyParam.body))
    try {
      console.log(JSON.stringify(bodyParam.body, null, 2));
      const preSignedData = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PRESIGNED_URL,
        bodyParam
      );

      axios
        .put(preSignedData, file, { headers: { "Content-Type": file.type } })
        .then((response) => {
          console.log("success");
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } catch (error) {
      // alert(JSON.stringify(error));
      console.error(error);
    }
  };
  const addInstruction = (ins) => {
    if (quizType !== "6") {
      setInstructions((currentInstruction) => [
        ...currentInstruction,
        {
          id: generate(),
          value: "",
        },
      ]);
    } else {
      setMatchTheFollowing((currentInstruction) => [
        ...currentInstruction,
        {
          id: generate(),
          value1: "",
          value2: "",
        },
      ]);
    }
  };
  useEffect(() => {
    setins(row.instructions);
    setTitel(row.title);
    getQuiz(row.assessid);
    setResTime(moment(row.answerdate).format("hh:mm"));
    setResDate(moment(row.answerdate).format("YYYY-MM-DD"));
  }, [row.instructions, row.title, row.answerdate]);

  const userDeatils = useSelector(authData);
  console.log(userDeatils);
  console.log("courseId", row.assessid);
  console.log("courseId", courseId);
  console.log("Title", titel);
  console.log("resTime", resTime);
  console.log("resDate", resDate);
  console.log("instructions", ins);
  console.log("questions", questions);
  console.log("quizType", quizType);

  const handleAnswerOptionClick = (correct, index) => {
    // questions[currentQuestion].response = index;
    let qdata = [...quizQuestions];

    qdata = produce(qdata, (draft) => {
      draft[currentQuestion].response = index;
    });
    setQuizQuestions(qdata);
    console.log(questions);

    // alert(JSON.stringify(correct));
    setCorrect(correct);
  };

  const handleChangeInputQuestion = (idx, event) => {
    const list = [...questions];
    console.log("List", list);
    console.log("idx", idx);
    list[idx].istem = event.target.value;
    setQuestions(list);
    console.log(list[idx].istem);
  };
  function setCheckbox(index, checked) {
    console.log(checkboxes);
    let newCheckboxes = [...checkboxes];

    if (newCheckboxes[index].checked === undefined) {
      newCheckboxes = produce(newCheckboxes, (draft) => {
        draft[index].checked = false;
      });

      // newCheckboxes[index].checked = false;
    }
    // newCheckboxes[index].checked = checked;
    newCheckboxes = produce(newCheckboxes, (draft) => {
      draft[index].checked = checked;
    });
    setCheckboxes(newCheckboxes);
    let tmp = 0;
    for (let k = 0; k < checkboxes.length; k++) {
      if (
        checkboxes[k].checked !== undefined &&
        checkboxes[k].checked === true
      ) {
        tmp = 1;
        setCorrect(true);
      }
      if (tmp === 0 && k == checkboxes.length - 1) {
        setCorrect(undefined);
      }
    }
  }
  const handleAnswerBtnClick = () => {
    let qdata = [...quizQuestions];
    if (correct === "true" || correct === true) {
      // setScore(score + 1);
    }
    // ansaddedtmp();
    const nextQuestion = currentQuestion + 1;

    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
      if (
        questions[nextQuestion].atype == 3 ||
        questions[nextQuestion].atype == 5
      ) {
        setCheckboxes(questions[nextQuestion].iopts);
      }
    } else {
      handleClose();
      setCurrentQuestion(0);
      setDetailsView(true);
      setEditView(false);
      setQuizQuestions(userDeatils.questions);
    }
    setCorrect(undefined);
  };
  async function getQuiz(id) {
    // setQLoading(true);
    const bodyParam = {
      body: {
        assessid: courseId,
        quizid: id,
        type: "assessmentdetails",
        quiztype: type == "Assessment" ? "assess" : "assign",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_ASSESMENT,
        bodyParam
      );
      console.log(response);
      const { qitems } = response;

      setQuestions(qitems);
      // let ques = { ...userDeatils };
      // if (response.qitems === undefined || response === undefined) {
      //   ques.questions = [];
      //   dispatch(awsSignIn(ques));
      // } else {
      //   ques.questions = qitems;
      //   dispatch(awsSignIn(ques));
      // }
      const imgUrl = `https://${
        Constants.DOMAIN
      }/${config.aws_org_id.toLowerCase()}-resources/images/assessment-images/${courseId}/${id}/`;
      setImgUrl(imgUrl);
    } catch (error) {
      console.error(error);
    }
  }

  const addQuestion = (question, qidx, save, qztype) => {
    setDefaultValidation(initialValidation);
    // alert(qztype);

    let qdata = [...questionData];
    if (qztype === undefined || qztype === "" || qztype == null) {
      setDefaultValidation({ error1: true });
      return;
    } else {
      setDefaultValidation({ error1: false });
    }

    if (
      question.istem === undefined ||
      question.istem === "" ||
      question.istem == null
    ) {
      if (qztype !== "6") {
        setDefaultValidation({ error2: true });
        return;
      } else {
        setDefaultValidation({ error2: false });
      }
    }

    if (
      question.qscore == undefined ||
      question.qscore == "" ||
      question.qscore == null
    ) {
      setDefaultValidation({ error3: true });
      return;
    } else {
      setDefaultValidation({ error3: false });
    }

    if (qztype == 2 || qztype == 6) {
      setQuestionValidation(initialValidation);

      if (
        question.iopts[0].content == "" ||
        question.iopts[0].content == undefined ||
        question.iopts[0].content.replace(/\s/g, "").length === 0 ||
        question.iopts[1].content == "" ||
        question.iopts[1].content == undefined ||
        question.iopts[1].content.replace(/\s/g, "").length === 0
      ) {
        setQuestionValidation({ error2: true });
        return;
      } else {
        setQuestionValidation({ error2: false });
      }
      if (question.correctoption == undefined) {
        setQuestionValidation({ error3: true });
        return;
      } else {
        setQuestionValidation({ error3: false });
      }
      let filterOps = question.iopts.filter((opts) => opts.correct === true);
      if (filterOps[0].content === "" || filterOps[0].content.length === 0) {
        setQuestionValidation({ error4: true });
        return;
      } else {
        setQuestionValidation({ error4: false });
      }

      if (
        question.iopts[2].content != "" &&
        question.iopts[2].content != undefined
      ) {
        if (question.iopts[2].content.replace(/\s/g, "").length === 0) {
          setQuestionValidation({ error5: true });
          return;
        } else {
          setQuestionValidation({ error5: false });
        }
        if (
          question.iopts[2].content == question.iopts[3].content ||
          question.iopts[2].content == question.iopts[4].content ||
          question.iopts[2].content == question.iopts[5].content
        ) {
          setQuestionValidation({ error8: true });
          return;
        } else {
          setQuestionValidation({ error8: false });
        }
      }

      if (
        question.iopts[3].content != "" &&
        question.iopts[3].content != undefined
      ) {
        if (question.iopts[3].content.replace(/\s/g, "").length === 0) {
          setQuestionValidation({ error5: true });
          return;
        } else {
          setQuestionValidation({ error5: false });
        }
        if (
          question.iopts[2].content == undefined ||
          question.iopts[2].content.replace(/\s/g, "").length === 0
        ) {
          setQuestionValidation({ error6: true });
          return;
        } else {
          setQuestionValidation({ error6: false });
        }
        if (
          question.iopts[3].content == question.iopts[4].content ||
          question.iopts[3].content == question.iopts[5].content
        ) {
          setQuestionValidation({ error8: true });
          return;
        } else {
          setQuestionValidation({ error8: false });
        }
      }

      if (
        question.iopts[4].content != "" &&
        question.iopts[4].content != undefined
      ) {
        if (question.iopts[4].content.replace(/\s/g, "").length === 0) {
          setQuestionValidation({ error5: true });
          return;
        } else {
          setQuestionValidation({ error5: false });
        }
        if (
          question.iopts[2].content == undefined ||
          question.iopts[2].content.replace(/\s/g, "").length === 0 ||
          question.iopts[3].content == undefined ||
          question.iopts[3].content.replace(/\s/g, "").length === 0
        ) {
          setQuestionValidation({ error6: true });
          return;
        } else {
          setQuestionValidation({ error6: false });
        }
        if (question.iopts[4].content == question.iopts[5].content) {
          setQuestionValidation({ error8: true });
          return;
        } else {
          setQuestionValidation({ error8: false });
        }
      }

      if (
        question.iopts[5].content != "" &&
        question.iopts[5].content != undefined
      ) {
        if (question.iopts[5].content.replace(/\s/g, "").length === 0) {
          setQuestionValidation({ error5: true });
          return;
        } else {
          setQuestionValidation({ error5: false });
        }
        if (
          question.iopts[2].content == undefined ||
          question.iopts[2].content.replace(/\s/g, "").length === 0 ||
          question.iopts[3].content == undefined ||
          question.iopts[3].content.replace(/\s/g, "").length === 0 ||
          question.iopts[4].content == undefined ||
          question.iopts[4].content.replace(/\s/g, "").length === 0
        ) {
          setQuestionValidation({ error6: true });
          return;
        } else {
          setQuestionValidation({ error6: false });
        }
      }
      if (
        question.iopts[0].content == question.iopts[1].content ||
        question.iopts[0].content == question.iopts[2].content ||
        question.iopts[0].content == question.iopts[3].content ||
        question.iopts[0].content == question.iopts[4].content ||
        question.iopts[0].content == question.iopts[5].content
      ) {
        setQuestionValidation({ error8: true });
        return;
      } else {
        setQuestionValidation({ error8: false });
      }

      if (
        question.iopts[1].content == question.iopts[2].content ||
        question.iopts[1].content == question.iopts[3].content ||
        question.iopts[1].content == question.iopts[4].content ||
        question.iopts[1].content == question.iopts[5].content
      ) {
        setQuestionValidation({ error8: true });
        return;
      } else {
        setQuestionValidation({ error8: false });
      }
    }

    if (qztype == 3) {
      setMultiSelectValidation(initialValidation);

      if (
        question.iopts[0].content == "" ||
        question.iopts[0].content == undefined ||
        question.iopts[0].content.replace(/\s/g, "").length === 0 ||
        question.iopts[1].content == "" ||
        question.iopts[1].content == undefined ||
        question.iopts[1].content.replace(/\s/g, "").length === 0 ||
        question.iopts[2].content == "" ||
        question.iopts[2].content == undefined ||
        question.iopts[2].content.replace(/\s/g, "").length === 0
      ) {
        setMultiSelectValidation({ error2: true });
        return;
      } else {
        setMultiSelectValidation({ error2: false });
      }

      if (
        question.iopts[2].content != "" &&
        question.iopts[2].content != undefined
      ) {
        if (question.iopts[2].content.replace(/\s/g, "").length === 0) {
          setMultiSelectValidation({ error5: true });
          return;
        } else {
          setMultiSelectValidation({ error5: false });
        }
        if (
          question.iopts[2].content == question.iopts[3].content ||
          question.iopts[2].content == question.iopts[4].content ||
          question.iopts[2].content == question.iopts[5].content
        ) {
          setMultiSelectValidation({ error8: true });
          return;
        } else {
          setMultiSelectValidation({ error8: false });
        }
      }

      if (
        question.iopts[3].content != "" &&
        question.iopts[3].content != undefined
      ) {
        if (question.iopts[3].content.replace(/\s/g, "").length === 0) {
          setMultiSelectValidation({ error5: true });
          return;
        } else {
          setMultiSelectValidation({ error5: false });
        }
        if (
          question.iopts[2].content == undefined ||
          question.iopts[2].content.replace(/\s/g, "").length === 0
        ) {
          setMultiSelectValidation({ error6: true });
          return;
        } else {
          setMultiSelectValidation({ error6: false });
        }
        if (
          question.iopts[3].content == question.iopts[4].content ||
          question.iopts[3].content == question.iopts[5].content
        ) {
          setMultiSelectValidation({ error8: true });
          return;
        } else {
          setMultiSelectValidation({ error8: false });
        }
      }

      if (
        question.iopts[4].content != "" &&
        question.iopts[4].content != undefined
      ) {
        if (question.iopts[4].content.replace(/\s/g, "").length === 0) {
          setMultiSelectValidation({ error5: true });
          return;
        } else {
          setMultiSelectValidation({ error5: false });
        }
        if (
          question.iopts[2].content == undefined ||
          question.iopts[2].content.replace(/\s/g, "").length === 0 ||
          question.iopts[3].content == undefined ||
          question.iopts[3].content.replace(/\s/g, "").length === 0
        ) {
          setMultiSelectValidation({ error6: true });
          return;
        } else {
          setMultiSelectValidation({ error6: false });
        }
        if (question.iopts[4].content == question.iopts[5].content) {
          setMultiSelectValidation({ error8: true });
          return;
        } else {
          setMultiSelectValidation({ error8: false });
        }
      }

      if (
        question.iopts[5].content != "" &&
        question.iopts[5].content != undefined
      ) {
        if (question.iopts[5].content.replace(/\s/g, "").length === 0) {
          setMultiSelectValidation({ error5: true });
          return;
        } else {
          setMultiSelectValidation({ error5: false });
        }
        if (
          question.iopts[2].content == undefined ||
          question.iopts[2].content.replace(/\s/g, "").length === 0 ||
          question.iopts[3].content == undefined ||
          question.iopts[3].content.replace(/\s/g, "").length === 0 ||
          question.iopts[4].content == undefined ||
          question.iopts[4].content.replace(/\s/g, "").length === 0
        ) {
          setMultiSelectValidation({ error6: true });
          return;
        } else {
          setMultiSelectValidation({ error6: false });
        }
      }
      if (
        question.iopts[0].content == question.iopts[1].content ||
        question.iopts[0].content == question.iopts[2].content ||
        question.iopts[0].content == question.iopts[3].content ||
        question.iopts[0].content == question.iopts[4].content ||
        question.iopts[0].content == question.iopts[5].content
      ) {
        setMultiSelectValidation({ error8: true });
        return;
      } else {
        setMultiSelectValidation({ error8: false });
      }

      if (
        question.iopts[1].content == question.iopts[2].content ||
        question.iopts[1].content == question.iopts[3].content ||
        question.iopts[1].content == question.iopts[4].content ||
        question.iopts[1].content == question.iopts[5].content
      ) {
        setMultiSelectValidation({ error8: true });
        return;
      } else {
        setMultiSelectValidation({ error8: false });
      }

      let tempoptionsiopts = [];
      let tempopsval = {};
      // console.log(question);
      if (question.iopts[0].content != undefined) {
        if (state.opt1) {
          tempopsval = {
            content: question.iopts[0].content,
            correct: "true",
          };
        } else {
          tempopsval = {
            content: question.iopts[0].content,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[1].content != undefined) {
        if (state.opt2) {
          tempopsval = {
            content: question.iopts[1].content,
            correct: "true",
          };
        } else {
          tempopsval = {
            content: question.iopts[1].content,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[2].content != undefined) {
        if (state.opt3) {
          tempopsval = {
            content: question.iopts[2].content,
            correct: "true",
          };
        } else {
          tempopsval = {
            content: question.iopts[2].content,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[3].content != undefined) {
        if (state.opt4) {
          tempopsval = {
            content: question.iopts[3].content,
            correct: "true",
          };
        } else {
          tempopsval = {
            content: question.iopts[3].content,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[4].content != undefined) {
        if (state.opt5) {
          tempopsval = {
            content: question.iopts[4].content,
            correct: "true",
          };
        } else {
          tempopsval = {
            content: question.iopts[4].content,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[5].content != undefined) {
        if (state.opt6) {
          tempopsval = {
            content: question.iopts[5].content,
            correct: "true",
          };
        } else {
          tempopsval = {
            content: question.iopts[5].content,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      // console.log(tempoptionsiopts);
      qdata = produce(qdata, (draft) => {
        draft[qidx].iopts = tempoptionsiopts;
        draft[qidx].atype = qztype;
      });
      qdata = JSON.parse(JSON.stringify(qdata));
      setQuestionData(qdata);
    }

    if (qztype == 4) {
      if (
        question.iopts[0].url == "" ||
        question.iopts[0].url == undefined ||
        question.iopts[1].url == "" ||
        question.iopts[1].url == undefined
      ) {
        setSingleSelectImageBase({ error2: true });
        return;
      } else {
        setSingleSelectImageBase({ error2: false });
      }
      if (question.correctoption == undefined) {
        setSingleSelectImageBase({ error3: true });
        return;
      } else {
        setSingleSelectImageBase({ error3: false });
      }

      if (question.correctoption != undefined) {
        var yy = question.correctoption;
        if (question.iopts[yy] == undefined) {
          setSingleSelectImageBase({ error4: true });
          return;
        } else {
          setSingleSelectImageBase({ error4: false });
        }
      }

      if (question.iopts[3].url != "" && question.iopts[3].url != undefined) {
        if (question.iopts[2].url == undefined) {
          setSingleSelectImageBase({ error6: true });
          return;
        } else {
          setSingleSelectImageBase({ error: false });
        }
      }

      if (question.iopts[4].url != "" && question.iopts[4].url != undefined) {
        if (
          question.iopts[2].url == undefined ||
          question.iopts[3].url == undefined
        ) {
          setSingleSelectImageBase({ error6: true });
          return;
        } else {
          setSingleSelectImageBase({ error6: false });
        }
      }

      if (question.iopts[5].url != "" && question.iopts[5].url != undefined) {
        if (
          question.iopts[2].url == undefined ||
          question.iopts[3].url == undefined ||
          question.iopts[4].url == undefined
        ) {
          setSingleSelectImageBase({ error6: true });
          return;
        } else {
          setSingleSelectImageBase({ error6: false });
        }
      }

      let tempoptionsiopts = [];
      let tempopsval = {};
      if (question.iopts[0].url != undefined) {
        if (question.correctoption == "0") {
          tempopsval = {
            url: question.iopts[0].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            url: question.iopts[0].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[1].url != undefined) {
        if (question.correctoption == "1") {
          tempopsval = {
            url: question.iopts[1].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            url: question.iopts[1].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[2].url != undefined) {
        if (question.correctoption == "2") {
          tempopsval = {
            url: question.iopts[2].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            url: question.iopts[2].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[3].url != undefined) {
        if (question.correctoption == "3") {
          tempopsval = {
            url: question.iopts[3].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            url: question.iopts[3].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[4].url != undefined) {
        if (question.correctoption == "4") {
          tempopsval = {
            url: question.iopts[4].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            url: question.iopts[4].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[5].url != undefined) {
        if (question.correctoption == "5") {
          tempopsval = {
            url: question.iopts[5].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            url: question.iopts[5].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }
      qdata = produce(qdata, (draft) => {
        draft[qidx].iopts = tempoptionsiopts;
        draft[qidx].atype = qztype;
      });
      qdata = JSON.parse(JSON.stringify(qdata));
      setQuestionData(qdata);
    }

    if (qztype == 5) {
      setMultiSelectImageBase(initialValidation);

      if (
        question.iopts[0].url == "" ||
        question.iopts[0].url == undefined ||
        question.iopts[1].url == "" ||
        question.iopts[1].url == undefined ||
        question.iopts[2].url == "" ||
        question.iopts[2].url == undefined
      ) {
        setMultiSelectImageBase({ error2: true });
        return;
      }

      if (question.iopts[3].url != "" && question.iopts[3].url != undefined) {
        if (question.iopts[2].url == undefined) {
          setMultiSelectImageBase({ error6: true });
          return;
        }
      }

      if (question.iopts[4].url != "" && question.iopts[4].url != undefined) {
        if (
          question.iopts[2].url == undefined ||
          question.iopts[3].url == undefined
        ) {
          setMultiSelectImageBase({ error6: true });
          return;
        }
      }

      if (question.iopts[5].url != "" && question.iopts[5].url != undefined) {
        if (
          question.iopts[2].url == undefined ||
          question.iopts[3].url == undefined ||
          question.iopts[4].url == undefined
        ) {
          setMultiSelectImageBase({ error6: true });
          return;
        }
      }

      let tempoptionsiopts = [];
      let tempopsval = {};
      if (question.iopts[0].url != undefined) {
        if (state.opt1) {
          tempopsval = {
            url: question.iopts[0].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            url: question.iopts[0].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[1].url != undefined) {
        if (state.opt2) {
          tempopsval = {
            url: question.iopts[1].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            url: question.iopts[1].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[2].url != undefined) {
        if (state.opt3) {
          tempopsval = {
            url: question.iopts[2].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            url: question.iopts[2].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[3].url != undefined) {
        if (state.opt4) {
          tempopsval = {
            url: question.iopts[3].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            url: question.iopts[3].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[4].url != undefined) {
        if (state.opt5) {
          tempopsval = {
            url: question.iopts[4].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            url: question.iopts[4].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[5].url != undefined) {
        if (state.opt6) {
          tempopsval = {
            url: question.iopts[5].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            url: question.iopts[5].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      qdata = produce(qdata, (draft) => {
        draft[qidx].iopts = tempoptionsiopts;
        draft[qidx].atype = qztype;
      });
      qdata = JSON.parse(JSON.stringify(qdata));
      setQuestionData(qdata);
    }

    if (qztype == 1) {
      if (value == undefined || value == "" || value == null) {
        setDefaultValidation({ error4: true });
        return;
      } else {
        setDefaultValidation({ error4: false });
      }

      let temp = [];
      let tempopsval = {};
      if (question.iopts[0].correct == "true") {
        tempopsval = {
          content: "True",
          correct: "true",
        };
      } else {
        tempopsval = {
          content: "True",
          correct: "false",
        };
      }
      temp.push(tempopsval);
      if (question.iopts[1].correct == "false") {
        tempopsval = {
          content: "False",
          correct: "true",
        };
      } else {
        tempopsval = {
          content: "False",
          correct: "false",
        };
      }
      temp.push(tempopsval);

      qdata = produce(qdata, (draft) => {
        draft[qidx].iopts = temp;
        draft[qidx].atype = "1";
      });
      qdata = JSON.parse(JSON.stringify(qdata));
      setQuestionData(qdata);
    }

    if (qztype == 6) {
      matchTheFollowing.forEach((match) => {
        if (match.value1 == "" || match.value2 == "") {
          setDefaultValidation({ error5: true });
          return;
        } else {
          setDefaultValidation({ error5: false });
        }
      });

      let str1 = "";
      let ind = 0;

      for (var i = 0; i < matchTheFollowing.length; i++) {
        if (matchTheFollowing[i].value != "") {
          ind += 1;
          str1 +=
            "<tr><td>" +
            ind +
            "." +
            matchTheFollowing[i].value1 +
            "</td><td>" +
            ind +
            "." +
            matchTheFollowing[i].value2 +
            "</td></tr>";
        }
      }
      //

      qdata = produce(questionData, (v) => {
        v[
          qidx
        ].istem = `<table class='qtable'><tr><th>A</th><th>B</th></tr>${str1}</table>`;
        v[qidx].atype = "6";
      });

      qdata = JSON.parse(JSON.stringify(qdata));
      if (save === 0) {
        qdata.push({
          iid: generate(),
          istem: "",
          iopts: initialValues,
        });
      }

      setQuestionData(qdata);

      // console.log(str1);
    }
    setAType(qztype);
    qdata = produce(qdata, (draft) => {
      draft[qidx].atype = qztype;
    });
    qdata = JSON.parse(JSON.stringify(qdata));
    setQuestionData(qdata);
    let cq = currentQuestion + 1;
    setCurrentQuestion(cq);

    questionObj.currentQ = questionData.length;

    setQuestionObj((currentQuestion) => ({ ...questionObj, questionObj }));

    // console.log(JSON.stringify(qdata));
    if (qztype != 6 && save === 0) {
      setQuestionData((currentQuestion) => [
        ...currentQuestion,
        {
          iid: generate(),
          istem: "",
          iopts: initialValues,
        },
      ]);
    } else {
      if (save !== 0) {
        let arr = [];
        qdata.forEach((ques, idx, array) => {
          const fill = ques.iopts.filter((opts) => opts.content !== "");

          arr.push(
            produce(ques, (draft) => {
              draft.iopts = fill;
              delete draft.correctoption;
              if (draft.atype == 7 || draft.atype == 8) {
                delete draft.iopts;
              }
            })
          );
        });

        console.log("ARRAY", arr);
        for (let i = 0; i < arr.length; i++) {
          questions.push(arr[i]);
        }

        console.log("ARRAYNEW", questions);

        savedata();
        // setFirstView(false);
        //saveAssessmentAndAssignmentData(body);
        handleClose();
      }
    }
    setSelectedFile("");
    setQuizType("");
    setState(initialState);
  };

  const savedata = () => {
    let dans = resDate;
    let tans = resTime;

    let qdata = [...questionData];
    let datetimeanswer = new Date((dans + " " + tans).split(" ").join("T"));
    datetimeanswer = datetimeanswer.getTime();

    console.log("Qdata", qdata);

    let body = {
      title: titel,
      startdate: undefined,
      enddate: undefined,
      answerdate: datetimeanswer,
      totalnoofquestions: questions.length,
      instructions: ins,
      qitems: questions,
      assessid: courseId,
      quizid: row.assessid,
      quiztype: type === "Assessment" ? "assess" : "assign",
    };
    console.log(body);
    setSuccess(true);
    saveAssessmentAndAssignmentData(body);

    // setSelectedFile("");
    // setQuizType("");
    // setState(initialState);
  };

  const saveAssessmentAndAssignmentData = async (body) => {
    const bodyParam = {
      body,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(JSON.stringify(bodyParam.body, null, 2));
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/editAssessment",
        bodyParam
      );
      console.log(response);

      setProgress(false);

      swal({
        title: "Success",
        text: "successfull",
        icon: "success",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
        }
      });
      window.location.reload();
    } catch (error) {
      // alert(JSON.stringify(error));
      console.error(error);
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      className={classes.paper}
      fullScreen={fullScreen}
      onBackdropClick="false"
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h2
          className={classes.title}
          style={{ margin: "auto", paddingLeft: "4rem" }}
        >
          Edit {type ? "Assessment" : "Assignment"}
        </h2>
        {/* <button
          onClick={() => {
            handleClose();
            setAddQuestionView(false);
            setEditView(false);
            setDetailsView(true);
            setCurrentQuestion(0);
          }}
        >
          X
        </button> */}

        <IconButton
          onClick={() => {
            handleClose();
            setAddQuestionView(false);
            setEditView(false);
            setDetailsView(true);
            setCurrentQuestion(0);
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>

      {detailsView ? (
        <div style={{ fontSize: "16px", textAlign: "center", margin: "20px" }}>
          <div>
            <p className="classLabel_assign_assess">{type} Title:</p>
            <input
              className="classInput_assign_assess"
              defaultValue={row?.title}
              onChange={(e) => {
                console.log("titel", titel);

                setTitel(e.target.value);
              }}
              type="text"
              style={{ width: "100%" }}
            />
            <p className="classLabel_assign_assess">Answers reveal date:</p>
            <input
              className="classInput_assign_assess"
              type="date"
              defaultValue={moment(row.answerdate).format("YYYY-MM-DD")}
              onChange={(e) => {
                setResDate(e.target.value);
              }}
              style={{ width: "100%" }}
            />

            <p className="classLabel_assign_assess">Answers reveal time:</p>
            <input
              className="classInput_assign_assess"
              type="time"
              defaultValue={moment(row.answerdate).format("hh:mm")}
              onChange={(e) => {
                setResTime(e.target.value);
              }}
              style={{ width: "100%" }}
            />
            <p className="classLabel_assign_assess">
              Instructions to students:
            </p>

            {ins === undefined || ins.length === 0 ? (
              <div>
                No Instructions{" "}
                <IconButton aria-label="add" onClick={() => handleAdd()}>
                  <AddCircleIcon fontSize="small" />
                </IconButton>
              </div>
            ) : (
              <>
                {ins.map((data, i) => {
                  return (
                    <div style={{ width: "max-content" }}>
                      <input
                        className="classInput_assign_assess"
                        value={data}
                        onChange={(e) => handleChange(e, i)}
                      />
                      <IconButton aria-label="add" onClick={() => handleAdd()}>
                        <AddCircleIcon fontSize="small" />
                      </IconButton>

                      <IconButton
                        aria-label="delete"
                        onClick={() => handleDelete(i)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </div>
                  );
                })}
              </>
            )}
            <CustomButton
              onClick={() => {
                setDetailsView(false);
                setEditView(true);
              }}
            >
              Next
            </CustomButton>
          </div>
        </div>
      ) : null}

      {editView ? (
        <div>
          {questions.length === 0 || questions === undefined ? (
            <>
              Add Question
              <CustomButton>Add Question</CustomButton>
              <CustomButton>Save</CustomButton>
            </>
          ) : (
            <>
              {(questions.length !== 0 ||
                userDeatils.questions !== 0 ||
                userDeatils.questions !== undefined ||
                questions[currentQuestion].istem !== undefined ||
                questions[currentQuestion].atype !== undefined) && (
                <div>
                  <div>
                    <>
                      <div>
                        <>
                          <div className={assessStyle.questionsection}>
                            <div className={assessStyle.questioncount}>
                              <span>Question {currentQuestion + 1}</span>/
                              {questions.length !== 0 ? questions.length : 1}
                            </div>
                            <div className={assessStyle.questiontext}>
                              <div style={{ fontSize: "15px" }}>
                                <input
                                  className="classInput_assign_assess"
                                  type="text"
                                  value={questions[currentQuestion].istem}
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                  }}
                                  onChange={(event) => {
                                    handleChangeInputQuestion(
                                      currentQuestion,
                                      event
                                    );
                                  }}
                                />
                              </div>

                              {questions[currentQuestion].img ? (
                                <div className="img_container">
                                  <img
                                    src={`${`https://${
                                      Constants.DOMAIN
                                    }/${config.aws_org_id.toLowerCase()}-resources/images/assessment-images/${courseId}/${assessId}/`}${
                                      questions[currentQuestion].img
                                    }`}
                                    alt="item"
                                  />
                                </div>
                              ) : null}
                            </div>
                          </div>
                          {questions[currentQuestion].atype == 7 ? (
                            <>
                              <TextareaAutosize
                                className={assessStyle.textarea}
                                rowsMax={6}
                                aria-label="maximum height"
                                placeholder="minimum 6 rows"
                                onChange={() => {
                                  setCorrect("true");
                                }}
                              />
                            </>
                          ) : (
                            <>
                              {questions[currentQuestion].atype == 1 ||
                              questions[currentQuestion].atype == 2 ||
                              questions[currentQuestion].atype == 4 ? (
                                <>
                                  <div className={assessStyle.answersection}>
                                    <div
                                      className={
                                        "btn-group " +
                                        assessStyle.btngroupvertical
                                      }
                                    >
                                      {questions[currentQuestion].iopts.map(
                                        (answerOption, index, arrayobj) => (
                                          <>
                                            {questions[currentQuestion].atype ==
                                            4 ? (
                                              <>
                                                <div>
                                                  <input
                                                    type="radio"
                                                    className="radio_opacity"
                                                    name="options"
                                                    id={answerOption.url}
                                                  />
                                                  <label
                                                    className={
                                                      assessStyle.labelstyle +
                                                      " " +
                                                      assessStyle.buttonQuiz
                                                    }
                                                    for={answerOption.url}
                                                    onClick={() =>
                                                      handleAnswerOptionClick(
                                                        answerOption.correct,
                                                        index
                                                      )
                                                    }
                                                  >
                                                    <Image
                                                      src={
                                                        imgUrl +
                                                        answerOption.url
                                                      }
                                                      rounded
                                                    />
                                                  </label>
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                <input
                                                  type="radio"
                                                  className="radio_opacity"
                                                  name="options"
                                                  id={answerOption.content}
                                                  value={answerOption.content}
                                                  checked={
                                                    answerOption.Selected
                                                  }
                                                  onChange={() => {
                                                    let qdata = [...questions];
                                                    for (
                                                      let i = 0;
                                                      i <
                                                      questions[currentQuestion]
                                                        .iopts.length;
                                                      i++
                                                    ) {
                                                      setQuizQuestions(
                                                        (currentQues) =>
                                                          produce(
                                                            currentQues,
                                                            (draft) => {
                                                              draft[
                                                                currentQuestion
                                                              ].iopts[
                                                                i
                                                              ].Selected = false;
                                                            }
                                                          )
                                                      );

                                                      // questions[
                                                      //   currentQuestion
                                                      // ].iopts[i].Selected = false;
                                                    }

                                                    setQuizQuestions(
                                                      (currentQues) =>
                                                        produce(
                                                          currentQues,
                                                          (draft) => {
                                                            draft[
                                                              currentQuestion
                                                            ].iopts[
                                                              index
                                                            ].Selected = true;
                                                          }
                                                        )
                                                    );
                                                  }}
                                                />
                                                <label
                                                  className={
                                                    assessStyle.labelstyle +
                                                    " " +
                                                    assessStyle.buttonQuiz
                                                  }
                                                  for={answerOption.content}
                                                  onClick={() =>
                                                    handleAnswerOptionClick(
                                                      answerOption.correct,
                                                      index
                                                    )
                                                  }
                                                >
                                                  {answerOption.content}
                                                </label>
                                              </>
                                            )}
                                          </>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {questions[currentQuestion].atype == 3 ||
                                  questions[currentQuestion].atype == 5 ? (
                                    <div className={assessStyle.answersection}>
                                      <FormControl component="fieldset">
                                        <FormGroup aria-label="position" row>
                                          {questions[currentQuestion].iopts.map(
                                            (answerOption, index, arrayobj) => (
                                              <>
                                                {questions[currentQuestion]
                                                  .atype == 5 ? (
                                                  <>
                                                    <div>
                                                      <FormControlLabel
                                                        className={
                                                          assessStyle.buttonQuiz
                                                        }
                                                        value={
                                                          <Image
                                                            src={
                                                              imgUrl +
                                                              answerOption.url
                                                            }
                                                            rounded
                                                          />
                                                        }
                                                        control={
                                                          <Checkbox color="primary" />
                                                        }
                                                        label={
                                                          <Image
                                                            src={
                                                              imgUrl +
                                                              answerOption.url
                                                            }
                                                            rounded
                                                          />
                                                        }
                                                        onChange={(e) => {
                                                          setCheckbox(
                                                            index,
                                                            e.target.checked
                                                          );
                                                        }}
                                                        labelPlacement="end"
                                                      />
                                                    </div>
                                                  </>
                                                ) : (
                                                  <>
                                                    <FormControlLabel
                                                      className={
                                                        assessStyle.buttonQuiz
                                                      }
                                                      value={
                                                        answerOption.content
                                                      }
                                                      control={
                                                        <Checkbox color="primary" />
                                                      }
                                                      label={
                                                        answerOption.content
                                                      }
                                                      onChange={(e) => {
                                                        setCheckbox(
                                                          index,
                                                          e.target.checked
                                                        );
                                                      }}
                                                      labelPlacement="end"
                                                    />
                                                  </>
                                                )}
                                              </>
                                            )
                                          )}
                                        </FormGroup>
                                      </FormControl>
                                    </div>
                                  ) : (
                                    <>
                                      {questions[currentQuestion].atype == 8 ? (
                                        <>
                                          <div className="file-input">
                                            <input
                                              type="file"
                                              id="file"
                                              name="file"
                                              className="file"
                                              onChange={(event) => {
                                                setFileName(
                                                  event.target.files[0].name
                                                );
                                              }}
                                              multiple
                                              required
                                            />
                                            <label for="file">
                                              Select file
                                              <p className="file-name2">
                                                {fileName !== undefined ||
                                                fileName !== null ||
                                                fileName !== ""
                                                  ? fileName
                                                  : ""}
                                              </p>
                                            </label>
                                          </div>

                                          {currentQuestion + 1 ===
                                          questions.length ? (
                                            <CustomButton
                                              fullWidth
                                              type="submit"
                                              onClick={() => {
                                                handleClose();
                                                setCurrentQuestion(0);
                                                setQuizQuestions(
                                                  userDeatils.questions
                                                );
                                              }}
                                            >
                                              Submit
                                            </CustomButton>
                                          ) : (
                                            <CustomButton
                                              fullWidth
                                              type="submit"
                                            >
                                              Next
                                            </CustomButton>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {questions[currentQuestion].atype ==
                                          6 ? (
                                            <>
                                              <p
                                                className={assessStyle.matchThe}
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    quizQuestions[
                                                      currentQuestion
                                                    ].istem,
                                                }}
                                              ></p>{" "}
                                              {quizQuestions[
                                                currentQuestion
                                              ].iopts.map(
                                                (
                                                  answerOption,
                                                  index,
                                                  arrayobj
                                                ) => (
                                                  <>
                                                    {quizQuestions[
                                                      currentQuestion
                                                    ].atype == 4 ? (
                                                      <>
                                                        <div>
                                                          <input
                                                            type="radio"
                                                            className="radio_opacity"
                                                            name="options"
                                                            id={
                                                              answerOption.content
                                                            }
                                                          />
                                                          <label
                                                            className={
                                                              assessStyle.labelstyle +
                                                              " " +
                                                              assessStyle.buttonQuiz
                                                            }
                                                            for={
                                                              answerOption.content
                                                            }
                                                            onClick={() =>
                                                              handleAnswerOptionClick(
                                                                answerOption.correct,
                                                                index
                                                              )
                                                            }
                                                          >
                                                            <Image
                                                              src={
                                                                imgUrl +
                                                                answerOption.url
                                                              }
                                                              rounded
                                                            />
                                                          </label>
                                                        </div>
                                                      </>
                                                    ) : (
                                                      <>
                                                        <input
                                                          type="radio"
                                                          className="radio_opacity"
                                                          name="options"
                                                          id={
                                                            answerOption.content
                                                          }
                                                        />
                                                        <label
                                                          className={
                                                            assessStyle.labelstyle +
                                                            " " +
                                                            assessStyle.buttonQuiz
                                                          }
                                                          for={
                                                            answerOption.content
                                                          }
                                                          onClick={() =>
                                                            handleAnswerOptionClick(
                                                              answerOption.correct,
                                                              index
                                                            )
                                                          }
                                                        >
                                                          {answerOption.content}
                                                        </label>
                                                      </>
                                                    )}
                                                  </>
                                                )
                                              )}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                          {questions[currentQuestion].atype == 8 ? (
                            <></>
                          ) : (
                            <div style={{ textAlign: "center" }}>
                              <CustomButton
                                className={assessStyle.nextbtn}
                                onClick={() => {
                                  handleAnswerBtnClick();
                                }}
                                style={{ width: "auto" }}
                              >
                                {currentQuestion == quizQuestions.length - 1
                                  ? "Submit"
                                  : "Next"}
                              </CustomButton>

                              <CustomButton
                                className={assessStyle.nextbtn}
                                onClick={() => {
                                  savedata();
                                }}
                                style={{ width: "auto" }}
                              >
                                Save
                              </CustomButton>

                              <CustomButton
                                className={assessStyle.nextbtn}
                                style={{ width: "auto" }}
                                onClick={() => {
                                  setEditView(false);
                                  setAddQuestionView(true);
                                  setQuestionData((currentQuestion) => [
                                    ...currentQuestion,
                                    {
                                      iid: generate(),
                                      istem: "",
                                      iopts: initialValues,
                                    },
                                  ]);
                                }}
                              >
                                Add Question
                              </CustomButton>
                            </div>
                          )}
                        </>
                      </div>
                    </>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      ) : null}

      {addQuestionView ? (
        <React.Fragment>
          {questionData.map((question, qidx) => (
            <React.Fragment>
              {qidx == questionObj.currentQ && (
                <React.Fragment>
                  <DialogContent
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      overflowX: "hidden",
                    }}
                  >
                    <p
                      className="classLabel_assign_assess"
                      style={{
                        textAlign: "center",
                        marginBottom: "1.5rem",
                      }}
                    >
                      Question {questionData.length}/{questionData.length}
                    </p>
                    {defaultValidation.error1 ? (
                      <p className="firstViewValidation">
                        Please select the Question type
                      </p>
                    ) : null}
                    <select
                      className="classInput_assign_assess"
                      onChange={(e) => {
                        console.log(" qidx", qidx);
                        setQuizType(e.target.value);
                        let qesData = [...questionData];
                        if (quizType !== "1") {
                          setQuestionData(
                            produce(qesData, (draft) => {
                              draft[qidx].iopts = initialValues;
                            })
                          );
                        }
                      }}
                    >
                      <option style={{ display: "none" }}>
                        Select - Question Type
                      </option>
                      <option value="1">True or False</option>
                      <option value="2">Single Select Multiple Choice</option>
                      <option value="3">Multiple Select Multiple Choice</option>

                      <option value="4">
                        Single Select Image Based Multiple Choice
                      </option>

                      <option value="5">
                        Multiple Select Image Based Multiple Choice
                      </option>

                      <option value="6">Match the Following</option>
                      <option value="7">Descriptive Questions</option>
                      <option value="8">File Upload</option>
                    </select>

                    {quizType !== "6" ? (
                      <div className={classes.displayFlex}>
                        <div className={classes.flexBasis}>
                          <h4>Question</h4>
                        </div>

                        <div style={{ width: "100%" }}>
                          {defaultValidation.error2 ? (
                            <p className="firstViewValidation">
                              Question cannot be empty
                            </p>
                          ) : null}
                          <textarea
                            className="classTextarea"
                            style={{ marginBottom: "20px" }}
                            //   value={question.istem}
                            autoComplete="off"
                            onChange={(e) => {
                              const istem = e.target.value;
                              // setIstem(istem);
                              setQuestionData((currentistem) =>
                                produce(currentistem, (v) => {
                                  v[qidx].istem = istem;
                                })
                              );
                            }}
                          ></textarea>
                        </div>
                      </div>
                    ) : (
                      <div className={classes.displayFlex}>
                        <div className={classes.flexBasis}>
                          <h4>Question:</h4>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                          className={classes.root1}
                        >
                          {matchTheFollowing.map((match, ids) => (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "1rem",
                              }}
                            >
                              <input
                                className="classInput_MatchTheFollowing"
                                onChange={(e) => {
                                  let value1 = e.target.value;
                                  setMatchTheFollowing((currMatchVal) =>
                                    produce(currMatchVal, (v) => {
                                      v[ids].value1 = value1;
                                    })
                                  );
                                }}
                              />
                              <input
                                className="classInput_MatchTheFollowing"
                                onChange={(e) => {
                                  let value2 = e.target.value;
                                  setMatchTheFollowing((currMatchVal) =>
                                    produce(currMatchVal, (v) => {
                                      v[ids].value2 = value2;
                                    })
                                  );
                                }}
                              />
                              <IconButton
                                aria-label="add"
                                onClick={() => addInstruction(ids)}
                              >
                                <AddCircleIcon />
                              </IconButton>

                              <IconButton
                                aria-label="delete"
                                onClick={() => deleteInstruction(match, ids)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    <div className={classes.displayFlex}>
                      <div className={classes.flexBasis}>
                        <h4>Image</h4>{" "}
                        <sub className={classes.small}>(Optional)</sub>
                      </div>
                      <div className={classes.root1}>
                        <input
                          accept="image/*"
                          className={classes.input}
                          id="contained-button-file"
                          multiple
                          type="file"
                          onChange={(e) => {
                            onFileChange(e, qidx);
                          }}
                        />

                        <label htmlFor="contained-button-file">
                          <Button
                            className={classes.uploadBtn}
                            component="span"
                            style={{
                              textTransform: "capitalize",
                            }}
                          >
                            Upload
                          </Button>
                        </label>
                        {
                          <h4
                            style={{
                              display: "inline-block",
                              marginLeft: "2rem",
                            }}
                          >
                            {selectedFile !== null &&
                            selectedFile.file !== undefined
                              ? selectedFile.file.name
                              : ""}
                          </h4>
                        }
                      </div>
                    </div>
                    {quizType === "1" ? (
                      <div style={{ marginLeft: "8rem" }}>
                        {tFOptions.map((opts, oidx) => (
                          <React.Fragment key={oidx + "sfsd"}>
                            <BlueRadio
                              checked={value == `${opts.content}`}
                              onChange={(e) =>
                                handleChangeRadio(e, oidx, qidx, quizType)
                              }
                              value={`${opts.content}`}
                              name="radio-button-demo"
                              inputProps={{ "aria-label": "False" }}
                            />
                            <span style={{ fontSize: "1.3rem" }}>
                              {opts.content}
                            </span>
                          </React.Fragment>
                        ))}
                      </div>
                    ) : null}
                    <div style={{ paddingLeft: "15%" }}>
                      {questionValidation.error2 && (
                        <p className="firstViewValidation">
                          You need to enter at least 2 options
                        </p>
                      )}
                      {questionValidation.error3 && (
                        <p className="firstViewValidation">
                          Please select the correct option
                        </p>
                      )}
                      {questionValidation.error4 && (
                        <p className="firstViewValidation">
                          You have selected an option for which no value has
                          been entered
                        </p>
                      )}
                      {questionValidation.error5 && (
                        <p className="firstViewValidation">
                          Option cannot just contain space
                        </p>
                      )}
                      {questionValidation.error6 && (
                        <p className="firstViewValidation">
                          You have entered an option by skipping one of the
                          previous one
                        </p>
                      )}
                      {questionValidation.error7 && (
                        <p className="firstViewValidation">
                          Please enter a feedback
                        </p>
                      )}
                      {questionValidation.error8 && (
                        <p className="firstViewValidation">
                          Entered options must have different values
                        </p>
                      )}
                      {multiSelectValidation.error1 && (
                        <p className="firstViewValidation">
                          Select at least 2 options
                        </p>
                      )}
                      {multiSelectValidation.error2 && (
                        <p className="firstViewValidation">
                          You need to enter at least 3 options
                        </p>
                      )}
                      {multiSelectValidation.error3 && (
                        <p className="firstViewValidation">
                          Please select the correct option
                        </p>
                      )}
                      {multiSelectValidation.error4 && (
                        <p className="firstViewValidation">
                          You have selected an option for which no value has
                          been entered
                        </p>
                      )}
                      {multiSelectValidation.error5 && (
                        <p className="firstViewValidation">
                          Option cannot just contain space
                        </p>
                      )}
                      {multiSelectValidation.error6 && (
                        <p className="firstViewValidation">
                          You have entered an option by skipping one of the
                          previous one
                        </p>
                      )}
                      {multiSelectValidation.error7 && (
                        <p className="firstViewValidation">
                          Please enter a feedback
                        </p>
                      )}
                      {multiSelectValidation.error8 && (
                        <p className="firstViewValidation">
                          Entered options must have different values
                        </p>
                      )}
                      {singleSelectImageBase.error2 && (
                        <p className="firstViewValidation">
                          You need to select at least 2 Images
                        </p>
                      )}
                      {singleSelectImageBase.error3 && (
                        <p className="firstViewValidation">
                          Please select the correct option
                        </p>
                      )}
                      {singleSelectImageBase.error4 && (
                        <p className="firstViewValidation">
                          You have selected an option for which no image has
                          been selected
                        </p>
                      )}
                      {singleSelectImageBase.error5 && (
                        <p className="firstViewValidation">
                          Option cannot just contain space
                        </p>
                      )}
                      {singleSelectImageBase.error6 && (
                        <p className="firstViewValidation">
                          You have selected an image by skipping one of the
                          previous one
                        </p>
                      )}
                      {singleSelectImageBase.error7 && (
                        <p className="firstViewValidation">
                          Please enter a feedback
                        </p>
                      )}
                      {singleSelectImageBase.error8 && (
                        <p>Entered options must have different values</p>
                      )}
                      {multiSelectImageBase.error1 && (
                        <p className="firstViewValidation">
                          Select at least 2 options
                        </p>
                      )}
                      {multiSelectImageBase.error2 && (
                        <p className="firstViewValidation">
                          You need to select at least 3 Images
                        </p>
                      )}
                      {multiSelectImageBase.error3 && (
                        <p className="firstViewValidation">
                          Please select the correct option
                        </p>
                      )}
                      {multiSelectImageBase.error4 && (
                        <p className="firstViewValidation">
                          You have selected an option for which no image has
                          been selected
                        </p>
                      )}
                      {multiSelectImageBase.error5 && (
                        <p className="firstViewValidation">
                          Option cannot just contain space
                        </p>
                      )}
                      {multiSelectImageBase.error6 && (
                        <p className="firstViewValidation">
                          You have selected an image by skipping one of the
                          previous one
                        </p>
                      )}
                      {multiSelectImageBase.error7 && (
                        <p className="firstViewValidation">
                          Please enter a feedback
                        </p>
                      )}
                      {multiSelectImageBase.error8 && (
                        <p className="firstViewValidation">
                          Entered options must have different values
                        </p>
                      )}
                    </div>
                    {quizType === "6" || quizType === "2" ? (
                      <>
                        <div className={classes.displayFlex}>
                          <div className={classes.flexBasis}>
                            <h4>Options:</h4>
                          </div>

                          <FormControl
                            className={classes.root1}
                            component="fieldset"
                            fullWidth
                          >
                            <RadioGroup
                              onChange={(e) => handleOptionChange(e, qidx)}
                              value={question.correctoption}
                            >
                              {question.iopts.map((opts, oidx) => (
                                <>
                                  <FormControlLabel
                                    value={oidx.toString()}
                                    control={<BlueRadio />}
                                    label={
                                      <input
                                        value={opts.content}
                                        className="classInput"
                                        onChange={(e) =>
                                          handleInputChange(e, oidx, qidx)
                                        }
                                        type="text"
                                        autoComplete="off"
                                      />
                                    }
                                  />
                                </>
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </>
                    ) : null}
                    {quizType === "4" ? (
                      <>
                        <div className={classes.displayFlex}>
                          <div className={classes.flexBasis}>
                            <h4>Options:</h4>
                          </div>

                          <FormControl
                            className={classes.root1}
                            component="fieldset"
                            fullWidth
                          >
                            <RadioGroup
                              onChange={(e) => handleOptionChange(e, qidx)}
                              value={question.correctoption}
                            >
                              {question.iopts.map((opts, oidx) => (
                                <>
                                  <FormControlLabel
                                    value={oidx.toString()}
                                    control={<BlueRadio />}
                                    label={
                                      <React.Fragment>
                                        <input
                                          accept="image/*"
                                          className={classes.input}
                                          id={oidx.toString()}
                                          multiple
                                          type="file"
                                          onChange={(e) => {
                                            onSImageFileChange(e, qidx, oidx);
                                          }}
                                        />

                                        <label htmlFor={oidx.toString()}>
                                          <Button
                                            className={classes.uploadBtn}
                                            component="span"
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            Upload
                                          </Button>
                                        </label>
                                        {
                                          <h4
                                            style={{
                                              display: "inline-block",
                                              marginLeft: "2rem",
                                            }}
                                          >
                                            {opts.img}
                                          </h4>
                                        }
                                      </React.Fragment>
                                    }
                                  />
                                </>
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </>
                    ) : null}
                    {quizType === "3" ? (
                      <>
                        <div className={classes.displayFlex}>
                          <div className={classes.flexBasis}>
                            <h4>Options:</h4>
                          </div>

                          <FormControl
                            component="fieldset"
                            className={classes.root1}
                          >
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={state.opt1}
                                    onChange={(e) =>
                                      handleMultiSelectChange(e, qidx)
                                    }
                                    name="opt1"
                                  />
                                }
                                label={
                                  <input
                                    className="classInput"
                                    type="text"
                                    onChange={(e) => {
                                      let opt1 = e.target.value;

                                      setQuestionData((currentInput) =>
                                        produce(currentInput, (v) => {
                                          v[qidx].iopts[0].content = opt1;
                                        })
                                      );
                                    }}
                                    autoComplete="off"
                                  />
                                }
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={state.opt2}
                                    onChange={(e) =>
                                      handleMultiSelectChange(e, qidx)
                                    }
                                    name="opt2"
                                  />
                                }
                                label={
                                  <input
                                    className="classInput"
                                    type="text"
                                    onChange={(e) => {
                                      let opt2 = e.target.value;
                                      setQuestionData((currentInput) =>
                                        produce(currentInput, (v) => {
                                          v[qidx].iopts[1].content = opt2;
                                        })
                                      );
                                    }}
                                    autoComplete="off"
                                  />
                                }
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={state.opt3}
                                    onChange={(e) =>
                                      handleMultiSelectChange(e, qidx)
                                    }
                                    name="opt3"
                                  />
                                }
                                label={
                                  <input
                                    className="classInput"
                                    type="text"
                                    onChange={(e) => {
                                      let opt3 = e.target.value;
                                      setQuestionData((currentInput) =>
                                        produce(currentInput, (v) => {
                                          v[qidx].iopts[2].content = opt3;
                                        })
                                      );
                                    }}
                                    autoComplete="off"
                                  />
                                }
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={state.opt4}
                                    onChange={(e) =>
                                      handleMultiSelectChange(e, qidx)
                                    }
                                    name="opt4"
                                  />
                                }
                                label={
                                  <input
                                    className="classInput"
                                    type="text"
                                    onChange={(e) => {
                                      let opt4 = e.target.value;
                                      setQuestionData((currentInput) =>
                                        produce(currentInput, (v) => {
                                          v[qidx].iopts[3].content = opt4;
                                        })
                                      );
                                    }}
                                    autoComplete="off"
                                  />
                                }
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={state.opt5}
                                    onChange={(e) =>
                                      handleMultiSelectChange(e, qidx)
                                    }
                                    name="opt5"
                                  />
                                }
                                label={
                                  <input
                                    className="classInput"
                                    type="text"
                                    onChange={(e) => {
                                      let opt5 = e.target.value;
                                      setQuestionData((currentInput) =>
                                        produce(currentInput, (v) => {
                                          v[qidx].iopts[4].content = opt5;
                                        })
                                      );
                                    }}
                                    autoComplete="off"
                                  />
                                }
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={state.opt6}
                                    onChange={(e) =>
                                      handleMultiSelectChange(e, qidx)
                                    }
                                    name="opt6"
                                  />
                                }
                                label={
                                  <input
                                    className="classInput"
                                    type="text"
                                    onChange={(e) => {
                                      let opt6 = e.target.value;
                                      setQuestionData((currentInput) =>
                                        produce(currentInput, (v) => {
                                          v[qidx].iopts[5].content = opt6;
                                        })
                                      );
                                    }}
                                    autoComplete="off"
                                  />
                                }
                              />
                            </FormGroup>
                          </FormControl>
                        </div>
                      </>
                    ) : null}
                    {quizType === "5" ? (
                      <>
                        <div className={classes.displayFlex}>
                          <div className={classes.flexBasis}>
                            <h4>Options:</h4>
                          </div>

                          <FormControl
                            className={classes.root1}
                            component="fieldset"
                            fullWidth
                          >
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={state.opt1}
                                    style={{ color: "#01498e" }}
                                    onChange={(e) =>
                                      handleMultiSelectChange(e, qidx)
                                    }
                                    name="opt1"
                                  />
                                }
                                label={
                                  <div className={classes.root1}>
                                    <input
                                      accept="image/*"
                                      className={classes.input}
                                      id="contained-button-file-1"
                                      multiple
                                      type="file"
                                      onChange={(event) => {
                                        let opt1 = event.target.files[0];
                                        var uniqid = new Date().getTime();
                                        var imtype =
                                          event.target.files[0].name.slice(
                                            (Math.max(
                                              0,
                                              event.target.files[0].name.lastIndexOf(
                                                "."
                                              )
                                            ) || Infinity) + 1
                                          );
                                        let imageName = `img${uniqid}.${imtype}`;
                                        setQuestionData((currentFile) =>
                                          produce(currentFile, (draft) => {
                                            draft[qidx].iopts[0].url =
                                              imageName;
                                            draft[qidx].iopts[0].img =
                                              opt1.name;
                                          })
                                        );
                                        imageUpload(opt1, imageName);
                                      }}
                                    />

                                    <label htmlFor="contained-button-file-1">
                                      <Button
                                        className={classes.uploadBtn}
                                        component="span"
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        Upload
                                      </Button>
                                    </label>
                                    {
                                      <h4
                                        style={{
                                          display: "inline-block",
                                          marginLeft: "2rem",
                                        }}
                                      >
                                        {questionData[qidx].iopts[0].img}
                                      </h4>
                                    }
                                  </div>
                                }
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={state.opt2}
                                    style={{ color: "#01498e" }}
                                    onChange={(e) =>
                                      handleMultiSelectChange(e, qidx)
                                    }
                                    name="opt2"
                                  />
                                }
                                label={
                                  <div className={classes.root1}>
                                    <input
                                      accept="image/*"
                                      className={classes.input}
                                      id="contained-button-file-2"
                                      multiple
                                      type="file"
                                      onChange={(e) => {
                                        let opt2 = e.target.files[0];
                                        var uniqid = new Date().getTime();
                                        var imtype =
                                          e.target.files[0].name.slice(
                                            (Math.max(
                                              0,
                                              e.target.files[0].name.lastIndexOf(
                                                "."
                                              )
                                            ) || Infinity) + 1
                                          );
                                        let imageName = `img${uniqid}.${imtype}`;
                                        setQuestionData((currentFile) =>
                                          produce(currentFile, (draft) => {
                                            draft[qidx].iopts[1].url =
                                              imageName;
                                            draft[qidx].iopts[1].img =
                                              opt2.name;
                                          })
                                        );
                                        imageUpload(opt2, imageName);
                                      }}
                                    />

                                    <label htmlFor="contained-button-file-2">
                                      <Button
                                        className={classes.uploadBtn}
                                        component="span"
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        Upload
                                      </Button>
                                    </label>
                                    {
                                      <h4
                                        style={{
                                          display: "inline-block",
                                          marginLeft: "2rem",
                                        }}
                                      >
                                        {questionData[qidx].iopts[1].img}
                                      </h4>
                                    }
                                  </div>
                                }
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={state.opt3}
                                    style={{ color: "#01498e" }}
                                    onChange={(e) =>
                                      handleMultiSelectChange(e, qidx)
                                    }
                                    name="opt3"
                                  />
                                }
                                label={
                                  <div className={classes.root1}>
                                    <input
                                      accept="image/*"
                                      className={classes.input}
                                      id="contained-button-file-3"
                                      multiple
                                      type="file"
                                      onChange={(e) => {
                                        let opt3 = e.target.files[0];
                                        var uniqid = new Date().getTime();
                                        var imtype =
                                          e.target.files[0].name.slice(
                                            (Math.max(
                                              0,
                                              e.target.files[0].name.lastIndexOf(
                                                "."
                                              )
                                            ) || Infinity) + 1
                                          );
                                        let imageName = `img${uniqid}.${imtype}`;
                                        setQuestionData((currentFile) =>
                                          produce(currentFile, (draft) => {
                                            draft[qidx].iopts[2].url =
                                              imageName;
                                            draft[qidx].iopts[2].img =
                                              opt3.name;
                                          })
                                        );
                                      }}
                                    />

                                    <label htmlFor="contained-button-file-3">
                                      <Button
                                        className={classes.uploadBtn}
                                        component="span"
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        Upload
                                      </Button>
                                    </label>
                                    {
                                      <h4
                                        style={{
                                          display: "inline-block",
                                          marginLeft: "2rem",
                                        }}
                                      >
                                        {questionData[qidx].iopts[2].img}
                                      </h4>
                                    }
                                  </div>
                                }
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={state.opt4}
                                    style={{ color: "#01498e" }}
                                    onChange={(e) =>
                                      handleMultiSelectChange(e, qidx)
                                    }
                                    name="opt4"
                                  />
                                }
                                label={
                                  <div className={classes.root1}>
                                    <input
                                      accept="image/*"
                                      className={classes.input}
                                      id="contained-button-file-4"
                                      multiple
                                      type="file"
                                      onChange={(e) => {
                                        let opt4 = e.target.files[0];
                                        var uniqid = new Date().getTime();
                                        var imtype =
                                          e.target.files[0].name.slice(
                                            (Math.max(
                                              0,
                                              e.target.files[0].name.lastIndexOf(
                                                "."
                                              )
                                            ) || Infinity) + 1
                                          );
                                        let imageName = `img${uniqid}.${imtype}`;
                                        setQuestionData((currentFile) =>
                                          produce(currentFile, (draft) => {
                                            draft[qidx].iopts[3].url =
                                              imageName;
                                            draft[qidx].iopts[3].img =
                                              opt4.name;
                                          })
                                        );
                                      }}
                                    />

                                    <label htmlFor="contained-button-file-4">
                                      <Button
                                        className={classes.uploadBtn}
                                        component="span"
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        Upload
                                      </Button>
                                    </label>
                                    {
                                      <h4
                                        style={{
                                          display: "inline-block",
                                          marginLeft: "2rem",
                                        }}
                                      >
                                        {questionData[qidx].iopts[3].img}
                                      </h4>
                                    }
                                  </div>
                                }
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={state.opt5}
                                    style={{ color: "#01498e" }}
                                    onChange={(e) =>
                                      handleMultiSelectChange(e, qidx)
                                    }
                                    name="opt5"
                                  />
                                }
                                label={
                                  <div className={classes.root1}>
                                    <input
                                      accept="image/*"
                                      className={classes.input}
                                      id="contained-button-file-5"
                                      multiple
                                      type="file"
                                      onChange={(e) => {
                                        let opt5 = e.target.files[0];
                                        var uniqid = new Date().getTime();
                                        var imtype =
                                          e.target.files[0].name.slice(
                                            (Math.max(
                                              0,
                                              e.target.files[0].name.lastIndexOf(
                                                "."
                                              )
                                            ) || Infinity) + 1
                                          );
                                        let imageName = `img${uniqid}.${imtype}`;
                                        setQuestionData((currentFile) =>
                                          produce(currentFile, (draft) => {
                                            draft[qidx].iopts[4].url =
                                              imageName;
                                            draft[qidx].iopts[4].img =
                                              opt5.name;
                                          })
                                        );
                                      }}
                                    />

                                    <label htmlFor="contained-button-file-5">
                                      <Button
                                        className={classes.uploadBtn}
                                        component="span"
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        Upload
                                      </Button>
                                    </label>
                                    {
                                      <h4
                                        style={{
                                          display: "inline-block",
                                          marginLeft: "2rem",
                                        }}
                                      >
                                        {questionData[qidx].iopts[4].img}
                                      </h4>
                                    }
                                  </div>
                                }
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={state.opt6}
                                    style={{ color: "#01498e" }}
                                    onChange={(e) =>
                                      handleMultiSelectChange(e, qidx)
                                    }
                                    name="opt6"
                                  />
                                }
                                label={
                                  <div className={classes.root1}>
                                    <input
                                      accept="image/*"
                                      className={classes.input}
                                      id="contained-button-file-6"
                                      multiple
                                      type="file"
                                      onChange={(e) => {
                                        let opt6 = e.target.files[0];
                                        var uniqid = new Date().getTime();
                                        var imtype =
                                          e.target.files[0].name.slice(
                                            (Math.max(
                                              0,
                                              e.target.files[0].name.lastIndexOf(
                                                "."
                                              )
                                            ) || Infinity) + 1
                                          );
                                        let imageName = `img${uniqid}.${imtype}`;
                                        setQuestionData((currentFile) =>
                                          produce(currentFile, (draft) => {
                                            draft[qidx].iopts[5].url =
                                              imageName;
                                            draft[qidx].iopts[5].img =
                                              opt6.name;
                                          })
                                        );
                                      }}
                                    />

                                    <label htmlFor="contained-button-file-6">
                                      <Button
                                        className={classes.uploadBtn}
                                        component="span"
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        Upload
                                      </Button>
                                    </label>
                                    {
                                      <h4
                                        style={{
                                          display: "inline-block",
                                          marginLeft: "2rem",
                                        }}
                                      >
                                        {questionData[qidx].iopts[5].img}
                                      </h4>
                                    }
                                  </div>
                                }
                              />
                            </FormGroup>
                          </FormControl>
                        </div>
                      </>
                    ) : null}
                    <div className={classes.displayFlex}>
                      <div className={classes.flexBasis}>
                        <h4>Marks:</h4>
                      </div>
                      <div className={classes.root1}>
                        {defaultValidation.error3 ? (
                          <p className="firstViewValidation">
                            Please enter the marks
                          </p>
                        ) : null}

                        <input
                          type="number"
                          className="classInput_Marks"
                          maxLength="1"
                          max="5"
                          min="0"
                          onChange={(e) => {
                            let qScore = e.target.value;

                            setQuestionData((currentQScore) =>
                              produce(currentQScore, (v) => {
                                v[qidx].qscore = qScore;
                              })
                            );
                          }}
                        />
                      </div>
                    </div>
                  </DialogContent>

                  <DialogActions
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <CustomButton
                      autoFocus
                      onClick={() => addQuestion(question, qidx, 0, quizType)}
                    >
                      Add Question
                    </CustomButton>
                    <CustomButton
                      autoFocus
                      onClick={() => addQuestion(question, qidx, 1, quizType)}
                    >
                      Save
                    </CustomButton>
                  </DialogActions>
                </React.Fragment>
              )}
            </React.Fragment>
          ))}
        </React.Fragment>
      ) : null}
    </Dialog>
  );
}
