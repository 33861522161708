import React from 'react';

const GradingIcon = ({ className, cls1, cls2, cls3 }) => {
    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className={className}>
            <rect className={cls1} x="5.36" y="4.55" width="14.38" height="15.9" rx="1.86" />
            <text className={cls2} transform="translate(9.03 10.95)">A+</text>
            <line className={cls3} x1="8" y1="14.03" x2="17.1" y2="14.03" />
            <line className={cls3} x1="8" y1="17" x2="17.1" y2="17" />
        </svg>
    );
};

export default GradingIcon;