// Dependencies imports
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import Paper from "@material-ui/core/Paper";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/styles";
import { API } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import AddScore from "./AddScore";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import Skeleton from "@material-ui/lab/Skeleton";

// Local imports
import UserHeader from "../Header/UserHeader/UserHeader";
import GradingDetailsGrid from "./GradingDetailsGrid";
import AddScoreNew from "./AddScoreNew";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";

// Styles imports
import Grade from "./GradingDetails.module.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: "18px",
    fontWeight: "600px",
    color: Constants.main_color_1,
    fontFamily: "nunito",
  },
  heading1: {
    fontSize: "18px",
    fontWeight: "600px",
    color: Constants.main_color_1,
    fontFamily: "nunito",
    paddingTop: "10px",
    paddingLeft: "10px",
    textAlign: "center",
  },
  dropoption: {
    fontSize: "16px",
    color: "#6a6a6a",
    fontWeight: "500",
    fontFamily: "nunito",
    padding: "10px 0px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
}));

// Start of Grading Details Screen
const GradingDetails = () => {
  const [course, setCourse] = useState([]);
  const [currentData, setCurrentData] = useState("");
  const [currentUsers, setCurrentUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [exportCourse, setExportCourse] = useState([]);
  let userDetails = useSelector(authData);
  // console.log("usdata" + JSON.stringify(userDetails));
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    getMiniAssignments();
    breadcrumb();
  }, []);

  async function getMiniAssignments() {
    setLoading(true);
    setIndex(0);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        pid: userDetails.curprgcou.opid,
        bpid: userDetails.curprgcou.bpid, // these thre are same
        assignid: userDetails.grade.btid,
        telective: userDetails.grade.telective,
        tid: userDetails.grade.tid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_MINI_ASSIGNMENTS,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );
      const users = response;
      // console.log("2222"+ JSON.stringify(users))

      if (response.courses !== undefined) {
        let courses = response.courses;
        // setCourse(response.courses);
        setExportCourse(response.courses);
        function groupByKey(array, key) {
          return array.reduce((hash, obj) => {
            if (obj[key] === undefined) return hash;
            return Object.assign(hash, {
              [obj[key]]: (hash[obj[key]] || []).concat(obj),
            });
          }, {});
        }
        var result = groupByKey(courses, "unit");
        let temp = [];
        for (const [key, value] of Object.entries(result)) {
          let obj = {};
          obj.title = key;
          obj.data = value;
          temp.push(obj);
        }
        //   for (let i = 0; i < courses.length; i++) {
        //     for (let j = 0; j < courses[i].objects.length; j++) {
        //       temp.push(courses[i].objects[j]);
        //     }
        // }

        for (let k = 0; k < temp.length; k++) {
          let obj = temp[k];
          // if (obj.data == undefined) {
          //     obj.data = [];
          // }
          let data = obj.data;
          let notGradedUnit = 0;
          for (let j = 0; j < data.length; j++) {
            // if (data[j].users == undefined) {
            //     data[j].users = [];
            // }
            let udata = data[j].users;
            let rows = [];
            let notGraded = 0;
            for (let i = 0; i < udata.length; i++) {
              if (
                udata[i].GRADES != undefined &&
                udata[i].GRADES.miniassignments[data[j].oid] != undefined
              ) {
                if (udata[i].GRADES.miniassignments[data[j].oid] == -1) {
                  //   alert(JSON.stringify(udata[i]));
                  // obj.score = "Not Graded";
                  let obj = {};
                  // obj.id = i;
                  obj.eid = udata[i].EID;
                  obj.name = udata[i].UNAME;
                  obj.usn = udata[i].USN;
                  obj.email = udata[i].EMAILID;
                  //   obj.email = udata[i].EMAIL;
                  rows.push(obj);
                  notGraded += 1;
                } else {
                  // obj.score = udata[i].GRADES.miniassignments[currentData.oid];
                }
              } else {
                // obj.score = "Not Submited";
              }
            }
            temp[k].data[j].notGradedUsers = rows;
            temp[k].data[j].notGraded = notGraded;
            notGradedUnit += notGraded;
          }
          temp[k].notGradedUnit = notGradedUnit;
        }

        setCourse(temp);
        setLoading(false);
        // alert("hiii"+JSON.stringify(currentData));
        // if (currentData != '') {
        //     alert("byy"+JSON.stringify(currentData));
        //     onClick(currentData);
        // }

        // console.log("tmd " + JSON.stringify(result))
      } else {
        setCourse([]);
        setLoading(false);
      }
    } catch (error) {
      setCourse([]);
      setLoading(false);
      swal({
        title: "Oooops!",
        text: "something went wrong!",
        icon: "warning",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          swal("Oooops!", "Contact developer");
        }
      });
      console.log("getUserError", error);
    }
    // console.log("Mount");
  }
  const onClick = (data) => {
    // console.log("ss", data);
    setCurrentData(data);
    convertRowData(data);
  };
  function convertRowData(currentData) {
    console.log("cdata19" + JSON.stringify(currentData.users));
    let udata = currentData.users;
    let rows = [];
    // console.log("udatass" + JSON.stringify(udata))
    for (let i = 0; i < udata.length; i++) {
      let obj = {};
      obj.id = i;
      obj.eid = udata[i].EID;
      obj.name = udata[i].UNAME;
      obj.usn = udata[i].USN;
      if (udata[i].GRADES != undefined) {
        // console.log("grade" + udata[i].GRADES.miniassignments[currentData.oid])
      }
      if (
        udata[i].GRADES != undefined &&
        udata[i].GRADES.miniassignments[currentData.oid] != undefined
      ) {
        if (udata[i].GRADES.miniassignments[currentData.oid] == -1) {
          obj.score = "Not Graded";
        } else {
          obj.score = udata[i].GRADES.miniassignments[currentData.oid];
        }
      } else {
        obj.score = "Not Submited";
      }

      rows.push(obj);
    }

    setRows(rows);
  }

  function breadcrumb() {
    let sdata = { ...userDetails };
    let temp = [...sdata.breadcrumb];

    temp[3] = {
      name: "Grading Details",
      path: "/gradingdetails",
    };
    sdata.breadcrumb = temp;
    dispatch(awsSignIn(sdata));
  }
  function exportcsv() {
    let an = exportCourse;
    // console.log("export" + JSON.stringify(an));

    var months = [
      "JAN",
      "FAB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    var a = new Date();
    var aday = a.getDate() + "-" + months[a.getMonth()] + "-" + a.getFullYear();

    var json_pre = [],
      tnm = "MiniAssignment-" + userDetails.grade.ttitle;

    let arrHeaderid = ["Name"];
    let arrHeadertitle = ["Name"];
    for (let row of an) {
      arrHeaderid.push(row.oid);
      arrHeadertitle.push(row.otitle);
    }

    // let arrHeadertitle = ["Name"];
    // for(let row of an){
    //     arrHeadertitle.push(row.otitle);
    // }

    for (let Muser of an[0].users) {
      var obj = {};

      obj.Name = Muser.UNAME;
      //obj.Score = user.SCORE;

      for (let row of an) {
        for (let user of row.users) {
          if (user.EID == Muser.EID) {
            if (
              user.GRADES != undefined &&
              user.GRADES.miniassignments[row.oid] != undefined
            ) {
              if (user.GRADES.miniassignments[row.oid] == -1) {
                obj[row.oid] = "Not Graded";
              } else {
                obj[row.oid] = user.GRADES.miniassignments[row.oid];
              }
            } else {
              obj[row.oid] = "Not Submited";
            }

            // if(user.SCORE == 'Not Graded'){
            //     // obj[row.oid] = user.SCORE.toString();
            //     obj[row.oid] = user.GRADES != undefined ? user.GRADES.miniassignments[row.oid] === undefined ? 'Not Graded' : user.GRADES.miniassignments[row.oid] : 'Not Graded';
            // } else {
            //     // let score = (user.SCORE).toString();
            //     // obj.Score = user.GRADES != undefined ? user.GRADES.miniassignments[an.oid] === undefined ? 'Not Graded' : user.GRADES.miniassignments[an.oid]+'/10' : 'Not Graded'
            //     // score = score.split("/",1);
            //     obj[row.oid] = user.GRADES != undefined ? user.GRADES.miniassignments[row.oid] === undefined ? 'Not Graded' : user.GRADES.miniassignments[row.oid] : 'Not Graded';
            // }
          }
        }
        //obj[hrow.assessid] = row[hrow.assessid] != undefined ? row[hrow.assessid] : 'NA';
      }

      json_pre.push(obj);
    }

    let csvData = ConvertToCSV(json_pre, arrHeaderid, arrHeadertitle);

    let blob = new Blob(["\ufeff" + csvData], {
      type: "text/csv;charset=utf-8;",
    });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("Chrome") == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }

    dwldLink.setAttribute("href", url);

    dwldLink.setAttribute("download", "REPORTS-" + tnm + " " + aday + ".csv");

    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
  function strRep(data) {
    if (typeof data == "string") {
      let newData = data.replace(/,/g, " ");
      return newData;
    } else if (typeof data == "undefined") {
      return "-";
    } else if (typeof data == "number") {
      return data.toString();
    } else {
      return data;
    }
  }
  function ConvertToCSV(objArray, headerList, newHeaders) {
    // console.log(objArray);
    // console.log(headerList);
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    let row = "";

    //    let newHeaders = [];

    //        newHeaders = ["Name", "Score"];

    for (let index in newHeaders) {
      row += newHeaders[index] + ",";
    }
    row = row.slice(0, -1);
    str += row + "\r\n";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in headerList) {
        let head = headerList[index];

        line +=
          headerList[headerList.length - 1] == headerList[index]
            ? strRep(array[i][head])
            : strRep(array[i][head]) + ",";
      }
      str += line + "\r\n";
    }
    return str;
  }

  const handleClickOpen = (val) => {
    setCurrentUsers(val);
    setOpen(true);
  };

  const handleClose = () => {
    getMiniAssignments();
    setCurrentData([]);
    setRows([]);
    setOpen(false);
  };

  function gradedetailView(obj, i) {
    console.log("obj " + i + " " + JSON.stringify(obj));
    /* if (obj.data == undefined) {
             obj.data = [];
         }
         let data = obj.data;
         let notGradedUnit = 0;
         for (let j = 0; j < data.length; j++) {
             if (data[j].users == undefined) {
                 data[j].users = [];
             }
 
             let udata = data[j].users;
            
             let notGraded = 0;
             for (let i = 0; i < udata.length; i++) {
 
                 if (udata[i].GRADES != undefined && udata[i].GRADES.miniassignments[currentData.oid] != undefined) {
                     if (udata[i].GRADES.miniassignments[currentData.oid] == -1) {
                         // obj.score = "Not Graded";
                         notGraded++;
                     }
                     else {
                         // obj.score = udata[i].GRADES.miniassignments[currentData.oid];
                     }
                 }
                 else {
                     obj.score = "Not Submited";
                 }
             }
             obj.data[j].notGraded = notGraded;
         }
         obj.notGradedUnit = notGradedUnit;
         
         {obj.notGradedUnit == 0 ? "": <span style={{color:"red"}}> :- {obj.notGradedUnit} </span> }
         
         {data.notGraded == 0 ? "": <span style={{color:"red"}}> :- {data.notGraded} </span>} 

         */

    return (
      <Accordion style={{ width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ width: "100%" }}
        >
          <Typography className={classes.heading}>
            {obj.title}{" "}
            {obj.notGradedUnit == 0 ? (
              ""
            ) : (
              <span style={{ color: "red" }}> :- {obj.notGradedUnit} </span>
            )}
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ width: "100%" }}>
          <Typography style={{ width: "100%" }}>
            {obj.data.map((data, i) => (
              <h3
                className={
                  data.oid === index ? Grade.dropoptionactive : Grade.dropoption
                }
                onClick={() => {
                  onClick(data);
                  setIndex(data.oid);
                }}
              >
                {data.otitle}
                {data.notGraded == 0 ? (
                  ""
                ) : (
                  <span
                    style={{ color: "red" }}
                    onClick={() => {
                      handleClickOpen(data);
                    }}
                  >
                    {" "}
                    :- {data.notGraded}{" "}
                  </span>
                )}
              </h3>
            ))}
          </Typography>
        </AccordionDetails>
      </Accordion>
    );
  }

  return (
    <div className={Grade.maincontainer}>
      <UserHeader Bindex={3} />
      {/* <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <AddScore handleClose={handleClose} />
                </Fade>
            </Modal> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        // onClose={handleClose}
      >
        <Fade in={open}>
          <AddScoreNew handleClose={handleClose} currentUsers={currentUsers} />
        </Fade>
      </Modal>
      <h3 className={Grade.header}>{userDetails.grade.ttitle}</h3>
      {loading === false ? (
        <div className={Grade.middlescreen}>
          <div className={Grade.leftscreen}>
            <button className={Grade.exportsubmit} onClick={() => exportcsv()}>
              Export CSV
            </button>
            <Paper style={{ height: "90%" }}>
              {course.length !== 0 ? (
                <div className={classes.root}>
                  {course.map((obj, i) => (
                    <Accordion style={{ width: "100%" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ width: "100%" }}
                      >
                        <Typography className={classes.heading}>
                          {obj.title}{" "}
                          {obj.notGradedUnit == 0 ? (
                            ""
                          ) : (
                            <span style={{ color: "red" }}>
                              {" "}
                              :- {obj.notGradedUnit}{" "}
                            </span>
                          )}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{ width: "100%" }}>
                        <Typography style={{ width: "100%" }}>
                          {obj.data.map((data, i) => (
                            <h3
                              className={
                                data.oid === index
                                  ? Grade.dropoptionactive
                                  : Grade.dropoption
                              }
                              onClick={() => {
                                onClick(data);
                                setIndex(data.oid);
                              }}
                            >
                              {data.otitle}
                              {data.notGraded == 0 ? (
                                ""
                              ) : (
                                <span
                                  style={{ color: "red" }}
                                  onClick={() => {
                                    handleClickOpen(data);
                                  }}
                                >
                                  {" "}
                                  :- {data.notGraded}{" "}
                                </span>
                              )}
                            </h3>
                          ))}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              ) : (
                <div className={classes.root}>
                  <Typography className={classes.heading1}>No Data</Typography>
                </div>
              )}
            </Paper>
          </div>
          <div className={Grade.rightscreen}>
            <Paper style={{ height: "90%" }}>
              <GradingDetailsGrid
                currentData={currentData}
                rows={rows}
                getMiniAssignments={getMiniAssignments}
              />
            </Paper>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            paddingTop: "20px",
            width: "100%",
            gap: "10px",
          }}
        >
          <Skeleton
            variant="rect"
            width="40%"
            height={600}
            style={{ borderRadius: "5px" }}
          />{" "}
          <Skeleton
            variant="rect"
            width="58%"
            height={400}
            style={{ borderRadius: "5px" }}
          />
        </div>
      )}
    </div>
  );
};

export default GradingDetails;
