// Dependencies imports
import React, { useState, useEffect } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbar,
} from "@material-ui/data-grid";
import { API } from "aws-amplify";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import AddInstructor from "./AddInstructor";
import EditInstructorCourse from "./EditInstructorCourse.js";
// Local imports
import { authData } from "../../redux/auth/authSlice";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import RemovePopup from "./RemovePopup";
import Fac from "./Faculty.module.scss";
// Styles imports
const useStyles = makeStyles({
  root: {
    "& .super-app-theme--header": {
      backgroundColor: Constants.main_color_1,
      fontSize: "16px",
      color: Constants.app_color,
      fontFamily: "nunito",
    },
  },
  root1: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
  },
  ".MuiButton-textPrimary": {
    color: Constants.main_color_1,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
});
// Start of Instructor Grid Component
const InstructorGrid = () => {
  const [loading, setLoading] = useState(true);
  const [facultyData, setFacultyData] = useState([]);
  const [editData, setEditData] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentRemoveInstructor, setCurrentRemoveInstructor] = useState("");
  const classes = useStyles();
  const [spin, setSpin] = useState(false);
  const [openInstructor, setOpenInstructor] = useState(false);
  const [openCourse, setOpenCourse] = useState(false);

  let userDetails = useSelector(authData);

  // Useeffect for faculty details api
  useEffect(() => {
    getFaculty();
    return () => {};
  }, []);

  // function for toolbar options above datagrid
  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <GridToolbar />
      </GridToolbarContainer>
    );
  }

  // Api call to get faculty details
  async function getFaculty() {
    setLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        bpid: userDetails.curprgcou.bpid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const faculty = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PROGRAM_BATCH_REPORT,
        bodyParam
      );

      convertData(faculty);
    } catch (error) {
      console.error(error);
    }
  }

  function convertData(data) {
    let temp = [];
    for (let i = 0; i < data.length; i++) {
      let obj = {};
      obj.id = i;
      obj.name = data[i].UNAME;
      obj.email = data[i].EMAILID;
      obj.userrole = data[i].UR;
      if (data[i].CIDS != undefined) {
        obj.course = data[i].CIDS;
      }
      if (obj.userrole === "Instructor") {
        temp.push(obj);
      }
    }

    setFacultyData(temp);
    setLoading(false);
  }
  const handleClickOpenInstructor = () => {
    setOpenInstructor(true);
  };
  const handleClickCloseInstructor = () => {
    setOpenInstructor(false);
  };
  const handleClickOpenCourse = () => {
    setOpenCourse(true);
  };
  const handleClickCloseCourse = () => {
    setOpenCourse(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function removeInstructor() {
    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        cid: userDetails.curprgcou.bpid,
        email: currentRemoveInstructor,
        batch: userDetails.curprgcou.bname,
        action: "remove",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_ELUPDATEINSTRUCTORBATCH,
        bodyParam
      );

      handleClose();
      if (response.code === "3" || response.code === 3) {
        swal({
          title: "Success",
          text: "Instructor removed Successfully to the Batch",
          icon: "success",
          dangerMode: false,
        }).then((willDelete) => {
          if (willDelete) {
            handleClose();
            getFaculty();
          }
        });
      } else if (response.code === "0" || response.code === 0) {
        swal({
          title: "Oooops!",
          text: "Instructor already exist in the batch!",
          icon: "warning",
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            setSpin(false);
          }
        });
      } else if (response.code === "3" || response.code === 3) {
        swal({
          title: "Success",
          text: "Instructor successfully removed from the batch!",
          icon: "success",
          dangerMode: false,
        }).then((willDelete) => {
          if (willDelete) {
            handleClose();
            getFaculty();
          }
        });
      } else if (response.code === "5" || response.code === 5) {
        swal({
          title: "Oooops!",
          text: "This user is not an Instructor !",
          icon: "warning",
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            setSpin(false);
          }
        });
      } else {
        swal({
          title: "Oooops!",
          text: "Instructor does not exist!",
          icon: "warning",
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            setSpin(false);
          }
        });
      }
      getFaculty();
      setSpin(false);
    } catch (error) {
      setSpin(false);
      swal({
        title: "Oooops!",
        text: "something went wrong!",
        icon: "warning",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          swal("Oooops!", "Contact developer");
          setSpin(false);
        }
      });
    }
  }

  // Column Data for Instructor Grid
  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 600,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "email",
      headerName: "Email",
      width: 600,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
  ];

  if (userDetails.role != "Instructors") {
    columns.push(
      {
        field: "Remove Instructor",
        headerClassName: "super-app-theme--header",
        flex: 1,
        width: 400,
        renderCell: (params) => (
          <strong>
            {userDetails.role == "Instructors" ? null : (
              <Button
                variant="contained"
                size="small"
                style={{
                  marginLeft: 16,
                  background: Constants.main_color_2,
                  color: "white",
                  fontSize: "14px",
                  fontFamily: "nunito",
                }}
                onClick={() => {
                  handleOpen();
                  setCurrentRemoveInstructor(params.row.email);
                }}
              >
                Remove
              </Button>
            )}
          </strong>
        ),
      },
      {
        field: "Course",
        headerClassName: "super-app-theme--header",
        flex: 1,
        width: 400,
        renderCell: () => (
          <strong>
            {userDetails.role == "Instructors" ? null : (
              <Button
                variant="contained"
                size="small"
                style={{
                  marginLeft: 16,
                  background: Constants.main_color_2,
                  color: "white",
                  fontSize: "14px",
                  fontFamily: "nunito",
                }}
                onClick={() => {
                  handleClickOpenCourse();
                }}
              >
                Course
              </Button>
            )}
          </strong>
        ),
      }
    );
  }

  const handleCellClick = (param) => {
    setEditData([param.row]);
  };

  return (
    <div style={{ height: 500, width: "100%" }}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <RemovePopup
            handleClose={handleClose}
            onSubmit={removeInstructor}
            spin={spin}
            setState={setSpin}
          />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openInstructor}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openInstructor}>
          <AddInstructor
            handleClose={handleClickCloseInstructor}
            getFaculty={getFaculty}
            facultyData={facultyData}
          />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openCourse}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openCourse}>
          <EditInstructorCourse
            handleClose={handleClickCloseCourse}
            getFaculty={getFaculty}
            facultyData={editData}
          />
        </Fade>
      </Modal>
      <div className={Fac.buttonholder}>
        {userDetails.role == "Admin" || userDetails.role == "Coordinators" ? (
          <button className={Fac.addbutton} onClick={handleClickOpenInstructor}>
            Add
          </button>
        ) : null}
      </div>

      <div style={{ height: 450, width: "100%" }} className={classes.root}>
        {loading === false ? (
          <DataGrid
            rows={facultyData}
            columns={columns}
            components={{ Toolbar: CustomToolbar }}
            disableSelectionOnClick
            className={classes[".MuiDataGrid-root"]}
            onCellClick={handleCellClick}
          />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "10px",
            }}
          >
            <Skeleton variant="rect" width="100%" height={350} />{" "}
          </div>
        )}
      </div>
    </div>
  );
};

export default InstructorGrid;
