import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/styles";
import { API } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import Skeleton from "@material-ui/lab/Skeleton";
import { TextField } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
// Local imports
import UserHeader from "../../Header/UserHeader/UserHeader";
import ProjectDetailsGrid from "./ProjectDetailsGrid";
import { Constants } from "../../../config/constants";
import config from "../../../config/aws-exports";
import "./ProjectDetails.scss";
import ProjectFile from "./ProjectFile";
// Styles imports
import Grade from "../GradingDetails.module.scss";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextareaAutosize,
} from "@material-ui/core";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbar,
} from "@material-ui/data-grid";

import Accordion from "@material-ui/core/Accordion";
import ListItemIcon from "@mui/material/ListItemIcon";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import SendIcon from "@mui/icons-material/Send";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Tabs, Box, Tab } from "@mui/material";
// import EditIcon from '@material-ui/core/edit';

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";


import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MenuItem from "@mui/material/MenuItem";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import InputAdornment from "@mui/material/InputAdornment";
import Swal from "sweetalert";

import OutlinedInput from "@mui/material/OutlinedInput";
import { IconButton } from "@material-ui/core";

import DirectionsIcon from "@mui/icons-material/Directions";

import Select from "@mui/material/Select";

import Reply from "../../../assets/svgjs/Reply";
// import CourseTab from "../../components/Tabs/courseTab";
import { Tab as Tab1 } from "semantic-ui-react";
import "./ProjectDetails.scss";
import ProjectApprovedMessages from "./ProjectApprovedMessages";
import { List, ListItem, Breadcrumbs } from "@material-ui/core";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@material-ui/icons/Close";

function CustomToolbar() {
  return (
    <GridToolbarContainer
      style={{ display: "flex", justifyContent: "flex-end" }}
    >
      <GridToolbar />
    </GridToolbarContainer>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .super-app-theme--header": {
      backgroundColor: Constants.main_color_1,
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
    },
  },
  textFieldStyle: {
    "& > label": {
      top: "-4px !important",
      fontSize: "1.5rem",
      backgroundColor: "white",
      padding: "2px",
    },
    // "& > div > input": { padding: "10px !important", },
  },
  heading: {
    fontSize: "18px",
    fontWeight: "600px",
    color: Constants.main_color_1,
    fontFamily: "nunito",
  },
  accordionStyle: {
    padding: "2rem",
    "& > div": {
      boxShadow: "#d8d8d880 0px 0px 3px 2px",
      margin: "0rem 0rem 1.5rem 0rem",
    },
    "& > div:before": {
      backgroundColor: "transparent",
    },
  },
  mainBlock: {
    margin: "0rem 0.5rem 1.5rem!important",
    boxShadow:
      "0px 0px 2px 2px rgb(0 0 0 / 10%), 0px 4px 5px 0px rgb(0 0 0 / 4%), 0px 1px 290px 0px rgb(0 0 0 / 2%)",
    "&:before": {
      backgroundColor: "white",
    },
    "& > div:first-child": {
      boxShadow: "0px 2px 2px 0px rgb(0 0 0 / 10%)",
    },
  },
}));

const ProjectGrading = () => {
  let userDetails = useSelector(authData);
  const dispatch = useDispatch();
  const [nuggets, setNuggets] = useState([]);
  const [objectData, setObjectData] = useState({});
  const [projectNugget, setprojectNugget] = useState([]);
  const classes = useStyles();
  let lcourseDetails = userDetails.project;
  console.log("lcourseDetails", userDetails);

  const hiddenFileInput = React.useRef(null);
  useEffect(() => {
    getCourseDetails();
  }, []);
  async function getCourseDetails() {
    console.log("");
    const bodyParam = {
      body: {
        btopicid: lcourseDetails.btid,
        oid: config.aws_org_id,
        topicid: lcourseDetails.tid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const courseDetails = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_COURSE_DETAILS,
        bodyParam
      );

      const topicsJSON = courseDetails?.nuggets;
      function groupByKey(array, key) {
        return array.reduce((hash, obj) => {
          if (obj[key] === undefined) return hash;
          return Object.assign(hash, {
            [obj[key]]: (hash[obj[key]] || []).concat(obj),
          });
        }, {});
      }
      var result = groupByKey(topicsJSON, "unit");
      let temp = [];
      for (let i = 0; i < topicsJSON.length; i++) {
        for (let j = 0; j < topicsJSON[i].objects.length; j++) {
          temp.push(topicsJSON[i].objects[j]);
        }
      }
      setObjectData(temp);
      console.log("temp", temp);
      setNuggets(result);
      setprojectNugget(courseDetails.nuggets);
    } catch (error) {
      console.error(error);
    }
  }

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    console.log("value", value);
    console.log("index", index);
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function CourseStructure() {
    const [value, setValue] = useState();
    const [selectedFile, setSelectedFile] = useState(null);
    const [message, setMessage] = useState("");
    const [replyMessage, setReplyMessage] = useState("");
    const [errMessage, setErrMessage] = useState("");
    const [fileName, setFileName] = useState("");
    const [fileUrlName, setFileUrlName] = useState("");
    const [projectObjDetails, setProjectObjDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState("");
    const [openIndex, setOpenIndex] = useState(null);
    const [showReply, setShowReply] = useState(null);
    const [fileOpen, setFileOpen] = useState(false);
    const [objFile, setObjFile] = useState();
    const [showAllMessage, setShowAllMessage] = useState(false);
    const [allMessages, setAllMessages] = useState([]);
    const [seeApprovedMessages, SetSeeApprovedMessages] = useState([]);
    const [showApprovedMessages, SetShowApprovedMessages] = useState(false);
    const [groupedMessages, setGroupedMessages] = useState([]);
    const [showCommentBox, setShowCommentBox] = useState(null);
    const [score, setScore] = useState(
      userDetails.projectUser.col8 !== undefined
        ? userDetails.projectUser.col8.score
        : ""
    );
    const [scoreEnable, SetScoreEnable] = useState(false);
    const [scoreError, SetScoreError] = useState(false);
    useEffect(() => {

      const fetchData = async () => {
        setLoading(false);
        console.log(value);
        try {
          if (value != undefined) {
            callApiRepeat(value);
          }
        } catch (err) {
          console.log(err.message);
        }
      };

      fetchData();
      const intervalId = setInterval(fetchData, 5000);
      return () => clearInterval(intervalId);
    }, [value]);
    async function callApi(tabValue) {

      setProjectObjDetails([]);
      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          eid: userDetails.projectUser.col3,
          bcid: lcourseDetails.btid,
          objid: tabValue,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      setLoading(true);
      try {
        const response = await API.post(
          config.aws_cloud_logic_custom_name,
          "/getUserProjectObjects",
          bodyParam
        );
        setLoading(false);
        if (response != null || response != undefined) {
          const groupedMessages = response.reduce((acc, message) => {
            const date = message.postedDate;
            if (!acc[date]) {
              acc[date] = [];
            }
            acc[date].push(message);
            return acc;
          }, {});
          setGroupedMessages(groupedMessages);
          setProjectObjDetails(response);
          setAllMessages(response);
        }

        console.log("response", response);
      } catch (error) {
        console.error(error);
      }
    }
    async function callApiRepeat(tabValue) {

      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          eid: userDetails.projectUser.col3,
          bcid: lcourseDetails.btid,
          objid: tabValue,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };


      try {
        const response = await API.post(
          config.aws_cloud_logic_custom_name,
          "/getUserProjectObjects",
          bodyParam
        );
        setLoading(false);
        if (response != null || response != undefined) {
          const groupedMessages = response.reduce((acc, message) => {
            const date = message.postedDate;
            if (!acc[date]) {
              acc[date] = [];
            }
            acc[date].push(message);
            return acc;
          }, {});

          setGroupedMessages(groupedMessages);
          setProjectObjDetails(response);
          setAllMessages(response);
        }

        console.log("response", response);
      } catch (error) {
        console.error(error);
      }
    }
    const handleChangeSideTab = (event, newValue, oid) => {

      console.log("Nugggets", newValue);
      setStatus("");
      setValue(newValue);
      callApi(newValue);
    };

    const handleMessageChange = (event, newValue, oid) => {
      setErrMessage("");
      setMessage(event.target.value);
    };

    async function projectObj(
      obj,
      update,
      replyobj,
      nextObj,
      curObjStatus,
      curProObj
    ) {
      console.log("update", update);
      console.log("statusjh", status);

      let projectObj = {};

      if (update == "update") {
        projectObj = projectObjDetails;
      } else if (update == "reply") {
      } else {
        if (
          message === "" ||
          message === null ||
          (message != undefined && message.trim() === "")
        ) {
          setErrMessage("Please enter Message");
          return;
        }

        console.log("selectedFile", selectedFile);

        if (selectedFile != undefined) {
          if (selectedFile.size > 250 * 1024) {
            setErrMessage("file size is greater than 250kb");
            return;
          }

          let extend =
            selectedFile.name.substring(
              selectedFile.name.lastIndexOf(".") + 1,
              selectedFile.name.length
            ) || selectedFile.name;

          console.log("extend", extend);

          if (
            extend !== "JPG" &&
            extend !== "PNG" &&
            extend !== "JPEG" &&
            extend !== "jpg" &&
            extend !== "png" &&
            extend !== "jpeg" &&
            extend !== "pdf"
          ) {
            setErrMessage(
              "Please upload files in the format of pdf, jpg, jpeg, png only."
            );

            return;
          }
        }

        let pfileName = new Date().getTime();
        projectObj.id = pfileName;
        projectObj.uname = userDetails.role;
        projectObj.message = message;
        projectObj.fileName = fileName;
        projectObj.fileUrlName = fileUrlName;
      }

      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          eid: userDetails.projectUser.col3,
          bcid: lcourseDetails.btid,
          projectObj: projectObj,
          replyobj: replyobj,
          objid: obj,
          type: update,
          nextObj: nextObj,
          bpid: userDetails.curprgcou.bpid,
          curObjStatus: curObjStatus,
          curProObj: curProObj,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      console.log("bodyParam", bodyParam);
      setLoading(true);
      try {
        const response = await API.post(
          config.aws_cloud_logic_custom_name,
          "/updateUserProjectObjects",
          bodyParam
        );

        setLoading(false);
      } catch (error) {
        console.error(error);
      }

      setMessage("");
      setErrMessage("");
      setReplyMessage("");
      setOpenIndex(null);
      setShowReply(null);
      setErrMessage("");
      setSelectedFile(null);
      setStatus("");

      callApi(obj);
    }

    async function handleImageChange(event, oid) {
      setSelectedFile(event.target.files[0]);

      // if (event.target.files[0].size > 250 * 1024) {
      //   setErrMessage("file size is greater than 250kb");
      //   return;
      // }
      setFileName(event.target.files[0].name);
      let pfileName = new Date().getTime();
      let filename = pfileName + event.target.files[0].name;
      setFileUrlName(filename);
      let filetype = event.target.files[0].type;
      if (event.target.files[0]) {
        const bodyParam = {
          body: {
            type: "project",
            filename: filename,
            filetype: filetype,
            oid: config.aws_org_id,
            eid: userDetails.projectUser.col3,
            bcid: lcourseDetails.btid,
            objid: oid,
          },
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        };
        setLoading(true);
        try {
          const response = await API.post(
            config.aws_cloud_logic_custom_name,
            Constants.GET_PRESIGNED_URL,
            bodyParam
          );
          fileUpload(event.target.files[0], response);
        } catch (error) {
          console.log("getCategoryError", error);
        }
      }
    }

    async function fileUpload(file, url) {
      await axios
        .put(url, file, { headers: { "Content-Type": file.type } })
        .then((res) => {
          console.log("data", JSON.stringify(res));
        })
        .catch((err) => {
          console.error(err);
        });

      setLoading(false);
    }

    const handleStatusChange = (event, proObj, object, index) => {
      //toggleCommentBox(projectobj.id);

      console.log("proObjproObjproObjproObj", proObj.id)
      console.log("event", event.target.value);
      setShowCommentBox(true)
      let newProObj = projectObjDetails;
      setStatus(event.target.value);
      let curObjStatus = event.target.value;
      let nextObj;

      if (event.target.value == 2) {
        let index = objectData.findIndex((obj) => obj.oid === value);

        console.log("index", index);
        console.log("OBJ", objectData.length);

        if (objectData.length > index) {
          if (objectData[index + 1] != undefined) {
            nextObj = objectData[index + 1].oid;
          }
        }

        if (nextObj == undefined) {
          SetScoreEnable(true);
        }
      }

      setProjectObjDetails(newProObj);
       projectObj(object.oid, "update", "", nextObj, curObjStatus, proObj);
    };

    const handleReplyMessageChange = (event, newValue, oid) => {
      setReplyMessage(event.target.value);
    };

    const postReply = (id, postid, type) => {
      let replyobj = {};
      replyobj.id = postid;
      replyobj.uname = userDetails.role;
      replyobj.message = replyMessage;

      console.log("id", id);

      projectObj(id, type, replyobj);
    };

    const toggleAccordion = (index) => {
      setOpenIndex(openIndex === index ? null : index);
    };

    const toggleReply = (index) => {
      setShowReply(showReply === index ? null : index);
    };

    const toggleCommentBox = (index) => {
      setShowCommentBox(showCommentBox === index ? null : index);
    };


    function openFile(fileObj) {
      setObjFile(fileObj);
      console.log("fileObj", fileObj);
      handleFileOpen();
    }
    const handleFileOpen = () => {
      setFileOpen(true);
    };

    const handleFileClose = () => {
      setFileOpen(false);
    };

    const downloadFile = async (obj) => {
      const stringWithoutSpaces = obj.fileUrlName.replace(/\s/g, "");
      let downloadUrl = `https://${Constants.DOMAIN
        }/${config.aws_org_id.toLowerCase()}-data/data/project/${userDetails.projectUser.col3
        }/${lcourseDetails.btid}/${value}/${obj.fileUrlName}`;

      console.log("downloadUrl", downloadUrl);
      try {
        const response = await fetch(downloadUrl);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = obj.fileName || "download";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      }
    };

    function formatDate(dateString) {
      // Split the date string into components
      const [day, month, year] = dateString.split("/");
      // Convert month to a numerical value (minus 1 for zero-based months)
      const monthIndex = parseInt(month, 10) - 1;
      // Create a new Date object using the components
      const date = new Date(year, monthIndex, day);
      // Format the date using Intl.DateTimeFormat
      const formatter = new Intl.DateTimeFormat("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      });
      const formattedDate = formatter.format(date);
      return formattedDate;
    }
    const handleApprovedMessageOpen = () => {
      SetShowApprovedMessages(true);
    };

    const handleApprovedMessageClose = () => {
      SetShowApprovedMessages(false);
    };
    async function getProjectApprovedMessage() {
      console.log("lcourseDetails", userDetails);
      console.log("lcourseDetails", lcourseDetails);
      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          bpid: userDetails.curprgcou.bpid,
          tid: lcourseDetails.tid,
          btid: lcourseDetails.btid,
          eid: userDetails.projectUser.col3,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      console.log("bodyParam", bodyParam);
      setLoading(true);
      try {
        const response = await API.post(
          config.aws_cloud_logic_custom_name,
          "/getProjectApprovedMessage",
          bodyParam
        );

        SetSeeApprovedMessages(response);
        setLoading(false);
        handleApprovedMessageOpen();
      } catch (error) {
        console.error(error);
      }
    }

    async function updateScore() {
      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          eid: userDetails.projectUser.col3,
          bcid: lcourseDetails.btid,
          objid: lcourseDetails.tid,
          bpid: userDetails.curprgcou.bpid,
          score: score,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      console.log("bodyParam", bodyParam);

      setLoading(true);
      try {
        const response = await API.post(
          config.aws_cloud_logic_custom_name,
          "/gradeProject",
          bodyParam
        );

        console.log("response", response);
      } catch (error) {
        console.error(error);
      }

      swal({
        title: "Success",
        text: "Marks Addedd successfully",
        icon: "success",
        dangerMode: false,
      }).then((willDelete) => {
        if (willDelete) {
          setLoading(false);
        }
      });
    }

    const handleScoreChange = (event) => {
      setErrMessage("");
      setScore(event.target.value);
    };

    const handleSubmit = (args) => {
      if (score == undefined || score == "") {
        SetScoreError("Please Enter The Score");
        return;
      }
      Swal({
        title: "Are you sure?",
        text: "You want to submit the final score",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willsave) => {
        if (willsave) {
          updateScore();
        }
      });
    };
    return (
      <div className={classes.root}>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={fileOpen}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={fileOpen}>
            <ProjectFile
              handleFileClose={handleFileClose}
              obj={objFile}
              objId={value}
              bcid={lcourseDetails.btid}
              userEid={userDetails.projectUser.col3}
            />
          </Fade>
        </Modal>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={showApprovedMessages}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={showApprovedMessages}>
            <ProjectApprovedMessages
              handleFileClose={handleApprovedMessageClose}
              seeApprovedMessages={seeApprovedMessages}
              nuggets={nuggets}
              projectNugget={projectNugget}
              callApi={callApi}



            />
          </Fade>
        </Modal>
        <div
          style={{
            display: "grid",
            gap: "2rem",
            background: "white",
            gridTemplateColumns: "12rem 12rem",
            padding: "3rem",
          }}
        >
          <h4>Name</h4>
          <h4>{userDetails.projectUser.col1}</h4>

          <h4>Email ID</h4>
          <h4>{userDetails.projectUser.col2}</h4>

          {/* {userDetails.projectUser.col5 == undefined ||
            userDetails.projectUser.col5 == "" ? null : (
            <>
              <h4>Candiate id</h4>
              <h4>{userDetails.projectUser.col5}</h4>
            </>
          )} */}
        </div>

        <div
          style={{
            boxShadow: "#d8d8d880 0px 0px 3px 2px",
            backgroundColor: "white",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              background: "white",
              marginTop: "2rem",
              padding: "2rem",
              alignItems: "center",
            }}
          >
            <h3 style={{ fontWeight: "700" }}>{lcourseDetails.ttitle}</h3>

            {loading ? (
              <Skeleton style={{ width: "100%", margin: "20px" }} />
            ) : (
              <div>
                {scoreEnable || userDetails.projectUser.col8.tp == 2 ? (
                  <div
                    style={{
                      display: "flex",
                      gap: "2rem",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Enter Score"
                      variant="outlined"
                      className={classes.textFieldStyle}
                      // disabled={
                      //   scoreEnable==false || userDetails.projectUser.col8.tp != 2
                      // }
                      value={score}
                      onChange={(e) => {
                        handleScoreChange(e);
                      }}
                    />
                    <button
                      className="button"
                      // disabled={
                      //   scoreEnable==false || userDetails.projectUser.col8.tp != 2
                      // }
                      style={{
                        height: "4.5rem",
                        background: "var(--button-background)",
                        // scoreEnable==false || userDetails.projectUser.col8.tp != 2
                        //   ? "grey"
                        //   : "var(--button-background)",
                      }}
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                ) : (
                  <></>
                )}

                {scoreError && (
                  <p
                    style={{
                      color: "red",
                      margin: "20px",
                      //cursor: "pointer",
                    }}
                  >
                    {scoreError}
                  </p>
                )}
              </div>
            )}
          </div>

          {
            userDetails.projectUser.col5 != undefined || userDetails.projectUser.col5 != "-" ? (<div className={classes.accordionStyle}>
              {Object.keys(nuggets).map((nugget, idx) => (
                <>
                  {nuggets[nugget].map(
                    (
                      { nid, unit, ntitle, nduration, objects, nenabled },
                      idx
                    ) => (


                      <Accordion className={classes.mainBlock}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography className={classes.heading}>
                            <div
                              style={{
                                display: "flex",
                                gap: "10px",
                              }}
                            >
                              <h3
                                className="item"
                                style={{
                                  fontWeight: "bold",

                                  color: userDetails.projectUser && userDetails.projectUser.col11 && userDetails.projectUser.col11.nid == nid ? "#e35f14" : "black"

                                }}
                              >
                                {ntitle}
                              </h3>
                              {
                                userDetails.projectUser && userDetails.projectUser.col11 && userDetails.projectUser.col11.nid == nid ? (<AccessTimeIcon
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    color: "#e35f14",
                                  }}
                                />) : (<></>)

                              }

                            </div>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ display: "block" }}>
                          <Typography>
                            <div>
                              {lcourseDetails?.coursetype == "Project" ? (
                                <Box
                                  sx={{
                                    flexGrow: 1,
                                    bgcolor: "background.paper",
                                    display: "flex",
                                  }}
                                >
                                  <Tabs
                                    orientation="vertical"
                                    variant="scrollable"
                                    value={value}
                                    onChange={handleChangeSideTab}
                                    aria-label="Vertical tabs example"
                                    sx={{ borderRight: 1, borderColor: "divider" }}
                                    style={{ width: "27rem" }}
                                  >
                                    {objects.map(
                                      ({
                                        oid,
                                        otitle,
                                        odescription,
                                        otype,
                                        oduration,
                                        ourl,
                                        userOP,
                                      }) => (
                                        <Tab
                                          key={oid}
                                          label={
                                            <div
                                              style={{
                                                display: "flex",
                                                gap: "10px",
                                                width: "100%",
                                                justifyContent: "Space-between",
                                                textTransform: "capitalize",
                                                alignItems: "center",
                                                color: "black",
                                              }}
                                            >
                                              <p
                                                style={{
                                                  fontWeight: "500",
                                                  fontSize: "16px",
                                                  textAlign: "left",
                                                }}
                                              >
                                                {otitle}
                                              </p>

                                            </div>
                                          }
                                          value={oid}
                                          style={{
                                            fontSize: "12px",
                                            alignItems: "start",
                                          }}
                                        />
                                      )
                                    )}
                                  </Tabs>

                                  {loading ? (
                                    <Skeleton
                                      style={{ width: "100%", margin: "20px" }}
                                    />
                                  ) : (
                                    <>
                                      {objects.map((object, index) => (
                                        <TabPanel
                                          key={index}
                                          value={value}
                                          index={object.oid}
                                          style={{ width: "100%" }}
                                        >
                                          <div
                                            style={{
                                              border: "1px solid lightgray",
                                              overflow: "hidden",
                                              borderRadius: "8px",
                                              marginBottom: "2rem",
                                              //backgroundColor: "#f0f3f7",
                                            }}
                                          >
                                            <div
                                              className="Project_Side"
                                              style={{
                                                display: "flex",
                                                backgroundColor: "#2f6eb2",
                                                justifyContent: "SpaceBetween",
                                                color: "white",
                                              }}
                                            >
                                              <h3 style={{ padding: "1rem" }}>
                                                {object.otitle}
                                              </h3>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  gap: "10px",
                                                  alignItems: "center",
                                                  padding: "1rem",
                                                }}
                                              >
                                                <DirectionsIcon />

                                                {showAllMessage ? (
                                                  <h3
                                                    style={{
                                                      textDecoration: "underline",
                                                      cursor: "pointer",
                                                      fontSize: "1.4rem",
                                                    }}
                                                    onClick={() => {
                                                      setShowAllMessage(false);
                                                      setProjectObjDetails(
                                                        allMessages
                                                      );
                                                    }}
                                                  >
                                                    See All message
                                                  </h3>
                                                ) : (
                                                  <h3
                                                    style={{
                                                      textDecoration: "underline",
                                                      cursor: "pointer",
                                                      fontSize: "1.4rem",
                                                    }}
                                                    onClick={() => {
                                                      getProjectApprovedMessage();
                                                    }}
                                                  >
                                                    See approved message
                                                  </h3>
                                                )}


                                              </div>
                                            </div>

                                            {projectObjDetails == undefined ||
                                              projectObjDetails.length == 0 ? (
                                              <></>
                                            ) : (
                                              <>
                                                <div>
                                                  {Object.keys(
                                                    groupedMessages
                                                  ).map((date) => (
                                                    <div key={date}>
                                                      <div
                                                        style={{
                                                          position: "relative",
                                                          borderBottom:
                                                            "1px solid lightgray",
                                                          margin: "2rem 0rem",
                                                        }}
                                                      >
                                                        <p
                                                          style={{
                                                            position: "absolute",
                                                            left: "42%",
                                                            top: "-1.5rem",
                                                            background: "white",
                                                            padding:
                                                              "0.5rem 3rem",
                                                            borderRadius: "4rem",
                                                            border:
                                                              "1px solid lightgray",
                                                            fontSize: "1.3rem",
                                                          }}
                                                        >
                                                          {formatDate(date)}
                                                        </p>
                                                      </div>
                                                      {groupedMessages[date].map(
                                                        (projectobj) => (
                                                          <div>
                                                            <div
                                                              style={{


                                                                borderLeft: projectobj.objStatus ==
                                                                  2 ? "3px solid #01a71b" : "",

                                                                backgroundColor: projectobj.objStatus ==
                                                                  2 ? "#d9f8db" : "",

                                                                borderBottom:
                                                                  "1px solid #cecece",
                                                                // borderTop:
                                                                //   "1px solid #cecece",
                                                                borderRadius:
                                                                  "5px",
                                                              }}
                                                            >
                                                              <ListItem alignItems="flex-start">
                                                                <ListItemAvatar>
                                                                  <Avatar
                                                                    // style={{
                                                                    //   backgroundColor:
                                                                    //     "#ffa200",
                                                                    // }}

                                                                    style={{
                                                                      fontSize:
                                                                        "14px",
                                                                      // backgroundColor:
                                                                      //   projectobj.uname
                                                                      //     .charAt(0)
                                                                      //     .toUpperCase() ===
                                                                      //   "I"
                                                                      //     ? "#007bff"
                                                                      //     : "#ffa200",

                                                                      backgroundColor:
                                                                        projectobj.uname
                                                                          .charAt(
                                                                            0
                                                                          )
                                                                          .toUpperCase() ===
                                                                          "A"
                                                                          ? "#f44336"
                                                                          : projectobj.uname
                                                                            .charAt(
                                                                              0
                                                                            )
                                                                            .toUpperCase() ===
                                                                            "I"
                                                                            ? "#007bff"
                                                                            : "#ffa200", // Default color if neither 'A' nor 'I'
                                                                    }}
                                                                  >
                                                                    {projectobj.uname
                                                                      .charAt(0)
                                                                      .toUpperCase()}
                                                                  </Avatar>
                                                                </ListItemAvatar>
                                                                <ListItemText
                                                                  style={{
                                                                    display:
                                                                      "block",
                                                                  }}
                                                                  primary={
                                                                    <p
                                                                      style={{
                                                                        fontSize:
                                                                          "14px",
                                                                        fontWeight:
                                                                          "500",
                                                                        marginBottom:
                                                                          "9px",
                                                                        color:
                                                                          "#333333",
                                                                        overflow:
                                                                          "auto",
                                                                      }}
                                                                    >
                                                                      {
                                                                        projectobj.uname
                                                                      }
                                                                    </p>
                                                                  }
                                                                  secondary={
                                                                    <div
                                                                      style={{
                                                                        fontSize:
                                                                          "16px",
                                                                        color:
                                                                          "black",
                                                                      }}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          marginBottom:
                                                                            "2rem",
                                                                        }}
                                                                      >
                                                                        <p
                                                                          style={{
                                                                            marginBottom:
                                                                              "1rem",
                                                                          }}
                                                                        >
                                                                          {
                                                                            projectobj.message
                                                                          }
                                                                        </p>

                                                                        {projectobj.fileName && (
                                                                          <div
                                                                            style={{
                                                                              display:
                                                                                "flex",
                                                                              gap: "1rem",

                                                                              alignItems:
                                                                                "center",
                                                                              marginBottom:
                                                                                "7px",
                                                                            }}
                                                                          >
                                                                            <p
                                                                              style={{
                                                                                fontSize:
                                                                                  "12px",
                                                                              }}
                                                                            >
                                                                              {
                                                                                projectobj.fileName
                                                                              }
                                                                            </p>

                                                                            <div>
                                                                              <VisibilityIcon
                                                                                style={{
                                                                                  width:
                                                                                    "20px",
                                                                                  height:
                                                                                    "20px",
                                                                                  marginRight:
                                                                                    "5px",
                                                                                  cursor:
                                                                                    "pointer",
                                                                                }}
                                                                                onClick={() => {
                                                                                  // setObjFile(
                                                                                  //   projectobj
                                                                                  // );
                                                                                  openFile(
                                                                                    projectobj
                                                                                  );
                                                                                }}
                                                                              />

                                                                              <DownloadIcon
                                                                                style={{
                                                                                  width:
                                                                                    "20px",
                                                                                  height:
                                                                                    "20px",
                                                                                  marginRight:
                                                                                    "5px",
                                                                                  cursor:
                                                                                    "pointer",
                                                                                }}
                                                                                onClick={() => {
                                                                                  downloadFile(
                                                                                    projectobj
                                                                                  );
                                                                                }}
                                                                              />
                                                                            </div>
                                                                          </div>
                                                                        )}

                                                                        <div
                                                                          style={{
                                                                            display:
                                                                              "flex",
                                                                            gap: "10px",
                                                                            alignItems:
                                                                              "center",
                                                                          }}
                                                                        >
                                                                          <div>
                                                                            <IconButton
                                                                              edge="end"
                                                                              onClick={() =>
                                                                                toggleReply(
                                                                                  projectobj.id
                                                                                )
                                                                              }
                                                                            >
                                                                              <Reply className="replyIcon" />
                                                                            </IconButton>
                                                                          </div>
                                                                          <p>
                                                                            reply
                                                                          </p>
                                                                          <p>
                                                                            {projectobj.reply ==
                                                                              undefined ||
                                                                              projectobj
                                                                                .reply
                                                                                .length ==
                                                                              0 ? (
                                                                              <>

                                                                              </>
                                                                            ) : (
                                                                              <div
                                                                                style={{
                                                                                  display:
                                                                                    "flex",
                                                                                  gap: "10px",
                                                                                }}
                                                                              >
                                                                                <p>
                                                                                  |
                                                                                </p>

                                                                                {/* <p style={{borderLeft: "2px solid #909599", height: "5rem"}}></p> */}
                                                                                <a
                                                                                  style={{
                                                                                    textDecoration:
                                                                                      "underline",
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  }}
                                                                                  href
                                                                                  onClick={() =>
                                                                                    toggleAccordion(
                                                                                      projectobj.id
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  {
                                                                                    projectobj
                                                                                      .reply
                                                                                      .length
                                                                                  }
                                                                                </a>

                                                                                <p>
                                                                                  reply
                                                                                </p>
                                                                              </div>
                                                                            )}
                                                                          </p>
                                                                          {/* <p style={{borderLeft: "2px solid #909599", height: "5rem"}}></p> */}
                                                                          <p>|</p>

                                                                          {projectobj.uname ==
                                                                            "Admin" ||
                                                                            projectobj.uname ==
                                                                            "Instructors" ? (
                                                                            <></>
                                                                          ) : (
                                                                            <div>
                                                                              <FormControl
                                                                                style={{
                                                                                  width:
                                                                                    "20rem",
                                                                                }}
                                                                              >
                                                                                <InputLabel
                                                                                  id="demo-simple-select-label"
                                                                                  style={{
                                                                                    padding:
                                                                                      "0px 8px",
                                                                                    background:
                                                                                      "white",
                                                                                    fontSize:
                                                                                      "1.5rem",
                                                                                  }}
                                                                                >
                                                                                  Approval
                                                                                  Status
                                                                                </InputLabel>
                                                                                <Select
                                                                                  labelId="demo-simple-select-label"
                                                                                  id="demo-simple-select"
                                                                                  value={
                                                                                    projectobj.objStatus ==
                                                                                      undefined
                                                                                      ? status
                                                                                      : projectobj.objStatus
                                                                                  }
                                                                                  label="Age"
                                                                                  onChange={(
                                                                                    e
                                                                                  ) => {
                                                                                    handleStatusChange(
                                                                                      e,
                                                                                      projectobj,
                                                                                      object,
                                                                                      index
                                                                                    );
                                                                                    toggleCommentBox(projectobj.id)

                                                                                  }

                                                                                  }
                                                                                >
                                                                                  <MenuItem
                                                                                    value={
                                                                                      2
                                                                                    }
                                                                                    style={{
                                                                                      justifyContent:
                                                                                        "center",
                                                                                      textAlign:
                                                                                        "left",
                                                                                    }}
                                                                                  >
                                                                                    <div
                                                                                      style={{
                                                                                        display:
                                                                                          "flex",
                                                                                        gap: "1rem",
                                                                                        color:
                                                                                          "green",
                                                                                      }}
                                                                                    >
                                                                                      <DoneIcon
                                                                                        style={{
                                                                                          height:
                                                                                            "2rem",
                                                                                          width:
                                                                                            "2rem",
                                                                                        }}
                                                                                      />

                                                                                      <h4
                                                                                        style={{
                                                                                          width:
                                                                                            "8rem",
                                                                                        }}
                                                                                      >
                                                                                        Approved
                                                                                      </h4>
                                                                                    </div>
                                                                                  </MenuItem>
                                                                                  <MenuItem
                                                                                    style={{
                                                                                      justifyContent:
                                                                                        "center",
                                                                                    }}
                                                                                    value={
                                                                                      3
                                                                                    }
                                                                                  >
                                                                                    <div
                                                                                      style={{
                                                                                        display:
                                                                                          "flex",
                                                                                        gap: "1rem",
                                                                                        color:
                                                                                          "red",
                                                                                      }}
                                                                                    >
                                                                                      <ClearIcon
                                                                                        style={{
                                                                                          height:
                                                                                            "2rem",
                                                                                          width:
                                                                                            "2rem",
                                                                                        }}
                                                                                      />

                                                                                      <h4
                                                                                        style={{
                                                                                          width:
                                                                                            "8rem",
                                                                                        }}
                                                                                      >
                                                                                        Reject
                                                                                      </h4>
                                                                                    </div>
                                                                                  </MenuItem>

                                                                                  <MenuItem
                                                                                    style={{
                                                                                      justifyContent:
                                                                                        "center",
                                                                                    }}
                                                                                    value={
                                                                                      1
                                                                                    }
                                                                                  >
                                                                                    <div
                                                                                      style={{
                                                                                        display:
                                                                                          "flex",
                                                                                        gap: "1rem",
                                                                                        color:
                                                                                          "orange",
                                                                                      }}
                                                                                    >
                                                                                      <AccessTimeIcon
                                                                                        style={{
                                                                                          height:
                                                                                            "2rem",
                                                                                          width:
                                                                                            "2rem",
                                                                                        }}
                                                                                      />
                                                                                      <h4
                                                                                        style={{
                                                                                          width:
                                                                                            "8rem",
                                                                                        }}
                                                                                      >
                                                                                        Inprogress
                                                                                      </h4>
                                                                                    </div>
                                                                                  </MenuItem>
                                                                                </Select>
                                                                              </FormControl>
                                                                            </div>
                                                                          )}
                                                                        </div>



                                                                        {/* {(showCommentBox ===
                                                                          index ||
                                                                          showCommentBox ===
                                                                          projectobj.id) && (<div>
                                                                            <TextareaAutosize
                                                                              style={{
                                                                                width: "100%",
                                                                                resize: "none",
                                                                                fontSize: "15px",
                                                                                lineHeight: "1.3",
                                                                                padding: "10px",
                                                                              }}
                                                                              minRows={2}
                                                                              placeholder="Type the feedback here..."
                                                                            // defaultValue={feedbackEnabled.comments}
                                                                            // onChange={(e) => (feedbackEnabled.comments = e.target.value)}
                                                                            />
                                                                          </div>)} */}

                                                                        {(showReply ===
                                                                          index ||
                                                                          showReply ===
                                                                          projectobj.id) && (
                                                                            <div>
                                                                              <ListItem alignItems="flex-start">
                                                                                <ListItemAvatar>
                                                                                  <Avatar
                                                                                    // style={{
                                                                                    //   backgroundColor:
                                                                                    //     "#ffa200",
                                                                                    // }}

                                                                                    style={{
                                                                                      fontSize:
                                                                                        "14px",
                                                                                      // backgroundColor:
                                                                                      //   projectobj.uname
                                                                                      //     .charAt(0)
                                                                                      //     .toUpperCase() ===
                                                                                      //   "I"
                                                                                      //     ? "#007bff"
                                                                                      //     : "#ffa200",

                                                                                      backgroundColor:
                                                                                        userDetails.role
                                                                                          .charAt(
                                                                                            0
                                                                                          )
                                                                                          .toUpperCase() ===
                                                                                          "A"
                                                                                          ? "#f44336"
                                                                                          : userDetails.role
                                                                                            .charAt(
                                                                                              0
                                                                                            )
                                                                                            .toUpperCase() ===
                                                                                            "I"
                                                                                            ? "#007bff"
                                                                                            : "#ffa200", // Default color if neither 'A' nor 'I'
                                                                                    }}
                                                                                  >
                                                                                    {userDetails.role
                                                                                      .charAt(
                                                                                        0
                                                                                      )
                                                                                      .toUpperCase()}
                                                                                  </Avatar>
                                                                                </ListItemAvatar>
                                                                                <ListItemText
                                                                                  style={{
                                                                                    display:
                                                                                      "block",
                                                                                  }}
                                                                                  primary={
                                                                                    <p
                                                                                      style={{
                                                                                        fontSize:
                                                                                          "small",
                                                                                        color:
                                                                                          "#3372B5",
                                                                                        marginBottom:
                                                                                          "5px",
                                                                                      }}
                                                                                    >
                                                                                      {
                                                                                        userDetails.role
                                                                                      }
                                                                                    </p>
                                                                                  }
                                                                                  secondary={
                                                                                    <OutlinedInput
                                                                                      style={{
                                                                                        margin:
                                                                                          "15px",
                                                                                        width:
                                                                                          "97%",
                                                                                        fontSize:
                                                                                          "14px",
                                                                                      }}
                                                                                      placeholder="Reply..."
                                                                                      value={
                                                                                        replyMessage
                                                                                      }
                                                                                      onChange={(
                                                                                        e
                                                                                      ) => {
                                                                                        handleReplyMessageChange(
                                                                                          e
                                                                                        );
                                                                                      }}
                                                                                      //onKeyDown={handleKeyPress}
                                                                                      endAdornment={
                                                                                        <InputAdornment position="end">
                                                                                          <IconButton edge="end">
                                                                                            <SendIcon
                                                                                              style={{
                                                                                                color:
                                                                                                  "#3372b5",
                                                                                                height:
                                                                                                  "2.5rem",
                                                                                                width:
                                                                                                  "2.5rem",
                                                                                              }}
                                                                                              onClick={() => {
                                                                                                postReply(
                                                                                                  object.oid,
                                                                                                  projectobj.id,
                                                                                                  "reply"
                                                                                                );
                                                                                              }}
                                                                                            />
                                                                                          </IconButton>
                                                                                        </InputAdornment>
                                                                                      }
                                                                                    />
                                                                                  }
                                                                                />
                                                                              </ListItem>
                                                                            </div>
                                                                          )}

                                                                        {projectobj.reply ==
                                                                          undefined ||
                                                                          projectobj
                                                                            .reply
                                                                            .length ==
                                                                          0 ? (
                                                                          <></>
                                                                        ) : (
                                                                          <>
                                                                            {projectobj.reply.map(
                                                                              (
                                                                                reply,
                                                                                index
                                                                              ) => (
                                                                                <div
                                                                                  key={
                                                                                    index
                                                                                  }
                                                                                  className="accordion"
                                                                                >
                                                                                  {(openIndex ===
                                                                                    index ||
                                                                                    openIndex ===
                                                                                    projectobj.id) && (
                                                                                      <div
                                                                                        className="accordion-content"
                                                                                        style={{
                                                                                          width:
                                                                                            "60%",
                                                                                        }}
                                                                                      >
                                                                                        <ListItem alignItems="flex-start">
                                                                                          <ListItemAvatar>
                                                                                            <Avatar
                                                                                              // style={{
                                                                                              //   backgroundColor:
                                                                                              //     "#ffa200",
                                                                                              // }}

                                                                                              style={{
                                                                                                fontSize:
                                                                                                  "14px",

                                                                                                backgroundColor:
                                                                                                  reply.uname
                                                                                                    .charAt(
                                                                                                      0
                                                                                                    )
                                                                                                    .toUpperCase() ===
                                                                                                    "A"
                                                                                                    ? "#f44336"
                                                                                                    : reply.uname
                                                                                                      .charAt(
                                                                                                        0
                                                                                                      )
                                                                                                      .toUpperCase() ===
                                                                                                      "I"
                                                                                                      ? "#007bff"
                                                                                                      : "#ffa200", // Default color if neither 'A' nor 'I'
                                                                                              }}
                                                                                            >
                                                                                              {reply.uname
                                                                                                .charAt(
                                                                                                  0
                                                                                                )
                                                                                                .toUpperCase()}
                                                                                            </Avatar>
                                                                                          </ListItemAvatar>
                                                                                          <ListItemText
                                                                                            style={{
                                                                                              display:
                                                                                                "block",
                                                                                            }}
                                                                                            primary={
                                                                                              <p
                                                                                                style={{
                                                                                                  fontSize:
                                                                                                    "small",
                                                                                                  color:
                                                                                                    "#3372B5",
                                                                                                  marginBottom:
                                                                                                    "5px",
                                                                                                }}
                                                                                              >
                                                                                                {
                                                                                                  reply.uname
                                                                                                }
                                                                                              </p>
                                                                                            }
                                                                                            secondary={
                                                                                              <div
                                                                                                style={{
                                                                                                  fontSize:
                                                                                                    "medium",
                                                                                                }}
                                                                                              >
                                                                                                {
                                                                                                  reply.message
                                                                                                }
                                                                                              </div>
                                                                                            }
                                                                                          />
                                                                                        </ListItem>
                                                                                        <hr />
                                                                                      </div>
                                                                                    )}
                                                                                </div>
                                                                              )
                                                                            )}
                                                                          </>
                                                                        )}
                                                                      </div>
                                                                    </div>
                                                                  }
                                                                />
                                                              </ListItem>
                                                            </div>
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                  ))}
                                                </div>
                                              </>
                                            )}

                                            <OutlinedInput
                                              style={{
                                                margin: "15px",
                                                width: "97%",
                                                fontSize: "14px",
                                              }}
                                              placeholder="Message..."
                                              value={message}
                                              onChange={(e) => {
                                                handleMessageChange(e);
                                              }}
                                              endAdornment={
                                                <InputAdornment position="end">
                                                  <div
                                                    style={{
                                                      fontSize: "14px",
                                                    }}
                                                  >
                                                    <input
                                                      className={classes.hidden}
                                                      // id={files.name}
                                                      // name={files.name}
                                                      accept="image/*,application/pdf,"
                                                      type="file"
                                                      onChange={(e) => {
                                                        handleImageChange(
                                                          e,
                                                          object.oid
                                                        );
                                                      }}
                                                      ref={hiddenFileInput}
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />

                                                    <Avatar
                                                      style={{
                                                        background: "transparent",

                                                        cursor: "pointer",
                                                      }}
                                                      onClick={handleClick}
                                                    >
                                                      <AttachFileIcon
                                                        style={{
                                                          rotate: "none",
                                                          transform:
                                                            "rotate(30deg)",
                                                          color: "#3f51b5",
                                                          height: "2.5rem",
                                                          width: "2.5rem",
                                                        }}
                                                      />
                                                    </Avatar>

                                                    <Typography
                                                      className={classes.title}
                                                      variant="h6"
                                                    ></Typography>
                                                    <p
                                                      style={{
                                                        fontSize: "12px",
                                                        fontWeight: "bold",
                                                        paddingLeft: "3%",
                                                      }}
                                                    ></p>
                                                  </div>
                                                  <IconButton edge="end">
                                                    <SendIcon
                                                      style={{
                                                        color: "#3372b5",
                                                        height: "2.5rem",
                                                        width: "2.5rem",
                                                      }}
                                                      onClick={() => {
                                                        projectObj(object.oid);
                                                      }}
                                                    />
                                                  </IconButton>
                                                </InputAdornment>
                                              }
                                            />

                                            {selectedFile && (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  gap: "5px",
                                                  fontSize: "12px",
                                                  // background: "#f2f2f2",
                                                  padding: "5px",
                                                  margin:
                                                    "0 0 10px 20px" /* top, right, bottom, left */,
                                                }}
                                              >
                                                <p>{selectedFile.name}</p>
                                                {/* <p
                      style={{
                        color: "red",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setSelectedFile(null)
                      }
                    >
                      X
                    </p> */}

                                                <CloseIcon
                                                  style={{
                                                    color: "red",
                                                    cursor: "pointer",
                                                    height: "2rem",
                                                    width: "2rem",
                                                  }}
                                                  onClick={() => {
                                                    setSelectedFile(null);
                                                    setErrMessage("");
                                                  }}
                                                />
                                              </div>
                                            )}

                                            {errMessage && (
                                              <p
                                                style={{
                                                  color: "red",
                                                  margin: "0 0 10px 20px",
                                                  textAlign: "center",
                                                }}
                                              >
                                                {errMessage}
                                              </p>
                                            )}
                                          </div>
                                        </TabPanel>
                                      ))}
                                    </>
                                  )}
                                </Box>
                              ) : (
                                <></>
                              )}
                            </div>

                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                    )
                  )}

                </>

              ))}
            </div>) : (<></>)
          }

        </div>
      </div>
    );
  }

  return (
    <div className={Grade.maincontainer}>
      <UserHeader Bindex={3} />
      <CourseStructure />
    </div>
  );
};

export default ProjectGrading;
