import React, { useEffect, useState } from "react";
import { API, Auth } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route, Link } from "react-router-dom";
import { Offline, Online } from "react-detect-offline";

import { awsSignIn, authData, awsSignOut } from "../redux/auth/authSlice";
import { SignIn, loginState, SignOut } from "../redux/auth/loginSlice";
import config from "../config/aws-exports";
import { Constants } from "../config/constants";

import LandingScreen from "../components/LandingScreen/LandingScreen";
import CreateQuiz from "../components/CreateQuiz/CreateQuiz";
//import Constants from '../config/constants';
//import logo from './logo.svg';

// import IndividualProgram from "../components/MyPrograms/IndividualProgram";

import MyApplicationLayout from "../Layouts/MyApplicationLayout";

import "./App.scss";
import Layout from "../Layouts/DashboardLayout";
import ProgramsLayout from "../Layouts/ProgramsLayout";
import ProgramsInfoLayout from "../Layouts/ProgramsInfoLayout";
import ManageBatchLayout from "../Layouts/ManageBatchLayout";
import FacultyLayout from "../Layouts/FacultyLayout";
import ContentLayout from "../Layouts/ContentLayout";
import GradingLayout from "../Layouts/GradingLayout";
import FeedbackLayout from "../Layouts/FeedbackLayout";
import UserStatsLayout from "../Layouts/UserStatsLayout";
import UsersLayout from "../Layouts/UsersLayout";
import ReportsLayout from "../Layouts/ReportsLayout";
import CourseViewLayout from "../Layouts/courseViewLayout";
import EmailTempLayout from "../Layouts/EmailTempLayout";
import DiscussionLayout from "../Layouts/DiscussionLayout";
import AnnouncementLayout from "../Layouts/AnnouncementLayout";
import EventsLayout from "../Layouts/EventsLayout";
import UserProgressLayout from "../Layouts/UserProgressLayout";
import GradingDetailsLayout from "../Layouts/GradingDetailsLayout";
import AssesmentDetailsLayout from "../Layouts/AssesmentDetailsLayout";
import AssignmentDetailsLayout from "../Layouts/AssignmentDetailsLayout";
import ApplicationDetailsLayout from "../Layouts/ApplicationDetailsLayout";
import JssApplicationDetailsLayout from "../Layouts/JssApplicationDetailsLayout";

import CourseLayout from "../Layouts/CourseLayout";

import ReportsAndAnalyticsLayout from "../Layouts/ReportsAndAnalyticsLayout";

import AllProgramsLayout from "../Layouts/AllProgramsLayout";
import AllProgramsInfoLayout from "../Layouts/ApplyProgramInfoLayout";
import ApplicationLayout from "../Layouts/ApplicationLayout";
import CourseCreationLayout from "../Layouts/courseCreationLayout";
import ComplentsLayout from "../Layouts/ComplentsLayout";
import PreLoginComplaints from "../Layouts/PreLoginComplaints";

import ProjectDetailsLayout from "../Layouts/ProjectDetailsLayout";
import ProjectGradingLayout from "../Layouts/ProjectGradingLayout";

function App() {
  const [api, setApi] = useState([{}]);
  const [user, setUser] = useState("");
  const userAuthStateValue = useSelector(authData);
  const loginStateValue = useSelector(loginState);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => { };
  }, []);

  async function signIn() {
    try {
      const user = await Auth.signIn("sumana@enhanzed.com", "qwerty@1");

      const info = await Auth.currentUserInfo();
      let userdata = user.attributes;
      userdata.username = user.username;
      userdata.id = info.id;
      setUser(JSON.stringify(userdata));
      dispatch(awsSignIn(userdata));
    } catch (error) {
      console.log("error signing in", error);
    }
  }

  function Home() {
    return (
      <div>
        <button onClick={signIn}>Login</button>
        <pre>{JSON.stringify(userAuthStateValue, null, 2)}</pre>
        <button
          onClick={() => {
            Auth.signOut();
            dispatch(awsSignOut());
          }}
        >
          signOut
        </button>
        <h1>Home</h1>
        <nav>
          <Link to="/">Home</Link>
          {" | "}
          <Link to="/LandingScreen">LandingScreen</Link>
          {" | "}
          <Link to="/about">About</Link>
          {" | "}
          <Link to="/dashboard">Dashboard </Link>
          {" | "}
          <Link to="/event">Events </Link>
          {" | "}
          <Link to="/MyPrograms/programs">Programs </Link>
          {" | "}
          <Link to="/myPrograms">Myprograms</Link>
          {" | "}
          <Link to="/course">Course</Link>
          {" | "}
          <Link to="/myProg">PreLogin</Link>
          {" | "}
          <Link to="/event">Event</Link>
        </nav>

        <div
          style={{ padding: 20, height: 300, width: "100%", overflow: "auto" }}
        ></div>
      </div>
    );
  }

  function About() {
    return (
      <div>
        <button
          onClick={() => {
            Auth.signOut();
            dispatch(awsSignOut());
          }}
        >
          signOut
        </button>
        <h1>About</h1>
        <nav>
          <Link to="/">Home</Link> {" | "}
          <Link to="/LandingScreen">LandingScreen</Link> {" | "}
          <Link to="/about">About</Link>
        </nav>
      </div>
    );
  }

  return (
    <div className="App">
      {/* <Offline>
        <div style={{width:'100%',height:'100%',position:'fixed',display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center', zIndex:'1110',background:'#000000a1',color:'white',fontSize:'100px'}}>
          <div>
            Please check your internet connection!
          </div>
        </div>
      </Offline> */}
      <Routes>
        {loginStateValue === 0 ? (
          <>
            <Route path="/" element={<LandingScreen />} />
            <Route path="*" element={<LandingScreen />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Layout />} />
            <Route path="/dashboard" element={<Layout />} />

            <Route path="/LandingScreen" element={<LandingScreen />} />

            <Route path="/Programs" element={<ProgramsLayout />} />
            <Route path="/ProgramsInfo" element={<ProgramsInfoLayout />} />
            <Route path="/ManageBatch" element={<ManageBatchLayout />} />
            <Route path="/Content" element={<ContentLayout />} />
            <Route path="/Faculty" element={<FacultyLayout />} />
            <Route path="/Grading" element={<GradingLayout />} />
            <Route path="/Feedback" element={<FeedbackLayout />} />
            <Route path="/complaints" element={<ComplentsLayout />} />
            <Route path="/complaints/complaints" element={<PreLoginComplaints />} />
            <Route path="/Reports" element={<ReportsLayout />} />
            <Route path="/UserStats" element={<UserStatsLayout />} />
            <Route path="/users" element={<UsersLayout />} />
            <Route path="/courseView" element={<CourseViewLayout />} />
            <Route path="/emailtemplate" element={<EmailTempLayout />} />
            <Route path="/discussion" element={<DiscussionLayout />} />
            <Route path="/announcement" element={<AnnouncementLayout />} />
            <Route path="/events" element={<EventsLayout />} />
            <Route path="/userprogress" element={<UserProgressLayout />} />
            <Route path="/gradingdetails" element={<GradingDetailsLayout />} />
            <Route
              path="/applicationdetails"
              element={<ApplicationDetailsLayout />}
            />
            <Route
              path="/jssapplicationdetails"
              element={<JssApplicationDetailsLayout />}
            />
            <Route path="/courseCreation" element={<CourseCreationLayout />} />
            <Route path="/createQuiz" element={<CreateQuiz />} />
            <Route
              path="/assesmentdetails"
              element={<AssesmentDetailsLayout />}
            />
            <Route
              path="/assignmentdetails"
              element={<AssignmentDetailsLayout />}
            />

            <Route path="/projectdetails" element={<ProjectDetailsLayout />} />
            <Route
              path="/projectgradingdetails"
              element={<ProjectGradingLayout />}
            />
            <Route path="/course" element={<CourseLayout />} />

            <Route path="/application" element={<ApplicationLayout />} />

            <Route
              path="/reportsAndAnalytics"
              element={<ReportsAndAnalyticsLayout />}
            />

            {/* // <Route path="/myApplication/application" element={<Application />} /> */}
            <Route path="/allPrograms" element={<AllProgramsLayout />} />
            <Route path="/applyProgram" element={<AllProgramsInfoLayout />} />
            <Route
              path="/myApplication/application"
              element={<ApplicationLayout />}
            />
            <Route path="*" element={<LandingScreen />} />
          </>
        )}
      </Routes>
    </div>
  );
}

export default App;

// import React from 'react';

// import Layout from '../Layout';

// import './App.scss';

// function App() {
//   return (
//     <div>
//       <Layout />
//     </div>
//   );
// }

// export default App;
