// Dependencies imports
import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";

import { useNavigate } from "react-router-dom";

import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import Box from "@material-ui/core/Box";
import SearchIcon from "@material-ui/icons/Search";


// Local imports
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import UserHeader from "../Header/UserHeader/UserHeader";
import PreviousBatches from "./PreviousBatches";
import nothingHereAt from "../../assets/images/Nothing here yet.gif";
// styles imports
import Prog from "./Programs.module.scss";
// Internal Styles for the material UI components
const header = {
  color: "white",
  fontFamily: "nunito",
  fontSize: "15px",
  fontWeight: "400px",
};

const programnames = {
  color: Constants.main_color_1,
  fontFamily: "nunito",
  fontSize: "16px",
  fontWeight: "500px",
};


const useStyles = makeStyles({
  root: {
    "& .super-app-theme--header": {
      backgroundColor: Constants.main_color_1,
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
});

// Styles for Pagination
const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

// Pagination function
function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

// Start of Main Program components
const Programs = () => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [batches, setBatches] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchResults, setSearchResults] = useState(tableData);

  const classes = useStyles();
  let userDetails = useSelector(authData);

  let navigate = useNavigate();
  const dispatch = useDispatch();
  // Useeffect for api call
  useEffect(() => {
    getPrograms();
    return () => { };
  }, []);

  // API call syntax to get programs
  async function getPrograms() {
    setLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {

      const programs = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PROGRAMS,
        bodyParam
      );

      getBatches(programs);
    } catch (error) {
      console.error(error);
    }
  }

  // API call syntax to get batches
  async function getBatches(programs) {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        role: "admin",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    if (
      userDetails.role == "Instructors" ||
      userDetails.role == "Coordinators"
    ) {
      bodyParam.body.role = userDetails.role;
      bodyParam.body.eid = userDetails.eid;
    }
    try {

      const batches = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_BATCHES,
        bodyParam
      );
      MergeData(programs.programs, batches);
    
    } catch (error) {
      console.error(error);
    }
  }

  // Function to Merge two data from two api
  function MergeData(programs, batches) {
    let tempPrograms = [];
    for (let i = 0; i < programs.length; i++) {
      let data = [];
      let flag = 0;
      for (const [key, value] of Object.entries(batches)) {
        if (value.opid == programs[i].pid) {
          data.push(value);
          flag = 1;
        }
      }
      data.reverse();
      programs[i].batches = data;
      if (flag == 1) {
        tempPrograms.push(programs[i]);
      } else if (userDetails.role == "Admin") {
        tempPrograms.push(programs[i]);
      
      }
    }
    setTableData(tempPrograms);
    setSearchResults(tempPrograms);
    setLoading(false);

  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  function titleclick(data, PValue) {
    let sdata = { ...userDetails };

    sdata.curprgDetails = { ...data };
    sdata.curprgDetails.pid = data.pid;

    dispatch(awsSignIn(sdata));

    navigate("/ProgramsInfo");
  }

  function tableview() {
    return (
      <TableBody>

        {searchResults.length == 0 ? (<div style={{ height: "30rem" }}>
          <img
            src={nothingHereAt}
            alt="nothingHereAt"
            style={{
              position: "absolute",
              width: "30rem",
              left: "55%",
              transform: "translate(-50%, -50%)",
              top: "52%",
            }}
          />
        </div>) : (
          <>
          
          {(rowsPerPage > 0
          ? searchResults.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )
          : searchResults
        ).map((item) => {
          return (
            <TableRow>
              <TableCell style={{ width: "25%" }}>
                <img
                  className={Prog.image}
                  src={`https://${Constants.DOMAIN
                    }/${config.aws_org_id.toLowerCase()}-resources/images/program-images/${item.pid
                    }.png`}
                  alt=""
                ></img>
              </TableCell>
              <TableCell style={{ width: "50%" }}>
                <h3
                  onClick={() => titleclick(item)}
                  style={programnames}
                  className={Prog.titleclick}
                >
                  {item.pname}
                </h3>
              </TableCell>

              <TableCell style={{ width: "25%" }}>
                <button
                  className={Prog.batchbutton}
                  onClick={() => {
                    handleClickOpen();
                    setBatches(item.batches);
                  }}
                >
                  <h3 className={Prog.batchtitle}>View batches </h3>
                </button>
              </TableCell>
            </TableRow>
          );
        })}</>
         )}


      </TableBody>
    );
  }

  function loader() {
    let a = [1, 2, 3, 4];
    return (
      <TableBody>
        {a.map((item) => {
          return (
            <TableRow>
              <TableCell style={{ width: "25%" }}>
                <Skeleton variant="rect" width={210} height={118} />
              </TableCell>
              <TableCell style={{ width: "50%" }}>
                <Box pt={0.5}>
                  <Skeleton />
                  <Skeleton width="60%" />
                </Box>
              </TableCell>
              <TableCell style={{ width: "25%" }}>
                <Box pt={0.5}>
                  <Skeleton />
                  <Skeleton width="60%" />
                </Box>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^\w\s]/gi, "");
    const results = tableData.filter((item) =>
      item.pname
        .toLowerCase()
        .replace(/[^\w\s]/gi, "")
        .includes(sanitizedValue.toLowerCase())
    );
    setSearchResults(results);
  };
  // Main Component Return
  return (
    <div className={Prog.maincontainer}>
      <UserHeader />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <PreviousBatches handleClose={handleClose} batches={batches} />
        </Fade>
      </Modal>
      <div className={Prog.manage}>
        <h3 className={Prog.heading}>Manage programs</h3>
      </div>
      <div className={Prog.table}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ background: Constants.main_color_1 }}>
              <TableRow>
                <TableCell style={header}>Program Image</TableCell>
                <TableCell style={header}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2rem",
                    }}
                  >
                    <p>Program Name</p>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "250px",
                        border: "1px solid rgb(118, 118, 118)",
                        borderRadius: "5px",
                        padding: "4px 10px",
                        backgroundColor: "white",
                      }}
                    >
                      <input
                        type="text"
                        className={Prog.searchinput}
                        placeholder="Search"
                        onChange={(e) => {
                          handleInputChange(e);
                        }}
                        style={{
                          border: "none",
                          height: "fit-content",
                          padding: "4px 3px",
                          fontSize: "15px",
                          width: "200px",
                          outline: "none",
                          background: "transparent",
                        }}
                      ></input>
                      <SearchIcon
                        onClick={() => { }}
                        style={{ color: "#3372b5" }}
                      />
                    </div>
                  </div>
                </TableCell>

                <TableCell style={header}>Batches</TableCell>
              </TableRow>
            </TableHead>

            {loading ? loader() : tableview()}
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={3}
                  count={searchResults.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default Programs;
