// Dependencies imports
import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbarContainer, GridToolbar } from '@material-ui/data-grid';
import { API } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Skeleton from "@material-ui/lab/Skeleton";
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';


// Local imports
import { authData, awsSignIn } from "../../redux/auth/authSlice";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";



// Styles import
import Assesg from './AssesmentGrid.module.scss';


// Data for Faculty Grid
const columns = [
    {
        field: 'user',
        headerName: 'Username',
        width: 350,
        flex: 1,
        headerClassName: 'super-app-theme--header',
    },
    {
        field: 'application',
        headerName: 'Application ID',
        width: 350,
        flex: 1,
        headerClassName: 'super-app-theme--header',
    },
    {
        field: 'submitted',
        headerName: 'Submitted On',
        width: 250,
        flex: 1,
        headerClassName: 'super-app-theme--header',
    },
    {
        field: 'status',
        headerName: 'Application Status',
        width: 250,
        flex: 1,
        headerClassName: 'super-app-theme--header',
    }
];



const useStyles = makeStyles({
    root: {
        '& .super-app-theme--header': {
            backgroundColor: Constants.main_color_1,
            fontSize: "16px",
            color: 'rgb(255,255,255)',
            fontFamily: "nunito"
        },
    },
    root1: {
        position: "fixed",
        top: "50%",
        left: "50%",
        zIndex: "2000"
    },
    ".MuiDataGrid-root": {
        fontSize: "14px",
        fontFamily: "nunito",
        fontWeight: 400,
        color: "black",
        cursor: "pointer"
    }
});

// Start of Faculty Grid Component
const ApplicationGrid = (props) => {
    let { data, columns } = props;
    const [loading, setLoading] = useState(true);
    const [spin, setSpin] = useState(false);

    const classes = useStyles();
    let navigate = useNavigate();
    const dispatch = useDispatch();

    let userDetails = useSelector(authData);

    // Useeffect for Application api
    useEffect(() => {

        setLoading(false);

    }, []);

    function onClickOfPage(name, path) {
        let sdata = { ...userDetails };
        let temp = [...sdata.breadcrumb];

        temp[3] = {
            name: name,
            path: path
        };

        sdata.breadcrumb = temp;

        dispatch(awsSignIn(sdata));
    }

    // function for toolbar options above datagrid
    function CustomToolbar() {
        return (
            <GridToolbarContainer style={{ display: "flex", justifyContent: "flex-end" }}>
                <GridToolbar />
            </GridToolbarContainer>
        );
    }

    const handleCellClick = (param, event) => {
        console.log(param.row);
        let sdata = { ...userDetails };
        sdata.curapplication = { ...param.row };
        let temp = [...sdata.breadcrumb];

        temp[3] = {
            name: "Application Details",
            path: "/applicationdetails"
        };

        sdata.breadcrumb = temp;
        dispatch(awsSignIn(sdata));
        if (config.aws_org_id == 'JSSAHER') {
            navigate("/jssapplicationdetails");
        } else {
            navigate("/applicationdetails");
        }
    }

    async function generateApplicationReport() {
        setSpin(true);
        const bodyParam = {
            body: {
                oid: config.aws_org_id,
                bpid: userDetails.curprgcou.bpid,
                courseId: userDetails.curprgcou.opid,
                pname: userDetails.curprgcou.pname,
            },
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };

        try {
            console.log(bodyParam.body);
            const csv = await API.post(
                config.aws_cloud_logic_custom_name,
                "/generateApplicationReport",
                bodyParam
            );
            console.log("csv ", csv);
            // convertData(application.applications);
            exp(csv);
            setSpin(false);
        }
        catch (error) {
            console.error(error);
            setSpin(false);
        }
    }

    function exp(csv) {
        // let obj = {...rcount};
        // obj['Question'] = question;
        

        let blob = new Blob(["\ufeff" + csv], {
            type: "text/csv;charset=utf-8;",
        });
        let dwldLink = document.createElement("a");
        let url = URL.createObjectURL(blob);
        let isSafariBrowser =
            navigator.userAgent.indexOf("Safari") != -1 &&
            navigator.userAgent.indexOf("Chrome") == -1;
        if (isSafariBrowser) {
            //if Safari open in new window to save file with random filename.
            dwldLink.setAttribute("target", "_blank");
        }

        dwldLink.setAttribute("href", url);

        dwldLink.setAttribute("download", userDetails.curprgcou.bname+".csv");

        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
        console.log(csv)

    }

    return (
        <div className={Assesg.maindiv}>
            <div className={Assesg.topbar}>
                {spin ? null : <Button disabled={spin} variant="contained"
                    // color="#2e6db0"
                    style={{ backgroundColor: "#2e6db0", color: "white" }}
                    onClick={() => { generateApplicationReport() }} >Export CSV</Button>}
                {!spin ? null : <div style={{ position: "fixed", top: 0, left: 0, width: "100vw", height: "100vh", background: "#0d08085c" }}>
                    <CircularProgress className={classes.root1} style={{ color: Constants.main_color_1 }} /></div>}
            </div>
            {loading === false ?
                <Paper>
                    <div style={{ height: "600px", width: '100%', fontSize: "20px" }} className={classes.root}>
                        <DataGrid
                            rows={data}
                            columns={columns}
                            components={{ Toolbar: CustomToolbar }}
                            className={classes['.MuiDataGrid-root']}
                            onCellClick={handleCellClick}
                            disableSelectionOnClick
                        />
                    </div>
                </Paper> : <div style={{ display: "flex", justifyContent: "flexStart", alignItems: "center", paddingTop: "40px" }}><Skeleton variant="rect" width="100%" height={600} /> </div>}
        </div>
    );
}

export default ApplicationGrid;