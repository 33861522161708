// Dependencies imports
import React, { useState, useEffect } from "react";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import { API } from "aws-amplify";
import Ann from "./AnnouncementFile.module.scss";
import swal from "sweetalert";
import {
  CircularProgress,
  TextareaAutosize,
  Backdrop,
  Fade,
  Modal,
} from "@material-ui/core";
import { Tab } from "semantic-ui-react";
import assessStyle from "./Assessment.module.scss";
import Done from "../../assets/svgjs/Done";
import Reject from "../../assets/svgjs/Reject";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";

// Local imports
import { ReactComponent as Close } from "../../assets/svg/close_black_24dp.svg";

import { makeStyles } from "@material-ui/core/styles";
// Styles imports
import Miniassignmentfile from "./miniassignmentfile";
import Add from "./AddScore.module.scss";

const useStyles = makeStyles({
  root: {
    "& .super-app-theme--header": {
      backgroundColor: Constants.app_dark_color,
    },
  },
  root1: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
});
const AddScore = (props) => {
  let {
    handleClose,
    response,
    currentData,
    userDet,
    rowsdata,
    setRowsData,
    selData,
  } = props;
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(response.score);
  const [obj, setObj] = useState();
  const [spin, setSpin] = useState(false);
  const [scoreErr, setScoreErr] = useState("");
  const [scoreDisable, setScoreDisable] = useState(false);
  const [feedbackEnabled, setFeedbackEnabled] = useState({
    enabled: false,
    comments: "",
  });
  let userDetails = useSelector(authData);

  useEffect(() => {
    if (
      currentData.miniassignmenttype != undefined &&
      (currentData.miniassignmenttype == 1 ||
        currentData.miniassignmenttype == "1")
    ) {
      setScoreDisable(false);
    }
  });

  const [open, setOpen] = useState(false);
  const imgUrl = `https://${
    Constants.DOMAIN
  }/${config.aws_org_id.toLowerCase()}-resources/images/quiz-images/${userDetails?.grade?.tid}/`;

  const classes = useStyles();

  function answerPrev() {
    const prevQuestion = currentQuestion - 1;

    setCurrentQuestion(prevQuestion);
  }

  function answerNext() {
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < response.response.length) {
      setCurrentQuestion(nextQuestion);
    }
  }
  async function updatefeedback() {
    let userName;

    if (userDetails.role == "Admin") {
      userName = "Admin";
    } else {
      userName = userDetails.name;
    }
    if (feedbackEnabled.comments == "") {
      setScoreErr("feedback can't be empty!");
      return;
    } else {
      setSpin(true);
      const bodyParam = {
        body: {
          eid: userDet.eid,
          objid: userDet.qid,
          oid: config.aws_org_id,
          fdcomments: feedbackEnabled.comments,
          bcourseid: userDet.btid,
          cname: userDetails.grade.ttitle,
          uname: currentData.unit,
          mname: currentData.ntitle,
          bpid: userDet.bpid,
          userName: userName,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      try {
        await API.post(
          config.aws_cloud_logic_custom_name,
          `/updateAssignorMiniAssign`,
          bodyParam
        );
        handleClose();
        setSpin(false);
      } catch (error) {
        console.error(error);
      }
    }
  }
  async function gradeAssignments(score) {
    if (score == undefined || score == null || score == "" || score == " ") {
      setScoreErr("Score can't be empty!");
      return;
    } else if (score > 10) {
      setScoreErr("Score Must be less then 10");
      return;
    } else if (score < 0) {
      setScoreErr("Score Must be greater then 0");
      return;
    } else {
      setSpin(true);
      const bodyParam = {
        body: {
          eid: userDet.eid,
          obid: userDet.qid,
          oid: config.aws_org_id,
          score: score,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      try {
        await API.post(
          config.aws_cloud_logic_custom_name,
          `/gradeAssignments`,
          bodyParam
        );
        let data = rowsdata;
        for (let i = 0; i < rowsdata.length; i++) {
          if (rowsdata[i].eid == userDet.eid) {
            data[i].score = score;
          }
        }
        setRowsData(data);

        handleClose();
        setSpin(false);
      } catch (error) {
        swal({
          title: "Oooops!",
          text: "something went wrong!",
          icon: "warning",
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
        console.error(error);
      }
    }
  }
  const panes = [
    {
      menuItem: "Feedback",
      render: () => (
        <Tab.Pane>
          <TextareaAutosize
            style={{
              width: "100%",
              resize: "none",
              fontSize: "15px",
              lineHeight: "1.3",
              padding: "10px",
            }}
            minRows={8}
            placeholder="Type the feedback here..."
            defaultValue={feedbackEnabled.comments}
            onChange={(e) => (feedbackEnabled.comments = e.target.value)}
          />
        </Tab.Pane>
      ),
    },
  ];

  const panes1 = [
    {
      menuItem: "Answer By student",
      render: () => (
        <Tab.Pane>
          <div>
            <textarea
              value={
                response.resubmittedData == undefined ||
                response?.resubmittedData.length == 0
                  ? response.response[currentQuestion].response
                  : response.resubmittedData[currentQuestion].response
              }
              style={{
                width: "100%",
                padding: "15px",
                borderRadius: "6px",
                height: "100px",
                whiteSpace: "normal",
                border: "2px solid black",
                fontSize: "1.4rem",
                lineHeight: "2rem",
              }}
            />

            <div>
              {response.resubmittedData != undefined &&
              response.resubmittedData[currentQuestion].filename !==
                undefined &&
              response.resubmittedData[currentQuestion].filename !== "" ? (
                <div>
                  <p
                    className={Ann.link}
                    style={{
                      color: "#007bff",
                      cursor: "pointer",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      addLink(response.resubmittedData[currentQuestion]);
                    }}
                  >
                    {response?.resubmittedData[currentQuestion]?.filename
                      ?.length > 18
                      ? response.resubmittedData[
                          currentQuestion
                        ].filename.substr(13)
                      : response.resubmittedData[currentQuestion].filename}
                  </p>
                </div>
              ) : (
                <>
                  {response.response[currentQuestion].filename !== undefined &&
                  response.response[currentQuestion].filename !== "" ? (
                    <div>
                      <p
                        className={Ann.link}
                        style={{
                          color: "#007bff",
                          cursor: "pointer",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                        onClick={() => {
                          addLink(response.response[currentQuestion]);
                        }}
                      >
                        {response?.response[currentQuestion]?.filename?.length >
                        18
                          ? response.response[currentQuestion].filename.substr(
                              13
                            )
                          : response.response[currentQuestion].filename}
                      </p>
                    </div>
                  ) : null}
                </>
              )}
            </div>
            <div>
              {response.resubmittedData != undefined &&
              response.resubmittedData[currentQuestion].ourl !== undefined ? (
                <a
                  href={response.resubmittedData[currentQuestion].ourl}
                  target="blank"
                  style={{ fontSize: "14" }}
                >
                  Click here to view in new tab
                </a>
              ) : (
                <>
                  {response.response[currentQuestion].ourl !== undefined ? (
                    <a
                      href={response.response[currentQuestion].ourl}
                      target="blank"
                      style={{ fontSize: "14" }}
                    >
                      Click here to view in new tab
                    </a>
                  ) : null}
                </>
              )}
            </div>
            <div>
              {currentQuestion > 0 ? (
                <button className={Add.prev1} onClick={() => answerPrev()}>
                  Prev
                </button>
              ) : null}

              {currentQuestion + 1 === response.response.length ? null : (
                <button className={Add.next1} onClick={() => answerNext()}>
                  Next
                </button>
              )}
            </div>
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Feedback",
      render: () => (
        <Tab.Pane>
          <div>
            {response.feedback != undefined ? (
              <div>
                {response.feedback.map((feedback) => (
                  <div>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <div>
                            <p
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                                marginBottom: "9px",
                                color: "#333333",
                                overflow: "auto",
                              }}
                            >
                              {feedback.givenby}
                            </p>

                            <p
                              style={{
                                fontWeight: "500",
                                marginBottom: "9px",
                                color: "#333333",
                                overflow: "auto",
                                whiteSpace: "pre-line",
                                fontSize: "1.4rem",
                                lineHeight: "2rem",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: feedback.message,
                              }}
                            ></p>
                          </div>
                        }
                        secondary={
                          <div>
                            <div style={{ fontSize: "12px" }}>
                              {feedback.timeStamp}
                            </div>
                          </div>
                        }
                      />
                    </ListItem>
                    <hr />
                  </div>
                ))}
              </div>
            ) : null}
          </div>

          <TextareaAutosize
            style={{
              width: "100%",
              resize: "none",
              fontSize: "15px",
              lineHeight: "1.3",
              padding: "10px",
            }}
            minRows={6}
            placeholder="Type the feedback here..."
            defaultValue={feedbackEnabled.comments}
            onChange={(e) => (feedbackEnabled.comments = e.target.value)}
          />

          <button
            disabled={scoreDisable}
            onClick={() => updatefeedback()}
            className={Add.fdsubmit}
          >
            Submit Feedback
          </button>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Resubmitted Answer",
      render: () => (
        <Tab.Pane>
          {response.resubmittedData != undefined ? (
            <div>
              <textarea
                value={response.response[currentQuestion].response}
                style={{
                  width: "100%",
                  padding: "15px",
                  borderRadius: "6px",
                  height: "100px",
                  whiteSpace: "normal",
                  border: "2px solid black",
                  fontSize: "1.4rem",
                  lineHeight: "2rem",
                }}
              />

              <div>
                {response.response[currentQuestion].filename !== undefined &&
                response.response[currentQuestion].filename !== "" ? (
                  <div>
                    <p
                      className={Ann.link}
                      style={{
                        color: "#007bff",
                        cursor: "pointer",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                      onClick={() => {
                        addLink(response.response[currentQuestion]);
                      }}
                    >
                      {response?.response[currentQuestion]?.filename?.length >
                      18
                        ? response.response[currentQuestion].filename.substr(13)
                        : response.response[currentQuestion].filename}
                    </p>
                  </div>
                ) : null}
              </div>
              <div>
                {response.response[currentQuestion].ourl !== undefined ? (
                  <a
                    href={response.response[currentQuestion].ourl}
                    target="blank"
                    style={{ fontSize: "14" }}
                  >
                    Click here to view in new tab
                  </a>
                ) : null}
              </div>
              <div>
                {currentQuestion > 0 ? (
                  <button className={Add.prev1} onClick={() => answerPrev()}>
                    Prev
                  </button>
                ) : null}

                {currentQuestion + 1 === response.response.length ? null : (
                  <button className={Add.next1} onClick={() => answerNext()}>
                    Next
                  </button>
                )}
              </div>
            </div>
          ) : (
            <>No DATA</>
          )}
        </Tab.Pane>
      ),
    },
  ];
  function handleChange(e) {
    if (e.target.value > 10) return false;
    if (e.target.value < 0) return false;
    setScore(e.target.value);
  }
  function addLink(item) {
    setObj(item);
    handleFileOpen();
  }
  const handleFileOpen = () => {
    setOpen(true);
  };

  function viewAns(viewQ) {
    if (viewQ.iid !== undefined && viewQ.response !== undefined) {
      if (viewQ.atype == 4) {
        return (
          <img
            style={{ marginLeft: "10%" }}
            src={`${imgUrl}${viewQ.iopts[viewQ.response].url}`}
            alt="item"
          />
        );
      } else {
        return viewQ.iopts[viewQ.response].content;
      }
    } else {
      return <div style={{ color: "red" }}> You have not answerd </div>;
    }
  }

  const handleFileClose = () => {
    setOpen(false);
  };
  return (
    <div>
      {spin === false ? (
        <div className={Add.mainpopup}>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <Miniassignmentfile handleFileClose={handleFileClose} obj={obj} />
            </Fade>
          </Modal>

          <div
            style={{
              position: "fixed",
              width: "71%",
              backgroundColor: "white",
              zIndex: "999",
            }}
          >
            <div className={Add.iconholder}>
              <Close onClick={handleClose} className={Add.icon} />
            </div>
            <h3 className={Add.header}>Add Score</h3>

            <div
              style={{
                color: "#cc0000",
                marginBottom: "12px",
                marginTop: "0px",
                textAlign: "start",
                marginLeft: "40%",
                fontSize: "14px",
              }}
            >
              {scoreErr}
            </div>
            <div className={Add.overholder}>
              <div style={{ display: "flex", gap: "4rem" }}>
                <div style={{ textAlign: "left" }}>
                  <h4>Name</h4>
                  <h4>Email ID</h4>
                  <h4>Candiate id</h4>
                </div>
                <div
                  style={{
                    textAlign: "left",

                    "word-wrap": "break-word",
                  }}
                >
                  <h4>{selData.UNAME}</h4>
                  <h4>{selData.EMAILID}</h4>
                  <h4>{selData.USN}</h4>
                </div>
              </div>

              <div
                style={{ display: "flex", gap: "2rem", alignItems: "center" }}
              >
                <h4 className={Add.over}>Overall Score: </h4>

                <input
                  disabled={scoreDisable}
                  value={score}
                  type="number"
                  min="1"
                  max="10"
                  onChange={(e) => handleChange(e)}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      gradeAssignments(score);
                    }
                  }}
                  className={Add.overinput}
                ></input>
              </div>

              <button
                disabled={scoreDisable}
                onClick={() => gradeAssignments(score)}
                className={Add.submit}
              >
                Submit
              </button>
            </div>
          </div>
          <div className={Add.questionsection} style={{ marginTop: "15rem" }}>
            <div className={Add.questioncount}>
              <span className={Add.question}>
                Question {currentQuestion + 1}
              </span>
              /{response.response.length}
            </div>
            <div className={Add.questiontext}>
              <p
                style={{ whiteSpace: "pre-line" }}
                dangerouslySetInnerHTML={{
                  __html: response.response[currentQuestion].istem,
                }}
              ></p>
            </div>

            {response.response[currentQuestion].imageurl ? (
              <div className={Add.imagecontainer}>
                <img
                  src={`${imgUrl}${response.response[currentQuestion].imageurl}`}
                  alt="item"
                />
              </div>
            ) : null}
          </div>

          <div className={Add.areaholder}>
            {response.response[currentQuestion].iopts == undefined ? (
              <>
                <div className="tabclass">
                  <Tab panes={panes1} />
                </div>
              </>
            ) : (
              <>
                <div className={assessStyle.answersection}>
                  <div className={"btn-group " + assessStyle.btngroupvertical}>
                    {response.response[currentQuestion].iopts.map(
                      (answerOption) => (
                        <>
                          <div style={{}}>
                            <div>
                              <div style={{ display: "flex" }}>
                                {response.response[currentQuestion].atype ==
                                4 ? (
                                  <>
                                    <div
                                      style={{
                                        width: " -webkit-fill-available",
                                      }}
                                    >
                                      <label
                                        className="buttonQuiz"
                                        for={answerOption.content}
                                      >
                                        <img
                                          src={`${imgUrl}${answerOption.url}`}
                                          alt="item"
                                        />
                                      </label>
                                    </div>
                                  </>
                                ) : (
                                  <label
                                    className={
                                      assessStyle.labelstyleAns +
                                      " " +
                                      assessStyle.buttonQuizAns
                                    }
                                    for={answerOption.content}
                                  >
                                    {answerOption.content}
                                  </label>
                                )}

                                <span>
                                  {answerOption.correct === "true" ||
                                  answerOption.correct === true ? (
                                    <Done
                                      className="right_size"
                                      cls1="cls1_D"
                                      cls2="cls2_D"
                                    />
                                  ) : (
                                    <Reject
                                      className="Wrong_size"
                                      cls1="cls1_D"
                                      cls2="cls2_D"
                                    />
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    )}
                  </div>
                </div>
                <br />

                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    display: "flex",
                  }}
                >
                  Your Answer :{" "}
                  <div style={{ fontSize: "14px", fontWeight: "700" }}>
                    {viewAns(response.response[currentQuestion])}
                  </div>
                  <div>
                  {currentQuestion > 0 ? (
                    <button className={Add.prev1} onClick={() => answerPrev()}>
                      Prev
                    </button>
                  ) : null}
                  {currentQuestion + 1 === response.response.length ? null : (
                    <button className={Add.next1} onClick={() => answerNext()}>
                      Next
                    </button>
                  )}
                </div>
                </div>
              </>
            )}
          </div>

          {feedbackEnabled.enabled && (
            <div style={{ padding: "10px" }} className="tabclass">
              <Tab panes={panes} style={{ marginTop: "20px" }} />
              <button
                disabled={scoreDisable}
                onClick={() => updatefeedback()}
                className={Add.fdsubmit}
              >
                Submit Feedback
              </button>
            </div>
          )}
        </div>
      ) : (
        <div className={classes.root}>
          <CircularProgress style={{ color: Constants.main_color_1 }} />
        </div>
      )}
    </div>
  );
};

export default AddScore;
