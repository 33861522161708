import React, { useState, useCallback } from "react";
import "./AssignmentAndAssessment.scss";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { produce } from "immer";
import { generate } from "shortid";
import FormGroup from "@material-ui/core/FormGroup";
import axios from "axios";
import assessStyle from "./AssessmentAndAssignmentView/Assessment.module.scss";
import Checkbox from "@material-ui/core/Checkbox";
import { Image } from "semantic-ui-react";
import { intersection } from "lodash";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
  TextareaAutosize,
} from "@material-ui/core";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import { API, JS } from "aws-amplify";
import AssessmentAndAssignmentList from "./AssessmentAndAssignmentList/AssessmentAndAssignmentList";
import { Skeleton } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector, useDispatch } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
const CustomButton = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "6px 15px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: "#01498e",
    borderColor: "#fff",
    color: "#fff",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      backgroundColor: "#01498e",
      border: "1px solid #fff",
      color: "#fff",
      boxShadow: "#fff",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#fff",
      borderColor: "#01498e",
    },
    "&:focus": {
      //   boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  },
})(Button);
const BlueRadio = withStyles({
  root: {
    color: "#01498e",
    "&$checked": {
      color: "#01498e",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
const useStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  title: {
    textTransform: "capitalize",
    textAlign: "center",
    fontSize: "2.5rem",
  },
  root1: {
    flexGrow: 1,
    "& .MuiTypography-body1": {
      flexGrow: "1",
    },
    "& .MuiFormControlLabel-root": {
      marginRight: "0",
    },
  },
  formGroupRoot: {
    "& .MuiFormGroup-root": {
      display: "flex !important",
    },
  },
  displayFlex: {
    display: "flex",
    alignItems: "center",
    gap: "2rem",
    width: "100%",
    justifyContent: "center",
  },
  flexBasis: {
    flexBasis: "15%",
  },
  textCenter: {
    textAlign: "center",
  },
  paper: {
    "& .MuiDialog-paperWidthSm": {
      minWidth: "800px",
      padding: "30px 80px",
    },
  },
  container: {
    display: "flex",
    gap: "2rem",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  quizButtonsContainer: {
    display: "flex",
    gap: "2rem",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "30px",
  },
  input: {
    display: "none",
  },
  uploadBtn: {
    color: "#fff",
    background: "#01498e",
    padding: ".5rem 5rem",
    fontSize: "1.4rem",
    "&:hover": {
      backgroundColor: "#01498e",
      color: "#fff",
      boxShadow: "#fff",
    },
  },
  fontSize: {},
  small: {
    fontSize: "1rem",
  },
}));

const initialValidation = {
  error1: false,
  error2: false,
  error3: false,
  error4: false,
  error5: false,
  error6: false,
  error7: false,
  error8: false,
  error9: false,
  error09: false,
};
const initialState = {
  opt1: false,
  opt2: false,
  opt3: false,
  opt4: false,
  opt5: false,
  opt6: false,
};
const initialValues = [
  { content: "" },
  { content: "" },
  { content: "" },
  { content: "" },
  { content: "" },
  { content: "" },
];
const initialMatchTheFollowing = [
  { value1: "", value2: "" },
  { value1: "", value2: "" },
  { value1: "", value2: "" },
  { id: generate(), value1: "", value2: "" },
];
export default function AssignmentAndAssessment({ type, courseId, subject }) {
  const [open, setOpen] = React.useState(false);
  const [firstView, setFirstView] = useState(true);
  const [scroll, setScroll] = React.useState("paper");
  const classes = useStyle();
  const [firstViewData, setFirstViewData] = useState({
    subject: subject,
    title: "",
    date: "",
    sTime: "",
    eTime: "",
    ansDate: "",
    ansTime: "",
    dTAns: 0,
    nowDate: "",
  });
  const [tFOptions, setTFOptions] = useState([
    { content: "True" },
    { content: "False" },
  ]);
  const [progress, setProgress] = useState(false);
  const [success, setSuccess] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [matchTheFollowing, setMatchTheFollowing] = useState(
    initialMatchTheFollowing
  );
  const [questionData, setQuestionData] = useState([]);
  const [questionObj, setQuestionObj] = useState({ currentQ: 0 });
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [quizType, setQuizType] = useState("");
  const [value, setValue] = useState("");
  const [aType, setAType] = useState("");
  let userDetails = useSelector(authData);
  const [state, setState] = useState(initialState);
  const [studentsList, setStudentList] = useState([]);
  const [firstViewValidation, setFirstViewValidation] =
    useState(initialValidation);

  const [defaultValidation, setDefaultValidation] = useState(initialValidation);
  const [multiSelectValidation, setMultiSelectValidation] =
    useState(initialValidation);
  const [options, setOptions] = useState(initialValues);
  const [questionValidation, setQuestionValidation] =
    useState(initialValidation);
  const [singleSelectImageBase, setSingleSelectImageBase] =
    useState(initialValidation);
  const [multiSelectImageBase, setMultiSelectImageBase] =
    useState(initialValidation);
  const [instructions, setInstructions] = useState([{ value: "" }]);
  const [listLoader, setListLoader] = useState(true);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [checked, setChecked] = React.useState([0]);

  const [assessAndAssignList, setAssessAndAssignList] = useState([]);
  const [previousbtn, setPreviousBtn] = useState(false);
  const [imgUrl, setImgUrl] = React.useState("");
  const [maxDate, setMaxDate] = useState("");
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
    setSuccess(false);
    resetForm();
    setQuestionData([]);
  };
  const resetForm = () => {
    setValue("");
    setQuizType("");
    setState(initialState);
    setInstructions([{ value: "" }]);
    setFirstViewData({
      subject: subject,
      title: "",
      date: "",
      sTime: "",
      eTime: "",
      ansDate: "",
      ansTime: "",
      dTAns: 0,
      nowDate: "",
    });
    setFirstView(true);
    setQuestionObj({ currentQ: 0 });
    setDefaultValidation(initialValidation);
  };
  const handleClose = () => {
    setOpen(false);
    resetForm();
    setPreviousBtn(false);
  };
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    var dtToday = new Date();

    var month = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();

    var maxDate = year + "-" + month + "-" + day;
    setMaxDate(maxDate);
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    getAssessment();
    getstudents();
    setListLoader(true);
  }, [open, type]);

  async function getstudents() {
    // setLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        bpid: userDetails.curprgcou.bpid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const users = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PROGRAM_BATCH_REPORT,
        bodyParam
      );

      console.log("users", users);

      const list = users.map(({ EMAILID, UNAME, UR }) => ({
        EMAILID,
        UNAME,
        UR,
      }));

      const filterStudents = list.filter((listnew) => listnew.UR === "User");

      console.log("filterByStudents", filterStudents);

      setStudentList(filterStudents);
      console.log("StudentList", studentsList);
    } catch (error) {
      console.error(error);
    }
  }

  const onFileChange = (event, qidx, oidx) => {
    setSelectedFile({ file: event.target.files[0] });
    var uniqid = new Date().getTime();
    var imtype = event.target.files[0].name.slice(
      (Math.max(0, event.target.files[0].name.lastIndexOf(".")) || Infinity) + 1
    );
    let imageName = `img${uniqid}.${imtype}`;
    setQuestionData((currentFile) =>
      produce(currentFile, (draft) => {
        draft[qidx].img = imageName;
      })
    );
    imageUpload(event.target.files[0], imageName);
  };
  const onSImageFileChange = (event, qidx, oidx) => {
    // alert(qidx);
    // alert(oidx);
    // console.log(event.target);
    var uniqid = new Date().getTime();
    var imtype = event.target.files[0].name.slice(
      (Math.max(0, event.target.files[0].name.lastIndexOf(".")) || Infinity) + 1
    );
    let imageName = `img${uniqid}.${imtype}`;
    setQuestionData((currentFile) =>
      produce(currentFile, (draft) => {
        draft[qidx].iopts[oidx].url = imageName;
        draft[qidx].iopts[oidx].img = event.target.files[0].name;
      })
    );
    imageUpload(event.target.files[0], imageName);
  };
  const imageUpload = async (file, name) => {
    const bodyParam = {
      body: {
        filetype: file.type,
        filename: name,
        oid: config.aws_org_id,
        type: "assessmentsimage",
        crid: courseId,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    // alert("hii"+JSON.stringify(bodyParam.body))
    try {
      console.log(JSON.stringify(bodyParam.body, null, 2));
      const preSignedData = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PRESIGNED_URL,
        bodyParam
      );

      axios
        .put(preSignedData, file, { headers: { "Content-Type": file.type } })
        .then((response) => {
          console.log("success");
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } catch (error) {
      // alert(JSON.stringify(error));
      console.error(error);
    }
  };

  const getAssessment = async () => {
    const bodyParam = {
      body: {
        assessid: courseId,
        type: "assessmentlist",
        quiztype: type == "Assessment" ? "assess" : "assign",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(JSON.stringify(bodyParam.body, null, 2));
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_ASSESMENT,
        bodyParam
      );
      if (response === null || response === undefined || response === "") {
        setAssessAndAssignList([]);
      } else {
        let { assessment } = response;
        console.log(assessment);
        setAssessAndAssignList(assessment);
      }
      setListLoader(false);
    } catch (error) {
      // alert(JSON.stringify(error));
      console.error(error);
    }
  };
  const handleInputChange = (e, oidx, qidx) => {
    const { name, value } = e.target;
    setQuestionData((currentoptions) =>
      produce(currentoptions, (v) => {
        v[qidx].iopts[oidx].content = value;
      })
    );
  };
  const handleChange = (event, oidx, qidx, qztype) => {
    setValue(event.target.value);

    setQuestionData((option) =>
      produce(option, (v) => {
        v[qidx].iopts.forEach((opts) => {
          opts.correct = false;
        });
      })
    );

    setQuestionData((option) =>
      produce(option, (v) => {
        v[qidx].iopts[oidx].correct = true;
      })
    );
    setQuestionData((option) =>
      produce(option, (v) => {
        v[qidx].atype = qztype;
      })
    );
  };
  const handleOptionChange = (event, qidx) => {
    setValue(event.target.value);

    setQuestionData((option) =>
      produce(option, (v) => {
        v[qidx].iopts.forEach((opts) => {
          opts.correct = false;
        });
      })
    );
    const correctoptionvalue = event.target.value;
    // console.log(correctoptionvalue);
    setQuestionData((option) =>
      produce(option, (v) => {
        v[qidx].iopts[correctoptionvalue].correct = true;
      })
    );
    setQuestionData((option) =>
      produce(option, (v) => {
        v[qidx].correctoption = correctoptionvalue;
      })
    );
  };
  const handleImageOptionChange = (event, qidx) => {
    setValue(event.target.value);

    setQuestionData((option) =>
      produce(option, (v) => {
        v[qidx].iopts.forEach((opts) => {
          opts.correct = false;
        });
      })
    );
    const correctoptionvalue = event.target.value;
    // console.log(correctoptionvalue);
    setQuestionData((option) =>
      produce(option, (v) => {
        v[qidx].iopts[correctoptionvalue].correct = true;
      })
    );
    setQuestionData((option) =>
      produce(option, (v) => {
        v[qidx].correctoption = correctoptionvalue;
      })
    );
  };
  const handleMultiSelectChange = (event, qidx) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const addInstruction = (ins) => {
    if (quizType !== "6") {
      setInstructions((currentInstruction) => [
        ...currentInstruction,
        {
          id: generate(),
          value: "",
        },
      ]);
    } else {
      setMatchTheFollowing((currentInstruction) => [
        ...currentInstruction,
        {
          id: generate(),
          value1: "",
          value2: "",
        },
      ]);
    }
  };
  const addQuestion = (question, qidx, save, qztype) => {
    setDefaultValidation(initialValidation);
    // alert(qztype);
    console.log("questionData", questionData);
    let qdata = [...questionData];
    if (qztype === undefined || qztype === "" || qztype == null) {
      setDefaultValidation({ error1: true });
      return;
    } else {
      setDefaultValidation({ error1: false });
    }

    if (
      question.istem === undefined ||
      question.istem === "" ||
      question.istem == null
    ) {
      if (qztype !== "6") {
        setDefaultValidation({ error2: true });
        return;
      } else {
        setDefaultValidation({ error2: false });
      }
    }

    if (
      question.qscore == undefined ||
      question.qscore == "" ||
      question.qscore == null
    ) {
      setDefaultValidation({ error3: true });
      return;
    } else {
      setDefaultValidation({ error3: false });
    }

    if (qztype == 2 || qztype == 6) {
      setQuestionValidation(initialValidation);

      if (
        question.iopts[0].content == "" ||
        question.iopts[0].content == undefined ||
        question.iopts[0].content.replace(/\s/g, "").length === 0 ||
        question.iopts[1].content == "" ||
        question.iopts[1].content == undefined ||
        question.iopts[1].content.replace(/\s/g, "").length === 0
      ) {
        setQuestionValidation({ error2: true });
        return;
      } else {
        setQuestionValidation({ error2: false });
      }
      if (question.correctoption == undefined) {
        setQuestionValidation({ error3: true });
        return;
      } else {
        setQuestionValidation({ error3: false });
      }
      let filterOps = question.iopts.filter((opts) => opts.correct === true);
      if (filterOps[0].content === "" || filterOps[0].content.length === 0) {
        setQuestionValidation({ error4: true });
        return;
      } else {
        setQuestionValidation({ error4: false });
      }

      if (
        question.iopts[2].content != "" &&
        question.iopts[2].content != undefined
      ) {
        if (question.iopts[2].content.replace(/\s/g, "").length === 0) {
          setQuestionValidation({ error5: true });
          return;
        } else {
          setQuestionValidation({ error5: false });
        }
        if (
          question.iopts[2].content == question.iopts[3].content ||
          question.iopts[2].content == question.iopts[4].content ||
          question.iopts[2].content == question.iopts[5].content
        ) {
          setQuestionValidation({ error8: true });
          return;
        } else {
          setQuestionValidation({ error8: false });
        }
      }

      if (
        question.iopts[3].content != "" &&
        question.iopts[3].content != undefined
      ) {
        if (question.iopts[3].content.replace(/\s/g, "").length === 0) {
          setQuestionValidation({ error5: true });
          return;
        } else {
          setQuestionValidation({ error5: false });
        }
        if (
          question.iopts[2].content == undefined ||
          question.iopts[2].content.replace(/\s/g, "").length === 0
        ) {
          setQuestionValidation({ error6: true });
          return;
        } else {
          setQuestionValidation({ error6: false });
        }
        if (
          question.iopts[3].content == question.iopts[4].content ||
          question.iopts[3].content == question.iopts[5].content
        ) {
          setQuestionValidation({ error8: true });
          return;
        } else {
          setQuestionValidation({ error8: false });
        }
      }

      if (
        question.iopts[4].content != "" &&
        question.iopts[4].content != undefined
      ) {
        if (question.iopts[4].content.replace(/\s/g, "").length === 0) {
          setQuestionValidation({ error5: true });
          return;
        } else {
          setQuestionValidation({ error5: false });
        }
        if (
          question.iopts[2].content == undefined ||
          question.iopts[2].content.replace(/\s/g, "").length === 0 ||
          question.iopts[3].content == undefined ||
          question.iopts[3].content.replace(/\s/g, "").length === 0
        ) {
          setQuestionValidation({ error6: true });
          return;
        } else {
          setQuestionValidation({ error6: false });
        }
        if (question.iopts[4].content == question.iopts[5].content) {
          setQuestionValidation({ error8: true });
          return;
        } else {
          setQuestionValidation({ error8: false });
        }
      }

      if (
        question.iopts[5].content != "" &&
        question.iopts[5].content != undefined
      ) {
        if (question.iopts[5].content.replace(/\s/g, "").length === 0) {
          setQuestionValidation({ error5: true });
          return;
        } else {
          setQuestionValidation({ error5: false });
        }
        if (
          question.iopts[2].content == undefined ||
          question.iopts[2].content.replace(/\s/g, "").length === 0 ||
          question.iopts[3].content == undefined ||
          question.iopts[3].content.replace(/\s/g, "").length === 0 ||
          question.iopts[4].content == undefined ||
          question.iopts[4].content.replace(/\s/g, "").length === 0
        ) {
          setQuestionValidation({ error6: true });
          return;
        } else {
          setQuestionValidation({ error6: false });
        }
      }
      if (
        question.iopts[0].content == question.iopts[1].content ||
        question.iopts[0].content == question.iopts[2].content ||
        question.iopts[0].content == question.iopts[3].content ||
        question.iopts[0].content == question.iopts[4].content ||
        question.iopts[0].content == question.iopts[5].content
      ) {
        setQuestionValidation({ error8: true });
        return;
      } else {
        setQuestionValidation({ error8: false });
      }

      if (
        question.iopts[1].content == question.iopts[2].content ||
        question.iopts[1].content == question.iopts[3].content ||
        question.iopts[1].content == question.iopts[4].content ||
        question.iopts[1].content == question.iopts[5].content
      ) {
        setQuestionValidation({ error8: true });
        return;
      } else {
        setQuestionValidation({ error8: false });
      }
    }

    if (qztype == 3) {
      setMultiSelectValidation(initialValidation);

      if (
        question.iopts[0].content == "" ||
        question.iopts[0].content == undefined ||
        question.iopts[0].content.replace(/\s/g, "").length === 0 ||
        question.iopts[1].content == "" ||
        question.iopts[1].content == undefined ||
        question.iopts[1].content.replace(/\s/g, "").length === 0 ||
        question.iopts[2].content == "" ||
        question.iopts[2].content == undefined ||
        question.iopts[2].content.replace(/\s/g, "").length === 0
      ) {
        setMultiSelectValidation({ error2: true });
        return;
      } else {
        setMultiSelectValidation({ error2: false });
      }

      if (
        question.iopts[2].content != "" &&
        question.iopts[2].content != undefined
      ) {
        if (question.iopts[2].content.replace(/\s/g, "").length === 0) {
          setMultiSelectValidation({ error5: true });
          return;
        } else {
          setMultiSelectValidation({ error5: false });
        }
        if (
          question.iopts[2].content == question.iopts[3].content ||
          question.iopts[2].content == question.iopts[4].content ||
          question.iopts[2].content == question.iopts[5].content
        ) {
          setMultiSelectValidation({ error8: true });
          return;
        } else {
          setMultiSelectValidation({ error8: false });
        }
      }

      if (
        question.iopts[3].content != "" &&
        question.iopts[3].content != undefined
      ) {
        if (question.iopts[3].content.replace(/\s/g, "").length === 0) {
          setMultiSelectValidation({ error5: true });
          return;
        } else {
          setMultiSelectValidation({ error5: false });
        }
        if (
          question.iopts[2].content == undefined ||
          question.iopts[2].content.replace(/\s/g, "").length === 0
        ) {
          setMultiSelectValidation({ error6: true });
          return;
        } else {
          setMultiSelectValidation({ error6: false });
        }
        if (
          question.iopts[3].content == question.iopts[4].content ||
          question.iopts[3].content == question.iopts[5].content
        ) {
          setMultiSelectValidation({ error8: true });
          return;
        } else {
          setMultiSelectValidation({ error8: false });
        }
      }

      if (
        question.iopts[4].content != "" &&
        question.iopts[4].content != undefined
      ) {
        if (question.iopts[4].content.replace(/\s/g, "").length === 0) {
          setMultiSelectValidation({ error5: true });
          return;
        } else {
          setMultiSelectValidation({ error5: false });
        }
        if (
          question.iopts[2].content == undefined ||
          question.iopts[2].content.replace(/\s/g, "").length === 0 ||
          question.iopts[3].content == undefined ||
          question.iopts[3].content.replace(/\s/g, "").length === 0
        ) {
          setMultiSelectValidation({ error6: true });
          return;
        } else {
          setMultiSelectValidation({ error6: false });
        }
        if (question.iopts[4].content == question.iopts[5].content) {
          setMultiSelectValidation({ error8: true });
          return;
        } else {
          setMultiSelectValidation({ error8: false });
        }
      }

      if (
        question.iopts[5].content != "" &&
        question.iopts[5].content != undefined
      ) {
        if (question.iopts[5].content.replace(/\s/g, "").length === 0) {
          setMultiSelectValidation({ error5: true });
          return;
        } else {
          setMultiSelectValidation({ error5: false });
        }
        if (
          question.iopts[2].content == undefined ||
          question.iopts[2].content.replace(/\s/g, "").length === 0 ||
          question.iopts[3].content == undefined ||
          question.iopts[3].content.replace(/\s/g, "").length === 0 ||
          question.iopts[4].content == undefined ||
          question.iopts[4].content.replace(/\s/g, "").length === 0
        ) {
          setMultiSelectValidation({ error6: true });
          return;
        } else {
          setMultiSelectValidation({ error6: false });
        }
      }
      if (
        question.iopts[0].content == question.iopts[1].content ||
        question.iopts[0].content == question.iopts[2].content ||
        question.iopts[0].content == question.iopts[3].content ||
        question.iopts[0].content == question.iopts[4].content ||
        question.iopts[0].content == question.iopts[5].content
      ) {
        setMultiSelectValidation({ error8: true });
        return;
      } else {
        setMultiSelectValidation({ error8: false });
      }

      if (
        question.iopts[1].content == question.iopts[2].content ||
        question.iopts[1].content == question.iopts[3].content ||
        question.iopts[1].content == question.iopts[4].content ||
        question.iopts[1].content == question.iopts[5].content
      ) {
        setMultiSelectValidation({ error8: true });
        return;
      } else {
        setMultiSelectValidation({ error8: false });
      }

      let tempoptionsiopts = [];
      let tempopsval = {};
      // console.log(question);
      if (question.iopts[0].content != undefined) {
        if (state.opt1) {
          tempopsval = {
            content: question.iopts[0].content,
            correct: "true",
          };
        } else {
          tempopsval = {
            content: question.iopts[0].content,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[1].content != undefined) {
        if (state.opt2) {
          tempopsval = {
            content: question.iopts[1].content,
            correct: "true",
          };
        } else {
          tempopsval = {
            content: question.iopts[1].content,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[2].content != undefined) {
        if (state.opt3) {
          tempopsval = {
            content: question.iopts[2].content,
            correct: "true",
          };
        } else {
          tempopsval = {
            content: question.iopts[2].content,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[3].content != undefined) {
        if (state.opt4) {
          tempopsval = {
            content: question.iopts[3].content,
            correct: "true",
          };
        } else {
          tempopsval = {
            content: question.iopts[3].content,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[4].content != undefined) {
        if (state.opt5) {
          tempopsval = {
            content: question.iopts[4].content,
            correct: "true",
          };
        } else {
          tempopsval = {
            content: question.iopts[4].content,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[5].content != undefined) {
        if (state.opt6) {
          tempopsval = {
            content: question.iopts[5].content,
            correct: "true",
          };
        } else {
          tempopsval = {
            content: question.iopts[5].content,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      // console.log(tempoptionsiopts);
      qdata = produce(qdata, (draft) => {
        draft[qidx].iopts = tempoptionsiopts;
        draft[qidx].atype = qztype;
      });
      qdata = JSON.parse(JSON.stringify(qdata));
      setQuestionData(qdata);
    }

    if (qztype == 4) {
      if (
        question.iopts[0].url == "" ||
        question.iopts[0].url == undefined ||
        question.iopts[1].url == "" ||
        question.iopts[1].url == undefined
      ) {
        setSingleSelectImageBase({ error2: true });
        return;
      } else {
        setSingleSelectImageBase({ error2: false });
      }
      if (question.correctoption == undefined) {
        setSingleSelectImageBase({ error3: true });
        return;
      } else {
        setSingleSelectImageBase({ error3: false });
      }

      if (question.correctoption != undefined) {
        var yy = question.correctoption;
        if (question.iopts[yy] == undefined) {
          setSingleSelectImageBase({ error4: true });
          return;
        } else {
          setSingleSelectImageBase({ error4: false });
        }
      }

      if (question.iopts[3].url != "" && question.iopts[3].url != undefined) {
        if (question.iopts[2].url == undefined) {
          setSingleSelectImageBase({ error6: true });
          return;
        } else {
          setSingleSelectImageBase({ error: false });
        }
      }

      if (question.iopts[4].url != "" && question.iopts[4].url != undefined) {
        if (
          question.iopts[2].url == undefined ||
          question.iopts[3].url == undefined
        ) {
          setSingleSelectImageBase({ error6: true });
          return;
        } else {
          setSingleSelectImageBase({ error6: false });
        }
      }

      if (
        question.iopts[0].img == question.iopts[1].img ||
        question.iopts[0].img == question.iopts[2].img ||
        question.iopts[0].img == question.iopts[3].img ||
        question.iopts[0].img == question.iopts[4].img ||
        question.iopts[0].img == question.iopts[5].img
      ) {
        setSingleSelectImageBase({ error8: true });
        return;
      } else {
        setSingleSelectImageBase({ error8: false });
      }

      if (
        question.iopts[1].img == question.iopts[2].img ||
        question.iopts[1].img == question.iopts[3].img ||
        question.iopts[1].img == question.iopts[4].img ||
        question.iopts[1].img == question.iopts[5].img
      ) {
        setSingleSelectImageBase({ error8: true });
        return;
      } else {
        setSingleSelectImageBase({ error8: false });
      }

      if (question.iopts[5].url != "" && question.iopts[5].url != undefined) {
        if (
          question.iopts[2].url == undefined ||
          question.iopts[3].url == undefined ||
          question.iopts[4].url == undefined
        ) {
          setSingleSelectImageBase({ error8: true });
          return;
        } else {
          setSingleSelectImageBase({ error8: false });
        }
      }

      let tempoptionsiopts = [];
      let tempopsval = {};
      if (question.iopts[0].url != undefined) {
        if (question.correctoption == "0") {
          tempopsval = {
            url: question.iopts[0].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            url: question.iopts[0].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[1].url != undefined) {
        if (question.correctoption == "1") {
          tempopsval = {
            url: question.iopts[1].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            url: question.iopts[1].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[2].url != undefined) {
        if (question.correctoption == "2") {
          tempopsval = {
            url: question.iopts[2].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            url: question.iopts[2].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[3].url != undefined) {
        if (question.correctoption == "3") {
          tempopsval = {
            url: question.iopts[3].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            url: question.iopts[3].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[4].url != undefined) {
        if (question.correctoption == "4") {
          tempopsval = {
            url: question.iopts[4].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            url: question.iopts[4].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[5].url != undefined) {
        if (question.correctoption == "5") {
          tempopsval = {
            url: question.iopts[5].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            url: question.iopts[5].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }
      qdata = produce(qdata, (draft) => {
        draft[qidx].iopts = tempoptionsiopts;
        draft[qidx].atype = qztype;
      });
      qdata = JSON.parse(JSON.stringify(qdata));
      setQuestionData(qdata);
    }

    if (qztype == 5) {
      setMultiSelectImageBase(initialValidation);

      if (
        question.iopts[0].url == "" ||
        question.iopts[0].url == undefined ||
        question.iopts[1].url == "" ||
        question.iopts[1].url == undefined ||
        question.iopts[2].url == "" ||
        question.iopts[2].url == undefined
      ) {
        setMultiSelectImageBase({ error2: true });
        return;
      }

      if (question.iopts[3].url != "" && question.iopts[3].url != undefined) {
        if (question.iopts[2].url == undefined) {
          setMultiSelectImageBase({ error6: true });
          return;
        }
      }

      if (question.iopts[4].url != "" && question.iopts[4].url != undefined) {
        if (
          question.iopts[2].url == undefined ||
          question.iopts[3].url == undefined
        ) {
          setMultiSelectImageBase({ error6: true });
          return;
        }
      }

      if (question.iopts[5].url != "" && question.iopts[5].url != undefined) {
        if (
          question.iopts[2].url == undefined ||
          question.iopts[3].url == undefined ||
          question.iopts[4].url == undefined
        ) {
          setMultiSelectImageBase({ error6: true });
          return;
        }
      }

      if (
        question.iopts[0].img == question.iopts[1].img ||
        question.iopts[0].img == question.iopts[2].img ||
        question.iopts[0].img == question.iopts[3].img ||
        question.iopts[0].img == question.iopts[4].img ||
        question.iopts[0].img == question.iopts[5].img
      ) {
        setMultiSelectImageBase({ error8: true });
        return;
      } else {
        setMultiSelectImageBase({ error8: false });
      }

      if (
        question.iopts[1].img == question.iopts[2].img ||
        question.iopts[1].img == question.iopts[3].img ||
        question.iopts[1].img == question.iopts[4].img ||
        question.iopts[1].img == question.iopts[5].img
      ) {
        setMultiSelectImageBase({ error8: true });
        return;
      } else {
        setMultiSelectImageBase({ error8: false });
      }

      let tempoptionsiopts = [];
      let tempopsval = {};
      if (question.iopts[0].url != undefined) {
        if (state.opt1) {
          tempopsval = {
            url: question.iopts[0].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            url: question.iopts[0].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[1].url != undefined) {
        if (state.opt2) {
          tempopsval = {
            url: question.iopts[1].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            url: question.iopts[1].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[2].url != undefined) {
        if (state.opt3) {
          tempopsval = {
            url: question.iopts[2].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            url: question.iopts[2].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[3].url != undefined) {
        if (state.opt4) {
          tempopsval = {
            url: question.iopts[3].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            url: question.iopts[3].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[4].url != undefined) {
        if (state.opt5) {
          tempopsval = {
            url: question.iopts[4].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            url: question.iopts[4].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[5].url != undefined) {
        if (state.opt6) {
          tempopsval = {
            url: question.iopts[5].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            url: question.iopts[5].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      qdata = produce(qdata, (draft) => {
        draft[qidx].iopts = tempoptionsiopts;
        draft[qidx].atype = qztype;
      });
      qdata = JSON.parse(JSON.stringify(qdata));
      setQuestionData(qdata);
    }

    if (qztype == 1) {
      if (value == undefined || value == "" || value == null) {
        setDefaultValidation({ error4: true });
        return;
      } else {
        setDefaultValidation({ error4: false });
      }

      let temp = [];
      let tempopsval = {};
      if (question.iopts[0].correct == true) {
        tempopsval = {
          content: "True",
          correct: "true",
        };
      } else {
        tempopsval = {
          content: "True",
          correct: "false",
        };
      }
      temp.push(tempopsval);
      if (question.iopts[1].correct == true) {
        tempopsval = {
          content: "False",
          correct: "true",
        };
      } else {
        tempopsval = {
          content: "False",
          correct: "false",
        };
      }
      temp.push(tempopsval);

      qdata = produce(qdata, (draft) => {
        draft[qidx].iopts = temp;
        draft[qidx].atype = "1";
      });
      qdata = JSON.parse(JSON.stringify(qdata));
      setQuestionData(qdata);
    }

    if (qztype == 6) {
      matchTheFollowing.forEach((match) => {
        if (match.value1 == "" || match.value2 == "") {
          setDefaultValidation({ error5: true });
          return;
        } else {
          setDefaultValidation({ error5: false });
        }
      });

      let str1 = "";
      let ind = 0;

      for (var i = 0; i < matchTheFollowing.length; i++) {
        if (matchTheFollowing[i].value != "") {
          ind += 1;
          str1 +=
            "<tr><td>" +
            ind +
            "." +
            matchTheFollowing[i].value1 +
            "</td><td>" +
            ind +
            "." +
            matchTheFollowing[i].value2 +
            "</td></tr>";
        }
      }
      //

      qdata = produce(questionData, (v) => {
        v[
          qidx
        ].istem = `<table class='qtable'><tr><th>A</th><th>B</th></tr>${str1}</table>`;
        v[qidx].atype = "6";
      });

      qdata = JSON.parse(JSON.stringify(qdata));
      if (save === 0) {
        qdata.push({
          iid: generate(),
          istem: "",
          iopts: initialValues,
        });
      }

      setQuestionData(qdata);

      // console.log(str1);
    }
    setAType(qztype);
    qdata = produce(qdata, (draft) => {
      draft[qidx].atype = qztype;
    });
    qdata = JSON.parse(JSON.stringify(qdata));
    setQuestionData(qdata);
    let cq = currentQuestion + 1;
    setCurrentQuestion(cq);

    questionObj.currentQ = questionData.length;

    setQuestionObj((currentQuestion) => ({ ...questionObj, questionObj }));

    // console.log(JSON.stringify(qdata));
    if (qztype != 6 && save === 0) {
      setQuestionData((currentQuestion) => [
        ...currentQuestion,
        {
          iid: generate(),
          istem: "",
          iopts: initialValues,
        },
      ]);
    } else {
      if (save !== 0) {
        let arr = [];
        qdata.forEach((ques, idx, array) => {
          const fill = ques.iopts.filter((opts) => opts.content !== "");

          arr.push(
            produce(ques, (draft) => {
              draft.iopts = fill;
              delete draft.correctoption;
              if (draft.atype == 7 || draft.atype == 8) {
                delete draft.iopts;
              }
            })
          );
        });
        let instuct = [...instructions];
        let inst = [];
        instuct.map((ins) => {
          let i = { ...ins };
          inst.push(i.value);
        });

        let body = {
          title: firstViewData.title,
          startdate: firstViewData.dTStart,
          enddate: firstViewData.dTEnd,
          answerdate: firstViewData.dTAns,
          totalnoofquestions: arr.length,
          instructions: inst,
          qitems: arr,
          cid: courseId,
          cname: userDetails?.curprg.ttitle,
          bpid: userDetails?.curprg.bpid,
          oid: config.aws_org_id,
          studentsList: studentsList,
          pname: userDetails?.curprgcou.ptitle,
          quiztype: type === "Assessment" ? "assess" : "assign",
        };
        console.log(body);
        setProgress(true);
        setSuccess(true);
        setFirstView(false);
        saveAssessmentAndAssignmentData(body);
      }
    }
    setSelectedFile("");
    setQuizType("");
    setState(initialState);
  };
  const saveAssessmentAndAssignmentData = async (body) => {
    const bodyParam = {
      body,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(JSON.stringify(bodyParam.body, null, 2));
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.ADD_ASSESSMENT,
        bodyParam
      );

      console.log(response);
      // handleClose();
      setProgress(false);
    } catch (error) {
      // alert(JSON.stringify(error));
      console.error(error);
    }
  };
  const deleteInstruction = (ins, idx) => {
    if (quizType !== "6") {
      if (idx !== 0) {
        const filteredInstructions = instructions.filter(
          (instruction) => instruction.id !== ins.id
        );
        setInstructions(filteredInstructions);
      }
    } else {
      if (idx > 2) {
        const filteredMatchTheFollowing = matchTheFollowing.filter(
          (matchTheFollowing) => matchTheFollowing.id !== ins.id
        );
        setMatchTheFollowing(filteredMatchTheFollowing);
      }
    }
  };
  const firstViewForm = () => {
    setFirstViewValidation(initialValidation);
    let fdata = { ...firstViewData };
    // alert(JSON.stringify(firstViewData, null, 2));
    if (
      firstViewData.title === undefined ||
      firstViewData.title === "" ||
      firstViewData.title.replace(/\s/g, "").length === 0
    ) {
      setFirstViewValidation({ error1: true });
      return;
    } else {
      setFirstViewValidation({ error1: false });
    }
    if (type === "Assessment") {
      if (
        firstViewData.date === undefined ||
        firstViewData.date === "" ||
        firstViewData.date === null ||
        firstViewData.date === "null"
      ) {
        setFirstViewValidation({ error2: true });
        return;
      } else {
        setFirstViewValidation({ error2: false });
      }

      // var now = firstViewData.nowDate;
      var nowDate = new Date().getDate();
      var nowMonth = new Date().getMonth();
      var nowYear = new Date().getFullYear();
      // alert("nowdate" + nowDate + "NOW MONTH" +  nowMonth + "y"+ nowYear)

      // var selectedDate = new Date();
      var selectedDate = new Date(firstViewData.date).getDate();
      var selectedMonth = new Date(firstViewData.date).getMonth();
      var selectedYear = new Date(firstViewData.date).getFullYear();
      // alert("selected" + selectedDate + "s MONTH" +  selectedMonth + "y"+ selectedYear)
      if (
        selectedDate < nowDate &&
        selectedMonth <= nowMonth &&
        selectedYear <= nowYear
      ) {
        // if (now <= selectedDate) {
        setFirstViewValidation({ error9: true });
        return;
      } else {
        setFirstViewValidation({ error2: false });
      }
      if (firstViewData.sTime === undefined || firstViewData.sTime === null) {
        setFirstViewValidation({ error3: true });
        return;
      } else {
        setFirstViewValidation({ error3: false });
      }

      if (firstViewData.eTime === undefined || firstViewData.eTime === null) {
        setFirstViewValidation({ error4: true });
        return;
      } else {
        setFirstViewValidation({ error4: false });
        if (firstViewData.eTime <= firstViewData.sTime) {
          setFirstViewValidation({ error5: true });
          return;
        } else {
          setFirstViewValidation({ error5: false });
        }
      }
    }
    let nowDateA = new Date().getDate();
    let nowMonthA = new Date().getMonth();
    let nowYearA = new Date().getFullYear();

    let selectedAnsDate = new Date(firstViewData.ansDate).getDate();
    let selectedAnsMonth = new Date(firstViewData.ansDate).getMonth();
    let selectedAnsYear = new Date(firstViewData.ansDate).getFullYear();
    // alert("selected" + selectedDate + "s MONTH" +  selectedMonth + "y"+ selectedYear)
    if (
      selectedAnsDate < nowDateA &&
      selectedAnsMonth <= nowMonthA &&
      selectedAnsYear <= nowYearA
    ) {
      // if (now <= selectedDate) {
      setFirstViewValidation({ error09: true });
      return;
    } else {
      setFirstViewValidation({ error2: false });
    }

    if (
      firstViewData.ansDate === undefined ||
      firstViewData.ansDate === "" ||
      firstViewData.ansDate === null ||
      firstViewData.ansDate === "null"
    ) {
      setFirstViewValidation({ error7: true });
      return;
    } else {
      setFirstViewValidation({ error7: false });
    }

    if (firstViewData.ansTime === undefined || firstViewData.ansTime === null) {
      setFirstViewValidation({ error8: true });
      return;
    } else {
      setFirstViewValidation({ error8: false });
    }

    for (let i = 0; i < instructions.length; i++) {
      if (instructions[i].value === "") {
        setFirstViewValidation({ error6: true });
        return;
      } else {
        setFirstViewValidation({ error6: false });
      }
    }

    if (type == "Assessment") {
      let d = firstViewData.date;
      let st = firstViewData.sTime;
      let et = firstViewData.eTime;
      let dateTimeStart = new Date((d + " " + st).split(" ").join("T"));
      dateTimeStart = dateTimeStart.getTime();
      console.log(firstViewData, null, 2);
      var dateTimeEnd = new Date((d + " " + et).split(" ").join("T"));
      dateTimeEnd = dateTimeEnd.getTime();
      let dans = firstViewData.ansDate;
      let tans = firstViewData.ansTime;

      let datetimeanswer = new Date((dans + " " + tans).split(" ").join("T"));
      datetimeanswer = datetimeanswer.getTime();
      console.log(dateTimeStart);
      console.log(dateTimeEnd);

      fdata = produce(firstViewData, (draft) => {
        draft.dTStart = dateTimeStart;
        draft.dTEnd = dateTimeEnd;
        draft.dTAns = datetimeanswer;
      });
      console.log(fdata);
      setFirstViewData(fdata);
    } else {
      let dans = firstViewData.ansDate;
      let tans = firstViewData.ansTime;

      let datetimeanswer = new Date((dans + " " + tans).split(" ").join("T"));
      datetimeanswer = datetimeanswer.getTime();
      // setFirstViewData({ ...firstViewData, dTAns: datetimeanswer });
      fdata = produce(firstViewData, (draft) => {
        draft.dTAns = datetimeanswer;
      });
      setFirstViewData(fdata);
    }

    const finalInstructions = instructions.filter(
      (instruction) => instruction.value !== ""
    );
    // console.log(finalInstructions);
    setInstructions(finalInstructions);
    setQuestionData((currentQuestion) => [
      ...currentQuestion,
      {
        iid: generate(),
        istem: "",
        iopts: initialValues,
      },
    ]);
    // console.log(fdata);
    setFirstView(false);
  };

  const previousBtnClick = (id) => {
    console.log("id", id);
    let qdata = [...questionData];
    console.log("qdatalen", qdata.length);
    console.log("qdata", qdata);

    // if (correct === "true" || correct === true) {
    //   // setScore(score + 1);
    // }
    // // ansaddedtmp();
    const nextQuestion = currentQuestion - 1;

    console.log("Cureent Question", questionData[nextQuestion].istem);

    if (nextQuestion < qdata.length) {
      setCurrentQuestion(nextQuestion);

      let previousData = qdata.filter((data) => data.iid !== id);
      console.log("pre", previousData);

      // setQuestionData(previousData);
      // if (
      //   questionData[nextQuestion].atype == 3 ||
      //   questionData[nextQuestion].atype == 5
      // ) {
      //   setCheckboxes(questionData[nextQuestion].iopts);
      // }
    } else {
      handleClose();

      setCurrentQuestion(0);
      setSelectedFile("");
      setQuizType("");
      setState(initialState);
    }
  };

  const nextBtnClick = () => {
    let qdata = [...questionData];
    console.log("qdatalen", qdata.length);
    console.log("qdata", qdata);
    // if (correct === "true" || correct === true) {
    //   // setScore(score + 1);
    // }
    // // ansaddedtmp();
    const nextQuestion = currentQuestion + 1;

    console.log("nextQuestion", nextQuestion);

    if (nextQuestion < qdata.length) {
      setCurrentQuestion(nextQuestion);
      // if (
      //   questionData[nextQuestion].atype == 3 ||
      //   questionData[nextQuestion].atype == 5
      // ) {
      //   setCheckboxes(questionData[nextQuestion].iopts);
      // }
    } else {
      handleClose();
      setCurrentQuestion(0);
    }
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <CustomButton onClick={handleClickOpen("body")}>Add </CustomButton>
        <Dialog
          className={classes.paper}
          fullScreen={fullScreen}
          minWidth="lg"
          open={open}
          scroll={scroll}
          onClose={handleClose}
          onBackdropClick="false"
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            <h2 className={classes.title}>Add {type}</h2>
            <IconButton
              style={{ position: "absolute", top: "0", right: "0" }}
              component="span"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          {firstView ? (
            <React.Fragment>
              <DialogContent
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  overflowX: "hidden",
                }}
              >
                <p className="classLabel_assign_assess">Name of the subject:</p>
                <input
                  className="classInput_assign_assess"
                  disabled
                  value={firstViewData.subject}
                  type="text"
                />
                {firstViewValidation.error1 ? (
                  <p className="firstViewValidation">
                    {type} title cannot be empty
                  </p>
                ) : null}
                <p className="classLabel_assign_assess">{type} Title:</p>
                <input
                  className="classInput_assign_assess"
                  value={firstViewData.title}
                  onChange={(e) =>
                    setFirstViewData({
                      ...firstViewData,
                      title: e.target.value,
                      nowDate: new Date(),
                    })
                  }
                  type="text"
                />
                {type === "Assessment" ? (
                  <React.Fragment>
                    {firstViewValidation.error2 ? (
                      <p className="firstViewValidation">Please select date</p>
                    ) : null}
                    {firstViewValidation.error9 ? (
                      <p className="firstViewValidation">
                        Date must be in the future
                      </p>
                    ) : null}
                    <p className="classLabel_assign_assess">Date:</p>

                    <input
                      className="classInput_assign_assess"
                      type="date"
                      id="txtDate"
                      min="8-20-2021"
                      onChange={(e) => {
                        setFirstViewData({
                          ...firstViewData,
                          date: e.target.value,
                        });
                      }}
                    />
                    {firstViewValidation.error3 ? (
                      <p className="firstViewValidation">
                        Please select start time
                      </p>
                    ) : null}
                    <p className="classLabel_assign_assess">Start time:</p>
                    <input
                      className="classInput_assign_assess"
                      type="time"
                      onChange={(e) => {
                        setFirstViewData({
                          ...firstViewData,
                          sTime: e.target.value,
                        });
                      }}
                    />
                    {firstViewValidation.error4 ? (
                      <p className="firstViewValidation">
                        Please select end time
                      </p>
                    ) : null}
                    {firstViewValidation.error5 ? (
                      <p className="firstViewValidation">
                        End time must be greater than start time
                      </p>
                    ) : null}
                    <p className="classLabel_assign_assess">End time:</p>
                    <input
                      className="classInput_assign_assess"
                      type="time"
                      onChange={(e) =>
                        setFirstViewData({
                          ...firstViewData,
                          eTime: e.target.value,
                        })
                      }
                    />
                  </React.Fragment>
                ) : null}
                {firstViewValidation.error7 ? (
                  <p className="firstViewValidation">Please select date</p>
                ) : null}
                {firstViewValidation.error09 ? (
                  <p className="firstViewValidation">
                    Date must be in the future
                  </p>
                ) : null}
                <p className="classLabel_assign_assess">Answers reveal date:</p>
                <input
                  className="classInput_assign_assess"
                  type="date"
                  onChange={(e) =>
                    setFirstViewData({
                      ...firstViewData,
                      ansDate: e.target.value,
                    })
                  }
                />
                {firstViewValidation.error8 ? (
                  <p className="firstViewValidation">Please select time</p>
                ) : null}
                <p className="classLabel_assign_assess">Answers reveal time:</p>
                <input
                  className="classInput_assign_assess"
                  type="time"
                  onChange={(e) =>
                    setFirstViewData({
                      ...firstViewData,
                      ansTime: e.target.value,
                    })
                  }
                />

                {firstViewValidation.error6 ? (
                  <p className="firstViewValidation">
                    Instructions cannot be empty
                  </p>
                ) : null}
                <p className="classLabel_assign_assess">
                  Instructions to students:
                </p>
                {instructions.map((instruction, idx) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <p className="classLabel_assign_assess">{idx + 1}</p>
                    <input
                      className="classInput_Instructor"
                      type="text"
                      value={instruction.value}
                      onChange={(e) => {
                        const insValue = e.target.value;
                        setInstructions((currIns) =>
                          produce(currIns, (v) => {
                            v[idx].value = insValue;
                          })
                        );
                      }}
                    />
                    <IconButton
                      aria-label="add"
                      onClick={() => addInstruction(instruction)}
                    >
                      <AddCircleIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      className={classes.margin}
                      onClick={() => deleteInstruction(instruction, idx)}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </div>
                ))}
              </DialogContent>
              <DialogActions
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CustomButton autoFocus onClick={() => firstViewForm()}>
                  Next
                </CustomButton>
              </DialogActions>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {questionData.map((question, qidx) => (
                <React.Fragment>
                  {qidx == questionObj.currentQ && (
                    <React.Fragment>
                      <DialogContent
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          overflowX: "hidden",
                        }}
                      >
                        <p
                          className="classLabel_assign_assess"
                          style={{
                            textAlign: "center",
                            marginBottom: "1.5rem",
                          }}
                        >
                          Question {questionData.length}/{questionData.length}
                        </p>
                        {defaultValidation.error1 ? (
                          <p className="firstViewValidation">
                            Please select the Question type
                          </p>
                        ) : null}
                        <select
                          className="classInput_assign_assess"
                          onChange={(e) => {
                            setQuizType(e.target.value);
                            let qesData = [...questionData];
                            if (quizType !== "1") {
                              setQuestionData(
                                produce(qesData, (draft) => {
                                  draft[qidx].iopts = initialValues;
                                })
                              );
                            }
                          }}
                        >
                          <option style={{ display: "none" }}>
                            Select - Question Type
                          </option>
                          <option value="1">True or False</option>
                          <option value="2">
                            Single Select Multiple Choice
                          </option>
                          <option value="3">
                            Multiple Select Multiple Choice
                          </option>

                          <option value="4">
                            Single Select Image Based Multiple Choice
                          </option>

                          <option value="5">
                            Multiple Select Image Based Multiple Choice
                          </option>

                          <option value="6">Match the Following</option>
                          <option value="7">Descriptive Questions</option>
                          <option value="8">File Upload</option>
                        </select>

                        {quizType !== "6" ? (
                          <div className={classes.displayFlex}>
                            <div className={classes.flexBasis}>
                              <h4>Question</h4>
                            </div>
                            <>
                              {!previousbtn ? (
                                <>
                                  <div style={{ width: "100%" }}>
                                    {defaultValidation.error2 ? (
                                      <p className="firstViewValidation">
                                        Question cannot be empty
                                      </p>
                                    ) : null}
                                    <textarea
                                      className="classTextarea"
                                      style={{ marginBottom: "20px" }}
                                      // defaultValue={questionData[currentQuestion].istem}
                                      autoComplete="off"
                                      onChange={(e) => {
                                        const istem = e.target.value;
                                        // setIstem(istem);
                                        setQuestionData((currentistem) =>
                                          produce(currentistem, (v) => {
                                            v[qidx].istem = istem;
                                          })
                                        );
                                      }}
                                    ></textarea>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <input
                                    className="classInput_assign_assess"
                                    type="text"
                                    value={questionData[currentQuestion].istem}
                                    style={{
                                      fontSize: "16px",
                                      //fontWeight: "bold",
                                    }}
                                  />
                                  {/* <textarea
                                    className="classTextarea"
                                    style={{ marginBottom: "20px" }}
                                    Value={questionData[currentQuestion].istem}
                                    autoComplete="off"
                                    onChange={(e) => {
                                      const istem = e.target.value;
                                      // setIstem(istem);
                                      setQuestionData((currentistem) =>
                                        produce(currentistem, (v) => {
                                          v[qidx].istem = istem;
                                        })
                                      );
                                    }}
                                  ></textarea> */}
                                </>
                              )}
                            </>
                          </div>
                        ) : (
                          <div className={classes.displayFlex}>
                            <div className={classes.flexBasis}>
                              <h4>Question:</h4>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                              className={classes.root1}
                            >
                              {matchTheFollowing.map((match, ids) => (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "1rem",
                                  }}
                                >
                                  <input
                                    className="classInput_MatchTheFollowing"
                                    onChange={(e) => {
                                      let value1 = e.target.value;
                                      setMatchTheFollowing((currMatchVal) =>
                                        produce(currMatchVal, (v) => {
                                          v[ids].value1 = value1;
                                        })
                                      );
                                    }}
                                  />
                                  <input
                                    className="classInput_MatchTheFollowing"
                                    onChange={(e) => {
                                      let value2 = e.target.value;
                                      setMatchTheFollowing((currMatchVal) =>
                                        produce(currMatchVal, (v) => {
                                          v[ids].value2 = value2;
                                        })
                                      );
                                    }}
                                  />
                                  <IconButton
                                    aria-label="add"
                                    onClick={() => addInstruction(ids)}
                                  >
                                    <AddCircleIcon />
                                  </IconButton>

                                  <IconButton
                                    aria-label="delete"
                                    onClick={() =>
                                      deleteInstruction(match, ids)
                                    }
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}

                        <div className={classes.displayFlex}>
                          <div className={classes.flexBasis}>
                            <h4>Image</h4>{" "}
                            <sub className={classes.small}>(Optional)</sub>
                          </div>
                          <div className={classes.root1}>
                            <input
                              accept="image/*"
                              className={classes.input}
                              id="contained-button-file"
                              multiple
                              type="file"
                              onChange={(e) => {
                                onFileChange(e, qidx);
                              }}
                            />

                            <label htmlFor="contained-button-file">
                              <Button
                                className={classes.uploadBtn}
                                component="span"
                                style={{
                                  textTransform: "capitalize",
                                }}
                              >
                                Upload
                              </Button>
                            </label>
                            {
                              <h4
                                style={{
                                  display: "inline-block",
                                  marginLeft: "2rem",
                                }}
                              >
                                {selectedFile !== null &&
                                selectedFile.file !== undefined
                                  ? selectedFile.file.name
                                  : ""}
                              </h4>
                            }
                          </div>
                        </div>

                        {previousbtn ? (
                          <>
                            {questionData[currentQuestion].atype == 1 ||
                            questionData[currentQuestion].atype == 2 ? (
                              <>
                                <div style={{ marginLeft: "8rem" }}>
                                  {questionData[currentQuestion].iopts.map(
                                    (opts, oidx) => (
                                      <React.Fragment key={oidx + "sfsd"}>
                                        <BlueRadio
                                        // onChange={() => {
                                        //   for (
                                        //     let i = 0;
                                        //     i <
                                        //     questions[currentQuestion].iopts
                                        //       .length;
                                        //     i++
                                        //   ) {
                                        //     setQuestions((currentQues) =>
                                        //       produce(
                                        //         currentQues,
                                        //         (draft) => {
                                        //           draft[
                                        //             currentQuestion
                                        //           ].iopts[i].correct = true;
                                        //         }
                                        //       )
                                        //     );

                                        //     console.log(questions);
                                        //     // quizQuestions[
                                        //     //   currentQuestion
                                        //     // ].iopts[i].Selected = false;
                                        //   }

                                        //   // setQuizQuestions(
                                        //   //   (currentQues) =>
                                        //   //     produce(
                                        //   //       currentQues,
                                        //   //       (draft) => {
                                        //   //         draft[
                                        //   //           currentQuestion
                                        //   //         ].iopts[
                                        //   //           index
                                        //   //         ].Selected = true;
                                        //   //       }
                                        //   //     )
                                        //   // );
                                        // }}
                                        // value={opts.content}
                                        // name="radio-button-demo"
                                        // inputProps={{
                                        //   "aria-label": "False",
                                        // }}
                                        />
                                        <span style={{ fontSize: "1.3rem" }}>
                                          {opts.content}
                                        </span>
                                      </React.Fragment>
                                    )
                                  )}
                                </div>
                              </>
                            ) : (
                              <>
                                {questionData[currentQuestion].atype == 3 ? (
                                  <>
                                    <div className={classes.displayFlex}>
                                      <div className={classes.flexBasis}>
                                        <h4>Options:</h4>
                                      </div>

                                      <FormControl
                                        component="fieldset"
                                        className={classes.root1}
                                      >
                                        {questionData[
                                          currentQuestion
                                        ].iopts.map((opts, oidx) => (
                                          <FormGroup>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={state.opt1}
                                                  onChange={(e) =>
                                                    handleMultiSelectChange(
                                                      e,
                                                      currentQuestion
                                                    )
                                                  }
                                                  name="opt1"
                                                />
                                              }
                                              label={
                                                <input
                                                  className="classInput"
                                                  type="text"
                                                  defaultValue={opts.content}
                                                  onChange={(e) => {}}
                                                  autoComplete="off"
                                                />
                                              }
                                            />
                                          </FormGroup>
                                        ))}
                                      </FormControl>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {questionData[currentQuestion].atype ==
                                    4 ? (
                                      <>
                                        <div
                                          className={assessStyle.answersection}
                                        >
                                          <div
                                            className={
                                              "btn-group " +
                                              assessStyle.btngroupvertical
                                            }
                                          >
                                            {questionData[
                                              currentQuestion
                                            ].iopts.map(
                                              (
                                                answerOption,
                                                index,
                                                arrayobj
                                              ) => (
                                                <>
                                                  {questionData[currentQuestion]
                                                    .atype == 4 ? (
                                                    <>
                                                      <div>
                                                        <input
                                                          type="radio"
                                                          className="radio_opacity"
                                                          name="options"
                                                          id={answerOption.url}
                                                        />
                                                        <label
                                                          className={
                                                            assessStyle.labelstyle +
                                                            " " +
                                                            assessStyle.buttonQuiz
                                                          }
                                                          for={answerOption.url}
                                                          // onClick={() =>
                                                          //   handleAnswerOptionClick(
                                                          //     answerOption.correct,
                                                          //     index
                                                          //   )
                                                          // }
                                                        >
                                                          <Image
                                                            src={
                                                              imgUrl +
                                                              answerOption.url
                                                            }
                                                            rounded
                                                          />
                                                        </label>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                              </>
                            )}

                            <>
                              {questionData[currentQuestion].atype == 5 ? (
                                <>
                                  <div className={classes.displayFlex}>
                                    <div className={classes.flexBasis}>
                                      <h4>Options:</h4>
                                    </div>

                                    <FormControl
                                      component="fieldset"
                                      className={classes.root1}
                                    >
                                      {questionData[currentQuestion].iopts.map(
                                        (opts, oidx) => (
                                          <FormGroup>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={state.opt1}
                                                  onChange={(e) =>
                                                    handleMultiSelectChange(
                                                      e,
                                                      currentQuestion
                                                    )
                                                  }
                                                  name="opt1"
                                                />
                                              }
                                              label={
                                                <input
                                                  className="classInput"
                                                  type="text"
                                                  defaultValue={opts.content}
                                                  onChange={(e) => {
                                                    //let opt1 = e.target.value;
                                                    // setQuestionData(
                                                    //   (currentInput) =>
                                                    //     produce(
                                                    //       currentInput,
                                                    //       (v) => {
                                                    //         v[
                                                    //           currentQuestion
                                                    //         ].iopts[0].content =
                                                    //           opt1;
                                                    //       }
                                                    //     )
                                                    // );
                                                  }}
                                                  autoComplete="off"
                                                />
                                              }
                                            />
                                          </FormGroup>
                                        )
                                      )}
                                    </FormControl>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {questionData[currentQuestion].atype == 6 ? (
                                    <>
                                      <p
                                        className={assessStyle.matchThe}
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            questionData[currentQuestion].istem,
                                        }}
                                      ></p>{" "}
                                      {questionData[currentQuestion].iopts.map(
                                        (answerOption, index, arrayobj) => (
                                          <>
                                            {questionData[currentQuestion]
                                              .atype == 4 ? (
                                              <>
                                                <div>
                                                  <input
                                                    type="radio"
                                                    className="radio_opacity"
                                                    name="options"
                                                    id={answerOption.content}
                                                  />
                                                  <label
                                                    className={
                                                      assessStyle.labelstyle +
                                                      " " +
                                                      assessStyle.buttonQuiz
                                                    }
                                                    for={answerOption.content}
                                                    // onClick={() =>
                                                    //   handleAnswerOptionClick(
                                                    //     answerOption.correct,
                                                    //     index
                                                    //   )
                                                    // }
                                                  >
                                                    <Image
                                                      src={
                                                        imgUrl +
                                                        answerOption.url
                                                      }
                                                      rounded
                                                    />
                                                  </label>
                                                </div>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        )
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </>

                            <>
                              {questionData[currentQuestion].atype == 7 ? (
                                <>
                                  <TextareaAutosize
                                    className={assessStyle.textarea}
                                    rowsMax={6}
                                    aria-label="maximum height"
                                    placeholder="minimum 6 rows"
                                    // onChange={() => {
                                    //   setCorrect("true");
                                    // }}
                                  />
                                </>
                              ) : (
                                <>
                                  {questionData[currentQuestion].atype == 8 ? (
                                    <div className="file-input">
                                      <input
                                        type="file"
                                        id="file"
                                        name="file"
                                        className="file"
                                        // onChange={(event) => {
                                        //   setFileName(event.target.files[0].name);
                                        // }}
                                        multiple
                                        required
                                      />
                                      <label for="file">
                                        Select file
                                        {/* <p className="file-name2">
                                  {fileName !== undefined ||
                                  fileName !== null ||
                                  fileName !== ""
                                    ? fileName
                                    : ""}
                                </p> */}
                                      </label>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </>
                          </>
                        ) : (
                          <></>
                        )}

                        {quizType === "1" ? (
                          <div style={{ marginLeft: "8rem" }}>
                            {tFOptions.map((opts, oidx) => (
                              <React.Fragment key={oidx + "sfsd"}>
                                <BlueRadio
                                  checked={value == `${opts.content}`}
                                  onChange={(e) =>
                                    handleChange(e, oidx, qidx, quizType)
                                  }
                                  value={`${opts.content}`}
                                  name="radio-button-demo"
                                  inputProps={{ "aria-label": "False" }}
                                />
                                <span style={{ fontSize: "1.3rem" }}>
                                  {opts.content}
                                </span>
                              </React.Fragment>
                            ))}
                          </div>
                        ) : null}
                        <div style={{ paddingLeft: "15%" }}>
                          {questionValidation.error2 && (
                            <p className="firstViewValidation">
                              You need to enter at least 2 options
                            </p>
                          )}
                          {questionValidation.error3 && (
                            <p className="firstViewValidation">
                              Please select the correct option
                            </p>
                          )}
                          {questionValidation.error4 && (
                            <p className="firstViewValidation">
                              You have selected an option for which no value has
                              been entered
                            </p>
                          )}
                          {questionValidation.error5 && (
                            <p className="firstViewValidation">
                              Option cannot just contain space
                            </p>
                          )}
                          {questionValidation.error6 && (
                            <p className="firstViewValidation">
                              You have entered an option by skipping one of the
                              previous one
                            </p>
                          )}
                          {questionValidation.error7 && (
                            <p className="firstViewValidation">
                              Please enter a feedback
                            </p>
                          )}
                          {questionValidation.error8 && (
                            <p className="firstViewValidation">
                              Entered options must have different values
                            </p>
                          )}
                          {multiSelectValidation.error1 && (
                            <p className="firstViewValidation">
                              Select at least 2 options
                            </p>
                          )}
                          {multiSelectValidation.error2 && (
                            <p className="firstViewValidation">
                              You need to enter at least 3 options
                            </p>
                          )}
                          {multiSelectValidation.error3 && (
                            <p className="firstViewValidation">
                              Please select the correct option
                            </p>
                          )}
                          {multiSelectValidation.error4 && (
                            <p className="firstViewValidation">
                              You have selected an option for which no value has
                              been entered
                            </p>
                          )}
                          {multiSelectValidation.error5 && (
                            <p className="firstViewValidation">
                              Option cannot just contain space
                            </p>
                          )}
                          {multiSelectValidation.error6 && (
                            <p className="firstViewValidation">
                              You have entered an option by skipping one of the
                              previous one
                            </p>
                          )}
                          {multiSelectValidation.error7 && (
                            <p className="firstViewValidation">
                              Please enter a feedback
                            </p>
                          )}
                          {multiSelectValidation.error8 && (
                            <p className="firstViewValidation">
                              Entered options must have different values
                            </p>
                          )}
                          {singleSelectImageBase.error2 && (
                            <p className="firstViewValidation">
                              You need to select at least 2 Images
                            </p>
                          )}
                          {singleSelectImageBase.error3 && (
                            <p className="firstViewValidation">
                              Please select the correct option
                            </p>
                          )}
                          {singleSelectImageBase.error4 && (
                            <p className="firstViewValidation">
                              You have selected an option for which no image has
                              been selected
                            </p>
                          )}
                          {singleSelectImageBase.error5 && (
                            <p className="firstViewValidation">
                              Option cannot just contain space
                            </p>
                          )}
                          {singleSelectImageBase.error6 && (
                            <p className="firstViewValidation">
                              You have selected an image by skipping one of the
                              previous one
                            </p>
                          )}
                          {singleSelectImageBase.error7 && (
                            <p className="firstViewValidation">
                              Please enter a feedback
                            </p>
                          )}
                          {singleSelectImageBase.error8 && (
                            <p className="firstViewValidation">
                              Please select the Different Files
                            </p>
                          )}
                          {multiSelectImageBase.error1 && (
                            <p className="firstViewValidation">
                              Select at least 2 options
                            </p>
                          )}
                          {multiSelectImageBase.error2 && (
                            <p className="firstViewValidation">
                              You need to select at least 3 Images
                            </p>
                          )}
                          {multiSelectImageBase.error3 && (
                            <p className="firstViewValidation">
                              Please select the correct option
                            </p>
                          )}
                          {multiSelectImageBase.error4 && (
                            <p className="firstViewValidation">
                              You have selected an option for which no image has
                              been selected
                            </p>
                          )}
                          {multiSelectImageBase.error5 && (
                            <p className="firstViewValidation">
                              Option cannot just contain space
                            </p>
                          )}
                          {multiSelectImageBase.error6 && (
                            <p className="firstViewValidation">
                              You have selected an image by skipping one of the
                              previous one
                            </p>
                          )}
                          {multiSelectImageBase.error7 && (
                            <p className="firstViewValidation">
                              Please enter a feedback
                            </p>
                          )}
                          {multiSelectImageBase.error8 && (
                            <p className="firstViewValidation">
                              Please select the Different Files
                            </p>
                          )}
                        </div>
                        {quizType === "6" || quizType === "2" ? (
                          <>
                            <div className={classes.displayFlex}>
                              <div className={classes.flexBasis}>
                                <h4>Options:</h4>
                              </div>

                              <FormControl
                                className={classes.root1}
                                component="fieldset"
                                fullWidth
                              >
                                <RadioGroup
                                  onChange={(e) => handleOptionChange(e, qidx)}
                                  value={question.correctoption}
                                >
                                  {question.iopts.map((opts, oidx) => (
                                    <>
                                      <FormControlLabel
                                        value={oidx.toString()}
                                        control={<BlueRadio />}
                                        label={
                                          <input
                                            value={opts.content}
                                            className="classInput"
                                            onChange={(e) =>
                                              handleInputChange(e, oidx, qidx)
                                            }
                                            type="text"
                                            autoComplete="off"
                                          />
                                        }
                                      />
                                    </>
                                  ))}
                                </RadioGroup>
                              </FormControl>
                            </div>
                          </>
                        ) : null}
                        {quizType === "4" ? (
                          <>
                            <div className={classes.displayFlex}>
                              <div className={classes.flexBasis}>
                                <h4>Options:</h4>
                              </div>

                              <FormControl
                                className={classes.root1}
                                component="fieldset"
                                fullWidth
                              >
                                <RadioGroup
                                  onChange={(e) => handleOptionChange(e, qidx)}
                                  value={question.correctoption}
                                >
                                  {question.iopts.map((opts, oidx) => (
                                    <>
                                      <FormControlLabel
                                        value={oidx.toString()}
                                        control={<BlueRadio />}
                                        label={
                                          <React.Fragment>
                                            <input
                                              accept="image/*"
                                              className={classes.input}
                                              id={oidx.toString()}
                                              multiple
                                              type="file"
                                              onChange={(e) => {
                                                onSImageFileChange(
                                                  e,
                                                  qidx,
                                                  oidx
                                                );
                                              }}
                                            />

                                            <label htmlFor={oidx.toString()}>
                                              <Button
                                                className={classes.uploadBtn}
                                                component="span"
                                                style={{
                                                  textTransform: "capitalize",
                                                }}
                                              >
                                                Upload
                                              </Button>
                                            </label>
                                            {
                                              <h4
                                                style={{
                                                  display: "inline-block",
                                                  marginLeft: "2rem",
                                                }}
                                              >
                                                {opts.img}
                                              </h4>
                                            }
                                          </React.Fragment>
                                        }
                                      />
                                    </>
                                  ))}
                                </RadioGroup>
                              </FormControl>
                            </div>
                          </>
                        ) : null}
                        {quizType === "3" ? (
                          <>
                            <div className={classes.displayFlex}>
                              <div className={classes.flexBasis}>
                                <h4>Options:</h4>
                              </div>

                              <FormControl
                                component="fieldset"
                                className={classes.root1}
                              >
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={state.opt1}
                                        onChange={(e) =>
                                          handleMultiSelectChange(e, qidx)
                                        }
                                        name="opt1"
                                      />
                                    }
                                    label={
                                      <input
                                        className="classInput"
                                        type="text"
                                        onChange={(e) => {
                                          let opt1 = e.target.value;

                                          setQuestionData((currentInput) =>
                                            produce(currentInput, (v) => {
                                              v[qidx].iopts[0].content = opt1;
                                            })
                                          );
                                        }}
                                        autoComplete="off"
                                      />
                                    }
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={state.opt2}
                                        onChange={(e) =>
                                          handleMultiSelectChange(e, qidx)
                                        }
                                        name="opt2"
                                      />
                                    }
                                    label={
                                      <input
                                        className="classInput"
                                        type="text"
                                        onChange={(e) => {
                                          let opt2 = e.target.value;
                                          setQuestionData((currentInput) =>
                                            produce(currentInput, (v) => {
                                              v[qidx].iopts[1].content = opt2;
                                            })
                                          );
                                        }}
                                        autoComplete="off"
                                      />
                                    }
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={state.opt3}
                                        onChange={(e) =>
                                          handleMultiSelectChange(e, qidx)
                                        }
                                        name="opt3"
                                      />
                                    }
                                    label={
                                      <input
                                        className="classInput"
                                        type="text"
                                        onChange={(e) => {
                                          let opt3 = e.target.value;
                                          setQuestionData((currentInput) =>
                                            produce(currentInput, (v) => {
                                              v[qidx].iopts[2].content = opt3;
                                            })
                                          );
                                        }}
                                        autoComplete="off"
                                      />
                                    }
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={state.opt4}
                                        onChange={(e) =>
                                          handleMultiSelectChange(e, qidx)
                                        }
                                        name="opt4"
                                      />
                                    }
                                    label={
                                      <input
                                        className="classInput"
                                        type="text"
                                        onChange={(e) => {
                                          let opt4 = e.target.value;
                                          setQuestionData((currentInput) =>
                                            produce(currentInput, (v) => {
                                              v[qidx].iopts[3].content = opt4;
                                            })
                                          );
                                        }}
                                        autoComplete="off"
                                      />
                                    }
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={state.opt5}
                                        onChange={(e) =>
                                          handleMultiSelectChange(e, qidx)
                                        }
                                        name="opt5"
                                      />
                                    }
                                    label={
                                      <input
                                        className="classInput"
                                        type="text"
                                        onChange={(e) => {
                                          let opt5 = e.target.value;
                                          setQuestionData((currentInput) =>
                                            produce(currentInput, (v) => {
                                              v[qidx].iopts[4].content = opt5;
                                            })
                                          );
                                        }}
                                        autoComplete="off"
                                      />
                                    }
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={state.opt6}
                                        onChange={(e) =>
                                          handleMultiSelectChange(e, qidx)
                                        }
                                        name="opt6"
                                      />
                                    }
                                    label={
                                      <input
                                        className="classInput"
                                        type="text"
                                        onChange={(e) => {
                                          let opt6 = e.target.value;
                                          setQuestionData((currentInput) =>
                                            produce(currentInput, (v) => {
                                              v[qidx].iopts[5].content = opt6;
                                            })
                                          );
                                        }}
                                        autoComplete="off"
                                      />
                                    }
                                  />
                                </FormGroup>
                              </FormControl>
                            </div>
                          </>
                        ) : null}
                        {quizType === "5" ? (
                          <>
                            <div className={classes.displayFlex}>
                              <div className={classes.flexBasis}>
                                <h4>Options:</h4>
                              </div>

                              <FormControl
                                className={classes.root1}
                                component="fieldset"
                                fullWidth
                              >
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={state.opt1}
                                        style={{ color: "#01498e" }}
                                        onChange={(e) =>
                                          handleMultiSelectChange(e, qidx)
                                        }
                                        name="opt1"
                                      />
                                    }
                                    label={
                                      <div className={classes.root1}>
                                        <input
                                          accept="image/*"
                                          className={classes.input}
                                          id="contained-button-file-1"
                                          multiple
                                          type="file"
                                          onChange={(event) => {
                                            let opt1 = event.target.files[0];
                                            var uniqid = new Date().getTime();
                                            var imtype =
                                              event.target.files[0].name.slice(
                                                (Math.max(
                                                  0,
                                                  event.target.files[0].name.lastIndexOf(
                                                    "."
                                                  )
                                                ) || Infinity) + 1
                                              );
                                            let imageName = `img${uniqid}.${imtype}`;
                                            setQuestionData((currentFile) =>
                                              produce(currentFile, (draft) => {
                                                draft[qidx].iopts[0].url =
                                                  imageName;
                                                draft[qidx].iopts[0].img =
                                                  opt1.name;
                                              })
                                            );
                                            imageUpload(opt1, imageName);
                                          }}
                                        />

                                        <label htmlFor="contained-button-file-1">
                                          <Button
                                            className={classes.uploadBtn}
                                            component="span"
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            Upload
                                          </Button>
                                        </label>
                                        {
                                          <h4
                                            style={{
                                              display: "inline-block",
                                              marginLeft: "2rem",
                                            }}
                                          >
                                            {questionData[qidx].iopts[0].img}
                                          </h4>
                                        }
                                      </div>
                                    }
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={state.opt2}
                                        style={{ color: "#01498e" }}
                                        onChange={(e) =>
                                          handleMultiSelectChange(e, qidx)
                                        }
                                        name="opt2"
                                      />
                                    }
                                    label={
                                      <div className={classes.root1}>
                                        <input
                                          accept="image/*"
                                          className={classes.input}
                                          id="contained-button-file-2"
                                          multiple
                                          type="file"
                                          onChange={(e) => {
                                            let opt2 = e.target.files[0];
                                            var uniqid = new Date().getTime();
                                            var imtype =
                                              e.target.files[0].name.slice(
                                                (Math.max(
                                                  0,
                                                  e.target.files[0].name.lastIndexOf(
                                                    "."
                                                  )
                                                ) || Infinity) + 1
                                              );
                                            let imageName = `img${uniqid}.${imtype}`;
                                            setQuestionData((currentFile) =>
                                              produce(currentFile, (draft) => {
                                                draft[qidx].iopts[1].url =
                                                  imageName;
                                                draft[qidx].iopts[1].img =
                                                  opt2.name;
                                              })
                                            );
                                            imageUpload(opt2, imageName);
                                          }}
                                        />

                                        <label htmlFor="contained-button-file-2">
                                          <Button
                                            className={classes.uploadBtn}
                                            component="span"
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            Upload
                                          </Button>
                                        </label>
                                        {
                                          <h4
                                            style={{
                                              display: "inline-block",
                                              marginLeft: "2rem",
                                            }}
                                          >
                                            {questionData[qidx].iopts[1].img}
                                          </h4>
                                        }
                                      </div>
                                    }
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={state.opt3}
                                        style={{ color: "#01498e" }}
                                        onChange={(e) =>
                                          handleMultiSelectChange(e, qidx)
                                        }
                                        name="opt3"
                                      />
                                    }
                                    label={
                                      <div className={classes.root1}>
                                        <input
                                          accept="image/*"
                                          className={classes.input}
                                          id="contained-button-file-3"
                                          multiple
                                          type="file"
                                          onChange={(e) => {
                                            let opt3 = e.target.files[0];
                                            var uniqid = new Date().getTime();
                                            var imtype =
                                              e.target.files[0].name.slice(
                                                (Math.max(
                                                  0,
                                                  e.target.files[0].name.lastIndexOf(
                                                    "."
                                                  )
                                                ) || Infinity) + 1
                                              );
                                            let imageName = `img${uniqid}.${imtype}`;
                                            setQuestionData((currentFile) =>
                                              produce(currentFile, (draft) => {
                                                draft[qidx].iopts[2].url =
                                                  imageName;
                                                draft[qidx].iopts[2].img =
                                                  opt3.name;
                                              })
                                            );
                                          }}
                                        />

                                        <label htmlFor="contained-button-file-3">
                                          <Button
                                            className={classes.uploadBtn}
                                            component="span"
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            Upload
                                          </Button>
                                        </label>
                                        {
                                          <h4
                                            style={{
                                              display: "inline-block",
                                              marginLeft: "2rem",
                                            }}
                                          >
                                            {questionData[qidx].iopts[2].img}
                                          </h4>
                                        }
                                      </div>
                                    }
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={state.opt4}
                                        style={{ color: "#01498e" }}
                                        onChange={(e) =>
                                          handleMultiSelectChange(e, qidx)
                                        }
                                        name="opt4"
                                      />
                                    }
                                    label={
                                      <div className={classes.root1}>
                                        <input
                                          accept="image/*"
                                          className={classes.input}
                                          id="contained-button-file-4"
                                          multiple
                                          type="file"
                                          onChange={(e) => {
                                            let opt4 = e.target.files[0];
                                            var uniqid = new Date().getTime();
                                            var imtype =
                                              e.target.files[0].name.slice(
                                                (Math.max(
                                                  0,
                                                  e.target.files[0].name.lastIndexOf(
                                                    "."
                                                  )
                                                ) || Infinity) + 1
                                              );
                                            let imageName = `img${uniqid}.${imtype}`;
                                            setQuestionData((currentFile) =>
                                              produce(currentFile, (draft) => {
                                                draft[qidx].iopts[3].url =
                                                  imageName;
                                                draft[qidx].iopts[3].img =
                                                  opt4.name;
                                              })
                                            );
                                          }}
                                        />

                                        <label htmlFor="contained-button-file-4">
                                          <Button
                                            className={classes.uploadBtn}
                                            component="span"
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            Upload
                                          </Button>
                                        </label>
                                        {
                                          <h4
                                            style={{
                                              display: "inline-block",
                                              marginLeft: "2rem",
                                            }}
                                          >
                                            {questionData[qidx].iopts[3].img}
                                          </h4>
                                        }
                                      </div>
                                    }
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={state.opt5}
                                        style={{ color: "#01498e" }}
                                        onChange={(e) =>
                                          handleMultiSelectChange(e, qidx)
                                        }
                                        name="opt5"
                                      />
                                    }
                                    label={
                                      <div className={classes.root1}>
                                        <input
                                          accept="image/*"
                                          className={classes.input}
                                          id="contained-button-file-5"
                                          multiple
                                          type="file"
                                          onChange={(e) => {
                                            let opt5 = e.target.files[0];
                                            var uniqid = new Date().getTime();
                                            var imtype =
                                              e.target.files[0].name.slice(
                                                (Math.max(
                                                  0,
                                                  e.target.files[0].name.lastIndexOf(
                                                    "."
                                                  )
                                                ) || Infinity) + 1
                                              );
                                            let imageName = `img${uniqid}.${imtype}`;
                                            setQuestionData((currentFile) =>
                                              produce(currentFile, (draft) => {
                                                draft[qidx].iopts[4].url =
                                                  imageName;
                                                draft[qidx].iopts[4].img =
                                                  opt5.name;
                                              })
                                            );
                                          }}
                                        />

                                        <label htmlFor="contained-button-file-5">
                                          <Button
                                            className={classes.uploadBtn}
                                            component="span"
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            Upload
                                          </Button>
                                        </label>
                                        {
                                          <h4
                                            style={{
                                              display: "inline-block",
                                              marginLeft: "2rem",
                                            }}
                                          >
                                            {questionData[qidx].iopts[4].img}
                                          </h4>
                                        }
                                      </div>
                                    }
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={state.opt6}
                                        style={{ color: "#01498e" }}
                                        onChange={(e) =>
                                          handleMultiSelectChange(e, qidx)
                                        }
                                        name="opt6"
                                      />
                                    }
                                    label={
                                      <div className={classes.root1}>
                                        <input
                                          accept="image/*"
                                          className={classes.input}
                                          id="contained-button-file-6"
                                          multiple
                                          type="file"
                                          onChange={(e) => {
                                            let opt6 = e.target.files[0];
                                            var uniqid = new Date().getTime();
                                            var imtype =
                                              e.target.files[0].name.slice(
                                                (Math.max(
                                                  0,
                                                  e.target.files[0].name.lastIndexOf(
                                                    "."
                                                  )
                                                ) || Infinity) + 1
                                              );
                                            let imageName = `img${uniqid}.${imtype}`;
                                            setQuestionData((currentFile) =>
                                              produce(currentFile, (draft) => {
                                                draft[qidx].iopts[5].url =
                                                  imageName;
                                                draft[qidx].iopts[5].img =
                                                  opt6.name;
                                              })
                                            );
                                          }}
                                        />

                                        <label htmlFor="contained-button-file-6">
                                          <Button
                                            className={classes.uploadBtn}
                                            component="span"
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            Upload
                                          </Button>
                                        </label>
                                        {
                                          <h4
                                            style={{
                                              display: "inline-block",
                                              marginLeft: "2rem",
                                            }}
                                          >
                                            {questionData[qidx].iopts[5].img}
                                          </h4>
                                        }
                                      </div>
                                    }
                                  />
                                </FormGroup>
                              </FormControl>
                            </div>
                          </>
                        ) : null}
                        <div className={classes.displayFlex}>
                          <div className={classes.flexBasis}>
                            <h4>Marks:</h4>
                          </div>
                          <div className={classes.root1}>
                            {defaultValidation.error3 ? (
                              <p className="firstViewValidation">
                                Please enter the marks
                              </p>
                            ) : null}

                            <input
                              type="number"
                              className="classInput_Marks"
                              maxLength="1"
                              max="5"
                              min="0"
                              onChange={(e) => {
                                let qScore = e.target.value;

                                setQuestionData((currentQScore) =>
                                  produce(currentQScore, (v) => {
                                    v[qidx].qscore = qScore;
                                  })
                                );
                              }}
                            />
                          </div>
                        </div>
                      </DialogContent>

                      <DialogActions
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <CustomButton
                          autoFocus
                          disabled={currentQuestion === 0}
                          onClick={() => {
                            setPreviousBtn(true);
                            console.log(
                              "questionData.length",
                              questionData.length
                            );
                            console.log("currentQuestion", currentQuestion);

                            previousBtnClick(
                              questionData[currentQuestion - 1].iid
                            );
                          }}
                        >
                          Previous
                        </CustomButton>
                        <CustomButton
                          autoFocus
                          onClick={() => {
                            addQuestion(question, qidx, 0, quizType);
                            setPreviousBtn(false);
                            console.log(
                              "questionData.length",
                              questionData.length
                            );
                            console.log("currentQuestion", currentQuestion);
                          }}
                          disabled={
                            previousbtn == true &&
                            currentQuestion + 1 != questionData.length
                          }
                        >
                          Add Question
                        </CustomButton>
                        <CustomButton
                          autoFocus
                          onClick={() =>
                            addQuestion(question, qidx, 1, quizType)
                          }
                        >
                          Save
                        </CustomButton>
                        <CustomButton
                          autoFocus
                          onClick={() => {
                            nextBtnClick();

                            // setPreviousBtn(false);
                          }}
                          disabled={currentQuestion + 1 === questionData.length}
                        >
                          Next
                        </CustomButton>
                      </DialogActions>
                    </React.Fragment>
                  )}
                </React.Fragment>
              ))}
            </React.Fragment>
          )}
          {success && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {progress ? (
                <CircularProgress color="primary" />
              ) : (
                <h1>Successfully Added {type}</h1>
              )}
            </div>
          )}
        </Dialog>
      </div>

      <div style={{ marginTop: "3rem" }}>
        {listLoader ? (
          <Skeleton variant="rect" width="100%">
            <div style={{ paddingTop: "40%", margin: "3rem 0" }} />
          </Skeleton>
        ) : (
          <React.Fragment>
            {type == "Assessment" ? (
              <AssessmentAndAssignmentList
                list={assessAndAssignList}
                type="Assessment"
                courseId={courseId}
              />
            ) : (
              <AssessmentAndAssignmentList
                list={assessAndAssignList}
                courseId={courseId}
              />
            )}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
}
