import React, { useState, useEffect, useRef } from "react";
import "./Course.scss";
import { FaBars } from "react-icons/fa";
import Tooltip from "@material-ui/core/Tooltip";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setCookie } from "react-use-cookie";

import { awsSignIn, authData, awsSignOut } from "../../redux/auth/authSlice";
import UserHeader from "../Header/UserHeader/UserHeader";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import { IconButton } from "@material-ui/core";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Modal from "@material-ui/core/Modal";
import { Dialog } from "@material-ui/core";

import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import NothingHereYet from "../../assets/images/nothing-here-icon.png";
import BreadcrumbShorthand from "../../modules/Breadcrumb/BreadcrumbShorthand";
import { Button } from "@material-ui/core";
import ReactPlayer from "react-player";
import useWindowDimensions from "../../modules/Window/Window";
import QuizIc from "../../assets/svgjs/Quiz";
import PdfIcon from "../../assets/svgjs/Pdf";
import ClockGray from "../../assets/svgjs/ClockGray";
import Done from "../../assets/svgjs/Done";
import RatingStar from "../../modules/Rating/Rating";
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import { API } from "aws-amplify";
// import CourseTab from "../../components/Tabs/courseTab";
import { Tab } from "semantic-ui-react";
import Video from "../../assets/svgjs/Video";
import Review from "../../assets/svgjs/Review";
import Html from "../../assets/svgjs/Html";
import Discussion from "./Discussion/Discussion";
import Assessment from "../../modules/Assessment/Assessment";
import Skeleton from "@material-ui/lab/Skeleton";
import Assignment from "../../modules/Assignment/Assignment";
import { List, ListItem } from "@material-ui/core";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import Fullscreen from "@material-ui/icons/Fullscreen";
import screenfull from "screenfull";
import { css } from "jquery";
// import UserHeader from "../../components/Header/UserHeader/UserHeader";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: "20px",
    height: "60vh",
    overflowY: "auto",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },

  tooltipFull: {
    fontSize: 30,
  },
  bottomIcons: {
    color: "#000",
    "&:hover": {
      color: "#777",
    },
  },
  volumeButton: {
    color: "#000",
    fontSize: 40,
    transform: "scale(0.9)",
    "&:hover": {
      color: "#777",
      transform: "scale(1.03)",
      transitionTimingFunction: "cubic-bezier(0.1, 0.1, 0.25, 1)",
    },
  },
  volumeButtonExit: {
    color: "lightgray",
    fontSize: 40,
    transform: "scale(0.9)",
    "&:hover": {
      color: "#777",
      transform: "scale(1.03)",
      transitionTimingFunction: "cubic-bezier(0.1, 0.1, 0.25, 1)",
    },
  },
}));

export default function Course({ handleToggleSidebar }) {
  const classes = useStyles();
  //const [course, setCourse] = useState({tdescription:"Course Description"});
  const [courseDetails, setCourseDetails] = useState({
    tdescription: "Course Description",
  });
  const [objectData, setObjectData] = useState({});
  const [oIndex, setOIndex] = useState(0);
  const [curObject, setcurObject] = useState({});
  const curObRef = useRef({});
  const [assignment, setAssignment] = useState(false);
  const [assignmentLoad, setAssignmentLoad] = useState(true);
  const [fullscreen, setFullScreen] = useState(true);

  //const [objects, setObjects] = useState([]);
  const [nuggets, setNuggets] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showPage, setShowPage] = useState(true);
  const [summative, setSummative] = useState(false);
  const [sumScore, setSumScore] = useState(false);

  const { height, width } = useWindowDimensions();

  const [live, setLive] = useState([]);
  const [liveLoad, setLiveLoad] = useState(true);
  const [liveopen, setLiveOpen] = useState(false);
  const [textopen, setTextOpen] = useState(false);
  const live_url = useRef("");
  const references = useRef("");
  const [qisLoading, setQIsLoading] = useState(false);
  const [sMiniScore, setSMiniScore] = useState(false);
  const [miniScore, setMiniScore] = useState(0);
  const full1 = useRef(null);

  const redux = useRef({});

  const scrollRef = useRef();

  const accRef = useRef();

  let navigate = useNavigate();
  let userDetails = useSelector(authData);
  const dispatch = useDispatch();
  let lcourseDetails = userDetails.curprgcou;

  console.log("l", lcourseDetails);
  console.log(userDetails);

  function toggleFullScreen() {
    screenfull.toggle(full1.current);
  }

  function onFullScreen() {
    setFullScreen(!fullscreen);
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    scrollRef.current.scrollTo(
      0,
      document.getElementById("acc" + panel).offsetTop
    );
  };

  useEffect(() => {
    getCourse();
    getCourseVideo();

    reduxRestore();
  }, []);

  function reduxRestore() {
    let sdata = { ...userDetails };
    redux.current = sdata;
  }
  async function getCourseVideo() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        cid: lcourseDetails.bcid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    // alert(JSON.stringify(bodyParam.body));
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        // Constants.GET_COURSE_VIDEO,
        "/getcoursevideo",
        bodyParam
      );

      // alert(JSON.stringify(response));
      console.log(response);
      setLiveLoad(false);
      setLive(response.video);
    } catch (error) {
      console.error(error);
    }
  }
  async function getCourse() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.eid,
        pid: lcourseDetails.pid,
        bpid: lcourseDetails.bpid,
        courseid: lcourseDetails.tid,
        bcourseid: lcourseDetails.bcid,
        ptitle: lcourseDetails.ptitle,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    //alert(JSON.stringify(bodyParam.body));
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_COURSE,
        bodyParam
      );
      //alert(JSON.stringify(response));
      console.log(response);
      const topicsJSON = response.nuggets;
      function groupByKey(array, key) {
        return array.reduce((hash, obj) => {
          if (obj[key] === undefined) return hash;
          return Object.assign(hash, {
            [obj[key]]: (hash[obj[key]] || []).concat(obj),
          });
        }, {});
      }
      var result = groupByKey(topicsJSON, "unit");
      let temp = [];
      for (let i = 0; i < topicsJSON.length; i++) {
        for (let j = 0; j < topicsJSON[i].objects.length; j++) {
          temp.push(topicsJSON[i].objects[j]);
        }
      }

      setNuggets(result);
      console.log(response);
      setCourseDetails(response);
      setShowPage(response.tenabled);
      setObjectData(temp);
      saveLocale(response);
      setIsLoading(false);
      let sdata = { ...redux.current };
      dispatch(awsSignIn(sdata));
    } catch (error) {
      // alert(JSON.stringify(error));
      console.error(error);
    }
  }

  async function syncUserProgress(userProgressData) {
    // let lcourseDetails = userDetails.curprgcou;
    setIsLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.eid,
        pid: lcourseDetails.pid,
        bpid: lcourseDetails.bpid,
        courseid: courseDetails.tid,
        bcourseid: courseDetails.btid,
        ptitle: lcourseDetails.ptitle,
        courseProgress: userProgressData,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    //alert(JSON.stringify(bodyParam.body));
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.SYNC_USER_PROGRESS,
        bodyParam
      );

      console.log(response);
      analyticsWebApp();
      getCourse();
      get();
    } catch (error) {
      // alert(JSON.stringify(error));
      console.error(error);
    }
  }

  async function get() {
    let userdata = { ...userDetails };

    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userdata.eid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      let response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_USER_PROGRESS,
        bodyParam
      );

      console.log("HIb " + JSON.stringify(response));
      //response.bpdata[0].tcourses[0].per = 100;
      userdata.data = response;
      //let encryptedString = cryptr.encrypt(JSON.stringify(userDetails));
      //dispatch(awsSignIn(userdata));
      //getEvents(UserProgressDetailsJSON);
      dispatch(awsSignIn(userdata));
    } catch (error) {
      //alert(JSON.stringify(error))
      console.error(error);
    }
  }

  async function analyticsWebApp() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        topicid: lcourseDetails.tid,
        eventtype: "Topic Subscribed",
        email: userDetails.eid,
        id: userDetails.id,
        gender: "Unknown",
        logintype: "Cognito",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.ANALYTICS_WEB_APP,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );
    } catch (error) {
      console.log("getCategoryError", error);
    }
  }

  const saveLocale = (data) => {
    //alert(data.Policy)
    const expires = new Date().getTime() + 60 * 60 * 1000;
    setCookie("CloudFront-Expires", expires);
    setCookie("CloudFront-Policy", data.Policy);
    setCookie("CloudFront-Signature", data.Signature);
    setCookie("CloudFront-Key-Pair-Id", data.KeyPairId);
  };
  const getAssignmentDataPost = async (quizid) => {
    setQIsLoading(true);
    setSMiniScore(false);
    setAssignmentLoad(true);
    setMiniScore(0);
    const bodyParam = {
      body: {
        quizid: quizid,
        oid: config.aws_org_id,
        eid: userDetails.eid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log(bodyParam);
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_ASSIGNMENT_DATA,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );
      // alert('hello');

      // alert(JSON.stringify(response.score));

      if (response.errorMessage !== undefined) {
        setAssignment(true);
      } else {
        if (response.score !== undefined) {
          setSMiniScore(true);
          // alert(response.score);
          setMiniScore(response.score);
        }
        setAssignment(false);
      }
      setQIsLoading(false);
      setAssignmentLoad(false);
      // return response;
    } catch (error) {
      console.log("getCategoryError", error);
    }
  };

  async function getQuizScore(curobj) {
    // alert(JSON.stringify(curobj));
    setSummative(false);
    setQIsLoading(true);
    const bodyParam = {
      body: {
        eid: userDetails.eid,
        obid: curobj.oid,
        oid: config.aws_org_id,
        rtype: "get",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log(JSON.stringify(bodyParam));
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.QUIZ_SCORE,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );
      // alert('hello');

      // alert(JSON.stringify(response));
      if (response != undefined || response != null) {
        setSumScore(response);
        setSummative(true);
      }

      setQIsLoading(false);
    } catch (error) {
      console.log("getCategoryError", error);
    }
  }

  function CourseStructure() {
    return (
      <div className={classes.root}>
        <Typography component="div" variant="h1">
          {isLoading ? <Skeleton /> : null}
        </Typography>
        <Typography component="div" variant="h1">
          {isLoading ? <Skeleton /> : null}
        </Typography>
        <Typography component="div" variant="h1">
          {isLoading ? <Skeleton /> : null}
        </Typography>
        <Typography component="div" variant="h1">
          {isLoading ? <Skeleton /> : null}
        </Typography>
        {/* <pre>{JSON.stringify(topics, null, 2)}</pre> */}
        {Object.keys(nuggets).map((nugget, idx) => (
          <Accordion
            ref={accRef}
            id={"acc" + idx}
            key={idx}
            expanded={expanded === `${idx}`}
            onChange={handleChange(`${idx}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.heading}>
                <h2 className="size-m-b">{nugget}</h2>
              </Typography>
            </AccordionSummary>
            {nuggets[nugget].map(
              ({ nid, unit, ntitle, nduration, objects, nenabled }, idx) => (
                <div>
                  <div className="display-flex padding2">
                    <h3 className="item" style={{ fontWeight: "bold" }}>
                      {/* <span style={{ paddingRight: '1rem' }}>{idx + 1}.</span>{' '} */}
                      {ntitle}
                    </h3>
                    <p style={{ fontSize: "14px" }}>
                      <span>
                        <ClockGray
                          className="clock-size--s"
                          cls1="cls1-s"
                          cls2="cls2-s"
                        />
                      </span>
                      {nduration} min
                    </p>
                  </div>
                  {objects.map(
                    ({
                      oid,
                      otitle,
                      odescription,
                      otype,
                      oduration,
                      ourl,
                      userOP,
                    }) => (
                      <div
                        className="flex size"
                        style={{
                          justifyContent: "space-between",
                          width: "calc(100% - 4.5%)",
                        }}
                      >
                        <p style={{ cursor: "pointer" }}>
                          <span>
                            {userOP.op === 2 || userOP.op === 1
                              ? renderSwitch(otype)
                              : renderSwitchGray(otype)}
                          </span>
                          <a
                            style={{ paddingLeft: "10px" }}
                            href
                            onClick={() => {
                              onClickObject(oid);
                            }}
                          >
                            {otitle}
                          </a>
                        </p>
                        <span>
                          {userOP.op === 2 ? (
                            <Done
                              className="Done_size"
                              cls1="cls1_D"
                              cls2="cls2_D"
                            />
                          ) : null}
                        </span>
                      </div>
                    )
                  )}
                </div>
              )
            )}
          </Accordion>
        ))}
      </div>
    );
  }

  function onClickObject(oid) {
    //alert(JSON.stringify(rowData));

    let temp = 0;
    for (let i = 0; i <= objectData.length; i++) {
      if (oid == objectData[i].oid) {
        temp = i;
        break;
      }
    }
    //alert(temp);
    setOIndex(temp);
    setcurObject(objectData[temp]);
    if (objectData[temp].otype === "quiz") {
      getAssignmentDataPost(objectData[temp].oid);
      getQuizScore(objectData[temp]);
    }
    curObRef.current = objectData[temp];
    // let sdata = {...userDetails};
    // sdata.oindex = temp;
    // dispatch(awsSignIn(sdata));
    //setOpen(true);
    //navigate("/coursePlayer");
    //alert("objectDetailsDATA "+JSON.stringify(temp));
    if (courseDetails.userTP != 0) {
      //dispatch(awsSignIn(sdata));
      setOpen(true);
      //navigate("/coursePlayer");
      // navigation.navigate('CoursePlayer', { topicId: topicId.current, courseDetails: courseDetailsRef.current, objectDetails: objectDetails, index: temp, exit });
    }
  }

  const handleClose = () => {
    getCourse();
    setOpen(false);
  };

  const handleLiveClose = () => {
    setLiveOpen(false);
  };
  const handleTextClose = () => {
    setTextOpen(false);
  };

  const renderSwitch = (param) => {
    if (param === "video") {
      return <Video className="video-size" cls1="cls-1--v" cls2="cls-2--v" />;
    } else if (param === "audio") {
      return <div className="audio"></div>;
    } else if (param === "quiz") {
      return <QuizIc className="quizAct" />;
    } else if (param === "html") {
      return (
        <Html
          className="video-size"
          cls1="cls-1--H"
          cls2="cls-2--H"
          cls3="cls-3--H"
        />
      );
    } else if (param === "interactivity") {
      return <Review className="video-size" cls1="cls-1--R" cls2="cls-2--R" />;
    } else if (param === "pdf") {
      return <PdfIcon className="pdfAct" />;
    }
  };
  const renderSwitchGray = (param) => {
    if (param === "video") {
      return (
        <Video className="video-size" cls1="cls-1--v-g" cls2="cls-2--v-g" />
      );
    } else if (param === "audio") {
      return <div className="audio"></div>;
    } else if (param === "quiz") {
      return <QuizIc className="quizdark" />;
    } else if (param === "html") {
      return (
        <Html
          className="video-size"
          cls1="cls-1--H-g"
          cls2="cls-2--H-g"
          cls3="cls-3--H-g"
        />
      );
    } else if (param === "interactivity") {
      return (
        <Review className="video-size" cls1="cls-1--R-g" cls2="cls-2--R-g" />
      );
    } else if (param === "pdf") {
      return <PdfIcon className="pdfGray" />;
    }
  };
  var panes = [
    {
      menuItem: "Course structure",
      render: () => (
        <Tab.Pane>
          <CourseStructure />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Discussion",
      render: () => (
        <Tab.Pane>
          <Discussion courseId={lcourseDetails.bcid} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Assignment",
      render: () => (
        <Tab.Pane>
          <Assignment
            courseId={lcourseDetails.bcid}
            progId={lcourseDetails.bpid}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Assessment",
      render: () => (
        <Tab.Pane>
          <Assessment
            courseId={lcourseDetails.bcid}
            progId={lcourseDetails.bpid}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Recorded session",
      render: () => (
        <Tab.Pane>
          <LiveSession />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Reference Textbooks",
      render: () => (
        <Tab.Pane>
          <Textbooks />
        </Tab.Pane>
      ),
    },
  ];


  if (lcourseDetails?.coursetype == "Project") {
    panes = panes.filter((pane) => pane.menuItem === "Course structure");
  }

  function Textbooks() {
    return (
      <div>
        <Typography component="div" variant="h1">
          {isLoading ? <Skeleton /> : null}
        </Typography>
        <Typography component="div" variant="h1">
          {isLoading ? <Skeleton /> : null}
        </Typography>
        <Typography component="div" variant="h1">
          {isLoading ? <Skeleton /> : null}
        </Typography>
        <Typography component="div" variant="h1">
          {isLoading ? <Skeleton /> : null}
        </Typography>
        {/* <pre>{JSON.stringify(topics, null, 2)}</pre> */}
        {courseDetails.reference !== undefined ? (
          courseDetails.reference.map((value, idx) => (
            <List>
              <div
                style={{ padding: "14px", cursor: "pointer" }}
                onClick={() => {
                  refOpen(value);
                }}
              >
                <h2 className="size-m-b">{value.title}</h2>
                {value.authors.map((author) => (
                  <h4 style={{ padding: "10px" }}>{author}</h4>
                ))}

                <div></div>
              </div>
              <hr />
            </List>
          ))
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              fontSize: "16px",
            }}
          >
            <img src={NothingHereYet} alt="nothing here, yet." />
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <p>There's nothing here, yet. </p>
            </div>
          </div>
        )}
      </div>
    );
  }

  function refOpen(value) {
    references.current = value;
    if (value.type === "link") {
    } else {
      /*  https://dhgdihuh7pgku.cloudfront.net/jssaher-content/CR013/html/CR013-3-3.pdf */
      setTextOpen(true);
    }
  }

  function LiveSession() {
    return (
      <div style={{ width: "100%", height: "100%" }}>
        {liveLoad == true ? (
          <div> </div>
        ) : (
          <div>
            {live.length == 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  fontSize: "16px",
                }}
              >
                <img src={NothingHereYet} alt="nothing here, yet." />
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <p>There's nothing here, yet. </p>
                </div>
              </div>
            ) : (
              <div>
                {live.map((Value, index, array) => {
                  return (
                    <div
                      style={{
                        width: "100%",
                        height: "64px",
                        borderBottom: "1px solid lightgray",
                        display: "flex",
                        alignItems: "center",
                        margin: "6px",
                      }}
                    >
                      <div style={{ flex: "1" }}>
                        <div>
                          <h3
                            style={{
                              fontWeight: "500",
                              paddingBottom: "8px",
                            }}
                          >
                            {Value.title}
                          </h3>
                        </div>

                        <div>
                          <h4 style={{ marginBottom: "4px", fontSize: "14px" }}>
                            {Value.date}
                          </h4>
                        </div>
                      </div>
                      <div style={{ width: "100px", marginRight: "35px" }}>
                        <Button
                          onClick={() => {
                            viewLive(Value.ourl);
                          }}
                          variant="contained"
                          color="primary"
                        >
                          View Session
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  function viewLive(id) {
    live_url.current = `https://${Constants.DOMAIN
      }/${config.aws_org_id.toLowerCase()}-resources/videos/coursevideos/${id}`;
    setLiveOpen(true);
  }
  function VideoView() {
    return (
      <div style={{ display: "flex", height: "78%", backgroundColor: "white" }}>
        <div style={{ height: "100%", width: "100%" }}>
          <div style={{ width: "100%", height: "26px" }}>
            <span
              style={{
                float: "right",
                cursor: "pointer",
                color: "black",
                fontSize: "26px",
                paddingRight: "20px",
              }}
              onClick={() => {
                handleLiveClose();
              }}
            >
              x
            </span>
          </div>
          <div
            style={{ height: height - 300, width: width - 300, padding: "2%" }}
          >
            <div
              className="player-wrapper"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <ReactPlayer
                url={live_url.current}
                controls={true}
                width="100%"
                height={height - 300}
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload", //<- this is the important bit
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
  function TextBookView() {
    let url = `https://${Constants.DOMAIN
      }/${config.aws_org_id.toLowerCase()}-resources/references/${references.current.url
      }`;

    //alert(url);

    return (
      <div
        style={{
          display: "flex",
          height: "78%",
          backgroundColor: "white",
        }}
      >
        <div style={{ height: "100%", width: "100%" }}>
          <div style={{ width: "100%", height: "26px" }}>
            <span
              style={{
                float: "right",
                cursor: "pointer",
                color: "black",
                fontSize: "26px",
                paddingRight: "20px",
              }}
              onClick={() => {
                handleTextClose();
              }}
            >
              x
            </span>
          </div>
          <div
            style={{ height: height - 300, width: width - 300, padding: "2%" }}
          >
            <div
              className="player-wrapper"
              style={{
                display: "flex",
                justifyContent: "center",
                height: "inherit",
                position: "relative",
              }}
            >
              {" "}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                  overflowX: "hidden",
                }}
              >
                <iframe
                  src={url}
                  height="100%"
                  width="100%"
                  title="textbook"
                ></iframe>
              </div>
              <IconButton
                style={{
                  position: "absolute",
                  bottom: "28px",
                  right: "50px",
                }}
                onClick={() => {
                  toggleFullScreen();
                  onFullScreen();
                }}
                className={classes.bottomIcons}
              >
                {fullscreen ? (
                  <Tooltip
                    title={
                      <p style={{ fontSize: "13px" }}>
                        Click here for full-screen mode
                      </p>
                    }
                    placement="top"
                  >
                    <Fullscreen
                      fontSize="large"
                      className={classes.volumeButton}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={
                      <p style={{ fontSize: "13px" }}>
                        Click here for exit full-screen mode
                      </p>
                    }
                    placement="top"
                  >
                    <FullscreenExitIcon
                      fontSize="large"
                      className={classes.volumeButtonExit}
                      style={{ bottom: "200px" }}
                    />
                  </Tooltip>
                )}
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderButton() {
    if (courseDetails.userTP == 0) {
      return (
        <div
          style={{
            display: "flex",
            // marginTop: '50px ',
            width: "100%",
            height: "50px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            style={{
              color: "white",
              borderStyle: "groove ",
              borderColor: "#E35F14 ",
              height: "30px ",
              lineHeight: "0px ",
              width: "150px",

              backgroundColor: "#E35F14",
              fontSize: "16px ",
              borderRadius: "3px ",

              fontWeight: "bold",
            }}
            onClick={startCourse}
          >
            Start
          </button>
        </div>
      );
    }
  }

  function startCourse() {
    let data = courseDetails.userProgressData;
    // alert(JSON.stringify(data));
    data[courseDetails.btid].tp = 1;
    data[courseDetails.btid].td.sd = Math.round(new Date().getTime() / 1000);
    data[courseDetails.btid].objects[
      courseDetails.nuggets[0].objects[0].oid
    ].op = 1;
    syncUserProgress(data);
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        padding: "25px",
        overflowY: "auto",
      }}
      ref={scrollRef}
    >
      {/* <pre> {JSON.stringify(user, null, 2)}</pre> */}

      <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
        <FaBars />
      </div>
      <UserHeader />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div
            style={{ display: "flex", height: "80%", backgroundColor: "white" }}
          ></div>
        </Fade>
      </Modal>

      {/* <Dialog  open={open}       
      scroll="body" 
      maxWidth="md" > 
      <Fade in={open}>
          <div
            style={{ display: "flex", height: "80%", backgroundColor: "white" }}
          >
          
          </div>
        </Fade>

      </Dialog> */}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={liveopen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={liveopen}>
          <VideoView />
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={textopen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        ref={full1}
      >
        <Fade in={textopen}>
          <TextBookView />
        </Fade>
      </Modal>

      <div className="text-align">{/*  <BreadcrumbShorthand /> */}</div>
      {showPage == true || showPage == "true" ? (
        <div className="management">
          <div className="h1">
            <h1 style={{ color: "#E35F14", fontSize: "20px" }}>
              <strong>{isLoading ? <Skeleton /> : courseDetails.ttitle}</strong>
            </h1>
          </div>
          <div className="management-info">
            <div className="flex-box">
              <div className="flex-box__container">
                <div className="time-line">
                  <div>
                    <span>
                      <ClockGray
                        className="clock-size"
                        cls1="cls1"
                        cls2="cls2"
                      />
                    </span>
                    <p>
                      {isLoading ? <Skeleton /> : courseDetails.tduration} weeks
                    </p>
                  </div>
                  {/* <div>
                  <RatingStar />
                </div>
                <div>
                  <p>500 students</p>
                </div>
              
              <div>
                <p>
                  Author: <strong>Mr.Shekar</strong>
                </p>
              </div> */}
                </div>
                <div>
                  <p style={{ fontSize: "15px" }}>
                    {courseDetails.tdescription}{" "}
                  </p>
                  {isLoading ? <Skeleton /> : renderButton()}
                </div>
              </div>
              {isLoading ? (
                <Skeleton variant="rect" width="100%">
                  <div style={{ paddingTop: "57%" }} />
                </Skeleton>
              ) : (
                <div
                  className="management__image"
                  style={{
                    backgroundImage: `url('https://${Constants.DOMAIN
                      }/${config.aws_org_id.toLowerCase()}-resources/images/topic-images/${courseDetails.tid
                      }.png')`,
                  }}
                ></div>
              )}
            </div>
          </div>
          {/* {topics.map((topic) => console.log(topic.unit))} */}
          <Tab panes={panes} style={{ marginTop: "20px" }} />
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1>The Course Is Disabled From Admin.</h1>
        </div>
      )}
    </div>
  );
}
