import React, { useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Constants } from "../../config/constants";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import { CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import "./CreateQuiz.scss";
// Swiper
import "swiper/swiper.scss";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/components/navigation/navigation.scss";
// import Swiper core and required modules
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Controller,
} from "swiper";

import TextField from "@material-ui/core/TextField";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import config from "../../config/aws-exports";
import { useState, useRef } from "react";

import { produce } from "immer";
import { generate } from "shortid";
import axios from "axios";
import { API, JS } from "aws-amplify";
import { AlertTitle } from "@material-ui/lab";

const CustomButton = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "6px 15px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: "#fff",
    borderColor: "#01498e",
    color: "#01498e",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      backgroundColor: "#01498e",
      border: "1px solid #fff",
      color: "#fff",
      boxShadow: "#fff",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#fff",
      borderColor: "#01498e",
    },
    "&:focus": {
      //   boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  },
})(Button);
const quizStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& .MuiTypography-body1": {
      flexGrow: "1",
    },
    "& .MuiFormControlLabel-root": {
      marginRight: "0",
    },
  },
  displayFlex: {
    display: "flex",
    alignItems: "center",
    gap: "2rem",
    width: "100%",
    justifyContent: "center",
  },
  displayFlexs: {
    display: "flex",
    // alignItems: "center",
    gap: "2rem",
    width: "60%",
    // justifyContent: "center",
  },
  flexBasis: {
    flexBasis: "15%",
  },
  textCenter: {
    textAlign: "center",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  container: {
    display: "flex",
    gap: "2rem",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  quizButtonsContainer: {
    display: "flex",
    gap: "2rem",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "30px",
  },
  input: {
    display: "none",
  },
  uploadBtn: {
    color: "#fff",
    background: "#01498e",
    padding: ".5rem 5rem",
    fontSize: "1.4rem",
    "&:hover": {
      backgroundColor: "#01498e",
      color: "#fff",
      boxShadow: "#fff",
    },
  },
  fontSize: {},
  small: {
    fontSize: "1rem",
  },
}));
const BlueRadio = withStyles({
  root: {
    color: "#01498e",
    "&$checked": {
      color: "#01498e",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
const initialValues = [
  { content: "" },
  { content: "" },
  { content: "" },
  { content: "" },
  { content: "" },
  { content: "" },
];
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Virtual, Controller]);
export default function CreateQuiz({
  folder,
  setSelectedObj,
  selectedObj,
  finalSave,
  typesButtons,
  setTypeButtons,
  quizQuestion,
  setQuizQuestion,
  editQuiz,
  editquizincourse,
  handleClose,
}) {
  const classes = quizStyles();
  const [aType, setAType] = useState("");

  const [quizForm, setQuizForm] = useState(false);
  const [titleForm, setTitleForm] = useState(false);
  // const [typesButtons, setTypeButtons] = useState(true);
  // const [tid, setTid] = useState(courseDetails.tid);
  const [cutOff, setCutOff] = useState(false);
  const [cutOffVal, setCutOffVal] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [value, setValue] = useState("");
  const [title, setTitle] = useState("");
  const [values, setValues] = useState(initialValues);
  const [istem, setIstem] = useState("");
  const [swiperRef, setSwiperRef] = useState(null);
  const [questionObj, setQuestionObj] = useState({ currentq: 0 });
  const [quesType, setQuesType] = useState("");
  const [feedbackView, setFeedbackView] = useState(false);
  const [miniAssignment, setMiniAssignment] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [nquestion, setNquestion] = useState(false);

  // const [quizQuestion, setQuizQuestion] = useState([]);

  const initialValidation = {
    error1: false,
    error2: false,
    error3: false,
    error4: false,
    error5: false,
    error6: false,
    error7: false,
    error8: false,
    error9: false,
    error09: false,
    error10: false,
  };
  const initialState = {
    opt1: false,
    opt2: false,
    opt3: false,
    opt4: false,
    opt5: false,
    opt6: false,
  };

  const initialMatchTheFollowing = [
    { id: generate(), value1: "", value2: "" },
    { id: generate(), value1: "", value2: "" },
    { id: generate(), value1: "", value2: "" },
    { id: generate(), value1: "", value2: "" },
  ];

  const [tFOptions, setTFOptions] = useState([
    { content: "True" },
    { content: "False" },
  ]);

  const [addQestBtn, setAddQestBtn] = useState("false");
  const [quizType, setQuizType] = useState("");
  const [multiform, setMultiForm] = useState(false);
  const [editmultiform, setEditMultiForm] = useState(false);
  const [miniAssigmentform, setMiniAssigmentform] = useState(false);
  const [instructions, setInstructions] = useState([{ value: "" }]);
  const [multiSelectValidation, setMultiSelectValidation] =
    useState(initialValidation);
  const [questionValidation, setQuestionValidation] =
    useState(initialValidation);

  const [selectminiassigmentType, setSelectMiniAssigmentType] = useState(false);
  const [miniassigmentType, setMiniassigmentType] = useState("");
  const [multiSelectImageBase, setMultiSelectImageBase] =
    useState(initialValidation);
  const [singleSelectImageBase, setSingleSelectImageBase] =
    useState(initialValidation);
  const [state, setState] = useState(initialState);
  const [defaultValidation, setDefaultValidation] = useState(initialValidation);
  const [matchTheFollowing, setMatchTheFollowing] = useState(
    initialMatchTheFollowing
  );

  const [progress, setProgress] = useState(false);
  const [success, setSuccess] = useState(false);
  const [textValues, setTextValues] = useState([""]);
  const [deadLine, setDeadLine] = useState(false);
  const [deadLineVal, setDeadLineVal] = useState("");

  // set error
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);
  const [error3, setError3] = useState(false);
  const [error4, setError4] = useState(false);
  const [error5, setError5] = useState(false);
  const [error6, setError6] = useState(false);
  const [error7, setError7] = useState(false);
  const [error8, setError8] = useState(false);
  const [error9, setError9] = useState(false);
  const onFileChange = (event) => {
    if (event.target.files[0] !== undefined) {
      setSelectedFile({ file: event.target.files[0] });
      // setSelectedFile({ filename: null });
      const file = event.target.files[0];
      let id = Math.floor(Math.random() * (999 - 100 + 1) + 100);
      setQuizQuestion((currentistem) =>
        produce(currentistem, (v) => {
          v[questionObj.currentq - 1].myfilename = event.target.files[0].name;
          v[questionObj.currentq - 1].imageurl = id + ".png";
          v[questionObj.currentq - 1].image = true;
        })
      );
      uploadfile(event.target.files[0], id);
    }
  };
  useEffect(() => {
    console.log("shiva" + JSON.stringify(selectedObj, null, 2));
    if (selectedObj.qtype !== undefined) {
      addQuesType(selectedObj.qtype);
      if (selectedObj.quizQuestion !== undefined) {
        for (let k = 0; k < selectedObj.quizQuestion.length; k++) {
          if (selectedObj.qtype != 3) {
            if (selectedObj.quizQuestion[k].iopts.length > 0) {
              for (let j = 0; j < 6; j++) {
                if (selectedObj.quizQuestion[k].iopts[j] === undefined) {
                  let obj = {
                    content: "",
                    correct: "",
                  };
                  selectedObj.quizQuestion[k].iopts[j] = obj;
                }
                if (
                  selectedObj.quizQuestion[k].iopts[j] !== undefined &&
                  selectedObj.quizQuestion[k].iopts[j].correct == true
                ) {
                  selectedObj.quizQuestion[k].correctoption = j.toString();
                }
              }
            }
          }
        }

        setQuizQuestion(selectedObj.quizQuestion);
      }

      if (selectedObj.qtitle) {
        setTitle(selectedObj.qtitle);
      }
      if (selectedObj.cutoff) {
        setCutOffVal(selectedObj.cutoff);
      }

      if (selectedObj.miniassignmenttype != undefined) {
        setMiniassigmentType(selectedObj.miniassignmenttype);
      }
    }
  }, []);

  const handleChange = (event, qidx) => {
    setValue(event.target.value);
    setQuizQuestion((option) =>
      produce(option, (v) => {
        v[qidx].iopts.forEach((opts) => {
          opts.correct = false;
        });
      })
    );
    const correctoptionvalue = event.target.value;
    console.log(correctoptionvalue);
    setQuizQuestion((option) =>
      produce(option, (v) => {
        v[qidx].iopts[correctoptionvalue].correct = true;
      })
    );
    setQuizQuestion((option) =>
      produce(option, (v) => {
        v[qidx].correctoption = correctoptionvalue;
      })
    );
  };

  const handleInputChange = (e, oidx, qidx) => {
    const { name, value } = e.target;
    setQuizQuestion((currentoptions) =>
      produce(currentoptions, (v) => {
        v[qidx].iopts[oidx].content = value;
      })
    );
  };
  const handleFeedbackChange = (e, oidx, qidx) => {
    const { name, value } = e.target;
    setQuizQuestion((currentoptions) =>
      produce(currentoptions, (v) => {
        v[qidx].iopts[oidx].feedback = value;
      })
    );
  };
  const reset = () => {
    setValues(initialValues);
    setIstem("");
    setSelectedFile("");
    setValue("");
  };

  const addQuestion = (idx) => {
    if (miniAssignment == true) {
      if (miniassigmentType == 1 || miniassigmentType == "1") {
        setQuizForm(false);
        setMiniAssigmentform(false);
        setMultiForm(true);
      } else if (
        miniassigmentType == 3 ||
        miniassigmentType == "3" ||
        miniassigmentType == 4 ||
        miniassigmentType == "4"
      ) {
        setQuizForm(false);
        setMiniAssigmentform(true);
      } else {
        setQuizForm(true);
      }
    } else {
      setQuizForm(true);
    }
    setTitleForm(false);
    setTypeButtons(false);
    setQuizQuestion((currentQuestion) => [
      ...currentQuestion,
      {
        iid: generate(),
        istem: "",
        iopts: initialValues,
      },
    ]);
    console.log("currentQuestion===", questionObj.currentq);
    let cq = currentQuestion + 1;
    setCurrentQuestion(cq);
    questionObj.currentq = quizQuestion.length + 1;

    setQuestionObj((currentQuestion) => ({ ...questionObj }));
    //setCurrentQuestion((currentState) => (currentQuestion +1));
    console.log("currentQuestion===", questionObj.currentq);
    setError1(false);
    setError2(false);
    setError3(false);
    setError4(false);
    setError5(false);
    setError6(false);
    setError7(false);
    setError8(false);
    reset();
  };
  const editObjNext = (idx) => {
    setDefaultValidation(initialValidation);
    if (selectedObj.miniassignmenttype === undefined) {
      setQuizForm(true);
    } else if (
      selectedObj.miniassignmenttype === 1 ||
      selectedObj.miniassignmenttype === "1"
    ) {
      setEditMultiForm(true);
      let questiondt = [...selectedObj.quizQuestion];
      setQuizType(selectedObj.quizQuestion[0].atype);
      if (selectedObj.quizQuestion[0].atype == 6) {
        let tbdata = selectedObj.quizQuestion[0].istem.replace(
          "<table class='qtable'>",
          ""
        );
        tbdata = tbdata.replace("</table>", "");
        let rmindx = tbdata.indexOf("</tr>");
        let rmtxt = tbdata.substr(0, rmindx);
        tbdata = tbdata.replace(rmtxt, "");
        tbdata = tbdata.split("<tr>");
        setMatchTheFollowing("");
        for (var j = 1; j < tbdata.length; j++) {
          let tdarr = tbdata[j].split("<td>");
          let vl1 = tdarr[1].replace("</td>", "").split(".");
          let vl2 = tdarr[2]
            .replace("</td>", "")
            .replace("</tr>", "")
            .split(".");
          setMatchTheFollowing((currentmatchTheFollowing) => [
            ...currentmatchTheFollowing,
            {
              id: generate(),
              value1: vl1[1],
              value2: vl2[1],
            },
          ]);
        }
      }

      if (selectedObj.quizQuestion[0].iopts !== undefined) {
        for (
          let index = 0;
          index < selectedObj.quizQuestion[0].iopts.length;
          index++
        ) {
          if (
            selectedObj.quizQuestion[0].iopts[index].correct === "true" ||
            selectedObj.quizQuestion[0].iopts[index].correct === true
          ) {
            // selectedObj.quizQuestion[0].iopts[index].correct = "true"
            questiondt = produce(questiondt, (curr) => {
              curr[0].iopts[index].correct = "true";
            });
            setValue(selectedObj.quizQuestion[0].iopts[index].content);
          }
        }
        if (questiondt[0].iopts.length < 6) {
          let myOptions = [];
          for (let j = 0; j < 6; j++) {
            if (questiondt[0].iopts[j] == undefined) {
              myOptions.push({
                content: "",
                correct: "false",
              });
            } else {
              myOptions.push(questiondt[0].iopts[j]);
            }
          }
          questiondt = produce(questiondt, (curr) => {
            curr[0].iopts = myOptions;
          });
          setQuizQuestion(questiondt);
        }
      }
      setQuizForm(false);
    } else if (
      selectedObj.miniassignmenttype === 2 ||
      selectedObj.miniassignmenttype === "2"
    ) {
      setQuizForm(true);
      if (selectedObj.quizQuestion[0].myfilename !== undefined) {
        setSelectedFile({
          myfilename: selectedObj.quizQuestion[0].myfilename,
        });
      } else {
        setSelectedFile("");
      }
    }
    setTitleForm(false);
    setTypeButtons(false);

    console.log("currentQuestion===", questionObj.currentq);
    let cq = currentQuestion + 1;
    setCurrentQuestion(cq);
    questionObj.currentq = cq;

    setQuestionObj((currentQuestion) => ({ ...questionObj }));
    //setCurrentQuestion((currentState) => (currentQuestion +1));
    console.log("currentQuestion===", questionObj.currentq);
  };
  const addQuesType = (type) => {
    setSelectMiniAssigmentType(false);

    if (type == 0 || type == 1 || type == 2 || type == 3) {
      setQuizForm(true);
      setQuesType(type);
      setTitleForm(true);
      setTypeButtons(false);

      // addQuestion(0);

      if (type == 0) {
        setQuizForm(true);
        setTitleForm(true);
        setFeedbackView(true);
      }

      if (type == 2 || type == 4 || type == 5) {
        setCutOff(true);
      }
      if (type == 3) {
        setQuizForm(true);
        setTitleForm(true);
        setSelectMiniAssigmentType(true);
        setMiniAssignment(true);
        setDeadLine(true);
      }
      setQuizQuestion([]);
      setQuestionObj({ currentq: 0 });
      setCurrentQuestion(0);
      reset();
      setCutOffVal("");
    }
  };
  const prevQues = (question) => {
    // setSelectedFile("");
    setError1(false);
    setError2(false);
    setError3(false);
    setError4(false);
    setError5(false);
    setError6(false);
    setError7(false);
    setError8(false);
    if (quesType != 3) {
      if (
        (question.istem == undefined ||
          question.istem.toString().replace(/\s/g, "").length === 0) &&
        (question.iopts[0].content == undefined ||
          question.iopts[0].content == "" ||
          question.iopts[0].content.toString().replace(/\s/g, "").length ===
            0) &&
        (question.iopts[1].content == undefined ||
          question.iopts[1].content == "" ||
          question.iopts[1].content.toString().replace(/\s/g, "").length ===
            0) &&
        (question.iopts[2].content == undefined ||
          question.iopts[2].content == "" ||
          question.iopts[2].content.toString().replace(/\s/g, "").length ===
            0) &&
        (question.iopts[3].content == undefined ||
          question.iopts[3].content == "" ||
          question.iopts[3].content.toString().replace(/\s/g, "").length ===
            0) &&
        (question.iopts[4].content == undefined ||
          question.iopts[4].content == "" ||
          question.iopts[4].content.toString().replace(/\s/g, "").length ===
            0) &&
        (question.iopts[5].content == undefined ||
          question.iopts[5].content == "" ||
          question.iopts[5].content.toString().replace(/\s/g, "").length === 0)
      ) {
        let filterPrev = quizQuestion.filter((ques) => ques.istem !== "");

        setQuizQuestion(filterPrev);
      } else {
        if (
          question.istem == undefined ||
          question.istem.toString().replace(/\s/g, "").length === 0
        ) {
          setError1(true);
          return;
        } else {
          setError1(false);
        }

        let filterOps = question.iopts.filter((opts) => opts.correct === true);
        if (
          question.iopts[0].content == undefined ||
          question.iopts[0].content == "" ||
          question.iopts[0].content.toString().replace(/\s/g, "").length ===
            0 ||
          question.iopts[1].content == undefined ||
          question.iopts[1].content == "" ||
          question.iopts[1].content.toString().replace(/\s/g, "").length === 0
        ) {
          setError2(true);
          return;
        } else {
          setError2(false);
        }
        if (question.correctoption == undefined) {
          setError3(true);
          return;
        } else {
          setError3(false);
        }

        if (filterOps[0].content === "" || filterOps[0].content.length === 0) {
          setError4(true);
          return;
        } else {
          setError4(false);
        }
        if (
          question.iopts[2].content != undefined &&
          question.iopts[2].content != ""
        ) {
          if (question.iopts[2].content.replace(/\s/g, "").length === 0) {
            setError5(true);
            return;
          } else {
            setError5(false);
          }
          if (
            question.iopts[2].content == question.iopts[3].content ||
            question.iopts[2].content == question.iopts[4].content ||
            question.iopts[2].content == question.iopts[5].content
          ) {
            setError8(true);
            return;
          } else {
            setError8(false);
          }
        }
        if (
          question.iopts[3].content != undefined &&
          question.iopts[3].content != ""
        ) {
          if (
            question.iopts[3].content.toString().replace(/\s/g, "").length === 0
          ) {
            setError5(true);
            return;
          } else {
            setError5(false);
          }
          if (
            question.iopts[2].content == undefined ||
            question.iopts[2].content.toString().replace(/\s/g, "").length === 0
          ) {
            setError6(true);
            return;
          } else {
            setError6(false);
          }
          if (
            question.iopts[3].content == question.iopts[4].content ||
            question.iopts[3].content == question.iopts[5].content
          ) {
            setError8(true);
            return;
          } else {
            setError8(false);
          }
        }
        if (
          question.iopts[4].content != undefined &&
          question.iopts[4].content != ""
        ) {
          if (question.iopts[4].content.replace(/\s/g, "").length === 0) {
            setError5(true);
            return;
          } else {
            setError5(false);
          }
          if (
            question.iopts[2].content == undefined ||
            question.iopts[2].content.replace(/\s/g, "").length === 0 ||
            question.iopts[3].content == undefined ||
            question.iopts[3].content.replace(/\s/g, "").length === 0
          ) {
            setError6(true);
            return;
          } else {
            setError6(false);
          }
          if (question.iopts[4].content == question.iopts[5].content) {
            setError8(true);
            return;
          } else {
            setError8(false);
          }
        }
        if (
          question.iopts[5].content != undefined &&
          question.iopts[5].content != ""
        ) {
          if (question.iopts[5].content.replace(/\s/g, "").length === 0) {
            setError5(true);
            return;
          } else {
            setError5(false);
          }
          if (
            question.iopts[2].content == undefined ||
            question.iopts[2].content.replace(/\s/g, "").length === 0 ||
            question.iopts[3].content == undefined ||
            question.iopts[3].content.replace(/\s/g, "").length === 0 ||
            question.iopts[4].content == undefined ||
            question.iopts[4].content.replace(/\s/g, "").length === 0
          ) {
            setError6(true);
            return;
          } else {
            setError6(false);
          }
        }
        if (
          question.iopts[0].content == question.iopts[1].content ||
          question.iopts[0].content == question.iopts[2].content ||
          question.iopts[0].content == question.iopts[3].content ||
          question.iopts[0].content == question.iopts[4].content ||
          question.iopts[0].content == question.iopts[5].content
        ) {
          setError8(true);
          return;
        } else {
          setError8(false);
        }
        if (
          question.iopts[1].content == question.iopts[2].content ||
          question.iopts[1].content == question.iopts[3].content ||
          question.iopts[1].content == question.iopts[4].content ||
          question.iopts[1].content == question.iopts[5].content
        ) {
          setError8(true);
          return;
        } else {
          setError8(false);
        }
      }
    } else {
      if (
        question.istem === undefined ||
        question.istem.toString().replace(/\s/g, "").length === 0
      ) {
        let filterQues = quizQuestion.filter((quiz) => quiz.istem !== "");
        setQuizQuestion(filterQues);
      } else {
        if (
          question.istem === undefined ||
          question.istem.toString().replace(/\s/g, "").length === 0
        ) {
          setError1(true);
          return;
        } else {
          setError1(false);
        }
      }
    }
  };
  const nextQues = (question) => {
    // setSelectedFile("");
    setError1(false);
    setError2(false);
    setError3(false);
    setError4(false);
    setError5(false);
    setError6(false);
    setError7(false);
    setError8(false);

    if (
      question.istem == undefined ||
      question.istem.replace(/\s/g, "").length === 0
    ) {
      setError1(true);
      return;
    } else {
      setError1(false);
    }
    if (quesType != 3) {
      let filterOps = question.iopts.filter((opts) => opts.correct === true);
      if (
        question.iopts[0].content == "" ||
        question.iopts[0].content == undefined ||
        question.iopts[0].content.replace(/\s/g, "").length === 0 ||
        question.iopts[1].content == "" ||
        question.iopts[1].content == undefined ||
        question.iopts[1].content.replace(/\s/g, "").length === 0
      ) {
        setError2(true);
        return;
      } else {
        setError2(false);
      }
      if (question.correctoption == undefined) {
        setError3(true);
        return;
      } else {
        setError3(false);
      }
      if (filterOps[0].content === "" || filterOps[0].content.length === 0) {
        setError4(true);
        return;
      } else {
        setError4(false);
      }

      if (
        question.iopts[2].content !== undefined &&
        question.iopts[2].content != ""
      ) {
        if (
          question.iopts[2].content.toString().replace(/\s/g, "").length === 0
        ) {
          setError5(true);
          return;
        } else {
          setError5(false);
        }
        if (
          question.iopts[2].content == question.iopts[3].content ||
          question.iopts[2].content == question.iopts[4].content ||
          question.iopts[2].content == question.iopts[5].content
        ) {
          setError8(true);
          return;
        } else {
          setError8(false);
        }
      }
      if (
        question.iopts[3].content != undefined &&
        question.iopts[3].content != ""
      ) {
        if (question.iopts[3].content.replace(/\s/g, "").length === 0) {
          setError5(true);
          return;
        }
        if (
          question.iopts[2].content === undefined ||
          question.iopts[2].content.toString(0).replace(/\s/g, "").length === 0
        ) {
          setError6(true);
          return;
        } else {
          setError6(false);
        }
        if (
          question.iopts[3].content == question.iopts[4].content ||
          question.iopts[3].content == question.iopts[5].content
        ) {
          setError8(true);
          return;
        }
      }

      if (
        question.iopts[4].content !== undefined &&
        question.iopts[4].content != ""
      ) {
        if (
          question.iopts[4].content.toString().replace(/\s/g, "").length === 0
        ) {
          setError5(true);
          return;
        }
        if (
          question.iopts[2].content === undefined ||
          question.iopts[2].content.toString().replace(/\s/g, "").length ===
            0 ||
          question.iopts[3].content === undefined ||
          question.iopts[3].content.toString().replace(/\s/g, "").length === 0
        ) {
          setError6(true);
          return;
        } else {
          setError6(false);
        }
        if (question.iopts[4].content == question.iopts[5].content) {
          setError8(true);
          return;
        }
      }
      if (
        question.iopts[5].content !== undefined &&
        question.iopts[5].content !== ""
      ) {
        if (
          question.iopts[5].content.toString().replace(/\s/g, "").length === 0
        ) {
          setError5(true);
          return;
        }
        if (
          question.iopts[2].content === undefined ||
          question.iopts[2].content.toString().replace(/\s/g, "").length ===
            0 ||
          question.iopts[3].content === undefined ||
          question.iopts[3].content.toString().replace(/\s/g, "").length ===
            0 ||
          question.iopts[4].content === undefined ||
          question.iopts[4].content.toString().replace(/\s/g, "").length === 0
        ) {
          setError6(true);
          return;
        } else {
          setError6(false);
        }
      }
      if (
        question.iopts[0].content == question.iopts[1].content ||
        question.iopts[0].content == question.iopts[2].content ||
        question.iopts[0].content == question.iopts[3].content ||
        question.iopts[0].content == question.iopts[4].content ||
        question.iopts[0].content == question.iopts[5].content
      ) {
        setError8(true);
        return;
      }
      if (
        question.iopts[1].content == question.iopts[2].content ||
        question.iopts[1].content == question.iopts[3].content ||
        question.iopts[1].content == question.iopts[4].content ||
        question.iopts[1].content == question.iopts[5].content
      ) {
        setError8(true);
        return;
      }
    }
  };

  const validate = (question) => {
    console.log("question", question);

    if (
      question.istem === undefined ||
      question.istem.toString().replace(/\s/g, "").length === 0
    ) {
      setError1(true);
      return;
    } else {
      setError1(false);
    }
    if (quesType != 3) {
      let filterOps = question.iopts.filter((opts) => opts.correct === true);

      if (
        question.iopts[0].content === undefined ||
        question.iopts[0].content == "" ||
        question.iopts[0].content.toString().replace(/\s/g, "").length === 0 ||
        question.iopts[1].content == undefined ||
        question.iopts[1].content == "" ||
        question.iopts[1].content.toString().replace(/\s/g, "").length === 0
      ) {
        setError2(true);
        return;
      } else {
        setError2(false);
      }

      if (question.correctoption == undefined) {
        setError3(true);
        return;
      } else {
        setError3(false);
      }
      if (filterOps[0].content === "" || filterOps[0].content.length === 0) {
        setError4(true);
        return;
      } else {
        setError4(false);
      }
      if (
        question.iopts[2].content !== undefined &&
        question.iopts[2].content != ""
      ) {
        if (question.iopts[2].content.replace(/\s/g, "").length === 0) {
          setError5(true);
          return;
        } else {
          setError5(false);
        }
        if (
          question.iopts[2].content == question.iopts[3].content ||
          question.iopts[2].content == question.iopts[4].content ||
          question.iopts[2].content == question.iopts[5].content
        ) {
          setError8(true);
          return;
        } else {
          setError8(false);
        }
      }
      if (
        question.iopts[3].content !== undefined &&
        question.iopts[3].content != ""
      ) {
        if (question.iopts[3].content.replace(/\s/g, "").length === 0) {
          setError5(true);
          return;
        } else {
          setError5(false);
        }
        if (
          question.iopts[2].content == undefined ||
          question.iopts[2].content.replace(/\s/g, "").length === 0
        ) {
          setError6(true);
          return;
        } else {
          setError6(false);
        }
        if (
          question.iopts[3].content == question.iopts[4].content ||
          question.iopts[3].content == question.iopts[5].content
        ) {
          setError8(true);
          return;
        } else {
          setError8(false);
        }
      }

      if (
        question.iopts[4].content !== undefined &&
        question.iopts[4].content != ""
      ) {
        if (question.iopts[4].content.replace(/\s/g, "").length === 0) {
          setError5(true);
          return;
        } else {
          setError5(false);
        }
        if (
          question.iopts[2].content == undefined ||
          question.iopts[2].content.replace(/\s/g, "").length === 0 ||
          question.iopts[3].content == undefined ||
          question.iopts[3].content.replace(/\s/g, "").length === 0
        ) {
          setError6(true);
          return;
        } else {
          setError6(false);
        }
        if (question.iopts[4].content == question.iopts[5].content) {
          setError8(true);
          return;
        } else {
          setError8(false);
        }
      }

      if (
        question.iopts[5].content !== undefined &&
        question.iopts[5].content != ""
      ) {
        if (question.iopts[5].content.replace(/\s/g, "").length === 0) {
          setError5(true);
          return;
        } else {
          setError5(false);
        }
        if (
          question.iopts[2].content == undefined ||
          question.iopts[2].content.replace(/\s/g, "").length === 0 ||
          question.iopts[3].content == undefined ||
          question.iopts[3].content.replace(/\s/g, "").length === 0 ||
          question.iopts[4].content == undefined ||
          question.iopts[4].content.replace(/\s/g, "").length === 0
        ) {
          setError6(true);
          return;
        } else {
          setError6(false);
        }
      }
      if (
        question.iopts[0].content == question.iopts[1].content ||
        question.iopts[0].content == question.iopts[2].content ||
        question.iopts[0].content == question.iopts[3].content ||
        question.iopts[0].content == question.iopts[4].content ||
        question.iopts[0].content == question.iopts[5].content
      ) {
        setError8(true);
        return;
      } else {
        setError8(false);
      }
      if (
        question.iopts[1].content == question.iopts[2].content ||
        question.iopts[1].content == question.iopts[3].content ||
        question.iopts[1].content == question.iopts[4].content ||
        question.iopts[1].content == question.iopts[5].content
      ) {
        setError8(true);
        return;
      } else {
        setError8(false);
      }
    }

    addQuestion(currentQuestion);
  };

  async function uploadfile(file, id) {
    const bodyParam = {
      body: {
        filetype: file.type,
        filename: id + ".png",
        folder: folder,
        type: "quiz",
        oid: config.aws_org_id,
        editquizincourse:
          editquizincourse == undefined ? false : editquizincourse,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log("prebody" + JSON.stringify(bodyParam.body));
      const preSignedData = await API.post(
        config.aws_cloud_logic_custom_name,
        //Constants.GET_PRESIGNED_URL,
        "/getpreSignedURL",
        bodyParam
      );
      console.log(bodyParam.body);
      console.log(id);

      console.log(preSignedData, file);
      axios
        .put(preSignedData, file, {
          headers: {
            "Content-Type": file.type,
            "Cache-Control": "no-cache no-store must-revalidate",
          },
        })
        .then((response) => {
          console.log("success");
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } catch (error) {
      console.error(error);
    }
  }

  const quizSave = (question) => {
    let arr = [];
    console.log("question", question);
    console.log("MiniassigmentType", miniassigmentType);
    setError1(false);
    setError2(false);
    setError3(false);
    setError4(false);
    setError5(false);
    setError6(false);
    setError7(false);
    setError8(false);
    setError9(false);
    if (
      question.istem === undefined ||
      question.istem == "" ||
      question.istem.toString().replace(/\s/g, "").length === 0
    ) {
      setError1(true);
      return;
    } else {
      setError1(false);
    }

    if (miniassigmentType == 2) {
      if (question.keywords == undefined) {
        setError9(true);
        return;
      } else {
        setError9(false);
      }

      for (let i = 0; i < question.keywords.length; i++) {

        if (question.keywords[i].trim() === "") {
          setError9(true);
          return;
        } else {
          setError9(false);
        }
      }

      
    }

    if (quesType != 3) {
      let filterOps = question.iopts.filter((opts) => opts.correct === true);
      if (
        question.iopts[0].content === undefined ||
        question.iopts[0].content == "" ||
        question.iopts[0].content.toString().replace(/\s/g, "").length === 0 ||
        question.iopts[1].content === undefined ||
        question.iopts[1].content == "" ||
        question.iopts[1].content.toString().replace(/\s/g, "").length === 0
      ) {
        setError2(true);
        return;
      } else {
        setError2(false);
      }
      if (question.correctoption === undefined) {
        setError3(true);
        return;
      } else {
        setError3(false);
      }

      if (filterOps[0].content === "" || filterOps[0].content.length === 0) {
        setError4(true);
        return;
      } else {
        setError4(false);
      }
      if (quesType != 3) {
        if (
          question.iopts[2].content !== undefined &&
          question.iopts[2].content != ""
        ) {
          if (
            question.iopts[2].content.toString().replace(/\s/g, "").length === 0
          ) {
            setError5(true);
            return;
          } else {
            setError5(false);
          }
          if (
            question.iopts[2].content == question.iopts[3].content ||
            question.iopts[2].content == question.iopts[4].content ||
            question.iopts[2].content == question.iopts[5].content
          ) {
            setError8(true);
            return;
          } else {
            setError8(false);
          }
        }

        if (
          question.iopts[3].content !== undefined &&
          question.iopts[3].content != ""
        ) {
          if (
            question.iopts[3].content.toString().replace(/\s/g, "").length === 0
          ) {
            setError5(true);
            return;
          } else {
            setError5(false);
          }
          if (
            question.iopts[2].content === undefined ||
            question.iopts[2].content.toString().replace(/\s/g, "").length === 0
          ) {
            setError6(true);
            return;
          } else {
            setError6(false);
          }
          if (
            question.iopts[3].content == question.iopts[4].content ||
            question.iopts[3].content == question.iopts[5].content
          ) {
            setError8(true);
            return;
          } else {
            setError8(false);
          }
        }

        if (
          question.iopts[4].content != undefined &&
          question.iopts[4].content != ""
        ) {
          if (
            question.iopts[4].content.toString().replace(/\s/g, "").length === 0
          ) {
            setError5(true);
            return;
          } else {
            setError5(false);
          }
          if (
            question.iopts[2].content === undefined ||
            question.iopts[2].content.toString().replace(/\s/g, "").length ===
              0 ||
            question.iopts[3].content === undefined ||
            question.iopts[3].content.toString().replace(/\s/g, "").length === 0
          ) {
            setError6(true);
            return;
          } else {
            setError6(false);
          }
          if (question.iopts[4].content == question.iopts[5].content) {
            setError8(true);
            return;
          } else {
            setError8(false);
          }
        }

        if (
          question.iopts[5].content != undefined &&
          question.iopts[5].content != ""
        ) {
          if (
            question.iopts[5].content.toString().replace(/\s/g, "").length === 0
          ) {
            setError5(true);
            return;
          } else {
            setError5(false);
          }
          if (
            question.iopts[2].content === undefined ||
            question.iopts[2].content.toString().replace(/\s/g, "").length ===
              0 ||
            question.iopts[3].content === undefined ||
            question.iopts[3].content.toString().replace(/\s/g, "").length ===
              0 ||
            question.iopts[4].content === undefined ||
            question.iopts[4].content.toString().replace(/\s/g, "").length === 0
          ) {
            setError6(true);
            return;
          } else {
            setError6(false);
          }
        }
      }

      if (
        question.iopts[0].content == question.iopts[1].content ||
        question.iopts[0].content == question.iopts[2].content ||
        question.iopts[0].content == question.iopts[3].content ||
        question.iopts[0].content == question.iopts[4].content ||
        question.iopts[0].content == question.iopts[5].content
      ) {
        setError8(true);
        return;
      } else {
        setError8(false);
      }
      if (
        question.iopts[1].content == question.iopts[2].content ||
        question.iopts[1].content == question.iopts[3].content ||
        question.iopts[1].content == question.iopts[4].content ||
        question.iopts[1].content == question.iopts[5].content
      ) {
        setError8(true);
        return;
      } else {
        setError8(false);
      }
      quizQuestion.forEach((ques, idx, array) => {
        const fill = ques.iopts.filter((opts) => opts.content !== "");

        let quesd = {};
        quesd = { ...ques };
        delete quesd.correctoption;
        console.log(quesd);
        quesd.iopts = [];
        quesd.iopts = fill;
        arr.push(quesd);
      });
    } else {
      quizQuestion.forEach((ques, idx, array) => {
        let quesd = {};
        quesd = { ...ques };
        delete quesd.iopts;
        arr.push(quesd);
      });
    }
    console.log(JSON.stringify(arr, null, 2));
    selectedObj.otherdata.miniassignmenttype = miniassigmentType;
    selectedObj.otherdata.title = title;
    selectedObj.otherdata.desc = title;
    selectedObj.otherdata.dur = quizQuestion.length * 60;
    selectedObj.otherdata.quesType = quesType;

    selectedObj.quizdata = {
      qitems: arr,
      qtype: quesType,
      cutoff: cutOffVal,
      deadLine: deadLineVal,
    };
    console.log("qtype" + JSON.stringify(selectedObj));
    console.log(selectedObj);
    setSelectedObj(selectedObj);
    finalSave();
    console.log(folder);

    console.log(quizQuestion);
  };

  const addInstruction = (ins) => {
    if (quizType !== "6") {
      setInstructions((currentInstruction) => [
        ...currentInstruction,
        {
          id: generate(),
          value: "",
        },
      ]);
    } else {
      setMatchTheFollowing((currentInstruction) => [
        ...currentInstruction,
        {
          id: generate(),
          value1: "",
          value2: "",
        },
      ]);
    }
  };

  const deleteInstruction = (ins, idx) => {
    if (quizType !== "6") {
      if (idx !== 0) {
        const filteredInstructions = instructions.filter(
          (instruction) => instruction.id !== ins.id
        );
        setInstructions(filteredInstructions);
      }
    } else {
      if (idx > 2) {
        // const filteredMatchTheFollowing = matchTheFollowing.filter(
        //   (matchTheFollowing) => (matchTheFollowing.value1 !== ins.value1 && matchTheFollowing.value2 !== ins.value2)
        // );
        const filteredMatchTheFollowing = matchTheFollowing.filter(
          (matchTheFollowing) => matchTheFollowing.id !== ins.id
        );
        setMatchTheFollowing(filteredMatchTheFollowing);
      }
    }
  };

  const handleOptionChange = (event, qidx) => {
    setValue(event.target.value);
    setQuizQuestion((option) =>
      produce(option, (v) => {
        v[qidx].iopts.forEach((opts) => {
          opts.correct = false;
        });
      })
    );

    const correctoptionvalue = event.target.value;
    // console.log(correctoptionvalue);
    setQuizQuestion((option) =>
      produce(option, (v) => {
        v[qidx].iopts[correctoptionvalue].correct = true;
      })
    );
    setQuizQuestion((option) =>
      produce(option, (v) => {
        v[qidx].correctoption = correctoptionvalue;
      })
    );
  };

  const onSImageFileChange = (event, qidx, oidx) => {
    var uniqid = new Date().getTime();
    var imtype = event.target.files[0].name.slice(
      (Math.max(0, event.target.files[0].name.lastIndexOf(".")) || Infinity) + 1
    );
    let imageName = `img${uniqid}`;
    setQuizQuestion((currentFile) =>
      produce(currentFile, (draft) => {
        draft[qidx].iopts[oidx].url = imageName + ".png";
        draft[qidx].iopts[oidx].img = event.target.files[0].name;
        draft[qidx].iopts[oidx].img2 = event.target.files[0].name;
      })
    );
    uploadfile(event.target.files[0], imageName);
  };

  const handleMultiSelectChange = (event, qidx) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const addMultiminiQuestion = (question, qidx, save, qztype) => {
    setDefaultValidation(initialValidation);
    if (question.qscore == 0) {
      setDefaultValidation({ error10: true });
      return;
    } else {
      setDefaultValidation({ error10: false });
    }

    let qdata = [...quizQuestion];
    if (qztype === undefined || qztype === "" || qztype == null) {
      setDefaultValidation({ error1: true });
      return;
    } else {
      setDefaultValidation({ error1: false });
    }

    if (
      question.istem === undefined ||
      question.istem === "" ||
      question.istem == null
    ) {
      if (qztype !== "6") {
        setDefaultValidation({ error2: true });
        return;
      } else {
        setDefaultValidation({ error2: false });
      }
    }

    if (
      question.qscore == undefined ||
      question.qscore == "" ||
      question.qscore == null
    ) {
      setDefaultValidation({ error10: true });
      return;
    } else {
      setDefaultValidation({ error10: false });
    }

    if (qztype == 6) {
      if (matchTheFollowing[0] != undefined) {
        if (
          matchTheFollowing[0].value1.replace(/\s/g, "").length == 0 ||
          matchTheFollowing[0].value2.replace(/\s/g, "").length == 0
        ) {
          setDefaultValidation({ error5: true });
          return;
        } else {
          setDefaultValidation({ error5: false });
        }
      }
      if (matchTheFollowing[1] != undefined) {
        if (
          matchTheFollowing[1].value1.replace(/\s/g, "").length == 0 ||
          matchTheFollowing[1].value2.replace(/\s/g, "").length == 0
        ) {
          setDefaultValidation({ error5: true });
          return;
        } else {
          setDefaultValidation({ error5: false });
        }
      }
      if (matchTheFollowing[2] != undefined) {
        if (
          matchTheFollowing[2].value1.replace(/\s/g, "").length == 0 ||
          matchTheFollowing[2].value2.replace(/\s/g, "").length == 0
        ) {
          setDefaultValidation({ error5: true });
          return;
        } else {
          setDefaultValidation({ error5: false });
        }
      }
    }

    if (qztype == 2 || qztype == 7 || qztype == 6) {
      setQuestionValidation(initialValidation);
      if (
        question.iopts[0].content == "" ||
        question.iopts[0].content == undefined ||
        question.iopts[0].content.replace(/\s/g, "").length === 0 ||
        question.iopts[1].content == "" ||
        question.iopts[1].content == undefined ||
        question.iopts[1].content.replace(/\s/g, "").length === 0
      ) {
        setQuestionValidation({ error2: true });
        return;
      } else {
        setQuestionValidation({ error2: false });
      }
      if (question.correctoption == undefined) {
        setQuestionValidation({ error3: true });
        return;
      } else {
        setQuestionValidation({ error3: false });
      }
      let filterOps = question.iopts.filter((opts) => opts.correct === true);
      if (filterOps[0].content === "" || filterOps[0].content.length === 0) {
        setQuestionValidation({ error4: true });
        return;
      } else {
        setQuestionValidation({ error4: false });
      }

      if (
        question.iopts[2].content != "" &&
        question.iopts[2].content != undefined
      ) {
        if (question.iopts[2].content.replace(/\s/g, "").length === 0) {
          setQuestionValidation({ error5: true });
          return;
        } else {
          setQuestionValidation({ error5: false });
        }
        if (
          question.iopts[2].content == question.iopts[3].content ||
          question.iopts[2].content == question.iopts[4].content ||
          question.iopts[2].content == question.iopts[5].content
        ) {
          setQuestionValidation({ error8: true });
          return;
        } else {
          setQuestionValidation({ error8: false });
        }
      }

      if (
        question.iopts[3].content != "" &&
        question.iopts[3].content != undefined
      ) {
        if (question.iopts[3].content.replace(/\s/g, "").length === 0) {
          setQuestionValidation({ error5: true });
          return;
        } else {
          setQuestionValidation({ error5: false });
        }
        if (
          question.iopts[2].content == undefined ||
          question.iopts[2].content.replace(/\s/g, "").length === 0
        ) {
          setQuestionValidation({ error6: true });
          return;
        } else {
          setQuestionValidation({ error6: false });
        }
        if (
          question.iopts[3].content == question.iopts[4].content ||
          question.iopts[3].content == question.iopts[5].content
        ) {
          setQuestionValidation({ error8: true });
          return;
        } else {
          setQuestionValidation({ error8: false });
        }
      }

      if (
        question.iopts[4].content != "" &&
        question.iopts[4].content != undefined
      ) {
        if (question.iopts[4].content.replace(/\s/g, "").length === 0) {
          setQuestionValidation({ error5: true });
          return;
        } else {
          setQuestionValidation({ error5: false });
        }
        if (
          question.iopts[2].content == undefined ||
          question.iopts[2].content.replace(/\s/g, "").length === 0 ||
          question.iopts[3].content == undefined ||
          question.iopts[3].content.replace(/\s/g, "").length === 0
        ) {
          setQuestionValidation({ error6: true });
          return;
        } else {
          setQuestionValidation({ error6: false });
        }
        if (question.iopts[4].content == question.iopts[5].content) {
          setQuestionValidation({ error8: true });
          return;
        } else {
          setQuestionValidation({ error8: false });
        }
      }

      if (
        question.iopts[5].content != "" &&
        question.iopts[5].content != undefined
      ) {
        if (question.iopts[5].content.replace(/\s/g, "").length === 0) {
          setQuestionValidation({ error5: true });
          return;
        } else {
          setQuestionValidation({ error5: false });
        }
        if (
          question.iopts[2].content == undefined ||
          question.iopts[2].content.replace(/\s/g, "").length === 0 ||
          question.iopts[3].content == undefined ||
          question.iopts[3].content.replace(/\s/g, "").length === 0 ||
          question.iopts[4].content == undefined ||
          question.iopts[4].content.replace(/\s/g, "").length === 0
        ) {
          setQuestionValidation({ error6: true });
          return;
        } else {
          setQuestionValidation({ error6: false });
        }
      }
      if (
        question.iopts[0].content == question.iopts[1].content ||
        question.iopts[0].content == question.iopts[2].content ||
        question.iopts[0].content == question.iopts[3].content ||
        question.iopts[0].content == question.iopts[4].content ||
        question.iopts[0].content == question.iopts[5].content
      ) {
        setQuestionValidation({ error8: true });
        return;
      } else {
        setQuestionValidation({ error8: false });
      }

      if (
        question.iopts[1].content == question.iopts[2].content ||
        question.iopts[1].content == question.iopts[3].content ||
        question.iopts[1].content == question.iopts[4].content ||
        question.iopts[1].content == question.iopts[5].content
      ) {
        setQuestionValidation({ error8: true });
        return;
      } else {
        setQuestionValidation({ error8: false });
      }
    }

    if (qztype == 3) {
      setMultiSelectValidation(initialValidation);

      if (
        question.iopts[0].content == "" ||
        question.iopts[0].content == undefined ||
        question.iopts[0].content.replace(/\s/g, "").length === 0 ||
        question.iopts[1].content == "" ||
        question.iopts[1].content == undefined ||
        question.iopts[1].content.replace(/\s/g, "").length === 0 ||
        question.iopts[2].content == "" ||
        question.iopts[2].content == undefined ||
        question.iopts[2].content.replace(/\s/g, "").length === 0
      ) {
        setMultiSelectValidation({ error2: true });
        return;
      } else {
        setMultiSelectValidation({ error2: false });
      }

      if (
        question.iopts[2].content != "" &&
        question.iopts[2].content != undefined
      ) {
        if (question.iopts[2].content.replace(/\s/g, "").length === 0) {
          setMultiSelectValidation({ error5: true });
          return;
        } else {
          setMultiSelectValidation({ error5: false });
        }
        if (
          question.iopts[2].content == question.iopts[3].content ||
          question.iopts[2].content == question.iopts[4].content ||
          question.iopts[2].content == question.iopts[5].content
        ) {
          setMultiSelectValidation({ error8: true });
          return;
        } else {
          setMultiSelectValidation({ error8: false });
        }
      }

      if (
        question.iopts[3].content != "" &&
        question.iopts[3].content != undefined
      ) {
        if (question.iopts[3].content.replace(/\s/g, "").length === 0) {
          setMultiSelectValidation({ error5: true });
          return;
        } else {
          setMultiSelectValidation({ error5: false });
        }
        if (
          question.iopts[2].content == undefined ||
          question.iopts[2].content.replace(/\s/g, "").length === 0
        ) {
          setMultiSelectValidation({ error6: true });
          return;
        } else {
          setMultiSelectValidation({ error6: false });
        }
        if (
          question.iopts[3].content == question.iopts[4].content ||
          question.iopts[3].content == question.iopts[5].content
        ) {
          setMultiSelectValidation({ error8: true });
          return;
        } else {
          setMultiSelectValidation({ error8: false });
        }
      }

      if (
        question.iopts[4].content != "" &&
        question.iopts[4].content != undefined
      ) {
        if (question.iopts[4].content.replace(/\s/g, "").length === 0) {
          setMultiSelectValidation({ error5: true });
          return;
        } else {
          setMultiSelectValidation({ error5: false });
        }
        if (
          question.iopts[2].content == undefined ||
          question.iopts[2].content.replace(/\s/g, "").length === 0 ||
          question.iopts[3].content == undefined ||
          question.iopts[3].content.replace(/\s/g, "").length === 0
        ) {
          setMultiSelectValidation({ error6: true });
          return;
        } else {
          setMultiSelectValidation({ error6: false });
        }
        if (question.iopts[4].content == question.iopts[5].content) {
          setMultiSelectValidation({ error8: true });
          return;
        } else {
          setMultiSelectValidation({ error8: false });
        }
      }

      if (
        question.iopts[5].content != "" &&
        question.iopts[5].content != undefined
      ) {
        if (question.iopts[5].content.replace(/\s/g, "").length === 0) {
          setMultiSelectValidation({ error5: true });
          return;
        } else {
          setMultiSelectValidation({ error5: false });
        }
        if (
          question.iopts[2].content == undefined ||
          question.iopts[2].content.replace(/\s/g, "").length === 0 ||
          question.iopts[3].content == undefined ||
          question.iopts[3].content.replace(/\s/g, "").length === 0 ||
          question.iopts[4].content == undefined ||
          question.iopts[4].content.replace(/\s/g, "").length === 0
        ) {
          setMultiSelectValidation({ error6: true });
          return;
        } else {
          setMultiSelectValidation({ error6: false });
        }
      }
      if (
        question.iopts[0].content == question.iopts[1].content ||
        question.iopts[0].content == question.iopts[2].content ||
        question.iopts[0].content == question.iopts[3].content ||
        question.iopts[0].content == question.iopts[4].content ||
        question.iopts[0].content == question.iopts[5].content
      ) {
        setMultiSelectValidation({ error8: true });
        return;
      } else {
        setMultiSelectValidation({ error8: false });
      }

      if (
        question.iopts[1].content == question.iopts[2].content ||
        question.iopts[1].content == question.iopts[3].content ||
        question.iopts[1].content == question.iopts[4].content ||
        question.iopts[1].content == question.iopts[5].content
      ) {
        setMultiSelectValidation({ error8: true });
        return;
      } else {
        setMultiSelectValidation({ error8: false });
      }

      let tempoptionsiopts = [];
      let tempopsval = {};
      // console.log(question);
      if (question.iopts[0].content != undefined) {
        if (state.opt1) {
          tempopsval = {
            content: question.iopts[0].content,
            correct: "true",
          };
        } else {
          tempopsval = {
            content: question.iopts[0].content,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[1].content != undefined) {
        if (state.opt2) {
          tempopsval = {
            content: question.iopts[1].content,
            correct: "true",
          };
        } else {
          tempopsval = {
            content: question.iopts[1].content,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[2].content != undefined) {
        if (state.opt3) {
          tempopsval = {
            content: question.iopts[2].content,
            correct: "true",
          };
        } else {
          tempopsval = {
            content: question.iopts[2].content,
            correct: "false",
          };
        }
        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[3].content != undefined) {
        if (state.opt4) {
          tempopsval = {
            content: question.iopts[3].content,
            correct: "true",
          };
        } else {
          tempopsval = {
            content: question.iopts[3].content,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[4].content != undefined) {
        if (state.opt5) {
          tempopsval = {
            content: question.iopts[4].content,
            correct: "true",
          };
        } else {
          tempopsval = {
            content: question.iopts[4].content,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[5].content != undefined) {
        if (state.opt6) {
          tempopsval = {
            content: question.iopts[5].content,
            correct: "true",
          };
        } else {
          tempopsval = {
            content: question.iopts[5].content,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      // console.log(tempoptionsiopts);
      qdata = produce(qdata, (draft) => {
        draft[qidx].iopts = tempoptionsiopts;
        draft[qidx].atype = qztype;
      });
      qdata = JSON.parse(JSON.stringify(qdata));
      setQuizQuestion(qdata);
    }

    if (qztype == 4) {
      if (
        question.iopts[0].url == "" ||
        question.iopts[0].url == undefined ||
        question.iopts[1].url == "" ||
        question.iopts[1].url == undefined
      ) {
        setSingleSelectImageBase({ error2: true });
        return;
      } else {
        setSingleSelectImageBase({ error2: false });
      }
      if (question.correctoption == undefined) {
        setSingleSelectImageBase({ error3: true });
        return;
      } else {
        setSingleSelectImageBase({ error3: false });
      }

      if (question.correctoption != undefined) {
        var yy = question.correctoption;
        if (question.iopts[yy] == undefined) {
          setSingleSelectImageBase({ error4: true });
          return;
        } else {
          setSingleSelectImageBase({ error4: false });
        }
      }

      if (question.iopts[3].url != "" && question.iopts[3].url != undefined) {
        if (question.iopts[2].url == undefined) {
          setSingleSelectImageBase({ error6: true });
          return;
        } else {
          setSingleSelectImageBase({ error: false });
        }
      }

      if (question.iopts[4].url != "" && question.iopts[4].url != undefined) {
        if (
          question.iopts[2].url == undefined ||
          question.iopts[3].url == undefined
        ) {
          setSingleSelectImageBase({ error6: true });
          return;
        } else {
          setSingleSelectImageBase({ error6: false });
        }
      }

      if (question.iopts[5].url != "" && question.iopts[5].url != undefined) {
        if (
          question.iopts[2].url == undefined ||
          question.iopts[3].url == undefined ||
          question.iopts[4].url == undefined
        ) {
          setSingleSelectImageBase({ error6: true });
          return;
        } else {
          setSingleSelectImageBase({ error6: false });
        }
      }

      let tempoptionsiopts = [];
      let tempopsval = {};
      if (question.iopts[0].url != undefined) {
        if (question.correctoption == "0") {
          tempopsval = {
            img: question.iopts[0].img,
            url: question.iopts[0].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            img: question.iopts[0].img,
            url: question.iopts[0].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[1].url != undefined) {
        if (question.correctoption == "1") {
          tempopsval = {
            img: question.iopts[1].img,
            url: question.iopts[1].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            img: question.iopts[1].img,
            url: question.iopts[1].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[2].url != undefined) {
        if (question.correctoption == "2") {
          tempopsval = {
            img: question.iopts[2].img,
            url: question.iopts[2].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            img: question.iopts[2].img,
            url: question.iopts[2].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[3].url != undefined) {
        if (question.correctoption == "3") {
          tempopsval = {
            img: question.iopts[3].img,
            url: question.iopts[3].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            img: question.iopts[3].img,
            url: question.iopts[3].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[4].url != undefined) {
        if (question.correctoption == "4") {
          tempopsval = {
            img: question.iopts[4].img,
            url: question.iopts[4].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            img: question.iopts[4].img,
            url: question.iopts[4].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[5].url != undefined) {
        if (question.correctoption == "5") {
          tempopsval = {
            img: question.iopts[5].img,
            url: question.iopts[5].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            img: question.iopts[5].img,
            url: question.iopts[5].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }
      qdata = produce(qdata, (draft) => {
        draft[qidx].iopts = tempoptionsiopts;
        draft[qidx].atype = qztype;
      });
      qdata = JSON.parse(JSON.stringify(qdata));
      setQuizQuestion(qdata);
    }

    if (qztype == 5) {
      setMultiSelectImageBase(initialValidation);

      if (
        question.iopts[0].url == "" ||
        question.iopts[0].url == undefined ||
        question.iopts[1].url == "" ||
        question.iopts[1].url == undefined ||
        question.iopts[2].url == "" ||
        question.iopts[2].url == undefined
      ) {
        setMultiSelectImageBase({ error2: true });
        return;
      }

      if (question.iopts[3].url != "" && question.iopts[3].url != undefined) {
        if (question.iopts[2].url == undefined) {
          setMultiSelectImageBase({ error6: true });
          return;
        }
      }

      if (question.iopts[4].url != "" && question.iopts[4].url != undefined) {
        if (
          question.iopts[2].url == undefined ||
          question.iopts[3].url == undefined
        ) {
          setMultiSelectImageBase({ error6: true });
          return;
        }
      }

      if (question.iopts[5].url != "" && question.iopts[5].url != undefined) {
        if (
          question.iopts[2].url == undefined ||
          question.iopts[3].url == undefined ||
          question.iopts[4].url == undefined
        ) {
          setMultiSelectImageBase({ error6: true });
          return;
        }
      }

      let tempoptionsiopts = [];
      let tempopsval = {};
      if (question.iopts[0].url != undefined) {
        if (state.opt1) {
          tempopsval = {
            url: question.iopts[0].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            url: question.iopts[0].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[1].url != undefined) {
        if (state.opt2) {
          tempopsval = {
            url: question.iopts[1].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            url: question.iopts[1].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[2].url != undefined) {
        if (state.opt3) {
          tempopsval = {
            url: question.iopts[2].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            url: question.iopts[2].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[3].url != undefined) {
        if (state.opt4) {
          tempopsval = {
            url: question.iopts[3].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            url: question.iopts[3].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[4].url != undefined) {
        if (state.opt5) {
          tempopsval = {
            url: question.iopts[4].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            url: question.iopts[4].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[5].url != undefined) {
        if (state.opt6) {
          tempopsval = {
            url: question.iopts[5].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            url: question.iopts[5].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      qdata = produce(qdata, (draft) => {
        draft[qidx].iopts = tempoptionsiopts;
        draft[qidx].atype = qztype;
      });
      qdata = JSON.parse(JSON.stringify(qdata));
      setQuizQuestion(qdata);
    }

    if (qztype == 1) {
      if (value == undefined || value == "" || value == null) {
        setDefaultValidation({ error4: true });
        return;
      } else {
        setDefaultValidation({ error4: false });
      }

      let temp = [];
      let tempopsval = {};
      if (
        question.iopts[0].correct === "true" ||
        question.iopts[0].correct === true
      ) {
        tempopsval = {
          content: "True",
          correct: "true",
        };
      } else {
        tempopsval = {
          content: "True",
          correct: "false",
        };
      }
      temp.push(tempopsval);
      if (
        question.iopts[1].correct === "true" ||
        question.iopts[1].correct === true
      ) {
        tempopsval = {
          content: "False",
          correct: "true",
        };
      } else {
        tempopsval = {
          content: "False",
          correct: "false",
        };
      }
      temp.push(tempopsval);

      qdata = produce(qdata, (draft) => {
        draft[qidx].iopts = temp;
        draft[qidx].atype = "1";
      });
      qdata = JSON.parse(JSON.stringify(qdata));
      setQuizQuestion(qdata);
    }
    if (qztype == 6) {
      matchTheFollowing.forEach((match) => {
        if (match.value1 == "" || match.value2 == "") {
          setDefaultValidation({ error5: true });
          return;
        } else {
          setDefaultValidation({ error5: false });
        }
      });

      let str1 = "";
      let ind = 0;

      for (var i = 0; i < matchTheFollowing.length; i++) {
        if (matchTheFollowing[i].value != "") {
          ind += 1;
          str1 +=
            "<tr><td>" +
            ind +
            "." +
            matchTheFollowing[i].value1 +
            "</td><td>" +
            ind +
            "." +
            matchTheFollowing[i].value2 +
            "</td></tr>";
        }
      }
      //

      qdata = produce(quizQuestion, (v) => {
        v[
          qidx
        ].istem = `<table class='qtable'><tr><th>A</th><th>B</th></tr>${str1}</table>`;
        v[qidx].atype = "6";
      });

      qdata = JSON.parse(JSON.stringify(qdata));
      if (save === 0) {
        qdata.push({
          iid: generate(),
          istem: "",
          iopts: initialValues,
        });
      }

      setQuizQuestion(qdata);

      // console.log(str1);
    }
    setAType(qztype);
    qdata = produce(qdata, (draft) => {
      draft[qidx].atype = qztype;
    });
    qdata = JSON.parse(JSON.stringify(qdata));
    setQuizQuestion(qdata);
    let cq = currentQuestion + 1;
    setCurrentQuestion(cq);
    questionObj.currentq = cq;
    setQuestionObj((currentQuestion) => ({ ...questionObj, questionObj }));

    if (qztype != 6 && save === 0) {
      setQuizQuestion((currentQuestion) => [
        ...currentQuestion,
        {
          iid: generate(),
          istem: "",
          iopts: initialValues,
        },
      ]);
    } else {
      if (save !== 0) {
        let arr = [];
        qdata.forEach((ques, idx, array) => {
          const fill = ques.iopts.filter((opts) => opts.content !== "");

          arr.push(
            produce(ques, (draft) => {
              draft.iopts = fill;
              // delete draft.correctoption;
              // if (draft.atype == 7 || draft.atype == 8) {
              //   delete draft.iopts;
              // }
            })
          );
        });
        let instuct = [...instructions];
        let inst = [];
        instuct.map((ins) => {
          let i = { ...ins };
          inst.push(i.value);
        });
        selectedObj.otherdata.miniassignmenttype = miniassigmentType;
        selectedObj.otherdata.title = title;
        selectedObj.otherdata.desc = title;
        selectedObj.otherdata.dur = quizQuestion.length * 60;
        selectedObj.otherdata.quesType = quesType;

        selectedObj.quizdata = {
          qitems: arr,
          qtype: quesType,
          cutoff: cutOffVal,
        };
        console.log("qtype" + JSON.stringify(selectedObj));
        console.log(selectedObj);
        setSelectedObj(selectedObj);
        finalSave();
        // setProgress(true);
        setSuccess(true);
        // setFirstView(false);
        // saveAssessmentAndAssignmentData(body);
      }
    }
    setSelectedFile("");
    setQuizType("");
    setState(initialState);
  };

  const handleChangeMulti = (event, oidx, qidx, qztype) => {
    setValue(event.target.value);

    setQuizQuestion((option) =>
      produce(option, (v) => {
        v[qidx].iopts.forEach((opts) => {
          opts.correct = false;
        });
      })
    );

    setQuizQuestion((option) =>
      produce(option, (v) => {
        v[qidx].iopts[oidx].correct = true;
      })
    );
    setQuizQuestion((option) =>
      produce(option, (v) => {
        v[qidx].atype = qztype;
      })
    );
  };

  const addNewQuestion = (question, qidx, save, qztype, buttonClick) => {
    setDefaultValidation(initialValidation);
    setAddQestBtn("true");
    let qdata = [...quizQuestion];

    if (qztype === undefined || qztype === "" || qztype == null) {
      setDefaultValidation({ error1: true });
      return;
    } else {
      setDefaultValidation({ error1: false });
    }

    if (qztype == 6) {
      if (matchTheFollowing[0] != undefined) {
        if (
          matchTheFollowing[0].value1.replace(/\s/g, "").length == 0 ||
          matchTheFollowing[0].value2.replace(/\s/g, "").length == 0
        ) {
          setDefaultValidation({ error5: true });
          return;
        } else {
          setDefaultValidation({ error5: false });
        }
      }
      if (matchTheFollowing[1] != undefined) {
        if (
          matchTheFollowing[1].value1.replace(/\s/g, "").length == 0 ||
          matchTheFollowing[1].value2.replace(/\s/g, "").length == 0
        ) {
          setDefaultValidation({ error5: true });
          return;
        } else {
          setDefaultValidation({ error5: false });
        }
      }
      if (matchTheFollowing[2] != undefined) {
        if (
          matchTheFollowing[2].value1.replace(/\s/g, "").length == 0 ||
          matchTheFollowing[2].value2.replace(/\s/g, "").length == 0
        ) {
          setDefaultValidation({ error5: true });
          return;
        } else {
          setDefaultValidation({ error5: false });
        }
      }
    }

    if (
      question.istem === undefined ||
      question.istem === "" ||
      question.istem == null ||
      question.istem.replace(/\s/g, "").length === 0
    ) {
      if (qztype !== "6") {
        setDefaultValidation({ error2: true });
        return;
      } else {
        setDefaultValidation({ error2: false });
      }
    }

    if (question.qscore == 0) {
      setDefaultValidation({ error10: true });
      return;
    } else {
      setDefaultValidation({ error10: false });
    }

    if (
      question.qscore == undefined ||
      question.qscore == "" ||
      question.qscore == null
    ) {
      setDefaultValidation({ error3: true });
      return;
    } else {
      setDefaultValidation({ error3: false });
    }

    if (qztype == 2 || qztype == 6 || qztype == 7) {
      setQuestionValidation(initialValidation);

      if (
        question.iopts[0].content === undefined ||
        question.iopts[0].content == "" ||
        question.iopts[0].content.replace(/\s/g, "").length === 0 ||
        question.iopts[1].content === undefined ||
        question.iopts[1].content == "" ||
        question.iopts[1].content.replace(/\s/g, "").length === 0
      ) {
        setQuestionValidation({ error2: true });
        return;
      } else {
        setQuestionValidation({ error2: false });
      }
      if (question.correctoption === undefined) {
        setQuestionValidation({ error3: true });
        return;
      } else {
        setQuestionValidation({ error3: false });
      }
      let filterOps = question.iopts.filter((opts) => opts.correct === true);
      if (filterOps.length > 0) {
        if (filterOps[0].content === "" || filterOps[0].content.length === 0) {
          setQuestionValidation({ error4: true });
          return;
        } else {
          setQuestionValidation({ error4: false });
        }
      }

      if (
        question.iopts[2] !== undefined &&
        question.iopts[2].content !== undefined &&
        question.iopts[2].content != ""
      ) {
        if (question.iopts[2].content.replace(/\s/g, "").length === 0) {
          setQuestionValidation({ error5: true });
          return;
        } else {
          setQuestionValidation({ error5: false });
        }
        if (
          question.iopts[2].content == question.iopts[3].content ||
          question.iopts[2].content == question.iopts[4].content ||
          question.iopts[2].content == question.iopts[5].content
        ) {
          setQuestionValidation({ error8: true });
          return;
        } else {
          setQuestionValidation({ error8: false });
        }
      }

      if (
        question.iopts[3] !== undefined &&
        question.iopts[3].content !== undefined &&
        question.iopts[3].content != ""
      ) {
        if (question.iopts[3].content.replace(/\s/g, "").length === 0) {
          setQuestionValidation({ error5: true });
          return;
        } else {
          setQuestionValidation({ error5: false });
        }
        if (
          question.iopts[2].content === undefined ||
          question.iopts[2].content.replace(/\s/g, "").length === 0
        ) {
          setQuestionValidation({ error6: true });
          return;
        } else {
          setQuestionValidation({ error6: false });
        }
        if (
          question.iopts[3].content == question.iopts[4].content ||
          question.iopts[3].content == question.iopts[5].content
        ) {
          setQuestionValidation({ error8: true });
          return;
        } else {
          setQuestionValidation({ error8: false });
        }
      }

      if (
        question.iopts[4] !== undefined &&
        question.iopts[4].content !== undefined &&
        question.iopts[4].content != ""
      ) {
        if (question.iopts[4].content.replace(/\s/g, "").length === 0) {
          setQuestionValidation({ error5: true });
          return;
        } else {
          setQuestionValidation({ error5: false });
        }
        if (
          question.iopts[2].content === undefined ||
          question.iopts[2].content.replace(/\s/g, "").length === 0 ||
          question.iopts[3].content === undefined ||
          question.iopts[3].content.replace(/\s/g, "").length === 0
        ) {
          setQuestionValidation({ error6: true });
          return;
        } else {
          setQuestionValidation({ error6: false });
        }
        if (question.iopts[4].content == question.iopts[5].content) {
          setQuestionValidation({ error8: true });
          return;
        } else {
          setQuestionValidation({ error8: false });
        }
      }

      if (
        question.iopts[5] !== undefined &&
        question.iopts[5].content !== undefined &&
        question.iopts[5].content != ""
      ) {
        if (question.iopts[5].content.replace(/\s/g, "").length === 0) {
          setQuestionValidation({ error5: true });
          return;
        } else {
          setQuestionValidation({ error5: false });
        }
        if (
          question.iopts[2].content === undefined ||
          question.iopts[2].content.replace(/\s/g, "").length === 0 ||
          question.iopts[3].content === undefined ||
          question.iopts[3].content.replace(/\s/g, "").length === 0 ||
          question.iopts[4].content === undefined ||
          question.iopts[4].content.replace(/\s/g, "").length === 0
        ) {
          setQuestionValidation({ error6: true });
          return;
        } else {
          setQuestionValidation({ error6: false });
        }
      }
      if (
        question.iopts[0].content == question.iopts[1].content ||
        question.iopts[0].content == question.iopts[2].content ||
        question.iopts[0].content == question.iopts[3].content ||
        question.iopts[0].content == question.iopts[4].content ||
        question.iopts[0].content == question.iopts[5].content
      ) {
        setQuestionValidation({ error8: true });
        return;
      } else {
        setQuestionValidation({ error8: false });
      }

      if (
        question.iopts[1].content == question.iopts[2].content ||
        question.iopts[1].content == question.iopts[3].content ||
        question.iopts[1].content == question.iopts[4].content ||
        question.iopts[1].content == question.iopts[5].content
      ) {
        setQuestionValidation({ error8: true });
        return;
      } else {
        setQuestionValidation({ error8: false });
      }
    }

    if (qztype == 3) {
      setMultiSelectValidation(initialValidation);

      if (
        question.iopts[0].content === undefined ||
        question.iopts[0].content == "" ||
        question.iopts[0].content.replace(/\s/g, "").length === 0 ||
        question.iopts[1].content === undefined ||
        question.iopts[1].content == "" ||
        question.iopts[1].content.replace(/\s/g, "").length === 0 ||
        question.iopts[2].content === undefined ||
        question.iopts[2].content == "" ||
        question.iopts[2].content.replace(/\s/g, "").length === 0
      ) {
        setMultiSelectValidation({ error2: true });
        return;
      } else {
        setMultiSelectValidation({ error2: false });
      }

      if (
        question.iopts[2].content !== undefined &&
        question.iopts[2].content != ""
      ) {
        if (question.iopts[2].content.replace(/\s/g, "").length === 0) {
          setMultiSelectValidation({ error5: true });
          return;
        } else {
          setMultiSelectValidation({ error5: false });
        }
        if (
          question.iopts[2].content == question.iopts[3].content ||
          question.iopts[2].content == question.iopts[4].content ||
          question.iopts[2].content == question.iopts[5].content
        ) {
          setMultiSelectValidation({ error8: true });
          return;
        } else {
          setMultiSelectValidation({ error8: false });
        }
      }

      if (
        question.iopts[3].content !== undefined &&
        question.iopts[3].content != ""
      ) {
        if (question.iopts[3].content.replace(/\s/g, "").length === 0) {
          setMultiSelectValidation({ error5: true });
          return;
        } else {
          setMultiSelectValidation({ error5: false });
        }
        if (
          question.iopts[2].content == undefined ||
          question.iopts[2].content.replace(/\s/g, "").length === 0
        ) {
          setMultiSelectValidation({ error6: true });
          return;
        } else {
          setMultiSelectValidation({ error6: false });
        }
        if (
          question.iopts[3].content == question.iopts[4].content ||
          question.iopts[3].content == question.iopts[5].content
        ) {
          setMultiSelectValidation({ error8: true });
          return;
        } else {
          setMultiSelectValidation({ error8: false });
        }
      }

      if (
        question.iopts[4].content !== undefined &&
        question.iopts[4].content != ""
      ) {
        if (question.iopts[4].content.replace(/\s/g, "").length === 0) {
          setMultiSelectValidation({ error5: true });
          return;
        } else {
          setMultiSelectValidation({ error5: false });
        }
        if (
          question.iopts[2].content == undefined ||
          question.iopts[2].content.replace(/\s/g, "").length === 0 ||
          question.iopts[3].content == undefined ||
          question.iopts[3].content.replace(/\s/g, "").length === 0
        ) {
          setMultiSelectValidation({ error6: true });
          return;
        } else {
          setMultiSelectValidation({ error6: false });
        }
        if (question.iopts[4].content == question.iopts[5].content) {
          setMultiSelectValidation({ error8: true });
          return;
        } else {
          setMultiSelectValidation({ error8: false });
        }
      }

      if (
        question.iopts[5].content !== undefined &&
        question.iopts[5].content != ""
      ) {
        if (question.iopts[5].content.replace(/\s/g, "").length === 0) {
          setMultiSelectValidation({ error5: true });
          return;
        } else {
          setMultiSelectValidation({ error5: false });
        }
        if (
          question.iopts[2].content === undefined ||
          question.iopts[2].content.replace(/\s/g, "").length === 0 ||
          question.iopts[3].content === undefined ||
          question.iopts[3].content.replace(/\s/g, "").length === 0 ||
          question.iopts[4].content === undefined ||
          question.iopts[4].content.replace(/\s/g, "").length === 0
        ) {
          setMultiSelectValidation({ error6: true });
          return;
        } else {
          setMultiSelectValidation({ error6: false });
        }
      }
      if (
        question.iopts[0].content == question.iopts[1].content ||
        question.iopts[0].content == question.iopts[2].content ||
        question.iopts[0].content == question.iopts[3].content ||
        question.iopts[0].content == question.iopts[4].content ||
        question.iopts[0].content == question.iopts[5].content
      ) {
        setMultiSelectValidation({ error8: true });
        return;
      } else {
        setMultiSelectValidation({ error8: false });
      }

      if (
        question.iopts[1].content == question.iopts[2].content ||
        question.iopts[1].content == question.iopts[3].content ||
        question.iopts[1].content == question.iopts[4].content ||
        question.iopts[1].content == question.iopts[5].content
      ) {
        setMultiSelectValidation({ error8: true });
        return;
      } else {
        setMultiSelectValidation({ error8: false });
      }

      let tempoptionsiopts = [];
      let tempopsval = {};

      if (question.iopts[0].content !== undefined) {
        if (state.opt1) {
          tempopsval = {
            content: question.iopts[0].content,
            correct: "true",
          };
        } else {
          tempopsval = {
            content: question.iopts[0].content,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[1].content !== undefined) {
        if (state.opt2) {
          tempopsval = {
            content: question.iopts[1].content,
            correct: "true",
          };
        } else {
          tempopsval = {
            content: question.iopts[1].content,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[2].content !== undefined) {
        if (state.opt3) {
          tempopsval = {
            content: question.iopts[2].content,
            correct: "true",
          };
        } else {
          tempopsval = {
            content: question.iopts[2].content,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[3].content !== undefined) {
        if (state.opt4) {
          tempopsval = {
            content: question.iopts[3].content,
            correct: "true",
          };
        } else {
          tempopsval = {
            content: question.iopts[3].content,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[4].content !== undefined) {
        if (state.opt5) {
          tempopsval = {
            content: question.iopts[4].content,
            correct: "true",
          };
        } else {
          tempopsval = {
            content: question.iopts[4].content,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[5].content !== undefined) {
        if (state.opt6) {
          tempopsval = {
            content: question.iopts[5].content,
            correct: "true",
          };
        } else {
          tempopsval = {
            content: question.iopts[5].content,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }
      qdata = produce(qdata, (draft) => {
        draft[qidx].iopts = tempoptionsiopts;
        draft[qidx].atype = qztype;
      });
      qdata = JSON.parse(JSON.stringify(qdata));
      setQuizQuestion(qdata);
    }

    if (qztype == 4) {
      if (
        question.iopts[0].url === undefined ||
        question.iopts[0].url == "" ||
        question.iopts[1].url === undefined ||
        question.iopts[1].url == ""
      ) {
        setSingleSelectImageBase({ error2: true });
        return;
      } else {
        setSingleSelectImageBase({ error2: false });
      }
      if (question.correctoption == undefined) {
        setSingleSelectImageBase({ error3: true });
        return;
      } else {
        setSingleSelectImageBase({ error3: false });
      }

      if (question.correctoption !== undefined) {
        var yy = question.correctoption;
        if (question.iopts[yy] === undefined) {
          setSingleSelectImageBase({ error4: true });
          return;
        } else {
          setSingleSelectImageBase({ error4: false });
        }
      }

      if (question.iopts[3].url !== undefined && question.iopts[3].url != "") {
        if (question.iopts[2].url === undefined) {
          setSingleSelectImageBase({ error6: true });
          return;
        } else {
          setSingleSelectImageBase({ error: false });
        }
      }

      if (question.iopts[4].url !== undefined && question.iopts[4].url != "") {
        if (
          question.iopts[2].url === undefined ||
          question.iopts[3].url === undefined
        ) {
          setSingleSelectImageBase({ error6: true });
          return;
        } else {
          setSingleSelectImageBase({ error6: false });
        }
      }

      if (question.iopts[5].url !== undefined && question.iopts[5].url != "") {
        if (
          question.iopts[2].url === undefined ||
          question.iopts[3].url === undefined ||
          question.iopts[4].url === undefined
        ) {
          setSingleSelectImageBase({ error6: true });
          return;
        } else {
          setSingleSelectImageBase({ error6: false });
        }
      }

      let tempoptionsiopts = [];
      let tempopsval = {};
      if (question.iopts[0].url !== undefined) {
        if (question.correctoption == "0") {
          tempopsval = {
            img: question.iopts[0].img,
            url: question.iopts[0].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            img: question.iopts[0].img,
            url: question.iopts[0].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[1].url !== undefined) {
        if (question.correctoption == "1") {
          tempopsval = {
            img: question.iopts[1].img,
            url: question.iopts[1].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            img: question.iopts[1].img,
            url: question.iopts[1].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[2].url !== undefined) {
        if (question.correctoption == "2") {
          tempopsval = {
            img: question.iopts[2].img,
            url: question.iopts[2].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            img: question.iopts[2].img,
            url: question.iopts[2].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[3].url !== undefined) {
        if (question.correctoption == "3") {
          tempopsval = {
            img: question.iopts[3].img,
            url: question.iopts[3].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            img: question.iopts[3].img,
            url: question.iopts[3].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[4].url !== undefined) {
        if (question.correctoption == "4") {
          tempopsval = {
            img: question.iopts[4].img,
            url: question.iopts[4].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            img: question.iopts[4].img,
            url: question.iopts[4].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[5].url !== undefined) {
        if (question.correctoption == "5") {
          tempopsval = {
            img: question.iopts[5].img,
            url: question.iopts[5].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            img: question.iopts[5].img,
            url: question.iopts[5].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }
      qdata = produce(qdata, (draft) => {
        draft[qidx].iopts = tempoptionsiopts;
        draft[qidx].atype = qztype;
      });
      qdata = JSON.parse(JSON.stringify(qdata));
      setQuizQuestion(qdata);
    }

    if (qztype == 5) {
      console.log(question);
      setMultiSelectImageBase(initialValidation);

      if (
        question.iopts[0].url === undefined ||
        question.iopts[0].url == "" ||
        question.iopts[1].url === undefined ||
        question.iopts[1].url == "" ||
        question.iopts[2].url === undefined ||
        question.iopts[2].url == ""
      ) {
        setMultiSelectImageBase({ error2: true });
        return;
      }

      if (question.iopts[3].url !== undefined && question.iopts[3].url != "") {
        if (question.iopts[2].url == undefined) {
          setMultiSelectImageBase({ error6: true });
          return;
        }
      }

      if (question.iopts[4].url !== undefined && question.iopts[4].url != "") {
        if (
          question.iopts[2].url === undefined ||
          question.iopts[3].url === undefined
        ) {
          setMultiSelectImageBase({ error6: true });
          return;
        }
      }

      if (question.iopts[5].url !== undefined && question.iopts[5].url != "") {
        if (
          question.iopts[2].url === undefined ||
          question.iopts[3].url === undefined ||
          question.iopts[4].url === undefined
        ) {
          setMultiSelectImageBase({ error6: true });
          return;
        }
      }

      let tempoptionsiopts = [];
      let tempopsval = {};
      if (question.iopts[0].url !== undefined) {
        if (state.opt1) {
          tempopsval = {
            img: question.iopts[0].img,
            url: question.iopts[0].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            img: question.iopts[0].img,
            url: question.iopts[0].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[1].url !== undefined) {
        if (state.opt2) {
          tempopsval = {
            img: question.iopts[1].img,
            url: question.iopts[1].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            img: question.iopts[1].img,
            url: question.iopts[1].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[2].url !== undefined) {
        if (state.opt3) {
          tempopsval = {
            img: question.iopts[2].img,
            url: question.iopts[2].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            img: question.iopts[2].img,
            url: question.iopts[2].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[3].url !== undefined) {
        if (state.opt4) {
          tempopsval = {
            img: question.iopts[3].img,
            url: question.iopts[3].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            img: question.iopts[3].img,
            url: question.iopts[3].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[4].url !== undefined) {
        if (state.opt5) {
          tempopsval = {
            img: question.iopts[4].img,
            url: question.iopts[4].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            img: question.iopts[4].img,
            url: question.iopts[4].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[5].url !== undefined) {
        if (state.opt6) {
          tempopsval = {
            img: question.iopts[5].img,
            url: question.iopts[5].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            img: question.iopts[5].img,
            url: question.iopts[5].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      qdata = produce(qdata, (draft) => {
        draft[qidx].iopts = tempoptionsiopts;
        draft[qidx].atype = qztype;
      });
      qdata = JSON.parse(JSON.stringify(qdata));

      setQuizQuestion(qdata);
    }

    if (qztype == 1) {
      if (value === undefined || value === "" || value === null) {
        setDefaultValidation({ error4: true });
        return;
      } else {
        setDefaultValidation({ error4: false });
      }

      let temp = [];
      let tempopsval = {};
      if (
        question.iopts[0].correct === "true" ||
        question.iopts[0].correct === true
      ) {
        tempopsval = {
          content: "True",
          correct: "true",
        };
      } else {
        tempopsval = {
          content: "True",
          correct: "false",
        };
      }
      temp.push(tempopsval);
      if (
        question.iopts[1].correct === "true" ||
        question.iopts[1].correct === true
      ) {
        tempopsval = {
          content: "False",
          correct: "true",
        };
      } else {
        tempopsval = {
          content: "False",
          correct: "false",
        };
      }
      temp.push(tempopsval);

      qdata = produce(qdata, (draft) => {
        draft[qidx].iopts = temp;
        draft[qidx].atype = "1";
      });
      qdata = JSON.parse(JSON.stringify(qdata));
      setQuizQuestion(qdata);
    }

    if (qztype == 6) {
      matchTheFollowing.forEach((match) => {
        if (match.value1 == "" || match.value2 == "") {
          setDefaultValidation({ error5: true });
          return;
        } else {
          setDefaultValidation({ error5: false });
        }
      });

      let str1 = "";
      let ind = 0;

      for (var i = 0; i < matchTheFollowing.length; i++) {
        if (matchTheFollowing[i].value != "") {
          ind += 1;
          str1 +=
            "<tr><td>" +
            ind +
            "." +
            matchTheFollowing[i].value1 +
            "</td><td>" +
            ind +
            "." +
            matchTheFollowing[i].value2 +
            "</td></tr>";
        }
      }
      //

      qdata = produce(quizQuestion, (v) => {
        v[
          qidx
        ].istem = `<table class='qtable'><tr><th>A</th><th>B</th></tr>${str1}</table>`;
        v[qidx].atype = "6";
      });

      qdata = JSON.parse(JSON.stringify(qdata));
      setQuizQuestion(qdata);
      if (save === 0) {
        qdata.push({
          iid: generate(),
          istem: "",
          iopts: initialValues,
        });
      }
    }
    setAType(qztype);
    qdata = produce(qdata, (draft) => {
      draft[qidx].atype = qztype;
    });
    qdata = JSON.parse(JSON.stringify(qdata));
    setQuizQuestion(qdata);
    questionObj.currentq = quizQuestion.length;
    setQuestionObj((currentQuestion) => ({ ...questionObj, questionObj }));

    if (qztype != 6 && save === 0) {
      setQuizQuestion((currentQuestion) => [
        ...currentQuestion,
        {
          iid: generate(),
          istem: "",
          iopts: initialValues,
        },
      ]);
    } else {
      if (save !== 0) {
        let arr = [];
        let fill = [];
        qdata.forEach((ques, idx, array) => {
          // const fill = '';
          if (ques.iopts != undefined) {
            fill = ques.iopts.filter((opts) => opts.content !== "");
          } else {
            fill = [];
          }
          arr.push(
            produce(ques, (draft) => {
              if (draft.iopts != undefined) {
                draft.iopts = fill;
              }
              // delete draft.correctoption;
              // if (draft.atype == 7 || draft.atype == 8) {
              //   delete draft.iopts;
              // }
            })
          );
        });
        let instuct = [...instructions];
        let inst = [];
        instuct.map((ins) => {
          let i = { ...ins };
          inst.push(i.value);
        });

        selectedObj.otherdata.miniassignmenttype = miniassigmentType;
        selectedObj.otherdata.title = title;
        selectedObj.otherdata.desc = title;
        selectedObj.otherdata.dur = quizQuestion.length * 60;
        selectedObj.otherdata.quesType = quesType;

        selectedObj.quizdata = {
          qitems: arr,
          qtype: quesType,
          cutoff: cutOffVal,
        };
        console.log("qtype" + JSON.stringify(selectedObj));
        console.log(selectedObj);
        setSelectedObj(selectedObj);
        finalSave();
        // setProgress(true);
        setEditMultiForm(false);
        setSuccess(true);
      }
    }
    setValue("");
    setSelectedFile("");
    setQuizType("");
    let cq = currentQuestion;
    setState(initialState);
    setNquestion(true);
    if (qdata.length == currentQuestion) {
      // cq = qdata.length;
      cq = currentQuestion + 1;
      setCurrentQuestion(cq);
      questionObj.currentq = cq;
      setQuestionObj((currentQuestion) => ({ ...questionObj, questionObj }));
    } else {
      cq = qdata.length + 1;
      questionObj.currentq = quizQuestion.length + 1;
      setCurrentQuestion(cq);
      setQuestionObj((currentQuestion) => ({ ...questionObj, questionObj }));
    }
  };

  const nextQuestion = (question, qidx, save, qztype, buttonClick) => {
    setDefaultValidation(initialValidation);
    let qdata = [...quizQuestion];

    if (qztype == 6) {
      if (matchTheFollowing[0] != undefined) {
        if (
          matchTheFollowing[0].value1.replace(/\s/g, "").length == 0 ||
          matchTheFollowing[0].value2.replace(/\s/g, "").length == 0
        ) {
          setDefaultValidation({ error5: true });
          return;
        } else {
          setDefaultValidation({ error5: false });
        }
      }
      if (matchTheFollowing[1] != undefined) {
        if (
          matchTheFollowing[1].value1.replace(/\s/g, "").length == 0 ||
          matchTheFollowing[1].value2.replace(/\s/g, "").length == 0
        ) {
          setDefaultValidation({ error5: true });
          return;
        } else {
          setDefaultValidation({ error5: false });
        }
      }
      if (matchTheFollowing[2] != undefined) {
        if (
          matchTheFollowing[2].value1.replace(/\s/g, "").length == 0 ||
          matchTheFollowing[2].value2.replace(/\s/g, "").length == 0
        ) {
          setDefaultValidation({ error5: true });
          return;
        } else {
          setDefaultValidation({ error5: false });
        }
      }
    }

    if (qztype === undefined || qztype === "" || qztype == null) {
      setDefaultValidation({ error1: true });
      return;
    } else {
      setDefaultValidation({ error1: false });
    }

    if (
      question.istem === undefined ||
      question.istem === "" ||
      question.istem == null
    ) {
      if (qztype !== "6") {
        setDefaultValidation({ error2: true });
        return;
      } else {
        setDefaultValidation({ error2: false });
      }
    }

    if (
      question.qscore == undefined ||
      question.qscore == "" ||
      question.qscore == null
    ) {
      setDefaultValidation({ error3: true });
      return;
    } else {
      setDefaultValidation({ error3: false });
    }

    if (qztype == 2 || qztype == 6 || qztype == 7) {
      setQuestionValidation(initialValidation);

      if (
        question.iopts[0].content == "" ||
        question.iopts[0].content == undefined ||
        question.iopts[0].content.replace(/\s/g, "").length === 0 ||
        question.iopts[1].content == "" ||
        question.iopts[1].content == undefined ||
        question.iopts[1].content.replace(/\s/g, "").length === 0
      ) {
        setQuestionValidation({ error2: true });
        return;
      } else {
        setQuestionValidation({ error2: false });
      }
      if (question.correctoption == undefined) {
        setQuestionValidation({ error3: true });
        return;
      } else {
        setQuestionValidation({ error3: false });
      }
      let filterOps = question.iopts.filter((opts) => opts.correct === true);

      if (filterOps.length > 0) {
        if (filterOps[0].content === "" || filterOps[0].content.length === 0) {
          setQuestionValidation({ error4: true });
          return;
        } else {
          setQuestionValidation({ error4: false });
        }
      }

      if (
        question.iopts[2].content !== undefined &&
        question.iopts[2].content != ""
      ) {
        if (question.iopts[2].content.replace(/\s/g, "").length === 0) {
          setQuestionValidation({ error5: true });
          return;
        } else {
          setQuestionValidation({ error5: false });
        }
        if (
          question.iopts[2].content == question.iopts[3].content ||
          question.iopts[2].content == question.iopts[4].content ||
          question.iopts[2].content == question.iopts[5].content
        ) {
          setQuestionValidation({ error8: true });
          return;
        } else {
          setQuestionValidation({ error8: false });
        }
      }

      if (
        question.iopts[3].content !== undefined &&
        question.iopts[3].content != ""
      ) {
        if (question.iopts[3].content.replace(/\s/g, "").length === 0) {
          setQuestionValidation({ error5: true });
          return;
        } else {
          setQuestionValidation({ error5: false });
        }
        if (
          question.iopts[2].content == undefined ||
          question.iopts[2].content.replace(/\s/g, "").length === 0
        ) {
          setQuestionValidation({ error6: true });
          return;
        } else {
          setQuestionValidation({ error6: false });
        }
        if (
          question.iopts[3].content == question.iopts[4].content ||
          question.iopts[3].content == question.iopts[5].content
        ) {
          setQuestionValidation({ error8: true });
          return;
        } else {
          setQuestionValidation({ error8: false });
        }
      }

      if (
        question.iopts[4].content !== undefined &&
        question.iopts[4].content != ""
      ) {
        if (question.iopts[4].content.replace(/\s/g, "").length === 0) {
          setQuestionValidation({ error5: true });
          return;
        } else {
          setQuestionValidation({ error5: false });
        }
        if (
          question.iopts[2].content == undefined ||
          question.iopts[2].content.replace(/\s/g, "").length === 0 ||
          question.iopts[3].content == undefined ||
          question.iopts[3].content.replace(/\s/g, "").length === 0
        ) {
          setQuestionValidation({ error6: true });
          return;
        } else {
          setQuestionValidation({ error6: false });
        }
        if (question.iopts[4].content == question.iopts[5].content) {
          setQuestionValidation({ error8: true });
          return;
        } else {
          setQuestionValidation({ error8: false });
        }
      }

      if (
        question.iopts[5].content !== undefined &&
        question.iopts[5].content != ""
      ) {
        if (question.iopts[5].content.replace(/\s/g, "").length === 0) {
          setQuestionValidation({ error5: true });
          return;
        } else {
          setQuestionValidation({ error5: false });
        }
        if (
          question.iopts[2].content == undefined ||
          question.iopts[2].content.replace(/\s/g, "").length === 0 ||
          question.iopts[3].content == undefined ||
          question.iopts[3].content.replace(/\s/g, "").length === 0 ||
          question.iopts[4].content == undefined ||
          question.iopts[4].content.replace(/\s/g, "").length === 0
        ) {
          setQuestionValidation({ error6: true });
          return;
        } else {
          setQuestionValidation({ error6: false });
        }
      }

      if (
        question.iopts[0].content == question.iopts[1].content ||
        question.iopts[0].content == question.iopts[2].content ||
        question.iopts[0].content == question.iopts[3].content ||
        question.iopts[0].content == question.iopts[4].content ||
        question.iopts[0].content == question.iopts[5].content
      ) {
        setQuestionValidation({ error8: true });
        return;
      } else {
        setQuestionValidation({ error8: false });
      }

      if (
        question.iopts[1].content == question.iopts[2].content ||
        question.iopts[1].content == question.iopts[3].content ||
        question.iopts[1].content == question.iopts[4].content ||
        question.iopts[1].content == question.iopts[5].content
      ) {
        setQuestionValidation({ error8: true });
        return;
      } else {
        setQuestionValidation({ error8: false });
      }
    }

    if (qztype == 3) {
      setMultiSelectValidation(initialValidation);

      if (
        question.iopts[0].content == "" ||
        question.iopts[0].content == undefined ||
        question.iopts[0].content.replace(/\s/g, "").length === 0 ||
        question.iopts[1].content == "" ||
        question.iopts[1].content == undefined ||
        question.iopts[1].content.replace(/\s/g, "").length === 0 ||
        question.iopts[2].content == "" ||
        question.iopts[2].content == undefined ||
        question.iopts[2].content.replace(/\s/g, "").length === 0
      ) {
        setMultiSelectValidation({ error2: true });
        return;
      } else {
        setMultiSelectValidation({ error2: false });
      }

      if (
        question.iopts[2].content !== undefined &&
        question.iopts[2].content != ""
      ) {
        if (question.iopts[2].content.replace(/\s/g, "").length === 0) {
          setMultiSelectValidation({ error5: true });
          return;
        } else {
          setMultiSelectValidation({ error5: false });
        }
        if (
          question.iopts[2].content == question.iopts[3].content ||
          question.iopts[2].content == question.iopts[4].content ||
          question.iopts[2].content == question.iopts[5].content
        ) {
          setMultiSelectValidation({ error8: true });
          return;
        } else {
          setMultiSelectValidation({ error8: false });
        }
      }

      if (
        question.iopts[3].content !== undefined &&
        question.iopts[3].content != ""
      ) {
        if (question.iopts[3].content.replace(/\s/g, "").length === 0) {
          setMultiSelectValidation({ error5: true });
          return;
        } else {
          setMultiSelectValidation({ error5: false });
        }
        if (
          question.iopts[2].content == undefined ||
          question.iopts[2].content.replace(/\s/g, "").length === 0
        ) {
          setMultiSelectValidation({ error6: true });
          return;
        } else {
          setMultiSelectValidation({ error6: false });
        }
        if (
          question.iopts[3].content == question.iopts[4].content ||
          question.iopts[3].content == question.iopts[5].content
        ) {
          setMultiSelectValidation({ error8: true });
          return;
        } else {
          setMultiSelectValidation({ error8: false });
        }
      }

      if (
        question.iopts[4].content !== undefined &&
        question.iopts[4].content != ""
      ) {
        if (question.iopts[4].content.replace(/\s/g, "").length === 0) {
          setMultiSelectValidation({ error5: true });
          return;
        } else {
          setMultiSelectValidation({ error5: false });
        }
        if (
          question.iopts[2].content == undefined ||
          question.iopts[2].content.replace(/\s/g, "").length === 0 ||
          question.iopts[3].content == undefined ||
          question.iopts[3].content.replace(/\s/g, "").length === 0
        ) {
          setMultiSelectValidation({ error6: true });
          return;
        } else {
          setMultiSelectValidation({ error6: false });
        }
        if (question.iopts[4].content == question.iopts[5].content) {
          setMultiSelectValidation({ error8: true });
          return;
        } else {
          setMultiSelectValidation({ error8: false });
        }
      }

      if (
        question.iopts[5].content !== undefined &&
        question.iopts[5].content != ""
      ) {
        if (question.iopts[5].content.replace(/\s/g, "").length === 0) {
          setMultiSelectValidation({ error5: true });
          return;
        } else {
          setMultiSelectValidation({ error5: false });
        }
        if (
          question.iopts[2].content == undefined ||
          question.iopts[2].content.replace(/\s/g, "").length === 0 ||
          question.iopts[3].content == undefined ||
          question.iopts[3].content.replace(/\s/g, "").length === 0 ||
          question.iopts[4].content == undefined ||
          question.iopts[4].content.replace(/\s/g, "").length === 0
        ) {
          setMultiSelectValidation({ error6: true });
          return;
        } else {
          setMultiSelectValidation({ error6: false });
        }
      }
      if (
        question.iopts[0].content == question.iopts[1].content ||
        question.iopts[0].content == question.iopts[2].content ||
        question.iopts[0].content == question.iopts[3].content ||
        question.iopts[0].content == question.iopts[4].content ||
        question.iopts[0].content == question.iopts[5].content
      ) {
        setMultiSelectValidation({ error8: true });
        return;
      } else {
        setMultiSelectValidation({ error8: false });
      }

      if (
        question.iopts[1].content == question.iopts[2].content ||
        question.iopts[1].content == question.iopts[3].content ||
        question.iopts[1].content == question.iopts[4].content ||
        question.iopts[1].content == question.iopts[5].content
      ) {
        setMultiSelectValidation({ error8: true });
        return;
      } else {
        setMultiSelectValidation({ error8: false });
      }

      let tempoptionsiopts = [];
      let tempopsval = {};

      if (question.iopts[0].content !== undefined) {
        if (state.opt1) {
          tempopsval = {
            content: question.iopts[0].content,
            correct: "true",
          };
        } else {
          tempopsval = {
            content: question.iopts[0].content,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[1].content !== undefined) {
        if (state.opt2) {
          tempopsval = {
            content: question.iopts[1].content,
            correct: "true",
          };
        } else {
          tempopsval = {
            content: question.iopts[1].content,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[2].content !== undefined) {
        if (state.opt3) {
          tempopsval = {
            content: question.iopts[2].content,
            correct: "true",
          };
        } else {
          tempopsval = {
            content: question.iopts[2].content,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[3].content !== undefined) {
        if (state.opt4) {
          tempopsval = {
            content: question.iopts[3].content,
            correct: "true",
          };
        } else {
          tempopsval = {
            content: question.iopts[3].content,
            correct: "false",
          };
        }
        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[4].content != undefined) {
        if (state.opt5) {
          tempopsval = {
            content: question.iopts[4].content,
            correct: "true",
          };
        } else {
          tempopsval = {
            content: question.iopts[4].content,
            correct: "false",
          };
        }
        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[5].content !== undefined) {
        if (state.opt6) {
          tempopsval = {
            content: question.iopts[5].content,
            correct: "true",
          };
        } else {
          tempopsval = {
            content: question.iopts[5].content,
            correct: "false",
          };
        }
        tempoptionsiopts.push(tempopsval);
      }
      qdata = produce(qdata, (draft) => {
        draft[qidx].iopts = tempoptionsiopts;
        draft[qidx].atype = qztype;
      });
      qdata = JSON.parse(JSON.stringify(qdata));
      setQuizQuestion(qdata);
    }

    if (qztype == 4) {
      if (
        question.iopts[0].url === undefined ||
        question.iopts[0].url == "" ||
        question.iopts[1].url === undefined ||
        question.iopts[1].url == ""
      ) {
        setSingleSelectImageBase({ error2: true });
        return;
      } else {
        setSingleSelectImageBase({ error2: false });
      }
      if (question.correctoption === undefined) {
        setSingleSelectImageBase({ error3: true });
        return;
      } else {
        setSingleSelectImageBase({ error3: false });
      }

      if (question.correctoption !== undefined) {
        var yy = question.correctoption;
        if (question.iopts[yy] == undefined) {
          setSingleSelectImageBase({ error4: true });
          return;
        } else {
          setSingleSelectImageBase({ error4: false });
        }
      }

      if (question.iopts[3].url !== undefined && question.iopts[3].url != "") {
        if (question.iopts[2].url == undefined) {
          setSingleSelectImageBase({ error6: true });
          return;
        } else {
          setSingleSelectImageBase({ error: false });
        }
      }

      if (question.iopts[4].url != undefined && question.iopts[4].url != "") {
        if (
          question.iopts[2].url == undefined ||
          question.iopts[3].url == undefined
        ) {
          setSingleSelectImageBase({ error6: true });
          return;
        } else {
          setSingleSelectImageBase({ error6: false });
        }
      }

      if (question.iopts[5].url !== undefined && question.iopts[5].url != "") {
        if (
          question.iopts[2].url == undefined ||
          question.iopts[3].url == undefined ||
          question.iopts[4].url == undefined
        ) {
          setSingleSelectImageBase({ error6: true });
          return;
        } else {
          setSingleSelectImageBase({ error6: false });
        }
      }

      let tempoptionsiopts = [];
      let tempopsval = {};
      if (question.iopts[0].url !== undefined) {
        if (question.correctoption == "0") {
          tempopsval = {
            img: question.iopts[0].img,
            url: question.iopts[0].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            img: question.iopts[0].img,
            url: question.iopts[0].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[1].url !== undefined) {
        if (question.correctoption == "1") {
          tempopsval = {
            img: question.iopts[1].img,
            url: question.iopts[1].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            img: question.iopts[1].img,
            url: question.iopts[1].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[2].url !== undefined) {
        if (question.correctoption == "2") {
          tempopsval = {
            img: question.iopts[2].img,
            url: question.iopts[2].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            img: question.iopts[2].img,
            url: question.iopts[2].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[3].url !== undefined) {
        if (question.correctoption == "3") {
          tempopsval = {
            img: question.iopts[3].img,
            url: question.iopts[3].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            img: question.iopts[3].img,
            url: question.iopts[3].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[4].url !== undefined) {
        if (question.correctoption == "4") {
          tempopsval = {
            img: question.iopts[4].img,
            url: question.iopts[4].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            img: question.iopts[4].img,
            url: question.iopts[4].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[5].url !== undefined) {
        if (question.correctoption == "5") {
          tempopsval = {
            img: question.iopts[5].img,
            url: question.iopts[5].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            img: question.iopts[5].img,
            url: question.iopts[5].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }
      qdata = produce(qdata, (draft) => {
        draft[qidx].iopts = tempoptionsiopts;
        draft[qidx].atype = qztype;
      });
      qdata = JSON.parse(JSON.stringify(qdata));
      setQuizQuestion(qdata);
    }

    if (qztype == 5) {
      setMultiSelectImageBase(initialValidation);

      if (
        question.iopts[0].url === undefined ||
        question.iopts[0].url == "" ||
        question.iopts[1].url === undefined ||
        question.iopts[1].url == "" ||
        question.iopts[2].url === undefined ||
        question.iopts[2].url == ""
      ) {
        setMultiSelectImageBase({ error2: true });
        return;
      }

      if (question.iopts[3].url !== undefined && question.iopts[3].url != "") {
        if (question.iopts[2].url === undefined) {
          setMultiSelectImageBase({ error6: true });
          return;
        }
      }

      if (question.iopts[4].url !== undefined && question.iopts[4].url != "") {
        if (
          question.iopts[2].url === undefined ||
          question.iopts[3].url === undefined
        ) {
          setMultiSelectImageBase({ error6: true });
          return;
        }
      }

      if (question.iopts[5].url !== undefined && question.iopts[5].url != "") {
        if (
          question.iopts[2].url === undefined ||
          question.iopts[3].url === undefined ||
          question.iopts[4].url === undefined
        ) {
          setMultiSelectImageBase({ error6: true });
          return;
        }
      }

      let tempoptionsiopts = [];
      let tempopsval = {};
      if (question.iopts[0].url !== undefined) {
        if (state.opt1) {
          tempopsval = {
            img: question.iopts[0].img,
            url: question.iopts[0].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            img: question.iopts[0].img,
            url: question.iopts[0].url,
            correct: "false",
          };
        }

        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[1].url !== undefined) {
        if (state.opt2) {
          tempopsval = {
            img: question.iopts[1].img,
            url: question.iopts[1].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            img: question.iopts[1].img,
            url: question.iopts[1].url,
            correct: "false",
          };
        }
        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[2].url !== undefined) {
        if (state.opt3) {
          tempopsval = {
            img: question.iopts[2].img,
            url: question.iopts[2].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            img: question.iopts[2].img,
            url: question.iopts[2].url,
            correct: "false",
          };
        }
        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[3].url !== undefined) {
        if (state.opt4) {
          tempopsval = {
            img: question.iopts[3].img,
            url: question.iopts[3].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            img: question.iopts[3].img,
            url: question.iopts[3].url,
            correct: "false",
          };
        }
        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[4].url !== undefined) {
        if (state.opt5) {
          tempopsval = {
            img: question.iopts[4].img,
            url: question.iopts[4].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            img: question.iopts[4].img,
            url: question.iopts[4].url,
            correct: "false",
          };
        }
        tempoptionsiopts.push(tempopsval);
      }

      if (question.iopts[5].url !== undefined) {
        if (state.opt6) {
          tempopsval = {
            img: question.iopts[5].img,
            url: question.iopts[5].url,
            correct: "true",
          };
        } else {
          tempopsval = {
            img: question.iopts[5].img,
            url: question.iopts[5].url,
            correct: "false",
          };
        }
        tempoptionsiopts.push(tempopsval);
      }

      qdata = produce(qdata, (draft) => {
        draft[qidx].iopts = tempoptionsiopts;
        draft[qidx].atype = qztype;
      });
      qdata = JSON.parse(JSON.stringify(qdata));

      setQuizQuestion(qdata);
    }

    if (qztype == 1) {
      if (value == undefined || value == "" || value == null) {
        setDefaultValidation({ error4: true });
        return;
      } else {
        setDefaultValidation({ error4: false });
      }

      let temp = [];
      let tempopsval = {};
      if (
        question.iopts[0].correct === "true" ||
        question.iopts[0].correct === true
      ) {
        tempopsval = {
          content: "True",
          correct: "true",
        };
      } else {
        tempopsval = {
          content: "True",
          correct: "false",
        };
      }
      temp.push(tempopsval);
      if (
        question.iopts[1].correct === "true" ||
        question.iopts[1].correct === true
      ) {
        tempopsval = {
          content: "False",
          correct: "true",
        };
      } else {
        tempopsval = {
          content: "False",
          correct: "false",
        };
      }
      temp.push(tempopsval);

      qdata = produce(qdata, (draft) => {
        draft[qidx].iopts = temp;
        draft[qidx].atype = "1";
      });
      qdata = JSON.parse(JSON.stringify(qdata));

      setQuizQuestion(qdata);
    }

    if (qztype == 6) {
      matchTheFollowing.forEach((match) => {
        if (match.value1 == "" || match.value2 == "") {
          setDefaultValidation({ error5: true });
          return;
        } else {
          setDefaultValidation({ error5: false });
        }
      });

      let str1 = "";
      let ind = 0;

      for (var i = 0; i < matchTheFollowing.length; i++) {
        if (matchTheFollowing[i].value != "") {
          ind += 1;
          str1 +=
            "<tr><td>" +
            ind +
            "." +
            matchTheFollowing[i].value1 +
            "</td><td>" +
            ind +
            "." +
            matchTheFollowing[i].value2 +
            "</td></tr>";
        }
      }
      //

      qdata = produce(quizQuestion, (v) => {
        v[
          qidx
        ].istem = `<table class='qtable'><tr><th>A</th><th>B</th></tr>${str1}</table>`;
        v[qidx].atype = "6";
      });

      qdata = JSON.parse(JSON.stringify(qdata));

      if (save === 0) {
        // qdata.push({
        //   iid: generate(),
        //   istem: "",
        //   iopts: initialValues,
        // });
      }

      setQuizQuestion(qdata);
    }
    setAType(qztype);
    qdata = produce(qdata, (draft) => {
      draft[qidx].atype = qztype;
    });
    qdata = JSON.parse(JSON.stringify(qdata));
    setQuizQuestion(qdata);

    if (qztype != 6 && save === 0) {
      // setQuizQuestion((currentQuestion) => [
      //   ...currentQuestion,
      //   {
      //     iid: generate(),
      //     istem: "",
      //     iopts: initialValues,
      //   },
      // ]);
    } else {
      if (save !== 0) {
        let arr = [];
        qdata.forEach((ques, idx, array) => {
          const fill = ques.iopts.filter((opts) => opts.content !== "");

          arr.push(
            produce(ques, (draft) => {
              draft.iopts = fill;
              delete draft.correctoption;
              if (draft.atype == 7 || draft.atype == 8) {
                delete draft.iopts;
              }
            })
          );
        });
        let instuct = [...instructions];
        let inst = [];
        instuct.map((ins) => {
          let i = { ...ins };
          inst.push(i.value);
        });
        setProgress(true);
        setSuccess(true);
      }
    }
    setValue("");
    setSelectedFile("");
    let cq = currentQuestion;

    if (buttonClick === "Prev") {
      cq = currentQuestion - 1;
      setQuizType(qdata[cq - 1].atype);
      setState(initialState);
      let prevst = cq - 1;
      for (let index = 0; index < qdata[cq - 1].iopts.length; index++) {
        if (
          qdata[prevst].iopts[index].correct === "true" ||
          qdata[prevst].iopts[index].correct === true
        ) {
          if (qdata[prevst].atype == 3 || qdata[prevst].atype == 5) {
            switch (index) {
              case 0:
                setState((option) =>
                  produce(option, (v) => {
                    v["opt1"] = true;
                  })
                );

                break;
              case 1:
                setState((option) =>
                  produce(option, (v) => {
                    v["opt2"] = true;
                  })
                );
                break;
              case 2:
                setState((option) =>
                  produce(option, (v) => {
                    v["opt3"] = true;
                  })
                );
                break;
              case 3:
                setState((option) =>
                  produce(option, (v) => {
                    v["opt4"] = true;
                  })
                );
                break;
              case 4:
                setState((option) =>
                  produce(option, (v) => {
                    v["opt5"] = true;
                  })
                );
                break;
              case 5:
                setState((option) =>
                  produce(option, (v) => {
                    v["opt6"] = true;
                  })
                );
                break;
              default:
                break;
            }
          } else if (qdata[prevst].atype != 1) {
            setQuizQuestion((option) =>
              produce(option, (v) => {
                v[prevst].correctoption = index.toString();
              })
            );
          } else {
            setValue(qdata[prevst].iopts[index].content);
          }
        }
      }
      if (qdata[prevst].atype != 1 && qdata[prevst].iopts.length < 6) {
        for (let index = 0; index < 6; index++) {
          if (qdata[prevst].iopts[index] === undefined) {
            setQuizQuestion((currentistem) =>
              produce(currentistem, (v) => {
                v[prevst].iopts[index] = {};
                v[prevst].iopts[index].content = "";
              })
            );
          }
          //qdata[cq -1].iopts[index].content = "";
        }
        qdata = JSON.parse(JSON.stringify(qdata));
      }
      if (qdata[prevst].atype == 6) {
        let tbdata = qdata[prevst].istem.replace("<table class='qtable'>", "");
        tbdata = tbdata.replace("</table>", "");
        let rmindx = tbdata.indexOf("</tr>");
        let rmtxt = tbdata.substr(0, rmindx);
        tbdata = tbdata.replace(rmtxt, "");
        tbdata = tbdata.split("<tr>");
        setMatchTheFollowing("");
        for (var j = 1; j < tbdata.length; j++) {
          let tdarr = tbdata[j].split("<td>");
          let vl1 = tdarr[1].replace("</td>", "").split(".");
          let vl2 = tdarr[2]
            .replace("</td>", "")
            .replace("</tr>", "")
            .split(".");
          setMatchTheFollowing((currentmatchTheFollowing) => [
            ...currentmatchTheFollowing,
            {
              id: generate(),
              value1: vl1[1],
              value2: vl2[1],
            },
          ]);
        }
      }
    } else if (buttonClick === "Next") {
      setState(initialState);
      cq = currentQuestion + 1;

      setQuizType(qdata[currentQuestion].atype);
      if (qdata[currentQuestion].atype == 6) {
        let tbdata = qdata[currentQuestion].istem.replace(
          "<table class='qtable'>",
          ""
        );
        tbdata = tbdata.replace("</table>", "");
        let rmindx = tbdata.indexOf("</tr>");
        let rmtxt = tbdata.substr(0, rmindx);
        tbdata = tbdata.replace(rmtxt, "");
        tbdata = tbdata.split("<tr>");
        setMatchTheFollowing("");
        for (var j = 1; j < tbdata.length; j++) {
          let tdarr = tbdata[j].split("<td>");
          let vl1 = tdarr[1].replace("</td>", "").split(".");
          let vl2 = tdarr[2]
            .replace("</td>", "")
            .replace("</tr>", "")
            .split(".");
          setMatchTheFollowing((currentmatchTheFollowing) => [
            ...currentmatchTheFollowing,
            {
              id: generate(),
              value1: vl1[1],
              value2: vl2[1],
            },
          ]);
        }
      }
      if (qdata[cq - 1].iopts != undefined) {
        for (let index = 0; index < qdata[cq - 1].iopts.length; index++) {
          if (
            qdata[cq - 1].iopts[index].correct === "true" ||
            qdata[cq - 1].iopts[index].correct === true
          ) {
            if (
              qdata[currentQuestion].atype == 3 ||
              qdata[currentQuestion].atype == 5
            ) {
              switch (index) {
                case 0:
                  setState((option) =>
                    produce(option, (v) => {
                      v["opt1"] = true;
                    })
                  );

                  break;
                case 1:
                  setState((option) =>
                    produce(option, (v) => {
                      v["opt2"] = true;
                    })
                  );
                  break;
                case 2:
                  setState((option) =>
                    produce(option, (v) => {
                      v["opt3"] = true;
                    })
                  );
                  break;
                case 3:
                  setState((option) =>
                    produce(option, (v) => {
                      v["opt4"] = true;
                    })
                  );
                  break;
                case 4:
                  setState((option) =>
                    produce(option, (v) => {
                      v["opt5"] = true;
                    })
                  );
                  break;
                case 5:
                  setState((option) =>
                    produce(option, (v) => {
                      v["opt6"] = true;
                    })
                  );
                  break;
                default:
                  break;
              }
            } else if (
              qdata[currentQuestion].atype == 4 ||
              qdata[currentQuestion].atype == 6 ||
              qdata[currentQuestion].atype == 2
            ) {
              setQuizQuestion((option) =>
                produce(option, (v) => {
                  v[currentQuestion].correctoption = index.toString();
                })
              );
            } else {
              setValue(qdata[cq - 1].iopts[index].content);
            }
          }
        }
      }

      if (
        qdata[currentQuestion].iopts != undefined &&
        qdata[currentQuestion].atype != 1 &&
        qdata[currentQuestion].iopts.length < 6
      ) {
        for (let index = 0; index < 6; index++) {
          if (qdata[currentQuestion].iopts[index] === undefined) {
            setQuizQuestion((currentistem) =>
              produce(currentistem, (v) => {
                v[currentQuestion].iopts[index] = {};
                v[currentQuestion].iopts[index].content = "";
              })
            );
          }
          //qdata[cq -1].iopts[index].content = "";
        }
        qdata = JSON.parse(JSON.stringify(qdata));
      }
    } else {
      cq = qdata.length + 1;
      setState(initialState);
      setQuizType("");
    }

    questionObj.currentq = cq;
    setQuestionObj((currentQuestion) => ({ ...questionObj, questionObj }));
    setCurrentQuestion((currentcQuestion) => cq);
  };

  function handleChangeMarks(e) {
    if (e.target.value < 1) {
      setDefaultValidation({ error10: true });
    } else {
      setDefaultValidation({ error10: false });
    }
  }

  const addTextBox = () => {
    setTextValues([...textValues, ""]); // Add an empty value to the state array
  };

  // Function to update the value of a textbox
  const handleTextBoxChange = (index, event, qidx) => {
    const newValues = [...textValues];
    newValues[index] = event.target.value;
    setTextValues(newValues);
    setQuizQuestion((currentistem) =>
      produce(currentistem, (v) => {
        v[questionObj.currentq - 1].keywords = newValues;
      })
    );
  };

  const removeTextBox = (index) => {
    const updatedTextBoxes = textValues.filter((_, i) => i !== index);
    console.log("updatedTextBoxes", updatedTextBoxes);
    // Remove the text box at the specified index
    setTextValues(updatedTextBoxes);
    setQuizQuestion((currentistem) =>
      produce(currentistem, (v) => {
        v[questionObj.currentq - 1].keywords = updatedTextBoxes;
      })
    );
  };

  // const handleDateChange = (e) => {
  //   setDeadLineDate(e.target.value);

  //   let dans = deadLineDate;
  //  // let tans = firstViewData.ansTime;

  //   let datetimeanswer = new Date((dans).split(" "));
  //   datetimeanswer = datetimeanswer.getTime();
  //  // console.log(dateTimeStart);
  // //  console.log(dateTimeEnd);

  //   console.log("e.target.value", datetimeanswer);

  //   setQuizQuestion((currentistem) =>
  //     produce(currentistem, (v) => {
  //       v[questionObj.currentq - 1].deadLineDate = deadLineDate;
  //     })
  //   );
  // };

  return (
    <div
      className={classes.container}
      // style={{ height : (quizQuestion[currentQuestion] != undefined &&
      // quizQuestion[currentQuestion].atype == '6')? '500px' : '100%',
      // overflowY : (quizQuestion[currentQuestion] != undefined &&
      //   quizQuestion[currentQuestion].atype == '6')? 'scroll' : ''}}
    >
      <div className={classes.quizButtonsContainer}>
        {titleForm ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              width: "500px",
              gap: "2rem",
            }}
          >
            {defaultValidation.error1 ? (
              <p className="firstViewValidation">
                Please Select the Mini Assignment type
              </p>
            ) : null}
            {selectminiassigmentType ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "3rem",
                  width: "100%",
                }}
              >
                <select
                  value={miniassigmentType}
                  disabled={editQuiz === true}
                  className="classInput_assign_assess"
                  onChange={(e) => {
                    setMiniassigmentType(e.target.value);
                  }}
                >
                  <option style={{ display: "none" }}>
                    Select - Mini Assignment Type
                  </option>
                  <option value="1">Multiple Type Questions </option>
                  <option value="2">Descriptive Questions </option>
                  <option value="3">match The Following </option>
                  <option value="4">Fill In The Blanks </option>
                </select>
              </div>
            ) : null}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "3rem",
                width: "100%",
              }}
            >
              <h3
                style={{
                  flexBasis: "15%",
                  fontSize: "1.6rem",
                  fontWeight: "500",
                }}
              >
                Title:
              </h3>
              <input
                type="text"
                style={{
                  flexGrow: "1",
                  height: "30px",
                  outline: "none",
                  fontSize: "1.5rem",
                  paddingLeft: "1rem",
                  border: "1px solid #01498e",
                  boxShadow: "0px 0px 5px 0px lightgrey",
                }}
                defaultValue={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            {cutOff ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "3rem",
                  width: "100%",
                }}
              >
                <h3
                  style={{
                    flexBasis: "15%",
                    fontSize: "1.6rem",
                    fontWeight: "500",
                  }}
                >
                  Cutoff Score:
                </h3>
                <input
                  type="text"
                  style={{
                    flexGrow: "1",
                    height: "30px",
                    outline: "none",
                    fontSize: "1.5rem",
                    paddingLeft: "1rem",
                    border: "1px solid #01498e",
                    boxShadow: "0px 0px 5px 0px lightgrey",
                  }}
                  required
                  autoComplete="false"
                  pattern="\d*"
                  value={cutOffVal}
                  maxLength="3"
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setCutOffVal(e.target.value);
                    }
                  }}
                />
              </div>
            ) : null}

            {deadLine ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "3rem",
                  width: "100%",
                }}
              >
                <h3
                  style={{
                    flexBasis: "15%",
                    fontSize: "1.6rem",
                    fontWeight: "500",
                  }}
                >
                  Submission DeadLine:
                </h3>
                <input
                  type="date"
                  style={{
                    flexGrow: "1",
                    height: "30px",
                    outline: "none",
                    fontSize: "1.5rem",
                    paddingLeft: "1rem",
                    border: "1px solid #01498e",
                    boxShadow: "0px 0px 5px 0px lightgrey",
                  }}
                  required
                  value={deadLineVal}
                  // maxLength="3"
                  onChange={(e) => {
                    if (e.target.value === "" || e.target.value) {
                      setDeadLineVal(e.target.value);
                    }
                  }}
                />
              </div>
            ) : null}
            <div>
              {editQuiz === false || editQuiz === undefined ? (
                <CustomButton
                  disabled={title.replace(/\s/g, "").length >= 1 ? false : true}
                  onClick={() => {
                    if (miniAssignment == true && miniassigmentType == "") {
                      setDefaultValidation({ error1: true });
                    } else {
                      addQuestion();
                    }
                  }}
                >
                  Add Question
                </CustomButton>
              ) : (
                <CustomButton
                  disabled={title.replace(/\s/g, "").length >= 1 ? false : true}
                  onClick={() => {
                    editObjNext();
                    setAddQestBtn("false");
                  }}
                >
                  Next
                </CustomButton>
              )}
            </div>
          </div>
        ) : null}
        {typesButtons ? (
          <div className={classes.displayFlex}>
            <CustomButton onClick={() => addQuesType("0")}>
              Formative Quiz With Feedback{" "}
            </CustomButton>
            <CustomButton onClick={() => addQuesType("2")}>
              {" "}
              Formative Quiz
            </CustomButton>
            <CustomButton onClick={() => addQuesType("1")}>
              {" "}
              Summative Quiz
            </CustomButton>
            <CustomButton onClick={() => addQuesType("3")}>
              {" "}
              Mini Assignment
            </CustomButton>
          </div>
        ) : null}
        {quizForm ? (
          <>
            {quizQuestion.map((question, qidx) => (
              <>
                {qidx == questionObj.currentq - 1 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2rem",
                    }}
                    key={question.iid}
                  >
                    <div className={classes.displayFlex}>
                      <div className={classes.flexBasis}>
                        <h4>Question No:</h4>
                      </div>
                      <div className={classes.root}>
                        <h4 className={classes.textCenter}>
                          {qidx + 1} of {quizQuestion.length}
                        </h4>
                      </div>
                    </div>
                    <div className={classes.displayFlex}>
                      <div className={classes.flexBasis}>
                        <h4>Question </h4>
                      </div>
                      <div className={classes.root}>
                        {error1 ? (
                          <span className="color-red">
                            Question cannot be empty
                          </span>
                        ) : null}
                        <textarea
                          className="classTextarea"
                          value={question.istem}
                          autoComplete="off"
                          onChange={(e) => {
                            const istem = e.target.value;
                            setIstem(istem);
                            setQuizQuestion((currentistem) =>
                              produce(currentistem, (v) => {
                                v[questionObj.currentq - 1].istem = istem;
                              })
                            );
                          }}
                        ></textarea>
                      </div>
                    </div>
                    <div className={classes.displayFlexs}>
                      <div className={classes.flexBasis}>
                        <h4>Image</h4>{" "}
                        <sub className={classes.small}>(Optional)</sub>
                      </div>
                      <div className={classes.root}>
                        <input
                          accept="image/*"
                          className={classes.input}
                          id="contained-button-file"
                          multiple
                          type="file"
                          onChange={(e) => {
                            onFileChange(e);
                          }}
                        />

                        <label htmlFor="contained-button-file">
                          <Button
                            className={classes.uploadBtn}
                            component="span"
                          >
                            Upload
                          </Button>
                        </label>
                        {
                          <h4
                            style={{
                              display: "inline-block",
                              marginLeft: "2rem",
                            }}
                          >
                            {selectedFile !== null &&
                            selectedFile.file !== undefined ? (
                              selectedFile.file.name
                            ) : (
                              <></>
                            )}

                            {selectedFile.myfilename !== null
                              ? selectedFile.myfilename
                              : ""}
                          </h4>
                        }
                      </div>
                    </div>

                    <div className={classes.displayFlex}>
                      {miniAssignment ? (
                        <>
                          <div className={classes.flexBasis}>
                            <h4>Add KeyWord</h4>
                            <IconButton
                              aria-label="add"
                              // onClick={() => addInstruction(instruction)}
                              onClick={addTextBox}
                            >
                              <AddCircleIcon fontSize="small" />
                            </IconButton>
                          </div>

                          <div className={classes.root}>
                            <div>
                              {textValues.map((value, index) => (
                                <div key={index}>
                                  <input
                                    style={{
                                      width: "70%",
                                      border: 0,
                                      padding: "1rem 1rem",
                                      boxShadow: " 0px 0px 5px 0px lightgrey",
                                      fontSize: "15px",
                                    }}
                                    key={index}
                                    type="text"
                                    value={value}
                                    onChange={(event) =>
                                      handleTextBoxChange(index, event, qidx)
                                    }
                                  />
                                  <button
                                    style={{ margin: "2rem" }}
                                    onClick={() => removeTextBox(index)}
                                  >
                                    -
                                  </button>
                                </div>
                              ))}
                            </div>
                            {error9 ? (
                              <span className="color-red">
                                KeyWord Connot be empty
                              </span>
                            ) : null}
                          </div>
                        </>
                      ) : null}
                    </div>

                    <div className={classes.displayFlex}>
                      {!miniAssignment ? (
                        <div className={classes.flexBasis}>
                          <h4>Options:</h4>
                          <sub className={classes.small}>
                            (Select the correct option)
                          </sub>
                        </div>
                      ) : null}
                      {/* <div className={classes.flexBasis}>
                        <h4>Options:</h4>
                        <sub className={classes.small}>
                          (Select the correct option)
                        </sub>
                      </div> */}
                      <div className={classes.root}>
                        {error2 ? (
                          <span className="color-red">
                            You need to enter at least 2 options
                          </span>
                        ) : null}
                        {error3 ? (
                          <span className="color-red">
                            Please select the correct option
                          </span>
                        ) : null}
                        {error4 ? (
                          <span className="color-red">
                            You have selected an option for which no value has
                            been entered
                          </span>
                        ) : null}
                        {error5 ? (
                          <span className="color-red">
                            Option cannot just contain space
                          </span>
                        ) : null}
                        {error6 ? (
                          <span className="color-red">
                            You have entered an option by skipping one of the
                            previous one
                          </span>
                        ) : null}
                        {error7 ? (
                          <span className="color-red">
                            Please enter a feedback
                          </span>
                        ) : null}
                        {error8 ? (
                          <span className="color-red">
                            Entered options must have different values
                          </span>
                        ) : null}
                        {!miniAssignment ? (
                          <FormControl
                            component="fieldset"
                            fullWidth
                            style={{ overflowY: "auto", maxHeight: "330px" }}
                          >
                            <RadioGroup
                              onChange={(e) => handleChange(e, qidx)}
                              value={question.correctoption}
                            >
                              {question.iopts.map((opts, oidx) => (
                                <>
                                  <FormControlLabel
                                    value={oidx.toString()}
                                    control={<BlueRadio />}
                                    label={
                                      <input
                                        value={opts.content}
                                        className="classInput"
                                        type="text"
                                        autoComplete="off"
                                        onChange={(e) =>
                                          handleInputChange(e, oidx, qidx)
                                        }
                                      />
                                    }
                                  />
                                  {feedbackView && opts.content !== "" ? (
                                    <input
                                      value={opts.feedback}
                                      className="classInputFeedback"
                                      type="text"
                                      name="feedback"
                                      autoComplete="off"
                                      placeholder="Feedback"
                                      onChange={(e) =>
                                        handleFeedbackChange(e, oidx, qidx)
                                      }
                                    />
                                  ) : null}
                                </>
                              ))}
                            </RadioGroup>
                          </FormControl>
                        ) : null}
                        {!miniAssignment ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-evenly",
                              gap: "2rem",
                              marginTop: "2rem",
                            }}
                          >
                            <CustomButton
                              type="submit"
                              disabled={
                                questionObj.currentq - 1 === 0 ? true : false
                              }
                              onClick={() => {
                                let qcurrentq = questionObj.currentq - 1;

                                if (qcurrentq > 0) {
                                  questionObj.currentq = qcurrentq;
                                  setQuestionObj((currentQuestion) => ({
                                    ...questionObj,
                                  }));
                                }
                                if (
                                  quizQuestion[qcurrentq - 1].myfilename !==
                                  undefined
                                ) {
                                  setSelectedFile({
                                    myfilename:
                                      quizQuestion[qcurrentq - 1].myfilename,
                                  });
                                } else {
                                  setSelectedFile("");
                                }
                                prevQues(question);
                              }}
                            >
                              Previous
                            </CustomButton>
                            <CustomButton
                              type="submit"
                              disabled={
                                questionObj.currentq + 1 <= quizQuestion.length
                                  ? false
                                  : true
                              }
                              onClick={() => {
                                //nextQues();
                                let qcurrentq = questionObj.currentq + 1;

                                if (qcurrentq <= quizQuestion.length) {
                                  questionObj.currentq = qcurrentq;
                                  setQuestionObj((currentQuestion) => ({
                                    ...questionObj,
                                  }));
                                }
                                nextQues(question);
                                if (
                                  quizQuestion[qcurrentq - 1].myfilename !==
                                  undefined
                                ) {
                                  setSelectedFile({
                                    myfilename:
                                      quizQuestion[qcurrentq - 1].myfilename,
                                  });
                                } else {
                                  setSelectedFile("");
                                }
                              }}
                            >
                              Next
                            </CustomButton>
                            <CustomButton
                              type="submit"
                              onClick={() => {
                                validate(question);
                              }}
                            >
                              Add question
                            </CustomButton>
                            <CustomButton
                              type="submit"
                              disabled={
                                quizQuestion.length === 1 ? true : false
                              }
                              onClick={() => {
                                const filteredArray = quizQuestion.filter(
                                  (rvques) => rvques.iid !== question.iid
                                );
                                setQuizQuestion(filteredArray);

                                if (questionObj.currentq != 1) {
                                  questionObj.currentq =
                                    questionObj.currentq - 1;
                                }
                                setQuestionObj((currentstate) => ({
                                  ...questionObj,
                                }));
                              }}
                            >
                              Remove question
                            </CustomButton>
                            <CustomButton
                              type="submit"
                              onClick={() => quizSave(question)}
                            >
                              Save
                            </CustomButton>
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-evenly",
                              gap: "2rem",
                              marginTop: "2rem",
                            }}
                          >
                            {/* <CustomButton
                              type="submit"
                              disabled={
                                questionObj.currentq - 1 === 0 ? true : false
                              }
                              onClick={() => {
                                let qcurrentq = questionObj.currentq - 1;

                                if (qcurrentq > 0) {
                                  questionObj.currentq = qcurrentq;
                                  setQuestionObj((currentQuestion) => ({
                                    ...questionObj,
                                  }));
                                }
                                if (
                                  quizQuestion[qcurrentq - 1].myfilename !==
                                  undefined
                                ) {
                                  setSelectedFile({
                                    myfilename:
                                      quizQuestion[qcurrentq - 1].myfilename,
                                  });
                                } else {
                                  setSelectedFile("");
                                }
                                prevQues(question);
                              }}
                            >
                              Previous
                            </CustomButton>
                            <CustomButton
                              type="submit"
                              disabled={
                                questionObj.currentq + 1 <= quizQuestion.length
                                  ? false
                                  : true
                              }
                              onClick={() => {
                                //nextQues();
                                let qcurrentq = questionObj.currentq + 1;

                                if (qcurrentq <= quizQuestion.length) {
                                  questionObj.currentq = qcurrentq;
                                  setQuestionObj((currentQuestion) => ({
                                    ...questionObj,
                                  }));
                                }
                                nextQues(question);
                                if (
                                  quizQuestion[qcurrentq - 1].myfilename !==
                                  undefined
                                ) {
                                  setSelectedFile({
                                    myfilename:
                                      quizQuestion[qcurrentq - 1].myfilename,
                                  });
                                } else {
                                  setSelectedFile("");
                                }
                              }}
                            >
                              Next
                            </CustomButton>
                            <CustomButton
                              type="submit"
                              onClick={() => {
                                validate(question);
                              }}
                            >
                              Add question
                            </CustomButton>
                            <CustomButton
                              type="submit"
                              disabled={
                                quizQuestion.length === 1 ? true : false
                              }
                              onClick={() => {
                                const filteredArray = quizQuestion.filter(
                                  (rvques) => rvques.iid !== question.iid
                                );
                                setQuizQuestion(filteredArray);

                                if (questionObj.currentq != 1) {
                                  questionObj.currentq =
                                    questionObj.currentq - 1;
                                }
                                setQuestionObj((currentstate) => ({
                                  ...questionObj,
                                }));
                              }}
                            >
                              Remove question
                            </CustomButton> */}
                            <CustomButton
                              type="submit"
                              onClick={() => quizSave(question)}
                            >
                              Save
                            </CustomButton>
                            <CustomButton type="submit" onClick={handleClose}>
                              Cancel
                            </CustomButton>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </>
            ))}
          </>
        ) : null}

        {multiform ? (
          <>
            {quizQuestion.map((question, qidx) => (
              <>
                {qidx == questionObj.currentq - 1 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      // gap: "2rem",
                    }}
                    key={question.iid}
                  >
                    <div className={classes.displayFlex}>
                      <div className={classes.root}>
                        <h4 className={classes.textCenter}>
                          Question {qidx + 1} of {quizQuestion.length}
                        </h4>
                      </div>
                    </div>
                    {defaultValidation.error1 ? (
                      <p className="firstViewValidation">
                        Please select the Question type
                      </p>
                    ) : null}
                    <select
                      className="classInput_assign_assess"
                      // value={question.atype}
                      onChange={(e) => {
                        setQuizType(e.target.value);
                        let qesData = [...quizQuestion];
                        if (quizType !== "1") {
                          setQuizQuestion(
                            produce(qesData, (draft) => {
                              draft[qidx].iopts = initialValues;
                            })
                          );
                        }
                      }}
                    >
                      <option style={{ display: "none" }}>
                        Select - Question Type
                      </option>
                      <option value="1">True or False</option>
                      <option value="2">Single Select Multiple Choice</option>
                      {/* <option value="3">Multiple Select Multiple Choice</option> */}
                      <option value="4">
                        Single Select Image Based Multiple Choice
                      </option>
                      {/* <option value="5">
                        Multiple Select Image Based Multiple Choice
                      </option> */}
                      <option value="6">Match the Following</option>
                      <option value="7">Fill in the Blanks</option>
                    </select>
                    {quizType !== "6" ? (
                      <div className={classes.displayFlex}>
                        <div className={classes.flexBasis}>
                          <h4>Question</h4>
                        </div>

                        <div style={{ width: "100%" }}>
                          {defaultValidation.error2 ? (
                            <p className="firstViewValidation">
                              Question cannot be empty
                            </p>
                          ) : null}
                          <textarea
                            className="classTextarea"
                            style={{ marginBottom: "20px" }}
                            value={question.istem}
                            autoComplete="off"
                            onChange={(e) => {
                              const istem = e.target.value;
                              setIstem(istem);
                              setQuizQuestion((currentistem) =>
                                produce(currentistem, (v) => {
                                  v[qidx].istem = istem;
                                })
                              );
                            }}
                          ></textarea>
                        </div>
                      </div>
                    ) : (
                      <div className={classes.displayFlex}>
                        <div className={classes.flexBasis}>
                          <h4>Question:</h4>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                          className={classes.root1}
                        >
                          {defaultValidation.error5 ? (
                            <p className="firstViewValidation">
                              Please enter atleast 3 validate questions
                            </p>
                          ) : null}
                          {matchTheFollowing.map((match, ids) => (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "2rem",
                              }}
                            >
                              <input
                                className="classInput_MatchTheFollowing"
                                onChange={(e) => {
                                  let value1 = e.target.value;
                                  setMatchTheFollowing((currMatchVal) =>
                                    produce(currMatchVal, (v) => {
                                      v[ids].value1 = value1;
                                    })
                                  );
                                }}
                              />
                              <input
                                className="classInput_MatchTheFollowing"
                                onChange={(e) => {
                                  let value2 = e.target.value;
                                  setMatchTheFollowing((currMatchVal) =>
                                    produce(currMatchVal, (v) => {
                                      v[ids].value2 = value2;
                                    })
                                  );
                                }}
                              />
                              <IconButton
                                aria-label="add"
                                onClick={() => addInstruction(ids)}
                              >
                                <AddCircleIcon />
                              </IconButton>

                              <IconButton
                                aria-label="delete"
                                onClick={() => deleteInstruction(match, ids)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    <div
                      className={classes.displayFlex}
                      style={{ justifyContent: "start" }}
                    >
                      <div className={classes.flexBasis}>
                        <h4>Image </h4>{" "}
                        <sub className={classes.small}>(Optional)</sub>
                      </div>
                      <div className={classes.root1}>
                        <input
                          accept="image/*"
                          className={classes.input}
                          id="contained-button-file"
                          multiple
                          type="file"
                          onChange={(e) => {
                            onFileChange(e, qidx);
                          }}
                        />

                        <label htmlFor="contained-button-file">
                          <Button
                            className={classes.uploadBtn}
                            component="span"
                            style={{
                              textTransform: "capitalize",
                            }}
                          >
                            Upload
                          </Button>
                        </label>
                        {
                          <h4
                            style={{
                              display: "inline-block",
                              marginLeft: "2rem",
                            }}
                          >
                            {selectedFile !== null &&
                            selectedFile.file !== undefined
                              ? selectedFile.file.name
                              : ""}
                          </h4>
                        }
                      </div>
                    </div>
                    {quizType === "1" ? (
                      <div style={{ marginLeft: "8rem" }}>
                        {tFOptions.map((opts, oidx) => (
                          <React.Fragment key={oidx + "sfsd"}>
                            <BlueRadio
                              checked={value == `${opts.content}`}
                              onChange={(e) =>
                                handleChangeMulti(e, oidx, qidx, quizType)
                              }
                              value={`${opts.content}`}
                              name="radio-button-demo"
                              inputProps={{ "aria-label": "False" }}
                            />
                            <span style={{ fontSize: "1.3rem" }}>
                              {opts.content}
                            </span>
                          </React.Fragment>
                        ))}
                        {defaultValidation.error4 ? (
                          <p className="firstViewValidation">
                            Please select True or False
                          </p>
                        ) : null}
                      </div>
                    ) : null}
                    <div style={{ paddingLeft: "15%" }}>
                      {questionValidation.error2 && (
                        <p className="firstViewValidation">
                          You need to enter at least 2 options
                        </p>
                      )}
                      {questionValidation.error3 && (
                        <p className="firstViewValidation">
                          Please select the correct option
                        </p>
                      )}
                      {questionValidation.error4 && (
                        <p className="firstViewValidation">
                          You have selected an option for which no value has
                          been entered
                        </p>
                      )}
                      {questionValidation.error5 && (
                        <p className="firstViewValidation">
                          Option cannot just contain space
                        </p>
                      )}
                      {questionValidation.error6 && (
                        <p className="firstViewValidation">
                          You have entered an option by skipping one of the
                          previous one
                        </p>
                      )}
                      {questionValidation.error7 && (
                        <p className="firstViewValidation">
                          Please enter a feedback
                        </p>
                      )}
                      {questionValidation.error8 && (
                        <p className="firstViewValidation">
                          Entered options must have different values
                        </p>
                      )}
                      {multiSelectValidation.error1 && (
                        <p className="firstViewValidation">
                          Select at least 2 options
                        </p>
                      )}
                      {multiSelectValidation.error2 && (
                        <p className="firstViewValidation">
                          You need to enter at least 3 options
                        </p>
                      )}
                      {multiSelectValidation.error3 && (
                        <p className="firstViewValidation">
                          Please select the correct option
                        </p>
                      )}
                      {multiSelectValidation.error4 && (
                        <p className="firstViewValidation">
                          You have selected an option for which no value has
                          been entered
                        </p>
                      )}
                      {multiSelectValidation.error5 && (
                        <p className="firstViewValidation">
                          Option cannot just contain space
                        </p>
                      )}
                      {multiSelectValidation.error6 && (
                        <p className="firstViewValidation">
                          You have entered an option by skipping one of the
                          previous one
                        </p>
                      )}
                      {multiSelectValidation.error7 && (
                        <p className="firstViewValidation">
                          Please enter a feedback
                        </p>
                      )}
                      {multiSelectValidation.error8 && (
                        <p className="firstViewValidation">
                          Entered options must have different values
                        </p>
                      )}
                      {singleSelectImageBase.error2 && (
                        <p className="firstViewValidation">
                          You need to select at least 2 Images
                        </p>
                      )}
                      {singleSelectImageBase.error3 && (
                        <p className="firstViewValidation">
                          Please select the correct option
                        </p>
                      )}
                      {singleSelectImageBase.error4 && (
                        <p className="firstViewValidation">
                          You have selected an option for which no image has
                          been selected
                        </p>
                      )}
                      {singleSelectImageBase.error5 && (
                        <p className="firstViewValidation">
                          Option cannot just contain space
                        </p>
                      )}
                      {singleSelectImageBase.error6 && (
                        <p className="firstViewValidation">
                          You have selected an image by skipping one of the
                          previous one
                        </p>
                      )}
                      {singleSelectImageBase.error7 && (
                        <p className="firstViewValidation">
                          Please enter a feedback
                        </p>
                      )}
                      {singleSelectImageBase.error8 && (
                        <p>Entered options must have different values</p>
                      )}
                      {multiSelectImageBase.error1 && (
                        <p className="firstViewValidation">
                          Select at least 2 options
                        </p>
                      )}
                      {multiSelectImageBase.error2 && (
                        <p className="firstViewValidation">
                          You need to select at least 3 Images
                        </p>
                      )}
                      {multiSelectImageBase.error3 && (
                        <p className="firstViewValidation">
                          Please select the correct option
                        </p>
                      )}
                      {multiSelectImageBase.error4 && (
                        <p className="firstViewValidation">
                          You have selected an option for which no image has
                          been selected
                        </p>
                      )}
                      {multiSelectImageBase.error5 && (
                        <p className="firstViewValidation">
                          Option cannot just contain space
                        </p>
                      )}
                      {multiSelectImageBase.error6 && (
                        <p className="firstViewValidation">
                          You have selected an image by skipping one of the
                          previous one
                        </p>
                      )}
                      {multiSelectImageBase.error7 && (
                        <p className="firstViewValidation">
                          Please enter a feedback
                        </p>
                      )}
                      {multiSelectImageBase.error8 && (
                        <p className="firstViewValidation">
                          Entered options must have different values
                        </p>
                      )}
                    </div>
                    {/* {quizType === "6" || quizType === "2" ? ( */}
                    {quizType === "2" ||
                    quizType === "7" ||
                    quizType === "6" ? (
                      <>
                        <div className={classes.displayFlex}>
                          <div className={classes.flexBasis}>
                            <h4>Options:</h4>
                          </div>

                          <FormControl
                            className={classes.root1}
                            component="fieldset"
                            fullWidth
                          >
                            <RadioGroup
                              onChange={(e) => handleOptionChange(e, qidx)}
                              value={question.correctoption}
                            >
                              {question.iopts.map((opts, oidx) => (
                                <>
                                  <FormControlLabel
                                    value={oidx.toString()}
                                    control={<BlueRadio />}
                                    label={
                                      <input
                                        style={{ width: "370px" }}
                                        value={opts.content}
                                        className="classInput"
                                        onChange={(e) =>
                                          handleInputChange(e, oidx, qidx)
                                        }
                                        type="text"
                                        autoComplete="off"
                                      />
                                    }
                                  />
                                </>
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </>
                    ) : null}
                    {quizType === "4" ? (
                      <>
                        <div className={classes.displayFlex}>
                          <div className={classes.flexBasis}>
                            <h4>Options:</h4>
                          </div>

                          <FormControl
                            className={classes.root1}
                            component="fieldset"
                            fullWidth
                          >
                            <RadioGroup
                              onChange={(e) => handleOptionChange(e, qidx)}
                              value={question.correctoption}
                            >
                              {question.iopts.map((opts, oidx) => (
                                <>
                                  <FormControlLabel
                                    value={oidx.toString()}
                                    control={<BlueRadio />}
                                    label={
                                      <React.Fragment>
                                        <input
                                          accept="image/*"
                                          className={classes.input}
                                          id={oidx.toString()}
                                          multiple
                                          type="file"
                                          onChange={(e) => {
                                            onSImageFileChange(e, qidx, oidx);
                                          }}
                                        />

                                        <label htmlFor={oidx.toString()}>
                                          <Button
                                            className={classes.uploadBtn}
                                            component="span"
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            Upload
                                          </Button>
                                        </label>
                                        {
                                          <h4
                                            style={{
                                              display: "inline-block",
                                              marginLeft: "2rem",
                                            }}
                                          >
                                            {opts.img}
                                          </h4>
                                        }
                                      </React.Fragment>
                                    }
                                  />
                                </>
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </>
                    ) : null}
                    {quizType === "3" ? (
                      <>
                        <div
                          className={classes.displayFlex}
                          style={{ justifyContent: "start" }}
                        >
                          <div className={classes.flexBasis}>
                            <h4>Options:</h4>
                          </div>

                          <FormControl
                            component="fieldset"
                            className={classes.root1}
                          >
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={state.opt1}
                                    onChange={(e) =>
                                      handleMultiSelectChange(e, qidx)
                                    }
                                    name="opt1"
                                  />
                                }
                                label={
                                  <input
                                    className="classInput"
                                    type="text"
                                    onChange={(e) => {
                                      let opt1 = e.target.value;

                                      setQuizQuestion((currentInput) =>
                                        produce(currentInput, (v) => {
                                          v[qidx].iopts[0].content = opt1;
                                        })
                                      );
                                    }}
                                    autoComplete="off"
                                  />
                                }
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={state.opt2}
                                    onChange={(e) =>
                                      handleMultiSelectChange(e, qidx)
                                    }
                                    name="opt2"
                                  />
                                }
                                label={
                                  <input
                                    className="classInput"
                                    type="text"
                                    onChange={(e) => {
                                      let opt2 = e.target.value;
                                      setQuizQuestion((currentInput) =>
                                        produce(currentInput, (v) => {
                                          v[qidx].iopts[1].content = opt2;
                                        })
                                      );
                                    }}
                                    autoComplete="off"
                                  />
                                }
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={state.opt3}
                                    onChange={(e) =>
                                      handleMultiSelectChange(e, qidx)
                                    }
                                    name="opt3"
                                  />
                                }
                                label={
                                  <input
                                    className="classInput"
                                    type="text"
                                    onChange={(e) => {
                                      let opt3 = e.target.value;
                                      setQuizQuestion((currentInput) =>
                                        produce(currentInput, (v) => {
                                          v[qidx].iopts[2].content = opt3;
                                        })
                                      );
                                    }}
                                    autoComplete="off"
                                  />
                                }
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={state.opt4}
                                    onChange={(e) =>
                                      handleMultiSelectChange(e, qidx)
                                    }
                                    name="opt4"
                                  />
                                }
                                label={
                                  <input
                                    className="classInput"
                                    type="text"
                                    onChange={(e) => {
                                      let opt4 = e.target.value;
                                      setQuizQuestion((currentInput) =>
                                        produce(currentInput, (v) => {
                                          v[qidx].iopts[3].content = opt4;
                                        })
                                      );
                                    }}
                                    autoComplete="off"
                                  />
                                }
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={state.opt5}
                                    onChange={(e) =>
                                      handleMultiSelectChange(e, qidx)
                                    }
                                    name="opt5"
                                  />
                                }
                                label={
                                  <input
                                    className="classInput"
                                    type="text"
                                    onChange={(e) => {
                                      let opt5 = e.target.value;
                                      setQuizQuestion((currentInput) =>
                                        produce(currentInput, (v) => {
                                          v[qidx].iopts[4].content = opt5;
                                        })
                                      );
                                    }}
                                    autoComplete="off"
                                  />
                                }
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={state.opt6}
                                    onChange={(e) =>
                                      handleMultiSelectChange(e, qidx)
                                    }
                                    name="opt6"
                                  />
                                }
                                label={
                                  <input
                                    className="classInput"
                                    type="text"
                                    onChange={(e) => {
                                      let opt6 = e.target.value;
                                      setQuizQuestion((currentInput) =>
                                        produce(currentInput, (v) => {
                                          v[qidx].iopts[5].content = opt6;
                                        })
                                      );
                                    }}
                                    autoComplete="off"
                                  />
                                }
                              />
                            </FormGroup>
                          </FormControl>
                        </div>
                      </>
                    ) : null}
                    {quizType === "5" ? (
                      <>
                        <div className={classes.displayFlex}>
                          <div className={classes.flexBasis}>
                            <h4>Options:</h4>
                          </div>

                          <FormControl
                            className={classes.root1}
                            component="fieldset"
                            fullWidth
                          >
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={state.opt1}
                                    style={{ color: "#01498e" }}
                                    onChange={(e) =>
                                      handleMultiSelectChange(e, qidx)
                                    }
                                    name="opt1"
                                  />
                                }
                                label={
                                  <div className={classes.root1}>
                                    <input
                                      accept="image/*"
                                      className={classes.input}
                                      id="contained-button-file-1"
                                      multiple
                                      type="file"
                                      onChange={(event) => {
                                        let opt1 = event.target.files[0];
                                        var uniqid = new Date().getTime();
                                        var imtype =
                                          event.target.files[0].name.slice(
                                            (Math.max(
                                              0,
                                              event.target.files[0].name.lastIndexOf(
                                                "."
                                              )
                                            ) || Infinity) + 1
                                          );
                                        let imageName = `img${uniqid}`;
                                        setQuizQuestion((currentFile) =>
                                          produce(currentFile, (draft) => {
                                            draft[qidx].iopts[0].url =
                                              imageName + ".png";
                                            draft[qidx].iopts[0].img =
                                              opt1.name;
                                          })
                                        );
                                        uploadfile(opt1, imageName);
                                      }}
                                    />

                                    <label htmlFor="contained-button-file-1">
                                      <Button
                                        className={classes.uploadBtn}
                                        component="span"
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        Upload
                                      </Button>
                                    </label>
                                    {
                                      <h4
                                        style={{
                                          display: "inline-block",
                                          marginLeft: "2rem",
                                        }}
                                      >
                                        {quizQuestion[qidx].iopts[0].img}
                                      </h4>
                                    }
                                  </div>
                                }
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={state.opt2}
                                    style={{ color: "#01498e" }}
                                    onChange={(e) =>
                                      handleMultiSelectChange(e, qidx)
                                    }
                                    name="opt2"
                                  />
                                }
                                label={
                                  <div className={classes.root1}>
                                    <input
                                      accept="image/*"
                                      className={classes.input}
                                      id="contained-button-file-2"
                                      multiple
                                      type="file"
                                      onChange={(e) => {
                                        let opt2 = e.target.files[0];
                                        var uniqid = new Date().getTime();
                                        var imtype =
                                          e.target.files[0].name.slice(
                                            (Math.max(
                                              0,
                                              e.target.files[0].name.lastIndexOf(
                                                "."
                                              )
                                            ) || Infinity) + 1
                                          );
                                        let imageName = `img${uniqid}`;
                                        setQuizQuestion((currentFile) =>
                                          produce(currentFile, (draft) => {
                                            draft[qidx].iopts[1].url =
                                              imageName + ".png";
                                            draft[qidx].iopts[1].img =
                                              opt2.name;
                                          })
                                        );
                                        uploadfile(opt2, imageName);
                                      }}
                                    />

                                    <label htmlFor="contained-button-file-2">
                                      <Button
                                        className={classes.uploadBtn}
                                        component="span"
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        Upload
                                      </Button>
                                    </label>
                                    {
                                      <h4
                                        style={{
                                          display: "inline-block",
                                          marginLeft: "2rem",
                                        }}
                                      >
                                        {quizQuestion[qidx].iopts[1].img}
                                      </h4>
                                    }
                                  </div>
                                }
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={state.opt3}
                                    style={{ color: "#01498e" }}
                                    onChange={(e) =>
                                      handleMultiSelectChange(e, qidx)
                                    }
                                    name="opt3"
                                  />
                                }
                                label={
                                  <div className={classes.root1}>
                                    <input
                                      accept="image/*"
                                      className={classes.input}
                                      id="contained-button-file-3"
                                      multiple
                                      type="file"
                                      onChange={(e) => {
                                        let opt3 = e.target.files[0];
                                        var uniqid = new Date().getTime();
                                        var imtype =
                                          e.target.files[0].name.slice(
                                            (Math.max(
                                              0,
                                              e.target.files[0].name.lastIndexOf(
                                                "."
                                              )
                                            ) || Infinity) + 1
                                          );
                                        let imageName = `img${uniqid}`;
                                        setQuizQuestion((currentFile) =>
                                          produce(currentFile, (draft) => {
                                            draft[qidx].iopts[2].url =
                                              imageName + ".png";
                                            draft[qidx].iopts[2].img =
                                              opt3.name;
                                          })
                                        );
                                      }}
                                    />

                                    <label htmlFor="contained-button-file-3">
                                      <Button
                                        className={classes.uploadBtn}
                                        component="span"
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        Upload
                                      </Button>
                                    </label>
                                    {
                                      <h4
                                        style={{
                                          display: "inline-block",
                                          marginLeft: "2rem",
                                        }}
                                      >
                                        {quizQuestion[qidx].iopts[2].img}
                                      </h4>
                                    }
                                  </div>
                                }
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={state.opt4}
                                    style={{ color: "#01498e" }}
                                    onChange={(e) =>
                                      handleMultiSelectChange(e, qidx)
                                    }
                                    name="opt4"
                                  />
                                }
                                label={
                                  <div className={classes.root1}>
                                    <input
                                      accept="image/*"
                                      className={classes.input}
                                      id="contained-button-file-4"
                                      multiple
                                      type="file"
                                      onChange={(e) => {
                                        let opt4 = e.target.files[0];
                                        var uniqid = new Date().getTime();
                                        var imtype =
                                          e.target.files[0].name.slice(
                                            (Math.max(
                                              0,
                                              e.target.files[0].name.lastIndexOf(
                                                "."
                                              )
                                            ) || Infinity) + 1
                                          );
                                        let imageName = `img${uniqid}`;
                                        setQuizQuestion((currentFile) =>
                                          produce(currentFile, (draft) => {
                                            draft[qidx].iopts[3].url =
                                              imageName + ".png";
                                            draft[qidx].iopts[3].img =
                                              opt4.name;
                                          })
                                        );
                                      }}
                                    />

                                    <label htmlFor="contained-button-file-4">
                                      <Button
                                        className={classes.uploadBtn}
                                        component="span"
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        Upload
                                      </Button>
                                    </label>
                                    {
                                      <h4
                                        style={{
                                          display: "inline-block",
                                          marginLeft: "2rem",
                                        }}
                                      >
                                        {quizQuestion[qidx].iopts[3].img}
                                      </h4>
                                    }
                                  </div>
                                }
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={state.opt5}
                                    style={{ color: "#01498e" }}
                                    onChange={(e) =>
                                      handleMultiSelectChange(e, qidx)
                                    }
                                    name="opt5"
                                  />
                                }
                                label={
                                  <div className={classes.root1}>
                                    <input
                                      accept="image/*"
                                      className={classes.input}
                                      id="contained-button-file-5"
                                      multiple
                                      type="file"
                                      onChange={(e) => {
                                        let opt5 = e.target.files[0];
                                        var uniqid = new Date().getTime();
                                        var imtype =
                                          e.target.files[0].name.slice(
                                            (Math.max(
                                              0,
                                              e.target.files[0].name.lastIndexOf(
                                                "."
                                              )
                                            ) || Infinity) + 1
                                          );
                                        let imageName = `img${uniqid}`;
                                        setQuizQuestion((currentFile) =>
                                          produce(currentFile, (draft) => {
                                            draft[qidx].iopts[4].url =
                                              imageName + ".png";
                                            draft[qidx].iopts[4].img =
                                              opt5.name;
                                          })
                                        );
                                      }}
                                    />

                                    <label htmlFor="contained-button-file-5">
                                      <Button
                                        className={classes.uploadBtn}
                                        component="span"
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        Upload
                                      </Button>
                                    </label>
                                    {
                                      <h4
                                        style={{
                                          display: "inline-block",
                                          marginLeft: "2rem",
                                        }}
                                      >
                                        {quizQuestion[qidx].iopts[4].img}
                                      </h4>
                                    }
                                  </div>
                                }
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={state.opt6}
                                    style={{ color: "#01498e" }}
                                    onChange={(e) =>
                                      handleMultiSelectChange(e, qidx)
                                    }
                                    name="opt6"
                                  />
                                }
                                label={
                                  <div className={classes.root1}>
                                    <input
                                      accept="image/*"
                                      className={classes.input}
                                      id="contained-button-file-6"
                                      multiple
                                      type="file"
                                      onChange={(e) => {
                                        let opt6 = e.target.files[0];
                                        var uniqid = new Date().getTime();
                                        var imtype =
                                          e.target.files[0].name.slice(
                                            (Math.max(
                                              0,
                                              e.target.files[0].name.lastIndexOf(
                                                "."
                                              )
                                            ) || Infinity) + 1
                                          );
                                        let imageName = `img${uniqid}`;
                                        setQuizQuestion((currentFile) =>
                                          produce(currentFile, (draft) => {
                                            draft[qidx].iopts[5].url =
                                              imageName + ".png";
                                            draft[qidx].iopts[5].img =
                                              opt6.name;
                                          })
                                        );
                                      }}
                                    />

                                    <label htmlFor="contained-button-file-6">
                                      <Button
                                        className={classes.uploadBtn}
                                        component="span"
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        Upload
                                      </Button>
                                    </label>
                                    {
                                      <h4
                                        style={{
                                          display: "inline-block",
                                          marginLeft: "2rem",
                                        }}
                                      >
                                        {quizQuestion[qidx].iopts[5].img}
                                      </h4>
                                    }
                                  </div>
                                }
                              />
                            </FormGroup>
                          </FormControl>
                        </div>
                      </>
                    ) : null}
                    <div
                      className={classes.displayFlex}
                      style={{ justifyContent: "start" }}
                    >
                      <div className={classes.flexBasis}>
                        <h4>Marks:</h4>
                      </div>
                      <div className={classes.root1}>
                        {defaultValidation.error10 ? (
                          <p className="firstViewValidation">
                            Please enter the marks should greater than zero
                          </p>
                        ) : null}

                        <input
                          type="number"
                          className="classInput_Marks"
                          // maxLength="1"
                          max="5"
                          min="1"
                          onChange={(e) => {
                            handleChangeMarks(e);
                            let qScore = e.target.value;
                            // if (e.target.value > 0) {
                            setQuizQuestion((currentQScore) =>
                              produce(currentQScore, (v) => {
                                v[qidx].qscore = qScore;
                              })
                            );
                            // }
                          }}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "2rem",
                        marginTop: "2rem",
                      }}
                    >
                      <CustomButton
                        type="submit"
                        onClick={() =>
                          addMultiminiQuestion(question, qidx, 0, quizType)
                        }
                      >
                        Add Question
                      </CustomButton>
                      <CustomButton
                        type="submit"
                        onClick={() =>
                          addMultiminiQuestion(question, qidx, 1, quizType)
                        }
                      >
                        Save
                      </CustomButton>
                    </div>
                  </div>
                )}
              </>
            ))}
          </>
        ) : null}

        {editmultiform ? (
          <>
            <div className="scrollQuiz">
              {quizQuestion.map((question, qidx) => (
                <>
                  {qidx == questionObj.currentq - 1 && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        // gap: "2rem",
                      }}
                      key={question.iid}
                    >
                      <div className={classes.displayFlex}>
                        <div className={classes.root}>
                          <h4 className={classes.textCenter}>
                            Question {qidx + 1} of {quizQuestion.length}
                          </h4>
                        </div>
                      </div>
                      {defaultValidation.error1 ? (
                        <p className="firstViewValidation">
                          Please select the Question type
                        </p>
                      ) : null}
                      <select
                        className="classInput_assign_assess"
                        style={{ marginLeft: "6%", marginRight: "6%" }}
                        defaultValue={
                          nquestion == true
                            ? "0"
                            : quizQuestion[currentQuestion - 1] !== undefined &&
                              quizQuestion[currentQuestion - 1].atype
                        }
                        onChange={(e) => {
                          setQuizType(e.target.value);
                          let qesData = [...quizQuestion];
                          if (quizType !== "1") {
                            setQuizQuestion(
                              produce(qesData, (draft) => {
                                draft[qidx].iopts = initialValues;
                              })
                            );
                          }
                          if (e.target.value == "6") {
                            setMatchTheFollowing(initialMatchTheFollowing);
                          }
                        }}
                      >
                        <option value="0" selected="selected" disabled hidden>
                          Select - Question Type
                        </option>
                        <option value="1">True or False</option>
                        <option value="2">Single Select Multiple Choice</option>
                        {/* <option value="3">
                          Multiple Select Multiple Choice
                        </option> */}

                        <option value="4">
                          Single Select Image Based Multiple Choice
                        </option>

                        {/* <option value="5">
                          Multiple Select Image Based Multiple Choice
                        </option> */}

                        <option value="6">Match the Following</option>
                        <option value="7">Fill in the Blanks</option>
                      </select>

                      {quizType !== "6" ? (
                        <div className={classes.displayFlex}>
                          <div className={classes.flexBasis}>
                            <h4>Question </h4>
                          </div>

                          <div style={{ width: "100%" }}>
                            {defaultValidation.error2 ? (
                              <p className="firstViewValidation">
                                Question cannot be empty
                              </p>
                            ) : null}
                            <textarea
                              className="classTextarea"
                              style={{ marginBottom: "20px" }}
                              //   value={question.istem}
                              autoComplete="off"
                              defaultValue={
                                nquestion == true
                                  ? ""
                                  : quizQuestion[currentQuestion - 1] !==
                                      undefined &&
                                    quizQuestion[currentQuestion - 1].istem
                              }
                              onChange={(e) => {
                                const istem = e.target.value;
                                setIstem(istem);

                                setQuizQuestion((currentistem) =>
                                  produce(currentistem, (v) => {
                                    console.log("come to set" + qidx);
                                    console.log(v[qidx]);
                                    v[qidx].istem = istem;
                                    console.log(v[qidx].istem);

                                    console.log(istem);
                                  })
                                );
                                console.log(JSON.stringify(quizQuestion));
                              }}
                            ></textarea>
                          </div>
                        </div>
                      ) : (
                        <div className={classes.displayFlex}>
                          <div className={classes.flexBasis}>
                            <h4>Question:</h4>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                            className={classes.root1}
                          >
                            {defaultValidation.error5 ? (
                              <p
                                style={{ marginLeft: "-55%" }}
                                className="firstViewValidation"
                              >
                                Please enter atleast 3 validate questions
                              </p>
                            ) : null}
                            {matchTheFollowing.map((match, ids) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "1rem",
                                }}
                              >
                                <input
                                  className="classInput_MatchTheFollowing"
                                  value={match.value1}
                                  onChange={(e) => {
                                    let value1 = e.target.value;
                                    setMatchTheFollowing((currMatchVal) =>
                                      produce(currMatchVal, (v) => {
                                        v[ids].value1 = value1;
                                      })
                                    );
                                  }}
                                />
                                <input
                                  className="classInput_MatchTheFollowing"
                                  value={match.value2}
                                  onChange={(e) => {
                                    let value2 = e.target.value;
                                    setMatchTheFollowing((currMatchVal) =>
                                      produce(currMatchVal, (v) => {
                                        v[ids].value2 = value2;
                                      })
                                    );
                                  }}
                                />
                                <IconButton
                                  aria-label="add"
                                  onClick={() => addInstruction(ids)}
                                >
                                  <AddCircleIcon />
                                </IconButton>

                                <IconButton
                                  aria-label="delete"
                                  onClick={() => deleteInstruction(match, ids)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      <div
                        className={classes.displayFlex}
                        style={{ justifyContent: "start" }}
                      >
                        <div className={classes.flexBasis}>
                          <h4>Image </h4>{" "}
                          <sub className={classes.small}>(Optional)</sub>
                        </div>
                        <div className={classes.root1}>
                          <input
                            accept="image/*"
                            className={classes.input}
                            id="contained-button-file"
                            multiple
                            type="file"
                            onChange={(e) => {
                              onFileChange(e, qidx);
                            }}
                          />

                          <label htmlFor="contained-button-file">
                            <Button
                              className={classes.uploadBtn}
                              component="span"
                              style={{
                                textTransform: "capitalize",
                              }}
                            >
                              Upload
                            </Button>
                          </label>
                          {
                            <h4
                              style={{
                                display: "inline-block",
                                marginLeft: "2rem",
                              }}
                            >
                              {/* {selectedFile !== null &&
                            selectedFile.file !== undefined
                              ? selectedFile.file.name
                              : ""} */}

                              {question.imageurl !== undefined
                                ? question.myfilename
                                : ""}
                            </h4>
                          }
                        </div>
                      </div>
                      {quizType === "1" || quizType === 1 ? (
                        <div style={{ marginLeft: "8rem", textAlign: "start" }}>
                          {tFOptions.map((opts, oidx) => (
                            <React.Fragment key={oidx + "sfsd"}>
                              <BlueRadio
                                checked={value == `${opts.content}`}
                                onChange={(e) =>
                                  handleChangeMulti(e, oidx, qidx, quizType)
                                }
                                value={`${opts.content}`}
                                name="radio-button-demo"
                                inputProps={{ "aria-label": "False" }}
                              />
                              <span style={{ fontSize: "1.3rem" }}>
                                {opts.content}
                              </span>
                            </React.Fragment>
                          ))}
                          {defaultValidation.error4 ? (
                            <p className="firstViewValidation">
                              Please select True or False
                            </p>
                          ) : null}
                        </div>
                      ) : null}
                      <div style={{ paddingLeft: "15%" }}>
                        {questionValidation.error2 && (
                          <p className="firstViewValidation">
                            You need to enter at least 2 options
                          </p>
                        )}
                        {questionValidation.error3 && (
                          <p className="firstViewValidation">
                            Please select the correct option
                          </p>
                        )}
                        {questionValidation.error4 && (
                          <p className="firstViewValidation">
                            You have selected an option for which no value has
                            been entered
                          </p>
                        )}
                        {questionValidation.error5 && (
                          <p className="firstViewValidation">
                            Option cannot just contain space
                          </p>
                        )}
                        {questionValidation.error6 && (
                          <p className="firstViewValidation">
                            You have entered an option by skipping one of the
                            previous one
                          </p>
                        )}
                        {questionValidation.error7 && (
                          <p className="firstViewValidation">
                            Please enter a feedback
                          </p>
                        )}
                        {questionValidation.error8 && (
                          <p className="firstViewValidation">
                            Entered options must have different values
                          </p>
                        )}
                        {multiSelectValidation.error1 && (
                          <p className="firstViewValidation">
                            Select at least 2 options
                          </p>
                        )}
                        {multiSelectValidation.error2 && (
                          <p className="firstViewValidation">
                            You need to enter at least 3 options
                          </p>
                        )}
                        {multiSelectValidation.error3 && (
                          <p className="firstViewValidation">
                            Please select the correct option
                          </p>
                        )}
                        {multiSelectValidation.error4 && (
                          <p className="firstViewValidation">
                            You have selected an option for which no value has
                            been entered
                          </p>
                        )}
                        {multiSelectValidation.error5 && (
                          <p className="firstViewValidation">
                            Option cannot just contain space
                          </p>
                        )}
                        {multiSelectValidation.error6 && (
                          <p className="firstViewValidation">
                            You have entered an option by skipping one of the
                            previous one
                          </p>
                        )}
                        {multiSelectValidation.error7 && (
                          <p className="firstViewValidation">
                            Please enter a feedback
                          </p>
                        )}
                        {multiSelectValidation.error8 && (
                          <p className="firstViewValidation">
                            Entered options must have different values
                          </p>
                        )}
                        {singleSelectImageBase.error2 && (
                          <p className="firstViewValidation">
                            You need to select at least 2 Images
                          </p>
                        )}
                        {singleSelectImageBase.error3 && (
                          <p className="firstViewValidation">
                            Please select the correct option
                          </p>
                        )}
                        {singleSelectImageBase.error4 && (
                          <p className="firstViewValidation">
                            You have selected an option for which no image has
                            been selected
                          </p>
                        )}
                        {singleSelectImageBase.error5 && (
                          <p className="firstViewValidation">
                            Option cannot just contain space
                          </p>
                        )}
                        {singleSelectImageBase.error6 && (
                          <p className="firstViewValidation">
                            You have selected an image by skipping one of the
                            previous one
                          </p>
                        )}
                        {singleSelectImageBase.error7 && (
                          <p className="firstViewValidation">
                            Please enter a feedback
                          </p>
                        )}
                        {singleSelectImageBase.error8 && (
                          <p>Entered options must have different values</p>
                        )}
                        {multiSelectImageBase.error1 && (
                          <p className="firstViewValidation">
                            Select at least 2 options
                          </p>
                        )}
                        {multiSelectImageBase.error2 && (
                          <p className="firstViewValidation">
                            You need to select at least 3 Images
                          </p>
                        )}
                        {multiSelectImageBase.error3 && (
                          <p className="firstViewValidation">
                            Please select the correct option
                          </p>
                        )}
                        {multiSelectImageBase.error4 && (
                          <p className="firstViewValidation">
                            You have selected an option for which no image has
                            been selected
                          </p>
                        )}
                        {multiSelectImageBase.error5 && (
                          <p className="firstViewValidation">
                            Option cannot just contain space
                          </p>
                        )}
                        {multiSelectImageBase.error6 && (
                          <p className="firstViewValidation">
                            You have selected an image by skipping one of the
                            previous one
                          </p>
                        )}
                        {multiSelectImageBase.error7 && (
                          <p className="firstViewValidation">
                            Please enter a feedback
                          </p>
                        )}
                        {multiSelectImageBase.error8 && (
                          <p className="firstViewValidation">
                            Entered options must have different values
                          </p>
                        )}
                      </div>
                      {quizType === "6" ||
                      quizType === "2" ||
                      quizType === "7" ? (
                        <>
                          <div className={classes.displayFlex}>
                            <div className={classes.flexBasis}>
                              <h4>Options:</h4>
                            </div>

                            <FormControl
                              className={classes.root1}
                              component="fieldset"
                              fullWidth
                            >
                              <RadioGroup
                                onChange={(e) => handleOptionChange(e, qidx)}
                                value={question.correctoption}
                              >
                                {question.iopts.map((opts, oidx) => (
                                  <>
                                    <FormControlLabel
                                      value={oidx.toString()}
                                      control={<BlueRadio />}
                                      label={
                                        <input
                                          value={opts.content}
                                          className="classInput"
                                          onChange={(e) =>
                                            handleInputChange(e, oidx, qidx)
                                          }
                                          type="text"
                                          autoComplete="off"
                                        />
                                      }
                                    />
                                  </>
                                ))}
                              </RadioGroup>
                            </FormControl>
                          </div>
                        </>
                      ) : null}
                      {quizType === "4" ? (
                        <>
                          <div
                            className={classes.displayFlex}
                            style={{ justifyContent: "start" }}
                          >
                            <div className={classes.flexBasis}>
                              <h4>Options:</h4>
                            </div>

                            <FormControl
                              className={classes.root1}
                              component="fieldset"
                              fullWidth
                            >
                              <RadioGroup
                                onChange={(e) => handleOptionChange(e, qidx)}
                                value={question.correctoption}
                              >
                                {question.iopts.map((opts, oidx) => (
                                  <>
                                    <FormControlLabel
                                      value={oidx.toString()}
                                      control={<BlueRadio />}
                                      label={
                                        <React.Fragment>
                                          <input
                                            accept="image/*"
                                            className={classes.input}
                                            id={oidx.toString()}
                                            multiple
                                            type="file"
                                            onChange={(e) => {
                                              onSImageFileChange(e, qidx, oidx);
                                              console.log(
                                                "iopts" + JSON.stringify(opts)
                                              );
                                            }}
                                          />

                                          <label htmlFor={oidx.toString()}>
                                            <Button
                                              className={classes.uploadBtn}
                                              component="span"
                                              style={{
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              Upload
                                            </Button>
                                          </label>
                                          {
                                            <h4
                                              style={{
                                                display: "inline-block",
                                                marginLeft: "2rem",
                                              }}
                                            >
                                              {opts.img2 == undefined
                                                ? opts.img
                                                : opts.img}
                                            </h4>
                                          }
                                        </React.Fragment>
                                      }
                                    />
                                  </>
                                ))}
                              </RadioGroup>
                            </FormControl>
                          </div>
                        </>
                      ) : null}
                      {quizType === "3" ? (
                        <>
                          <div
                            className={classes.displayFlex}
                            style={{ justifyContent: "start" }}
                          >
                            <div className={classes.flexBasis}>
                              <h4>Options:</h4>
                            </div>

                            <FormControl
                              component="fieldset"
                              className={classes.root1}
                            >
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={state.opt1}
                                      onChange={(e) =>
                                        handleMultiSelectChange(e, qidx)
                                      }
                                      name="opt1"
                                    />
                                  }
                                  label={
                                    <input
                                      className="classInput"
                                      type="text"
                                      value={
                                        quizQuestion[qidx].iopts[0].content
                                      }
                                      onChange={(e) => {
                                        let opt1 = e.target.value;

                                        setQuizQuestion((currentInput) =>
                                          produce(currentInput, (v) => {
                                            v[qidx].iopts[0].content = opt1;
                                          })
                                        );
                                      }}
                                      autoComplete="off"
                                    />
                                  }
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={state.opt2}
                                      onChange={(e) =>
                                        handleMultiSelectChange(e, qidx)
                                      }
                                      name="opt2"
                                    />
                                  }
                                  label={
                                    <input
                                      className="classInput"
                                      type="text"
                                      value={
                                        quizQuestion[qidx].iopts[1].content
                                      }
                                      onChange={(e) => {
                                        let opt2 = e.target.value;
                                        setQuizQuestion((currentInput) =>
                                          produce(currentInput, (v) => {
                                            v[qidx].iopts[1].content = opt2;
                                          })
                                        );
                                      }}
                                      autoComplete="off"
                                    />
                                  }
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={state.opt3}
                                      onChange={(e) =>
                                        handleMultiSelectChange(e, qidx)
                                      }
                                      name="opt3"
                                    />
                                  }
                                  label={
                                    <input
                                      className="classInput"
                                      type="text"
                                      value={
                                        quizQuestion[qidx].iopts[2].content
                                      }
                                      onChange={(e) => {
                                        let opt3 = e.target.value;
                                        setQuizQuestion((currentInput) =>
                                          produce(currentInput, (v) => {
                                            v[qidx].iopts[2].content = opt3;
                                          })
                                        );
                                      }}
                                      autoComplete="off"
                                    />
                                  }
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={state.opt4}
                                      onChange={(e) =>
                                        handleMultiSelectChange(e, qidx)
                                      }
                                      name="opt4"
                                    />
                                  }
                                  label={
                                    <input
                                      className="classInput"
                                      type="text"
                                      value={
                                        quizQuestion[qidx].iopts[3].content
                                      }
                                      onChange={(e) => {
                                        let opt4 = e.target.value;
                                        setQuizQuestion((currentInput) =>
                                          produce(currentInput, (v) => {
                                            v[qidx].iopts[3].content = opt4;
                                          })
                                        );
                                      }}
                                      autoComplete="off"
                                    />
                                  }
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={state.opt5}
                                      onChange={(e) =>
                                        handleMultiSelectChange(e, qidx)
                                      }
                                      name="opt5"
                                    />
                                  }
                                  label={
                                    <input
                                      className="classInput"
                                      type="text"
                                      value={
                                        quizQuestion[qidx].iopts[4].content
                                      }
                                      onChange={(e) => {
                                        let opt5 = e.target.value;
                                        setQuizQuestion((currentInput) =>
                                          produce(currentInput, (v) => {
                                            v[qidx].iopts[4].content = opt5;
                                          })
                                        );
                                      }}
                                      autoComplete="off"
                                    />
                                  }
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={state.opt6}
                                      onChange={(e) =>
                                        handleMultiSelectChange(e, qidx)
                                      }
                                      name="opt6"
                                    />
                                  }
                                  label={
                                    <input
                                      className="classInput"
                                      type="text"
                                      value={
                                        quizQuestion[qidx].iopts[5].content
                                      }
                                      onChange={(e) => {
                                        let opt6 = e.target.value;
                                        setQuizQuestion((currentInput) =>
                                          produce(currentInput, (v) => {
                                            v[qidx].iopts[5].content = opt6;
                                          })
                                        );
                                      }}
                                      autoComplete="off"
                                    />
                                  }
                                />
                              </FormGroup>
                            </FormControl>
                          </div>
                        </>
                      ) : null}
                      {quizType === "5" ? (
                        <>
                          <div
                            className={classes.displayFlex}
                            style={{ justifyContent: "start" }}
                          >
                            <div className={classes.flexBasis}>
                              <h4>Options:</h4>
                            </div>

                            <FormControl
                              className={classes.root1}
                              component="fieldset"
                              fullWidth
                            >
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={state.opt1}
                                      style={{ color: "#01498e" }}
                                      onChange={(e) =>
                                        handleMultiSelectChange(e, qidx)
                                      }
                                      name="opt1"
                                    />
                                  }
                                  label={
                                    <div className={classes.root1}>
                                      <input
                                        accept="image/*"
                                        className={classes.input}
                                        id="contained-button-file-1"
                                        multiple
                                        type="file"
                                        onChange={(event) => {
                                          let opt1 = event.target.files[0];
                                          var uniqid = new Date().getTime();
                                          var imtype =
                                            event.target.files[0].name.slice(
                                              (Math.max(
                                                0,
                                                event.target.files[0].name.lastIndexOf(
                                                  "."
                                                )
                                              ) || Infinity) + 1
                                            );
                                          let imageName = `img${uniqid}`;
                                          setQuizQuestion((currentFile) =>
                                            produce(currentFile, (draft) => {
                                              draft[qidx].iopts[0].url =
                                                imageName + ".png";
                                              draft[qidx].iopts[0].img =
                                                opt1.name;
                                            })
                                          );
                                          uploadfile(opt1, imageName);
                                        }}
                                      />

                                      <label htmlFor="contained-button-file-1">
                                        <Button
                                          className={classes.uploadBtn}
                                          component="span"
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          Upload
                                        </Button>
                                      </label>
                                      {
                                        <h4
                                          style={{
                                            display: "inline-block",
                                            marginLeft: "2rem",
                                          }}
                                        >
                                          {quizQuestion[qidx].iopts[0].img !==
                                            undefined &&
                                            quizQuestion[qidx].iopts[0].img}
                                          {quizQuestion[qidx].iopts[0].img ===
                                            undefined &&
                                            quizQuestion[qidx].iopts[0].url}
                                        </h4>
                                      }
                                    </div>
                                  }
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={state.opt2}
                                      style={{ color: "#01498e" }}
                                      onChange={(e) =>
                                        handleMultiSelectChange(e, qidx)
                                      }
                                      name="opt2"
                                    />
                                  }
                                  label={
                                    <div className={classes.root1}>
                                      <input
                                        accept="image/*"
                                        className={classes.input}
                                        id="contained-button-file-2"
                                        multiple
                                        type="file"
                                        onChange={(e) => {
                                          let opt2 = e.target.files[0];
                                          var uniqid = new Date().getTime();
                                          var imtype =
                                            e.target.files[0].name.slice(
                                              (Math.max(
                                                0,
                                                e.target.files[0].name.lastIndexOf(
                                                  "."
                                                )
                                              ) || Infinity) + 1
                                            );
                                          let imageName = `img${uniqid}`;
                                          setQuizQuestion((currentFile) =>
                                            produce(currentFile, (draft) => {
                                              draft[qidx].iopts[1].url =
                                                imageName + ".png";
                                              draft[qidx].iopts[1].img =
                                                opt2.name;
                                            })
                                          );
                                          uploadfile(opt2, imageName);
                                        }}
                                      />

                                      <label htmlFor="contained-button-file-2">
                                        <Button
                                          className={classes.uploadBtn}
                                          component="span"
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          Upload
                                        </Button>
                                      </label>
                                      {
                                        <h4
                                          style={{
                                            display: "inline-block",
                                            marginLeft: "2rem",
                                          }}
                                        >
                                          {quizQuestion[qidx].iopts[1].img !==
                                            undefined &&
                                            quizQuestion[qidx].iopts[1].img}
                                          {quizQuestion[qidx].iopts[1].img ===
                                            undefined &&
                                            quizQuestion[qidx].iopts[1].url}
                                        </h4>
                                      }
                                    </div>
                                  }
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={state.opt3}
                                      style={{ color: "#01498e" }}
                                      onChange={(e) =>
                                        handleMultiSelectChange(e, qidx)
                                      }
                                      name="opt3"
                                    />
                                  }
                                  label={
                                    <div className={classes.root1}>
                                      <input
                                        accept="image/*"
                                        className={classes.input}
                                        id="contained-button-file-3"
                                        multiple
                                        type="file"
                                        onChange={(e) => {
                                          let opt3 = e.target.files[0];
                                          var uniqid = new Date().getTime();
                                          var imtype =
                                            e.target.files[0].name.slice(
                                              (Math.max(
                                                0,
                                                e.target.files[0].name.lastIndexOf(
                                                  "."
                                                )
                                              ) || Infinity) + 1
                                            );
                                          let imageName = `img${uniqid}`;
                                          setQuizQuestion((currentFile) =>
                                            produce(currentFile, (draft) => {
                                              draft[qidx].iopts[2].url =
                                                imageName + ".png";
                                              draft[qidx].iopts[2].img =
                                                opt3.name;
                                            })
                                          );
                                        }}
                                      />

                                      <label htmlFor="contained-button-file-3">
                                        <Button
                                          className={classes.uploadBtn}
                                          component="span"
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          Upload
                                        </Button>
                                      </label>
                                      {
                                        <h4
                                          style={{
                                            display: "inline-block",
                                            marginLeft: "2rem",
                                          }}
                                        >
                                          {quizQuestion[qidx].iopts[2].img !==
                                            undefined &&
                                            quizQuestion[qidx].iopts[2].img}
                                          {quizQuestion[qidx].iopts[2].img ===
                                            undefined &&
                                            quizQuestion[qidx].iopts[2].url}
                                        </h4>
                                      }
                                    </div>
                                  }
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={state.opt4}
                                      style={{ color: "#01498e" }}
                                      onChange={(e) =>
                                        handleMultiSelectChange(e, qidx)
                                      }
                                      name="opt4"
                                    />
                                  }
                                  label={
                                    <div className={classes.root1}>
                                      <input
                                        accept="image/*"
                                        className={classes.input}
                                        id="contained-button-file-4"
                                        multiple
                                        type="file"
                                        onChange={(e) => {
                                          let opt4 = e.target.files[0];
                                          var uniqid = new Date().getTime();
                                          var imtype =
                                            e.target.files[0].name.slice(
                                              (Math.max(
                                                0,
                                                e.target.files[0].name.lastIndexOf(
                                                  "."
                                                )
                                              ) || Infinity) + 1
                                            );
                                          let imageName = `img${uniqid}`;
                                          setQuizQuestion((currentFile) =>
                                            produce(currentFile, (draft) => {
                                              draft[qidx].iopts[3].url =
                                                imageName + ".png";
                                              draft[qidx].iopts[3].img =
                                                opt4.name;
                                            })
                                          );
                                        }}
                                      />

                                      <label htmlFor="contained-button-file-4">
                                        <Button
                                          className={classes.uploadBtn}
                                          component="span"
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          Upload
                                        </Button>
                                      </label>
                                      {
                                        <h4
                                          style={{
                                            display: "inline-block",
                                            marginLeft: "2rem",
                                          }}
                                        >
                                          {quizQuestion[qidx].iopts[3].img !==
                                            undefined &&
                                            quizQuestion[qidx].iopts[3].img}
                                          {quizQuestion[qidx].iopts[3].img ===
                                            undefined &&
                                            quizQuestion[qidx].iopts[3].url}
                                        </h4>
                                      }
                                    </div>
                                  }
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={state.opt5}
                                      style={{ color: "#01498e" }}
                                      onChange={(e) =>
                                        handleMultiSelectChange(e, qidx)
                                      }
                                      name="opt5"
                                    />
                                  }
                                  label={
                                    <div className={classes.root1}>
                                      <input
                                        accept="image/*"
                                        className={classes.input}
                                        id="contained-button-file-5"
                                        multiple
                                        type="file"
                                        onChange={(e) => {
                                          let opt5 = e.target.files[0];
                                          var uniqid = new Date().getTime();
                                          var imtype =
                                            e.target.files[0].name.slice(
                                              (Math.max(
                                                0,
                                                e.target.files[0].name.lastIndexOf(
                                                  "."
                                                )
                                              ) || Infinity) + 1
                                            );
                                          let imageName = `img${uniqid}`;
                                          setQuizQuestion((currentFile) =>
                                            produce(currentFile, (draft) => {
                                              draft[qidx].iopts[4].url =
                                                imageName + ".png";
                                              draft[qidx].iopts[4].img =
                                                opt5.name;
                                            })
                                          );
                                        }}
                                      />

                                      <label htmlFor="contained-button-file-5">
                                        <Button
                                          className={classes.uploadBtn}
                                          component="span"
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          Upload
                                        </Button>
                                      </label>
                                      {
                                        <h4
                                          style={{
                                            display: "inline-block",
                                            marginLeft: "2rem",
                                          }}
                                        >
                                          {quizQuestion[qidx].iopts[4] !==
                                            undefined && (
                                            <>
                                              {quizQuestion[qidx].iopts[4]
                                                .img !== undefined &&
                                                quizQuestion[qidx].iopts[4].img}
                                              {quizQuestion[qidx].iopts[4]
                                                .img === undefined &&
                                                quizQuestion[qidx].iopts[4]
                                                  .url !== undefined &&
                                                quizQuestion[qidx].iopts[4].url}
                                            </>
                                          )}
                                        </h4>
                                      }
                                    </div>
                                  }
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={state.opt6}
                                      style={{ color: "#01498e" }}
                                      onChange={(e) =>
                                        handleMultiSelectChange(e, qidx)
                                      }
                                      name="opt6"
                                    />
                                  }
                                  label={
                                    <div className={classes.root1}>
                                      <input
                                        accept="image/*"
                                        className={classes.input}
                                        id="contained-button-file-6"
                                        multiple
                                        type="file"
                                        onChange={(e) => {
                                          let opt6 = e.target.files[0];
                                          var uniqid = new Date().getTime();
                                          var imtype =
                                            e.target.files[0].name.slice(
                                              (Math.max(
                                                0,
                                                e.target.files[0].name.lastIndexOf(
                                                  "."
                                                )
                                              ) || Infinity) + 1
                                            );
                                          let imageName = `img${uniqid}`;
                                          setQuizQuestion((currentFile) =>
                                            produce(currentFile, (draft) => {
                                              draft[qidx].iopts[5].url =
                                                imageName + ".png";
                                              draft[qidx].iopts[5].img =
                                                opt6.name;
                                            })
                                          );
                                        }}
                                      />

                                      <label htmlFor="contained-button-file-6">
                                        <Button
                                          className={classes.uploadBtn}
                                          component="span"
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          Upload
                                        </Button>
                                      </label>
                                      {
                                        <h4
                                          style={{
                                            display: "inline-block",
                                            marginLeft: "2rem",
                                          }}
                                        >
                                          {quizQuestion[qidx].iopts[5] !==
                                            undefined && (
                                            <>
                                              {quizQuestion[qidx].iopts[5]
                                                .img !== undefined &&
                                                quizQuestion[qidx].iopts[5].img}
                                              {quizQuestion[qidx].iopts[5]
                                                .img === undefined &&
                                                quizQuestion[qidx].iopts[5].url}
                                            </>
                                          )}
                                        </h4>
                                      }
                                    </div>
                                  }
                                />
                              </FormGroup>
                            </FormControl>
                          </div>
                        </>
                      ) : null}
                      {defaultValidation.error10 ? (
                        <p className="firstViewValidation">
                          Please enter the marks should greater than zero
                        </p>
                      ) : null}
                      <div
                        className={classes.displayFlex}
                        style={{ justifyContent: "start" }}
                      >
                        <div className={classes.flexBasis}>
                          <h4>Marks:</h4>
                        </div>

                        <div className={classes.root1}>
                          {defaultValidation.error3 ? (
                            <p className="firstViewValidation">
                              Please enter the marks
                            </p>
                          ) : null}

                          <input
                            type="number"
                            className="classInput_Marks"
                            maxLength="1"
                            defaultValue={
                              nquestion == true
                                ? ""
                                : quizQuestion[currentQuestion - 1] !==
                                    undefined &&
                                  quizQuestion[currentQuestion - 1].qscore
                            }
                            max="5"
                            min="1"
                            onChange={(e) => {
                              let qScore = e.target.value;
                              // handleChangeMarks(e);

                              setQuizQuestion((currentQScore) =>
                                produce(currentQScore, (v) => {
                                  v[qidx].qscore = qScore;
                                })
                              );
                            }}
                          />
                        </div>
                      </div>

                      <DialogActions
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          marginTop: "2rem",
                        }}
                      >
                        {currentQuestion - 1 === 0 && !addQestBtn ? null : (
                          <>
                            {addQestBtn == "false" ? (
                              <CustomButton
                                autoFocus
                                onClick={() =>
                                  nextQuestion(
                                    quizQuestion[currentQuestion - 1],
                                    qidx,
                                    0,
                                    quizType,
                                    "Prev"
                                  )
                                }
                              >
                                Prev
                              </CustomButton>
                            ) : null}
                          </>
                        )}
                        {currentQuestion === quizQuestion.length ? null : (
                          <>
                            {addQestBtn == "false" ? (
                              <CustomButton
                                autoFocus
                                onClick={() => {
                                  nextQuestion(
                                    quizQuestion[currentQuestion - 1],
                                    qidx,
                                    0,
                                    quizType,
                                    "Next"
                                  );
                                }}
                              >
                                Next
                              </CustomButton>
                            ) : null}
                          </>
                        )}
                        <CustomButton
                          autoFocus
                          onClick={() =>
                            addNewQuestion(
                              quizQuestion[currentQuestion - 1],
                              qidx,
                              0,
                              quizType,
                              "Add"
                            )
                          }
                        >
                          Add Question
                        </CustomButton>
                        <CustomButton
                          autoFocus
                          onClick={
                            () =>
                              addNewQuestion(
                                question,
                                qidx,
                                1,
                                quizType,
                                "Save"
                              )
                            // setAddQestBtn(false)
                          }
                        >
                          Save
                        </CustomButton>
                      </DialogActions>
                    </div>
                  )}
                </>
              ))}
            </div>
          </>
        ) : null}

        {miniAssigmentform ? (
          <>
            {quizQuestion.map((question, qidx) => (
              <>
                {qidx == questionObj.currentq - 1 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      // gap: "2rem",
                    }}
                    key={question.iid}
                  >
                    <div className={classes.displayFlex}>
                      <div className={classes.root}>
                        <h4 className={classes.textCenter}>
                          Question {qidx + 1} of {quizQuestion.length}
                        </h4>
                      </div>
                    </div>

                    <div className={classes.displayFlex}>
                      <div className={classes.flexBasis}>
                        <h4>Question</h4>
                      </div>

                      <div style={{ width: "100%" }}>
                        {defaultValidation.error2 ? (
                          <p className="firstViewValidation">
                            Question cannot be empty
                          </p>
                        ) : null}
                        <textarea
                          className="classTextarea"
                          style={{ marginBottom: "20px" }}
                          value={question.istem}
                          autoComplete="off"
                          onChange={(e) => {
                            const istem = e.target.value;
                            setIstem(istem);
                            setQuizQuestion((currentistem) =>
                              produce(currentistem, (v) => {
                                v[qidx].istem = istem;
                              })
                            );
                          }}
                        ></textarea>
                      </div>
                    </div>
                    <div
                      className={classes.displayFlex}
                      style={{ justifyContent: "start" }}
                    >
                      <div className={classes.flexBasis}>
                        <h4>Image </h4>{" "}
                        <sub className={classes.small}>(Optional)</sub>
                      </div>
                      <div className={classes.root1}>
                        <input
                          accept="image/*"
                          className={classes.input}
                          id="contained-button-file"
                          multiple
                          type="file"
                          onChange={(e) => {
                            onFileChange(e, qidx);
                          }}
                        />

                        <label htmlFor="contained-button-file">
                          <Button
                            className={classes.uploadBtn}
                            component="span"
                            style={{
                              textTransform: "capitalize",
                            }}
                          >
                            Upload
                          </Button>
                        </label>
                        {
                          <h4
                            style={{
                              display: "inline-block",
                              marginLeft: "2rem",
                            }}
                          >
                            {selectedFile !== null &&
                            selectedFile.file !== undefined
                              ? selectedFile.file.name
                              : ""}
                          </h4>
                        }
                      </div>
                    </div>

                    <div style={{ paddingLeft: "15%" }}>
                      {questionValidation.error2 && (
                        <p className="firstViewValidation">
                          You need to enter at least 2 options
                        </p>
                      )}
                      {questionValidation.error3 && (
                        <p className="firstViewValidation">
                          Please select the correct option
                        </p>
                      )}
                      {questionValidation.error4 && (
                        <p className="firstViewValidation">
                          You have selected an option for which no value has
                          been entered
                        </p>
                      )}
                      {questionValidation.error5 && (
                        <p className="firstViewValidation">
                          Option cannot just contain space
                        </p>
                      )}
                      {questionValidation.error6 && (
                        <p className="firstViewValidation">
                          You have entered an option by skipping one of the
                          previous one
                        </p>
                      )}
                      {questionValidation.error7 && (
                        <p className="firstViewValidation">
                          Please enter a feedback
                        </p>
                      )}
                      {questionValidation.error8 && (
                        <p className="firstViewValidation">
                          Entered options must have different values
                        </p>
                      )}
                      {multiSelectValidation.error1 && (
                        <p className="firstViewValidation">
                          Select at least 2 options
                        </p>
                      )}
                      {multiSelectValidation.error2 && (
                        <p className="firstViewValidation">
                          You need to enter at least 3 options
                        </p>
                      )}
                      {multiSelectValidation.error3 && (
                        <p className="firstViewValidation">
                          Please select the correct option
                        </p>
                      )}
                      {multiSelectValidation.error4 && (
                        <p className="firstViewValidation">
                          You have selected an option for which no value has
                          been entered
                        </p>
                      )}
                      {multiSelectValidation.error5 && (
                        <p className="firstViewValidation">
                          Option cannot just contain space
                        </p>
                      )}
                      {multiSelectValidation.error6 && (
                        <p className="firstViewValidation">
                          You have entered an option by skipping one of the
                          previous one
                        </p>
                      )}
                      {multiSelectValidation.error7 && (
                        <p className="firstViewValidation">
                          Please enter a feedback
                        </p>
                      )}
                      {multiSelectValidation.error8 && (
                        <p className="firstViewValidation">
                          Entered options must have different values
                        </p>
                      )}
                      {singleSelectImageBase.error2 && (
                        <p className="firstViewValidation">
                          You need to select at least 2 Images
                        </p>
                      )}
                      {singleSelectImageBase.error3 && (
                        <p className="firstViewValidation">
                          Please select the correct option
                        </p>
                      )}
                      {singleSelectImageBase.error4 && (
                        <p className="firstViewValidation">
                          You have selected an option for which no image has
                          been selected
                        </p>
                      )}
                      {singleSelectImageBase.error5 && (
                        <p className="firstViewValidation">
                          Option cannot just contain space
                        </p>
                      )}
                      {singleSelectImageBase.error6 && (
                        <p className="firstViewValidation">
                          You have selected an image by skipping one of the
                          previous one
                        </p>
                      )}
                      {singleSelectImageBase.error7 && (
                        <p className="firstViewValidation">
                          Please enter a feedback
                        </p>
                      )}
                      {singleSelectImageBase.error8 && (
                        <p>Entered options must have different values</p>
                      )}
                      {multiSelectImageBase.error1 && (
                        <p className="firstViewValidation">
                          Select at least 2 options
                        </p>
                      )}
                      {multiSelectImageBase.error2 && (
                        <p className="firstViewValidation">
                          You need to select at least 3 Images
                        </p>
                      )}
                      {multiSelectImageBase.error3 && (
                        <p className="firstViewValidation">
                          Please select the correct option
                        </p>
                      )}
                      {multiSelectImageBase.error4 && (
                        <p className="firstViewValidation">
                          You have selected an option for which no image has
                          been selected
                        </p>
                      )}
                      {multiSelectImageBase.error5 && (
                        <p className="firstViewValidation">
                          Option cannot just contain space
                        </p>
                      )}
                      {multiSelectImageBase.error6 && (
                        <p className="firstViewValidation">
                          You have selected an image by skipping one of the
                          previous one
                        </p>
                      )}
                      {multiSelectImageBase.error7 && (
                        <p className="firstViewValidation">
                          Please enter a feedback
                        </p>
                      )}
                      {multiSelectImageBase.error8 && (
                        <p className="firstViewValidation">
                          Entered options must have different values
                        </p>
                      )}
                    </div>
                    {/* {quizType === "6" || quizType === "2" ? ( */}

                    <div className={classes.displayFlex}>
                      <div className={classes.flexBasis}>
                        <h4>Options:</h4>
                      </div>

                      <FormControl
                        className={classes.root1}
                        component="fieldset"
                        fullWidth
                      >
                        <RadioGroup
                          onChange={(e) => handleOptionChange(e, qidx)}
                          value={question.correctoption}
                        >
                          {question.iopts.map((opts, oidx) => (
                            <>
                              <FormControlLabel
                                value={oidx.toString()}
                                control={<BlueRadio />}
                                label={
                                  <input
                                    style={{ width: "370px" }}
                                    value={opts.content}
                                    className="classInput"
                                    onChange={(e) =>
                                      handleInputChange(e, oidx, qidx)
                                    }
                                    type="text"
                                    autoComplete="off"
                                  />
                                }
                              />
                            </>
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </div>

                    <div
                      className={classes.displayFlex}
                      style={{ justifyContent: "start" }}
                    >
                      <div className={classes.flexBasis}>
                        <h4>Marks:</h4>
                      </div>
                      <div className={classes.root1}>
                        {defaultValidation.error10 ? (
                          <p className="firstViewValidation">
                            Please enter the marks should greater than zero
                          </p>
                        ) : null}

                        <input
                          type="number"
                          className="classInput_Marks"
                          // maxLength="1"
                          max="5"
                          min="1"
                          onChange={(e) => {
                            handleChangeMarks(e);
                            let qScore = e.target.value;
                            // if (e.target.value > 0) {
                            setQuizQuestion((currentQScore) =>
                              produce(currentQScore, (v) => {
                                v[qidx].qscore = qScore;
                              })
                            );
                            // }
                          }}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "2rem",
                        marginTop: "2rem",
                      }}
                    >
                      <CustomButton
                        type="submit"
                        onClick={() =>
                          addMultiminiQuestion(question, qidx, 0, 2)
                        }
                      >
                        Add Question
                      </CustomButton>
                      <CustomButton
                        type="submit"
                        onClick={() =>
                          addMultiminiQuestion(question, qidx, 1, 2)
                        }
                      >
                        Save
                      </CustomButton>
                    </div>
                  </div>
                )}
              </>
            ))}
          </>
        ) : null}

        {success && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h1>
              Miniassignment {multiform ? "Added" : "Updated"} Successfully{" "}
            </h1>
          </div>
        )}
      </div>
    </div>
  );
}
