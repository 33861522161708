// Dependencies imports
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import { Link, useNavigate } from "react-router-dom";

import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";

// Local imports
import { ReactComponent as Close } from '../../assets/svg/close_black_24dp.svg';
import { Constants } from "../../config/constants";

// Styles imports
import Prev from './PreviousBatches.module.scss';

// Start of Previous Batches popup component 
const PreviousBatches = (props) => {
    let userDetails = useSelector(authData);
    let { handleClose, batches } = props;
    let navigate = useNavigate();
    const dispatch = useDispatch();

    // Internal styles for table cells
    const header = {
        color: "white",
        fontFamily: "nunito",
        fontSize: "18px",
        fontWeight: "600px"
    }

    const batchnames = {
        color: Constants.main_color_1,
        fontFamily: "nunito",
        fontSize: "16px",
        fontWeight: "500px",
        paddingBotton: "10px"
    }

    function titleclick(data, PValue) {
        console.log("data", data)
        let sdata = { ...userDetails };
        // for(let i = 0; i < pdata.length; i++){
        //   if(data.bpid == pdata[i].bpid){
        //     sdata.curprgcou = pdata[i];
        //   }
        // }

        sdata.curprgcou = { ...data };
        sdata.curprgcou.pid = data.opid;
        sdata.curprgcou.bpid = data.bpid;
        sdata.curprgcou.ptitle = data.pname;

        console.log("dhfdf", (sdata.curprgcou));
        if(sdata.breadcrumb == undefined){
            sdata.breadcrumb = [];
            let temp = [...sdata.breadcrumb];
            temp[0] = {
                name:'Manage Programs',
                path:'/Programs'
            };
            temp[1] = {
                name:`${sdata.curprgcou.bname}-${sdata.curprgcou.ptitle}`,
                path:'/ManageBatch'
            };
            sdata.breadcrumb = temp;
        }else{
            let temp = [...sdata.breadcrumb];
            temp[0] = {
                name:'Manage Programs',
                path:'/Programs'
            };
            temp[1] = {
                name:`${sdata.curprgcou.bname}-${sdata.curprgcou.ptitle}`,
                path:'/ManageBatch'
            };
            sdata.breadcrumb = temp;
        }

        dispatch(awsSignIn(sdata));

        navigate("/ManageBatch");
    }

    function tableview() {
        return (
            <TableBody>
                {batches.map((item) => {
                    return (
                        <TableRow>
                            <TableCell style={batchnames} className={Prev.batch} onClick={() => { titleclick(item) }}>
                                {item.bname}
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        );
    };

    return (
        <div className={Prev.popupcontainer}>
            <div className={Prev.iconholder} >
                <Close className={Prev.icon} onClick={handleClose} />
            </div>
            {/* <h3 className={Prev.header}>
                PG Diploma in Bioinformatics
            </h3> */}
            <div className={Prev.table}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead style={{ background: Constants.main_color_1 }}>
                            <TableRow>
                                <TableCell style={header}>
                                    <TableSortLabel>Previous batches</TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {tableview()}
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};

export default PreviousBatches;