// Dependencies imports
import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbar,
} from "@material-ui/data-grid";
import { API } from "aws-amplify";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import Button from "@material-ui/core/Button";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import Swal from "sweetalert";
// Local imports
import CreateUser from "./CreateUser";
import EditUser from "./EditUser";
import config from "../../config/aws-exports";
import UserHeader from "../Header/UserHeader/UserHeader";
import { Constants } from "../../config/constants";
import UserBulkUpload from "./UserBulkUpload";
// Styles imports
import Use from "./User.module.scss";

function CustomToolbar() {
  return (
    <GridToolbarContainer
      style={{ display: "flex", justifyContent: "flex-end" }}
    >
      <GridToolbar />
    </GridToolbarContainer>
  );
}
const useStyles = makeStyles({
  root: {
    "& .super-app-theme--header": {
      backgroundColor: Constants.main_color_1,
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
  },
});

function Users() {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [editUserData, setEditUserData] = useState(false);
  const [openBulk, setOpenBulk] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };
  const handleCellClick = (param) => {
    setOpenEdit(true);
    setEditUserData(param.row);
  };

  const handleBulkOpen = () => {
    setOpenBulk(true);
  };

  const handleBulkClose = () => {
    setOpenBulk(false);
  };
  useEffect(() => {
    getUsersDetails();
  }, []);

  async function getUsersDetails() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_USERS,
        bodyParam
      );
      const users = response;
      let trows = [];
      for (let i = 0; i < users.length; i++) {
        let obj = {};
        obj.id = i;
        obj.col1 = users[i].UNAME;
        obj.col2 = users[i].EMAILID;
        obj.col3 = users[i].UR;

        if (users[i].LA === undefined) {
        } else {
          var diff = moment.utc(users[i].LA).fromNow();

          obj.col4 = diff;
        }
        if (users[i].US === 1 || users[i].US === "1") {
          obj.col5 = "Active";
        } else {
          obj.col5 = "Not Active";
        }

        obj.col6 = users[i].GEN;
        obj.col7 = users[i].CNO;
        obj.col8 = users[i].ADS;
        obj.col9 = users[i].EID;

        if (users[i].REASON != undefined || users[i].REASON != "") {
          obj.col10 = users[i].REASON;
        }
        if (users[i].ENDISDATE != undefined || users[i].ENDISDATE != "") {
          obj.col11 = users[i].ENDISDATE;
        }

        trows.push(obj);
      }
      const tcolumns = [
        {
          field: "col1",
          headerName: "Name",
          flex: 1,
          headerClassName: "super-app-theme--header",

          renderCell: (param) => {
            return (
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleCellClick(param);
                }}
              >
                {param.value}
              </p>
            );
          },
        },
        {
          field: "col2",
          headerName: "Email",
          flex: 1,
          headerClassName: "super-app-theme--header",
        },
        {
          field: "col3",
          headerName: "Role",
          flex: 1,
          headerClassName: "super-app-theme--header",
        },
        {
          field: "col4",
          headerName: "Last Active",
          flex: 1,
          headerClassName: "super-app-theme--header",
        },
        {
          field: "col5",
          headerName: "Status",
          flex: 1,
          headerClassName: "super-app-theme--header",
        },
        {
          field: "col6",
          headerName: "Gender",
          flex: 1,
          headerClassName: "super-app-theme--header",
        },
        {
          field: "reset",
          headerName: "Reset User",
          flex: 1,
          sortable: false,
          disableExport: true,
          disableClickEventBubbling: true,
          headerClassName: "super-app-theme--header",
          renderCell: (param) => {
            return (
              <Button
                size="small"
                startIcon={
                  <RestartAltIcon
                    color="warning"
                    style={{ height: "2rem", width: "2rem" }}
                  />
                }
                onClick={() => {
                  const email = param.row.col2;
                  const name = param.row.col1;
                  const username = email;
                  const role = param.row.col3;
                  handleReset({ name, username, role });
                }}
              ></Button>
            );
          },
        },
      ];
      setRows(trows);
      setColumns(tcolumns);
      setLoading(false);
    } catch (error) {
      console.log("getUserError", error);
    }
  }

  async function downloadcsv() {
    const response = await fetch("userlist.csv");
    const data = await response.text();
    const blob = new Blob([data], { type: "data:text/csv;charset=utf-8," });
    const url = window.URL.createObjectURL(blob);
    let dwldLink = document.createElement("a");
    let isSafariBrowser =
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("Chrome") == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", "userlist.csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  async function resetUser(req) {
    try {
      const bodyParam = {
        body: req,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/reset-password",
        bodyParam
      );

      if (response != undefined) {
        Swal({
          title: "Success",
          text: "User Reseted successfully",
          icon: "success",
          dangerMode: false,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
        handleClose();
        getUsersDetails();
      }
    } catch (error) {
      throw error;
    }
  }

  const handleReset = (args) => {
    Swal({
      title: "Are you sure?",
      text: "You want to Reset!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willsave) => {
      if (willsave) {
        resetUser(args);
      }
    });
  };
  return (
    <div className={Use.maincontainer}>
      <UserHeader />
      <div className={Use.holder}>
        <h3 className={Use.header}>Manage Users</h3>

        <div
          style={{
            display: "flex",
            gap: "2rem",
          }}
        >
          <a
            style={{ fontSize: "13px", cursor: "pointer", color: "blue" }}
            onClick={downloadcsv}
          >
            Download Format
          </a>
          <button onClick={handleBulkOpen} className={Use.button}>
            Bulk Upload
          </button>
          <button onClick={handleClickOpen} className={Use.button}>
            Create User
          </button>
        </div>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <CreateUser
            handleClose={handleClose}
            getUsersDetails={getUsersDetails}
            rows={rows}
          />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openEdit}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <EditUser
            handleClose={handleCloseEdit}
            editUserData={editUserData}
            getUsersDetails={getUsersDetails}
          />
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openBulk}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openBulk}>
          <UserBulkUpload handleClose={handleBulkClose} type="user" />
        </Fade>
      </Modal>
      <div style={{ height: 600, width: "100%" }} className={classes.root}>
        {loading === false ? (
          <DataGrid
            rows={rows}
            columns={columns}
            className={classes[".MuiDataGrid-root"]}
            components={{
              Toolbar: CustomToolbar,
            }}
            disableSelectionOnClick
          />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Skeleton variant="rect" width="100%" height={600} />{" "}
          </div>
        )}
      </div>
    </div>
  );
}
export default Users;
