// Dependencies imports
import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import config from "../../../config/aws-exports";
import { Constants } from "../../../config/constants";
import Paper from '@material-ui/core/Paper';
import Skeleton from "@material-ui/lab/Skeleton";
import { useDispatch} from "react-redux";

// Local imports
import UserHeader from "../../Header/UserHeader/UserHeader";
import PostQuestion from './PostQuestion';

// Styles imports
import Dis from './Discussion.module.scss';

// Start of Discussion component
const Discussion = () => {
    const [programDetail, setProgramDetail] = useState([]);
    const [index, setindex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [discussion, setDiscussion] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [ids, setIds] = useState();
    const dispatch = useDispatch();
    let userDetails = useSelector(authData);

    useEffect(() => {
        getprogram();
        breadcrumb();
    }, []);

    async function getprogram() {
        console.log("userDetails"+JSON.stringify(userDetails))
        setLoading(true)
        const bodyParam = {
            body: {
                oid: config.aws_org_id,
                pid: userDetails.curprgcou.opid,
                bpid: userDetails.curprgcou.bpid,// these thre are same


            },
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        if (userDetails.role == "Instructors" || userDetails.role == "Coordinators") {
            bodyParam.body.eid = userDetails.eid;
          }
        try {
            const response = await API.post(
                config.aws_cloud_logic_custom_name,
                Constants.GET_Programs_Details,
                bodyParam
                //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
            );
            const users = response;
            console.log("programresponse" + JSON.stringify(users))
            if (response.pcurriculum !== undefined) {

                setProgramDetail(response.pcurriculum);
                if (response.pcurriculum[0].btid !== undefined) {
                    setIds(response.pcurriculum[0].btid);
                    setLoading(false);
                }
            }


        } catch (error) {
            swal({
                title: "Oooops!",
                text: "something went wrong!",
                icon: "warning",
                dangerMode: true,
            })
                .then(willDelete => {
                    if (willDelete) {
                        swal("Oooops!", "Contact developer");
                    }
                });
            console.log("getUserError", error);
        }
        console.log("Mount");
    }
    // async function getDiscussionData() {
    //     setIsLoading(true);
    //     const bodyParam = {
    //       body: {
    //         oid: config.aws_org_id,

    //         bpid: courseId,
    //       },
    //       headers: {
    //         "Content-Type": "application/json",
    //         Accept: "application/json",
    //       },
    //     };
    //     alert("10"+JSON.stringify(bodyParam.body))
    //     try {
    //       const response = await API.post(
    //         config.aws_cloud_logic_custom_name,
    //         `/getDiscussion`,
    //         bodyParam
    //       );
    //       const { discussion } = response;
    //       console.log("discussion", discussion);
    //       setDiscussion(discussion);
    //       setIsLoading(false);
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   }
    const onClick = (data) => {
        console.log(data.btid)
        setIds(data.btid);

    }

    function breadcrumb() {
        let sdata = { ...userDetails };
        let temp = [...sdata.breadcrumb];
        
        temp[2] = {
            name:'Discussion',
            path:'/discussion'
        };

        sdata.breadcrumb = temp;

        dispatch(awsSignIn(sdata));
    }


    return (

        <div className={Dis.maincontainer}>
            <UserHeader Bindex={2}/>
            <h3 className={Dis.header}>Discussions</h3>
            {loading == false ?
                <div className={Dis.mainscreen}>
                    <div className={Dis.leftcomponent}>
                        <Paper style={{ height: "110%" }}>

                            <h4 className={Dis.course}>Course name</h4>
                            {programDetail.map((name, i) => (
                                <h5 className={i === index ? Dis.headingactive : Dis.heading} onClick={() => { onClick(name); setindex(i) }}>{name.ttitle}</h5>
                            ))}

                        </Paper>
                    </div>
                    <div className={Dis.rightcomponent}>
                        <Paper>
                            <PostQuestion ids={ids} />
                        </Paper>
                    </div>
                </div>
                : <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", paddingTop: "20px", width: "100%", gap: "50px" }}><Skeleton variant="rect" width="30%" height={600} style={{ borderRadius: "5px" }} /> <Skeleton variant="rect" width="60%" height={600} style={{ borderRadius: "5px" }} /></div>}
        </div>
    );
};

export default Discussion;
