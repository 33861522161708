// Dependencies imports
import React, { useEffect, useState } from 'react';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel'
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import { authData } from "../../redux/auth/authSlice";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import swal from 'sweetalert';
// Local imports

// Styles imports
import Add from './AddAnnouncement.module.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent"
  },
  formControl: {
    border: "1px solid #6a6a6a",
    height: "fit-content",
    width: "100%",
    borderRadius: "5px",
    fontSize: "14px",
    fontFamily: "nunito",
    padding: "5px"
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  program: {
    fontSize: "16px",
    color: "black",
    fontWeight: "500",
    paddingLeft: "5px"
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const names = [
  'PG Diploma in Cosmeceutics',
  'PG Diploma in Medical Devices',
  'PG Diploma in Herbal Products'
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddInstructor = (props) => {
  let { handleClose, getFaculty ,facultyData} = props;
  const classes = useStyles();
  const [personName, setPersonName] = useState([]);
  const [programDetail, setProgramDetail] = useState([]);
  const [programName, setProgramName] = useState([]);
  const [instructorEmail, setInstructorEmail] = useState("");
  const [instructorEmailErr, setInstructorEmailErr] = useState("");
  const [courseErr, setCourseErr] = useState("");
  const [spin, setSpin] = useState(false);

  let userDetails = useSelector(authData);
  
  useEffect(() => {
    getProgramsDetails();
    console.log("facultydata" + JSON.stringify(facultyData))
   
  }, []);

  const onsubmit = (event) => {
    
    let formIsValid = true;
    let neweid;
    setInstructorEmailErr("");
    setCourseErr("");

    if (personName === "" || personName.length === 0) {
      setCourseErr("* Select Course");
      return;
    }
     

    if (instructorEmail === "") {
      formIsValid = false;
      setInstructorEmailErr("*Please enter your email-ID.")
      return;
    }
    if (instructorEmail !== "") {
      //regular expression for email validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(instructorEmail)) {
        formIsValid = false;
        setInstructorEmailErr("*Please enter valid email-ID.");
        return;
      }
    }
    if (facultyData != undefined && facultyData.length > 0) {
      for (let i = 0; i < facultyData.length; i++) {
        if (facultyData[i].email == instructorEmail) {
          setInstructorEmailErr("Instructor already Exist");
          return;
        }
      }
    }
   
    if (formIsValid === true) {
     
      addInstructor();
    }
  };

  async function getProgramsDetails() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        pid: userDetails.curprgcou.opid,
        bpid: userDetails.curprgcou.bpid,

      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    if (userDetails.role == "Instructors" || userDetails.role == "Coordinators") {
      bodyParam.body.eid = userDetails.eid;
    }
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_Programs_Details,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );
      const users = response;
      let programdetails = [];
      let programname = []
      if (response.pcurriculum !== undefined) {
        for (var i = 0; i < response.pcurriculum.length; i++) {
          let obj = {};
          obj.tid = response.pcurriculum[i].btid;
          obj.ttitle = response.pcurriculum[i].ttitle;
          programdetails.push(obj);
          programname.push(obj.ttitle)
        }
      }
      setProgramDetail(programdetails);




      //   setRows(trows);
      //   setColumns(tcolumns);
      //   setLoading(false);
      // setIsLoading(false);

    } catch (error) {
      swal({
        title: "Oooops!",
        text: "something went wrong!",
        icon: "warning",
        dangerMode: true,
      })
        .then(willDelete => {
          if (willDelete) {
            setSpin(false);
          }
        });
      console.log("getUserError", error);
    }
    console.log("Mount");
  }

  const handleChange = (event) => {
    console.log("multi" + JSON.stringify(event.target.value));
    // let prn = [];
    // for (let i = 0; i < event.target.value.length; i++)
    // {
    //     prn.push(event.target.value[i].ttitle);
    // }
    setPersonName(event.target.value);
    // setProgramName(prn)

  };
  const onChangeHandlerInstructorEmail = event => {
    setInstructorEmail(event.target.value);
  };

  async function addInstructor() {
    setSpin(true);
    let dummydata = personName;
    for (let i = 0; i < personName.length; i++){
      dummydata[i].bpid = userDetails.curprgcou.bpid;
    }
  
    const bodyParam = {
      body: {

        oid: config.aws_org_id,
        cid: userDetails.curprgcou.bpid,
        email: instructorEmail,
        batch: userDetails.curprgcou.bname,
        action: "add",
        courses: dummydata

      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    
 
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_ELUPDATEINSTRUCTORBATCH,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );
      // const users = response;


      handleClose();
      if (response.code === "2" || response.code === 2) {

        swal({
          title: "Success",
          text: "Instructor added Successfully to the Batch",
          icon: "success",
          dangerMode: false,
        })
          .then(willDelete => {
            if (willDelete) {
              handleClose();
              getFaculty();
              setSpin(false);
            }
          });

      } else if (response.code === "0" || response.code === 0) {
        swal({
          title: "Oooops!",
          text: "Instructor already exist in the batch!",
          icon: "warning",
          dangerMode: true,
        })
          .then(willDelete => {
            if (willDelete) {
              setSpin(false);
            }
          });

      } else if (response.code === "3" || response.code === 3) {


        swal({
          title: "Success",
          text: "Instructor successfully removed from the batch!",
          icon: "success",
          dangerMode: false,
        })
          .then(willDelete => {
            if (willDelete) {
              handleClose();
              getFaculty();
              setSpin(false);
            }
          });


      } else if (response.code === "5" || response.code === 5) {
        swal({
          title: "Oooops!",
          text: "This user is not an Instructor!",
          icon: "warning",
          dangerMode: true,
        })
          .then(willDelete => {
            if (willDelete) {
              setSpin(false);
            }
          });


      } else {
        swal({
          title: "Oooops!",
          text: "Instructor does not exist!",
          icon: "warning",
          dangerMode: true,
        })
          .then(willDelete => {
            if (willDelete) {
              setSpin(false);
            }
          });

      }
      setSpin(false);
      getFaculty();
   
    } catch (error) {
      setSpin(false);
      swal({
        title: "Oooops!",
        text: "Somthing wrong!",
        icon: "warning",
        dangerMode: true,
      })
        .then(willDelete => {
          if (willDelete) {
            setSpin(false);
            swal({
              title: "Contact Developer",
             
            })
          }
        });

      console.log("getUserError", error);
      setSpin(false);
    }
    console.log("Mount");
  }


  return (
    <div>
      {spin === false ?
    <div className={Add.mainpopup}>
      <h3 className={Add.header}>Add Instructor</h3>
      <div className={Add.multiselect}>
        <div style={{ color: "#cc0000", marginBottom: "12px", marginTop: "-10px", textAlign: "start", marginLeft: "20%" }}>{courseErr}</div>
        <FormControl className={classes.formControl}>
              <InputLabel id="demo-mutiple-checkbox-label" className={classes.program}>Select Course</InputLabel>
              
          <Select
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            multiple
            value={personName}
            onChange={handleChange}
            input={<Input />}
                renderValue={(selected) => {
                  selected.join(', '); console.log(JSON.stringify(selected));
                  let temp = [];
                  for (let i = 0; i < selected.length; i++) {
                    temp.push(selected[i].ttitle);
                  }
                  return temp;
                }}
            MenuProps={MenuProps}
          >
            {programDetail.map((item) => (
              <MenuItem key={item} value={item}>
                <Checkbox checked={personName.indexOf(item) > -1} />
                <ListItemText primary={item.ttitle} />
              </MenuItem>
            ))}
                
          </Select>
        </FormControl>
      </div>
      <div style={{ color: "#cc0000", textAlign: "start", marginLeft: "20%" }}>{instructorEmailErr}</div>
      <input type="email" placeholder="Email" className={Add.title} onChange={onChangeHandlerInstructorEmail} value={instructorEmail} />
      <h3> Selected Courses </h3>
      <div style={{textAlign:"start",padding:"12px",fontSize:"14px"}}>
        
        <ol>
        {personName.map((item)=><li>{item.ttitle}</li>)}
        </ol>
      </div>
      <div className={Add.buttonholder}>
        <button className={Add.cancel} onClick={handleClose}>Cancel</button>
        <button className={Add.submit} onClick={onsubmit}>Add</button>
      </div>
    </div>: <div className={classes.root}>
          <CircularProgress style={{ color: Constants.main_color_1 }} /></div>}
    </div>
  );
};

export default AddInstructor;