import React, { useEffect, useState } from 'react';

import { ReactComponent as Close } from '../../assets/svg/close_black_24dp.svg';

import User from './CreateUser.module.scss';
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import { API, JS } from "aws-amplify";
import swal from 'sweetalert';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        heigth: "100%",
        alignItems: "center",
        justifyContent: "center",
        background: "transparent"
    },
}));


const CreateUser = (props) => {
    let { handleClose, getUsersDetails, rows } = props;
    const [userNameErr, setUserNameErr] = useState("");
    const [emailErr, setEmailErr] = useState("");
    const [pnoErr, setPnoErr] = useState("");
    const [genderErr, setGenderErr] = useState("");
    const [spin, setSpin] = useState(false);
    const classes = useStyles();
    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [pno, setPno] = useState("");
    const [gender, setGender] = useState("");
    const [address, setAddress] = useState("");
    const [urole, setUrole] = useState("User");
    const [loading, setLoading] = useState(false);

    const onChangeHandlerUser = event => {
        setUserName(event.target.value);
    };
    const onChangeHandlerEmail = event => {
        setEmail(event.target.value);
    };
    const onChangeHandlerPno = event => {
        setPno(event.target.value);
    };
    const onChangeHandlerGender = event => {
        setGender(event.target.value);
    };
    const onChangeHandlerAddress = event => {
        setAddress(event.target.value);
    };
    const onChangeHandlerUrole = event => {
        setUrole(event.target.value);
    };
    // useEffect(() => {
    //     getUsersDetails();
    //   }, []);
    const onsubmit = () => {

        console.log("data" + JSON.stringify(rows));
        let formIsValid = true;
        setUserNameErr("");
        setEmailErr("");
        setPnoErr("");
        setGenderErr("");

        if (userName === "" || userName === null || (userName != undefined && userName.trim() === "")) {
            formIsValid = false;
            setUserNameErr("Please enter user name");
            return;
        }
        if (email === "" || email === null || (email != undefined && email.trim() === "")) {
            formIsValid = false;
            setEmailErr("*Please enter your email-ID.");
            return;
        }
        if (pno !== null && pno !== undefined && pno.trim() !== "" && pno.length < 10) {
            formIsValid = false;
            setPnoErr("Please enter a valid phone number (should be atleast 10 digits)");
            return;
        }
        if (gender === "" || gender === null) {
            formIsValid = false;
            setGenderErr("Please select gender");
            return;
        }
        if (userName !== "") {
            if (!userName.match(/^[a-zA-Z ]*$/)) {
                formIsValid = false;
                setUserNameErr("*Please enter alphabet characters only.");
                return;
            }
        }
        if (email !== "") {
            //regular expression for email validation
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            let reg = /^[a-zA-Z0-9]+(?:[.-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:[.-][a-zA-Z0-9]+)*\.[a-zA-Z]{2,3}$/;
            let r = new RegExp(/(^[0-9a-zA-Z]+(?:[._-][0-9a-zA-Z]+)*)@([0-9a-zA-Z]+(?:[._-][0-9a-zA-Z]+)*\.[0-9a-zA-Z]{2,3})$/i);
            // alert("hi"+r.test(email));

            if (!r.test(email)) {
                formIsValid = false;
                setEmailErr("*Please enter valid email-ID.");
                return;
            }
        
            // if (email.match(new RegExp(/^[A-Z]/)) !== null) {
            //     setEmailErr("*Please enter valid email-ID.");
            //     return;
            // }
        }
        if (rows != undefined && rows.length > 0) {
            for (let i = 0; i < rows.length; i++) {
                if (email == rows[i].col2) {
                    setEmailErr("User Already Exist as " + rows[i].col3);
                    return;
                }

            }
        }

        if (formIsValid == true) {
            // alert("ok");
            Updateuser();
        }

        async function Updateuser() {
            setSpin(true);
            const bodyParam = {
                body: {
                    oid: config.aws_org_id,
                    uname: userName,
                    eid: email,
                    urole: urole,
                    gender: gender,
                    address: address,
                    contactno: pno,
                    //   groups:[null]
                },
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            };
            // alert("bd" + JSON.stringify(bodyParam.body));
            try {
                const response = await API.post(
                    config.aws_cloud_logic_custom_name,
                    Constants.GET_ADDUSER,
                    bodyParam
                    //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
                );
                // const users = response;

                if (response.Code === 2 || response.Code === "2") {
                    swal({
                        title: "Success",
                        text: "User added successfully",
                        icon: "success",
                        dangerMode: false,
                    })
                        .then(willDelete => {
                            if (willDelete) {
                                setSpin(false);
                            }
                        });
                    handleClose();
                    getUsersDetails();
                }
                else if (response.Code === 0 || response.Code === "0") {
                    swal({
                        title: "Oooops!",
                        text: "User already exists!",
                        icon: "warning",
                        dangerMode: true,
                    })
                        .then(willDelete => {
                            if (willDelete) {
                                setSpin(false);
                            }
                        });


                }
                else {
                    swal({
                        title: "Oooops!",
                        text: "User not added due to some reason!",
                        icon: "warning",
                        dangerMode: true,
                    })
                        .then(willDelete => {
                            if (willDelete) {
                                setSpin(false);
                            }
                        });
                    handleClose();
                    getUsersDetails();
                }


            } catch (error) {
                setSpin(false);
                swal({
                    title: "Oooops!",
                    text: "Something went wrong!",
                    icon: "warning",
                    dangerMode: true,
                })
                    .then(willDelete => {
                        if (willDelete) {
                            setSpin(false);
                        }
                    });
                console.log("getUserError", error);
            }
            console.log("Mount");
        }


    };

    return (
        <div className={User.maindiv}>
            {spin === false ?
                <div className={User.maincontainer}>
                    <div className={User.iconcontainer} >
                        <Close className={User.closebutton} onClick={handleClose} />
                    </div>
                    <h3 className={User.create}>Create user</h3>
                    <div className={User.form}>
                        <div className={User.field}>
                            <label style={{ fontSize: "16px", fontWeight: "500px", fontFamily: "nunito" }} for="name" required>* Name</label>
                            <input style={{ border: "none", width: "80%", boxShadow: "0px 0px 5px 0px lightgrey ", background: "white", height: "30px", marginBottom: "20px", fontSize: "15px", paddingLeft: "10px", fontWeight: "200px" }} type="text" id="name" onChange={onChangeHandlerUser}
                                value={userName} required />
                        </div>
                        <div style={{ color: "#cc0000", marginBottom: "12px", marginTop: "-10px", textAlign: "start", marginLeft: "20%" }}>{userNameErr}</div>

                        <div className={User.field}>
                            <label style={{ fontSize: "16px", fontWeight: "500px", fontFamily: "nunito" }} for="email" required>* Email</label>
                            <input style={{ border: "none", width: "80%", boxShadow: "0px 0px 5px 0px lightgrey ", background: "white", height: "30px", marginBottom: "20px", fontSize: "15px", paddingLeft: "10px", fontWeight: "200px" }} type="email" id="email" onChange={onChangeHandlerEmail}
                                value={email} required />
                        </div>
                        <div style={{ color: "#cc0000", marginBottom: "12px", marginTop: "-10px", textAlign: "start", marginLeft: "20%" }}>{emailErr}</div>

                        <div className={User.field}>
                            <label style={{ fontSize: "16px", fontWeight: "500px", fontFamily: "nunito" }} for="contact">Ph. No</label>
                            <input style={{ border: "none", width: "80%", boxShadow: "0px 0px 5px 0px lightgrey ", background: "white", height: "30px", marginBottom: "20px", fontSize: "15px", paddingLeft: "10px", fontWeight: "200px" }} type="number" id="contact" onChange={onChangeHandlerPno}
                                value={pno} />
                        </div>
                        <div style={{ color: "#cc0000", marginBottom: "12px", marginTop: "-10px", textAlign: "start", marginLeft: "20%" }}>{pnoErr}</div>

                        <div className={User.field}>
                            <label style={{ fontSize: "16px", fontWeight: "500px", fontFamily: "nunito" }} for="gender">Gender</label>
                            <select style={{ border: "none", width: "80%", boxShadow: "0px 0px 5px 0px lightgrey", background: "white", height: "30px", marginBottom: "20px", fontSize: "15px", paddingLeft: "10px", fontWeight: "200px" }} name="gender" id="gender" onChange={onChangeHandlerGender}
                                value={gender}>
                                <option value="">Select</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="trans">Trans Gender</option>
                            </select>
                        </div>
                        <div style={{ color: "#cc0000", marginBottom: "12px", marginTop: "-10px", textAlign: "start", marginLeft: "20%" }}>{genderErr}</div>

                        <div className={User.field}>
                            <label style={{ fontSize: "16px", fontWeight: "500px", fontFamily: "nunito" }} for="address">Address</label>
                            <textarea style={{ border: "none", width: "80%", boxShadow: "0px 0px 5px 0px lightgrey", background: "white", height: "80px", marginBottom: "20px", fontSize: "15px", paddingLeft: "10px", fontWeight: "200px" }} id="address" rows="4" onChange={onChangeHandlerAddress}
                                value={address} />
                        </div>
                        <div className={User.field}>
                            <label style={{ fontSize: "16px", fontWeight: "500px", fontFamily: "nunito" }} for="role">User role</label>
                            <select style={{ border: "none", width: "80%", boxShadow: "0px 0px 5px 0px lightgrey", background: "white", height: "30px", marginBottom: "20px", fontSize: "15px", paddingLeft: "10px", fontWeight: "200px" }} name="role" id="role" required onChange={onChangeHandlerUrole}
                                value={urole}>
                                <option value="User">User</option>
                                <option value="Instructor">Instructor</option>
                                <option value="Coordinator">Program Coordinator</option>
                            </select>
                        </div>
                        <br></br>
                        <br></br>
                        <input type="submit" value="Save" className={User.submit} onClick={onsubmit}></input>
                    </div>
                </div> : <div className={classes.root}>
                    <CircularProgress style={{ color: Constants.main_color_1 }} /></div>}
        </div>
    )
}

export default CreateUser;