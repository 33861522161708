import React from 'react';

const EmailIcon = ({ className, cls1 }) => {
    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className={className}
            width={30}
            height={30}>

            <rect className={cls1} x="4.16" y="7.44" width="16.68" height="10.13" rx="0.63" />
            <path className={cls1} d="M4.37,7.61l7.69,5.3a1.13,1.13,0,0,0,1.3,0l7.27-5.28" />
        </svg>
    );
};

export default EmailIcon;