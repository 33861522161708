// Dependencies imports
import React, { useState, useEffect } from "react";
// Local imports
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
// Styles imports
import assessStyle from "./Assessment.module.scss";
import Add from "./AddScore.module.scss";
import Done from "../../assets/svgjs/Done";
import Reject from "../../assets/svgjs/Reject";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import config from "../../config/aws-exports";
import { API } from "aws-amplify";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Button,
} from "@material-ui/core";
import { Constants } from "../../config/constants";
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "70vw",
    backgroundColor: "transparent",
    padding: 20,
    height: "auto",
    overflowX: "hidden",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  root: {
    width: "100%",
    backgroundColor: "transparent",
    "& .MuiBox-root": {
      margin: "-7px",
      padding: "8px",
      backgroundColor: "white",
    },
  },
}));
const PopAssesment = (props) => {
  let { rows, setRows, handleClose, usetData, quizId } = props;
  const [modalStyle] = React.useState(getModalStyle);
  const [openAnswer, setOpenAnswer] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [assessAns, setAssessAns] = useState({});
  const [imgUrl, setImgUrl] = useState();
  const [formatCurDate, setFormatCurDate] = useState(0);
  const [assessmentData, setAssessmentData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [spin, setSpin] = useState(false);

  const [score, setScore] = useState("");
  const dispatch = useDispatch();
  const classes = useStyles();
  let userDetails = useSelector(authData);

  useEffect(() => {
    getAssessmentData(userDetails);
    formatDate();
    let sdata = { ...userDetails };
    if (usetData.score == "Not Graded") {
      setScore(0);
    } else if (
      usetData.score !== undefined ||
      usetData.score === 0 ||
      usetData.score === "0"
    ) {
      setScore(usetData.score);
    }
    setImgUrl(
      `https://${Constants.DOMAIN}/jssaher-resources/images/assessment-images/${userDetails.asses.btid}/${quizId}/`
    );
    dispatch(awsSignIn(sdata));
  }, []);
  async function getAssessmentData(userDetails) {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: usetData.eid,
        bpid: userDetails.curprgcou.bpid,
        assessid: userDetails.asses.btid,
        quizid: quizId,
        quiztype: "assess",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_ASSESSMENT_ANSWERS,
        bodyParam
      );
      const { assessment } = response;
      setAssessmentData(assessment);
      setAssessAns(response);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }
  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }
  
  function viewAns() {
    if (
      assessAns.response !== undefined &&
      assessAns.response.response !== undefined
    ) {
      let l = assessAns.response.response.length - 1;
      let questionsA = assessAns.qitems.length - 1;
      if (questionsA >= currentQuestion) {
        let tem = [...assessAns.response.response];
        let flag = -1;
        for (let i = 0; i < tem.length; i++) {
          if (tem[i].iid == assessAns.qitems[currentQuestion].iid) {
            flag = i;
          }
        }
        if (flag != -1) {
          if (
            assessAns.qitems[currentQuestion]?.atype != 7 &&
            assessAns.qitems[currentQuestion]?.atype != 8
          ) {
            return assessAns.qitems[currentQuestion].iopts[
              assessAns.response.response[flag].response
            ].content;
          } else {
            return assessAns?.response?.response[flag]?.response;
          }
        } else {
          return <div style={{ color: "red" }}> You have not answerd </div>;
        }
      } else {
        return <div style={{ color: "red" }}> You have not answerd </div>;
      }
    } else {
      return <div style={{ color: "red" }}> You have not answerd </div>;
    }
  }
  function answerPrev() {
   
    const prevQuestion = currentQuestion - 1;

    setCurrentQuestion(prevQuestion);
  }

  function answerNext() {
    
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < assessAns.qitems.length) {
      setCurrentQuestion(nextQuestion);
    }
  }
  function formatDate() {
    
    setInterval(() => {
      setFormatCurDate(toTimestamp(moment().format("YYYY-MM-DD h:mm:ss a")));
    }, 2000);

  }
  function toTimestamp(strDate) {
    var datum = Date.parse(strDate);
    return datum / 1;
  }
  function handleChange(e) {
    setScore(e.target.value);
  }
  async function gradeAssesment(score) {
    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: usetData.eid,
        bpid: userDetails.curprgcou.bpid,
        assessid: userDetails.asses.btid,
        quizid: quizId,
        score: score,
        quiztype: "assess",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_UPDATE_ASSESMENT_REPORT,
        bodyParam
      );
     
      if (response == "Done Updating") {
        let temp = [...rows];
        for (let i = 0; i < temp.length; i++) {
          if (usetData.eid == temp[i].eid) {
            temp[i].score = score;
          }
        }
        setRows(temp);
      }
      setSpin(false);
      handleClose();
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <div>
      {spin === false ? (
        <div className={Add.mainpopup}>
        
          <div style={modalStyle} className={classes.paper}>
            <div className={assessStyle.bodyAns}>
           
              {
                assessAns.qitems === undefined ||
                assessAns.qitems.length === 0 ? (
                  <div style={{ fontSize: "15px" }}>Loading.. </div>
                ) : (
                  <div>
                    <div className={assessStyle.questionsection}>
                      <div
                        style={{
                          position: "fixed",
                          backgroundColor: "white",
                          width: "92%",
                          top: "0",
                          zIndex: "999",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                            float: "right",
                            fontSize: "14px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setOpenAnswer(false);
                            setCurrentQuestion(0);

                            setAssessAns([]);
                          }}
                        >
                          
                          <p onClick={handleClose}>X</p>
                        </div>

                        <h3 className={Add.header}>Add Score</h3>
                        <h4 className={Add.over}>Overall Score: </h4>

                        <div className={Add.overholder}>
                          <div
                            style={{
                              display: "flex",
                              gap: "3rem",
                              textAlign: "left",
                            }}
                          >
                            <div>
                              <h4>Name </h4>
                              <h4>Email Id </h4>
                              <h4>Candidate Id </h4>
                            </div>

                            <div>
                              <h4>{usetData.name}</h4>
                              <h4>{usetData.email} </h4>
                              <h4>{usetData.usn}</h4>
                            </div>
                          </div>
                          <input
                            value={score}
                            onChange={(e) => handleChange(e)}
                            onKeyPress={(event) => {
                              if (event.key === "Enter") {
                                gradeAssesment(score);
                              }
                            }}
                            className={Add.overinput}
                          ></input>
                          <button
                            onClick={() => gradeAssesment(score)}
                            className={Add.submit}
                          >
                            Submit
                          </button>
                        </div>
                      </div>

                      <div style={{ marginTop: "15rem", fontSize: "14px" }}>
                        <div className={assessStyle.questioncount}>
                          <span>Question {currentQuestion + 1}</span>/
                          {assessAns.qitems.length}
                        </div>
                        <div>
                          {assessAns.qitems[currentQuestion].istem}
                          {assessAns.qitems[currentQuestion].img ? (
                            <div className="img_container">
                              <img
                                src={`${imgUrl}${assessAns.qitems[currentQuestion].img}`}
                                alt="item"
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className={assessStyle.answersection}>
                      {assessAns.qitems[currentQuestion].atype == "2" ? (
                        <div
                          className={
                            "btn-group " + assessStyle.btngroupvertical
                          }
                        >
                          {assessAns.qitems[currentQuestion].iopts.map(
                            (answerOption, index, arrayobj) => (
                              <>
                                <div style={{}}>
                                  <div>
                                 
                                    <div style={{ display: "flex" }}>
                                      <label
                                        className={
                                          assessStyle.labelstyleAns +
                                          " " +
                                          assessStyle.buttonQuizAns
                                        }
                                        for={answerOption.content}
                                      >
                                        {answerOption.content}
                                      </label>
                                      <span>
                                        {answerOption.correct === "true" ||
                                        answerOption.correct === true ? (
                                          <Done
                                            className="right_size"
                                            cls1="cls1_D"
                                            cls2="cls2_D"
                                          />
                                        ) : (
                                          <Reject
                                            className="Wrong_size"
                                            cls1="cls1_D"
                                            cls2="cls2_D"
                                          />
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                          )}
                        </div>
                      ) : null}
                    </div>
                    <br />

                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        display: "flex",
                        overflow: "auto",
                      }}
                    >
                      Your Answer :{" "}
                      <div style={{ fontSize: "14px", fontWeight: "700" }}>
                        {
                          viewAns()

                        }
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        marginTop: "2rem",
                        justifyContent: "space-around",
                      }}
                    >
                      {currentQuestion > 0 ? (
                        <Button
                          color="primary"
                          variant="contained"
                          className="btn-size"
                          onClick={() => answerPrev()}
                          style={{ fontSize: "12px" }}
                        >
                          Prev
                        </Button>
                      ) : null}
                      {currentQuestion + 1 === assessAns.qitems.length ? (
                        <div></div>
                      ) : (
                        <Button
                          color="primary"
                          variant="contained"
                          className="btn-siz"
                          onClick={() => answerNext()}
                          style={{ float: "right", fontSize: "12px" }}
                        >
                          Next
                        </Button>
                      )}
                    </div>
                  </div>
                )
               
              }
           
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.root}>
          <CircularProgress style={{ color: Constants.main_color_1 }} />
        </div>
      )}
    </div>
  );
};

export default PopAssesment;
