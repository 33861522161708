import React, { useState } from 'react';
import AssignmentDetails from '../components/Programs/AssignmentDetails';
import Aside from '../modules/SideNav/Aside';

function AssignmentDetailsLayout() {
    const rtl = false;

    const [toggled, setToggled] = useState(false);

    const handleToggleSidebar = value => {
        setToggled(value);
    };

    return (
        <div className={`app ${rtl ? 'rtl' : ''} ${toggled ? 'toggled' : ''}`}>
            <Aside
                rtl={rtl}
                toggled={toggled}
                handleToggleSidebar={handleToggleSidebar}
            />
            <AssignmentDetails handleToggleSidebar={handleToggleSidebar} />
        </div>
    );
}

export default AssignmentDetailsLayout;
