import React from 'react';

const ReportIcon = ({ className, cls1, cls2 }) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={className}>
      <line className={cls1} x1="5.31" y1="15.61" x2="5.31" y2="19.62" />
      <line className={cls1} x1="9.97" y1="11.61" x2="9.97" y2="19.62" />
      <line className={cls1} x1="14.6" y1="13.21" x2="14.6" y2="19.62" />
      <line className={cls1} x1="19.2" y1="9.62" x2="19.2" y2="19.62" />
      <path className={cls2} d="M18.15,6.45l-1-.93,2.5-.14,0,2.55-1-1" />
      <path className={cls1} d="M5.65,12.82,9.93,8.39a.92.92,0,0,1,1.37,0l2.08,2.5a.93.93,0,0,0,1.4,0l3.84-4.3" />
    </svg>
  );
};

export default ReportIcon;
