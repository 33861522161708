// Dependencies imports
import React, {useState} from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import swal from 'sweetalert';
// Local imports
import { Constants } from "../../../config/constants";

import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData, awsSignOut } from "../../../redux/auth/authSlice";
import config from "../../../config/aws-exports";
import { API } from "aws-amplify";
// Style imports
import Add from "./AddRecording.module.scss"

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      heigth: "100%",
      alignItems: "center",
      justifyContent: "center",
      background: "transparent"
    },
  }));

const DeleteReference = (props) => {
    let {  handleClose,deleteData,reload} = props;
    const [spin, setSpin] = useState(false);
  const classes = useStyles();
  let userDetails = useSelector(authData);
  let lcourseDetails = userDetails.curprg;
   const   onsubmit = async() => {
      // setname(name)
      // alert(event.target.files[0]);

      
      setSpin(true);
      

      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          cid: lcourseDetails.btid,
          action: "delete",
          id:deleteData.id,
            
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
    
      
      try {
        const response = await API.post(
          config.aws_cloud_logic_custom_name,
          Constants.REFERENCES,
          bodyParam
          //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
        );
        // const users = response;
        reload();
        handleClose();
        swal({
          title: "Success",
          text: "Reference Deleted successfully",
          icon: "success",
          dangerMode: false,
        })
          .then(willDelete => {
            if (willDelete) {

              handleClose();
            }
          });
        setSpin(false);
      ;
      } catch (error) {
        swal({
          title: "Oooops!",
          text: "something went wrong!",
          icon: "warning",
          dangerMode: true,
        })
          .then(willDelete => {
            if (willDelete) {
  
            }
          });
        console.log("getUserError", error);
      }
      console.log("Mount");
    
      
    
     
      
    
  };
    return(
        <div>
      {spin === false ?
        <div className={Add.mainpopup}>
          <div className={Add.form}>
            <form>
              <div className={Add.header}>Are you sure you want to delete ?</div>
              <div className={Add.buttonholder}>
                <button className={Add.cancel} onClick={handleClose}>NO</button>
                <button className={Add.submit} onClick={onsubmit} >Yes</button>
              </div>
            </form>
          </div>
        </div> : <div className={classes.root}>
          <CircularProgress style={{ color: Constants.main_color_1 }} /></div>}
    </div>
    );
};

export default DeleteReference;

