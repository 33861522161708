// Dependencies imports
import React, { useState, useEffect } from "react";
import { Tab } from "semantic-ui-react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import PublishIcon from "@material-ui/icons/Publish";
import { API } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import Skeleton from "@material-ui/lab/Skeleton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import DownloadIcon from "@mui/icons-material/Download";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/styles";
import { Avatar } from "@material-ui/core";
import swal from "sweetalert";
import axios from "axios";
// Local imports
import UserHeader from "../Header/UserHeader/UserHeader";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import DownLoadDocs from "./DownLoadDocs";
import AccordionPopup from "./AccordionPopup";
import ViewDoc from "./ViewDoc";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Chart } from "react-google-charts";
import OAAImage from "../../assets/images/OAA.png";
import TLT from "../../assets/images/Total_Login_Time.png";
import TPT from "../../assets/images/Total_Practise_Time.png";
import LL from "../../assets/images/Last_Login.png";
import moment from "moment";
// Styles imports
import User from "./UserProgress.module.scss";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

// Internal Styles for the material UI components
const header = {
  color: "white",
  fontFamily: "nunito",
  fontSize: "15px",
  fontWeight: "400px",
};

const programnames = {
  color: Constants.main_color_1,
  fontFamily: "nunito",
  fontSize: "16px",
  fontWeight: "500px",
};

// Materail Styles for Modal
const useStyles = makeStyles({
  root: {
    "& .super-app-theme--header": {
      background: Constants.main_color_1,
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  center: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    margin: "10px",
  },
  buttonsty: {
    background: "var(--button-background)",
    border: "none",
    borderRadius: "5px",
    height: "30px",
    width: "fit-content",
    color: "var(--button-text)",
    fontFamily: "nunito",
    fontWeight: "700",
    fontSize: "16px",
    padding: "0 20px",
  },
  pieChartStyle: {
    width: "100%",
    height: "100%",
  },
  AssessmentpieChartStyle: {
    "& > div ": {
      overflow: "auto",
    },
    "& > div > div > div > div > svg": {
      width: "100vw!important",
    },
  },
  flexStyleResponsive: {
    display: "flex",
    "@media (max-width: 600px)": {
      display: "block",
      paddingBottom: "1rem",
    },
    "@media (min-width: 770px) and (max-width: 1111px)": {
      display: "block",
      height: "23rem",
      overflow: "auto",
      paddingBottom: "3rem",
    },
    "& > div:nth-child(2)": {
      display: "grid",
      alignItems: "center",
      gap: "3rem",
      margin: "auto",
      width: "60%",
      "@media (max-width: 750px)": {
        width: "100%",
      },
      "@media (min-width: 770px) and (max-width: 1360px)": {
        width: "100%",
      },
    },
  },
  progressBar: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    gap: "1rem",
    flexWrap: "wrap-reverse",
    "& > p:nth-child(1)": {
      width: "82%",
      fontWeight: "700",
      overflowWrap: "break-word",
    },
    "& > p:nth-child(3)": {
      width: "4rem",
      fontWeight: "700",
    },
  },
  subHeading: {
    color: "#4f4f4f",
    fontSize: "1.8rem",
  },
  progressBarContainer: {
    width: "82%",
    "@media (min-width: 400px) and (max-width: 600px)": {
      width: "99%",
    },
  },

  containerMain: {
    width: "100%",
    height: '55rem',
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#E5EDF3",
    alignItems: "center",
  },
  containerMainCenter: {
    position: "absolute", top: "36%", left: "46.5%",
    fontSize: "1.8rem",
    "@media (max-width: 1440px)": {
      fontSize: "1.5rem",
    },
    "@media (max-width: 1030px)": {
      fontSize: "1.2rem",
      left: "45%"
    },
    "& > div": {
      backgroundColor: "#85E085", borderRadius: "2rem", marginTop: "1rem", textTransform: "uppercase", fontWeight: "600", padding: "0.2rem 2rem",
    },
  },
  containerMainWordingL: {
    display: "flex", gap: "2rem",
    fontSize: "1.6rem", alignItems: "center",
    "& > p:first-child": {
      width: "24rem",
      textAlign: "left",
    },
    "@media (max-width: 1440px)": {
      fontSize: "1.3rem",
      "& > p:first-child": {
        width: "20rem",
        textAlign: "left",
      },
    },
    "@media (max-width: 1030px)": {
      fontSize: "1rem",
      gap: "2.2rem",
    },
  },
  containerMainWordingR: {
    display: "flex", gap: "6rem",
    fontSize: "1.6rem", alignItems: "center",
    "@media (max-width: 1440px)": {
      fontSize: "1.3rem",
      "& > p:nth-child(2)": {
        width: "20rem",
        textAlign: "left",
      },
    },
    "@media (max-width: 1030px)": {
      fontSize: "1rem",
      gap: "2.2rem",
    },
  },
  selectSection: {
    fontSize: "1.5rem",
    "& > label": {
      fontSize: "1.5rem",
      backgroundColor: "white",
    },
    "& > div > div": {
      fontSize: "1.5rem",
      padding: "15px!important",
      display: "flex",
      alignItems: "center",
    },
  },
});

const UserProgress = () => {
  const [userData, setUserData] = useState({
    pid: "0",
    bpid: "0",
    pname: "",
    va: "0",
    dc: "0",
    kc: "0",
    ovp: "0",
    certificate: "",
    markscard: [],
  });
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [examData, setExamData] = useState([]);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [marksFile, setMarkFile] = useState();
  const [certificateFile, setCertificateFile] = useState();
  const [tempCfile, setTempCfile] = useState([]);
  const [tempMfile, setTempMfile] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [address, setAddress] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [dob, setDob] = useState("");
  const [passportNo, setPassportNo] = useState("");
  const [govtId, setGovtId] = useState("");
  const [higestDegree, setHigestDegree] = useState("");
  const [workExperience, setWorkExperience] = useState("");
  const [profilePicName, setProfilePicName] = useState("");
  const [pissueTextUpload, setPIssueTextUpload] = useState("");
  const [spin, setSpin] = useState(false);
  let userDetails = useSelector(authData);

  const [ftype, setFtype] = useState();
  const [fileSelected, setFileSelected] = useState(false);

  const [openAdd, setOpenAdd] = useState(false);
  const [openFileUpload, setOpenFileUpload] = useState(false);
  const [openCertificateFileUpload, setOpenCertificateFileUpload] =
    useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [openProfile, setopenProfile] = useState(false);
  const [openDelete, setopenDelete] = useState(false);
  const [deleteMarksCert, setDeleteMarksCert] = useState(false);
  const [openDownload, setopenDownload] = useState(false);
  const [courses, setCourses] = useState([]);
  const [rows, setRows] = useState("");
  const [ccount, setCcount] = useState(0);
  const [seme, setSeme] = useState("1");
  const [semCourse, setSemCourse] = useState("0");
  const [userTotalProgress, setUserTotalProgress] = useState();
  const [semester, setSemester] = React.useState('1');
  const [conSemester, setConSemester] = React.useState('1');
  const [learnerSemester, setLearnerSemester] = React.useState('1');
  const [assessSemester, setAssessSemester] = React.useState('1');
  const [conSemCourse, setConSemCourse] = useState("0");
  const [learnerSemCourse, setLearnerSemCourse] = useState("0");
  const [conCourses, setConCourses] = useState([]);
  const [learnerCourses, setLearnerCourses] = useState([]);
  const [dataBar, setDataBar] = useState([
    [
      "Element",
      "Density",
      { role: "style" },
      {
        sourceColumn: 0,
        role: "annotation",
        type: "string",
        calc: "stringify",
      },
    ],
    ["Audio Visual Learning material", 20, "#7C84E0", null],
    ["E-Resources", 27, "#917CE0", null],
    ["Live Discussion Forum", 33, "#AF7CE0", null],
    ["Assignment", 60, "color: #CE7CE0", null],
  ]);
  const [dataBar2, setDataBar2] = useState([
    ["Subject", "Assessment 1", "Assessment 2", "Assessment 3", "Assessment 4"],
    ["English", 40, 30, 20, 0],
    ["Science", 30, 40, 50, 0],
    ["Maths", 60, 20, 30, 40],
    ["Chemistry", 10, 50, 30, 0],
    ["Biology", 10, 40, 20, 0],
    ["Practical Exam", 60, 40, 50, 0],
  ]);
  const [semData, setSemData] = useState([
    ["Semester", "Hours per Day"],
    ["Semester 1", 10],
    ["Semester 2", 2],
    ["Semester 3", 3],
    ["Semester 4", 4],
    ["Semester 5", 2],
    ["Semester 6", 1],// CSS-style declaration
  ]);

  const [docs, setDocs] = useState([]);
  useEffect(() => {
    getUserMarkscardCertificate();
    getuserprogress();
    breadcrumb();
    if (userDetails.selbatchuser.exam != undefined) {
      exam(userDetails.selbatchuser.exam);
    }
    return () => { };
  }, []);

  const handleAddOpen = () => {
    setOpenAdd(true);
  };

  const handleAddClose = () => {
    setOpenAdd(false);
  };

  const handledeleteOpen = () => {
    setopenDelete(true);
  };

  const handleDeleteClose = () => {
    setopenDelete(false);
  };

  const handleProfileOpen = () => {
    setopenProfile(true);
  };

  const handleProfileClose = () => {
    setopenProfile(false);
  };

  const handleDownloadOpen = () => {
    setopenDownload(true);
  };

  const handleDownloadClose = () => {
    setopenDownload(false);
  };

  const handleFileUploadopen = () => {
    setOpenFileUpload(true);
  };

  const handleFileUploadClose = () => {
    setOpenFileUpload(false);
  };

  const handleFileUploadCertificateopen = () => {
    setOpenCertificateFileUpload(true);
  };

  const handleFileUploadCertificateclose = () => {
    setOpenCertificateFileUpload(false);
  };

  const changeHandlerMarksCard = (e) => {
    setPIssueTextUpload("");
    setFileSelected(false);
    if (e.target.files != undefined) {

      let fileUploadType = "markscard";
      if (e.target.files[0].size < 50 * 1024) {
        setPIssueTextUpload("file size is less than 50kb");
        return;
      } else if (e.target.files[0].size > 1024 * 1024) {
        setPIssueTextUpload("file size is greater than 1mb");
        return;
      } else {
        setFileSelected(true);
        if (
          tempCfile !== undefined &&
          Array.isArray(tempCfile) &&
          tempCfile.length > 0
        ) {
          setTempMfile((tempmfile) => [...tempmfile, e.target.files[0].name]);
        } else {
          setTempMfile([e.target.files[0].name]);
        }
        uploadDocsToServer(e.target.files[0], fileUploadType);
      }
    }
  };

  const changeHandlerCertificate = (e) => {
    setPIssueTextUpload("");
    setFileSelected(false);
    if (e.target.files != undefined) {

      let selectedFile = e.target.files[0];
      let extend =
        selectedFile.name.substring(
          selectedFile.name.lastIndexOf(".") + 1,
          selectedFile.name.length
        ) || selectedFile.name;
      let fileUploadType = "certificate";
      if (e.target.files[0].size < 50 * 1024) {
        setPIssueTextUpload("file size is less than 50kb");
        return;
      } else if (e.target.files[0].size > 1024 * 1024) {
        setPIssueTextUpload("file size is greater than 1mb");
        return;
      } else if (
        extend == "JPG" ||
        extend == "PNG" ||
        extend == "JPEG" ||
        extend == "jpg" ||
        extend == "png" ||
        extend == "jpeg"
      ) {
        setFileSelected(true);
        if (
          certificateFile !== undefined &&
          Array.isArray(certificateFile) &&
          certificateFile.length > 0
        ) {
          setTempCfile((cFile) => [...cFile, e.target.files[0].name]);
        } else {
          setTempCfile([e.target.files[0].name]);
        }
        uploadDocsToServer(e.target.files[0], fileUploadType);
      } else {
        setPIssueTextUpload(
          "Profile photo Only images with .jpg, .jpeg, .png extension are allowed."
        );
        return;
      }
    }
  };

  async function uploadDocsToServer(file, fileUploadType) {
    setSpin(true);

    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.selbatchuser.eid,
        type: fileUploadType,
        filetype: file.type,
        filename: file.name,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      let response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PRESIGNED_URL,
        bodyParam
      );
      fileUpload(file, response);
    } catch (error) {
      setSpin(false);
      console.log("getCategoryError", error);
    }
  }

  async function fileUpload(file, url) {
    setSpin(true);
    await axios
      .put(url, file, { headers: { "Content-Type": file.type } })
      .then(() => {
        setSpin(false);
      })
      .catch((err) => {
        console.error(err);
        setSpin(false);
      });
  }

  async function getUserMarkscardCertificate() {
    setLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.selbatchuser.eid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      let MarksCardCertificate = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getUserMarkscardCertificate",
        bodyParam
      );

      setFirstName(MarksCardCertificate[0].UNAME);
      setLastName(MarksCardCertificate[0].LASTNAME);

      setEmail(MarksCardCertificate[0].EMAILID);
      setPhoneNo(MarksCardCertificate[0].CNO);
      setAddress(MarksCardCertificate[0].ADS);
      setDob(MarksCardCertificate[0].DOB);
      setHigestDegree(MarksCardCertificate[0].HIGHDEG);
      setCountryCode(MarksCardCertificate[0].COUNTRYCODE);
      setPassportNo(MarksCardCertificate[0].PASSPORTNO);
      setGovtId(MarksCardCertificate[0].OTHGOVID);
      setWorkExperience(MarksCardCertificate[0].WORKEXP);
      setProfilePicName(MarksCardCertificate[0].PROFILEPICNAME);
      setDocs(MarksCardCertificate[0].DOCUMENTNAME);

      if (
        MarksCardCertificate !== undefined &&
        Array.isArray(MarksCardCertificate[0].MARKSCARD)
      ) {
        setMarkFile(MarksCardCertificate[0].MARKSCARD);
        setTempMfile(MarksCardCertificate[0].MARKSCARD);
      }
      if (
        MarksCardCertificate !== undefined &&
        Array.isArray(MarksCardCertificate[0].CERTIFICATE)
      ) {
        setCertificateFile(MarksCardCertificate[0].CERTIFICATE);
        setTempCfile(MarksCardCertificate[0].CERTIFICATE);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const removeMarksCard = (DeleteMarks) => {
    let deleteMessageIndex = marksFile.indexOf(DeleteMarks);
    setMarkFile([
      ...marksFile.slice(0, deleteMessageIndex),
      ...marksFile.slice(deleteMessageIndex + 1),
    ]);
  };

  const removeCertificate = (deleteCertificate) => {
    let deleteMessageIndex = certificateFile.indexOf(deleteCertificate);
    setCertificateFile([
      ...certificateFile.slice(0, deleteMessageIndex),
      ...certificateFile.slice(deleteMessageIndex + 1),
    ]);
  };

  async function getuserprogress() {
    setLoading(true);

    const { eid } = userDetails.selbatchuser;

    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: eid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      let topic = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_USER_PROGRESS,
        bodyParam
      );

      if (!topic) {
        let obj = { myTopics: [] };

        convertData(obj);
      } else {
        // If needed, add additional checks before accessing topic[userDetails.curprgcou.bpid]

        setUserTotalProgress(topic[userDetails.curprgcou.bpid]);
        getSemWiseData('0', '1', topic[userDetails.curprgcou.bpid]);
        var topicids = [];
        if (topic[userDetails.curprgcou.bpid] != undefined) {
          topicids.push(userDetails.curprgcou.bpid);
        }

        if (topicids.length == 0) {
          let obj = { myTopics: [] };
          convertData(obj);
        } else {
          getMyTopics(topicids, topic);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  async function getSemWiseData(course, sem, topic) {

    const allsem = [["Semester", "Hours per Day"]];
    const coursearr = [];
    const arraa = [];
    const topids = [];
    let html = 0, video = 0, minis = 0, inter = 0;
    let tot1 = 0, tot2 = 0, tot3 = 0, tot4 = 0, tot5 = 0, tot6 = 0;
    let ctot1 = 0, ctot2 = 0, ctot3 = 0, ctot4 = 0, ctot5 = 0, ctot6 = 0;
    const objects = topic?.courses;

    for (const key in objects) {
      if (objects.hasOwnProperty(key)) {
        const obj = objects[key];
        if (sem == obj.td.sem) {

          if (obj.total) {

            if (course == '0') {
              html += obj.total?.tcHtmlObj + obj.total?.tcPdfObj;
              video += obj.total?.tcVideoObj + obj.total?.tcVimeoObj + obj.total?.tcYoutubeObj + obj.total?.tcAudioObj;
              minis += obj.total?.tcQuizObj + obj.total?.tcSQuizObj;
              inter += obj.total?.tcInteractivityObj;
            } else {

              if (course == obj?.td?.bcid) {
                html += obj.total?.tcHtmlObj + obj.total?.tcPdfObj;
                video += obj.total?.tcVideoObj + obj.total?.tcVimeoObj + obj.total?.tcYoutubeObj + obj.total?.tcAudioObj;
                minis += obj.total?.tcQuizObj + obj.total?.tcSQuizObj;
                inter += obj.total?.tcInteractivityObj;
              }
            }
          }
          topids.push(obj.td.bcid);
          arraa.push(obj.td);
          coursearr.push({ "bcid": obj.td.bcid, "ttitle": obj.td.ttitle });
        }
        if (obj?.td?.sem == '1' && obj.total) {
          ctot1 = obj.total.tcobj + ctot1;
          tot1 = obj.total.tobj + tot1;

        }
        if (obj?.td?.sem == '2' && obj.total) {
          ctot2 = obj.total.tcobj + ctot2;
          tot2 = obj.total.tobj + tot2;
        }
        if (obj?.td?.sem == '3' && obj.total) {
          ctot3 = obj.total.tcobj + ctot1;
          tot3 = obj.total.tobj + tot1;
        }
        if (obj?.td?.sem == '4' && obj.total) {
          ctot4 = obj.total.tcobj + ctot4;
          tot4 = obj.total.tobj + tot4;
        }
        if (obj?.td?.sem == '5' && obj.total) {
          ctot5 = obj.total.tcobj + ctot5;
          tot5 = obj.total.tobj + tot5;
        }
        if (obj?.td?.sem == '6' && obj.total) {
          ctot6 = obj.total.tcobj + ctot6;
          tot6 = obj.total.tobj + tot6;
        }

      }
    }

    let sem1 = ctot1 == undefined || ctot1 == 0 ? 0 : (ctot1 / tot1) * 100;
    let sem2 = ctot2 == undefined || ctot2 == 0 ? 0 : (ctot2 / tot2) * 100;
    let sem3 = ctot3 == undefined || ctot3 == 0 ? 0 : (ctot3 / tot3) * 100;
    let sem4 = ctot4 == undefined || ctot4 == 0 ? 0 : (ctot4 / tot4) * 100;
    let sem5 = ctot5 == undefined || ctot5 == 0 ? 0 : (ctot5 / tot5) * 100;
    let sem6 = ctot6 == undefined || ctot6 == 0 ? 0 : (ctot6 / tot6) * 100;
    allsem.push(["Semester 1", sem1]);
    allsem.push(["Semester 2", sem2]);
    allsem.push(["Semester 3", sem3]);
    allsem.push(["Semester 4", sem4]);
    allsem.push(["Semester 5", sem5]);
    allsem.push(["Semester 6", sem6]);

    setDataBar([
      ["Element", "Density", { role: "style" }, { sourceColumn: 0, role: "annotation", type: "string", calc: "stringify" }],
      ["Audio Visual Learning material", video, "#7C84E0", null],
      ["E-Resources", html, "#917CE0", null],
      ["Live Discussion Forum", inter, "#AF7CE0", null],
      ["Assignment", minis, "color: #CE7CE0", null],
    ]);
    setCcount(coursearr?.length);
    setCourses(arraa);
    setConCourses(arraa);
    setLearnerCourses(arraa);
    setSemData(allsem);

    await getReports(topids, coursearr);
  }

  async function getReports(topicIDs, coursearr) {

    setLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        bpid: userDetails.curprgcou.bpid,
        topicids: topicIDs,
        eid: userDetails?.selbatchuser?.eid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    const datab2 = [["Subject", "Assessment 1", "Assessment 2", "Assessment 3", "Assessment 4"]];

    try {
      const response = await API.post(config.aws_cloud_logic_custom_name, Constants.GET_MY_REPORTS, bodyParam);

      if (!response) {
        setLoading(false);
        return;
      }

      for (let i = 0; i < response.length; i++) {

        if (userDetails?.selbatchuser?.eid == response[i].EID) {
          let obj = {};
          obj.id = i;

          obj.col1 = response[i].UNAME;
          if (
            response[i].USN !== undefined &&
            response[i].USN[userDetails.curprgcou.bpid] !== undefined
          ) {
            obj.col2 = response[i].USN[userDetails.curprgcou.bpid].usn;
          }
          obj.EMAILID = response[i].EMAILID;
          obj.EID = response[i].EID;
          obj.country = response[i].country;
          obj.totalActiveModule = response[i].totalActiveModule;
          obj.totalCompletedModule = response[i].totalCompletedModule;
          obj.lastactive = moment(response[i].LA).format('DD MMM YYYY hh:mm A');
          obj.tlogintime = response[i].TLOGINTIME;
          if (response[i].TLOGINTIME === undefined) {
            obj.tlogintime = "--:-- Hrs";
          } else {
            const hours = Math.floor(response[i].TLOGINTIME / 60);
            const minutes = response[i].TLOGINTIME % 60;
            const formattedHours = formatTime(hours);
            const formattedMinutes = formatTime(minutes);
            obj.tlogintime = formattedHours + ":" + formattedMinutes + " Hrs";
          }
          if (response[i].totalTimespentSum === undefined || response[i].totalTimespentSum == 0) {
            obj.totalTimespentSum = "--:-- Hrs";
          } else {
            const roundedNumber = Math.ceil(response[i].totalTimespentSum);
            const hours = Math.floor(roundedNumber / 60);
            const minutes = roundedNumber % 60;
            const formattedHours = formatTime(hours);
            const formattedMinutes = formatTime(minutes);
            obj.totalTimespentSum = formattedHours + ":" + formattedMinutes + " Hrs";
          }
          obj.totalCompletedModulePercent = parseFloat(response[i].totalCompletedModulePercent).toFixed(
            2
          );
          obj.totalMiniassignments = response[i].totalMiniassignments;
          obj.totalCompletedMiniassignment = response[i].totalCompletedMiniassignment;
          obj.totalminipercent = parseFloat((response[i].totalCompletedMiniassignment / response[i].totalMiniassignments) * 100).toFixed(2)
          obj.miniAverageScore =
            response[i].miniAverageScore != null
              ? parseFloat(response[i].miniAverageScore).toFixed(2)
              : "Not graded";
          obj.assesment1 =
            response[i].assesment1 != null
              ? parseFloat(response[i].assesment1).toFixed(2)
              : "Not Attended";
          obj.assesment2 =
            response[i].assesment2 != null
              ? parseFloat(response[i].assesment2).toFixed(2)
              : "Not Attended";
          obj.assesment3 =
            response[i].assesment3 != null
              ? parseFloat(response[i].assesment3).toFixed(2)
              : "Not Attended";
          let average = [];
          average.push(response[i].assesment1);
          average.push(response[i].assesment2);
          average.push(response[i].assesment3);
          average.sort();
          obj.assesmentavg = parseFloat((average[0] + average[1]) / 25).toFixed(2);
          obj.cassesment = response[i].cassesment;
          obj.sempass = response[i].sempass;
          obj.semper = response[i].semper;
          obj.courseCleared = response[i].courseCleared;
          obj.examAttended = response[i].examAttended;

          for (let s = 0; s < coursearr.length; s++) {
            if (obj.cassesment != undefined && obj.cassesment[coursearr[s].bcid]) {
              if (Object.keys(obj.cassesment[coursearr[s].bcid]).length > 0) {
                var ll = [];
                for (const [key, value] of Object.entries(obj.cassesment[coursearr[s].bcid])) {
                  ll.push(value);
                }

                let t1 = ll[0] != undefined ? ll[0] : 0;
                let t2 = ll[1] != undefined ? ll[1] : 0;
                let t3 = ll[2] != undefined ? ll[2] : 0;
                let t4 = ll[3] != undefined ? ll[3] : 0;
                datab2.push([coursearr[s].ttitle, t1, t2, t3, t4]);
              }
            } else {
              datab2.push([coursearr[s].ttitle, 0, 0, 0, 0]);
            }
          }

          setRows(obj);
        }
      }

      setDataBar2(datab2);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }
  const formatTime = (time) => {
    return `${time < 10 ? '0' : ''}${time}`;
  };
  async function getMyTopics(topicids, topic) {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.selbatchuser.eid,
        topicids: topicids,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const topic2 = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_MY_TOPICS,
        bodyParam
      );
      let data = topic[userDetails.curprgcou.bpid];
      topic2.myTopics = [];
      let obj = {
        tid: data.pd.pid,
        bpid: data.pd.bpid,

        pname: data.pd.ptitle,
      };
      topic2.myTopics.push(obj);
      topic2.progress = data;

      convertData(topic2);
      getAllQuizScores();
    } catch (error) {
      console.error(error);
    }
  }

  async function getAllQuizScores() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.selbatchuser.eid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_ALL_QUIZ_SCORE,
        bodyParam
      );
    } catch (error) {
      console.error(error);
    }
  }

  function exam(data) {
    let temp = [];
    for (const [value] of Object.entries(data)) {
      temp.push(value);
    }

    setExamData(temp[0]);

  }


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function convertData(data) {
    let myapplication = data;
    let tobj = 0,
      tcobj = 0,
      tVideoObj = 0,
      tcVideoObj = 0,
      tAudioObj = 0,
      tcAudioObj = 0,
      tPdfObj = 0,
      tcPdfObj = 0,
      tHtmlObj = 0,
      tcHtmlObj = 0,
      tInteractivityObj = 0,
      tcInteractivityObj = 0,
      tYoutubeObj = 0,
      tcYoutubeObj = 0,
      tVimeoObj = 0,
      tcVimeoObj = 0,
      tQuizObj = 0,
      tcQuizObj = 0;
    let tobjprogress, avprogress, dprogress, kprogress;

    if (myapplication.progress != undefined) {
      let object1 = myapplication.progress.courses;

      for (const [value] of Object.entries(object1)) {

        if (value.total != undefined) {
          tobj = tobj + value.total.tobj;
          tcobj = tcobj + value.total.tobj;
          tVideoObj = tVideoObj + value.total.tVideoObj;
          tcVideoObj = tcVideoObj + value.total.tcVideoObj;
          tAudioObj = tAudioObj + value.total.tAudioObj;
          tcAudioObj = tcAudioObj + value.total.tcAudioObj;
          tPdfObj = tPdfObj + value.total.tPdfObj;
          tcPdfObj = tcPdfObj + value.total.tcPdfObj;
          tHtmlObj = tHtmlObj + value.total.tHtmlObj;
          tcHtmlObj = tcHtmlObj + value.total.tcHtmlObj;
          tInteractivityObj = tInteractivityObj + value.total.tInteractivityObj;
          tcInteractivityObj =
            tcInteractivityObj + value.total.tcInteractivityObj;
          tYoutubeObj = tYoutubeObj + value.total.tYoutubeObj;
          tcYoutubeObj = tcYoutubeObj + value.total.tcYoutubeObj;
          tVimeoObj = tVimeoObj + value.total.tVimeoObj;
          tcVimeoObj = tcVimeoObj + value.total.tcVimeoObj;
          tQuizObj = tQuizObj + value.total.tQuizObj;
          tcQuizObj = tcQuizObj + value.total.tcQuizObj;
        }

        tobjprogress = tobj != 0 ? (tcobj * 100) / tobj : 0;
        let tav = tVideoObj + tAudioObj + tYoutubeObj + tVimeoObj;
        let tcav = tcVideoObj + tcAudioObj + tcYoutubeObj + tcVimeoObj;
        avprogress = tav != 0 ? (tcav * 100) / tav : 0;
        let td = tPdfObj + tHtmlObj + tInteractivityObj;
        let tcd = tcPdfObj + tcHtmlObj + tcInteractivityObj;
        dprogress = td != 0 ? (tcd * 100) / td : 0;
        kprogress = tQuizObj != 0 ? (tcQuizObj * 100) / tQuizObj : 0;
      }
    }

    if (myapplication.progress != undefined) {
      if (myapplication.myTopics[0] != undefined) {
        myapplication.myTopics[0].vprog = avprogress.toFixed(2);
        myapplication.myTopics[0].dprog = dprogress.toFixed(2);
        myapplication.myTopics[0].sqprog = kprogress.toFixed(2);
        myapplication.myTopics[0].progress = parseInt(tobjprogress.toFixed(2));

        let obj = {
          pid: myapplication.myTopics[0].tid,
          bpid: myapplication.myTopics[0].bpid,
          pname: myapplication.myTopics[0].pname,
          va: avprogress.toFixed(2),
          dc: dprogress.toFixed(2),
          kc: kprogress.toFixed(2),
          ovp: parseInt(tobjprogress.toFixed(2)),
        };
        setUserData(obj);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }

  function breadcrumb() {
    let sdata = { ...userDetails };
    let temp = [...sdata.breadcrumb];

    temp[3] = {
      name: sdata.selbatchuser.name,
      path: "/userprogress",
    };

    sdata.breadcrumb = temp;

    dispatch(awsSignIn(sdata));
  }
  const onChangeHandlerSem = event => {

    setSeme(event.target.value);
    getSemWiseData("0", event.target.value, userTotalProgress);
  };
  const onChangeHandlerCourse = event => {
    setSemCourse(event.target.value);
    getSemWiseData(event.target.value, seme, userTotalProgress);
  };
  // Data for tabs
  const panes = [
    {
      menuItem: "Overview",
      render: () => {
        return <Tab.Pane>{overview()}</Tab.Pane>;
      },
    },
    {
      menuItem: "Progress",
      render: () => {
        return <Tab.Pane>{progress()}</Tab.Pane>;
      },
    },
    {
      menuItem: "Documents",
      render: () => {
        return <Tab.Pane>{documents()}</Tab.Pane>;
      },
    },
  ];


  const options = {
    pieHole: 0.4,
    is3D: false,
    colors: ['#6d75cf', '#957cf7', '#cb76dd', '#76b9df'],
  };



  const optionsBar = {
    width: 500,
    height: 300,
    bar: { groupWidth: "95%" },
    legend: { position: "none" },
  };



  const optionsBar2 = {
    chart: {
      subtitle: "Subject and Assessment performance",

    },
    colors: ['#6d75cf', '#957cf7', '#cb76dd', '#76b9df'],
  };



  const handleChangeSem = (event) => {
    setSemester(event.target.value);
  };
  const handleChangeLearnerSem = (event) => {
    setLearnerSemester(event.target.value);
    getLearnerSemWiseData("0", event.target.value, userTotalProgress, 'learner');
  };
  const handleConChangeSem = (event) => {
    setConSemester(event.target.value);
    getConSemWiseData("0", event.target.value, userTotalProgress, 'cons');
  };
  const handleChangeAssessSem = (event) => {
    setAssessSemester(event.target.value);
    getConSemWiseData("0", event.target.value, userTotalProgress, 'assessment');
  };
  async function getConSemWiseData(course, sem, topic, type) {

    const coursearr = [];
    const arraa = [];
    const topids = [];

    const objects = topic?.courses;

    for (const key in objects) {
      if (objects.hasOwnProperty(key)) {
        const obj = objects[key];
        if (sem == obj.td.sem) {

          topids.push(obj.td.bcid);
          arraa.push(obj.td);
          coursearr.push({ "bcid": obj.td.bcid, "ttitle": obj.td.ttitle });
        }
      }
    }
    if (type == 'cons') {
      setConCourses(coursearr);
      await getConReports(topids, coursearr);
    }
    if (type == 'assessment') {
      await getAssessReports(topids, coursearr);
    }
    if (type == 'learner') {
      setLearnerCourses(coursearr);

    }
  }
  async function handleChangeConCourse(event) {
    let cou = event.target.value;
    let qdata = [...conCourses];
    var filteredBcids = qdata
      .filter((item) => item.bcid === cou)
      .map((item) => item.bcid);

    setConSemCourse(event.target.value);
    await getConReports(filteredBcids, conCourses);
  };
  async function onChangeHandlerLearnerCourse(event) {
    let cou = event.target.value;
    let qdata = [...learnerCourses];
    var filteredBcids = qdata
      .filter((item) => item.bcid === cou)
      .map((item) => item.bcid);

    setLearnerSemCourse(event.target.value);
    getLearnerSemWiseData(cou, learnerSemester, userTotalProgress, 'learner');
  };
  const headers = ['Total_Active_Modules', 'Total_Active_Modules_Completed', 'Assignments_Submitted', 'Average_Assignment_Marks', 'Semester_Percentile'];

  const downloadCsv = (data, filename) => {

    var arr = [];
    arr.push({
      "Total Active Modules": data.totalActiveModule,
      "Total Completed Modules": data.totalCompletedModule,
      "Total Mini Assignments": data.totalCompletedMiniassignment,
      "averageassignments": data.miniAverageScore,
      "Total_Courses_Cleared": data.courseCleared,
      "Total_Courses_Exam_Attended": data.examAttended,
      "sempass": data.sempass
    })

    const csvContent =
      "data:text/csv;charset=utf-8," +
      [headers.join(",")].concat(arr.map(row => Object.values(row).join(","))).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename || "data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  async function getConReports(topicIDs, coursearr) {
    setLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        bpid: userDetails.curprgcou.bpid,
        topicids: topicIDs,
        eid: userDetails?.selbatchuser?.eid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(config.aws_cloud_logic_custom_name, Constants.GET_MY_REPORTS, bodyParam);

      if (!response) {
        setLoading(false);
        return;
      }

      for (let i = 0; i < response.length; i++) {

        if (userDetails?.selbatchuser?.eid == response[i].EID) {
          let obj = {};
          obj.id = i;

          obj.col1 = response[i].UNAME;
          if (
            response[i].USN !== undefined &&
            response[i].USN[userDetails.curprgcou.bpid] !== undefined
          ) {
            obj.col2 = response[i].USN[userDetails.curprgcou.bpid].usn;
          }
          obj.EMAILID = response[i].EMAILID;
          obj.EID = response[i].EID;
          obj.country = response[i].country;
          obj.totalActiveModule = response[i].totalActiveModule;
          obj.totalCompletedModule = response[i].totalCompletedModule;
          obj.lastactive = moment(response[i].LA).format('DD MMM YYYY hh:mm A');
          obj.tlogintime = response[i].TLOGINTIME;
          if (response[i].TLOGINTIME === undefined) {
            obj.tlogintime = "--:-- Hrs";
          } else {
            const hours = Math.floor(response[i].TLOGINTIME / 60);
            const minutes = response[i].TLOGINTIME % 60;
            const formattedHours = formatTime(hours);
            const formattedMinutes = formatTime(minutes);
            obj.tlogintime = formattedHours + ":" + formattedMinutes + " Hrs";
          }
          obj.totalCompletedModulePercent = parseFloat(response[i].totalCompletedModulePercent).toFixed(
            2
          );
          obj.totalMiniassignments = response[i].totalMiniassignments;
          obj.totalCompletedMiniassignment = response[i].totalCompletedMiniassignment;
          obj.totalminipercent = parseFloat((response[i].totalCompletedMiniassignment / response[i].totalMiniassignments) * 100).toFixed(2)
          obj.miniAverageScore =
            response[i].miniAverageScore != null
              ? parseFloat(response[i].miniAverageScore).toFixed(2)
              : "Not graded";
          obj.assesment1 =
            response[i].assesment1 != null
              ? parseFloat(response[i].assesment1).toFixed(2)
              : "Not Attended";
          obj.assesment2 =
            response[i].assesment2 != null
              ? parseFloat(response[i].assesment2).toFixed(2)
              : "Not Attended";
          obj.assesment3 =
            response[i].assesment3 != null
              ? parseFloat(response[i].assesment3).toFixed(2)
              : "Not Attended";
          let average = [];
          average.push(response[i].assesment1);
          average.push(response[i].assesment2);
          average.push(response[i].assesment3);
          average.sort();
          obj.assesmentavg = parseFloat((average[0] + average[1]) / 25).toFixed(2);
          obj.cassesment = response[i].cassesment;
          obj.sempass = response[i].sempass;
          obj.semper = response[i].semper;
          obj.courseCleared = response[i].courseCleared;
          obj.examAttended = response[i].examAttended;

          setRows(obj);
        }
      }

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }
  async function getAssessReports(topicIDs, coursearr) {
    setLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        bpid: userDetails.curprgcou.bpid,
        topicids: topicIDs,
        eid: userDetails?.selbatchuser?.eid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    const datab2 = [["Subject", "Assessment 1", "Assessment 2", "Assessment 3", "Assessment 4"]];

    try {
      const response = await API.post(config.aws_cloud_logic_custom_name, Constants.GET_MY_REPORTS, bodyParam);

      if (!response) {
        setLoading(false);
        return;
      }

      for (let i = 0; i < response.length; i++) {

        if (userDetails?.selbatchuser?.eid == response[i].EID) {
          let obj = {};
          obj.id = i;


          obj.cassesment = response[i].cassesment;

          for (let s = 0; s < coursearr.length; s++) {
            if (obj.cassesment != undefined && obj.cassesment[coursearr[s].bcid]) {
              if (Object.keys(obj.cassesment[coursearr[s].bcid]).length > 0) {
                var ll = [];
                for (const [key, value] of Object.entries(obj.cassesment[coursearr[s].bcid])) {
                  ll.push(value);
                }

                let t1 = ll[0] != undefined ? ll[0] : 0;
                let t2 = ll[1] != undefined ? ll[1] : 0;
                let t3 = ll[2] != undefined ? ll[2] : 0;
                let t4 = ll[3] != undefined ? ll[3] : 0;
                datab2.push([coursearr[s].ttitle, t1, t2, t3, t4]);
              }
            } else {
              datab2.push([coursearr[s].ttitle, 0, 0, 0, 0]);
            }
          }
        }
      }

      setDataBar2(datab2);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }
  async function getLearnerSemWiseData(course, sem, topic) {

    const coursearr = [];
    const arraa = [];
    const topids = [];
    let html = 0, video = 0, minis = 0, inter = 0;

    const objects = topic?.courses;

    for (const key in objects) {
      if (objects.hasOwnProperty(key)) {
        const obj = objects[key];
        if (sem == obj.td.sem) {

          if (obj.total) {

            if (course == '0') {
              html += obj.total?.tcHtmlObj + obj.total?.tcPdfObj;
              video += obj.total?.tcVideoObj + obj.total?.tcVimeoObj + obj.total?.tcYoutubeObj + obj.total?.tcAudioObj;
              minis += obj.total?.tcQuizObj + obj.total?.tcSQuizObj;
              inter += obj.total?.tcInteractivityObj;
            } else {

              if (course == obj?.td?.bcid) {
                html += obj.total?.tcHtmlObj + obj.total?.tcPdfObj;
                video += obj.total?.tcVideoObj + obj.total?.tcVimeoObj + obj.total?.tcYoutubeObj + obj.total?.tcAudioObj;
                minis += obj.total?.tcQuizObj + obj.total?.tcSQuizObj;
                inter += obj.total?.tcInteractivityObj;
              }
            }
          }
          topids.push(obj.td.bcid);
          arraa.push(obj.td);
          coursearr.push({ "bcid": obj.td.bcid, "ttitle": obj.td.ttitle });
        }

      }
    }


    setDataBar([
      ["Element", "Density", { role: "style" }, { sourceColumn: 0, role: "annotation", type: "string", calc: "stringify" }],
      ["Audio Visual Learning material", video, "#7C84E0", null],
      ["E-Resources", html, "#917CE0", null],
      ["Live Discussion Forum", inter, "#AF7CE0", null],
      ["Assignment", minis, "color: #CE7CE0", null],
    ]);

    setLearnerCourses(arraa);

  }
  function overview() {
    return (
      <>
        <div className={User.topsection} style={{ gap: "2rem", display: "grid", marginBottom: "2.5rem", gridTemplateColumns: "32% 38% 26%", alignItems: "normal" }}>
          <Paper style={{ boxShadow: "#dedede 0px 0px 4px 1px", overflow: "hidden", display: "flex", alignItems: "center" }}>
            <div className={User.container} style={{ display: 'flex', alignItems: 'center', width: "100%", borderLeft: "4px solid #047DC2", height: "100px" }}>
              <div style={{ width: '60', height: '60', marginRight: '20px' }}>
                <Avatar name="user" />
              </div>
              <div className={User.percent}>
                {loading === false ? (
                  <div>
                    <div className={User.field}>
                      <h2 className={User.title} style={{ width: "5rem", textAlign: "right" }}>Name : </h2>
                      <h2 className={User.title}>{userDetails?.selbatchuser?.name}</h2>
                    </div>
                    <div className={User.field}>
                      <h2 className={User.title} style={{ width: "5rem", textAlign: "right" }}>Email : </h2>
                      <h2 className={User.title}>{userDetails?.selbatchuser?.email}</h2>
                    </div>
                    <div className={User.field}>
                      <h2 className={User.title} style={{ width: "5rem", textAlign: "right" }}>USN : </h2>
                      <h2 className={User.title}>{userDetails?.selbatchuser?.usn}</h2>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center", margin: "auto",
                    }}
                  >
                    <Skeleton variant="rect" width={200} height={28} />{" "}
                  </div>
                )}
              </div>
            </div>
          </Paper>
          <Paper style={{ boxShadow: "#dedede 0px 0px 4px 1px", overflow: "hidden", display: "flex", alignItems: "center", }}>
            <div className={User.container} style={{ display: 'flex', alignItems: 'center', width: "100%", borderLeft: "4px solid #26A700", justifyContent: "center", height: "100px" }}>
              <div>
                {loading === false ? (
                  <div style={{ display: 'flex', fontFamily: "nunito" }}>
                    <div style={{ display: "flex", alignItems: "center", }}>
                      <img src={TLT} alt="Icon" style={{ width: "4rem" }} />
                    </div>
                    <div style={{ borderRight: '1px solid lightgrey', padding: '5px', textAlign: "left", display: "grid", gap: "6px" }}>
                      <p style={{ fontSize: '14px', color: "#26A700" }}>Total Login Time </p>
                      <p style={{ fontSize: '18px', fontWeight: "700", color: "black" }}>{rows.tlogintime}</p>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img src={TPT} alt="Icon" style={{ width: "4rem" }} />
                    </div>
                    <div style={{ padding: '5px', textAlign: "left", display: "grid", gap: "6px" }}>
                      <p style={{ fontSize: '16px', color: "#2f78d8" }}>Total Practice Time</p>
                      <p style={{ fontSize: '18px', fontWeight: "700", color: "black" }}>{rows.totalTimespentSum}</p>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "auto",
                    }}
                  >
                    <Skeleton variant="rect" width={250} height={28} />{" "}
                  </div>
                )}
              </div>
            </div>
          </Paper>
          <Paper style={{ boxShadow: "#dedede 0px 0px 4px 1px", overflow: "hidden", display: "flex", alignItems: "center" }}>
            <div className={User.container} style={{ display: 'flex', alignItems: 'center', width: "100%", borderLeft: "4px solid #B3B300", height: "100px" }}>
              <div className={User.percent} style={{ padding: 0 }}>
                {loading === false ? (
                  <div style={{ display: 'flex' }}>
                    <div style={{ display: "flex", alignItems: "center", }}>
                      <img src={LL} alt="Icon" style={{ width: "4rem" }} />
                    </div>
                    <div style={{ padding: '5px', textAlign: "left", display: "grid", gap: "6px" }}>
                      <p style={{ fontSize: '16px', color: "#B3B300" }}>Last Login</p>
                      <p style={{ fontSize: '18px', fontWeight: "700", color: "black" }}>{rows.lastactive}</p>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "auto",
                    }}
                  >
                    <Skeleton variant="rect" width={200} height={28} />{" "}
                  </div>
                )}
              </div>
            </div>
          </Paper>
        </div>

        <div className={User.topsection} style={{ gap: "2rem", display: "grid", marginBottom: "2.5rem", gridTemplateColumns: "32% 38% 26%", alignItems: "normal", marginTop: '10px' }}>
          <Paper style={{ boxShadow: "#dedede 0px 0px 4px 1px", display: "flex", alignItems: "center" }}>
            <div className={User.container} style={{ backgroundColor: "#CBDFEE", width: "100%", height: "100px" }}>
              <h3 className={User.percent}>
                {loading === false ? (
                  <div className={User.field} style={{ display: 'block', textAlign: 'left' }}>
                    <h3 className={User.select}>
                      Program
                    </h3>
                    <select style={{ border: "none", width: "100%", boxShadow: "0px 0px 5px 0px lightgrey", background: "white", height: "30px", margin: "10px 0 0 0", fontSize: "15px", paddingLeft: "10px", fontWeight: "200px" }}
                      name="sem" id="sem" required >

                      <option value="1">{userDetails.curprgcou?.pname}</option>

                    </select>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center", margin: "auto",
                    }}
                  >
                    <Skeleton variant="rect" width={200} height={28} />{" "}
                  </div>
                )}{" "}
              </h3>
            </div>
          </Paper>
          <Paper style={{ boxShadow: "#dedede 0px 0px 4px 1px", display: "flex", alignItems: "center" }}>
            <div className={User.container} style={{ width: "100%", backgroundColor: "#f8f9fa", height: "100px" }}>
              <h3 className={User.percent}>
                {loading === false ? (
                  <div className={User.field} style={{ display: 'block', textAlign: 'left' }}>
                    <h3 className={User.select}>
                      Semester
                    </h3>
                    <select style={{ border: "none", width: "100%", boxShadow: "0px 0px 5px 0px lightgrey", background: "white", height: "30px", margin: "10px 0 0 0", fontSize: "15px", paddingLeft: "10px", fontWeight: "200px" }}
                      name="sem" id="sem" value={seme} required onChange={onChangeHandlerSem}>

                      <option value="1">First Semester</option>
                      <option value="2">Second Semester</option>
                      <option value="3">Third Semester</option>
                      <option value="4">Fourth Semester</option>
                      <option value="5">Fifth Semester</option>
                      <option value="6">Sixth Semester</option>
                    </select>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center", margin: "auto",
                    }}
                  >
                    <Skeleton variant="rect" width={250} height={28} />{" "}
                  </div>
                )}{" "}
              </h3>
            </div>
          </Paper>
          <Paper style={{ boxShadow: "#dedede 0px 0px 4px 1px", display: "flex", alignItems: "center" }}>
            <div className={User.container} style={{ width: "100%", backgroundColor: "#f8f9fa", height: "100px" }}>

              <h3 className={User.percent}>
                {loading === false ? (
                  <div className={User.field} style={{ display: 'block', textAlign: 'left' }}>
                    <h3 className={User.select}>
                      Course
                    </h3>
                    <select
                      style={{
                        border: "none",
                        width: "100%",
                        boxShadow: "0px 0px 5px 0px lightgrey",
                        background: "white",
                        height: "30px",
                        margin: "10px 0 0 0",
                        fontSize: "15px",
                        paddingLeft: "10px",
                        fontWeight: "200px"
                      }}
                      name="sem"
                      id="sem"
                      required
                      value={semCourse}
                      onChange={onChangeHandlerCourse}
                    >
                      <option value="0">All</option>

                      {courses.map((item) => (
                        <option value={item.bcid}>{item.ttitle}</option>
                      ))}
                    </select>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center", margin: "auto",
                    }}
                  >
                    <Skeleton variant="rect" width={200} height={28} />{" "}
                  </div>
                )}
              </h3>
            </div>
          </Paper>
        </div>

        <div className={User.topsection} style={{ margin: '20px 0px', gap: "2rem", display: "grid", marginBottom: "2.5rem", gridTemplateColumns: "49% 49% ", alignItems: "normal" }}>
          <Paper style={{ boxShadow: "#dedede 0px 0px 4px 1px", display: "flex", alignItems: "center", overflow: "auto" }}>
            <div className={User.container} style={{ display: 'flex', alignItems: 'center', width: "580px", height: '370px' }}>
              <div className={User.percent} style={{ width: "100%", height: "100%", overflow: "hidden" }}>
                {loading === false ? (
                  <div className={User.field} style={{ display: 'block', textAlign: 'left', }}>
                    <h3 className={User.select}>
                      Overall Program Analytics
                    </h3>
                    <div className={classes.pieChartStyle}>
                      <Chart
                        chartType="PieChart"
                        width="100%"
                        height="100%"
                        data={semData}
                        options={options}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Skeleton variant="rect" width={50} height={28} />{" "}
                  </div>
                )}
              </div>
            </div>
          </Paper>

          <Paper style={{ boxShadow: "#dedede 0px 0px 4px 1px", display: "flex", alignItems: "center", overflow: "auto" }}>
            <div className={User.container} style={{ width: "580px", height: '370px' }}>
              <h3 className={User.percent} style={{ width: "100%", height: "100%" }}>
                {loading === false ? (
                  <div className={User.field} style={{ display: 'block', textAlign: 'left', }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                      <h3 className={User.select}>
                        Learner Engagement Analytics
                      </h3>
                      <Box style={{ minWidth: 300, display: "flex", gap: "1rem" }}>
                        <FormControl fullWidth className={classes.selectSection}>
                          <InputLabel id="demo-simple-select-label">Semester</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={learnerSemester}
                            label="Semester"
                            onChange={handleChangeLearnerSem}
                          >
                            <MenuItem value={1}>First Semester</MenuItem>
                            <MenuItem value={2}>Second Semester</MenuItem>
                            <MenuItem value={3}>Third Semester</MenuItem>
                            <MenuItem value={4}>Fourth Semester</MenuItem>
                            <MenuItem value={5}>Fifth Semester</MenuItem>
                            <MenuItem value={6}>sixth Semester</MenuItem>
                          </Select>
                        </FormControl>
                        <FormControl fullWidth className={classes.selectSection}>
                          <InputLabel id="demo-simple-select-label">Course Name</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={learnerSemCourse}
                            onChange={onChangeHandlerLearnerCourse}
                            label="Course Name"
                          >
                            <MenuItem value="0">All</MenuItem>
                            {learnerCourses.map((item) => (
                              <MenuItem value={item.bcid}>{item.ttitle}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </div>
                    <div className={classes.pieChartStyle}>
                      <Chart
                        chartType="BarChart"
                        width="100%"
                        height="100%"
                        data={dataBar}
                        options={optionsBar}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Skeleton variant="rect" width={50} height={28} />{" "}
                  </div>
                )}{" "}
              </h3>
            </div>
          </Paper>
        </div>

        <div>
          <Paper style={{ boxShadow: "#dedede 0px 0px 4px 1px", display: "flex", alignItems: "center" }}>
            <div className={User.container} style={{ width: "100%", height: '320px' }}>
              <h3 className={User.percent} style={{ width: "100%", height: "100%", overflow: "hidden" }}>
                {loading === false ? (
                  <div className={User.field} style={{ display: 'block', textAlign: 'left', height: "100%" }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                      <h3 className={User.select}>
                        Assessment Results
                      </h3>
                      <Box style={{ minWidth: 130, display: "flex", gap: "1rem" }}>
                        <FormControl fullWidth className={classes.selectSection}>
                          <InputLabel id="demo-simple-select-label">Semester</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={assessSemester}
                            label="Semester"
                            onChange={handleChangeAssessSem}
                          >
                            <MenuItem value={1}>First Semester</MenuItem>
                            <MenuItem value={2}>Second Semester</MenuItem>
                            <MenuItem value={3}>Third Semester</MenuItem>
                            <MenuItem value={4}>Fourth Semester</MenuItem>
                            <MenuItem value={5}>Fifth Semester</MenuItem>
                            <MenuItem value={6}>sixth Semester</MenuItem>

                          </Select>
                        </FormControl>
                      </Box>
                    </div>
                    <div className={classes.AssessmentpieChartStyle}>
                      <Chart
                        chartType="Bar"
                        width="97%"
                        height="100%"
                        data={dataBar2}
                        options={optionsBar2}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Skeleton variant="rect" width={50} height={28} />{" "}
                  </div>
                )}{" "}
              </h3>
            </div>
          </Paper>
        </div>


        <div style={{ marginTop: '20px' }}>
          <Paper style={{ boxShadow: "#dedede 0px 0px 4px 1px", }}>
            <div style={{ display: "flex", justifyContent: "space-between", padding: "1rem 1rem", alignItems: "center" }}>
              <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <h3 className={User.select}>
                  Consolidated Analytics
                </h3>
                <Box style={{ minWidth: 300, display: "flex", gap: "1rem" }}>
                  <FormControl fullWidth className={classes.selectSection}>
                    <InputLabel id="demo-simple-select-label">Semester</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={conSemester}
                      label="Semester"
                      onChange={handleConChangeSem}
                    >
                      <MenuItem value={1}>First Semester</MenuItem>
                      <MenuItem value={2}>Second Semester</MenuItem>
                      <MenuItem value={3}>Third Semester</MenuItem>
                      <MenuItem value={4}>Fourth Semester</MenuItem>
                      <MenuItem value={5}>Fifth Semester</MenuItem>
                      <MenuItem value={6}>sixth Semester</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl fullWidth className={classes.selectSection}>
                    <InputLabel id="demo-simple-select-label">Course Name</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={conSemCourse}
                      label="Course Name"
                      onChange={handleChangeConCourse}
                    >
                      <MenuItem value="0">All</MenuItem>
                      {conCourses.map((item) => (
                        <MenuItem value={item.bcid}>{item.ttitle}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <button className={User.primaryButton} onClick={() => downloadCsv(rows, 'user_data.csv')}>
                <DownloadIcon style={{ fontSize: "2rem!important" }} /> Export
              </button>
            </div>

            <div className={classes.containerMain} >
              {loading === false ? (
                <div className={User.field}>
                  <div style={{ padding: "2rem", textAlign: "center", position: "relative" }}>
                    <img src={OAAImage} alt="Overall Activity Analytics Image" style={{ width: "120rem" }} />

                    <div className={classes.containerMainCenter}>
                      <p>Semester <br /> Percentile <br /> <b>{rows.semper == undefined ? '--' : rows.semper}</b></p>
                      <div>{rows.sempass == undefined ? '--' : rows.sempass}</div>
                    </div>

                    <div style={{ position: "absolute", top: "14%", left: "66.5%" }}>
                      <div className={classes.containerMainWordingR}>
                        <p>{rows.examAttended == undefined ? '--' : rows.examAttended}</p><p>Total Courses Exam Attended</p>
                      </div>
                    </div>
                    <div style={{ position: "absolute", top: "46%", left: "72%" }}>
                      <div className={classes.containerMainWordingR}>
                        <p>{rows.courseCleared == undefined ? '--' : rows.courseCleared}</p><p>Total Courses Cleared</p>
                      </div>
                    </div>
                    <div style={{ position: "absolute", bottom: "13%", left: "65%" }}>
                      <div className={classes.containerMainWordingR} style={{ gap: "4rem" }}>

                        <div>
                          <p>{rows.totalCompletedModule} / {rows.totalActiveModule}</p>
                          <p style={{ color: "var(--button-background)", }}>{rows.totalCompletedModulePercent}%</p>
                        </div>
                        <div style={{ textAlign: "left" }}>
                          <p style={{ borderBottom: "1px solid lightgray", paddingBottom: "0.5rem" }}>Active Modules Completed</p>
                          <p>Total Active Modules</p>
                        </div>
                      </div>
                    </div>

                    <div style={{ position: "absolute", top: "12%", right: "64.5%" }}>
                      <div className={classes.containerMainWordingL}>
                        <p>Average Formative Assessment Marks</p>
                        <p>{rows.assesmentavg}/ 25</p>

                      </div>
                    </div>
                    <div style={{ position: "absolute", top: "46%", right: "70%" }}>
                      <div className={classes.containerMainWordingL}>
                        <p>Average Assignment Marks</p>
                        <p>{rows.miniAverageScore} / 12</p>

                      </div>
                    </div>
                    <div style={{ position: "absolute", bottom: "13%", right: "65.5%" }}>
                      <div className={classes.containerMainWordingL} style={{ gap: "4rem" }}>
                        <div style={{ textAlign: "left" }}>
                          <p style={{ borderBottom: "1px solid lightgray", paddingBottom: "0.5rem" }}>Assignments Submitted</p>
                          <p>Total Assignments in the course</p>
                        </div>

                        <div>
                          <p>{rows.totalCompletedMiniassignment} / {rows.totalMiniassignments}</p>
                          <p style={{ color: "var(--button-background)", }}>{rows.totalminipercent}%</p>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Skeleton variant="rect" width={50} height={28} />{" "}
                </div>
              )}{" "}
            </div>
            <div>
              {loading === false ? (
                <Paper style={{ boxShadow: "#dedede 0px 0px 4px 1px", overflow: "auto" }}>
                  <table className={User.customtable}>
                    <thead>
                      <tr>
                        <th>Total_Active_Modules</th>
                        <th>Total_Active_Modules_Completed</th>
                        <th>Assignments_Submitted</th>
                        <th>Average_Assignment_Marks</th>
                        <th>Total_Courses_Cleared</th>
                        <th>Total_Courses_Exam_Attended</th>
                        <th>Semester_Percentile</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{rows.totalActiveModule}</td>
                        <td>{rows.totalCompletedModule}</td>
                        <td>{rows.totalCompletedMiniassignment}</td>
                        <td>{rows.miniAverageScore}</td>
                        <td>{rows.examAttended}</td>
                        <td>{rows.courseCleared}</td>
                        <td>{rows.sempass}</td>
                      </tr>

                    </tbody>
                  </table>
                </Paper>

              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Skeleton variant="rect" width={50} height={28} />{" "}
                </div>
              )}{" "}
            </div>
          </Paper >
        </div >


      </>
    );
  }
  function progress() {
    return (
      <>
        <div >

          <div className={User.table}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead style={{ background: Constants.main_color_1 }}>
                  <TableRow>
                    <TableCell style={header}>Program Image</TableCell>
                    <TableCell style={header}>Program Name</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ width: "30%" }}>
                      {loading ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <Skeleton variant="rect" width={210} height={120} />{" "}
                        </div>
                      ) : (
                        <img
                          src={`https://${Constants.DOMAIN
                            }/${config.aws_org_id.toLowerCase()}-resources/images/program-images/${userData.pid
                            }.png`}
                          className={User.image}
                        />
                      )}
                    </TableCell>
                    <TableCell style={{ width: "30%" }}>
                      <h3
                        onClick={() => {
                          handleClickOpen();
                        }}
                        style={programnames}
                        className={User.programname}
                      >
                        {loading === false ? (
                          userData.pname
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            <Skeleton variant="text" width={300} height={20} />{" "}
                          </div>
                        )}
                      </h3>
                    </TableCell>

                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>

      </>
    );
  }
  function documents() {
    return (
      <>
        <div>
          <Paper>
            <div className={classes.center}>
              <div
                style={{
                  textAlign: "center",
                  padding: "10px",
                  display: "inline-block",
                }}
              >
                <div style={{ width: "500px" }}>
                  <h3
                    style={{
                      marginBottom: "10px",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleProfileOpen();
                    }}
                  >
                    Click Here To View User Profile
                  </h3>
                </div>
              </div>
            </div>
          </Paper>

          <Paper>
            <div className={classes.center}>
              <div
                style={{
                  textAlign: "center",
                  padding: "10px",
                  display: "inline-block",
                }}
              >
                <div>
                  <h3 style={{ marginBottom: "10px" }}>Marks Card</h3>

                  <div
                    style={{
                      background: Constants.main_color_1,
                      color: "white",
                      height: "60px",
                      width: "120px",
                      borderRadius: "5px",
                      alignContent: "center",
                      margin: "auto",
                    }}
                  >
                    <h4> Upload File</h4>
                    <PublishIcon
                      style={{ cursor: "pointer" }}
                      onClick={handleFileUploadopen}
                    />
                  </div>
                </div>

                {marksFile === undefined ||
                  marksFile.length === 0 ||
                  marksFile.length === undefined ? (
                  <div>No MarksCard</div>
                ) : (
                  <>
                    <div>
                      {Array.isArray(marksFile) &&
                        marksFile.length > 0 &&
                        marksFile.map((mfile) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                gap: "20px",
                                border: "none",
                                margin: "10px",
                                borderRadius: "5px",
                                boxShadow: "0px 0px 5px 0px lightgrey ",
                              }}
                            >
                              <h3 style={{ color: " Constants.main_color_1" }}>
                                {mfile}
                              </h3>

                              <div style={{ display: "flex", gap: "5px" }}>
                                <VisibilityIcon
                                  style={{
                                    height: "25px",
                                    width: "25px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setFtype("markscard");
                                    setSelectedFile(mfile);
                                    handleAddOpen();
                                  }}
                                />

                                <HighlightOffIcon
                                  style={{
                                    height: "25px",
                                    width: "25px",
                                    cursor: "pointer",
                                    color: "red",
                                  }}
                                  onClick={() => {
                                    setFtype("markscard");
                                    handledeleteOpen();
                                    setDeleteMarksCert(true);
                                    removeMarksCard(mfile);
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </>
                )}
              </div>
            </div>
          </Paper>

          <Paper>
            <div className={classes.center}>
              <div
                style={{
                  textAlign: "center",
                  padding: "10px",
                  display: "inline-block",
                  gap: "50px",
                  margin: "30px",
                }}
              >
                <div>
                  <h3 style={{ marginBottom: "10px" }}>Certificate</h3>

                  <div
                    style={{
                      background: Constants.main_color_1,
                      color: "white",
                      height: "60px",
                      width: "120px",
                      borderRadius: "5px",
                      alignContent: "center",
                      textAlign: "center",
                      margin: "auto",
                    }}
                  >
                    <h4> Upload File</h4>

                    <PublishIcon
                      style={{ cursor: "pointer" }}
                      onClick={handleFileUploadCertificateopen}
                    />
                  </div>
                </div>

                {certificateFile === undefined ||
                  certificateFile.length === 0 ||
                  certificateFile.length === undefined ? (
                  <div>No Certificate</div>
                ) : (
                  <>
                    <div>
                      {Array.isArray(certificateFile) &&
                        certificateFile.length > 0 &&
                        certificateFile.map((cfile) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                gap: "20px",
                                border: "none",
                                margin: "10px",
                                borderRadius: "5px",
                                boxShadow: "0px 0px 5px 0px lightgrey ",
                              }}
                            >
                              <h3>{cfile}</h3>
                              <div style={{ display: "flex", gap: "5px" }}>
                                <VisibilityIcon
                                  style={{
                                    height: "25px",
                                    width: "25px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setFtype("certificate");
                                    setSelectedFile(cfile);
                                    handleAddOpen();
                                  }}
                                />

                                <HighlightOffIcon
                                  style={{
                                    height: "25px",
                                    width: "25px",
                                    cursor: "pointer",
                                    color: "red",
                                  }}
                                  onClick={() => {
                                    setFtype("certificate");
                                    setDeleteMarksCert(true);
                                    handledeleteOpen();
                                    removeCertificate(cfile);
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </>
                )}
              </div>
            </div>
          </Paper>
        </div>
      </>
    );
  }

  const onsubmit = async (type, fname) => {
    setLoading(true);
    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.selbatchuser.eid,
        markscard: marksFile,
        certificate: certificateFile,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    if (type == "cert") {
      bodyParam.body.certificate = fname;
    }
    if (type == "markscard") {
      bodyParam.body.markscard = fname;
    }
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/updateUsermarkscardCertificate",
        bodyParam
      );
      handleFileUploadClose();
      setOpenCertificateFileUpload(false);
      handleDeleteClose();

      if (deleteMarksCert === false) {
        swal({
          title: "Success",
          text: "Uploaded successfully",
          icon: "success",
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            setSpin(false);
          }
        });

        setSpin(false);
      } else if (deleteMarksCert === true)
        swal({
          title: "Success",
          text: "deleted successfully",
          icon: "success",
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            setSpin(false);
          }
        });

      setSpin(false);
    } catch (error) {
      console.log("getUserError", error);
    }
  };

  return (
    <div className={User.maincontainer}>
      <UserHeader Bindex={3} />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <AccordionPopup handleClose={handleClose} />
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openAdd}
        closeAfterTransition
      >
        <Fade in={openAdd}>
          <ViewDoc
            handleAddClose={handleAddClose}
            docFileName={selectedFile}
            eid={userDetails?.selbatchuser?.eid}
            ftype={ftype}
          />
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openFileUpload}
        closeAfterTransition
      >
        <Fade in={openFileUpload}>
          <div
            style={{
              width: "50%",
              height: "50%",
              background: "transparent",
              alignitems: "center",
              justifycontent: "center",
              backgroundColor: "white",
              display: "grid",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: "max-content",
              }}
            >
              <h3 style={{ padding: "8px" }}> Marks Card</h3>
              <button onClick={handleFileUploadClose}>X</button>
            </div>

            <div
              className={User.form}
              style={{
                padding: "20px",
                textAlign: "center",
                margin: "10px auto",
              }}
            >
              <div
                style={{
                  color: "#cc0000",
                  marginBottom: "12px",
                  marginTop: "-10px",
                  textAlign: "start",
                  fontSize: "13px",
                }}
              >
              </div>
              <div
                className={User.field}
                style={{
                  border: "1px solid gray",
                  padding: "50px",
                  display: "block",
                }}
              >
                <input
                  type="file"
                  id="files"
                  accept=".csv, .pdf, .jpg"
                  onChange={(e) => changeHandlerMarksCard(e)}
                />
                {pissueTextUpload !== undefined && pissueTextUpload !== "" && (
                  <p>{pissueTextUpload}</p>
                )}
              </div>

              <br></br>

              <br></br>
              {spin ? (
                <CircularProgress />
              ) : (
                <input
                  type="submit"
                  value="Save"
                  className={classes.buttonsty}
                  onClick={() => {
                    if (tempMfile && fileSelected) {
                      setMarkFile(tempMfile);
                      onsubmit("markscard", tempMfile);
                    }
                  }}
                ></input>
              )}
            </div>
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openCertificateFileUpload}
        closeAfterTransition
      >
        <Fade in={openCertificateFileUpload}>
          <div
            style={{
              width: "50%",
              height: "50%",
              background: "transparent",
              alignitems: "center",
              justifycontent: "center",
              backgroundColor: "white",
              display: "grid",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: "max-content",
              }}
            >
              <h3 style={{ padding: "8px" }}>Certificate</h3>
              <button onClick={handleFileUploadCertificateclose}>X</button>
            </div>

            <div
              className={User.form}
              style={{
                padding: "20px",
                textAlign: "center",
                margin: "10px auto",
              }}
            >
              <div
                className={User.field}
                style={{
                  border: "1px solid gray",
                  padding: "50px",
                  display: "block",
                }}
              >
                <input
                  type="file"
                  id="files"
                  accept="image/png, image/jpeg"
                  onChange={(e) => changeHandlerCertificate(e)}
                />
                {pissueTextUpload !== undefined && pissueTextUpload !== "" && (
                  <p>{pissueTextUpload}</p>
                )}
              </div>

              <br></br>

              <br></br>
              {spin ? (
                <CircularProgress />
              ) : (
                <input
                  type="submit"
                  value="Save"
                  className={classes.buttonsty}
                  onClick={() => {
                    if (tempCfile && fileSelected) {
                      setCertificateFile(tempCfile);
                      onsubmit("cert", tempCfile);
                    }
                  }}
                ></input>
              )}
            </div>
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openProfile}
        closeAfterTransition
      >
        <Fade in={openProfile}>
          <div
            style={{
              width: "50%",
              height: "80vh",
              overflow: "auto",
              background: "transparent",
              alignitems: "center",
              justifycontent: "center",
              backgroundColor: "white",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h3 style={{ padding: "8px" }}>Profile details</h3>
              <button onClick={handleProfileClose}>X</button>
            </div>

            <div>
              <Avatar
                style={{
                  cursor: "pointer",
                  height: "8rem",
                  width: "8rem",
                  margin: "auto",
                }}
                src={`https://${Constants.DOMAIN
                  }/${config.aws_org_id.toLowerCase()}-resources/profile/${userDetails.selbatchuser.eid
                  }/profilepic/${profilePicName}`}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "20px",
                fontSize: "16px",
                fontWeight: "bold",
                gap: "20px",
                border: "none",

                borderRadius: "5px",
                boxShadow: "0px 0px 5px 0px lightgrey ",
              }}
            >
              <label>firstName:</label>
              <label>{firstName}</label>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "20px",
                fontSize: "16px",
                fontWeight: "bold",
                gap: "20px",
                border: "none",

                borderRadius: "5px",
                boxShadow: "0px 0px 5px 0px lightgrey ",
              }}
            >
              <label>LastName:</label>
              <label>{lastName}</label>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "20px",
                fontSize: "16px",
                fontWeight: "bold",
                gap: "20px",
                border: "none",

                borderRadius: "5px",
                boxShadow: "0px 0px 5px 0px lightgrey ",
              }}
            >
              <label>Phone number:</label>
              <label>{phoneNo}</label>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "20px",
                fontSize: "16px",
                fontWeight: "bold",
                gap: "20px",
                border: "none",

                borderRadius: "5px",
                boxShadow: "0px 0px 5px 0px lightgrey ",
              }}
            >
              <label>Country Code:</label>
              <label>{countryCode}</label>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "20px",
                fontSize: "16px",
                fontWeight: "bold",
                gap: "20px",
                border: "none",

                borderRadius: "5px",
                boxShadow: "0px 0px 5px 0px lightgrey ",
              }}
            >
              <label>Date of Birth:</label>
              <label>{dob}</label>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "20px",
                fontSize: "16px",
                fontWeight: "bold",
                gap: "20px",
                border: "none",

                borderRadius: "5px",
                boxShadow: "0px 0px 5px 0px lightgrey ",
              }}
            >
              <label>Address:</label>
              <label>{address}</label>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "20px",
                fontSize: "16px",
                fontWeight: "bold",
                gap: "20px",
                border: "none",

                borderRadius: "5px",
                boxShadow: "0px 0px 5px 0px lightgrey ",
              }}
            >
              <label>Passport Number:</label>
              <label>{passportNo}</label>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "20px",
                fontSize: "16px",
                fontWeight: "bold",
                gap: "20px",
                border: "none",

                borderRadius: "5px",
                boxShadow: "0px 0px 5px 0px lightgrey ",
              }}
            >
              <label>Other Govt ID Number:</label>
              <label>{govtId}</label>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "20px",
                fontSize: "16px",
                fontWeight: "bold",
                gap: "20px",
                border: "none",

                borderRadius: "5px",
                boxShadow: "0px 0px 5px 0px lightgrey ",
              }}
            >
              <label>Higest Degree:</label>
              <label>{higestDegree}</label>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "20px",
                fontSize: "16px",
                fontWeight: "bold",
                gap: "20px",
                border: "none",

                borderRadius: "5px",
                boxShadow: "0px 0px 5px 0px lightgrey ",
              }}
            >
              <label>Work Experience:</label>
              <label>{workExperience}</label>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "20px",
                fontSize: "16px",
                fontWeight: "bold",
                gap: "20px",
                border: "none",

                borderRadius: "5px",
                boxShadow: "0px 0px 5px 0px lightgrey ",
              }}
            >
              <label>Uploaded Documents:</label>

              <div>
                {docs === undefined ||
                  docs.length === 0 ||
                  docs.length === undefined ? (
                  <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                    No Documents
                  </div>
                ) : (
                  docs.map((docs, idx) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",

                          }}
                        >
                          {docs}
                        </label>

                        <div style={{ display: "flex", gap: "5px" }}>
                          <VisibilityIcon
                            style={{
                              width: "20px",
                              height: "20px",

                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setSelectedFile(docs);
                              handleAddOpen();
                              setFtype("profiledocs");
                            }}
                          />

                          <DownloadIcon
                            onClick={() => {
                              handleDownloadOpen();
                              setFtype("profiledocs");
                              setSelectedFile(docs);
                            }}
                            style={{
                              width: "20px",
                              height: "20px",
                              color: "blue",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openDelete}
        closeAfterTransition
      >
        <Fade in={openDelete}>
          <div
            style={{
              width: "fit-content",
              height: "fit-content",
              background: "transparent",
              alignitems: "center",
              justifycontent: "center",
              backgroundColor: "white",
              display: "grid",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                textAlign: "center",
                margin: "10px",
              }}
            >
              <form>
                <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                  Are you sure you want to delete ?
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "50px",
                    justifyContent: "center",
                    padding: "10px",
                  }}
                >
                  <button
                    style={{ fontSize: "16px", fontWeight: "bold" }}
                    onClick={() => {
                      handleDeleteClose();
                      getUserMarkscardCertificate();
                    }}
                  >
                    No
                  </button>
                  <button
                    onClick={onsubmit}
                    style={{ fontSize: "16px", fontWeight: "bold" }}
                  >
                    Yes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openDownload}
        closeAfterTransition
      >
        <Fade in={openDownload}>
          <DownLoadDocs
            handleDownloadClose={handleDownloadClose}
            docFileName={selectedFile}
            uploadType={ftype}
          />
        </Fade>
      </Modal>
      <h3 className={User.header}>User Progress</h3>
      <div className={User.tabholder}>
        <Tab panes={panes} />
      </div>
    </div>
  );
};

export default UserProgress;