// Dependencies imports
import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbarContainer, GridToolbar } from '@material-ui/data-grid';
import { API } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/styles';
import { useNavigate } from "react-router-dom";

// Local imports
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import config from "../../../config/aws-exports";
import { Constants } from "../../../config/constants";
import UserBulkUploadTenant from '../UserBulkUploadTenant';
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";

// Styles imports
import Assesg from '../AssesmentGrid.module.scss';

const useStyles = makeStyles({
  root: {
    '& .super-app-theme--header': {
      background: Constants.main_color_1,
      fontSize: "16px",
      color: 'rgb(255,255,255)',
      fontFamily: "nunito"
    },
  },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer"
  }
});

// Start of User Stats Grid Component
const ProjectDetailsGrid = ({ userData, selectionModel, setSelectionModel }) => {
  const [openBulk, setOpenBulk] = useState(false);
  const [rowdata, setRowdata] = useState(null);

  const classes = useStyles();
  const userDetails = useSelector(authData);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Function for toolbar options above datagrid
  const CustomToolbar = () => (
    <GridToolbarContainer style={{ display: "flex", justifyContent: "flex-end" }}>
      <GridToolbar />
    </GridToolbarContainer>
  );

  // Use effect for fetching user data
  useEffect(() => {
  }, []);

  // Columns definition
  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 300,
      flex: 1,
      headerClassName: 'super-app-theme--header',
      editable: true,
      renderCell: (param) => {
        return (

          <h4
            onClick={() => {
              handleCellClick(param);
            }}
            className={Assesg.head}
          >
            {param.value}
          </h4>

        );
      },
    },
    { field: 'email', headerName: 'Email', width: 300, flex: 1, headerClassName: 'super-app-theme--header', editable: true },
    { field: 'usn', headerName: 'Candidate Id', width: 300, flex: 1, headerClassName: 'super-app-theme--header', editable: true },

  ];



  // Handle cell click event
  const handleCellClick = (param) => {
    let sdata = { ...userDetails };
    sdata.selbatchuser = { ...param.row };
    let temp = [...sdata.breadcrumb];
    temp[3] = { name: sdata.selbatchuser.name, path: "/userprojectprogress" };
    sdata.breadcrumb = temp;
    dispatch(awsSignIn(sdata));
    navigate("/userprojectprogress");
  }

  // Fetch user data from API
  async function getFaculty() {
    const bodyParam = { body: { oid: config.aws_org_id, bpid: userDetails.curprgcou.bpid }, headers: { "Content-Type": "application/json", Accept: "application/json" } };
    try {
      await API.post(config.aws_cloud_logic_custom_name, Constants.GET_PROGRAM_BATCH_REPORT, bodyParam);
      
    } catch (error) {
      console.error(error);
    }
  }

  // Handle closing of modal
  const handleBulkClose = () => setOpenBulk(false);

  return (
    <div style={{ height: 400, width: '100%' }}>
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={userData}
          columns={columns}
          disableSelectionOnClick
          components={{ Toolbar: CustomToolbar }}
          onRowSelected={e => console.log(e)}
          onSelectionModelChange={(newSelection) => setSelectionModel(newSelection)}
          selectionModel={selectionModel}
          className={classes['.MuiDataGrid-root']}
        />
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openBulk}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={openBulk}>
          <UserBulkUploadTenant
            handleClose={handleBulkClose}
            type="user"
            getUsers={getFaculty}
            rows={userData}
            rowdata={rowdata}
          />
        </Fade>
      </Modal>
    </div>
  );
}

export default ProjectDetailsGrid;
