// Dependencies imports
import React, { useState, useEffect, useRef } from "react";
import { API } from "aws-amplify";
import { Card, CardMedia, Typography, CardContent } from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import Skeleton from "@material-ui/lab/Skeleton";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";

import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { Tab } from "semantic-ui-react";

// Local imports
import UserHeader from "../Header/UserHeader/UserHeader";

// Styles imports
import Cont from "./Content.module.scss";
//import { set } from "immer/dist/internal";

// Start of Content Component
const Content = () => {
  const [progDetails, setProgDetails] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [sendMail, setSendMail] = useState(false);
  const [sendMailIntern, setSendMailIntern] = useState(false);
  const [item, setItem] = useState();
  const [idx, setIdx] = useState();

  let userDetails = useSelector(authData);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  console.log("user", userDetails);

  useEffect(() => {
    getProgDetails();
    breadcrumb();
  }, []);

  async function getProgDetails() {
    const bodyParam = {
      body: {
        bpid: userDetails.curprgcou.bpid,
        oid: config.aws_org_id,
        pid: userDetails.curprgcou.pid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    if (
      userDetails.role == "Instructors" ||
      userDetails.role == "Coordinators"
    ) {
      bodyParam.body.eid = userDetails.eid;
    }
    try {
      console.log(bodyParam.body);
      const PDetails = await API.post(
        config.aws_cloud_logic_custom_name,
        `/getProgramsDetails`,
        bodyParam
      );
      console.log("PDetails" + JSON.stringify(PDetails));
      setProgDetails(PDetails);
      setLoading(false);
      console.log(PDetails);
    } catch (error) {
      // alert(JSON.stringify(error));
      console.error(error);
    }
  }

  const enableDisablePost = async (item, idx) => {
    console.log(item);
    setLoading(true);
    const bodyParam = {
      body: {
        tid: item.tid,
        oid: config.aws_org_id,
        btid: item.btid,
        bpid: userDetails.curprgcou.bpid,
        disable: !item.tenabled,
        pid: userDetails.curprgcou.pid,
        ttitle: item.ttitle,
        ptitle: userDetails.curprgcou.ptitle,
        email: item.email,
        emailIntern: item.emailIntern,
        bname: userDetails.curprgcou.bname,
        disablebyemail: userDetails.email,
        disablebyName: userDetails.name,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    console.log(JSON.stringify(bodyParam.body, null, 2));
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/enableCourse",
        bodyParam
      );
      // progDetails.pcurriculum[idx].tenabled = !item.tenabled;


      for (let i = 0; i < progDetails.pcurriculum.length; i++) {
        if (progDetails.pcurriculum[i].tid == item.tid) {
          progDetails.pcurriculum[i].tenabled = !item.tenabled;
        }
      }
      setProgDetails(progDetails);
      console.log(JSON.stringify(progDetails, null, 2));
      setLoading(false);
      // return response;
    } catch (error) {
      console.log("enableNuggetserre", error);
    }

    // setSendMail(false);
    // setSendMailIntern(false);

    item.email = false;
    item.emailIntern = false;
    setItem(item);
  };

  function breadcrumb() {
    let sdata = { ...userDetails };
    let temp = [...sdata.breadcrumb];

    temp[2] = {
      name: "Content",
      path: "/Content",
    };

    sdata.breadcrumb = temp;

    dispatch(awsSignIn(sdata));
  }

  function titleclick(data, PValue) {
    console.log("userDetails.coursetype", userDetails.coursetype);
    let sdata = { ...userDetails };
    // for(let i = 0; i < pdata.length; i++){
    //   if(data.bpid == pdata[i].bpid){
    //     sdata.curprgcou = pdata[i];
    //   }
    // }

    sdata.curprg = { ...data };

    sdata.curprg.pid = userDetails.curprgcou.pid;
    sdata.curprg.bpid = userDetails.curprgcou.bpid;
    sdata.btid = data.btid;

    let temp = [...sdata.breadcrumb];

    // temp[3] = {
    //   name: userDetails?.coursetype &&
    //     ((userDetails.coursetype == "project") || (userDetails.curprg.coursetype == "Project")) ? "Project View" : "Course View",
    //   path: "/courseView",
    // };

    // sdata.breadcrumb = temp;

    dispatch(awsSignIn(sdata));

    navigate("/courseView");
  }

  // function onClickOfPage(name, path) {
  //   let sdata = { ...userDetails };
  //   let temp = [...sdata.breadcrumb];

  //   temp[3] = {
  //     name: name,
  //     path: path,
  //   };

  //   sdata.breadcrumb = temp;

  //   dispatch(awsSignIn(sdata));
  // }

  function onClickOfPage(name, path) {
    let type = name;

    let sdata = { ...userDetails };
    let temp = [...sdata.breadcrumb];
    temp[3] = {
      name: name,
      path: path,
    };
    sdata.breadcrumb = temp;
    sdata.coursetype = type;
    dispatch(awsSignIn(sdata));
    navigate("/courseCreation");
  }

  const panes = [
    {
      menuItem: "Content",
      render: () => (
        <Tab.Pane>
          <div className={Cont.topbar}>
            <h3 className={Cont.header}>Content</h3>
            <button
              className={Cont.createbutton}
              onClick={() => {
                //navigate("/courseCreation");
                onClickOfPage("course", "/courseCreation");
              }}
            >
              Create course
            </button>

            {/* <select
              style={{
                border: "none",
                width: "10%",
                boxShadow: "0px 0px 5px 0px lightgrey",
                background: "#fff",
                height: "30px",
                marginBottom: "20px",
                color: "#000",
                fontSize: "15px",
                paddingLeft: "10px",
                fontWeight: "200px",
              }}
              required
              onChange={onChangeHandlerCourse}
            >
              <option value="" disabled selected>
                Select
              </option>
              <option value="course">Course</option>
              <option value="project">Project</option>
            </select> */}
          </div>

          <div className={Cont.cardholder}>
            {isLoading && (
              <>
                {["h1", "h1", "h1"].map((item) => (
                  <>
                    <Card style={{ height: "300px" }}>
                      <CardMedia>
                        <div className={Cont.image}>
                          <Skeleton variant="rect" height={130} />
                        </div>
                      </CardMedia>
                      <CardContent style={{ padding: "10px" }}>
                        <h1 className={Cont.coursename}>
                          <Typography component="div" variant="h1">
                            <Skeleton />
                          </Typography>
                          <Typography component="div" variant="h3">
                            <Skeleton />
                          </Typography>
                        </h1>
                      </CardContent>
                    </Card>
                  </>
                ))}
              </>
            )}
            {progDetails.pcurriculum !== undefined && !isLoading ? (
              progDetails.pcurriculum
                .filter((p) => p.coursetype !== "Project")
                .map((item, idx) => (
                  <div className={Cont.cards}>
                    <Card style={{ height: "300px" }}>
                      <CardMedia>
                        <img
                          className={Cont.image}
                          src={`https://${config.aws_content_delivery_cloudfront_domain
                            }/${config.aws_org_id.toLowerCase()}-resources/images/topic-images/${item.tid
                            }.png`}
                        ></img>
                      </CardMedia>
                      <CardContent style={{ padding: "10px" }}>
                        <h2 className={Cont.coursename}>{item.tlabel}</h2>
                        <h3
                          className={Cont.coursecontent}
                          style={{ height: "55px" }}
                          onClick={() => titleclick(item)}
                        >
                          {item.ttitle}
                        </h3>
                        {item.tenabled === true ? (
                          <button
                            className={Cont.disablebutton}
                            onClick={() => {
                              item.email = false;
                              item.emailIntern = true;
                              setIdx(idx);
                              setItem(item);
                              setSendMailIntern(true);
                              enableDisablePost(item, idx);
                            }}
                          >
                            Disable
                          </button>
                        ) : (
                          <button
                            className={Cont.enablebutton}
                            onClick={() => {
                              setItem(item);
                              setIdx(idx);
                              //enableDisablePost(item, idx);
                              setOpen(true);
                            }}
                          >
                            Enable
                          </button>
                        )}
                      </CardContent>
                    </Card>
                  </div>
                ))
            ) : (
              <p></p>
            )}
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Project",
      render: () => (
        <Tab.Pane active={true}>
          <div className={Cont.topbar}>
            <h3 className={Cont.header}>Project</h3>
            <button
              className={Cont.createbutton}
              onClick={() => {
                // navigate("/courseCreation");
                onClickOfPage("project", "/courseCreation");
              }}
            >
              Create Project
            </button>

            {/* <select
              style={{
                border: "none",
                width: "10%",
                boxShadow: "0px 0px 5px 0px lightgrey",
                background: "#fff",
                height: "30px",
                marginBottom: "20px",
                color: "#000",
                fontSize: "15px",
                paddingLeft: "10px",
                fontWeight: "200px",
              }}
              required
              onChange={onChangeHandlerCourse}
            >
              <option value="" disabled selected>
                Select
              </option>
              <option value="course">Course</option>
              <option value="project">Project</option>
            </select> */}
          </div>

          <div className={Cont.cardholder}>
            {isLoading && (
              <>
                {["h1", "h1", "h1"].map((item) => (
                  <>
                    <Card style={{ height: "300px" }}>
                      <CardMedia>
                        <div className={Cont.image}>
                          <Skeleton variant="rect" height={130} />
                        </div>
                      </CardMedia>
                      <CardContent style={{ padding: "10px" }}>
                        <h1 className={Cont.coursename}>
                          <Typography component="div" variant="h1">
                            <Skeleton />
                          </Typography>
                          <Typography component="div" variant="h3">
                            <Skeleton />
                          </Typography>
                        </h1>
                      </CardContent>
                    </Card>
                  </>
                ))}
              </>
            )}
            {progDetails.pcurriculum !== undefined && !isLoading ? (
              progDetails.pcurriculum
                .filter((p) => p.coursetype === "Project")
                .map((item, idx) => (
                  <div className={Cont.cards}>
                    <Card style={{ height: "300px" }}>
                      <CardMedia>
                        <img
                          className={Cont.image}
                          src={`https://${config.aws_content_delivery_cloudfront_domain
                            }/${config.aws_org_id.toLowerCase()}-resources/images/topic-images/${item.tid
                            }.png`}
                        ></img>
                      </CardMedia>
                      <CardContent style={{ padding: "10px" }}>
                        <h2 className={Cont.coursename}>{item.tlabel}</h2>
                        <h3
                          className={Cont.coursecontent}
                          style={{ height: "55px" }}
                          onClick={() => titleclick(item)}
                        >
                          {item.ttitle}
                        </h3>
                        {item.tenabled === true ? (
                          <button
                            className={Cont.disablebutton}
                            onClick={() => {
                              item.email = false;
                              item.emailIntern = true;
                              setIdx(idx);
                              setItem(item);
                              setSendMailIntern(true);
                              enableDisablePost(item, idx);
                            }}
                          >
                            Disable
                          </button>
                        ) : (
                          <button
                            className={Cont.enablebutton}
                            onClick={() => {
                              setItem(item);
                              setIdx(idx);
                              //enableDisablePost(item, idx);
                              setOpen(true);
                            }}
                          >
                            Enable
                          </button>
                        )}
                      </CardContent>
                    </Card>
                  </div>
                ))
            ) : (
              <p></p>
            )}
          </div>
        </Tab.Pane>
      ),
    },
  ];

  return (
    <div className={Cont.maincontainer}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div
            style={{
              backgroundColor: "white",
              width: "50rem",
              transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              transform: "translate(-50%, -50%)",
              position: "absolute",
              height: "20rem",
              padding: "2rem",
              top: "50%",
              left: "50%",
              textAlign: "center",
            }}
          >
            <h2>
              After enableling the course do you want to notify users through
              E-mail
            </h2>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "40px",
                gap: "5rem",
              }}
            >
              <button
                className={Cont.disablebutton}
                onClick={() => {
                  setSendMail(false);
                  setOpen(false);
                  enableDisablePost(item, idx);
                }}
              >
                Don't Send
              </button>

              <button
                className={Cont.enablebutton}
                onClick={() => {
                  //setSendMail(true);
                  item.email = true;
                  item.emailIntern = false;
                  setItem(item);
                  enableDisablePost(item, idx);
                  setOpen(false);
                }}
              >
                Send
              </button>
            </div>
          </div>
        </Fade>
      </Modal>
      <UserHeader Bindex={2} />
      <Tab panes={panes} />
    </div>
  );
};

export default Content;
