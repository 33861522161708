import React from 'react';

const DiscussionIcon = ({ className, cls1 }) => {
    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className={className}>
            <path className={cls1} d="M17.81,11.55c0,2.88-2.95,5.23-6.58,5.23a7.7,7.7,0,0,1-2.12-.29,1.14,1.14,0,0,0-.58,0,38.38,38.38,0,0,1-5.11.86c-.31,0-1.58,0-1.34-.57.13-.33.89-.41,1.19-.55a12.45,12.45,0,0,0,1.6-.88,1.21,1.21,0,0,0,.35-1.65,4.31,4.31,0,0,1-.57-2.14c0-2.9,2.94-5.24,6.58-5.24S17.81,8.65,17.81,11.55Z" />
            <path className={cls1} d="M9.07,16.55a7.47,7.47,0,0,0,4.7,1.57,8.1,8.1,0,0,0,2.12-.28,1.14,1.14,0,0,1,.58,0,38.38,38.38,0,0,0,5.11.86c.31,0,1.58,0,1.34-.57-.13-.33-.89-.41-1.19-.54a13.88,13.88,0,0,1-1.6-.89A1.21,1.21,0,0,1,19.78,15a4.28,4.28,0,0,0,.57-2.14c0-2.26-1.79-4.18-4.31-4.92" />
            <circle className={cls1} cx="7.51" cy="11.61" r="0.23" />
            <circle className={cls1} cx="11.17" cy="11.61" r="0.23" />
            <circle className={cls1} cx="14.68" cy="11.61" r="0.23" />
        </svg>
    );
};

export default DiscussionIcon;