import React, { useEffect, useState, useRef } from "react";
import User from "./AddUsersTenant.module.scss";
import config from "../../config/aws-exports";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { authData } from "../../redux/auth/authSlice";
import { useSelector } from "react-redux";
import { DataGrid } from '@material-ui/data-grid';
import { Close } from "@material-ui/icons";
import { Constants } from "../../config/constants";
import { Select, ListItemText, MenuItem, Input, Checkbox, Radio } from '@material-ui/core';
import { API } from "aws-amplify";
import swal from "sweetalert";


const useStyles = makeStyles((theme) => ({
    root: {
        '& .super-app-theme--header': {
            background: Constants.main_color_1,
            fontSize: "16px",
            color: 'rgb(255,255,255)',
            fontFamily: "nunito"
        },
    },
    ".MuiDataGrid-root": {
        fontSize: "14px",
        fontFamily: "nunito",
        fontWeight: 400,
        color: "black",
        cursor: "pointer"
    },
    formControl: {
        border: "1px solid #6a6a6a",
        height: "fit-content",
        width: "100%",
        borderRadius: "5px",
        fontSize: "14px",
        fontFamily: "nunito",
        padding: "5px"
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    program: {
        fontSize: "16px",
        color: "black",
        fontWeight: "500",
        paddingLeft: "5px"
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const AssignInstructors = (props) => {
    let { handleClose, getUsers, rows, instructors } = props;
    const [spin, setSpin] = useState(false);
    const classes = useStyles();
    const [personName, setPersonName] = useState([]);
    let userDetails = useSelector(authData);
    const accRef = useRef();
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedInstructor, setSelectedInstructor] = useState([]);
    const [programDetail, setProgramDetail] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [unCheckedUsers, setUnCheckedUsers] = useState([]);
    const [userData, setUserData] = useState([]);
    const [instructorList, setInstructorList] = useState([]);


    useEffect(() => {
        getProgramsDetails()

    }, []);


    async function onsubmit() {
        let userslist = await recvDataMail(selectionModel);


        const usersNotInA = selectedUsers.filter(userA => {
            // Check if the current user from 'a' is present in 'b' by comparing 'eid'
            return !userslist.some(userB => userA.eid === userB.eid);
        });


        setUnCheckedUsers(usersNotInA)
        const usersNotInB = userslist.filter(userA => {
            return !selectedUsers.some(userB => userA.eid === userB.eid);
        });


        if (personName && personName.length == 0) {
            swal(
                "Oops!",
                "Please select the Course",
                "error"
            );
            return;
        }

        if (selectedInstructor && selectedInstructor.length == 0) {
            swal(
                "Oops!",
                "Please select the Instructor",
                "error"
            );
            return;
        }
        userslist = usersNotInB
        const bodyParam = {
            body: {
                oid: config.aws_org_id,
                bpid: userDetails.curprgcou.bpid,
                userarray: userslist,
                ProjectInstructor: selectedInstructor,
                course: personName,
                unCheckedUsers: usersNotInA

            },
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };

        setSpin(true);
        try {

            let response = await API.post(
                config.aws_cloud_logic_custom_name,
                "/assignInstructors",
                bodyParam
            );

            if (response != null && response.code == 0) {
                setSpin(false);
                setUserData(response.data);
            } else {
                swal({
                    title: "Success",
                    text: "Users assigned successfully",
                    icon: "success",
                    dangerMode: false,
                });

                getUsers();
                handleClose();

            }
        } catch (error) {
            console.error(error);
        }

        setSpin(false);
    };

    async function getProgramsDetails() {
        const bodyParam = {
            body: {
                oid: config.aws_org_id,
                pid: userDetails.curprgcou.opid,
                bpid: userDetails.curprgcou.bpid,

            },
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        if (userDetails.role == "Instructors" || userDetails.role == "Coordinators") {
            bodyParam.body.eid = userDetails.eid;
        }
        try {
            const response = await API.post(
                config.aws_cloud_logic_custom_name,
                Constants.GET_Programs_Details,
                bodyParam

            );
            const users = response;
            let programdetails = [];
            let programname = []
            if (response.pcurriculum !== undefined) {
                for (var i = 0; i < response.pcurriculum.length; i++) {
                    let obj = {};
                    obj.tid = response.pcurriculum[i].btid;
                    obj.ttitle = response.pcurriculum[i].ttitle;
                    programdetails.push(obj);
                    programname.push(obj.ttitle)
                }
            }
            setProgramDetail(programdetails);



        } catch (error) {
            swal({
                title: "Oooops!",
                text: "something went wrong!",
                icon: "warning",
                dangerMode: true,
            })
                .then(willDelete => {
                    if (willDelete) {
                        setSpin(false);
                    }
                });
            console.error("getUserError", error);
        }

    }

    const handleChange = (event) => {
        let selectArray = event.target.value;



        let matchingCourses = [];

        for (let i = 0; i < instructors.length; i++) {
            const projectInstructor = instructors[i].CIDS[userDetails.curprgcou.bpid];
            let foundMatch = false;


            for (const courseId in projectInstructor) {
                const instructorObj = projectInstructor[courseId];


                for (let j = 0; j < selectArray.length; j++) {
                    const course = selectArray[j];

                    if (course.tid == instructorObj.tid) {

                        foundMatch = true;
                    }
                }
            }


            if (foundMatch) {
                matchingCourses.push(instructors[i]);
            }
        }



        setInstructorList(matchingCourses)
        setPersonName(selectArray);
    };

    const handleChangeInstructors = (event) => {
        let selectArray = event.target.value;
        setSelectedInstructor(selectArray);

        if (personName && personName.length == 0) {
            swal({
                title: "Oops!",
                text: "Please select the Course",
                icon: "error",
            }).then(() => {
                setSelectedInstructor([]);
            });
        }


        let a = rows
        const matchingCourses = [];

        for (let i = 0; i < a.length; i++) {
            const projectInstructor = a[i].ProjectInstructor;
            for (const courseId in projectInstructor) {
                const instructorObj = projectInstructor[courseId];

                personName.forEach((course) => {
                    if (instructorObj[course.tid]) {
                        const instructor = instructorObj[course.tid];

                        if (instructor.EMAILID != undefined) {
                            if (instructor.EMAILID == selectArray.EMAILID) {
                                matchingCourses.push(
                                    a[i]
                                );
                            }
                        }
                    }
                });
            }
        }


        setSelectedUsers(matchingCourses)
        const isPresentInB = (item) => {
            return matchingCourses.some(bItem => bItem.id == item.id);
        };

        setSelectionModel(rows.filter(isPresentInB).map(item => item.id))
        rows.filter(isPresentInB).map(item => item.id)


    };

    async function recvDataMail(indexArr) {
        const filteredUsers = rows?.filter(row => indexArr?.includes(row.id));
        return filteredUsers;
    }

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            width: 300,
            flex: 1,
            headerClassName: 'super-app-theme--header',

        },
        {
            field: 'email',
            headerName: 'Email',
            width: 300,
            flex: 1,
            headerClassName: 'super-app-theme--header',
        }
    ];

    return (
        <div className={User.maindiv}>


            {userData.length > 0 ? (
                <div className={User.maincontainer} >

                    <div className={User.iconcontainer}>
                        <h3 style={{ fontSize: "14px", textAlign: "left", fontWeight: "bold" }}>
                            These users are already assigned for instructor
                        </h3>
                        <Close className={User.closebutton} onClick={handleClose} />
                    </div>

                    <div
                        style={{
                            height: "200px",
                            overflowY: "scroll",
                            marginTop: "10px",

                        }}
                    >
                        <table style={{ fontSize: "14px", width: "100%" }}>
                            <thead style={{ backgroundColor: "aliceblue" }}>
                                <th>Name</th>
                                <th>Email</th>

                            </thead>
                            <tbody>
                                {userData.map((item, i) => (
                                    <tr key={i}>
                                        <td>{item.UserName}</td>
                                        <td>{item.UserEmail}</td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : <>  {spin === false ? (
                <div className={User.maincontainer}>
                    <div className={User.iconcontainer}>
                        <h3 className={User.create}>Assign Instructors</h3>
                        <Close className={User.closebutton} onClick={handleClose} />
                    </div>

                    <div className={User.form}>
                        <div>
                            <div>
                                <div className={User.field} style={{ margin: "1rem" }}>
                                    <h3 className={User.select} style={{ width: "30%", textAlign: "left" }}>Select Course</h3>
                                    <Select
                                        labelId="demo-mutiple-checkbox-label"
                                        id="demo-mutiple-checkbox"
                                        style={{
                                            border: "none",
                                            width: "50%",
                                            boxShadow: "0px 0px 5px 0px lightgrey",
                                            background: "white",
                                            height: "30px",
                                            fontSize: "15px",
                                            paddingLeft: "10px",
                                            fontWeight: "200px"
                                        }}
                                        multiple
                                        value={personName}
                                        onChange={handleChange}
                                        input={<Input />}
                                        renderValue={(selected) => {
                                            selected.join(', ');
                                            let temp = [];
                                            for (let i = 0; i < selected.length; i++) {
                                                temp.push(selected[i].ttitle);
                                            }
                                            return temp;
                                        }}
                                        MenuProps={MenuProps}
                                    >
                                        {programDetail.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox checked={personName.indexOf(item) > -1} />
                                                <ListItemText primary={item.ttitle} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>

                                <div className={User.field} style={{ margin: "1rem" }}>
                                    <h3 className={User.select} style={{ width: "30%", textAlign: "left" }}>Select Instructor</h3>

                                    <Select
                                        labelId="demo-single-checkbox-label"
                                        id="demo-single-checkbox"
                                        style={{
                                            border: "none",
                                            width: "50%",
                                            boxShadow: "0px 0px 5px 0px lightgrey",
                                            background: "white",
                                            height: "30px",
                                            fontSize: "15px",
                                            paddingLeft: "10px",
                                            fontWeight: "200px"
                                        }}
                                        value={selectedInstructor}
                                        onChange={handleChangeInstructors}
                                        input={<Input />}
                                        renderValue={(selected) => {
                                            return selected.UNAME;
                                        }}
                                        MenuProps={MenuProps}
                                    >
                                        {instructorList?.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                <Radio checked={selectedInstructor === item} />
                                                <ListItemText primary={item.UNAME} />
                                            </MenuItem>
                                        ))}
                                    </Select>

                                </div>

                            </div>

                            <div style={{ height: 400, width: '100%' }}>
                                <DataGrid
                                    rows={rows}
                                    columns={columns}
                                    checkboxSelection
                                    onSelectionModelChange={(newSelection) => {

                                        setSelectionModel(newSelection);
                                    }}
                                    selectionModel={selectionModel}
                                    isSelected={() => {

                                        return false;
                                    }}
                                    className={classes['.MuiDataGrid-root']}
                                />
                            </div>

                        </div>

                        <div
                            style={{
                                marginTop: "15px",

                            }}
                        >
                            <input
                                type="submit"
                                value="Save"
                                onClick={onsubmit}
                                className={
                                    window.navigator.onLine === true
                                        ? User.btn_color
                                        : User.btn_colordis
                                }
                                disabled={!window.navigator.onLine}
                            ></input>
                        </div>
                    </div>

                </div>

            ) : (
                <div className={classes.root}>
                    <CircularProgress style={{ color: config.main_color_1 }} />
                </div>
            )}</>}

        </div>
    )
}

export default AssignInstructors