import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CardMedia } from "@material-ui/core";
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { saveAs } from "file-saver";

const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      heigth: "100%",
      alignItems: "center",
      justifyContent: "center",
      background: "transparent",
    },
  }));


const DownLoadDocs=(props)=> {
    let { handleDownloadClose, docFileName, uploadType } = props;
    const classes = useStyles();
    let userDetails = useSelector(authData);
    async function downloadcode(sData) {
        console.log(sData);
        console.log("uploadType", uploadType);
    
        saveAs.saveAs(
          `https://${
            Constants.DOMAIN
          }/${config.aws_org_id.toLowerCase()}-resources/profile/${
            userDetails.selbatchuser.eid
          }/${uploadType}/${sData}`,
          sData
        );
      }
    
  return (
    <div
    style={{
      width: "80%",
      //height: "80%",
      background: "transparent",
      alignitems: "center",
      justifycontent: "center",
      backgroundColor: "white",
      display: "grid",
    }}
  >
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: "max-content",
      }}
    >
      <h3>{docFileName}</h3>
      <button onClick={handleDownloadClose}>X</button>
    </div>
    <div
      style={{
        textAlign: "center",
        margin: "20px",
        
        
      }}
    >
      <button
        style={{ fontSize: "16px", fontWeight: "bold" }}
        onClick={() => {
          downloadcode(docFileName);
          handleDownloadClose();
        }}
      >
        Click Here TO Download The File
      </button>
    </div>
  </div>
  )
}

export default DownLoadDocs