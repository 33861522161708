import React, { useState } from "react";

import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/styles";

// Local imports
import "./ProjectDetails.scss";
import Typography from "@material-ui/core/Typography";
import { Tabs, Box, Tab } from "@mui/material";


import "./ProjectDetails.scss";

import { styled } from '@mui/material/styles';
import Grid from "@material-ui/core/Grid";
import PropTypes from 'prop-types';
import { Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const useStyles = makeStyles({
  formContainer: {
    "@media (max-width: 940px)": {
      width: "100%",
    },
  },
  mainDialog: {
    display: "flex",
    justifyContent: "center",
    width: "90%",
    height: "84vh",
    overflowX: "hidden",
    borderRadius: "5px",
    background: "white",
    "& > div": {
      width: "100%",
    },
  },
  tabLabel: {
    fontSize: "1.8rem!important",
    textTransform: "capitalize!important",
    alignItems: "start!important",
    "& > svg": {
      position: "absolute",
      right: "20px",
      margin: "0!important",
    },
    '&.Mui-selected': {
      color: '#e4651d!important',
      borderBottom: '2px solid #e4651d',
    },
  },

  mainGrid: {
    width: "100%",
    margin: 0,
    "& > div": {
      padding: "15px!important",
    },
  },
  joinNowButton: {
    backgroundColor: "var(--sub-heading-color) !important",
    textTransform: "capitalize!important",
    height: "40px",
    fontSize: "1.5rem!important",
    border: "none",
    color: "white!important",
    borderRadius: "4px",
  },

});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "var(--sub-heading-color) !important",
  color: theme.palette.common.white,
  fontSize: '1.5rem'
}));

let rows = [];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


const ProjectApprovedMessages = (props) => {
  let { handleFileClose, seeApprovedMessages, nuggets, projectNugget, callApi } = props;
  const classes = useStyles();

  const [value, setValue] = React.useState();
  function Row(props) {
    const { row, index } = props;

    const [open, setOpen] = React.useState(false);
    const backgroundColor = index % 2 === 1 ? '#eee' : 'transparent';

    return (
      <React.Fragment>
        <TableRow sx={{ backgroundColor, fontWeight: "bold" }}>
          <TableCell sx={{ fontSize: '1.5rem', padding: "4px 16px", color: "var(--sub-heading-color)", }}>{row.otitle}</TableCell>
          <TableCell sx={{ fontSize: '1.5rem', padding: "4px 16px", color: "var(--sub-heading-color)", }}>
            {
              row.projectData && row.projectData.length > 0 ? (
                <p style={{
                  width: "100%",
                  textAlign: "center",
                  borderRadius: "0.75rem",
                  border:
                    row.projectData[0].objStatus == 2
                      ? "2px solid"
                      : row.projectData[0].objStatus === "In-Progress"
                        ? "1px solid"
                        : row.projectData[0].objStatus === "Denied"
                          ? "1px solid"
                          : "",

                  borderColor:
                    row.projectData[0].objStatus == 2
                      ? "#005c0f"
                      : row.projectData[0].objStatus === "In-Progress"
                        ? "#b89247"
                        : row.projectData[0].objStatus === "Denied"
                          ? "#d61212"
                          : "",
                  backgroundColor:
                    row.projectData[0].objStatus == 2
                      ? "#f0ffec"
                      : row.projectData[0].objStatus === "In-Progress"
                        ? "#fffaf0"
                        : row.projectData[0].objStatus === "Denied"
                          ? "#fcf6f6"
                          : "",
                  color:
                    row.projectData[0].objStatus == 2
                      ? "#005c0f"
                      : row.projectData[0].objStatus === "In-Progress"
                        ? "#b89247"
                        : row.projectData[0].objStatus === "Denied"
                          ? "#d61212"
                          : "",
                }}>            {row.projectData[0].objStatus == 2 ? "Approved" : row.projectData[0].objStatus}</p>
              ) : (<p></p>)
            }
          </TableCell>
          <TableCell sx={{ fontSize: '1.5rem', padding: "4px 16px" }}>
            {
              row.projectData && row.projectData.length > 0 ? (<IconButton
                aria-label="expand row"
                onClick={() => setOpen(!open)}
                sx={{ color: 'var(--sub-heading-color)' }}
              >
                View message
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>) : (<></>)
            }

          </TableCell>
          <TableCell sx={{ fontSize: '1.5rem', padding: "4px 16px" }}>
            {
              row.projectData && row.projectData.length > 0 ? (
                <p>
                  {row.projectData[0].postedDate}
                </p>
              ) : (<p></p>)
            }

          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell style={{ backgroundColor: "#fafafa", padding: "0 1rem" }} colSpan={4}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <h4>Message :</h4>
                <Typography variant="body1" component="div" sx={{ fontSize: '1.2rem', margin: "1rem 0" }}>

                  {
                    row.projectData && row.projectData.length > 0 ? (<div>{row.projectData[0].message}
                      <div style={{ display: "flex", color: "var(--sub-heading-color)", gap: "0.1rem", alignItems: "center", cursor: "pointer" }}>
                        <p
                          onClick={() => {
                            handleFileClose();
                            TabPanel(row.oid);
                            callApi(row.oid)
                          }}
                          style={{ textDecoration: "underline", fontSize: "14px", }}>
                          Visit Thread
                        </p>

                        <OpenInNewIcon style={{ height: "1.5rem" }} />
                      </div>
                    </div>) : (<></>)
                  }
                </Typography>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  Row.propTypes = {
    row: PropTypes.shape({
      serial: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
      fileUploaded: PropTypes.string.isRequired,
      responseMessage: PropTypes.string.isRequired,
    }).isRequired,
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);

    for (let i = 0; i < projectNugget.length; i++) {
      let temp = [];
      if (projectNugget[i].nid == newValue && (seeApprovedMessages != null || seeApprovedMessages != undefined)) {
        for (let j = 0; j < projectNugget[i].objects.length; j++) {
          for (let k = 0; k < seeApprovedMessages.length; k++) {
            if (projectNugget[i].objects[j].oid == seeApprovedMessages[k].oid) {
              temp.push(seeApprovedMessages[k]);

            }

          }
        }

        rows = temp

      }
    }

  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "93%",
        height: "84vh",
        overflowX: "hidden",
        paddingBottom: "20px",
        background: "white",
        margin: "50px",
      }}
    >
      <div
        style={{
          width: "90%",
        }}
      >
        <div>
          <div style={{ display: "grid", gridTemplateColumns: "30% 70%", }}>
            <div style={{ margin: "2rem" }}>
              <h2 style={{ fontWeight: "bold" }}>Approved Message</h2>

              <div style={{ padding: "1rem 2rem", backgroundColor: "var(--sub-heading-color)", color: "white", margin: "2rem 2rem 2rem 0", fontSize: "2rem" }}>Project Phase</div>


              {Object.keys(nuggets).map((nugget, idx) => (
                <>
                  {nuggets[nugget].map(
                    ({ nid, ntitle, }) => (
                      <Box
                        sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', }}
                      >
                        <Tabs
                          orientation="vertical"
                          variant="scrollable"
                          value={value}
                          onChange={handleChange}
                          aria-label="Vertical tabs example"
                          sx={{
                            width: "100%", '& > div > span': {
                              display: "none!important",
                            },
                          }}
                        >
                          <Tab

                            className={classes.tabLabel}

                            key={nid}
                            label={
                              <div
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  width: "100%",
                                  justifyContent: "Space-between",
                                  textTransform: "capitalize",
                                  alignItems: "center",

                                }}
                              >
                                <p
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    textAlign: "left",
                                  }}
                                >
                                  {ntitle}
                                </p>





                              </div>
                            }
                            value={nid}
                            style={{
                              fontSize: "12px",
                              alignItems: "start",
                            }}
                          />
                        </Tabs>

                      </Box>
                    )
                  )}
                </>
              ))}



            </div>

            <div style={{ position: "relative", borderLeft: "4px solid lightgray", height: "84vh" }}>
              <CloseIcon onClick={handleFileClose} style={{ position: "absolute", right: "1rem", top: "1rem", cursor: "pointer" }} />


              <Grid item xs={12} sm={12} md={12} lg={12}>

                <TableContainer component={Paper} style={{ marginTop: "6rem" }}>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Project Objective</StyledTableCell>
                        <StyledTableCell>Objective Status</StyledTableCell>
                        <StyledTableCell>Approved Message</StyledTableCell>
                        <StyledTableCell>Approval Date</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>


                      {rows != undefined || rows.length != undefined ? (<> {rows.map((row, index) => (
                        <Row key={row.oid} row={row} index={index} style={{
                          backgroundColor: index % 2 == 0 ? '#f0f0f0' : 'black',
                        }} />
                      ))}</>) : (<></>)


                      }

                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>



            </div>
          </div>

        </div>

      </div>
    </div>
  );
};

export default ProjectApprovedMessages;
