import React from 'react';

const ContentIcon = ({ className, cls1, cls2 }) => {
    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className={className}>
            <path className={cls1} d="M20.16,17H5a1.93,1.93,0,0,1-2-1.86V7A1.93,1.93,0,0,1,5,5.16H20.16a1.93,1.93,0,0,1,2,1.86v8.14A1.93,1.93,0,0,1,20.16,17Z" />
            <path className={cls2} d="M15.35,11.59l-5.06,2.89a.52.52,0,0,1-.71-.16h0a.62.62,0,0,1-.07-.19,2.11,2.11,0,0,1,0-.26V8.23c0-.34.16-.58.44-.61a.65.65,0,0,1,.36.07l5.08,2.89A.55.55,0,0,1,15.35,11.59Z" />
        </svg>
    );
};

export default ContentIcon;