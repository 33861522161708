// Dependencies imports
import React, { useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
// Local imports
import { Constants } from "../../../config/constants";
import { useSelector } from "react-redux";
import { authData } from "../../../redux/auth/authSlice";
import config from "../../../config/aws-exports";
import { API } from "aws-amplify";
// Style imports
import Add from "./AddRecording.module.scss";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
}));

const DeleteRecording = (props) => {
  let { handleClose, deleteData, reload } = props;
  const [spin, setSpin] = useState(false);
  const classes = useStyles();
  let userDetails = useSelector(authData);
  let lcourseDetails = userDetails.curprg;
  const onsubmit = async () => {
    setSpin(true);

    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        cid: lcourseDetails.btid,
        action: "delete",
        id: deleteData.id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.COURSE_VIDEO,
        bodyParam
      );
      deleteNotification();
      reload();
      handleClose();
      swal({
        title: "Success",
        text: "Session Deleted successfully",
        icon: "success",
        dangerMode: false,
      }).then((willDelete) => {
        if (willDelete) {
          handleClose();
        }
      });
      setSpin(false);
    } catch (error) {
      swal({
        title: "Oooops!",
        text: "something went wrong!",
        icon: "warning",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
        }
      });
    }
  };

  const deleteNotification = async () => {
    let usersList = await getUsers();
    const bodyParam = {
      body: {
        action: "delete",
        oid: config.aws_org_id,
        userslist: usersList,
        id: deleteData.id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/deleteNotification",
        bodyParam
      );
    } catch (error) {
      console.error("getUserError", error);
    }
  };

  async function getUsers() {
    let filterStudents = [];
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        bpid: userDetails.curprgcou.bpid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const users = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PROGRAM_BATCH_REPORT,
        bodyParam
      );
      const list = users.map(({ EID, UNAME, UR }) => ({
        EID,
        UNAME,
        UR,
      }));

      filterStudents = list.filter((listnew) => listnew.UR === "User");
    } catch (error) {
      console.error(error);
    }

    return filterStudents;
  }
  return (
    <div>
      {spin === false ? (
        <div className={Add.mainpopup}>
          <div className={Add.form}>
            <form>
              <div className={Add.header}>
                Are you sure you want to delete ?
              </div>
              <div className={Add.buttonholder}>
                <button className={Add.cancel} onClick={handleClose}>
                  NO
                </button>
                <button className={Add.submit} onClick={onsubmit}>
                  Yes
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className={classes.root}>
          <CircularProgress style={{ color: Constants.main_color_1 }} />
        </div>
      )}
    </div>
  );
};

export default DeleteRecording;
