import React from 'react';

const EventIcon = ({ className, cls1, cls2 }) => {
    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 25 25"
            className={className}>
            <rect className={cls1} x="3.53" y="6.32" width="17.93" height="14.15" rx="1.37" />
            <line className={cls1} x1="3.53" y1="10.53" x2="21.47" y2="10.53" />
            <line className={cls2} x1="9.08" y1="4.53" x2="9.08" y2="8.37" />
            <line className={cls2} x1="15.92" y1="4.53" x2="15.92" y2="8.37" />
        </svg>
    );
};

export default EventIcon;