import React from "react";

const AnnouncementsIcon = ({ className, cls1, cls2 }) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={className}>
      <path className={cls1} d="M8.28,15.35c.26.67.5,1.32.75,2s.58,1.5.86,2.26a1.37,1.37,0,0,1-.8,1.78,4.14,4.14,0,0,1-.82.27,1.37,1.37,0,0,1-1.45-.85L5.23,16.59l0-.06-.35.13a1.61,1.61,0,0,1-1.61-.23,1.31,1.31,0,0,1-.46-.56c-.24-.62-.5-1.24-.7-1.87a1.6,1.6,0,0,1,1-1.94L6.91,10.6A.29.29,0,0,0,7,10.5L7.5,10l3.05-3.09c.38-.38.77-.76,1.15-1.15.08-.08.12-.18.19-.26s.17-.19.26-.29l1-1a.73.73,0,0,1,1.23.27c.79,2.06,1.57,4.13,2.36,6.19l.59,1.56c.33.85.65,1.71,1,2.56a.73.73,0,0,1-.73,1c-.45,0-.9-.06-1.35-.1l-1.52-.1-1.61-.12-2-.14-1.93-.15-.39,0H8.71ZM12,6.1l-1,1L7.49,10.7s-.06.09-.1.13a.61.61,0,0,1-.17.15l-.75.29L3.23,12.51A1.13,1.13,0,0,0,2.56,14l.6,1.56a1.13,1.13,0,0,0,1.5.68L5.24,16a.25.25,0,0,1,.34.17l0,.13,1,2.49.27.7,2.21-.79s0,0,0-.06c-.18-.46-.35-.93-.53-1.39C8.25,16.6,8,16,7.76,15.32A.23.23,0,0,1,7.9,15l.57-.22a5.5,5.5,0,0,1,1.07,0l1.6.12,2,.14,1.6.12.74.06Zm5.44,9.26h.06c.26,0,.39-.15.3-.39s-.11-.29-.17-.44c-.74-1.95-1.48-3.91-2.23-5.86-.5-1.33-1-2.65-1.51-4a.27.27,0,0,0-.49-.1l-1,1a.12.12,0,0,0,0,.14l1.71,4.54c.61,1.61,1.21,3.23,1.82,4.84a.17.17,0,0,0,.18.14ZM9.23,19.14,7,19.94c.1.25.18.5.29.73a.9.9,0,0,0,1.09.46A6,6,0,0,0,9,20.9a.89.89,0,0,0,.52-1C9.42,19.65,9.32,19.41,9.23,19.14Z" />
      <path className={cls2} d="M22.74,7.76a.66.66,0,0,1-.48.65l-2.89,1-.79.28a.68.68,0,0,1-.88-.41.66.66,0,0,1,.44-.87l3.68-1.28A.67.67,0,0,1,22.74,7.76Zm-.47,0c0-.16-.15-.24-.33-.17L18.3,8.85a.21.21,0,0,0-.16.27.22.22,0,0,0,.3.12l2.41-.84L22.09,8Q22.28,7.91,22.27,7.76Z" />
      <path className={cls2} d="M16.82,6.53A.68.68,0,0,1,17,6.1l2-2.48A.68.68,0,0,1,20.14,4a.69.69,0,0,1-.16.47L18,6.94a.69.69,0,0,1-.72.24A.67.67,0,0,1,16.82,6.53Zm.69.2c.11,0,.15-.07.2-.14.29-.35.56-.72.85-1.07s.7-.89,1.05-1.34a.22.22,0,0,0,.05-.11.19.19,0,0,0-.12-.22.17.17,0,0,0-.23,0c-.15.19-.3.37-.44.56-.51.64-1,1.28-1.52,1.93a.21.21,0,0,0,0,.23A.2.2,0,0,0,17.51,6.73Z" />
      <path className={cls2} d="M22.25,12.52l-1.65-.1-1.51-.1a.67.67,0,0,1-.63-.7.68.68,0,0,1,.71-.65l1.52.09,1.62.1a.66.66,0,0,1,.67.71.67.67,0,0,1-.71.65ZM22,12h0c.12,0,.23,0,.34,0a.21.21,0,0,0,.21-.18.21.21,0,0,0-.18-.22l-.21,0-1.33-.08-1.62-.09c-.15,0-.24.07-.24.19a.21.21,0,0,0,.22.22l.75.05Z" />
    </svg>

  );
};

export default AnnouncementsIcon;

// <style>.cls-1{fill:#e35f14;}</style>
