// Dependencies imports
import React, { useState, useEffect } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbar,
} from "@material-ui/data-grid";
import { authData } from "../../redux/auth/authSlice";
import Skeleton from "@material-ui/lab/Skeleton";
import swal from "sweetalert";
import { makeStyles } from "@material-ui/styles";

// Local imports

// Styles imports
import Assesg from "./AttendanceGrid.module.scss";
import { useSelector} from "react-redux";

import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";

import { API } from "aws-amplify";

const useStyles = makeStyles({
  root: {
    "& .super-app-theme--header": {
      backgroundColor: Constants.main_color_1,
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
  },
});

// Data for Faculty Grid
const columns = [
  {
    field: "Enrollment",
    headerName: "Candiate id",
    width: 300,
    flex: 1,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "Name",
    headerName: "Name",
    width: 300,
    flex: 1,
    headerClassName: "super-app-theme--header",
  },

  {
    field: "Total",
    headerName: "Total attendance",
    width: 300,
    flex: 1,
    headerClassName: "super-app-theme--header",
  },
];

// Start of Faculty Grid Component
const AttendanceGrid = () => {
  const [userData, setUserData] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [attendanceList, setAttendancelist] = useState([]);
  const [attendList, setAttendList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState(null);
  const classes = useStyles();

  let userDetails = useSelector(authData);

  useEffect(() => {
    getAttendanceData();
    return () => {};
  }, []);

  async function getAttendanceData() {
    const bodyParam = {
      body: {
        action: "get",
        bpid: userDetails.curprgcou.bpid,
        oid: config.aws_org_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        `/attendance`,
        bodyParam
      );

      setAttendancelist(response);
      convertData(response);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  function postAttendanceDataCall() {
    if (date == "" || date === null || date === undefined) {
      swal({ type: "error", title: "Please select a date" });
    } else if (attendList.length === 0 || attendList === undefined) {
      swal({ type: "error", title: "Please select Students" });
    } else {
      postAttendanceData();
    }
  }

  async function postAttendanceData() {
    setLoading(true);
    const bodyParam = {
      body: {
        action: "update",
        bpid: userDetails.curprgcou.bpid,
        date: date,
        oid: config.aws_org_id,
        userlist: attendList,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        `/attendance`,
        bodyParam
      );
      setSelectionModel([]);
      setDate("");
      setAttendList([]);
      getAttendanceData();
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }

  function convertData(data) {
    let temp = [];
    for (let i = 0; i < data.length; i++) {
      let obj = {};
      obj.id = i;
      if (
        data[i].USN !== undefined &&
        data[i].USN[userDetails.curprgcou.bpid] !== undefined
      ) {
        obj.Enrollment = data[i].USN[userDetails.curprgcou.bpid].usn;
      }
      obj.Name = data[i].UNAME;
      if (
        data[i].ATEND !== undefined &&
        data[i].ATEND[userDetails.curprgcou.bpid] !== undefined
      ) {
        obj.Total = data[i].ATEND[userDetails.curprgcou.bpid].count;
      }

      temp.push(obj);
    }

    setUserData(temp);
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <GridToolbar />
      </GridToolbarContainer>
    );
  }

  const handleCellClick = (param, event) => {};
  function checking(index1) {
    let rowObj = {};
    let attendList1 = [];

    for (let i = 0; i < index1.length; i++) {
      rowObj = attendanceList[index1[i]];
      attendList1.push(rowObj);
    }

    setAttendList(attendList1);
  }

  return (
    <div className={Assesg.maindiv}>
      <div className={Assesg.topbar}>
        <div className={Assesg.leftside}>
          <h3 className={Assesg.select}>Select Date</h3>
          <input
            style={{
              paddingLeft: "5px",
              fontSize: "12px",
              border: "1px solid #e35f14",
              borderRadius: "5px",
            }}
            placeholder="Date"
            type="date"
            onChange={(e) => setDate(e.target.value)}
            value={date}
          ></input>
        </div>
        <div className={Assesg.rightside}>
          <button
            className={Assesg.addbutton}
            onClick={() => postAttendanceDataCall()}
          >
            Mark attendance
          </button>
        </div>
      </div>
      {loading === false ? (
        <div style={{ height: 500, width: "100%" }} className={classes.root}>
          <DataGrid
            rows={userData}
            columns={columns}
            checkboxSelection
            disableSelectionOnClick
            components={{ Toolbar: CustomToolbar }}
            onRowSelected={(e) => console.log("e", e)}
            onSelectionModelChange={(newSelection) => {
              setSelectionModel(newSelection);
              checking(newSelection);
            }}
            selectionModel={selectionModel}
            onCellClick={handleCellClick}
            className={classes[".MuiDataGrid-root"]}
          />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            paddingTop: "20px",
            height: "100%",
          }}
        >
          <Skeleton variant="rect" width="100%" height={400} />{" "}
        </div>
      )}
    </div>
  );
};

export default AttendanceGrid;
