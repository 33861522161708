import React, { useState } from 'react';
import Feedback from '../components/Programs/Feedback';
import Complents from '../components/Programs/Complents';
import Aside from '../modules/SideNav/Aside';


function ComplentsLayout() {
    const rtl = false;

    const [toggled, setToggled] = useState(false);

    const handleToggleSidebar = value => {
        setToggled(value);
    };
  return (
    <div className={`app ${rtl ? 'rtl' : ''} ${toggled ? 'toggled' : ''}`}>
            <Aside
                rtl={rtl}
                toggled={toggled}
                handleToggleSidebar={handleToggleSidebar}
            />
            <Complents handleToggleSidebar={handleToggleSidebar} />
        </div>
  )
}

export default ComplentsLayout