// Dependencies imports
import React, { useEffect, useState } from 'react';
import { API } from "aws-amplify";
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from "@material-ui/lab/Skeleton";

// Local imports
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import UserHeader from "../Header/UserHeader/UserHeader";
import LineChart from './LineChart';

// Styles imports
import Dash from './DashboardStyles.module.scss';

// Internal Styles for the material UI components
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: "space-between",
    alignItems: "center"
  },
  graph: {
    width: "100%",
    height: "fit-content",
    textAlign: "center",
    verticalAlign: "middle",
  },
  paper: {
    textAlign: "center",
    verticalAlign: "middle",
    marginLeft: theme.spacing(0),
    marginRight: "0px",
    width: "250px",
    height: theme.spacing(16),
    justifyContent: "space-between"
  }
}));


// Main Dashboard Component
const Dashboard = () => {
  const [userCount, setUserCount] = useState({});
  const [programscount, setProgramsCount] = useState({});
  const [bacthescount, setBacthesCount] = useState({});
  const [activeUsers, setActiveUsers] = useState('');
  const [loading, setLoading] = useState(true);


  const classes = useStyles();

  // Useeffect for api call
  useEffect(() => {
    getUserCount();
    getPrograms();
    getBatches();
    return () => { };
  }, []);

  // API call syntax to get usercount
  async function getUserCount() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_USER_COUNT,
        bodyParam
      );
      console.log("userResponse", JSON.stringify(response));
      let uc = response.usercount;
      let auser = uc.length;
      if (auser > 0) {
        auser = auser - 1;

      }

      setUserCount(response);
      setActiveUsers(uc[auser].UC);
      setLoading(false);
    }
    catch (error) {
      console.error(error);
    }
  }

  async function getPrograms() {

    const bodyParam = {
      body: {
        oid: config.aws_org_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const programs = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PROGRAMS,
        bodyParam
      );
      console.log("programs", programs);
      // getBatches(programs);
      if (programs !== undefined && programs.programs !== undefined) {
        setProgramsCount(programs.programs)
      }


    }
    catch (error) {
      console.error(error);
    }
  }

  async function getBatches() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        //eid: ,
        role: "admin"
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const batches = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_BATCHES,
        bodyParam
      );
      console.log("Batches", batches);
      let data = [];
      for (const [key, value] of Object.entries(batches)) {
        data.push(value);
      }
      setBacthesCount(data);

    }
    catch (error) {
      console.error(error);
    }
  }


  return (
    <div className={Dash.main}>
      <UserHeader />
      <div className={Dash.firsthalf}>
        <div className={Dash.lefttotal}>
          <h1 className={Dash.header}>Total</h1>
          <div className={Dash.paperholder}>
            <Paper elevation={3} className={classes.paper}>
              <h2 className={Dash.numbers}>{loading === false ? userCount.users : <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><Skeleton variant="rect" width={50} height={28} /> </div>}</h2>
              <h3 className={Dash.types}>Users</h3>
            </ Paper >
            <Paper elevation={3} className={classes.paper}>
              <h2 className={Dash.numbers}>{loading === false ? programscount.length : <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><Skeleton variant="rect" width={50} height={28} /> </div>}</h2>
              <h3 className={Dash.types}>Programs</h3>
            </ Paper >
            <Paper elevation={3} className={classes.paper}>
              <h2 className={Dash.numbers}>{loading === false ? bacthescount.length : <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><Skeleton variant="rect" width={50} height={28} /> </div>}</h2>
              <h3 className={Dash.types}>Batches</h3>
            </ Paper >
            <Paper elevation={3} className={classes.paper}>
              <h2 className={Dash.numbers}>{loading === false ? activeUsers : <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><Skeleton variant="rect" width={50} height={28} /> </div>}</h2>
              <h3 className={Dash.types}>Unique Users</h3>
            </ Paper >
          </div>
        </div>
        {/* <div className={Dash.rightactive}>
          <h1>Active</h1>
          <div className={classes.root}>
            <Paper elevation={3} className={classes.paper}>
              <h2 className={Dash.numbers}>{loading === false ? bacthescount.length : <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}><Skeleton variant="rect" width={50} height={28} /> </div>}</h2>
              <h3 className={Dash.types}>Batches</h3>
            </ Paper >
            <Paper elevation={3} className={classes.paper}>
              <h2 className={Dash.numbers}>{loading === false ? activeUsers : <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}><Skeleton variant="rect" width={50} height={28} /> </div>}</h2>
              <h3 className={Dash.types}>Active Users</h3>
            </ Paper >
          </div>
        </div> */}
      </div>
      <div className={Dash.secondhalf}>
        <h1 className={Dash.header}>User activity</h1>
        <div className={Dash.holder}>
          <Paper elevation={3} className={classes.graph}>
            {loading === false ?
              <LineChart data={userCount.usercount} /> : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "40px" }}><Skeleton variant="rect" width="80%" height={300} style={{marginBottom: "40px"}} /> </div>}
          </ Paper>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;