import React from "react";
import { Constants } from "../../../config/constants";
import config from "../../../config/aws-exports";
import "./ProjectDetails.scss";

const ProjectFile = (props) => {
  let { handleFileClose, obj, objId, bcid, userEid } = props;

  
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "93%",
        height: "84vh",
        overflowX: "hidden",
        paddingBottom: "20px",
        background: "white",
        margin: "50px",
      }}
    >
      <div>
        <div
          style={{
            margin: "0.5rem",
            display: "flex",
            justifyContent: "space-between",
            fontSize: "14px",
          }}
        >
          <p>{obj.fileName} </p>
          <button onClick={handleFileClose}>X</button>
        </div>

        <iframe
          src={`https://${
            Constants.DOMAIN
          }/${config.aws_org_id.toLowerCase()}-data/data/project/${userEid}/${bcid}/${objId}/${
            obj.fileUrlName
          }`}
          width={"92vw"}
          title="Iframe Example"
          style={{ width: "92vw", height: "74vh", margin: "2rem" }}
        ></iframe>
      </div>
    </div>
  );
};

export default ProjectFile;
