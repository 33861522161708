// Dependencies imports
import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";

// Local imports
import { ReactComponent as Close } from "../../assets/svg/close_black_24dp.svg";
import { authData } from "../../redux/auth/authSlice";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
} from "@material-ui/data-grid";
// Styles imports
import Acc from "./AccordionPopup.module.scss";
const columns = [
  {
    field: "col1",
    headerName: "Student Name",
    width: 300,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "col2",
    headerName: "Candiate id",
    width: 300,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "col3",
    headerName: "Email id",
    width: 300,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "col4",
    headerName: "Country name",
    width: 300,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "col5",
    headerName: "Total modules active",
    width: 300,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "col6",
    headerName: "Total modules completed (Numbers)",
    width: 400,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "col7",
    headerName: "Total modules completed (Percentage)",
    width: 400,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "col8",
    headerName: "Active assignments",
    width: 300,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "col9",
    headerName: "Submitted assignments",
    width: 300,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "col10",
    headerName: "Average Assignment marks",
    width: 300,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "col11",
    headerName: "Assessment 1",
    width: 300,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "col12",
    headerName: "Assessment 2",
    width: 300,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "col13",
    headerName: "Assessment 3",
    width: 300,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "col16",
    headerName: "Average Assessment Marks(best of two)",
    width: 400,
    headerClassName: "super-app-theme--header",
  },
];

const useStyles = makeStyles(() => ({
  root: {
    display: "inline-block",
    width: "100%",
    paddingTop: "20px",
    "& .super-app-theme--header": {
      backgroundColor: Constants.main_color_1,
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
    },
  },
  root1: {
    width: "100%",
  },
  heading: {
    fontSize: "16px",
    fontWeight: 400,
    color: Constants.main_color_1,
  },
  subheading: {
    color: "black",
    fontFamily: "nunito",
    fontSize: "14px",
    fontWeight: "600px",
  },
  "MuiAccordionDetails-root": {
    display: "inline-block",
    width: "100%",
    padding: "10px 20px",
  },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
  },
}));

// Start of main accordion component
const AccordionPopup = (props) => {
  const [loading, setLoading] = useState(true);
  const [courseName, setcourseName] = useState(props.courseData.ttitle);
  const [rows, setRows] = useState([]);
  const classes = useStyles();
  let { handleClose } = props;

  let userDetails = useSelector(authData);

  useEffect(() => {
    getuserprogress();
    return () => {};
  }, []);

  async function getuserprogress() {
    setLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        bpid: userDetails.curprgcou.bpid,
        topicids: [props.courseData.btid],
        telective: props.courseData.telective,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      let response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getmyCoursereports",
        bodyParam
      );
      if (response === null || response === undefined) {
        setLoading(false);
      }
      let trows = [];
      for (let i = 0; i < response.length; i++) {
        let obj = {};
        obj.id = i;
        obj.col1 = response[i].UNAME;
        if (
          response[i].USN !== undefined &&
          response[i].USN[userDetails.curprgcou.bpid] !== undefined
        ) {
          obj.col2 = response[i].USN[userDetails.curprgcou.bpid].usn;
        }
        obj.col3 = response[i].EMAILID;
        obj.col4 = response[i].country;
        obj.col5 = response[i].totalActiveModule;
        obj.col6 = response[i].totalCompletedModule;
        obj.col7 = parseFloat(response[i].totalCompletedModulePercent).toFixed(
          2
        );
        obj.col8 = response[i].totalMiniassignments;
        obj.col9 =
          response[i].totalCompletedMiniassignment == undefined
            ? 0
            : response[i].totalCompletedMiniassignment;
        obj.col10 =
          response[i].miniAverageScore != null
            ? parseFloat(response[i].miniAverageScore).toFixed(2)
            : "Not Graded";
        if (Object.keys(response[i].Assesment).length > 0) {
          obj.col11 =
            response[i].Assesment[1] != undefined &&
            response[i].Assesment[1] != "NA"
              ? response[i].Assesment[1]
              : "Not Attendeded";
          obj.col12 =
            response[i].Assesment[2] != undefined &&
            response[i].Assesment[2] != "NA"
              ? response[i].Assesment[2]
              : "Not Attendeded";
          obj.col13 =
            response[i].Assesment[3] != undefined &&
            response[i].Assesment[3] != "NA"
              ? response[i].Assesment[3]
              : "Not Attendeded";
        } else {
          obj.col11 = "Not Attendeded";
          obj.col12 = "Not Attendeded";
          obj.col13 = "Not Attendeded";
        }
        let average = [];
        if (Object.keys(response[i].Assesment).length > 0) {
          for (let a = 1; a <= Object.keys(response[i].Assesment).length; a++) {
            if (
              response[i].Assesment[a] != undefined &&
              response[i].Assesment[a] != "NA"
            ) {
              average.push(response[i].Assesment[a]);
            }
          }
          average.sort();
          average.reverse();

          if (average[0] != undefined && average[1] != undefined) {
            obj.col16 = parseFloat((average[0] + average[1]) / 2).toFixed(2);
          } else if (average[0] != undefined && average[1] == undefined) {
            obj.col16 = parseFloat(average[0]).toFixed(2);
          } else {
            obj.col16 = "Not Attendeded";
          }
        } else {
          obj.col16 = "Not Attendeded";
        }

        trows.push(obj);
      }
      setRows(trows);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={{
            fileName: `Report-${userDetails.curprgcou.bname}-${
              userDetails.curprgcou.pname
            }-${courseName != undefined ? courseName : "Course"}`,
          }}
        />
      </GridToolbarContainer>
    );
  }

  return (
    <div className={Acc.mainpopup}>
      <div className={Acc.closeholder}>
        <Close onClick={handleClose} className={Acc.closebutton} />
      </div>
      <h3>{courseName}</h3>
      <div className={classes.root}>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Skeleton variant="rect" width={1500} height={600} />{" "}
          </div>
        ) : (
          <div style={{ height: 500, width: "100%" }} className={classes.root}>
            <DataGrid
              rows={rows}
              columns={columns}
              className={classes[".MuiDataGrid-root"]}
              components={{
                Toolbar: CustomToolbar,
              }}
              disableSelectionOnClick
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AccordionPopup;
