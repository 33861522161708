// import React from 'react';
// import { Doughnut } from 'react-chartjs-2';

// const data = {
//     labels: ['Male', 'Female', 'Other'],
//     datasets: [
//         {
//             label: '# of Votes',
//             data: [12, 19, 3],
//             backgroundColor: [
//                 '#49c4c8',
//                 '#fe8541',
//                 '#707070'
//             ],
//             borderWidth: 1,
//             hoverOffset: 4,
//             align: "start",
//             offset: 3
//         },
//     ],
// };

// const DoughnutChart = () => (
    // <>
    //     <div style={{ width: "100%", height: "100%" }}>
    //         <Doughnut data={data} width={130} height={100} options={{ maintainAspectRatio: false }} />
    //     </div>
    // </>
// );

// export default DoughnutChart;


import React from 'react';
import { Doughnut } from 'react-chartjs-2';



const DoughnutChart = (props) => {
    let { genderChartData } = props;
    const data = {
        labels: ['Male', 'Female', 'Other'],
        datasets: [
            {
                label: '# of Votes',
                data: genderChartData,
                backgroundColor: [
                    '#49c4c8',
                    '#fe8541',
                    '#707070'
                ],
                borderWidth: 1,
                hoverOffset: 4,
                align: "start",
                offset: 3
            },
        ],
    };

    return (
        <>
        <div style={{ width: "100%", height: "100%" }}>
            <Doughnut data={data} width={130} height={100} options={{ maintainAspectRatio: false }} />
        </div>
    </>
    )
}

export default DoughnutChart;