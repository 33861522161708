import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "semantic-ui-react";
import ReactPlayer from "react-player";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import useWindowDimensions from "../../../modules/Window/Window";
import { Constants } from "../../../config/constants";
import config from "../../../config/aws-exports";
import { API } from "aws-amplify";
import "./CoursePlayer.scss";
import Cviewstyle from "../../../components/Programs/CourseCreation/courseCreation.module.scss";
import swal from "sweetalert";
import { Image } from "semantic-ui-react";


import {
  InputAdornment,
  Backdrop,
  CircularProgress,
  makeStyles,
  TextField,
  FormControlLabel,
  Checkbox,FormControl,FormGroup,
} from "@material-ui/core";
import Done from "../../../assets/svgjs/Done";
import { Formik, Field } from "formik";
import CustomImageInput from "../../../modules/CustomImageInput/CustomImageInput";

import axios from "axios";
import Reject from "../../../assets/svgjs/Reject";
import EditorJs from "react-editor-js";
import { EDITOR_JS_TOOLS } from "../../../components/Programs/CourseCreation/EditorConstants";
import RegexTextField from "../../../components/Programs/CourseCreation/RegexTextField";
import edjsHTML from "editorjs-html";
import CreateQuiz from "../../../components/CreateQuiz/CreateQuiz";
import produce from "immer";

const CoursePlayer = (props) => {
  let {
    handleClose,
    courseDetails,
    curObject,
    assignment,
    summative,
    sumScore,
    miniScore,
    sMiniScore,
    qisLoading,
    setQIsLoading,
    getAssignmentDataPost,
    assignmentLoad,
    setAssignmentLoad,
    editObj,
    setEditObj,
    selectedObj,
    setSelectedObj,
    nuggets,
    folder,
    setOpen,
    editquizincourse,
  } = props;

  const [quizQuestion, setQuizQuestion] = useState([]);
  const [btnClick, setBtnClick] = useState(false);
  const [qtype, setQtype] = useState("");
  const [answerReveal, setAnswerReveal] = useState(false);

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [score, setScore] = useState(0);
  const formData = useRef([]);
  const full = useRef(null);
  const [correct, setCorrect] = useState(undefined);


  const { height } = useWindowDimensions();
  const [tryAgain, setTryAgain] = useState(false);
  const [formativeAns, setFormativeAns] = useState([]);
  const [isSubmittingg, setSubmittingg] = useState(false);
  const instanceRef = useRef(null);
  const [typesButtons, setTypeButtons] = useState(false);

  const [istLoading, setIstLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [loaded, setLoaded] = useState(false);
  const [cutOff, setCutOff] = useState("");
  const [checkboxes, setCheckboxes] = useState([]);


  const [feedbackButton, setFeedbackButton] = useState({
    disableSubmit: true,
    hideNext: true,
    hideSubmit: false,
    submitted: false,
  });
  let h = true;
  const [editQuiz, setEditQuiz] = useState(false);
  const startTime = useRef(0);

  let userDetails = useSelector(authData);
  const dispatch = useDispatch();

  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiTextField-root": {
        color: "black !important",
      },
      "& .MuiFormLabel-root": {
        color: "black !important",
      },
    },
    textarea11: {
      "& .MuiInputBase-input": {
        color: " #black !important",
        fontSize: "12.5px !important",
      },
    },
    paper: {
      backgroundColor: theme.palette.background.paper,

      height: "200px",
      width: "500px",
      borderRadius: "4px",
      border: "none",
      padding: theme.spacing(2, 4, 3),
    },
    modalConfirm: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      border: "none",
      transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },

    tooltipFull: {
      fontSize: 30,
    },
    bottomIcons: {
      color: "#000",
      "&:hover": {
        color: "#777",
      },
    },
    volumeButton: {
      color: "#000",
      fontSize: 40,
      transform: "scale(0.9)",
      "&:hover": {
        color: "#777",
        transform: "scale(1.03)",
        transitionTimingFunction: "cubic-bezier(0.1, 0.1, 0.25, 1)",
      },
    },
    volumeButtonExit: {
      color: "lightgray",
      fontSize: 40,
      transform: "scale(0.9)",
      "&:hover": {
        color: "#777",
        transform: "scale(1.03)",
        transitionTimingFunction: "cubic-bezier(0.1, 0.1, 0.25, 1)",
      },
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "98%",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    
    startTime.current = Math.round(new Date().getTime());
    setAssignmentLoad(false);
    setLoaded(true);

  }, []);

  async function getQuiz(obj) {
    setEditQuiz(true);
    setQIsLoading(true);
    const bodyParam = {
      body: {
        quizid: obj.oid,
        oid: config.aws_org_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        `/getquiz`,
        bodyParam
      );
      setCutOff(response.cutoff);
      
      const { qitems, qtype } = response;
     
      setQtype(qtype);
      setQuizQuestion(qitems);

      if (obj.edit === true) {
        selectedObj.quizQuestion = qitems;
        selectedObj.qtitle = curObject.otitle;
        if(curObject.qtype == 3){
          selectedObj.miniassignmenttype = curObject.miniassignmenttype != undefined ? curObject.miniassignmenttype :
          undefined;
        }
        setSelectedObj(selectedObj);
        editObj.oeditmode = true;
        setEditObj({ ...editObj });
      }

      setBtnClick(true);
      setQIsLoading(false);
      setAssignmentLoad(false);
    } catch (error) {
     
      console.error(error);
    }
  }

  function setCheckbox(index, checked) {
    let newCheckboxes = [...checkboxes];
    if (newCheckboxes[index].checked == undefined) {
      newCheckboxes = produce(newCheckboxes, (draft) => {
        draft[index].checked = false;
      });
    }
    
    newCheckboxes = produce(newCheckboxes, (draft) => {
      draft[index].checked = checked;
    });
    setCheckboxes(newCheckboxes);
    let tmp = 0;
    for (let k = 0; k < checkboxes.length; k++) {
      if (
        checkboxes[k].checked !== undefined &&
        checkboxes[k].checked === true
      ) {
        tmp = 1;
        setCorrect(true);
      }
      if (tmp === 0 && k == checkboxes.length - 1) {
        setCorrect(undefined);
      }
    }
  }

  function renderObjects() {
    let objType = curObject.otype;
    switch (objType) {
      case "video":
        return VideoView(); // loadVideoView(curObject);
      case "audio":
        return AudioView(); // loadAudioView(curObject);
      case "pdf":
        return PdfView(); // loadMultimediaView(curObject);
      case "html":
        return WebView(); // loadMultimediaView(curObject);
      case "interactivity":
        return WebView(); // loadInteractivityView(curObject);
      case "Interactivity":
        return WebView(); // loadInteractivityView(curObject);
        case "scorm":
        return WebView(); 
      case "quiz":
        return quizView();
      case "vimeo":
        return VimeoView(); // loadVimeoView(curObject);
      case "youtube":
        return youtubeView(); // loadYoutubeView(curObject);
      default:
        return null;
    }
  }

  function VideoView() {
    return (
      <div
        className="player-wrapper"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <ReactPlayer
          url={curObject.curl}
          controls={true}
          width="100%"
          height={height - 300}
          config={{
            file: {
              attributes: {
                controlsList: "nodownload", //<- this is the important bit
              },
            },
          }}
        />
      </div>
    );
  }

  function AudioView() {
   
    return (
      <div>
        <div
          className="player-wrapper"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <ReactPlayer
            url={curObject.curl}
            controls={true}
            width="100%"
            height={height - 900}
          />
        </div>
      </div>
    );
  }

  function VimeoView() {
    return (
      <div>
        <div
          className="player-wrapper"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <ReactPlayer
            url={curObject.url}
            controls={true}
            width="calc(100% - 15%)"
            height="calc(100% - 20px)"
          />
        </div>
      </div>
    );
  }

  function youtubeView() {
    return (
      <div>
        <div
          className="player-wrapper"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <ReactPlayer
            url={curObject.url}
            controls={true}
            width="calc(100% - 15%)"
            height="calc(100% - 20px)"
          />
        </div>
      </div>
    );
  }

  function PdfView() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: height - 300,
          overflowX: "hidden",
        }}
      >
        <iframe src={curObject.curl} height="200" width="100%"></iframe>
      
      </div>
    );
  }

  function WebView() {
    
    return (
      <div
        style={{
          display: "flex",
         
          height: height - 300,
        
          justifyContent: "center",
        }}
      >
        <iframe
          src={curObject.curl}
          width={"100%"}
          
          height={height - 100}
          title="WebView"
        />
      </div>
    );
  }

  const ochangeHandler = (event) => {
    
    if (event.target.files[0] !== undefined) {
      
      if (selectedObj.otype == "html") {
        if (event.target.files[0].type == "text/html") {
          setSelectedFile(event.target.files[0]);
          selectedObj.otherdata.files = event.target.files[0].name;
          setSelectedObj(selectedObj);
        } else {
          swal("Please upload valid file format");
          return;
        }
      } else if (selectedObj.otype == "pdf") {
        if (event.target.files[0].type == "application/pdf") {
          setSelectedFile(event.target.files[0]);
          selectedObj.otherdata.files = event.target.files[0].name;
          setSelectedObj(selectedObj);
        } else {
          swal("Please upload valid file format");
          return;
        }
      } else if (selectedObj.otype == "Interactivity") {
        if (event.target.files[0].type == "application/x-zip-compressed") {
          setSelectedFile(event.target.files[0]);
          selectedObj.otherdata.files = event.target.files[0].name;
          setSelectedObj(selectedObj);
        } else {
          swal("Please upload valid file format");
          return;
        }
      } else if (selectedObj.otype == "video") {
        if (event.target.files[0].type == "video/mp4") {
          setSelectedFile(event.target.files[0]);
          selectedObj.otherdata.files = event.target.files[0].name;
          setSelectedObj(selectedObj);
        } else {
          swal("Please upload valid file format");
          return;
        }
      } else if (selectedObj.otype == "audio") {
        if (event.target.files[0].type == "audio/mpeg") {
          setSelectedFile(event.target.files[0]);
          selectedObj.otherdata.files = event.target.files[0].name;
          setSelectedObj(selectedObj);
        } else {
          swal("Please upload valid file format");
          return;
        }
      } else {
        swal("Please upload valid Data");
      }
      
    }
  };
  if (quizQuestion == undefined) {
    alert("No Quiz Present");
    handleClose();
    return;
  }

  function handleAnswerOption(ques) {
    return ques;
  }

  const handleNextQuestionClick = () => {
    const nextQuestion = currentQuestion + 1;
   
    if (nextQuestion < quizQuestion.length) {
      setCurrentQuestion(nextQuestion);
      if (quizQuestion[nextQuestion].atype == 3 ||
        quizQuestion[nextQuestion].atype == 5
      ) {
        setCorrect(undefined);
        setCheckboxes(quizQuestion[nextQuestion].iopts);
      }
    } else {
      setShowScore(true);
      
    }
  };

  const handleAnswerOptionClick = (ansOpts, idx) => {
    if (ansOpts.correct == "true" || ansOpts.correct === true) {
      setScore(score + 1);
    }

   
    let answer = [...formativeAns];
    answer.push(idx);
    setFormativeAns(answer);
    handleNextQuestionClick();
  };

  const imgUrl = `https://${
    Constants.DOMAIN
  }/${config.aws_org_id.toLowerCase()}-resources/images/quiz-images/${courseDetails?.tid}/`;

  function validateEmail(value) {
    let error;
    if (value) {
      if (
        value.type === "image/png" ||
        value.type === "image/jpg" ||
        value.type === "application/pdf" ||
        value.type === "image/jpeg"
      ) {
        error = "";
      } else {
        error = `*File not supported, please match requested type.`;
      }
      return error;
    }
  }

  function validateUsername(value) {
    let error;

    if (value === "" || value.replace(/\s/g, "").length === 0) {
      error = "*A text is required";
    } else {
      error = "";
    }
    return error;
  }

  const getPreSignedUrl = async (value, obj) => {
    setSubmittingg(true);

    let fileName = new Date().getTime();

    let quest = handleAnswerOption(quizQuestion[currentQuestion]);
    quest.response = value.response;
    quest.filename = fileName + value.file.name;
    formData.current.push(quest);
    if (value.file) {
      const bodyParam = {
        body: {
          type: "minassign",
          filename: fileName + value.file.name,
          filetype: value.file.type,
          oid: config.aws_org_id,
          eid: userDetails.eid,
          quizid: obj.oid,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          
        },
      };

      try {
        const response = await API.post(
          config.aws_cloud_logic_custom_name,
          Constants.GET_PRESIGNED_URL,
          bodyParam
        );

        handleNextQuestionClick();
        setSubmittingg(false);
       
        fileUpload(value.file, response);
      } catch (error) {}
    } else {
      handleNextQuestionClick();
      setSubmittingg(false);
    }
  };

  async function fileUpload(file, url) {
    await axios
      .put(url, file, {
        headers: {
          "Content-Type": file.type,
          "Cache-Control": "no-cache no-store must-revalidate",
        },
      })
      .then(() => {})
      .catch((err) => {
        console.error(err);
      });
  }

  const TextInputComponent = ({ field, ...props }) => {
    const { errorMessage, touched } = props;
    const { name, onChange } = field;
    return (
      <div className={classes.root}>
        <TextField
          fullWidth
          className={classes.textarea11}
          placeholder="Type your response here..."
          multiline
          rows={4}
          name={name}
          variant="outlined"
          color="primary"
          error={touched && errorMessage ? true : false}
          label="Insert some text"
          helperText={touched && errorMessage ? errorMessage : undefined}
          onChange={onChange}
        />
      </div>
    );
  };

  function answerPrev() {
    const prevQuestion = currentQuestion - 1;

    setCurrentQuestion(prevQuestion);
  }

  function answerNext() {
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < quizQuestion.length) {
      setCurrentQuestion(nextQuestion);
    }
  }

  function quizView() {
   
    return (
      <div className="body">
      
        {btnClick ? (
          <div className="app1">
            {showScore ? (
              <div className="score-section">
                {qtype != 3 ? (
                  <div>
                    You scored {score} out of {quizQuestion.length}
                  
                    {qtype == 2 &&
                    ((score / quizQuestion.length) * 100 >= parseInt(cutOff) ||
                      tryAgain) ? (
                      <div>
                        <button
                          className="btnQuizLD"
                          style={{
                            width: "200px",
                            border: "3px solid white",
                            fontSize: "15px",
                          }}
                          onClick={() => {
                           
                            setScore(0);
                            setTryAgain(false);
                            setFormativeAns([]);
                          }}
                        >
                          Mark Complete
                        </button>
                        <button
                          className="btnQuizLD"
                          style={{
                            width: "200px",
                            border: "3px solid white",
                            fontSize: "15px",
                          }}
                          onClick={() => {
                            getQuiz(curObject);
                            setCurrentQuestion(0);
                            setAnswerReveal(true);
                            setShowScore(false);
                          
                          }}
                        >
                          {qisLoading ? (
                            <CircularProgress color="default" size={30} />
                          ) : (
                            "Answers"
                          )}
                        </button>
                      </div>
                    ) : qtype == 2 ? (
                      <div style={{ fontSize: "16px" }}>
                        {" "}
                        You should score more than {cutOff}% to complete this
                        Quiz.
                      </div>
                    ) : (
                      <div>Submitted</div>
                    )}
                  </div>
                ) : (
                  <div>
                    <h3>Goto Home Page</h3>
                    <div>
                      <button
                        className="buttonQuizCt"
                        onClick={() => {
                          handleClose();
                          setScore(0);
                          setCurrentQuestion(0);
                          getQuiz(curObject);
                        }}
                      >
                        Exit Assignment
                      </button>
                    </div>
                  </div>
                )}

                {qtype != 1 &&
                qtype != 3 &&
                (score / quizQuestion.length) * 100 < parseInt(cutOff) &&
                tryAgain == false ? (
                  <div>
                    <button
                      className="buttonQuizCt"
                      onClick={() => {
                        setBtnClick(false);
                        getQuiz(curObject);
                        setScore(0);
                        setShowScore(false);
                        setCurrentQuestion(0);
                        setTryAgain(true);
                        setFormativeAns([]);
                      }}
                    >
                      Try Again
                    </button>
                    <button
                      className="buttonQuizCt"
                      onClick={() => {
                        getAssignmentDataPost();
                        setBtnClick(false);
                        handleClose();
                        setScore(0);
                        setCurrentQuestion(0);
                        setTryAgain(false);
                        setFormativeAns([]);
                      }}
                    >
                      Exit Assignment
                    </button>
                  </div>
                ) : qtype == 1 ? (
                  <div>
                    {" "}
                    <button
                      className="buttonQuizCt"
                      onClick={() => {
                        getAssignmentDataPost();
                        setBtnClick(false);
                        handleClose();
                        setScore(0);
                        setCurrentQuestion(0);
                      }}
                    >
                      Exit Assignment
                    </button>
                  </div>
                ) : null}
              </div>
            ) : answerReveal ? (
              <div>
                <div className="scrollQuiz">
                  <div className="question-section">
                    <div className="question-count">
                      <span>Question {currentQuestion + 1}</span>/
                      {quizQuestion.length}
                    </div>
                    <div
                      className="question-text"
                      dangerouslySetInnerHTML={{
                        __html: quizQuestion[currentQuestion].istem,
                      }}
                    >
                      
                    </div>

                    {quizQuestion[currentQuestion].imageurl ? (
                      <div className="img_container">
                        <img
                          src={`${imgUrl}${quizQuestion[currentQuestion].imageurl}`}
                          alt="item"
                        />
                      </div>
                    ) : null}
                  </div>
                  {qtype != 3 ? (
                    <div className="answer-section">
                      {quizQuestion[currentQuestion].iopts.map(
                        (answerOption, idx) => (
                          <div style={{ display: "flex" }}>
                            {" "}
                            {formativeAns[currentQuestion] !== idx ? (
                              <button className={`buttonQuizAR`}>
                                {answerOption.content}
                              </button>
                            ) : (
                              <button
                                className={`buttonQuizAR`}
                                style={{
                                  backgroundColor: "lightgray",
                                  border: "2px solid #234668",
                                }}
                              >
                                {answerOption.content}
                              </button>
                            )}
                            {answerOption.correct == true ||
                            answerOption.correct == "true" ? (
                              <Done
                                className="right_size"
                                cls1="cls1_D"
                                cls2="cls2_D"
                              />
                            ) : (
                              <Reject
                                className="Wrong_size"
                                cls1="cls1_D"
                                cls2="cls2_D"
                              />
                            )}
                            {formativeAns[currentQuestion] !== idx ? null : (
                              <p
                                style={{
                                  fontSize: "14px",
                                  paddingLeft: "8px",
                                  paddingTop: "8px",
                                }}
                              >
                                Your Response
                              </p>
                            )}
                          </div>
                        )
                      )}
                      <br />
                    </div>
                  ) : null}
                  {currentQuestion > 0 ? (
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      className="btn-size"
                      onClick={() => answerPrev()}
                      style={{ fontSize: "12px" }}
                    >
                      Prev
                    </Button>
                  ) : null}
                  {currentQuestion + 1 === quizQuestion.length ? (
                    <div></div>
                  ) : (
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      className="btn-siz"
                      onClick={() => answerNext()}
                      style={{ float: "right", fontSize: "12px" }}
                    >
                      Next
                    </Button>
                  )}

                  <br />
                  <br />
                  <br />

                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    className="btn-sizeClose"
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      border: "1px solid black",
                      width: "100px",
                      marginLeft: "45%",
                      fontSize: "12px",
                    }}
                    onClick={() => {
                     
                      setCurrentQuestion(0);
                      setShowScore(true);
                      setAnswerReveal(false);
                    }}
                  >
                    Close
                  </Button>
                </div>
              </div>
            ) : (
              <div className="scrollQuiz">
                <div className="question-section">
                  <div className="question-count">
                    <span>Question {currentQuestion + 1}</span>/
                    {quizQuestion.length}
                  </div>
                  <div
                    className="question-text"
                    dangerouslySetInnerHTML={{
                      __html: quizQuestion[currentQuestion].istem,
                    }}
                  >
                   
                  </div>
                  <center>
                  <div style={{ width: "270px" }}>
                    {quizQuestion[currentQuestion].imageurl ? (
                      <div className="img_container">
                        <img
                          src={`${imgUrl}${quizQuestion[currentQuestion].imageurl}`}
                         
                          alt="item"
                        />
                      </div>
                    ) : null}
                  </div>
                  </center>
                

                </div>
                {qtype != 3 ? (
                  <div className="answer-section">
                    {qtype == 0 ? (
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: `${
                            quizQuestion[currentQuestion].imageurl
                              ? "3fr 1fr"
                              : "1fr"
                          } `,
                          gridGap: "2rem",
                        }}
                      >
                        <div style={{ width: "100%" }}>
                          {quizQuestion[currentQuestion].iopts.map(
                            (answerOption, idx) => (
                              <React.Fragment key={"aaa" + idx}>
                                <div style={{ display: "flex" }}>
                                  <button
                                   
                                    className={`buttonQuizFb ${
                                      answerOption.Selected ? "ddd" : ""
                                    }`}
                                    onClick={() => {
                                      handleCheck(answerOption, idx);
                                      
                                    }}
                                    disabled={feedbackButton.submitted}
                                  >
                                    {answerOption.content}
                                  </button>
                                  {feedbackButton.submitted &&
                                  answerOption.correct == true ? (
                                    <Done
                                      className="right_size"
                                      cls1="cls1_D"
                                      cls2="cls2_D"
                                    />
                                  ) : (
                                    feedbackButton.submitted && (
                                      <Reject
                                        className="Wrong_size"
                                        cls1="cls1_D"
                                        cls2="cls2_D"
                                      />
                                    )
                                  )}
                                </div>
                                {answerOption.Selected &&
                                  feedbackButton.submitted &&
                                  (answerOption.feedback === undefined ? (
                                    ""
                                  ) : (
                                    <button className={`buttonQuizSq`}>
                                      {answerOption.feedback}
                                    </button>
                                  ))}
                               
                              </React.Fragment>
                            )
                          )}
                        </div>
                        <div style={{ width: "270px" }}>
                          {quizQuestion[currentQuestion].imageurl ? (
                            <div className="img_container">
                              <img
                                src={`${imgUrl}${quizQuestion[currentQuestion].imageurl}`}
                                
                                alt="item"
                              />
                            </div>
                          ) : null}
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "3rem",
                          }}
                        >
                          <div>
                            {!feedbackButton.hideSubmit && (
                              <button
                                className="buttonFb"
                                disabled={feedbackButton.disableSubmit}
                                onClick={() => {
                                  feedbackButton.disableSubmit = true;
                                  feedbackButton.hideNext = false;
                                  feedbackButton.hideSubmit = true;
                                  feedbackButton.submitted = true;
                                  setFeedbackButton({
                                    ...feedbackButton,
                                  });
                                }}
                              >
                                Submit
                              </button>
                            )}
                            {!feedbackButton.hideNext && (
                              <>
                                {currentQuestion !== quizQuestion.length - 1 ? (
                                  <button
                                    className="buttonFb"
                                    onClick={() => {
                                      answerNext();
                                      setFeedbackButton({
                                        disableSubmit: true,
                                        hideNext: true,
                                        hideSubmit: false,
                                        submitted: false,
                                      });
                                      setQuizQuestion((currentQues) =>
                                        produce(currentQues, (v) => {
                                          v[currentQuestion].iopts.forEach(
                                            (opt) => {
                                              opt.Selected = false;
                                            }
                                          );
                                        })
                                      );
                                    }}
                                  >
                                    Next
                                  </button>
                                ) : (
                                  <button
                                    className="buttonFb"
                                    onClick={() => {
                                     
                                      setScore(0);
                                      setTryAgain(false);
                                      setFormativeAns([]);
                                    }}
                                  >
                                    Completed
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                          <div>
                            <button
                              className="buttonFb-a"
                              onClick={() => {
                                if (
                                  currentQuestion !==
                                  quizQuestion.length - 1
                                ) {
                                  getAssignmentDataPost();
                                  setBtnClick(false);
                                  handleClose();
                                  setScore(0);
                                  setCurrentQuestion(0);
                                  setTryAgain(false);
                                  setFormativeAns([]);
                                } else {
                                  if (!feedbackButton.hideNext) {
                                    
                                    setScore(0);
                                    setTryAgain(false);
                                    setFormativeAns([]);
                                  } else {
                                    getAssignmentDataPost();
                                    setBtnClick(false);
                                    handleClose();
                                    setScore(0);
                                    setCurrentQuestion(0);
                                    setTryAgain(false);
                                    setFormativeAns([]);
                                  }
                                }
                              }}
                            >
                              Exit
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: `${
                              quizQuestion[currentQuestion].imageurl
                                ? "3fr 1fr"
                                : "1fr"
                            } `,
                            gridGap: "2rem",
                          }}
                        >
                          <div>
                            {quizQuestion[currentQuestion].iopts.map(
                              (answerOption, idx) => (
                                <button
                                  key={"a" + idx}
                                  
                                  className={`buttonQuiz`}
                                  data-id={`${
                                    answerOption.correct == true
                                      ? answerOption.correct
                                      : null
                                  }`}
                                  onClick={() => {
                                    handleAnswerOptionClick(answerOption, idx);
                                   
                                  }}
                                >
                                  {answerOption.content}
                                </button>
                              )
                            )}
                          </div>
                          <div style={{ width: "270px" }}>
                            {quizQuestion[currentQuestion].imageurl ? (
                              <div className="img_container">
                                <img
                                  src={`${imgUrl}${quizQuestion[currentQuestion].imageurl}`}
                                  
                                  alt="item"
                                />
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <div>
                    {!quizQuestion[currentQuestion].iopts ||
                    quizQuestion[currentQuestion].iopts.length === 0 ? (
                      <div className={classes.root}>
                        <Formik
                          initialValues={{
                            file: "",
                            text: "",
                            email: "",
                          }}
                          
                          validateOnBlur={true}
                          onSubmit={async (
                            values,
                            {  resetForm }
                          ) => {
                           
                            getPreSignedUrl(
                              {
                                file: values.file,
                                response: values.text,
                                email: values.email,
                              },
                              curObject
                            );
                           
                            resetForm();
                           
                          }}
                          render={({
                            
                            errors,
                            touched,
                            handleChange,
                            handleSubmit,
                            handleBlur,
                            setFieldValue,
                            
                          }) => {
                            
                            return (
                              <form
                                onSubmit={handleSubmit}
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: `${
                                    quizQuestion[currentQuestion].imageurl
                                      ? "4fr"
                                      : "1fr"
                                  } `,
                                  gridGap: "1.5rem",
                                }}
                              >
                               
                                <br></br>
                                <Field
                                  validate={validateUsername}
                                  name="text"
                                  component={TextInputComponent}
                                 
                                  touched={touched["text"]}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                 
                                />
                                <div style={{ width: "5px" }}>
                                 
                                </div>
                                {errors.text && (
                                  <div
                                    style={{
                                    
                                      paddingTop: "-10px",
                                      fontSize: "12px",
                                      color: "red",
                                    }}
                                  >
                                    {errors.text}
                                  </div>
                                )}
                                {errors.file && (
                                  <div
                                    style={{
                                      float: "left",
                                     
                                      fontSize: "12px",
                                      color: "red",
                                      marginBottom: "-35px",
                                    }}
                                  >
                                    {errors.file}
                                  </div>
                                )}
                                <Field
                                  validate={validateEmail}
                                  name="file"
                                  component={CustomImageInput}
                                  title="Select a file"
                                  setFieldValue={setFieldValue}
                                 
                                  touched={touched["file"]}
                                  style={{ display: "flex" }}
                                  onBlur={handleBlur}
                                />

                                {currentQuestion + 1 === quizQuestion.length ? (
                                  navigator.onLine ? (
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      fullWidth
                                      type="submit"
                                      disabled={isSubmittingg === true}
                                      className="btn-size"
                                    >
                                      {isSubmittingg ? "Loading..." : "Submit"}
                                    </Button>
                                  ) : (
                                    <p>You are offline</p>
                                  )
                                ) : (
                                  <div
                                    
                                  >
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      fullWidth
                                      type="submit"
                                      disabled={isSubmittingg === true}
                                      className="btn-size"
                                      style={{
                                        float: "right",
                                        marginRight: "20px",
                                      }}
                                    >
                                      {isSubmittingg ? "Loading..." : "Next"}
                                    </Button>
                                   
                                  </div>
                                )}
                              </form>
                            );
                          }}
                        />
                      </div>
                    ) : (
                      <div className="answer-section">
                      {quizQuestion[currentQuestion].atype == 3 ||
                          quizQuestion[currentQuestion].atype == 5 ? (
                          <FormControl component="fieldset">
                                <FormGroup aria-label="position" style={{paddingLeft:'2%',display:'block'}} row>
                                  {quizQuestion[
                                    currentQuestion
                                  ].iopts.map(
                                    (answerOption, index) => (
                                      <>
                                        {quizQuestion[currentQuestion]
                                          .atype == 5 ? (
                                          <>
                                            <div >
                                              <FormControlLabel
                                                className={`buttonQuiz`}
                                                value={
                                                  <Image
                                                    src={
                                                      imgUrl +
                                                      answerOption.url
                                                    }
                                                    rounded
                                                  />
                                                }
                                                control={
                                                  <Checkbox color="primary" />
                                                }
                                                label={
                                                  <Image
                                                    src={
                                                      imgUrl +
                                                      answerOption.url
                                                    }
                                                    rounded
                                                  />
                                                }
                                                onChange={(e) => {
                                                  setCheckbox(
                                                    index,
                                                    e.target.checked
                                                  );
                                                }}
                                                labelPlacement="end"
                                              />
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            <FormControlLabel
                                              className={
                                                `buttonQuiz`
                                              }
                                              value={answerOption.content}
                                              control={
                                                <Checkbox color="primary" />
                                              }
                                              label={answerOption.content}
                                              onChange={(e) => {
                                                setCheckbox(
                                                  index,
                                                  e.target.checked
                                                );
                                              }}
                                              labelPlacement="end"
                                            />
                                          </>
                                        )}
                                      </>
                                    )
                           
                                    
                                  )}
                                </FormGroup>

                            <Button variant="contained" color="primary" disabled={correct === undefined}
                              onClick={() => {
                                handleNextQuestionClick();
                              }} 
                              style={{width:'50px',margin:'3%'}}>
                                Next 
                            </Button>
                          </FormControl>
                        ):(
                          <>
                          {quizQuestion[currentQuestion].iopts.map(
                            (answerOption, id) => (
                              <>{quizQuestion[currentQuestion].atype == 4 ? (
                              <div>
                              <input
                                type="radio"
                                className="radio_opacity"
                                name="options"
                                id={answerOption.url}
                              />
                              <label
                                className={`buttonQuiz`}
                                for={answerOption.url}
                                onClick={() =>
                                handleAnswerOptionClick(
                                  answerOption.correct,
                                  id
                                )
                                }
                              >
                              <Image src={imgUrl + answerOption.url} rounded/>
                              </label>
                              </div>
                              ):(
                              <button
                              key={"b" + id}
                             
                              className={`buttonQuiz`}
                              data-id={`${
                                answerOption.correct == true
                                ? answerOption.correct
                                : null
                              }`}
                              onClick={() =>
                                handleAnswerOptionClick(answerOption)
                              }
                              >
                              {answerOption.content}
                              </button>
                              )}
                              </>
                            )
                          )}
                          </>
                        )
                      }
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div>
            {curObject.qtype == 3 ? (
              <div>
                <button
                  className="btnQuizLD"
                  onClick={() => {
                    getQuiz(curObject);
                    setShowScore(false);
                    setQIsLoading(true);
                    setAssignmentLoad(true);
                  }}
                >
                  Assignment
                </button>
                {assignment ? (
                  assignmentLoad ? (
                    <CircularProgress color="default" size={30} />
                  ) : navigator.onLine ? (
                    <button
                      className="btnQuizLD"
                      onClick={() => {
                        getQuiz(curObject);
                        setShowScore(false);
                        setQIsLoading(true);
                        setAssignmentLoad(true);
                      }}
                    >
                      Assignment
                    </button>
                  ) : (
                    <p>you are offline</p>
                  )
                ) : (
                  <div>
                    {sMiniScore ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          textAlign: "center",
                        }}
                      >
                        <h1
                          style={{
                            color: "#000",
                            textAlign: "center",
                            margin: "2.5rem 0",
                            boxShadow: "0 0 0 5px #e35f14",
                            borderRadius: "100%",
                            height: "100px",
                            width: "100px",
                            position: "relative",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            {miniScore}/10
                          </span>
                        </h1>
                      </div>
                    ) : null}
                    <div style={{ color: "black" }}>
                      {assignmentLoad ? (
                        <CircularProgress color="default" size={30} />
                      ) : (
                        <h1></h1>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                {summative ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <h1
                      style={{
                        color: "#000",
                        textAlign: "center",
                        margin: "2.5rem 0",
                        boxShadow: "0 0 0 5px #e35f14",
                        borderRadius: "100%",
                        height: "120px",
                        width: "120px",
                        position: "relative",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        {sumScore} %
                      </span>
                    </h1>
                  </div>
                ) : (
                  <>
                    <br />
                  
                    {curObject.qtype == 2 ? (
                      <div>
                        <h3 style={{ marginLeft: "-14px" }}>Instructions:</h3>
                        <div style={{ fontSize: "14px" }}>
                          <ol>
                            <li>
                              {" "}
                              Unit formative quiz consists of Multiple Choice
                              Questions.
                            </li>
                            <li> Click on "START QUIZ" button.</li>
                            <li>
                              {" "}
                              On starting the quiz, One question appears at a
                              time.
                            </li>
                            <li>
                              {" "}
                              Once you select an answer, you will get the next
                              question.
                            </li>
                            {curObject.cutoff != undefined && (
                              <li>
                                On answering all the questions, you should get a
                                score of {curObject.cutoff}% or more to view the
                                right answers and then click on "MARK COMPLETE"
                                button.
                              </li>
                            )}
                            {curObject.cutoff == undefined && (
                              <li>
                                On answering all the questions, you should get a
                                score of {75}% or more to view the right answers
                                and then click on "MARK COMPLETE" button.
                              </li>
                            )}

                            <li>
                              Once you click on "MARK COMPLETE", the system
                              would allow you to move to the next Unit.
                            </li>
                            {curObject.cutoff != undefined && (
                              <li>
                                If you get {`<`} {curObject.cutoff}% score, you
                                must click on "TRY AGAIN" button to retake the
                                quiz and improve your score.
                              </li>
                            )}
                            {curObject.cutoff == undefined && (
                              <li>
                                If you get {`<`} {75}% score, you must click on
                                "TRY AGAIN" button to retake the quiz and
                                improve your score.
                              </li>
                            )}
                          </ol>
                        </div>
                        <br />
                      </div>
                    ) : (
                      <div></div>
                    )}
                    {qisLoading ? (
                      <CircularProgress color="default" size={30} />
                    ) : (
                      <button
                        className="btnQuizLD"
                        onClick={() => {
                          getQuiz(curObject);
                          setShowScore(false);
                          setQIsLoading(true);
                          setFeedbackButton({
                            disableSubmit: true,
                            hideNext: true,
                            hideSubmit: false,
                            submitted: false,
                          });
                        }}
                      >
                        Start Quiz
                      </button>
                    )}
                    <br />
                    
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
  const handleCheck = ( idx) => {
    setQuizQuestion((currentQues) =>
      produce(currentQues, (v) => {
        v[currentQuestion].iopts.forEach((opt) => {
          opt.Selected = false;
        });
      })
    );
    setQuizQuestion((currentQues) =>
      produce(currentQues, (v) => {
        v[currentQuestion].iopts[idx].Selected = true;
      })
    );
    setFeedbackButton({ ...feedbackButton, disableSubmit: false });
  };
  const finalSave = async () => {
   
    selectedObj.otherdata.dur =
      Number(selectedObj.otherdata.min) * 60 +
      Number(selectedObj.otherdata.sec);
    let file;
    let id = Math.floor(Math.random() * (999 - 100 + 1) + 100);
    let filename = "";

    let object = {
      otitle: selectedObj.otherdata.title,
      odescription: selectedObj.otherdata.desc,
      otype: selectedObj.otype,
      oduration: selectedObj.otherdata.dur,
      ourl: selectedObj.ourl,
      oid: selectedObj.oid,
      miniassignmenttype: selectedObj.otherdata.miniassignmenttype,
    };

    var body = {
      folder: folder,
    };
    if (selectedObj.stype !== undefined && selectedObj.stype == "chtml") {
      body.filetype = "text/html";
      body.filename =
        Math.floor(Math.random() * (999 - 100 + 1) + 100) + ".html";
      object.newfile =
        Math.floor(Math.random() * (999 - 100 + 1) + 100) + ".html";
      object.filechanges = true;
      const savedData = await instanceRef.current.save();

      const edjsParser = edjsHTML();
      // blocks
      file = edjsParser.parse(savedData);
      file = JSON.parse(JSON.stringify(file));
      let html = "<html><heaed></heaed><body>";
      for (let i = 0; i < file.length; i++) {
        html = html + file[i];
      }
      html = html + "</html></body>";
      file = html;
    } else if (selectedObj.otype == "quiz") {
      body.filename =
        Math.floor(Math.random() * (999 - 100 + 1) + 100) + ".json";
      object.newfile = body.filename;
      object.filechanges = true;
      object.oduration = selectedObj.quizdata.qitems.length * 60;
      file = selectedObj.quizdata;
      body.filetype = "application/json";
      object.qtype = selectedObj.quizdata.qtype;
      if (
        selectedObj.quizdata.qtype == 2 ||
        selectedObj.quizdata.qtype == "2"
      ) {
        object.cutoff = selectedObj.quizdata.cutoff;
      }
    } else if (selectedFile !== undefined) {
      file = selectedFile;
      body.filename = id + selectedFile.name;
      body.filetype = selectedFile.type;
      object.newfile = id + selectedFile.name;
      object.filechanges = true;
      object.curl = URL.createObjectURL(selectedFile);
    }
    if (object.filechanges === true) {
      uploadfile(body, file, object);
    } else {
      nuggets[selectedObj.nindex].nchanges = true;
      nuggets[selectedObj.nindex].objects[selectedObj.oindex] = object;
     
      setOpen(false);

      setTypeButtons(true);
      editObj.oeditmode = false;
      setEditObj({ ...editObj });
    }
  };
  const checkData = async () => {
    selectedObj.otherdata.dsval = false;
    selectedObj.otherdata.tval = false;
    selectedObj.otherdata.mval = false;
    selectedObj.otherdata.sval = false;

    if (
      selectedObj.otherdata.title === undefined ||
      selectedObj.otherdata.title == "" ||
      selectedObj.otherdata.title.replace(/\s/g, "").length === 0
    ) {
      selectedObj.otherdata.tval = true;

      setSelectedObj((prevselectedObj) => ({
        ...prevselectedObj,
        ...selectedObj,
      }));

      return;
    }

    if (
      selectedObj.otherdata.desc === undefined ||
      selectedObj.otherdata.desc == "" ||
      selectedObj.otherdata.desc.replace(/\s/g, "").length === 0
    ) {
      selectedObj.otherdata.dsval = true;
      selectedObj.otherdata.tval = false;
      setSelectedObj((prevselectedObj) => ({
        ...prevselectedObj,
        ...selectedObj,
      }));
      return;
    }

    if (
      selectedObj.otherdata.min === undefined ||
      selectedObj.otherdata.min == "" ||
      selectedObj.otherdata.min.toString().replace(/\s/g, "").length === 0
    ) {
      selectedObj.otherdata.dsval = false;
      selectedObj.otherdata.tval = false;
      selectedObj.otherdata.mval = true;
      setSelectedObj((prevselectedObj) => ({
        ...prevselectedObj,
        ...selectedObj,
      }));
      return;
    }

    if (
      selectedObj.otherdata.sec === undefined ||
      selectedObj.otherdata.sec == "" ||
      selectedObj.otherdata.sec <= 0 ||
      selectedObj.otherdata.sec.toString().replace(/\s/g, "").length === 0
    ) {
      selectedObj.otherdata.dsval = false;
      selectedObj.otherdata.tval = false;
      selectedObj.otherdata.mval = false;
      selectedObj.otherdata.sval = true;
      setSelectedObj((prevselectedObj) => ({
        ...prevselectedObj,
        ...selectedObj,
      }));
      return;
    }
   
    finalSave();
  };
  async function uploadfile(body, file, object) {
    setIstLoading(true);
    const bodyParam = {
      body,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
    
      const preSignedData = await API.post(
        config.aws_cloud_logic_custom_name,
        
        "/getpreSignedURL",
        bodyParam
      );
      let ftype = "";
      if (selectedObj.stype !== undefined && selectedObj.stype == "chtml") {
        ftype = "text/html";
      } else if (selectedObj.otype == "quiz") {
        ftype = "application/json";
      } else {
        ftype = file.type;
      }

      axios
        .put(preSignedData, file, {
          headers: {
            "Content-Type": ftype,
            "Cache-Control": "no-cache no-store must-revalidate",
          },
        })
        .then(() => {
          nuggets[selectedObj.nindex].nchanges = true;
          nuggets[selectedObj.nindex].objects[selectedObj.oindex] = object;
          
         
          setOpen(false);
          editObj.oeditmode = false;
          setEditObj({ ...editObj });
          setIstLoading(false);
          setTypeButtons(true);
          
        })
        .catch((error) => {
        
          console.error("There was an error!", error);
        });
    } catch (error) {
     
      console.error(error);
    }
  }

  return (
    <div style={{ height: "100%", width: "100%", padding: "20px" }}>
      {istLoading && (
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div style={{ width: "100%", height: "26px" }}>
       
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              fontSize: "18px",
              paddingLeft: "2%",
              paddingTop: "1%",
              fontWeight: "500",
              width: "66%",
            }}
          >
            {curObject.otitle}
          </div>
          {editObj.meditmode && !editObj.oeditmode && (
            <button
              style={styles.editButton}
              onClick={() => {
                if (curObject.otype == "quiz") {
                  let crobj = { ...curObject };
                  crobj.edit = true;
                  getQuiz(crobj);
                } else {
                  editObj.oeditmode = true;
                  setEditObj({ ...editObj });
                }
              }}
            >
              {" "}
              Edit Object
            </button>
          )}
          <span
            style={{
              float: "right",
              cursor: "pointer",
              color: "black",
              fontSize: "26px",
              paddingRight: "20px",
            }}
            onClick={() => {
              handleClose();
              setTryAgain(false);
              setFormativeAns([]);
              editObj.oeditmode = false;
              setEditObj({ ...editObj });
            }}
          >
            x
          </span>
        </div>
      </div>
      {loaded == true ? (
        <div style={{ height: "100%", width: "100%" }}>
          <div
            ref={full}
            style={{
              width: "100%",
              padding: "2%",
              position: "relative",
            }}
          >
            {editObj.oeditmode && (
              <>
                <div
                  style={{ margin: "0px 50px 0px 50px", textAlign: "center" }}
                >
                  {selectedObj.seltype && selectedObj.sobject != "quiz" && (
                    <div>
                      <div style={{ margin: "20px 15px", overflow: "hidden" }}>
                        {selectedObj.error1 && (
                          <p className={Cviewstyle.verror1}>
                            {selectedObj.sobject}
                            title cannot be empty
                          </p>
                        )}

                        <form autoComplete="off">
                          <TextField
                            label="Title"
                            id="outlined-margin-normal"
                            className={classes.textField}
                            defaultValue={selectedObj.otherdata.title}
                            error={selectedObj.otherdata.tval}
                            helperText={
                              selectedObj.otherdata.tval
                                ? "Please Enter Title"
                                : ""
                            }
                            margin="normal"
                            variant="outlined"
                            onChange={(e) => {
                              selectedObj.otherdata.title = e.target.value;
                              setSelectedObj(selectedObj);
                            }}
                          />
                          <TextField
                            label="Description"
                            id="outlined-margin-normal"
                            className={classes.textField}
                            defaultValue={selectedObj.otherdata.desc}
                            error={selectedObj.otherdata.dsval}
                            helperText={
                              selectedObj.otherdata.dsval
                                ? "Please Enter Description"
                                : ""
                            }
                            margin="normal"
                            variant="outlined"
                            onChange={(e) => {
                              selectedObj.otherdata.desc = e.target.value;
                              setSelectedObj(selectedObj);
                            }}
                          />

                          <div style={{ display: "flex" }}>
                            <RegexTextField
                              label="Duration"
                              error={selectedObj.otherdata.mval}
                              defaultValue={selectedObj.otherdata.min}
                              helperText={
                                selectedObj.otherdata.mval
                                  ? "Please Enter Min"
                                  : ""
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    Min
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(e) => {
                                selectedObj.otherdata.min = e.target.value;
                                setSelectedObj(selectedObj);
                              }}
                            />
                            <RegexTextField
                              label=""
                              error={selectedObj.otherdata.sval}
                              defaultValue={selectedObj.otherdata.sec}
                              helperText={
                                selectedObj.otherdata.sval
                                  ? "Please Enter Sec"
                                  : ""
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    Sec
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(e) => {
                                selectedObj.otherdata.sec = e.target.value;
                                setSelectedObj(selectedObj);
                              }}
                            />
                          </div>
                        </form>
                      </div>
                      {selectedObj.stype == "chtml" && (
                        <>
                          {selectedObj.ferror && (
                            <p
                              style={{
                                color: "red",
                                margin: "0 60px",
                                textAlign: "left",
                              }}
                            >
                              {" "}
                              Please enter some data.{" "}
                            </p>
                          )}
                          <div
                            style={{
                              height: "300px",
                              border: "1px solid #ddd",
                              margin: "0px 60px",
                              padding: "6px 28px",
                              overflow: "hidden auto",
                            }}
                          >
                            <EditorJs
                              tools={EDITOR_JS_TOOLS}
                              instanceRef={(instance) =>
                                (instanceRef.current = instance)
                              }
                            />
                          </div>
                        </>
                      )}
                      {(selectedObj.stype === undefined ||
                        selectedObj.stype != "chtml") && (
                        <>
                          <p className={Cviewstyle.pvalid}>
                            Please select a {selectedObj.sobject} to upload
                          </p>
                          <div
                            className={Cviewstyle.insidediv}
                            style={{
                              margin: "20px 59px 19px 24px",
                              width: "95%",
                            }}
                          >
                            <div className={Cviewstyle.centeritem}>
                              <input
                                type="file"
                                id="ofiles"
                                style={{
                                  visibility: "hidden",
                                  position: "absolute",
                                }}
                                onChange={ochangeHandler}
                                accept={selectedObj.afile}
                              />
                              <label for="ofiles">
                                <p
                                  variant="contained"
                                  style={{
                                    backgroundColor: "rgb(227, 95, 20)",
                                    color: "#fff",
                                    width: "100px",
                                    height: "36px",
                                    marginRight: "10px",
                                    alignSelf: "center",
                                    padding: "10px",
                                    cursor: "pointer",
                                    borderRadius: "2px",
                                  }}
                                >
                                  Browse
                                </p>
                              </label>
                            </div>

                            {selectedObj.otherdata.files != "" && (
                              <p className={Cviewstyle.pfile}>
                                {selectedObj.otherdata.files}
                              </p>
                            )}
                            {selectedObj.otherdata.files == "" && (
                              <p className={Cviewstyle.pfile}>
                                {selectedObj.sobject}
                                file not selected{" "}
                              </p>
                            )}
                            {selectedObj.ferror && (
                              <p
                                className={Cviewstyle.pfile}
                                style={{ color: "red" }}
                              >
                                {" "}
                                Please select file{" "}
                              </p>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  {selectedObj.sobject != "quiz" && (
                    <>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "rgb(227, 95, 20)",
                          color: "#fff",
                          width: "100px",
                          height: "36px",
                          marginRight: "10px",
                          alignSelf: "center",
                        }}
                        onClick={() => checkData()}
                      >
                        Save
                      </Button>
                      <Button
                        variant="outlined"
                        style={{
                          width: "100px",
                          height: "36px",
                          alignSelf: "center",
                        }}
                        onClick={() => {
                          editObj.oeditmode = false;
                          setEditObj({ ...editObj });
                        }}
                      >
                        Cancel
                      </Button>
                    </>
                  )}
                  {selectedObj.seltype && selectedObj.sobject == "quiz" && (
                    <>
                      <CreateQuiz
                        selectedObj={selectedObj}
                        setSelectedObj={setSelectedObj}
                        finalSave={finalSave}
                        folder={folder}
                        typesButtons={typesButtons}
                        setTypeButtons={setTypeButtons}
                        quizQuestion={quizQuestion}
                        setQuizQuestion={setQuizQuestion}
                        editQuiz={editQuiz}
                        editquizincourse={editquizincourse}
                      />
                    </>
                  )}
                </div>
              </>
            )}
            {!editObj.oeditmode && renderObjects()}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default CoursePlayer;
let styles = {
  editButton: {
    color: "white",
    borderStyle: "groove ",
    borderColor: Constants.main_color_2,
    height: "30px ",
    lineHeight: "0px ",
    width: "150px",
    marginTop: "5px",
    backgroundColor: Constants.main_color_2,
    fontSize: "15px ",
    borderRadius: "3px ",
    float: "right",
    fontWeight: "bold",
  },
  enableDisable: {
    color: "black",
    borderStyle: "groove ",
    borderColor: "black",
    height: "30px ",
    lineHeight: "0px ",
    width: "150px",
    marginTop: "15px",
    marginRight: "50px",
    backgroundColor: "white",
    fontSize: "15px ",
    borderRadius: "3px ",
    float: "right",
  },
};
